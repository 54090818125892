import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Beforeunload } from "react-beforeunload";
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";
import { Alert, Stack } from "@mui/material";
import "../../assets/stylesheets/newcitation.css";
import { ReactComponent as CalendarGroup } from "../../assets/icons/calendarGroup.svg";
import Zunge from "./completeappointment/zunge/Zunge";
import Pulse from "./completeappointment/pulse/Pulse";
import Akupunktur from "./completeappointment/akupunktur/Akupunktur";
import { NewReminderCitation } from "../../components/reminders/NewReminderCitation";
import Recommendations from "./completeappointment/Recommendation";
import Prescriptions from "./completeappointment/Prescriptions";
import ClinicalFindings from "./completeappointment/ClinicalFindings";
import Quizz from "../citations/Quizz";
import UploadFile from "./completeappointment/UploadFile";
import { PatientInfo } from "./completeappointment/PatientInfo";
import Info from "./completeappointment/info/Info";
import GetUserToken from "../../utils/GetUserToken";
import AlertComponent from "../alerts/Alert";
import Attention from "./completeappointment/Attention";
import Warnings from "./completeappointment/Warnings";
import DiagnosisSyndrome from "./completeappointment/DiagnosisSyndrome";
import AltDiagnosis from "./completeappointment/AltDiagnosis";
import AdditionalsProducts from "./completeappointment/AdditionalsProducts";

class CompleteAppointment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userToken: GetUserToken(),
      citation: [],
      invoiced: null,
      patientID: "",
      loading: true,
      archived: null,
      componentId: "",
      name: "",
      time: "",
      email: "",
      treatment: "",
      adittionals: [],
      adittional: [],
      diagnosis: "",
      altdiagnosis: [],
      //whoDiagnosis: [],
      isAlert: {
        open: false,
        severity: "",
        message: "",
      },
      updated_at: null,
      findings: "",
      warnings: "",
      attention: "",
      clinicTreatments: [],
      show: false,
      //selectedAdittionalTreatment: "",
      //beforeAdittional: [],
      clinicalFindings: [],
      diagnosisList: [],
      saved: true,
      zungeUpdate: [],
      pulseUpdate: [],
      akupunturUpdate: [],
      infoUpdate: [],
      recommendationsUpdate: [],
      attentionUpdate: [],
      warningsUpdate: [],
      syndromeUpdate: [],
      altDiagnosisUpdate: [],
      additionalsProducts: [],
      //choosenProducts: [],
      editing: false,
      action: [],
      editor: [],
      ...this.props.citation,
    };

    this.initData = this.initData.bind(this);
    this.getCitation = this.getCitation.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.stripHtmlEntities = this.stripHtmlEntities.bind(this);
    this.getTreatments = this.getTreatments.bind(this);
    this.getClinicalFindings = this.getClinicalFindings.bind(this);
    this.getDiagnosisList = this.getDiagnosisList.bind(this);
    this.getAdittionalProducts = this.getAdittionalProducts.bind(this);
    this.dateFormat = this.dateFormat.bind(this);
    this.setIsAlert = this.setIsAlert.bind(this);
  }
  async componentDidMount() {
    this.initData();
  }

  async initData() {
    this.setState({ ...this.state, loading: true });
    await this.getCitation();
    await this.getTreatments();
    await this.getClinicalFindings();
    await this.getDiagnosisList();
    await this.getAdittionalProducts();
    this.setState({ ...this.state, loading: false });
  }

  async getCitation() {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    var current_id = atob(id);

    this.setState({ ...this.state, componentId: current_id });

    const url = process.env.REACT_APP_API_URL + `/show/${current_id}`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: this.state.userToken,
      },

      redirect: "follow",
    };

    await fetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then((response) => {
        this.setState({
          ...this.state,
          citation: response,
          invoiced: response["invoiced"],
          patientID: response["patient_id"],
          name: response["name"],
          archived: response["archived"],
          time: response["time"],
          email: response["email"],
          treatment: response["treatment"],
          adittional: response["adittional"]
            ? JSON.parse(response["adittional"])
            : [],
          //choosenProducts: choosenProducts,
          //diagnosis: response["diagnosis"],
          //altdiagnosis: westlicheDiagnosis,
          // FIXME: Solucionar Conflictos De Logica Y Limpiar Variables
          altdiagnosis:
            response["altdiagnosis"] == null ||
            response["altdiagnosis"].length == 0
              ? []
              : JSON.parse(response["altdiagnosis"]),
          findings: response["findings"],
          //warnings: response["warnings"],
          //attention: response["attention"],
          updated_at: response["updated_at"],
          action: response["action"],
          editor: response["editor"],
        });
      })
      .catch(() => console.log("error"));
  }

  async getTreatments() {
    const url = process.env.REACT_APP_API_URL + "/citations/getTreatments";

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: this.state.userToken,
      },
    })
      .then((response) => {
        response.json().then((response) => {
          const filteredTreatments = response.clinicTreatments.filter(
            (item) => {
              return item.archived === false;
            }
          );

          this.setState({
            ...this.state,
            clinicTreatments: filteredTreatments.sort((a, b) =>
              a.name.localeCompare(b.name)
            ),
          });
        });
      })
      .catch((error) => console.log(error.message));
  }

  async getClinicalFindings() {
    // const url = process.env.REACT_APP_API_URL + "/citations/getClinicalFindings?email=" + this.state.email;
    const url =
      process.env.REACT_APP_API_URL +
      "/citations/getClinicalFindingsById?patient_id=" +
      this.state.patientID;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: this.state.userToken,
      },
    })
      .then((response) => {
        response.json().then((response) => {
          this.setState({
            ...this.state,
            clinicalFindings: response["clinicalFindings"],
          });
        });
      })
      .catch((error) => console.log(error.message));
  }

  async getDiagnosisList() {
    const url = process.env.REACT_APP_API_URL + "/diagnosis/index";

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: this.state.userToken,
      },
    })
      .then((response) => {
        response.json().then((response) => {
          const sortedDiagnosis = response.diagnosis.sort((a, b) =>
            a.code > b.code ? 1 : b.code > a.code ? -1 : 0
          );

          this.setState({
            ...this.state,
            diagnosisList: sortedDiagnosis,
          });
        });
      })
      .catch((error) => console.log(error.message));
  }

  async getAdittionalProducts() {
    const url = process.env.REACT_APP_API_URL_V2 + "/bundles/index";

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: this.state.userToken,
      },
    })
      .then((response) => {
        response.json().then((response) => {
          let active_bundles = response.filter(
            (bundle) =>
              !bundle.archived &&
              bundle.adittional_products !== "" &&
              bundle.adittional_products !== "[]"
          );
          console.log(active_bundles);
          this.setState({
            ...this.state,
            adittionals: active_bundles,
          });
        });
      })
      .catch((error) => console.log(error.message));
  }

  stripHtmlEntities(str) {
    return String(str).replace(/</g, "&lt;").replace(/>/g, "&gt;");
  }

  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      saved: false,
      editing: true,
    });
  }

  onSubmit() {
    //translations
    const { t } = this.props;
    //alert
    this.setIsAlert({
      open: false,
      severity: "",
      message: "",
    });

    const {
      treatment,
      //adittional,
      //diagnosis,
      //altdiagnosis,
      findings,
      //warnings,
      //attention,
      //choosenProducts,
    } = this.state;

    const {
      match: {
        params: { id },
      },
    } = this.props;

    const body = {
      treatment,
      //adittional,
      //adittional: JSON.stringify(choosenProducts),
      //diagnosis,
      //altdiagnosis: JSON.stringify(altdiagnosis),
      findings,
      //warnings,
      //attention,
    };

    const url = process.env.REACT_APP_API_URL + `/citations/update/${atob(id)}`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: this.state.userToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          this.setState({
            ...this.state,
            editing: false,
            saved: true,
          });

          //alert
          this.setIsAlert({
            open: true,
            severity: "success",
            message: t("CompleteAppointment.Appointment saved!"),
          });

          setTimeout(() => {
            document.getElementById("here").scrollIntoView();
          }, 500);

          this.initData();
          return response.json();
        } else {
          //alert
          this.setIsAlert({
            open: true,
            severity: "error",
            message: t(
              "CompleteAppointment.Error saving data. Please backup and retry."
            ),
          });
        }
      })
      .catch((error) => console.log(error.message));
  }

  handleCallbackZunge = (childDataZ) => {
    this.setState({
      ...this.state,
      zungeUpdate: childDataZ,
    });
  };

  handleCallbackPulse = (childDataP) => {
    this.setState({
      ...this.state,
      pulseUpdate: childDataP,
    });
  };

  handleCallbackAkupuntur = (childDataA) => {
    this.setState({
      ...this.state,
      akupunturUpdate: childDataA,
    });
  };

  handleCallbackInfo = (childDataI) => {
    this.setState({
      ...this.state,
      infoUpdate: childDataI,
    });
  };

  handleCallbackRecommendations = (childDataR) => {
    this.setState({
      ...this.state,
      recommendationsUpdate: childDataR,
    });
  };

  handleCallbackAttention = (childDataAttention) => {
    this.setState({
      ...this.state,
      attentionUpdate: childDataAttention,
    });
  };

  handleCallbackWarnings = (childDataWarnings) => {
    this.setState({
      ...this.state,
      warningsUpdate: childDataWarnings,
    });
  };

  handleCallbackSyndrome = (childDataSyndrome) => {
    this.setState({
      ...this.state,
      syndromeUpdate: childDataSyndrome,
    });
  };

  handleCallbackAltDiagnosis = (childDataAltDiagnosis) => {
    this.setState({
      ...this.state,
      altDiagnosisUpdate: childDataAltDiagnosis,
    });
  };

  handleCallbackAdditionalProducts = (childDataAdditionalsProducts) => {
    this.setState({
      ...this.state,
      additionalsProducts: childDataAdditionalsProducts,
    });
  };

  dateFormat = (language, date) => {
    let arrayDate = date.split("-");
    let formattedDate =
      language === "en"
        ? `${arrayDate[1]}/${arrayDate[2]}/${arrayDate[0]}`
        : arrayDate.reverse().join("/");
    return formattedDate;
  };

  setIsAlert = (alert) => {
    this.setState({
      ...this.state,
      isAlert: alert,
    });
  };

  render() {
    const { t, i18n } = this.props;
    let language = i18n.language;
    return (
      <>
        <NewReminderCitation
          patientName={this.state.name}
          treatments={""}
          patientID={this.state.patientID}
          patientEmail={this.state.email}
        />

        {!this.state.saved && (
          <Beforeunload onBeforeunload={(event) => event.preventDefault()} />
        )}

        {this.state.loading ? (
          <div className="d-flex justify-content-center">
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <>
            <div className="frame">
              <PatientInfo
                citation={this.state.citation}
                saved={this.state.saved}
                action={this.state.action}
                editor={this.state.editor}
                editing={this.state.editing}
                onSubmit={this.onSubmit}
              />

              <div className="row">
                <div
                  className="col-4"
                  style={{
                    background: "white",
                    borderRadius: "10px",
                    marginRight: "10px",
                    paddingLeft: "20px",
                  }}
                >
                  <h4 className="subtitles-patients pt-3">
                    {t("CompleteAppointment.Appointment")}
                    &nbsp;&nbsp;
                    {this.state.archived && (
                      <>
                        <Tooltip
                          title={t(
                            "CompleteAppointment.This appointment is archived. That means that not will be showed in the calendar and invoces, but you can still see the info."
                          )}
                          placement="right"
                        >
                          <span className="appointment-status-archived">
                            &nbsp;{t("CompleteAppointment.Archived")}&nbsp;
                          </span>
                        </Tooltip>
                        &nbsp;&nbsp;
                      </>
                    )}
                    {this.state.invoiced ? (
                      <span className="invoices-list-invoiced-badge">
                        &nbsp;{t("CompleteAppointment.Invoiced")}&nbsp;
                      </span>
                    ) : (
                      <span className="invoices-list-unpaid-badge">
                        &nbsp;{t("CompleteAppointment.Not invoiced")}&nbsp;
                      </span>
                    )}
                  </h4>

                  <div className="complete-citation-info-grid border-bottom">
                    <div className="text-center">
                      <CalendarGroup />
                    </div>

                    <div className="text-center border-end">
                      <h4 className="subtitles-patients">
                        {t("CompleteAppointment.Time")}:
                      </h4>
                      <h4 className="key-text-box">
                        {this.state.time.split("T")[1].split(".")[0]}
                      </h4>
                    </div>

                    <div className="text-center border-end">
                      <h4 className="subtitles-patients">
                        {t("CompleteAppointment.Name")}:
                      </h4>
                      <h4 className="key-text-box">{this.state.name}</h4>
                    </div>

                    <div className="text-center">
                      <h4 className="subtitles-patients">
                        {t("CompleteAppointment.Date")}:
                      </h4>
                      <h4 className="key-text-box">
                        {this.dateFormat(
                          language,
                          this.state.time.split("T")[0]
                        )}
                      </h4>
                    </div>
                  </div>

                  {/* END HEADER*/}

                  <div className="mt-3">
                    <h5 className="subtitles-patients">
                      {t("CompleteAppointment.Treatments")}:
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%", marginTop: "15px" }}
                      >
                        <Select
                          id="treatment"
                          name="treatment"
                          value={this.state.treatment}
                          onChange={this.onChange}
                          style={{ height: "46px" }}
                          disabled={this.state.invoiced}
                        >
                          {this.state.clinicTreatments.map((item, i) => (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </h5>
                  </div>

                  <div className="mt-3">
                    {/* --- Additional Products (Bundles) --- */}
                    <AdditionalsProducts
                      id={this.state.componentId}
                      patientID={this.state.patientID}
                      handleCallbackAdditionalProducts={
                        this.handleCallbackAdditionalProducts
                      }
                      bundles={this.state.adittionals}
                      name={this.state.name}
                      patientEmail={this.state.email}
                      invoiced={this.state.invoiced}
                      currentAdditional={this.state.adittional}
                    />

                    <Stack sx={{ mt: 1, mb: 2 }} style={{ width: "100%" }}>
                      <Alert
                        severity="warning"
                        style={{ alignItems: "center" }}
                        className="title-inputs"
                      >
                        {t(
                          "CompleteAppointment.Remember to use the refresh button when adding or removing additional products"
                        )}{" "}
                        <Link
                          className="blue-anchors"
                          to={{
                            pathname: `/citation/${btoa(
                              this.state.componentId
                            )}`,
                          }}
                        >
                          {t("CompleteAppointment.here")}
                        </Link>
                      </Alert>
                    </Stack>
                  </div>
                </div>

                <div
                  className="col-5 d-flex flex-column justify-content-around"
                  style={{
                    background: "white",
                    borderRadius: "10px",
                    paddingLeft: "10px",
                  }}
                >
                  {/* --- ATTENTION --- */}

                  <Attention
                    id={this.state.componentId}
                    patientID={this.state.patientID}
                    handleCallbackAttention={this.handleCallbackAttention}
                  />

                  {/* --- WARNINGS --- */}

                  <Warnings
                    id={this.state.componentId}
                    patientID={this.state.patientID}
                    handleCallbackWarnings={this.handleCallbackWarnings}
                  />

                  {/* --- DIAGNOSIS (Syndrome) --- */}

                  <DiagnosisSyndrome
                    id={this.state.componentId}
                    patientID={this.state.patientID}
                    handleCallbackSyndrome={this.handleCallbackSyndrome}
                  />

                  {/* --- ALT diagnosis (Western and data from CRUD) --- */}

                  <AltDiagnosis
                    id={this.state.componentId}
                    patientID={this.state.patientID}
                    handleCallbackAltDiagnosis={this.handleCallbackAltDiagnosis}
                    diagnosisList={this.state.diagnosisList}
                    invoiced={this.state.invoiced}
                    currentDiagnosis={this.state.altdiagnosis}
                  />
                </div>

                <div
                  className="col-2"
                  style={{
                    background: "white",
                    borderRadius: "10px",
                    marginLeft: "10px",
                  }}
                >
                  <h5 className="subtitles-patients mt-3">
                    {t("CompleteAppointment.Upload files")}
                  </h5>
                  <UploadFile patient_id={this.state.patientID} />
                </div>
              </div>

              <div className="row mt-2">
                <div
                  className="col-11 pt-3 pb-3"
                  style={{
                    background: "white",
                    borderRadius: "10px",
                    width: "93%",
                  }}
                >
                  <ClinicalFindings
                    clinicalFindings={this.state.clinicalFindings}
                    useBeforeunload={this.useBeforeunload}
                    findings={this.state.findings}
                    onChange={this.onChange}
                    saved={this.state.saved}
                    onFindingsChange={this.onFindingsChange}
                    updated_at={this.state.updated_at}
                    onSubmit={this.onSubmit}
                  />
                </div>
              </div>

              <div className="row mt-3 mb-3 text-center">
                <div className="col-10">
                  <button
                    type="submit"
                    className="button-citation-box-2-save"
                    onClick={this.onSubmit}
                  >
                    {t("Buttons.Save")}
                  </button>
                </div>
              </div>

              {/* --- ZUNGE --- */}
              <div className="row mt-2" id="here">
                <Zunge
                  id={this.state.componentId}
                  patientID={this.state.patientID}
                  handleCallbackZunge={this.handleCallbackZunge}
                />
              </div>

              {/* --- PULSE  --- */}

              <div className="row mt-2">
                <Pulse
                  id={this.state.componentId}
                  patientID={this.state.patientID}
                  handleCallbackPulse={this.handleCallbackPulse}
                />
              </div>

              {/* --- Akupunktur  --- */}

              <div className="row mt-2">
                <Akupunktur
                  id={this.state.componentId}
                  patientID={this.state.patientID}
                  handleCallbackAkupuntur={this.handleCallbackAkupuntur}
                />
              </div>

              {/* --- Quiz  --- */}

              <div className="row mt-2">
                <Quizz
                  id={this.state.componentId}
                  patientID={this.state.patientID}
                />
              </div>

              {/* --- Info  --- */}

              <div className="row mt-2">
                <Info
                  id={this.state.componentId}
                  patientID={this.state.patientID}
                  handleCallbackInfo={this.handleCallbackInfo}
                />
              </div>

              {/* --- Recommendations  --- */}

              <div className="row mt-2">
                <Recommendations
                  id={this.state.componentId}
                  patientID={this.state.patientID}
                  handleCallbackRecommendations={
                    this.handleCallbackRecommendations
                  }
                />
              </div>

              <div className="row mt-2 pb-4">
                <Prescriptions
                  citation={{
                    id: this.state.componentId,
                    invoiced: this.state.invoiced,
                    archived: this.state.archived,
                  }}
                  patientID={this.state.patientID}
                />
              </div>
            </div>
          </>
        )}

        {this.state.isAlert.open && (
          <AlertComponent
            isAlert={this.state.isAlert}
            severity={this.state.isAlert.severity}
            message={this.state.isAlert.message}
            setIsAlert={this.setIsAlert}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(CompleteAppointment);
