import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CircularProgress, Tooltip } from "@material-ui/core";
import Table from "./CurrentPatientTable";
// import resend from "../../../assets/icons/resend.png";
import { ReactComponent as AddNew } from "../../../assets/icons/add.svg";
import { TrackableLink } from "../../../utils/helper";

const CurrentPatients = ({ patients, loading }) => {
  // translations
  const { t } = useTranslation();
  let currentLanguage = localStorage.getItem("i18nextLng");

  const columns = useMemo(
    () => [
      {
        // first group
        Header: " ",
        // First group columns
        columns: [
          {
            Header: "ID",
            accessor: "id",
          },
          {
            Header: <>{t("CurrentPatients.0")}</>,
            accessor: "lastname",
          },
          {
            Header: <>{t("CurrentPatients.1")}</>,
            accessor: "firstname",
          },
          {
            Header: <>{t("CurrentPatients.2")}</>,
            accessor: "total",
            Cell: ({ row }) => (
              <>
                {row.original.total} &nbsp;{t("CurrentPatients.3")}
              </>
            ),
          },
          {
            Header: <>{t("CurrentPatients.4")}</>,
            accessor: " ",
            Cell: ({ row }) => (
              <>
                {row.original.citations.length > 0 ? (
                  <>
                    <span className="invoices-list-invoiced-badge">
                      &nbsp;&nbsp;{t("CurrentPatients.5")}&nbsp;&nbsp;
                    </span>
                  </>
                ) : (
                  <>
                    <span className="invoices-list-unpaid-badge">
                      &nbsp;&nbsp;{t("CurrentPatients.6")}&nbsp;&nbsp;
                    </span>
                  </>
                )}{" "}
              </>
            ),
          },
          {
            Header: " ",
            accessor: "created_at",
            Cell: ({ row }) => (
              <>
                {
                  <Link
                    to={"create-invoice/" + row.original.id}
                    id="new_invoice_patientId"
                    onClick={() => TrackableLink("new_invoice_patientId")}
                  >
                    <Tooltip title="New invoice" aria-label="add">
                      <AddNew
                        className="icon"
                        style={{ stroke: "var(--primary-color)" }}
                      />
                    </Tooltip>
                  </Link>
                }{" "}
              </>
            ),
          },
        ],
      },
    ],
    [currentLanguage]
  );

  return (
    <>
      <div style={{ marginRight: "50px" }}>
        <div className="container-fluid treatments-table">
          {loading ? (
            <div className="d-flex justify-content-center">
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <>
              <Table columns={columns} data={patients} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CurrentPatients;
