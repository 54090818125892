import { useTranslation } from "react-i18next";
import { RelativeDiv, Box, TableTitle, Table, Thead, Tbody, Tr, Th, Td } from './styles'

const Component = () => {
  const { t } = useTranslation();

  return (
    <>
      <RelativeDiv>
        <TableTitle>{t("PrescriptionForm.Blend selected")}: <b>{t("PrescriptionFormTable.No blend was selected")}</b> </TableTitle>
        <div style={{ height: 5 }} />
        <Table style={{ opacity: .5 }}>
          <Thead>
            <Tr>
              <Th style={{ width: 28 }}>
                <div style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>

                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="15px" height="15px"><path d="M8.127,24l9.507-9.52a3.507,3.507,0,0,0,0-4.948L8.116,0,6,2.121l9.518,9.531a.5.5,0,0,1,0,.707L6.01,21.879Z" /></svg>
                </div>
              </Th>
              <Th style={{ width: 40 }}>{t("PrescriptionFormTable.Pos")}</Th>
              <Th style={{ width: '65%' }}>{t("PrescriptionFormTable.Name")}</Th>
              <Th style={{ width: '35%' }}>{t("PrescriptionFormTable.Pinyin name")}</Th>
              <Th style={{ width: 70 }}>{t("PrescriptionFormTable.Pao")}</Th>
              <Th style={{ width: 70 }}>{t("PrescriptionFormTable.Gram")}</Th>
              <Th style={{ width: 70 }}>{t("PrescriptionFormTable.Orig")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Array(10).fill().map((a, index) =>
              <Tr>
                <Td>
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="15px" height="15px"><g id="_01_align_center" data-name="01 align center"><path d="M7.412,24,6,22.588l9.881-9.881a1,1,0,0,0,0-1.414L6.017,1.431,7.431.017l9.862,9.862a3,3,0,0,1,0,4.242Z" /></g></svg>
                  </div>
                </Td>
                <Td>{index + 1}</Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
              </Tr>
            )}

            <Tr>
              <Td style={{ border: 0, padding: "4px 0px" }} colSpan={5}>
                <span style={{ display: 'block', textAlign: 'right', padding: "0px 5px" }}>{t("PrescriptionFormTable.Total grams")}</span>
              </Td>
              <Td>0</Td>
              <Td>0</Td>
            </Tr>
          </Tbody>
        </Table>
        <Box>{t("PrescriptionFormTable.Select a blend or add herbs to start creating a new prescription")}</Box>
      </RelativeDiv>

      <div style={{ height: 40 }} />
    </>
  )
}

export default Component