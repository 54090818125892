// DEV: if the title, nameEN or nameES value is modified, you must also update that value in the translation files.

export const symptoms = [
  {
    id: "",
    title: "Keine ausgewählt",
    nameEN: "None selected",
    nameES: "Ninguno seleccionado",
  },
  {
    id: 0,
    title: "Blass-feucht",
    nameEN: "Pale-moist",
    nameES: "Pálido-húmedo",
  },
  {
    id: 1,
    title: "Blass-geschwollen",
    nameEN: "Pale-swollen",
    nameES: "Pálido-hinchado",
  },
  {
    id: 2,
    title: "Blass-Zahnmarken",
    nameEN: "Pale tooth marks",
    nameES: "Marcas de dientes pálidos",
  },
  { id: 3, title: "Blass-trocken", nameEN: "Pale-dry", nameES: "Pálido-seco" },
  { id: 4, title: "Blass-dünn", nameEN: "Pale-thin", nameES: "Pálido-delgado" },
  {
    id: 5,
    title: "Blass-Ränder",
    nameEN: "Pale edges",
    nameES: "Bordes pálidos",
  },
  {
    id: 6,
    title: "Violett-rötlich",
    nameEN: "Purple-reddish",
    nameES: "Púrpura-rojizo",
  },
  {
    id: 7,
    title: "Violett-rote Ränder",
    nameEN: "Purple-red edges",
    nameES: "Bordes rojo-púrpura",
  },
  {
    id: 8,
    title: "Violett-abgelöster Belag",
    nameEN: "Purple-detached coating",
    nameES: "Revestimiento separado púrpura",
  },
  {
    id: 9,
    title: "Violett-bläulich",
    nameEN: "Purple-bluish",
    nameES: "Violeta-azulado",
  },
  {
    id: 10,
    title: "Violett-blass weißer Belag",
    nameEN: "Violet-pale white coating",
    nameES: "Revestimiento blanco-violeta pálido",
  },
  {
    id: 11,
    title: "Rot Mit Belag Zungenrand",
    nameEN: "Red with coating tongue edge",
    nameES: "Rojo con revestimiento en el borde de la lengua",
  },
  {
    id: 12,
    title: "Rot Mit Belag Zungenspitze",
    nameEN: "Red with tongue tip coating",
    nameES: "Rojo con revestimiento en la punta de la lengua",
  },
  {
    id: 13,
    title: "Rot Mit Belag Zentrum",
    nameEN: "Red With Coating Center",
    nameES: "Rojo con revestimiento en el centro",
  },
  {
    id: 14,
    title: "Rot Mit Belag Wurzel",
    nameEN: "Red with root coating",
    nameES: "Rojo con revestimiento de raíz",
  },
  {
    id: 15,
    title: "Rot ohne Belag",
    nameEN: "Red without coating",
    nameES: "Rojo sin revestimiento",
  },
  {
    id: 16,
    title: "Rot ohne Belag Zungenrand",
    nameEN: "Red without coating tongue edge",
    nameES: "Rojo sin revestimiento borde lengua",
  },
  {
    id: 17,
    title: "Rot ohne Belag Zungenspitze",
    nameEN: "Red without coating tongue tip",
    nameES: "Rojo sin revestimiento punta lengua",
  },
  {
    id: 18,
    title: "Rot ohne Belag Zentrum",
    nameEN: "Red without coating center",
    nameES: "Rojo sin revestimiento centro",
  },
  {
    id: 19,
    title: "Rot ohne Belag Belaglose Areale",
    nameEN: "Red without coating areas without coating",
    nameES: "Rojo sin revestimiento zonas sin revestimiento",
  },
  {
    id: 20,
    title: "Rot ohne Belag Trocken, rissig",
    nameEN: "Red without coating Dry, cracked",
    nameES: "Rojo sin revestimiento Seco, agrietado",
  },
  {
    id: 21,
    title: "Rote Punkte Verteilt auf ganzer Zunge",
    nameEN: "Red spots distributed over the entire tongue",
    nameES: "Manchas rojas distribuidas por toda la lengua",
  },
  {
    id: 22,
    title: "Rote Punkte Oberes Drittel, grob, erhaben",
    nameEN: "Red dots upper third, coarse, raised",
    nameES: "Puntos rojos tercio superior, gruesos, levantados",
  },
  {
    id: 23,
    title: "Rote Punkte Verteilt, deutlich erhaben, dunkelrot",
    nameEN: "Red dots distributed, clearly raised, dark red",
    nameES: "Puntos rojos distribuidos, claramente elevados, rojo oscuro",
  },
  {
    id: 24,
    title: "Rote Punkte mit roter Zunge Zungenrand",
    nameEN: "Red dots with red tongue edge",
    nameES: "Puntos rojos con el borde de la lengua rojo",
  },
  {
    id: 25,
    title: "Rote Punkte mit roter Zunge Zungenspitze, feinkörnig",
    nameEN: "Red dots with red tongue tongue tip, fine-grained",
    nameES: "Puntos rojos con punta de lengua roja, de grano fino",
  },
  {
    id: 26,
    title: "Rote Punkte mit roter",
    nameEN: "Red dots with red",
    nameES: "Puntos rojos con rojo",
  },
  {
    id: 27,
    title: "Zunge Unteres Drittel, gelber Belag",
    nameEN: "Tongue lower third, yellow coating",
    nameES: "Tercio inferior de la lengua, revestimiento amarillo",
  },
  { id: 28, title: "Dünn Blass", nameEN: "Thin pale", nameES: "Thin pale" },
  {
    id: 29,
    title: "Dünn Rot, belaglos",
    nameEN: "Thin red, topless",
    nameES: "Delgado rojo, topless",
  },
  {
    id: 30,
    title: "Geschwollen Blass",
    nameEN: "Puffy Pale",
    nameES: "Pálido hinchado",
  },
  {
    id: 31,
    title: "Geschwollen Zahnmarken, feucht",
    nameEN: "Swollen tooth marks, moist",
    nameES: "Marcas de dientes hinchados, húmedos",
  },
  {
    id: 32,
    title: "Geschwollen Rote Spitze",
    nameEN: "Swollen red tip",
    nameES: "Punta roja hinchada",
  },
  {
    id: 33,
    title: "Geschwollen Rote Ränder",
    nameEN: "Swollen red margins",
    nameES: "Márgenes rojos hinchados",
  },
  {
    id: 34,
    title: "Geschwollen Oberes Drittel",
    nameEN: "Swollen upper third",
    nameES: "Tercio superior hinchado",
  },
  { id: 35, title: "Steif", nameEN: "Stiff", nameES: "Rígido" },
  { id: 36, title: "Schlaff", nameEN: "Flaccid", nameES: "Flácido" },
  { id: 37, title: "Lang", nameEN: "Long", nameES: "Largo" },
  { id: 38, title: "Spannung", nameEN: "Tension", nameES: "Tensión" },
  {
    id: 39,
    title: "Kurz Blass, feucht",
    nameEN: "Short Pale, moist",
    nameES: "Pálido corto, húmedo",
  },
  {
    id: 40,
    title: "Kurz Rot, belaglos",
    nameEN: "Short red, topless",
    nameES: "Rojo corto, topless",
  },
  {
    id: 41,
    title: "Delle Oberen Drittel",
    nameEN: "Delle Oberen Drittel",
    nameES: "Delle Oberen Drittel",
  },
  {
    id: 42,
    title: "Delle Einkerbung Spitze",
    nameEN: "Dent indentation tip",
    nameES: "Punta de indentación",
  },
  {
    id: 43,
    title: "Rissig Kurz, horizontal oder vertikal",
    nameEN: "Cracked short, horizontal or vertical",
    nameES: "Corto agrietado, horizontal o vertical",
  },
  {
    id: 44,
    title: "Rissig Lang und tief in der Mittellinie bis Spitze",
    nameEN: "Cracked long and deep in the midline to tip",
    nameES: "Grieta larga y profunda en la línea media hasta la punta",
  },
  {
    id: 45,
    title: "Rissig Mittellinie, breit",
    nameEN: "Cracked midline, wide",
    nameES: "Línea media agrietada, ancha",
  },
  {
    id: 46,
    title: "Rissig Kurz, horizontal an den Zungenrändern",
    nameEN: "Cracked short, horizontal at the edges of the tongue",
    nameES: "Grieta corta, horizontal en los bordes de la lengua",
  },
  { id: 47, title: "Zitternd", nameEN: "Trembling", nameES: "Tembloroso" },
  { id: 48, title: "Schräg", nameEN: "Oblique", nameES: "Oblicua" },
  {
    id: 49,
    title: "Aufgerollt Rand",
    nameEN: "Rolled up edge",
    nameES: "Borde enrollado",
  },
  {
    id: 50,
    title: "Wurzellos Mittleres Areal",
    nameEN: "Rootless middle area",
    nameES: "Zona media sin raíz",
  },
  {
    id: 51,
    title: "Wurzellos Partiell, „Landkarte“",
    nameEN: "Root lot Partial, 'Map'",
    nameES: "Root lot Partial, 'Map'",
  },
  {
    id: 51,
    title: "Belaglos Rot",
    nameEN: "Rootless Red",
    nameES: "Rojo sin raíz",
  },
  {
    id: 52,
    title: "Weiß Dick",
    nameEN: "White Thick",
    nameES: "Blanco Grueso",
  },
  {
    id: 53,
    title: "Grau, dunkel Feuchte Zunge",
    nameEN: "Gray, Dark Moist Tongue",
    nameES: "Lengua gris, oscura y húmeda",
  },
  {
    id: 54,
    title: "Grau, dunkel, Trockene Zuge",
    nameEN: "Gray, dark, dry tongue",
    nameES: "Lengua gris, oscura y seca",
  },
  { id: 55, title: "Gelb", nameEN: "Yellow", nameES: "Amarillo" },
];

export const zungenfarbe = [
  {
    id: "",
    title: "Keine ausgewählt",
    nameEN: "None selected",
    nameES: "Ninguno seleccionado",
  },
  {
    id: 0,
    title: "Blass-feucht / Yang-Mangel",
    nameEN: "Pale-damp / Yang deficiency",
    nameES: "Pálido-húmedo / Deficiencia de Yang",
  },
  {
    id: 1,
    title: "Blass-geschwollen /	Milz-Qi-Mangel",
    nameEN: "Pale-swollen / spleen qi deficiency",
    nameES: "Hinchazón pálida / deficiencia de bazo qi",
  },
  {
    id: 2,
    title: "Blass-Zahnmarken / (schwerer) Milz-Yang-Mangel",
    nameEN: "Pale tooth marks / (severe) spleen yang deficiency",
    nameES: "Marcas de dientes pálidos / deficiencia yang de bazo (severa)",
  },
  {
    id: 3,
    title: "Blass-trocken / Blut-Mangel",
    nameEN: "Pale-dry / blood deficiency",
    nameES: "Pálido-seco / deficiencia de sangre",
  },
  {
    id: 4,
    title: "Blass-dünn / Herz-Blut-Mangel",
    nameEN: "Pale-thin / heart-blood deficiency",
    nameES: "Pálido-delgado / deficiencia corazón-sangre",
  },
  {
    id: 5,
    title: "Blass-Ränder / Leber-Blut-Mangel",
    nameEN: "Pale-edged / liver-blood deficiency",
    nameES: "Pálido-rojizo / deficiencia de sangre hepática",
  },
  {
    id: 6,
    title: "Violett-rötlich / Hitze",
    nameEN: "Purple-reddish / heat",
    nameES: "Púrpura-rojizo / calor",
  },
  {
    id: 7,
    title: "Violett-rote Ränder	/ Leber-Qi-Stagnation",
    nameEN: "Purple-red edges / liver qi stagnation",
    nameES: "Bordes rojo púrpura / estancamiento del qi del hígado",
  },
  {
    id: 8,
    title: "Violett-abgelöster Belag / Nieren-Yin-Mangel",
    nameEN: "Purple detached plaque / Kidney Yin deficiency",
    nameES: "Placa desprendida púrpura / Deficiencia de Yin de Riñón",
  },
  {
    id: 9,
    title: "Violett-bläulich / Qi-, Blut-Mangel",
    nameEN: "Violet-bluish / Qi, blood deficiency",
    nameES: "Violeta-azulado / Qi, deficiencia de sangre",
  },
  {
    id: 10,
    title: "Violett-blass weißer Belag / Kälte",
    nameEN: "Purple-pale white coating / cold",
    nameES: "Revestimiento blanco-púrpura / frío",
  },
  {
    id: 11,
    title: "Rot Mit Belag Zungenrand / Leber-Feuer",
    nameEN: "Red with coating tongue edge / liver fire",
    nameES: "Rojo con revestimiento en el borde de la lengua / fuego hepático",
  },
  {
    id: 12,
    title: "Rot Mit Belag Zungenspitze / Herz-Feuer",
    nameEN: "Red With Coating Tongue Tip / Heart-Fire",
    nameES: "Rojo con revestimiento punta lengua / fuego corazón",
  },
  {
    id: 13,
    title: "Rot Mit Belag Zentrum / Magen-Hitze",
    nameEN: "Red With Coating Center / Stomach Heat",
    nameES: "Rojo con revestimiento centro / calor Estomacal",
  },
  {
    id: 14,
    title: "Rot Mit Belag Wurzel / Feuchte-Hitze im unteren Erwärmer",
    nameEN: "Red With Coating Root / Moist Heat in Lower Heater",
    nameES: "Rojo con revestimiento raíz / calor húmedo en calentador inferior",
  },
  {
    id: 15,
    title: "Rot ohne Belag",
    nameEN: "Red without coating",
    nameES: "Rojo sin revestimiento",
  },
  {
    id: 16,
    title: "Rot ohne Belag Zungenrand / Leber-Yin-Mangel",
    nameEN: "Red without coating tongue edge / liver yin deficiency",
    nameES: "Rojo sin revestimiento borde lengua / deficiencia yin hígado",
  },
  {
    id: 17,
    title: "Rot ohne Belag Zungenspitze / Leere-Hitze im Herz",
    nameEN: "Red without coating tip of tongue / Empty heat in heart",
    nameES:
      "Rojo sin revestimiento en punta de lengua / calor vacío en corazón",
  },
  {
    id: 18,
    title: "Rot ohne Belag Zentrum / Magen-Yin-Mangel",
    nameEN: "Red without coating center / stomach yin deficiency",
    nameES: "Rojo sin revestimiento centro / deficiencia de yin de estómago",
  },
  {
    id: 19,
    title: "Rot ohne Belag Belaglose Areale	/ Nieren-Yin-Mangel",
    nameEN: "Red without plaque Plaque-free areas / Kidney Yin deficiency",
    nameES: "Rojo sin placa Zonas sin placa / deficiencia de Yin renal",
  },
  {
    id: 20,
    title: "Rot ohne Belag Trocken, rissig / Nieren-Yin-Mangel",
    nameEN: "Red without coating Dry, cracked / kidney yin deficiency",
    nameES: "Rojo sin revestimiento Seco, agrietado / deficiencia de yin renal",
  },
  {
    id: 21,
    title:
      "Rote Punkte Verteilt auf ganzer Zunge / Eingeschlossene Pathogene Faktoren",
    nameEN: "Red dots spread all over tongue / Entrapped pathogenic factors",
    nameES:
      "Puntos rojos esparcidos por toda la lengua / Factores patógenos atrapados",
  },
  {
    id: 22,
    title: "Rote Punkte Oberes Drittel, grob, erhaben / Wind-Hitze",
    nameEN: "Red dots upper third, coarse, raised / wind-heat",
    nameES: "Puntos rojos tercio superior, gruesos, elevados / viento-calor",
  },
  {
    id: 23,
    title: "Rote Punkte Verteilt, deutlich erhaben, dunkelrot /	Toxische Hitze",
    nameEN: "Red dots distributed, clearly raised, dark red / Toxic heat",
    nameES:
      "Puntos rojos distribuidos, claramente elevados, rojo oscuro / calor tóxico",
  },
  {
    id: 24,
    title: "Rote Punkte mit roter Zunge Zungenrand / Leber-Feuer",
    nameEN: "Red dots with red tongue edge / liver fire",
    nameES: "Puntos rojos con el borde de la lengua rojo / fuego hepático",
  },
  {
    id: 25,
    title: "Rote Punkte mit roter Zunge Zungenspitze, feinkörnig / Herz-Hitze",
    nameEN: "Red dots with red tongue tip, fine-grained / heart heat",
    nameES:
      "Puntos rojos con punta de lengua roja, de grano fino / calor del corazón",
  },
  {
    id: 26,
    title:
      "Rote Punkte mit roter Zunge Unteres Drittel, gelber Belag /	Feuchte-Hitze",
    nameEN: "Red spots with red tongue lower third, yellow coating / damp-heat",
    nameES:
      "Puntos rojos con tercio inferior de la lengua rojo, revestimiento amarillo / calor húmedo",
  },
];

export const zungengestalt = [
  {
    id: "",
    title: "Keine ausgewählt",
    nameEN: "None selected",
    nameES: "Ninguno seleccionado",
  },
  {
    id: 0,
    title: "Dünn Blass / Blut-Mangel",
    nameEN: "Thin Pale / Blood Deficiency",
    nameES: "Pálido fino / deficiencia de sangre",
  },
  {
    id: 1,
    title: "Dünn Rot, belaglos	/ Yin-Mangel",
    nameEN: "Thin Red, topless / Yin deficiency",
    nameES: "Rojo delgado, topless / deficiencia de Yin",
  },
  {
    id: 2,
    title: "Feuchtigkeit / Schleim",
    nameEN: "Moisture / Mucus",
    nameES: "Humedad / Mucosidad",
  },
  {
    id: 3,
    title: "Geschwollen Blass / Milz-Qi-Schwäche",
    nameEN: "Puffy Pale / Spleen Qi Weakness",
    nameES: "Pálido hinchado / Debilidad de Qi de Bazo",
  },
  {
    id: 4,
    title: "Geschwollen Zahnmarken, feucht	/ Milz-Yang-Schwäche",
    nameEN: "Swollen tooth marks, moist / spleen yang weakness",
    nameES:
      "Marcas de dientes hinchados, húmedos / debilidad del yang del bazo",
  },
  {
    id: 5,
    title: "Geschwollen Rote Spitze / Starkes Herz-Feuer",
    nameEN: "Swollen red tip / Strong heart fire",
    nameES: "Punta roja hinchada / Fuerte fuego del corazón",
  },
  {
    id: 6,
    title: "Geschwollen Rote Ränder / Leber-Feuer",
    nameEN: "Swollen red edges / liver fire",
    nameES: "Bordes rojos hinchados / fuego del hígado",
  },
  {
    id: 7,
    title: "Geschwollen Oberes Drittel	/ Schleim i. d. Lunge",
    nameEN: "Swollen upper third / mucus in the lung",
    nameES: "Tercio superior hinchado / mucosidad en el pulmón",
  },
  {
    id: 8,
    title: "Steif / Innerer Wind",
    nameEN: "Stiff / Internal wind",
    nameES: "Rigidez / viento interno",
  },
  {
    id: 9,
    title: "Schlaff / Mangel Körperflüssigkeiten",
    nameEN: "Flaccid / Lack of body fluids",
    nameES: "Flácido / Falta de fluidos corporales",
  },
  {
    id: 10,
    title: "Lang / Hitze-Neigung",
    nameEN: "Long / heat tendency",
    nameES: "Largo / Tendencia al calor",
  },
  {
    id: 11,
    title: "Spannung / Leber-Qi-Stagnation",
    nameEN: "Tension / liver qi stagnation",
    nameES: "Tensión / estancamiento del qi del hígado",
  },
];

export const zungenbelag = [
  {
    id: "",
    title: "Keine ausgewählt",
    nameEN: "None selected",
    nameES: "Ninguno seleccionado",
  },
  {
    id: 0,
    title: "Wurzellos Mittleres Areal / Magen-Qi-Schwäche",
    nameEN: "Rootless Middle Area / Stomach Qi Weakness",
    nameES: "Zona media sin raíz / debilidad del qi del estómago",
  },
  {
    id: 1,
    title: "Wurzellos Partiell, „Landkarte“ / (Magen-, Nieren) Yin-Mangel",
    nameEN: "Rootless Partial, 'Map' / (Stomach, Kidney) Yin deficiency",
    nameES: "Parcial sin raíz, 'Map' / (Estómago, Riñón) Deficiencia de Yin",
  },
  {
    id: 2,
    title: "Belaglos Rot /	Nieren-Yin-Mangel mit Leere-Hitze",
    nameEN: "Coatingless Red / Kidney Yin Deficiency with Empty-Heat",
    nameES:
      "Rojo sin revestimiento / Deficiencia de Yin de Riñón con Calor Vacío",
  },
  {
    id: 3,
    title: "Weiß Dick / Feuchtigkeit Kälte",
    nameEN: "White Thick / Moisture Cold",
    nameES: "Blanco Grueso / Humedad Fría",
  },
  {
    id: 4,
    title: "Grau, dunkel Feuchte Zunge	/ Starke Kälte",
    nameEN: "Gray, dark Moist tongue / Strong cold",
    nameES: "Lengua gris, oscura y húmeda / Frío fuerte",
  },
  {
    id: 5,
    title: "Grau, dunkel Trockene Zuge	/ Starke Hitze",
    nameEN: "Gray, dark Dry tongue / Strong heat",
    nameES: "Lengua gris, oscura y seca / Calor fuerte",
  },
  {
    id: 6,
    title: "Gelb / Feuchte-Hitze",
    nameEN: "Yellow / Humid heat",
    nameES: "Amarillo / Calor húmedo",
  },
];
