import GetUserToken from "../utils/GetUserToken";
import { parseJSON } from "../utils/parseJSON";

const urlBase = `${process.env.REACT_APP_API_URL_V2}/prescriptions`;
const readUrl = (url = "") => `${urlBase}/${url}`;

class Prescription {
  async getOne({ id }) {
    console.log("getOne - prescriptions - service", id);
    const auth = `${GetUserToken()}`;

    let res = await fetch(readUrl(id), {
      method: "GET",
      headers: {
        Authorization: auth,
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
      },
    });

    res = await parseJSON(res);
    return res;
  }

  async getAll(payload) {
    console.log("getAll - prescriptions - service");
    const auth = `${GetUserToken()}`;
    const { currentPage, sortBy, sortDirection, itemsPerPage, filters } =
      payload;
    const queryParams = new URLSearchParams(filters).toString();

    let res = await fetch(
      `${readUrl()}?${queryParams}&currentPage=${currentPage}&sortBy=${sortBy}&sortDirection=${sortDirection}&itemsPerPage=${itemsPerPage}`,
      {
        method: "GET",
        headers: {
          Authorization: auth,
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );

    res = await parseJSON(res);
    return res;
  }

  async createOne(payload) {
    console.log("createOne - prescriptions - service", payload);
    const auth = `${GetUserToken()}`;

    let res = await fetch(readUrl(), {
      method: "POST",
      headers: {
        Authorization: auth,
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(payload),
    });

    res = await parseJSON(res);
    return res;
  }

  async update(payload) {
    console.log("update - prescriptions - service", payload);
    const { id, ...rest } = payload;

    const auth = `${GetUserToken()}`;

    let res = await fetch(readUrl(id), {
      method: "PATCH",
      headers: {
        Authorization: auth,
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(rest),
    });

    res = await parseJSON(res);
    return res;
  }

  async duplicate(payload) {
    console.log("update - prescriptions - service", payload);
    const { id, ...rest } = payload;

    const auth = `${GetUserToken()}`;

    let res = await fetch(readUrl("duplicate"), {
      method: "POST",
      body: { id },
      headers: {
        Authorization: auth,
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(rest),
    });

    res = await parseJSON(res);
    return res;
  }

  async download({ idPrescription }) {
    console.log("download - prescriptions - service", idPrescription);
    const auth = `${GetUserToken()}`;

    let res = await fetch(readUrl(`download/${idPrescription}`), {
      method: "GET",
      headers: {
        Authorization: auth,
        "api-key": process.env.REACT_APP_API_KEY,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const blob = await res.blob();

    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `prescription_${idPrescription}.pdf`;

    // Trigger the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return { success: true };
  }
}

const prescription = new Prescription();
export default prescription;
