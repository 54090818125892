const formatDateAndTime = (inputDateString, is24HourFormat = true) => {
  const dateOptions = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  };

  const timeOptions = {
    hour: is24HourFormat ? 'numeric' : '2-digit',
    minute: '2-digit',
    hour12: !is24HourFormat, // This ensures 12-hour format
  };

  const formattedDate = new Date(inputDateString).toLocaleDateString(undefined, dateOptions);
  const formattedTime = new Date(inputDateString).toLocaleTimeString('en-US', timeOptions);

  if (is24HourFormat) {
    return `${formattedDate} at ${formattedTime} hours`;
  } else {
    return `${formattedDate} at ${formattedTime}`;
  }
};

const formatDateAndTimeKeepTimezone = (inputDateString, is24HourFormat = true) => {
  // Definir la expresión regular para extraer los componentes de fecha y hora
  const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).\d+\+\d{2}:\d{2}$/;

  // Extraer los componentes de fecha y hora usando la expresión regular
  const matches = inputDateString.match(regex);
  if (!matches) {
    return 'Invalid input date string';
  }

  // Obtener componentes de fecha
  const year = matches[1];
  const month = matches[2];
  const day = matches[3];

  // Obtener componentes de hora
  let hours = parseInt(matches[4]);
  const minutes = matches[5];
  const timeFormat = is24HourFormat ? 'hours' : '12-hours';

  // Convertir a formato de 12 horas si es necesario
  if (!is24HourFormat) {
    const amPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convertir 0 a 12 en formato de 12 horas
    return `${month}/${day}/${year} at ${hours}:${minutes} ${amPm}`;
  }

  return `${month}/${day}/${year} at ${hours}:${minutes} hours`;
};

const functions = {
  formatDateAndTime,
  formatDateAndTimeKeepTimezone
}

export default functions