import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslation } from "react-i18next";

export const PatientPicker = ({
  patients,
  setPatient,
  getPatientCitations,
  picked,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="form-group">
        <Autocomplete
          disabled={picked != null}
          className="invoice-patient-select"
          id="patient"
          name="patient"
          placeholder={t("SelectLabel.Select a patient")}
          options={patients}
          onChange={(event, value) => {
            var fieldValue = value;
            if (!fieldValue) {
              fieldValue = null;
            } else getPatientCitations(value);
          }}
          getOptionLabel={(option) =>
            option["lastname"] + ", " + option["firstname"]
          }
          inputValue={patients["id"]}
          renderInput={(params) => (
            <TextField
              placeholder={t("SelectLabel.Select a patient")}
              variant="outlined"
              {...params}
            />
          )}
          style={{ width: "100%" }}
        />
      </div>
    </>
  );
};

export default PatientPicker;
