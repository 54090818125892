import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { useBeforeunload } from "react-beforeunload";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import edit_clock from "../../../assets/icons/edit_clock.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Accordion, Card, Button } from "react-bootstrap";
import GetUserToken from "../../../utils/GetUserToken";
import Alert from "../../alerts/Alert";

const Attention = ({ id, patientID, handleCallbackAttention }) => {
  const { t } = useTranslation();

  const userToken = GetUserToken();

  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const [last, setLast] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [save, setSave] = useState(false);
  const [fieldOne, setFieldOne] = useState("");
  const [hovering, setHovering] = useState(false);

  useBeforeunload((event) => {
    if (save !== false) {
      event.preventDefault();
    }
  });

  const fetchData = async () => {
    handleCallbackAttention([false, "No changes"]);
    setLoading(true);

    var requestOptions = {
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL +
      `/citations/getAttention?patient_id=` +
      patientID,
      requestOptions
    );

    const json = await res.json();

    let data = json.attention;
    let filteredData = data.filter((element) => element[2] !== null);
    setFiltered(filteredData);

    let pulseData = data.find((element) => element[2] !== null);
    setLast(pulseData);

    if (pulseData) {
      handleCallbackAttention([true, pulseData[1]]);
      setValid(true);
      let values = pulseData[2];
      setFieldOne(values);

      setLoading(false);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    const body = { attention: fieldOne };
    fetch(process.env.REACT_APP_API_URL + `/citations/update/` + id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
        setSave(false);
        setLoading(false);
        fetchData();
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.Something went wrong, please try again"),
        });
        setLoading(false);
      });
  };

  const handleDateChange = (e) => {
    let values = e.target.value[2];
    setFieldOne(values);

    setLast(e.target.value);
    setSave(true);
  };

  return (
    <>
      <Accordion style={{ paddingRight: "0", paddingLeft: "0" }}>
        <Card
          className="reminders-cards"
          style={{ width: "100%", borderRadius: "10px" }}
        >
          <Card.Header
            className="reminders-subtitle"
            style={{
              border: "none",
              background: "white",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "space-between",
              paddingRight: "0",
              paddingLeft: "0",
            }}
          >
            <div>
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="0"
                className="text-center subtitles-patients"
                style={{ textDecoration: "none" }}
                id="accordion_recommendations"
              >
                <span>&#8595; &nbsp;</span>
                {t("CompleteAppointment.Danger")}:
              </Accordion.Toggle>
              <span className="subtitles-patients border-start">
                <img
                  src={edit_clock}
                  alt="clock"
                  onMouseEnter={() => setHovering(true)}
                  onMouseLeave={() => setHovering(false)}
                />
                {loading ? (
                  <></>
                ) : (
                  <>
                    {filtered.length != 0 ? (
                      <>
                        <Select
                          id="treatment"
                          name="treatment"
                          value={last}
                          onChange={handleDateChange}
                          variant="standard"
                        >
                          {filtered.map((item, i) => (
                            <MenuItem key={`attention-menu-item-${i}`} value={item}>
                              <b>{item[0].split("T")[0]}&nbsp; /&nbsp;</b>
                              {item[1].replace("T", " at: ").slice(0, -8)}
                              &nbsp;&nbsp;
                            </MenuItem>
                          ))}
                        </Select>
                        {hovering && (
                          <span className="key-text-box">
                            <b> {t("CompleteAppointment.Reference1")}</b>
                            {t("CompleteAppointment.Reference2")}
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        <span className="key-text-box">
                          &nbsp;{t("Messages.No previous records")}
                        </span>
                      </>
                    )}
                  </>
                )}
              </span>
            </div>
            <div>
              {save == false ? (
                <></>
              ) : (
                <button
                  className="button-citation-box-2-save"
                  onClick={handleSubmit}
                  style={{ padding: "2px" }}
                >
                  {t("Buttons.Save")}
                </button>
              )}
            </div>
          </Card.Header>

          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <form
                onSubmit={handleSubmit}
                style={{
                  background: "#f4f5fa",
                  borderRadius: "10px",
                  padding: "10px 30px",
                }}
              >
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <CircularProgress color="secondary" />
                  </div>
                ) : (
                  <>
                    <div className="row">
                      <div className="col mb-2 mt-2">
                        <Card style={{ width: "auto" }}>
                          <Card.Body>
                            <Card.Text>
                              <TextareaAutosize
                                value={fieldOne}
                                onChange={(e) => {
                                  setFieldOne(e.target.value);
                                  setSave(true);
                                }}
                                aria-label="minimum height"
                                minRows={6}
                                style={{ width: "-webkit-fill-available" }}
                              />
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                  </>
                )}
              </form>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      {isAlert.open && (
        <Alert
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
    </>
  );
};

export default Attention;
