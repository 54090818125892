import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import PrescriptionForm from "../components/Form";
import urlHandlers from "../../../helpers/url";
import {
  createOnePrescription,
  resetCreateOnePrescription,
} from "../../../redux/slices/prescription";
import AlertComponent from "../../../components/alerts/Alert";

const keys = {
  patient_id: "",
  doctor_id: "",
  company_id: "",
  citation_id: "",
  state: "",
  delivery_method: "",
  prescription_id: "",
  prescription_type: "",
  pharmacy_comments: "",
  patient_comments: "",
  days: 1,
  created_at: "",
  blends: "",
};

const Component = ({
  prescriptionCreate,
  prescriptionCreateLoading,
  prescriptionCreateError,
  prescriptionCreateSuccess,
  currentUser,
  currentUserDoctorProfile,
  pharmacies,
  infoRoute,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { history } = infoRoute;
  const [initialValues, setInitialValues] = useState(keys);
  const [idCitation, setIdCitation] = useState(null);
  const [validated, setValidated] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const isValid = (formElements) => {
    let isValid = true;

    // Iterate over form elements and check validity
    for (let i = 0; i < formElements.length; i++) {
      const element = formElements[i];

      // Check if the element is required and if its value is empty
      if (element.required && !element.value.trim()) {
        isValid = false;
        break; // Break the loop early if any required field is empty
      }
    }

    return isValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    if (isValid(form.elements)) {
      // Get all form elements
      var formElements = event.target.elements;

      // Create an object to store form values
      var formData = {};

      // Iterate over form elements and populate formData object
      for (var i = 0; i < formElements.length; i++) {
        var element = formElements[i];

        if (element.name) {
          let value = element.value;

          if (element.name === "pharmacy_id") {

            const pharmacy = pharmacies?.data?.find(

              (pharmacy) => pharmacy?.id === Number(value)
            );
            formData["pharmacy"] = pharmacy;
          } else {
            formData[element?.name] = value;
          }
        }
      }

      // Format the blend property as an array
      formData = formatArray(formData);

      // Save id of citation for redirection
      setIdCitation(formData["citation_id"]);

      // Dispatch action with formatted data
      dispatch(createOnePrescription(formData));
    } else {
      setValidated(true);
    }
  };

  // Function to format blend as an array
  const formatArray = (formData) => {
    // Crear un array para almacenar los blends
    const cleanedFormData = {
      blends: [],
    };

    Object.keys(formData).forEach((key) => {
      // Check if the key starts with "blends"
      if (key.startsWith("blends")) {
        // Extraer el índice del blend del nombre de la clave
        const blendIndex = key.split(".")[1];

        // Si no existe un objeto para este blendIndex, inicializarlo
        if (!cleanedFormData?.blends[blendIndex]) {
          cleanedFormData.blends[blendIndex] = { id: null, herbs: [] };
        }

        // Si la clave es para el id del blend, actualizar el id
        if (key.endsWith("id")) {
          cleanedFormData.blends[blendIndex].id = formData[key]
            ? Number(formData[key])
            : null;
        }

        // Si la clave es para una hierba, agregarla al array de hierbas
        if (key.includes("herbs")) {
          // Extraer el índice de la hierba del nombre de la clave
          const herbIndex = key.split(".")[3];
          // Obtener el nombre de la propiedad (herb, pao, qty, orig)
          const property = key.split(".")[4];
          // Si no existe un objeto para esta herbIndex, inicializarlo
          if (!cleanedFormData.blends[blendIndex].herbs[herbIndex]) {
            cleanedFormData.blends[blendIndex].herbs[herbIndex] = {};
          }
          // Establecer el valor en el objeto herbs
          cleanedFormData.blends[blendIndex].herbs[herbIndex][property] =
            formData[key];
        }
      } else {
        cleanedFormData[key] = formData[key];
      }
    });

    return cleanedFormData;
  };

  useEffect(() => {
    setInitialValues((oldState) => ({
      ...oldState,
      ...urlHandlers.getParamsFromUrl(window.location.href),
      ...(currentUser?.roles?.isDoctor && {
        doctor_id: currentUserDoctorProfile?.id,
      }),
    }));
  }, [currentUser, currentUserDoctorProfile]);

  useEffect(() => {
    if (prescriptionCreateError) {
      setAlert({
        open: true,
        severity: "error",
        message: t("Messages.The operation could not be processed"),
      });
    }
  }, [prescriptionCreateError]);

  useEffect(() => {
    if (prescriptionCreateSuccess) {
      setAlert({
        open: true,
        severity: "success",
        message: t("Messages.The operation was successful"),
      });
      setTimeout(() => {
        history.push(
          idCitation
            ? `/completeappointment/${btoa(idCitation)}`
            : "/prescription"
        );
      }, 1000);
    }
  }, [prescriptionCreateSuccess]);

  useEffect(() => {
    if (prescriptionCreateError || prescriptionCreateSuccess) {
      setTimeout(() => {
        dispatch(resetCreateOnePrescription());
        setAlert({
          open: false,
          severity: "",
          message: "",
        });
      }, 3000);
    }
  }, [prescriptionCreateError, prescriptionCreateSuccess]);

  return (
    <>
      <h4 className="main-title-treatments">
        {t("PrescriptionNew.New Prescription")}
      </h4>
      <br />
      <Form
        id="new-prescription-form"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        style={{ width: "95%" }}
      >
        <PrescriptionForm
          validated={validated}
          initialValues={initialValues}
          isLoading={prescriptionCreateLoading}
        />
        <div className="d-flex justify-content-end">
          <Button
            className="btnGo"
            form="new-prescription-form"
            type="submit"
            target={false}
            disabled={prescriptionCreateLoading}
          >
            {t("PrescriptionNew.Create Prescription")}
          </Button>
        </div>
      </Form>

      <AlertComponent
        isAlert={alert.open}
        setIsAlert={setAlert}
        severity={alert.severity}
        message={alert.message}
      />
    </>
  );
};

const state = ({ prescriptionStore, user: userStore, pharmacyStore }) => {
  const {
    data: prescriptionCreate,
    loading: prescriptionCreateLoading,
    error: prescriptionCreateError,
    success: prescriptionCreateSuccess,
  } = prescriptionStore.create;

  const {
    data: pharmacies,
    loading: pharmaciesLoading,
    error: pharmaciesError,
    success: pharmaciesSuccess,
  } = pharmacyStore.all;

  const { auth: currentUser, doctor: currentUserDoctorProfile } = userStore;

  return {
    prescriptionCreate,
    prescriptionCreateLoading,
    prescriptionCreateError,
    prescriptionCreateSuccess,
    currentUser,
    currentUserDoctorProfile,
    pharmacies,
  };
};

export default connect(state)(Component);
