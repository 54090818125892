import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import services from "../../services";

const initialState = {
  auth: null,
  doctor: null,
  token: null,
  update: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
};

// Define an asynchronous thunk to fetch user's doctor
const getCurrentUserDoctorProfile = createAsyncThunk(
  "user/getCurrentUserDoctorProfile",
  async (payload, thunkAPI) => {
    try {
      // Make an API call to fetch user's doctor based on the userId
      const { response, error } = await services.doctor.getByUserId(payload);

      if (error) {
        throw new Error(error);
      }

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Async action to update a user
const updateUser = createAsyncThunk("user/update", async (payload) => {
  console.log("update - user - action", payload);
  const { response, error } = await services.user.update(payload);
  if (error) {
    throw new Error(error);
  }
  return response;
});

//define props? and methods with reducers
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    authCheck: (state, action) => {
      state.auth = action.payload;
    },
    doctor: (state, action) => {
      state.doctor = action.payload;
    },
    tokenData: (state, action) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentUserDoctorProfile.fulfilled, (state, action) => {
        // Update the state with the fetched doctor data
        state.doctor = action.payload;
      })
      .addCase(updateUser.pending, (state) => {
        state.update.data = null;
        state.update.loading = true;
        state.update.success = false;
        state.update.error = false;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.update.data = action.payload;
        state.update.loading = false;
        state.update.success = true;
        state.update.error = false;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.update.data = null;
        state.update.loading = false;
        state.update.success = false;
        state.update.error = action.error.message;
      });
  },
});

// Export the reducers as named exports
export const { authCheck, tokenData, doctor } = userSlice.actions;

// Export the async actions
export { getCurrentUserDoctorProfile, updateUser };

// Export the slice reducer as the default export
export default userSlice.reducer;
