import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ReactComponent as Pencil } from "../../assets/icons/pencil.svg";
import GetUserToken from "../../utils/GetUserToken";
import { TrackableLink } from "../../utils/helper";
import Table from "./AdditionalTable";

const AdittionalsList = () => {
  // translation
  const { t } = useTranslation();
  let currentLanguage = localStorage.getItem("i18nextLng");
  // auth
  const userToken = GetUserToken();
  // utils
  const [loading, setLoading] = useState(true);
  // data
  const [activeBundles, setActiveBundles] = useState([]);
  const [archivedBundles, setArchivedBundles] = useState([]);

  const columns = useMemo(
    () => [
      {
        // first group
        Header: " ",
        // First group columns
        columns: [
          {
            Header: "ID",
            accessor: "id",
          },
          {
            Header: <>{t("AdditionalList.Fee")} Nr.</>,
            accessor: "rate",
          },
          {
            Header: <>{t("AdditionalList.Title")}</>,
            accessor: "title",
          },
          {
            Header: <>{t("AdditionalList.Products")}</>,
            accessor: "adittional_products",
            Cell: ({ row }) => (
              <>
                {row.original.adittional_products ? (
                  JSON.parse(row.original.adittional_products).map(
                    (product, index) => (
                      <span
                        key={index}
                        className="badge rounded-pill product-rectangle"
                      >
                        {product.title}
                      </span>
                    )
                  )
                ) : (
                  <></>
                )}
              </>
            ),
          },
          {
            Header: <>{t("AdditionalList.Active")}</>,
            accessor: "archived",
            Cell: ({ row }) => (
              <>
                {row.original.archived ? (
                  <>{t("AdditionalList.No")}</>
                ) : (
                  <>{t("AdditionalList.Yes")}</>
                )}
              </>
            ),
          },
          {
            Header: " ",
            accessor: " ",
            Cell: ({ row }) => (
              <Link
                to={`/edit-adittional/${row.original.id}`}
                id="edit_bundle"
                onClick={() => TrackableLink("edit_bundle")}
              >
                <Pencil className="icon" />
              </Link>
            ),
          },
        ],
      },
    ],
    [currentLanguage]
  );

  const fetchData = async () => {
    try {
      setLoading(true);
      const url = process.env.REACT_APP_API_URL_V2 + "/bundles/index";
      var requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        redirect: "follow",
      };

      const res = await fetch(url, requestOptions);
      const json = await res.json();
      const active_bundles = json.filter((bundle) => !bundle.archived);
      const archived_bundles = json.filter((bundle) => bundle.archived);
      setActiveBundles(active_bundles);
      setArchivedBundles(archived_bundles);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="frame">
      <h4 className="main-title mt-2">
        {t("AdditionalList.Adittional Products")}
      </h4>
      <br />

      <div style={{ marginRight: "50px" }}>
        <Tabs defaultActiveKey="active_bundles" className="mb-3">
          <Tab
            eventKey="active_bundles"
            title={t("AdditionalList.Active Additional Products")}
          >
            <div className="container-fluid treatments-table">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <Table columns={columns} data={activeBundles} />
              )}
            </div>
          </Tab>
          <Tab
            eventKey="archived_bundles"
            title={t("AdditionalList.Archived Additional Products")}
          >
            <div className="container-fluid treatments-table">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <Table columns={columns} data={archivedBundles} isArchived />
              )}
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default AdittionalsList;
