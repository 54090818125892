import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../../assets/stylesheets/completeappointment.css";
import { Link } from "react-router-dom";

export const PatientInfo = ({
  citation,
  saved,
  action,
  editor,
  editing,
  onSubmit,
}) => {
  const { t, i18n } = useTranslation();
  let language = i18n.language;

  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  var options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const dateFormat = (language, date) => {
    let arrayDate = date.split("-");
    let formattedDate =
      language === "en"
        ? `${arrayDate[1]}/${arrayDate[2]}/${arrayDate[0]}`
        : arrayDate.reverse().join("/");
    return formattedDate;
  };

  useEffect(() => {
    if (action == "no_data" || editor == "no_data") {
      setMessage(
        t("PatientInfo.Sorry, we cannot get history for this appointment")
      );
      setValid(false);
    } else {
      const today = new Date().toLocaleDateString("en-US");
      const edition = new Date(action.created_at).toLocaleDateString("en-US");
      if (today == edition) {
        var hours = Math.abs(new Date() - new Date(action.created_at)) / 36e5;
        if (hours < 1) {
          setMessage(
            t("PatientInfo.Last edit was made recently by") +
              " " +
              editor.fullname
          );
        } else {
          setMessage(
            t("PatientInfo.Last edit was made") +
              " " +
              hours.toFixed(0) +
              " " +
              t("PatientInfo.hour(s) ago by") +
              " " +
              editor.fullname
          );
        }
      } else {
        const edited_at = new Date(action.created_at).toLocaleDateString(
          language,
          options
        );
        setMessage(
          t("PatientInfo.Last edit was on") +
            " " +
            edited_at +
            " " +
            t("PatientInfo.by") +
            " " +
            editor.fullname
        );
      }
      setValid(true);
    }
    setLoading(false);
  }, [language]);

  return (
    <div style={{ marginBottom: "20px" }}>
      <Link
        className="blue-anchors"
        target="_blank"
        to={{ pathname: "/patients-profile/" + `${citation.patient_id}` }}
      >
        <span className="patient-name-span blue-anchors">
          {citation.firstname}&nbsp;{citation.lastname}&nbsp;&gt;
        </span>
      </Link>
      <span className="patient-date-span">
        &nbsp;{t("PatientInfo.Appointment")}&nbsp;
        {dateFormat(language, citation.time.split("T")[0])}
      </span>
      &nbsp; / &nbsp;
      <span className="patient-edit-history">
        {editing ? (
          <>
            {t("PatientInfo.Editing patient file")}... &nbsp;&nbsp;
            <span className="blue-anchors-save" onClick={onSubmit}>
              {t("Buttons.Save")}
            </span>{" "}
            &nbsp;&nbsp;
          </>
        ) : (
          message
        )}
      </span>
      &nbsp;&nbsp;
      <a className="blue-anchors-save" href={"mailto:" + citation.email}>
        {t("PatientInfo.Send email to patient")}
      </a>
      <br />
      <Link to="/calendar" style={{ textDecoration: "none", zIndex: "99999" }}>
        <span className="patient-back-span">
          &lt;&nbsp;{t("PatientInfo.Back to calendar")}
        </span>
      </Link>
    </div>
  );
};
