import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Cards from "./homepageViews/homepageCards";
import Footer from "./homepageViews/footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/stylesheets/homepage.css";
import HomeReminders from "./homepageViews/homepageReminders";
import ButtonGroupComponent from "./reusableComponents/ButtonGroup";

const HomeSecretary = ({ userId }) => {
  // translations
  const { t, i18n } = useTranslation();
  // redux
  const state = useSelector((state) => state.user);
  // reminders
  const [updateReminders, setUpdateReminders] = useState(false);

  useEffect(() => {
    if (state.auth.language) {
      switch (state.auth.language) {
        case "EN":
          i18n.changeLanguage("en");
          break;
        case "ES":
          i18n.changeLanguage("es");
          break;
        default:
          i18n.changeLanguage("de");
          break;
      }
    }
  }, []);

  return (
    <>
      {/* Reminder and Support */}
      <ButtonGroupComponent
        route="homeSecretary"
        visible
        setUpdateReminders={setUpdateReminders}
      />

      <div className="frame">
        <h4 className="main-title mt-2">
          {state.auth ? (
            <div>
              {t("Home.0")}, {state.auth?.fullname}
            </div>
          ) : (
            <></>
          )}
        </h4>

        {state.doctor?.id ? (
          <Cards id={userId} />
        ) : (
          <>
            {" "}
            <p>Assistant dashboard</p>
          </>
        )}

        <h3 className="home-reminders-title">{t("Home.2")}</h3>

        <div className="home-reminders-box">
          <HomeReminders updateReminders={updateReminders} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HomeSecretary;
