import React from "react";
import { useTranslation } from "react-i18next";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FormControl } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { es, de, enUS } from "date-fns/locale";
import useStyles from "./styles";

const DateSelect = ({ dateSelector, disableWeekends, handleDateChange }) => {
  const { i18n } = useTranslation();
  let localizer =
    i18n.language === "es" ? es : i18n.language === "en" ? enUS : de;
  // styles
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <FormControl className={classes.formControl}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localizer}>
          <DatePicker
            autoOk
            variant="static"
            orientation="portrait"
            openTo="date"
            id="date"
            label="Appointment Date"
            name="date"
            onChange={handleDateChange}
            value={dateSelector}
            animateYearScrolling
            shouldDisableDate={disableWeekends}
            minDate={new Date()}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
    </div>
  );
};

export default DateSelect;
