import React from 'react'
import { useTranslation } from 'react-i18next';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Calendar, momentLocalizer } from "react-big-calendar";


export const TimeLockerCalendar = ({ handleDoctorFilterChange, doctors, handleSelect, localizer, filter_doctor_id, events, filtered, handleOpen, eventStyleGetter, filterEventsToShow }) => {
    const { t } = useTranslation();

    return (
        <>
            <div>
            <FormControl className="filter">
                <InputLabel>{t('Calendar.Filter by Therapist')}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={handleDoctorFilterChange}
                    defaultValue = ""
                >
                    <MenuItem value={null}>{t('Calendar.Select')}</MenuItem>
                    { doctors.map( doctor => <MenuItem key={doctor.id} value={doctor.id}>{doctor.name}</MenuItem>)}
                </Select>
            </FormControl>
        </div>
        <br/>       
        <Calendar
            step={15}
            selectable
            onSelectSlot={handleSelect}
            popup
            localizer={localizer}
            defaultDate={new Date()}
            defaultView="week"
            // events={filter_doctor_id == null ? events : filtered}
            events={filterEventsToShow()}
            style={{ height: "100vh", width: "100%", background: 'white' }}
            onSelectEvent={handleOpen}
            eventPropGetter={eventStyleGetter}
            min={new Date(0, 0, 0, 6, 0, 0)}
            max={new Date(0, 0, 0, 21, 0, 0)}
            longPressThreshold={10}
            messages={{
                today: t('Calendar.Today'),
                previous: t('Calendar.Back'),
                next: t('Calendar.Next'),
                month: t('Calendar.Month'),
                week: t('Calendar.Week'),
                day: t('Calendar.Day'),
                agenda: t('Calendar.Agenda')
            }}
        />
        </>
    )
}

export default TimeLockerCalendar;
