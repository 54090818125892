import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import "../../assets/stylesheets/floatingButtons.css";
import { ReactComponent as Reminders } from "../../assets/icons/reminders.svg";
import NewReminder from "./NewReminder";
import AlertComponent from "../alerts/Alert";

export const NewReminderHelper = ({ setUpdateReminders }) => {
  // translations
  const { t } = useTranslation();

  // alert
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  //Create new reminder
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (setUpdateReminders) {
      setUpdateReminders(true);
    }
  };
  const handleShow = () => setShow(true);

  return (
    <>
      <div
        className="reminder-button"
        id="reminder_button"
        onClick={handleShow}
      >
        <Reminders className="icon" />
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ background: "#F4F5FA" }}>
          <Modal.Title>{t("NewReminderHelper.0")}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#F4F5FA" }}>
          <NewReminder handleClose={handleClose} setIsAlert={setIsAlert} />
        </Modal.Body>
      </Modal>

      {isAlert.open && (
        <AlertComponent
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
    </>
  );
};
