import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { Avatar, CircularProgress } from "@material-ui/core";
import PatientAppointments from "./PatientAppointments";
import PatientPrescriptions from "./PatientPrescriptions";
import PatientInvoices from "./PatientInvoices";
import Pencil from "../../assets/icons/blue_pencil.png";
import { EditPatient } from "./EditPatient";
import "../../assets/stylesheets/patients.css";
import GetUserToken from "../../utils/GetUserToken";
import AlertComponent from "../alerts/Alert";

const Component = (infoRoute) => {
  // auth
  const userToken = GetUserToken();
  // translations
  const { t, i18n } = useTranslation();
  let language = i18n.language;
  // redux
  const state = useSelector((state) => state.user);
  const user = state.auth;
  // route
  const { location, match } = infoRoute;
  const isInvoice = location.state ? true : false;
  // utils
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  // data
  const [data, setData] = useState([]);

  const dateFormat = (language, date) => {
    if (date !== null) {
      let arrayDate = date.split("-");
      let formattedDate =
        language === "en"
          ? `${arrayDate[1]}/${arrayDate[2]}/${arrayDate[0]}`
          : arrayDate.reverse().join("/");
      return formattedDate;
    }
    return;
  };

  const fetchData = async () => {
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL +
        "/patients/getPatientInfo?id=" +
        match.params.id,
      requestOptions
    );
    const json = await res.json();
    // console.log(json.patient);
    setData(json.patient);
  };

  useEffect(() => {
    if (state.auth.language) {
      switch (state.auth.language) {
        case "EN":
          i18n.changeLanguage("en");
          break;
        case "ES":
          i18n.changeLanguage("es");
          break;
        default:
          i18n.changeLanguage("de");
          break;
      }
    }
    fetchData();
  }, []);

  // Edit patient
  const [editshow, setEditShow] = useState(false);
  const handleEditClose = () => {
    setEditShow(false);
  };
  //--

  const wait = (
    <div className="d-flex justify-content-center">
      <CircularProgress color="secondary" />
    </div>
  );

  const all = useMemo(() => {
    return data.map((value, index) => (
      <>
        <div
          className="row patient-id-box"
          style={{ width: "95%", marginLeft: "0px" }}
        >
          <div
            className="col-3 d-flex flex-column align-items-center"
            key={index}
          >
            <Avatar className="mt-5 profile-avatar" />
            <p className="subtitles-patients mt-4">
              {value.lastname}, {value.firstname}
            </p>
            <p className="key-text-box">
              {t("PatientProfile.Patient")} ID: {value.id}
            </p>
          </div>
          <div className="col-9 border-start">
            <div className="row border-bottom mb-3">
              <div className="row">
                <div className="col-8">
                  <p className="subtitles-patients">
                    {t("PatientProfile.Personal Info")}
                  </p>
                </div>
                <div className="col-4 button-column">
                  <div className="key-text-box-edit-button d-flex justify-content-end">
                    <div
                      className="edit-patient-div"
                      onClick={() => setEditShow(true)}
                    >
                      <img
                        src={Pencil}
                        alt="Pencil"
                        id={value.id}
                        className="edit-patient-link"
                      />
                      <span className="key-text-box-edit" id={value.id}>
                        {t("PatientProfile.Edit patient")}&nbsp;&nbsp;&nbsp;
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <p>
                  <span className="key-text-box">
                    {t("EditPatient.Gender")}:{" "}
                  </span>
                  <span className="value-text-box">
                    {value.gender && t(`Genders.${value.gender}`)}
                  </span>
                </p>
                <p className="key-text-box">
                  {t("EditPatient.Birth")}:{" "}
                  <span className="value-text-box">
                    {dateFormat(language, value.birthday)}
                  </span>
                </p>
              </div>
              <div className="col">
                {value.relationship == null || "" ? (
                  <p className="key-text-box">
                    {t("EditPatient.Relationship w/")}:
                  </p>
                ) : (
                  <p className="key-text-box">
                    {t("EditPatient.Relationship w/")}:{" "}
                    <span className="value-text-box">
                      <Link
                        target="_blank"
                        className="blue-anchors"
                        to={`/patients-profile/${value.relationship}`}
                      >
                        {value.relationshipName}
                      </Link>
                    </span>
                  </p>
                )}
                <p className="key-text-box">
                  {t("PatientProfile.Insurance")} ID:{" "}
                  <span className="value-text-box">{value.insurance}</span>
                </p>
              </div>
            </div>
            <p className="subtitles-patients">{t("EditPatient.Address")}</p>
            <div className="row border-bottom mb-3">
              <div className="col-3">
                <p className="key-text-box">
                  {t("EditPatient.Country")}:{" "}
                  <span className="value-text-box">{value.address}</span>
                </p>
              </div>
              <div className="col-2">
                <p className="key-text-box">
                  {t("EditPatient.City")}:{" "}
                  <span className="value-text-box">{value.city}</span>
                </p>
              </div>
              <div className="col-2">
                <p className="key-text-box">
                  {t("EditPatient.Zip")}:{" "}
                  <span className="value-text-box">{value.zip}</span>
                </p>
              </div>
              <div className="col-5">
                <p className="key-text-box">
                  {t("EditPatient.Street")}:{" "}
                  <span className="value-text-box">{value.street}</span>
                </p>
              </div>
            </div>
            <p className="subtitles-patients">{t("PatientProfile.Contact")}</p>
            <div className="row border-bottom mb-3">
              <div className="col">
                <p className="key-text-box">
                  {t("PatientProfile.Email")}:{" "}
                  <span className="value-text-box">{value.email}</span>
                </p>
              </div>
              <div className="col">
                <p className="key-text-box">
                  {t("EditPatient.Phone")}:{" "}
                  <span className="value-text-box">{value.phone}</span>
                </p>
              </div>
            </div>
            <p className="subtitles-patients">{t("PatientProfile.Other")}</p>
            <div className="row">
              <div className="col">
                <p className="key-text-box">
                  Praxis: <span className="value-text-box">{value.praxis}</span>
                </p>
              </div>
              <div className="col">
                <p className="key-text-box">
                  {t("EditPatient.Notes")}:{" "}
                  <span className="value-text-box">{value.notes}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <PatientAppointments id={match.params.id} />

        {user?.roles?.isDoctor && <PatientPrescriptions id={match.params.id} />}

        {user?.admin && <PatientInvoices id={match.params.id} />}
      </>
    ));
  }, [match, data, user]);

  return (
    <>
      <div className="frame">
        <h2 className="subtitles-patients">{t("PatientProfile.Patient")} ID</h2>
        <p className="blue-anchors">
          <Link
            className="blue-anchors"
            to={
              isInvoice
                ? `/create-invoice/${location.state.idInvoice}`
                : "/patients"
            }
          >
            &#60; {t("Buttons.Back")}
          </Link>
        </p>

        {data.length > 0 ? all : wait}

        <Modal
          show={editshow}
          onHide={handleEditClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Header style={{ background: "#F4F5FA" }}>
            <Modal.Title>
              {t("PatientProfile.Edit patient")} # {match.params.id}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ background: "#F4F5FA" }}>
            <EditPatient
              id={match.params.id}
              infoRoute={infoRoute}
              handleeditClose={handleEditClose}
              handleUpdate={fetchData}
              setIsAlert={setIsAlert}
            />
          </Modal.Body>
        </Modal>

        {isAlert.open && (
          <AlertComponent
            isAlert={isAlert}
            setIsAlert={setIsAlert}
            severity={isAlert.severity}
            message={isAlert.message}
          />
        )}
      </div>
    </>
  );
};

export default Component;
