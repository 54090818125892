import React from "react";
import CompanyForm from "../components/CompanyForm";
import CompanyPhoneForm from "../components/FormCompanyPhone";
import CompanyEmailForm from "../components/FormCompanyEmail";
import BankAccountForm from "../components/FormBankAccount";
import { Row, Col, Container } from "react-bootstrap";

const Component = ({ isOnboarding = false, handleStepComplete = () => {} }) => {
  return (
    <>
      {!isOnboarding ? (
        <div className="frame pb-3">
          <Container fluid>
            <Row>
              <Col sm={4}>
                <CompanyForm />
              </Col>
              <Col sm={1}></Col>
              <Col sm={4}>
                <Row style={{ marginBottom: 100 }}>
                  <Col>
                    <CompanyEmailForm />
                  </Col>
                </Row>
                <Row style={{ marginBottom: 100 }}>
                  <Col>
                    <CompanyPhoneForm />
                  </Col>
                </Row>
                <Row style={{ marginBottom: 100 }}>
                  <Col>
                    <BankAccountForm />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <div style={{ padding: "40px" }}>
          <Container fluid>
            <Row>
              <Col sm={12} className="px-4">
                <CompanyForm
                  isOnboarding={isOnboarding}
                  handleStepComplete={handleStepComplete}
                />
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default Component;
