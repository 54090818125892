import GetUserToken from "../utils/GetUserToken";
import { parseJSON } from "../utils/parseJSON";

const urlBase = `${process.env.REACT_APP_API_URL_V2}/citations`;
const readUrl = (url = "") => `${urlBase}/${url}`;

class Citation {
  async getOne({ id }) {
    console.log("getOne - citations - service", id);
    const auth = `${GetUserToken()}`;

    let res = await fetch(readUrl(id), {
      method: "GET",
      headers: {
        Authorization: auth,
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
      },
    });

    res = await parseJSON(res);
    return res;
  }

  async getAll(payload) {
    console.log("getAll - citations - service");
    const auth = `${GetUserToken()}`;

    let res = await fetch(readUrl(), {
      method: "GET",
      headers: {
        Authorization: auth,
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
      },
    });

    res = await parseJSON(res);
    return res;
  }

  async createOne(payload) {
    console.log("createOne - citations - service", payload);

    const auth = `${GetUserToken()}`;

    let res = await fetch(readUrl(), {
      method: "POST",
      headers: {
        Authorization: auth,
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(payload),
    });

    res = await parseJSON(res);
    return res;
  }

  async update(payload) {
    const { id, ...rest } = payload;
    console.log("update - citations - service", payload);

    const auth = `${GetUserToken()}`;

    let res = await fetch(readUrl(id), {
      method: "PATCH",
      headers: {
        Authorization: auth,
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(rest),
    });

    res = await parseJSON(res);
    return res;
  }
}

const citation = new Citation();
export default citation;
