import React from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";

export const ForgotPasswordStep2 = ({
  password,
  setPassword,
  handleSubmit,
  loading,
}) => {
  // translations
  const { t } = useTranslation();

  return (
    <>
      <div className="mt-5">{t("ForgotPassword.9")}</div>

      <div className="form-group mt-4">
        <label className="title-inputs">{t("ForgotPassword.10")}</label>
        <br />
        <br />
        <input
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          className="input-control"
          placeholder={t("ForgotPassword.10")}
          required
        />
      </div>

      {loading ? (
        <div className="d-flex justify-content-center">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <>
          <button
            disabled={password === ""}
            type="submit"
            className={password === "" ? "btn-gray-save" : "blue-btn"}
            style={{ width: "100%" }}
            onClick={handleSubmit}
          >
            CONFIRM NEW PASSWORD
          </button>
        </>
      )}
    </>
  );
};

export default ForgotPasswordStep2;
