export const styles = {
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 200,
    background: '#333',
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    padding: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  sliderContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    alignItems: 'flex-start',
  },
  sliderLabel: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: 12,
    color: 'black',
    textTransform: 'uppercase'
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 32,
  },
}
