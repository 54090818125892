import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import FieldLoader from "../FieldLoader";
import { connect } from "react-redux";

const Component = ({
  isLoading,
  initialValues,
}) => {
  // translation
  const { t } = useTranslation();

  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    if (!event.target) {
      console.error("Event was not found.");
    }

    const { name, value, type, required } = event.target;
    console.log("handleChange", { name, value, type, required });

    setValues((prevValues) => {
      const newValues = { ...prevValues };

      if (value === "") {
        delete newValues[name];
      } else {
        newValues[name] = value;
      }

      console.log("handleChange", { newValues });
      return newValues;
    });

    // Validate the required field
    if (
      ((type === "text" || type === "textarea") &&
        value.trim() === "" &&
        required) ||
      (type === "file" && !value && required)
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "This field is required",
      }));
    } else {
      // Clear the error if the field is not empty
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  return (
    <>
      <Form.Group className="mb-3" controlId="name">
        <Form.Label>{t("PharmacyTable/Form.Name")}</Form.Label>
        <FieldLoader loading={isLoading}>
          <Form.Control
            required
            type="text"
            name="name"
            defaultValue={initialValues["name"]}
            onInput={handleChange}
            disabled={isLoading}
          />
        </FieldLoader>
        {!!errors["name"] && (
          <Form.Control.Feedback type="invalid">
            {t("Messages.Required field")}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group className="mb-3" controlId="street">
        <Form.Label>{t("PharmacyTable/Form.Street")}</Form.Label>
        <FieldLoader loading={isLoading}>
          <Form.Control
            required
            type="text"
            name="street"
            defaultValue={initialValues["street"]}
            onInput={handleChange}
            disabled={isLoading}
          />
        </FieldLoader>
        {!!errors["street"] && (
          <Form.Control.Feedback type="invalid">
            {t("Messages.Required field")}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group className="mb-3" controlId="number">
        <Form.Label>{t("PharmacyTable/Form.Number")}</Form.Label>
        <FieldLoader loading={isLoading}>
          <Form.Control
            type="text"
            name="number"
            defaultValue={initialValues["number"]}
            onInput={handleChange}
            disabled={isLoading}
          />
        </FieldLoader>
        {!!errors["number"] && (
          <Form.Control.Feedback type="invalid">
            {t("Messages.Required field")}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group className="mb-3" controlId="zip">
        <Form.Label>{t("PharmacyTable/Form.Zip code")}</Form.Label>
        <FieldLoader loading={isLoading}>
          <Form.Control
            type="text"
            name="zip"
            defaultValue={initialValues["zip"]}
            onInput={handleChange}
            disabled={isLoading}
          />
        </FieldLoader>
        {!!errors["zip"] && (
          <Form.Control.Feedback type="invalid">
            {t("Messages.Required field")}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group className="mb-3" controlId="town">
        <Form.Label>{t("PharmacyTable/Form.Town")}</Form.Label>
        <FieldLoader loading={isLoading}>
          <Form.Control
            type="text"
            name="town"
            defaultValue={initialValues["town"]}
            onInput={handleChange}
            disabled={isLoading}
          />
        </FieldLoader>
        {!!errors["town"] && (
          <Form.Control.Feedback type="invalid">
            {t("Messages.Required field")}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group className="mb-3" controlId="province">
        <Form.Label>{t("PharmacyTable/Form.Province")}</Form.Label>
        <FieldLoader loading={isLoading}>
          <Form.Control
            type="text"
            name="province"
            defaultValue={initialValues["province"]}
            onInput={handleChange}
            disabled={isLoading}
          />
        </FieldLoader>
        {!!errors["province"] && (
          <Form.Control.Feedback type="invalid">
            {t("Messages.Required field")}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group className="mb-3" controlId="country">
        <Form.Label>{t("PharmacyTable/Form.Country")}</Form.Label>
        <FieldLoader loading={isLoading}>
          <Form.Control
            type="text"
            name="country"
            defaultValue={initialValues["country"]}
            onInput={handleChange}
            disabled={isLoading}
          />
        </FieldLoader>
        {!!errors["country"] && (
          <Form.Control.Feedback type="invalid">
            {t("Messages.Required field")}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group className="mb-3" controlId="country_code">
        <Form.Label>{t("PharmacyTable/Form.Country code")}</Form.Label>
        <FieldLoader loading={isLoading}>
          <Form.Control
            type="text"
            name="country_code"
            defaultValue={initialValues["country_code"]}
            onInput={handleChange}
            disabled={isLoading}
          />
        </FieldLoader>
        {!!errors["country_code"] && (
          <Form.Control.Feedback type="invalid">
            {t("Messages.Required field")}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group className="mb-3" controlId="phone_number">
        <Form.Label>{t("PharmacyTable/Form.Phone number")}</Form.Label>
        <FieldLoader loading={isLoading}>
          <Form.Control
            type="text"
            name="phone_number"
            defaultValue={initialValues["phone_number"]}
            onInput={handleChange}
            disabled={isLoading}
          />
        </FieldLoader>
        {!!errors["phone_number"] && (
          <Form.Control.Feedback type="invalid">
            {t("Messages.Required field")}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group className="mb-3" controlId="email">
        <Form.Label>{t("PharmacyTable/Form.Email")}</Form.Label>
        <FieldLoader loading={isLoading}>
          <Form.Control
            type="text"
            name="email"
            defaultValue={initialValues["email"]}
            onInput={handleChange}
            disabled={isLoading}
          />
        </FieldLoader>
        {!!errors["email"] && (
          <Form.Control.Feedback type="invalid">
            {t("Messages.Required field")}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group className="mb-3" controlId="web">
        <Form.Label>{t("PharmacyTable/Form.Web")}</Form.Label>
        <FieldLoader loading={isLoading}>
          <Form.Control
            type="text"
            name="web"
            defaultValue={initialValues["web"]}
            onInput={handleChange}
            disabled={isLoading}
          />
        </FieldLoader>
        {!!errors["web"] && (
          <Form.Control.Feedback type="invalid">
            {t("Messages.Required field")}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </>
  );
};

const state = () => {
  return {};
};

export default connect(state)(Component);
