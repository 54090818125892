import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import CircularProgress from "@material-ui/core/CircularProgress";
import GetUserToken from "../../utils/GetUserToken";

export const EditDiagnosis = ({
  updateDiagnosis,
  idDiagnosis,
  setErrorUpdating,
  setSuccessUpdating,
  setShowupdatemodal,
}) => {
  // translation
  const { t } = useTranslation();
  // auth
  const userToken = GetUserToken();

  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    var requestOptions = {
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
    };
    const res = await fetch(
      process.env.REACT_APP_API_URL + "/diagnosis/show/" + idDiagnosis,
      requestOptions
    );
    const json = await res.json();
    setCode(json.code);
    setName(json.name);
  };

  const handleSubmit = async () => {
    setSaving(true);

    const body = {
      code: code,
      name: name,
    };

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      body: JSON.stringify(body),
    };

    await fetch(
      process.env.REACT_APP_API_URL + "/diagnosis/update/" + idDiagnosis,
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          setSuccessUpdating(true);
          updateDiagnosis();
        } else {
          setErrorUpdating(true);
        }
        setShowupdatemodal(false);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      <div className="form-group">
        <label className="title-inputs">{t("Diagnosis.Code")}</label>
        <input
          required
          type="text"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          className="input-control"
        />
      </div>
      <div className="form-group">
        <label className="title-inputs">{t("Diagnosis.Name")}</label>
        <TextareaAutosize
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          aria-label="minimum height"
          minRows={3}
          className="input-control"
        />
      </div>
      <div className="d-flex justify-content-center">
        {saving ? (
          <>
            <CircularProgress color="secondary" />
          </>
        ) : (
          <>
            {code != "" ? (
              <>
                <button className="blue-btn" onClick={handleSubmit}>
                  {t("Buttons.Save")}
                </button>
              </>
            ) : (
              <>
                <button disabled className="blue-btn-disabled">
                  {t("Buttons.Save")}
                </button>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default EditDiagnosis;
