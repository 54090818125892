import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@material-ui/core";
import Alert from "../../alerts/Alert";
import GetUserToken from "../../../utils/GetUserToken";

const UserAccount = ({ patientId, userId, infoRoute }) => {
  // auth
  const userToken = GetUserToken();
  // translations
  const { t } = useTranslation();
  // route
  const history = infoRoute.history;
  // utils
  const [loading, setLoading] = useState(false);
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  // data
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  // const [currentPassword, setCurrentPassword] = useState("");

  const fetchPatientData = async () => {
    try {
      setLoading(true);
      var requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        redirect: "follow",
      };
      const res = await fetch(
        `
      ${process.env.REACT_APP_API_URL}/patients/getPatientInfo?id=${patientId}`,
        requestOptions
      );
      const json = await res.json();
      setEmail(json.patient[0].email);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserData = async () => {
    try {
      setLoading(true);
      var requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        redirect: "follow",
      };
      const res = await fetch(
        `
      ${process.env.REACT_APP_API_URL_V2}/users/show/${userId}`,
        requestOptions
      );
      const json = await res.json();
      setEmail(json.email);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // Post method
  const handleSubmit = () => {
    if (!email || !password || !passwordConfirmation) {
      setIsAlert({
        open: true,
        severity: "warning",
        message: t("Messages.You must complete the required fields"),
      });
      return;
    }
    if (password !== passwordConfirmation) {
      setIsAlert({
        open: true,
        severity: "warning",
        message: t("Messages.Please enter your password again"),
      });
      return;
    }
    setLoading(true);

    const url = `${process.env.REACT_APP_API_URL}/users/resetPassword?email=${email}&password=${password}&api_key=qiXpoint@tcm`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          setIsAlert({
            open: true,
            severity: "success",
            message: t("Messages.The operation was successful"),
          });
          setTimeout(() => {
            history.push("/");
          }, 1000);
          setLoading(false);
        } else {
          setIsAlert({
            open: true,
            severity: "error",
            message: t("Messages.The operation could not be processed"),
          });
          setLoading(false);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (patientId) {
      fetchPatientData();
    } else {
      fetchUserData();
    }
  }, []);

  return (
    <>
      <div className="container-fluid treatments-table mt-5 container-user-account">
        {loading ? (
          <div className="d-flex justify-content-center">
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <>
            <div className="form-group">
              <label htmlFor="password" className="reminders-subtitle">
                {t("UserProfile.Password")}
              </label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="input-control"
              />
            </div>

            <div className="form-group">
              <label
                htmlFor="passwordConfirmation"
                className="reminders-subtitle"
              >
                {t("UserProfile.Password Confirmation")}
              </label>
              <input
                type="password"
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                className="input-control"
              />
            </div>

            {/* <div className="form-group">
            <label htmlFor="currentPassword" className="reminders-subtitle">
              {t("UserProfile.Current Password")}
            </label>
            <input
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              className="input-control"
            />
          </div> */}

            <br />
            {/* <hr /> */}
            <br />

            {/* <h1 className="reminders-subtitle">
              {t("UserProfile.Cancel account")}
            </h1>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "30px",
              }}
            >
              <p className="key-text-box">
                {t("UserProfile.Are you sure you want to delete your account?")}
              </p>
              <button
                className="btnCancel"
                // onClick={() => handleSubmit()}
                style={{ width: "120px", fontSize: "12px" }}
              >
                {t("UserProfile.Delete Account")}
              </button>
            </div> */}

            <br />
            <hr />
            <br />
            <div className="d-flex justify-content-center">
              <Link to={"/"}>
                <button
                  className="btnCancel"
                  style={{ width: "120px", marginRight: "20px" }}
                >
                  {t("Buttons.Cancel")}
                </button>
              </Link>
              <div>
                <button
                  className="btnGo"
                  onClick={() => handleSubmit()}
                  style={{ width: "120px" }}
                >
                  {t("Buttons.Save")}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      {isAlert.open && (
        <Alert
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
    </>
  );
};

export default UserAccount;
