import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { LoginStep0 } from "./login/LoginStep0";
import { LoginStep1 } from "./login/LoginStep1";

const Component = ({ setValidToken }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [step, setStep] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);
  // data
  const [accessToken, setAccessToken] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [key, setKey] = useState("");
  const [code, setCode] = useState("");

  const TWO_MINUTES = 2 * 60 * 1000;

  // Function to calculate the remaining time and update state
  const calculateRemainingTime = () => {
    const lastAttempt = sessionStorage.getItem("loginAttemptTime");

    if (lastAttempt) {
      const now = new Date().getTime();
      const timeDiff = now - parseInt(lastAttempt, 10);

      if (timeDiff < TWO_MINUTES) {
        const timeLeft = Math.ceil((TWO_MINUTES - timeDiff) / 1000);
        setRemainingTime(timeLeft);
        return timeLeft;
      }
    }

    return 0;
  };

  // Function to check if the user is blocked based on remaining time
  const isBlocked = () => {
    const timeLeft = calculateRemainingTime();
    return timeLeft > 0;
  };

  const handlePreSubmit = async (e) => {
    e.preventDefault();

    if (isBlocked()) {
      setError(true);
      return;
    }

    setLoading(true);

    const credentials = {
      email,
      password,
    };

    const token = await fetch(
      process.env.REACT_APP_API_URL + "/users/prelogin",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(credentials),
      }
    ).then((result) => result.json());

    if (token.code == "400") {
      setKey(token.token);
      setAccessToken(token.access_token);
      setLoading(false);
      setStep(1);
      // Store the timestamp of the successful pre-login attempt
      sessionStorage.setItem(
        "loginAttemptTime",
        new Date().getTime().toString()
      );
    } else {
      setError(true);
      setLoading(false);
    }
  };

  const setAuth0Token = async () => {
    // console.log("setting auth0 token... :)");
    // const encrypted = await GetAuthToken();
    localStorage.setItem("auth", "encrypted.access_token");
    // dispatch(tokenData(encrypted));
    return true;
  };

  const setToken = (userToken) => {
    localStorage.setItem("token", JSON.stringify(userToken));
    return true;
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (key === code) {
      const credentials = {
        email,
        password,
      };

      const token = await fetch(
        process.env.REACT_APP_API_URL + "/users/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "api-key": process.env.REACT_APP_API_KEY,
            Authorization: accessToken,
          },
          body: JSON.stringify(credentials),
        }
      ).then((result) => result.json());

      if (token.code === "400") {
        await setAuth0Token();
        setLoading(false);
        setToken(token);
        setValidToken(token);
        // handleAuth0Login();
        if (window.location.pathname === "/login") {
          window.location.href = "/";
        }
      } else {
        setError(true);
        setLoading(false);
      }
    } else {
      setLoading(false);
      setError(true);
    }
  };

  return (
    <>
      {step === 0 && (
        <LoginStep0
          error={error}
          setError={setError}
          loading={loading}
          handlePreSubmit={handlePreSubmit}
          setEmail={setEmail}
          setPassword={setPassword}
          setStep={setStep}
        />
      )}

      {step === 1 && (
        <LoginStep1
          error={error}
          setError={setError}
          loading={loading}
          code={code}
          setCode={setCode}
          handleSubmit={handleSubmit}
          setEmail={setEmail}
          setPassword={setPassword}
          setStep={setStep}
        />
      )}

      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setError(false)}
          severity="error"
        >
          {t("Messages.Please wait")} {remainingTime}
          {t("Messages.seconds before trying again")}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default Component;
