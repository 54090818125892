import React from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button } from "@mui/material";

const ConfirmDeletionModal = ({
  resetDialog,
  setResetDialog,
  handleResetPrices,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={resetDialog} onClose={() => setResetDialog(false)}>
      <DialogTitle id="alert-dialog-title">
        {t("AdditionalList.Refresh bundle prices")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t(
            "AdditionalList.Are you sure you want to refresh all bundle prices? This means all custom prices you entered will disappear."
          )}
          <br />
          {t("AdditionalList.This operation cannot be undone")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setResetDialog(false)} color="primary">
          {t("Buttons.Cancel")}
        </Button>
        <Button onClick={() => handleResetPrices()} color="primary" autoFocus>
          {t("Buttons.Confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeletionModal;
