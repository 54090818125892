import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "react-bootstrap";
import Cropper from "react-easy-crop";
import getCroppedImg from "./helpers/cropImage";
import { styles } from "./styles";

const Component = ({
  name,
  defaultValue = "https://www.tcmassist.org/wp-content/uploads/2022/08/tcm-assist-logo.png",
  onInput,
  showZoomControl,
  required,
}) => {
  // translations
  const { t } = useTranslation();

  const [previewImage, setPreviewImage] = useState(defaultValue);
  const [croppedImage, setCroppedImage] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  async function blobUrlToFile(ext) {
    const response = await fetch(croppedImage);
    const blobData = await response.blob();
    const filename = `logo.${ext}`; // Set the desired filename
    const file = new File([blobData], filename, { type: `image/${ext}` });
    return file;
  }

  const handleChangeImage = async () => {
    const parts = selectedFile.type.split("/");
    const ext = parts[1];
    const file = await blobUrlToFile(ext);
    !!onInput && onInput({ target: { value: [file], name, required } });
  };

  const setDefaultImageAsSelectedFile = async () => {
    if (typeof defaultValue === 'string') {
      const parts = defaultValue?.split(".");
      const ext = parts[parts.length - 1];
      const file = await blobUrlToFile(ext);
      setSelectedFile(file);
    }
  }

  const handleImageUpload = (e) => {
    const uploadedFile = e.target.files[0];

    if (uploadedFile) {
      if (
        (uploadedFile.type === "image/png" ||
          uploadedFile.type === "image/jpeg") &&
        uploadedFile.size <= 3 * 1024 * 1024 // 3MB
      ) {
        setSelectedFile(uploadedFile);
        setPreviewImage(URL.createObjectURL(uploadedFile));
      } else {
        alert("Please upload a PNG or JPEG image with a maximum size of 3MB.");
      }
    }
  };

  const saveCroppedImage = useCallback(async () => {
    try {
      if (previewImage) {
        const croppedImage = await getCroppedImg(
          previewImage,
          croppedAreaPixels,
          rotation
        );
        setCroppedImage(croppedImage);
        setShowModal(false);
      }
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation, previewImage]);

  useEffect(() => {
    if (!!croppedImage) {
      handleChangeImage();
    }
  }, [croppedImage]);

  useEffect(() => {
    if (!!defaultValue) {
      setDefaultImageAsSelectedFile();
    }
  }, [defaultValue]);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img
          src={
            croppedImage ? croppedImage : defaultValue
          }
          alt="Logo"
          style={{ width: 200, height: 200, borderRadius: "100%" }}
        />
        <input
          hidden
          id="upload-file"
          type="file"
          accept="image/jpeg, image/png"
          onChange={handleImageUpload}
        />

        <div style={{ height: 15 }} />

        <Button size="sm" type="button" onClick={() => setShowModal(true)}>
          {t("ImageCrop.Change logo")}
        </Button>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>{t("ImageCrop.Select a new logo")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: 200,
              background: "#333",
            }}
          >
            <Cropper
              image={previewImage}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={showZoomControl && setZoom}
            />
          </div>

          <div style={styles.controls}>
            {showZoomControl && (
              <div style={styles.sliderContainer}>
                <p variant="overline" style={styles.sliderLabel}>
                  {t("ImageCrop.Zoom")}
                </p>

                <input
                  type="range"
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e) => {
                    setZoom(e.target.value);
                  }}
                  className="zoom-range"
                />
              </div>
            )}

            <Button
              size="sm"
              variant="primary"
              type="button"
              onClick={() =>
                document.querySelector('input[id="upload-file"]').click()
              }
            >
              {t("ImageCrop.Load other image")}
            </Button>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            size="sm"
            variant="secondary"
            type="button"
            onClick={() => setShowModal(false)}
          >
            {t("Buttons.Cancel")}
          </Button>
          <Button
            size="sm"
            variant="primary"
            type="button"
            onClick={saveCroppedImage}
          >
            {t("Buttons.Upload")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Component;
