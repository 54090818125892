import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import PharmacyForm from "../components/Form";
import {
  getOnePharmacy,
  updatePharmacy,
  resetUpdateOnePharmacy,
} from "../../../redux/slices/pharmacy";
import AlertComponent from "../../../components/alerts/Alert";

const keys = {
  name: "",
  street: "",
  number: "",
  zip: "",
  town: "",
  province: "",
  country: "",
  country_code: "",
  phone_number: "",
  email: "",
  web: "",
};

const Component = ({
  pharmacyUpdate,
  pharmacyUpdateLoading,
  pharmacyUpdateError,
  pharmacyUpdateSuccess,
  pharmacy,
  pharmacyLoading,
  pharmacyError,
  pharmacySuccess,
  infoRoute,
}) => {
  // translation
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  // route
  const {
    history,
    location: {
      state: { id },
    },
  } = infoRoute;
  // utils
  const [initialValues, setInitialValues] = useState(keys);
  const [alert, setAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    // Get all form elements
    var formElements = event.target.elements;

    // Create an object to store form values
    var formData = {};

    // Iterate over form elements and populate formData object
    for (var i = 0; i < formElements.length; i++) {
      var element = formElements[i];

      if (element.name) {
        let value = element.value;
        formData[element.name] = value;
      }
    }

    console.log("Update pharmacy submit payload", { ...pharmacy, ...formData });
    dispatch(updatePharmacy({ ...pharmacy, ...formData }));
  };

  useEffect(() => {
    let initialValues = {};
    if (!!pharmacy) {
      for (const key of Object.keys(keys)) {
        initialValues[key] = pharmacy[key];
      }
    }

    setInitialValues(initialValues);
  }, [pharmacy]);

  useEffect(() => {
    if (id) {
      dispatch(getOnePharmacy({ id }));
    }
  }, [infoRoute]);

  useEffect(() => {
    if (pharmacyUpdateError) {
      setAlert({
        open: true,
        severity: "error",
        message: t("Messages.The operation could not be processed"),
      });
    }
  }, [pharmacyUpdateError]);

  useEffect(() => {
    if (pharmacyUpdateSuccess) {
      setAlert({
        open: true,
        severity: "success",
        message: t("Messages.The operation was successful"),
      });
      setTimeout(() => {
        history.push("/pharmacy");
      }, 1000);
    }
  }, [pharmacyUpdateSuccess]);

  useEffect(() => {
    if (pharmacyUpdateError || pharmacyUpdateSuccess) {
      setTimeout(() => {
        dispatch(resetUpdateOnePharmacy());
        setAlert({
          open: false,
          severity: "",
          message: "",
        });
      }, 3000);
    }
  }, [pharmacyUpdateError, pharmacyUpdateSuccess]);

  return (
    <>
      <div>
        <h4 className="main-title mt-2">{t("PharmacyEdit.Edit Pharmacy")}</h4>
        <p className="blue-anchors">
          <Link className="blue-anchors" to="/pharmacy">
            &#60; {t("PharmacyEdit.Back to pharmacies")}
          </Link>
        </p>
      </div>
      <br />
      <Form
        noValidate
        id="edit-pharmacy-form"
        onSubmit={handleSubmit}
        style={{ width: "95%" }}
      >
        <PharmacyForm
          initialValues={initialValues}
          isLoading={pharmacyUpdateLoading || pharmacyLoading}
        />
        <div className="d-flex justify-content-end">
          <Button
            className="btnGo"
            form="edit-pharmacy-form"
            type="submit"
            target={false}
            disabled={pharmacyUpdateLoading}
          >
            {t("PharmacyEdit.Edit Pharmacy")}
          </Button>
        </div>
      </Form>

      <AlertComponent
        isAlert={alert.open}
        setIsAlert={setAlert}
        severity={alert.severity}
        message={alert.message}
      />
    </>
  );
};

const state = ({ pharmacyStore }) => {
  const {
    data: pharmacy,
    loading: pharmacyLoading,
    error: pharmacyError,
    success: pharmacySuccess,
  } = pharmacyStore.one;

  const {
    data: pharmacyUpdate,
    loading: pharmacyUpdateLoading,
    error: pharmacyUpdateError,
    success: pharmacyUpdateSuccess,
  } = pharmacyStore.update;

  return {
    pharmacyUpdate,
    pharmacyUpdateLoading,
    pharmacyUpdateError,
    pharmacyUpdateSuccess,
    pharmacy,
    pharmacyLoading,
    pharmacyError,
    pharmacySuccess,
  };
};

export default connect(state)(Component);
