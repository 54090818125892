import styled from 'styled-components'
import { Link as UiLink } from 'react-router-dom'

export const Link = styled(UiLink)`
  display: block;
  width: 100%;
  line-height: 38px;
  text-align: center;
  border: 1px solid #0d6efd;
  border-radius: 6px;
  text-decoration: none;
`