import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";
import { ReactComponent as Pencil } from "../../assets/icons/pencil.svg";
import { ReactComponent as Delete } from "../../assets/icons/delete.svg";
import NewTaxRates from "./NewTaxRates";
import EditTaxRates from "./EditTaxRates";
import { DeleteDialog } from "./DeleteDialog";
import GetUserToken from "../../utils/GetUserToken";
import Table from "./Table";
import AlertComponent from "../alerts/Alert";
import "../../assets/stylesheets/treatmentsStyles.css";

const TaxRateList = () => {
  // translation
  const { t } = useTranslation();
  let currentLanguage = localStorage.getItem("i18nextLng");
  // auth
  const userToken = GetUserToken();
  // data
  const [data, setData] = useState();
  const [insurance, setInsurance] = useState("");
  // utils
  const [loading, setLoading] = useState(true);
  const [showmodal, setShowmodal] = useState(false);
  const [showupdatemodal, setShowupdatemodal] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const columns = useMemo(
    () => [
      {
        // first group
        Header: " ",
        // First group columns
        columns: [
          {
            Header: <>ID</>,
            accessor: "id",
          },
          {
            Header: <>{t("Settings.Code")}</>,
            accessor: "vat_code",
          },
          {
            Header: <>{t("Settings.Rate")}(%)</>,
            accessor: "tax_rate",
          },
          {
            Header: <>{t("Insurances.Description")}</>,
            accessor: "description",
          },
          {
            Header: " ",
            accessor: "edit",
            Cell: ({ row }) => (
              <div style={{ cursor: "pointer" }}>
                <Pencil
                  className="icon"
                  onClick={() => handleEdit(row.original.id)}
                />
              </div>
            ),
          },
          /*           {
            Header: " ",
            accessor: "delete",
            Cell: ({ row }) => (
              <div style={{ cursor: "pointer" }}>
                <Delete
                  className="icon"
                  onClick={() => handlePreDelete(row.original)}
                />
              </div>
            ),
          }, */
        ],
      },
    ],
    [currentLanguage]
  );

  const fetchData = async () => {
    const url = process.env.REACT_APP_API_URL_V2 + "/tax_rates/index";

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    const res = await fetch(url, requestOptions);
    const json = await res.json();
    console.log(json);
    setData(json.tax_rates);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = (id) => {
    setInsurance(id);
    setShowupdatemodal(true);
  };

  const handlePreDelete = (item) => {
    setInsurance(item);
    setDialog(true);
  };

  const handleDelete = async (ins) => {
    const url = process.env.REACT_APP_API_URL_V2 + "/tax_rates/destroy/";

    var requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
    };

    await fetch(url + ins, requestOptions)
      .then((response) => {
        if (response.ok) {
          setIsAlert({
            open: true,
            severity: "success",
            message: t("Messages.The operation was successful"),
          });
          setDialog(false);
          fetchData();
        } else {
          setDialog(false);
          setIsAlert({
            open: true,
            severity: "error",
            message: t("Messages.The operation could not be processed"),
          });
        }
      })
      .catch((error) =>
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.Something went wrong, please try again"),
        })
      );
  };

  return (
    <>
      <div className="frame">
        <h4 className="main-title mt-2">{t("TaxRates.Tax Rates")}</h4>
        <br />

        <div style={{ marginRight: "50px" }}>
          <div className="container-fluid treatments-table">
            {loading ? (
              <div className="d-flex justify-content-center">
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <Table
                columns={columns}
                data={data}
                setShowmodal={setShowmodal}
              />
            )}
          </div>
        </div>
      </div>

      <NewTaxRates
        showmodal={showmodal}
        setShowmodal={setShowmodal}
        setIsAlert={setIsAlert}
        fetchData={fetchData}
      />

      <Modal
        show={showupdatemodal}
        onHide={() => setShowupdatemodal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ background: "#F4F5FA" }}>
          <Modal.Title>{t("Settings.Edit tax rates")}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#F4F5FA" }}>
          <EditTaxRates
            insurance={insurance}
            updateInsurances={fetchData}
            setShowupdatemodal={setShowupdatemodal}
            setIsAlert={setIsAlert}
          />
        </Modal.Body>
      </Modal>

      <DeleteDialog
        dialog={dialog}
        setDialog={setDialog}
        handleDelete={handleDelete}
        insurance={insurance}
      />

      {isAlert.open && (
        <AlertComponent
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
    </>
  );
};

export default TaxRateList;
