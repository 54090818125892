import GetUserToken from "./GetUserToken";

const fetchApi = async (url, method, body = "", token = true) => {
  let options = {
    method,
    headers: {
      "Content-Type": "application/json",
      "api-key": process.env.REACT_APP_API_KEY,
      // Authorization: GetUserToken(),
    },
    redirect: "follow",
  };

  if (token) {
    options.headers = {
      ...options.headers,
      Authorization: GetUserToken(),
    };
  }

  if (body !== "") {
    options.body = body;
  }

  const res = await fetch(url, options);
  const data = await res.json();

  return {
    data,
    ok: res.ok,
    status: res.status,
  };
};

export default fetchApi;
