import React from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Arrow from "../../assets/icons/sort_arrow.svg";
import "../../assets/stylesheets/patients.css";

export default function Table({ columns, data, setShowmodal }) {
  // translations
  const { t } = useTranslation();

  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    setFilter, // The useFilter Hook provides a way to set the filter
    setGlobalFilter,
    page, // rows OR PAGE for the table based on the data passed
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { globalFilter, pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters, // Adding the useFilters Hook to the table
    useGlobalFilter,
    useSortBy,
    usePagination
    // You can add as many Hooks as you want. Hooks for react-table here
  );

  React.useEffect(() => {}, [globalFilter]);

  return (
    <>
      <div
        style={{
          width: "95%",
          background: "white",
          paddingTop: "18px",
          scrollPaddingBottom: "10px",
          borderRadius: "8px",
        }}
      >
        <div className="d-flex justify-content-end">
          <input
            className="search-field"
            type="text"
            value={globalFilter || ""}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder={t("ProducTable.0")}
          />

          <Button
            className="new-patient-btn"
            id="add_diagnosis"
            onClick={() => setShowmodal(true)}
          >
            {t("Diagnosis.Add Diagnosis")}
          </Button>
        </div>

        <table
          {...getTableProps()}
          className="table table-borderless table-striped list-patient"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <img
                            src={Arrow}
                            alt="Arrow"
                            style={{ transform: "rotate(180deg)" }}
                          />
                        ) : (
                          <img src={Arrow} alt="Arrow" />
                        )
                      ) : (
                        <></>
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row); // This line is necessary to prepare the rows and get the row props from react-table dynamically
              // Each row can be rendered directly as a string using the react-table render method
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div
        className="pagination d-flex justify-content-end pb-5"
        style={{ width: "95%" }}
      >
        <button
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          className="pagination-btn"
        >
          {"<<"}
        </button>{" "}
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="pagination-btn"
        >
          {"<"}
        </button>{" "}
        <span>
          {" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="pagination-btn"
        >
          {">"}
        </button>{" "}
        <button
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          className="pagination-btn"
        >
          {">>"}
        </button>{" "}
      </div>
    </>
  );
}
