import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import "../../../../assets/stylesheets/multistepCitation.css";
import { getLinkAsOfAppLanguage } from "../../../../utils/helper";

const Component = ({
  classes,
  neue_logo,
  loading,
  nextStep,
  accepted,
  setAccepted,
  company,
}) => {
  const { t, i18n } = useTranslation();
  let language = i18n.language.slice(0, 2);

  return (
    <>
      <div className="container-fluid">
        <div className="root">
          <div className="row">
            <div className="col container-NeueTermin">
              <div className="d-flex justify-content-center">
                <img
                  style={{
                    width: "150px",
                    marginTop: "30px",
                    marginBottom: "20px",
                  }}
                  //src={neue_logo}
                  src={
                    company?.logo?.url ||
                    "https://www.tcmassist.org/wp-content/uploads/2022/08/tcm-assist-logo.png"
                  }
                  alt="logo"
                />
              </div>
              <div className="d-flex justify-content-center">
                <h3 className="subtitle-praxis">{t("NeueTermin.0")}</h3>
              </div>
              <br />
              <div className="d-flex justify-content-center padding-p">
                <h3 className="paragraph-neue">
                  {company?.business_name} {t("NeueTermin.1")}
                  <br />
                  {t("NeueTermin.2")}
                </h3>
              </div>
              <br />
              <div className="d-flex justify-content-center">
                <h3 className="paragraph-neue check">
                  <Checkbox
                    checked={accepted?.company_terms_and_conditions}
                    onChange={() => setAccepted("company")}
                    style={{
                      color: accepted?.company_terms_and_conditions
                        ? "var(--primary-color)"
                        : "rgba(0, 0, 0, 0.54)",
                    }}
                    inputProps={{ "aria-label": "company checkbox" }}
                  />
                  {t("NeueTermin.3")}{" "}
                  <a
                    href={
                      company?.policies_and_terms &&
                      company?.policies_and_terms !== "null"
                        ? company?.policies_and_terms
                        : ""
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("NeueTermin.4")}
                  </a>{" "}
                  {t("NeueTermin.22")} {company?.business_name}
                  {t("NeueTermin.5")}
                </h3>
              </div>
              <div className="d-flex justify-content-center">
                <h3 className="paragraph-neue check">
                  <Checkbox
                    checked={accepted?.our_terms_and_conditions}
                    onChange={() => setAccepted("our")}
                    style={{
                      color: accepted?.our_terms_and_conditions
                        ? "var(--primary-color)"
                        : "rgba(0, 0, 0, 0.54)",
                    }}
                    inputProps={{ "aria-label": "our checkbox" }}
                  />
                  {t("NeueTermin.3")}{" "}
                  <a
                    href={getLinkAsOfAppLanguage(language)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("NeueTermin.4")}
                  </a>{" "}
                  {t("NeueTermin.22")} Digitales Zeugs UG
                  {t("NeueTermin.5")}
                </h3>
              </div>
              <div className="d-flex justify-content-center mt-3 mb-3">
                {loading ? (
                  <>
                    <button disabled className="btnBack" onClick={nextStep}>
                      {t("NeueTermin.6")}
                    </button>
                  </>
                ) : (
                  <>
                    {accepted.company_terms_and_conditions &&
                    accepted.our_terms_and_conditions ? (
                      <>
                        <button
                          className="btngo"
                          style={{
                            backgroundColor: "var(--primary-color)",
                          }}
                          onClick={nextStep}
                        >
                          {t("NeueTermin.7")}
                        </button>
                      </>
                    ) : (
                      <>
                        <button disabled className="btnBack" onClick={nextStep}>
                          {t("NeueTermin.7")}
                        </button>
                      </>
                    )}
                  </>
                )}
                {loading ? (
                  <>
                    <button disabled className="btnBack" onClick={nextStep}>
                      {t("NeueTermin.6")}
                    </button>
                  </>
                ) : (
                  <>
                    <>
                      <button
                        className="btngo"
                        style={{
                          backgroundColor: "var(--primary-color)",
                        }}
                        onClick={() => (window.location.href = "/login")}
                      >
                        {t("NeueTermin.8")}
                      </button>
                    </>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const state = ({ companyStore }) => {
  const {
    data: company,
    loading: companyLoading,
    error: companyError,
    success: companySuccess,
  } = companyStore.one;

  return {
    company,
    companyLoading,
    companyError,
    companySuccess,
  };
};

export default connect(state)(Component);
