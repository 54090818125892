import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import services from "../../services";

// Define initial state
const initialState = {
  one: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  all: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  create: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  update: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
};

// Async action to get one citation
const getOneCitation = createAsyncThunk("citation/getOne", async (payload) => {
  console.log("get one - citation - action", payload);
  const { response, error } = await services.citation.getOne(payload);
  if (error) {
    throw new Error(error);
  }
  return response;
});

// Async action to get all companies
const getAllCitations = createAsyncThunk("citation/getAll", async (payload) => {
  console.log("get all - citation - action", payload);
  const { response, error } = await services.citation.getAll(payload);
  if (error) {
    throw new Error(error);
  }
  return response;
});

// Async action to create one citation
const createOneCitation = createAsyncThunk(
  "citation/createOne",
  async (payload) => {
    console.log("create one - citation - action", payload);
    const { response, error } = await services.citation.createOne(payload);
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Async action to update a citation
const updateCitation = createAsyncThunk("citation/update", async (payload) => {
  console.log("update - citation - action", payload);
  const { response, error } = await services.citation.update(payload);
  if (error) {
    throw new Error(error);
  }
  return response;
});

// Create a citation slice
const citationSlice = createSlice({
  name: "citation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOneCitation.pending, (state, action) => {
        state.one.data = null;
        state.one.loading = true;
        state.one.success = false;
        state.one.error = false;
      })
      .addCase(getOneCitation.fulfilled, (state, action) => {
        state.one.data = action.payload;
        state.one.loading = false;
        state.one.success = true;
        state.one.error = false;
      })
      .addCase(getOneCitation.rejected, (state, action) => {
        state.one.data = null;
        state.one.loading = false;
        state.one.success = false;
        state.one.error = action.error.message;
      })
      .addCase(getAllCitations.pending, (state) => {
        state.all.data = null;
        state.all.loading = true;
        state.all.success = false;
        state.all.error = false;
      })
      .addCase(getAllCitations.fulfilled, (state, action) => {
        state.all.data = action.payload;
        state.all.loading = false;
        state.all.success = true;
        state.all.error = false;
      })
      .addCase(getAllCitations.rejected, (state, action) => {
        state.all.data = null;
        state.all.loading = false;
        state.all.success = false;
        state.all.error = action.error.message;
      })
      .addCase(updateCitation.pending, (state) => {
        state.update.data = null;
        state.update.loading = true;
        state.update.success = false;
        state.update.error = false;
      })
      .addCase(updateCitation.fulfilled, (state, action) => {
        state.update.data = action.payload;
        state.update.loading = false;
        state.update.success = true;
        state.update.error = false;
        state.one.data = action.payload;
      })
      .addCase(updateCitation.rejected, (state, action) => {
        state.update.data = null;
        state.update.loading = false;
        state.update.success = false;
        state.update.error = action.error.message;
      });
  },
});

// Export the async actions and the reducer
export {
  getOneCitation,
  getAllCitations,
  createOneCitation,
  updateCitation,
};

export default citationSlice.reducer;