import GetUserToken from "../utils/GetUserToken";
import { parseJSON } from "../utils/parseJSON";

const urlBase = `${process.env.REACT_APP_API_URL}/users`;
const readUrl = (url = "") => `${urlBase}/${url}`;

class User {
  async update(payload) {
    const { id, ...rest } = payload;
    const auth = `Bearer ${GetUserToken()}`;

    let res = await fetch(readUrl(`update_roles/${id}`), {
      method: "POST",
      headers: {
        Authorization: auth,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(rest),
    });

    res = await parseJSON(res);
    return res;
  }
}

const user = new User();
export default user;
