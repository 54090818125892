import React from "react";
import { useTranslation } from "react-i18next";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

export const DeleteUndo = ({ undo, setUndo, handleUndo, product }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Snackbar
        open={undo}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={10000}
        onClose={() => setUndo(false)}
      >
        <MuiAlert
          onClose={() => setUndo(false)}
          severity="info"
          variant="filled"
        >
          {t("InvoiceDeleteUndo.0")}: {product} {t("InvoiceDeleteUndo.1")}.{" "}
          <span onClick={() => handleUndo()} style={{ cursor: "pointer" }}>
            <b>{t("InvoiceDeleteUndo.2")}</b>
          </span>
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default DeleteUndo;
