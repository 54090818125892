import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { CircularProgress, TextareaAutosize } from "@material-ui/core";
import GetUserToken from "../../utils/GetUserToken";

const NewProductOnboarding = ({ isAlert, setIsAlert, setIsProductCreated }) => {
  // auth
  const userToken = GetUserToken();
  // translations
  const { t } = useTranslation();
  //data
  const [title, setTitle] = useState("");
  const [rate, setRate] = useState("");
  // utils
  const [loading, setLoading] = useState(false);

  // Post method
  const handleSubmit = () => {
    setLoading(true);

    const product = {
      title,
      rate,
      is_child_product: false,
    };

    const url = process.env.REACT_APP_API_URL + "/products/create";

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
      body: JSON.stringify(product),
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.id) {
          setIsAlert({
            open: true,
            severity: "success",
            message: t("Messages.The operation was successful"),
          });
          setLoading(false);
          setTimeout(() => {
            setIsProductCreated(response.id);
          }, 1000);
        } else {
          setIsAlert({
            open: true,
            severity: "error",
            message: t("Messages.The operation could not be processed"),
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.Something went wrong, please try again"),
        });
        setLoading(false);
      });
  };

  return (
    <Box
      sx={{
        maxHeight: "100%",
        overflowY: "auto",
        width: "100%",
        maxWidth: "500px",
        mr: "auto",
        ml: "auto",
        mt: "20px",
        backgroundColor: "#ffffff",
        border: "1px solid #f1f4fa",
        borderRadius: "16px",
        p: "26px",
      }}
    >
      <h4 className="main-title text-center mt-2">{t("NewProduct.0")}</h4>
      <div className="form-group">
        <label className="title-inputs">{t("NewProduct.2")}</label>
        <input
          required
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="input-control"
          disabled={loading}
        />
      </div>

      <div className="form-group">
        <label className="title-inputs">{t("NewProduct.4")}</label>
        <TextareaAutosize
          type="text"
          value={rate}
          onChange={(e) => setRate(e.target.value)}
          aria-label="minimum height"
          minRows={3}
          className="input-control"
          disabled={loading}
        />
      </div>

      <div className="d-flex justify-content-center">
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <button
            className={
              !title || !rate || isAlert.open ? "blue-btn-disabled" : "blue-btn"
            }
            id="create_product"
            disabled={!title || !rate || isAlert.open}
            onClick={handleSubmit}
          >
            {t("NewProduct.3")}
          </button>
        )}
      </div>
    </Box>
  );
};

export default NewProductOnboarding;
