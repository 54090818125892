import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <MDBFooter color="blue" className="font-small pt-4 mt-4">
      <MDBContainer fluid className="text-center text-md-left">
        <MDBRow>
          <MDBCol md="12">
            {/*             <a
              className="blue-anchors"
              target="_blank"
              href="https://jessica-noll.de/impressum/"
              rel="noreferrer"
            >
              {t("Footer.0")}
            </a>
            &nbsp; &nbsp;
            <a
              className="blue-anchors"
              target="_blank"
              href="https://jessica-noll.de/datenschutz/"
              rel="noreferrer"
            >
              {t("Footer.1")}
            </a> */}
            <a
              className="blue-anchors"
              target="_blank"
              href="https://digitaleszeugs.atlassian.net/servicedesk/customer/portal/7"
              rel="noreferrer"
            >
              {" "}
              {t("Help.Help")}
            </a>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          &copy; 2023 / Gebaut durch Accentoris AG
        </MDBContainer>
      </div>
    </MDBFooter>
  );
};

export default Footer;
