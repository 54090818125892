import React from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "../../../assets/stylesheets/newdoctorcitation.css";

export const TreatmentPicker = ({
  treatments,
  setTreatment,
  checkSchedule,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <h1 className="subtitle-modal">{t("Calendar.Treatment")}</h1>
      <FormControl style={{ minWidth: "100%", textAlign: "left" }}>
        <Autocomplete
          id="treatment"
          name="treatment"
          options={treatments.sort((a, b) => a.name.localeCompare(b.name))}
          onChange={(event, value) => {
            var fieldValue = value;
            if (!fieldValue) {
              fieldValue = null;
            } else {
              setTreatment(value.id);
            }

            var field = value;
            if (!field) {
              field = null;
            } else {
              checkSchedule(value.first);
            }
          }}
          getOptionLabel={(option) => option["name"]}
          inputValue={treatments["id"]}
          renderInput={(params) => <TextField {...params} />}
        />
      </FormControl>
    </>
  );
};
