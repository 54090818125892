import React from "react";
import IconButton from "@material-ui/core/IconButton";
import EmailIcon from "@material-ui/icons/Email";

const EmailButton = ({ emailAddress }) => {
  const handleEmailClick = () => {
    const emailLink = `mailto:${emailAddress}`;
    window.location.href = emailLink;
  };

  return (
    <IconButton
      onClick={handleEmailClick}
      aria-label="Send Email"
      style={{ color: "var(--primary-color)" }}
    >
      <EmailIcon />
    </IconButton>
  );
};

export default EmailButton;
