import React, { useEffect, useMemo, useState } from "react";
import { Accordion, Button, Form, Row, Col } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import { Card, Center, Select, Image } from "./styles";
import FieldLoader from "../../../FieldLoader";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect, useDispatch } from "react-redux";
import { getAllHerbs } from "../../../../../../redux/slices/herb";
import { useTranslation } from "react-i18next";
import { SliderValueLabel } from "@mui/material";

const Component = ({
  isLoading,
  name,
  value,
  herbs,
  herbsLoading,
  herbsError,
  herbsSuccess,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState([]);

  const handleInputChange = (index, e) => {
    const newFormData = [...formData];
    if (e.target.name.includes("qty")) {
      newFormData[index] = { ...newFormData[index], qty: e.target.value };
    } else {
      newFormData[index] = { ...newFormData[index], ...e.target.value };
    }
    setFormData(newFormData);
  };

  const handleAddField = () => {
    setFormData([...formData, { ...herbs[0], qty: "" }]);
  };

  const handleRemoveField = (index) => {
    const newFormData = [...formData];
    newFormData.splice(index, 1);
    setFormData(newFormData);
  };

  const initFormData = () => {
    if (value && value.length > 0) {
      const initialFormData = value.map((initValue, index) => {
        return {
          ...herbs.find((herb) => herb.id === initValue.herb),
          qty: initValue.qty,
        };
      });
      setFormData(initialFormData);
    } else {
      setFormData((oldState) => [
        ...oldState,
        {
          ...herbs[0],
          qty: "",
        },
      ]);
    }
  };



  useEffect(() => {
    if (herbs) {
      initFormData();
    }
  }, [value, herbs]);

  useEffect(() => {
    if (!herbs) {
      dispatch(getAllHerbs());
    }
  }, []);

  return (
    <Accordion
      style={{ paddingRight: "0", paddingLeft: "0", marginBottom: "1rem" }}
    >
      <Card>
        <Card.Header
          className="reminders-subtitle"
          style={{
            border: "none",
            background: "white",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}

        >
          <div>
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="0"
              className="text-center subtitles-patients"
              style={{ textDecoration: "none" }}
              id="accordion_herbs"
            >
              <span>&#8595; &nbsp;</span>
              {t("BlendTable/Form.Herbs")}


              {herbsLoading && <CircularProgress size={20} style={{ marginLeft: 20 }} />}
            </Accordion.Toggle>
          </div>
        </Card.Header>

        <Accordion.Collapse eventKey="0">
          <Card.Body>
            {formData.map((field, index) => (
              <Row key={`herb-${index}-row`}>
                <Col>
                  <Form.Group className="mb-3" controlId={`${name}-${index}`}>
                    {index <= 0 && <Form.Label>{t("HerbsField.Pinyin name")}</Form.Label>}
                    <FieldLoader loading={herbsLoading}>
                      <input
                        type="hidden"
                        name={`herb.${index}.herb`}
                        value={field.id}
                      />
                      <Select
                        id="herb-pinying-name"
                        size="small"
                        noOptionsText="Not Found"
                        options={herbs}
                        value={field}
                        onInputChange={(event, value, reason) => {
                          const selectedHerb = herbs?.find(
                            (herb) => herb.pinyin_name === value
                          );

                          handleInputChange(index, {
                            target: {
                              name: "herb",
                              value: selectedHerb,
                            },
                          });
                        }}
                        disabled={isLoading || herbsLoading}
                        renderInput={(params) => <TextField {...params} />}
                        getOptionLabel={(option) => option.pinyin_name || ""}
                      />
                    </FieldLoader>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group className="mb-3" controlId={`${name}-${index}`}>
                    {index <= 0 && <Form.Label>{t("HerbsField.English name")}</Form.Label>}
                    <FieldLoader loading={herbsLoading}>
                      <Select
                        id="herb-english-name"
                        size="small"
                        noOptionsText="Not Found"
                        options={herbs}
                        value={field}
                        onInputChange={(event, value, reason) => {
                          const selectedHerb = herbs?.find(
                            (herb) => herb.english_name === value
                          );

                          handleInputChange(index, {
                            target: {
                              name: "herb",
                              value: selectedHerb,
                            },
                          });
                        }}
                        disabled={isLoading || herbsLoading}
                        renderInput={(params) => <TextField {...params} />}
                        getOptionLabel={(option) => option.english_name || ""}
                      />
                    </FieldLoader>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group className="mb-3" controlId={`${name}-${index}`}>
                    {index <= 0 && <Form.Label>{t("HerbsField.Quantity")}</Form.Label>}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 15,
                      }}
                    >
                      <FieldLoader loading={herbsLoading}>
                        <Form.Control
                          required
                          type="number"
                          min={0}
                          name={`herb.${index}.qty`}
                          value={field.qty}
                          onInput={(e) => handleInputChange(index, e)}
                          disabled={isLoading || herbsLoading}
                        />
                      </FieldLoader>

                      <span>{t("HerbsField.grams")}</span>
                    </div>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group className="mb-3" controlId={`${name}-${index}`}>
                    {index > 0 && (
                      <button
                        type="button"
                        className="btn-cancel mt-1"
                        disabled={herbsLoading || isLoading}
                        onClick={() => handleRemoveField(index)}
                      >
                        {t("HerbsField.Remove")}
                      </button>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            ))}

            <button
              type="button"
              className="btn-cancel mt-1"
              onClick={handleAddField}
              disabled={herbsLoading || isLoading}
            >
              {t("HerbsField.Add Field")}
            </button>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

const state = ({ herbStore }) => {
  const {
    data: herbs,
    loading: herbsLoading,
    error: herbsError,
    success: herbsSuccess,
  } = herbStore.all;

  return {
    herbs,
    herbsLoading,
    herbsError,
    herbsSuccess,
  };
};

export default connect(state)(Component);
