import { Label } from "./styles"

const Component = ({ children }) => {
  return (
    <Label>
      {children}
    </Label>
  )
}

export default Component