import styled from 'styled-components'

export const RelativeDiv = styled('div')`
  position: relative;
  
`

export const Box = styled('div')`
  border: 1px solid gray;
  padding: 10px 15px;
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 1px 2px 2px 0px rgba(0,0,0,.2);
`

export const TableTitle = styled('h3')`
  line-height: 24px;
  font-size: 16px;
  font-weight: normal;
  margin: 0px;
  opacity: .5;
`

export const Table = styled('table')`
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
  width: 100%;
  font-size: 14px;
`

export const Thead = styled('thead')`
  border: 0;
  padding: 0;
`

export const Tbody = styled('tbody')`
  border: 0;
  padding: 0;
`

export const Tr = styled('tr')`
  border: 0px;
`

export const Th = styled('th')`
  border: 1px solid gray;
  padding: 0px 3px;
  font-size: 16px;
  overflow: visible;

  // &:first-child {
  //   border: 0px;
  //   border-bottom: 1px solid gray;
  //   text-align: center;
  //   padding: 0px;
  // }
`

export const Td = styled('td')`
  border: 1px solid gray;
  padding: 0px 3px;
  overflow: visible;

  &:first-child {
    border: 0px;
    border-bottom: 1px solid gray;
    border-left: 1px solid gray;
    text-align: center;
    padding: 0px;
  }
`
