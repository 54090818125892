import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import services from "../../services";

// Define initial state
const initialState = {
  one: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  all: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  create: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  update: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
};

// Async action to get one blend
const getOneBlend = createAsyncThunk("blend/getOne", async (payload) => {
  console.log("get one - blend - action", payload);
  const { response, error } = await services.blend.getOne(payload);
  if (error) {
    throw new Error(error);
  }
  return response;
});

// Async action to get all companies
const getAllBlends = createAsyncThunk("blend/getAll", async (payload) => {
  console.log("get all - blend - action", payload);
  const { response, error } = await services.blend.getAll(payload);
  if (error) {
    throw new Error(error);
  }
  return response;
});

// Async action to create one blend
const createOneBlend = createAsyncThunk(
  "blend/createOne",
  async (payload) => {
    console.log("create one - blend - action", payload);
    const { response, error } = await services.blend.createOne(payload);
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Async action to reset a blend creation
const resetCreateOneBlend = createAsyncThunk("blend/resetCreateOne", async (payload) => {
  console.log("reset creation - blend - action", payload);
});

// Async action to update a blend
const updateBlend = createAsyncThunk("blend/update", async (payload) => {
  console.log("update - blend - action", payload);
  const { response, error } = await services.blend.update(payload);
  if (error) {
    throw new Error(error);
  }
  return response;
});

// Async action to reset a blend update
const resetUpdateOneBlend = createAsyncThunk("blend/resetUpdateOne", async (payload) => {
  console.log("reset update - blend - action", payload);
});

// Create a blend slice
const blendSlice = createSlice({
  name: "blend",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOneBlend.pending, (state, action) => {
        state.one.data = null;
        state.one.loading = true;
        state.one.success = false;
        state.one.error = false;
      })
      .addCase(getOneBlend.fulfilled, (state, action) => {
        state.one.data = action.payload;
        state.one.loading = false;
        state.one.success = true;
        state.one.error = false;
      })
      .addCase(getOneBlend.rejected, (state, action) => {
        state.one.data = null;
        state.one.loading = false;
        state.one.success = false;
        state.one.error = action.error.message;
      })

      .addCase(createOneBlend.pending, (state, action) => {
        state.create.data = null;
        state.create.loading = true;
        state.create.success = false;
        state.create.error = false;
      })
      .addCase(createOneBlend.fulfilled, (state, action) => {
        state.create.data = action.payload;
        state.create.loading = false;
        state.create.success = true;
        state.create.error = false;
      })
      .addCase(createOneBlend.rejected, (state, action) => {
        state.create.data = null;
        state.create.loading = false;
        state.create.success = false;
        state.create.error = action.error.message;
      })
      .addCase(resetCreateOneBlend.fulfilled, (state, action) => {
        state.create.data = null;
        state.create.loading = false;
        state.create.success = false;
        state.create.error = false;
      })

      .addCase(getAllBlends.pending, (state) => {
        // state.all.data = null;
        state.all.loading = true;
        state.all.success = false;
        state.all.error = false;
      })
      .addCase(getAllBlends.fulfilled, (state, action) => {
        state.all.data = action.payload;
        state.all.loading = false;
        state.all.success = true;
        state.all.error = false;
      })
      .addCase(getAllBlends.rejected, (state, action) => {
        state.all.data = null;
        state.all.loading = false;
        state.all.success = false;
        state.all.error = action.error.message;
      })

      .addCase(updateBlend.pending, (state) => {
        state.update.data = null;
        state.update.loading = true;
        state.update.success = false;
        state.update.error = false;
      })
      .addCase(updateBlend.fulfilled, (state, action) => {
        state.update.data = action.payload;
        state.update.loading = false;
        state.update.success = true;
        state.update.error = false;
        state.one.data = action.payload;
      })
      .addCase(updateBlend.rejected, (state, action) => {
        state.update.data = null;
        state.update.loading = false;
        state.update.success = false;
        state.update.error = action.error.message;
      })
      .addCase(resetUpdateOneBlend.fulfilled, (state, action) => {
        state.update.data = null;
        state.update.loading = false;
        state.update.success = false;
        state.update.error = false;
      });
  },
});

// Export the async actions and the reducer
export {
  getOneBlend,
  getAllBlends,
  createOneBlend,
  resetCreateOneBlend,
  updateBlend,
  resetUpdateOneBlend
};

export default blendSlice.reducer;