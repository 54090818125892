import React from 'react'
import { useTranslation } from 'react-i18next';

export const TimeLockerView = ({ doctor, start, end, deleteEvent, deleting }) => {
    const { t } = useTranslation();
    return (
        <>
            <h3 id="simple-modal-title">{t('Calendar.Time Locking Details')}:</h3>
            <p id="simple-modal-description">
                {t('Calendar.Therapist')}: {doctor} <br />
                {t('Calendar.From')}: {start} <br />
                {t('Calendar.To')}: {end} <br />

                <br />
                {deleting ? <></> : <>
                <div className="d-flex justify-content-center">
                    <button className="blue-btn" type="button" onClick={deleteEvent}>
                        {t('Buttons.Destroy')}
                    </button>
                </div>
                </>}
            </p>
        </>
    )
}

export default TimeLockerView;