import React from "react";
import { useTranslation } from "react-i18next";
import { Stepper, Step, StepLabel, Typography } from "@material-ui/core";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useStyles from "./styles";

const StepperComponent = ({ activeStep }) => {
  // translations
  const { t } = useTranslation();
  // styles
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // steps
  const steps = [
    "Select Treatment",
    "Select Therapist",
    "Select Date",
    "Select Time",
  ];

  return (
    <div className={classes.stepperRoot}>
      <Stepper
        activeStep={activeStep}
        orientation={isMobile ? "vertical" : "horizontal"}
        classes={{
          root: classes.stepper,
          vertical: classes.verticalStepper,
        }}
      >
        {steps.map((label, index) => (
          <Step key={label} className={classes.step}>
            <StepLabel
              StepIconProps={{
                classes: {
                  root: classes.stepIcon,
                },
              }}
            >
              <Typography
                className={
                  index === activeStep ? classes.activeLabel : classes.label
                }
              >
                {t(`FirstStep.${label}`)}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default StepperComponent;
