import React from "react";
import { useTranslation } from "react-i18next";
import {
  Popover,
  IconButton,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { getWikiLinkfromAppLanguage } from "../../utils/helper";

const HelpPopup = ({
  anchorEl,
  open,
  currentIndex,
  setCurrentIndex,
  handleClose,
  content,
}) => {
  // translations
  const { t, i18n } = useTranslation();
  let currentLanguage = i18n.language;
  // styles
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % content.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? content.length - 1 : prevIndex - 1
    );
  };

  return (
    <Popover
      open={open}
      anchorEl={isMobile ? null : anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: isMobile ? "center" : "bottom",
        horizontal: isMobile ? "center" : "right",
      }}
      transformOrigin={{
        vertical: isMobile ? "center" : "top",
        horizontal: isMobile ? "center" : "right",
      }}
      sx={{
        "& .MuiPaper-root": {
          padding: 2,
          top: isMobile ? "111px !important" : "auto !important",
          left: isMobile ? "16px !important" : "auto !important",
          right: isMobile ? "auto" : 72,
          bottom: isMobile ? "auto" : 110,
        },
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <Typography variant="h6">{t("HelpPopup.Help Information")}</Typography>
        <IconButton onClick={handleClose} size="small">
          <CloseIcon />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 2,
        }}
      >
        {content.length !== 0 && (
          <Card
            sx={{
              width: 400,
              height: 430,
              backgroundColor: "#f4f5fa",
              textAlign: "center",
              border: "1px solid #7f7f7f",
              borderRadius: "10px",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Box sx={{ color: "var(--primary-color)", mr: 1 }}>
                  {content[currentIndex].icon}
                </Box>
                <Typography variant="h6">
                  {t(`NextSteps.${content[currentIndex].title}`)}
                </Typography>
              </Box>

              <Box sx={{ height: 260, mb: 2 }}>
                <img
                  src={content[currentIndex].image}
                  alt={content[currentIndex].title}
                  style={{ width: "100%", height: "100%" }}
                />
              </Box>

              <a
                href={getWikiLinkfromAppLanguage(
                  currentLanguage,
                  content[currentIndex].link
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "var(--secondary-color)",
                    color: "#FFFFFF",
                    marginTop: "8px",
                    // width: "50%",
                  }}
                >
                  {t("HelpPopup.Learn more")}
                </Button>
              </a>

              {/* Carousel buttons */}
              {content.length > 1 && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: 0,
                    transform: "translateY(-50%)",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Button
                    size="small"
                    variant="text"
                    style={{
                      backgroundColor: "var(--secondary-color)",
                      color: "#FFFFFF",
                      borderRadius: "50%",
                      minWidth: "35px",
                      minHeight: "35px",
                    }}
                    onClick={handlePrev}
                  >
                    {"<"}
                  </Button>
                  <Button
                    size="small"
                    variant="text"
                    style={{
                      backgroundColor: "var(--secondary-color)",
                      color: "#FFFFFF",
                      borderRadius: "50%",
                      minWidth: "35px",
                      minHeight: "35px",
                    }}
                    onClick={handleNext}
                  >
                    {">"}
                  </Button>
                </Box>
              )}
            </CardContent>
          </Card>
        )}
      </Box>

      {/* Carousel indicators */}
      {content.length > 1 && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          {content.map((_, index) => (
            <Box
              key={index}
              sx={{
                width: 8,
                height: 8,
                borderRadius: "50%",
                backgroundColor:
                  index === currentIndex
                    ? "var(--primary-color)"
                    : "var(--secondary-color)",
                mx: 0.5,
              }}
            />
          ))}
        </Box>
      )}
    </Popover>
  );
};

export default HelpPopup;
