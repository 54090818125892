import React from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";

const ScheduleTable = ({ schedule }) => {
  // translation
  const { t } = useTranslation();

  return (
    <>
      {schedule.length > 0 && (
        <>
          <table className="table" style={{ borderRadius: "10px" }}>
            <tbody>
              <tr>
                {schedule.includes("08:00") ? (
                  <Tooltip title="Slot Available">
                    <td className="box-available">08:00</td>
                  </Tooltip>
                ) : (
                  <Tooltip title="Slot Busy">
                    <td className="box-unavailable">08:00</td>
                  </Tooltip>
                )}
                {schedule.includes("08:20") ? (
                  <Tooltip title="Slot Available">
                    <td className="box-available">08:20</td>
                  </Tooltip>
                ) : (
                  <Tooltip title="Slot Busy">
                    <td className="box-unavailable">08:20</td>
                  </Tooltip>
                )}
                {schedule.includes("08:40") ? (
                  <Tooltip title="Slot Available">
                    <td className="box-available">08:40</td>
                  </Tooltip>
                ) : (
                  <Tooltip title="Slot Busy">
                    <td className="box-unavailable">08:40</td>
                  </Tooltip>
                )}
              </tr>
              <tr>
                {schedule.includes("09:00") ? (
                  <Tooltip title="Slot Available">
                    <td className="box-available">09:00</td>
                  </Tooltip>
                ) : (
                  <Tooltip title="Slot Busy">
                    <td className="box-unavailable">09:00</td>
                  </Tooltip>
                )}
                {schedule.includes("09:20") ? (
                  <Tooltip title="Slot Available">
                    <td className="box-available">09:20</td>
                  </Tooltip>
                ) : (
                  <Tooltip title="Slot Busy">
                    <td className="box-unavailable">09:20</td>
                  </Tooltip>
                )}
                {schedule.includes("09:40") ? (
                  <Tooltip title="Slot Available">
                    <td className="box-available">09:40</td>
                  </Tooltip>
                ) : (
                  <Tooltip title="Slot Busy">
                    <td className="box-unavailable">09:40</td>
                  </Tooltip>
                )}
              </tr>
              <tr>
                {schedule.includes("10:00") ? (
                  <Tooltip title="Slot Available">
                    <td className="box-available">10:00</td>
                  </Tooltip>
                ) : (
                  <Tooltip title="Slot Busy">
                    <td className="box-unavailable">10:00</td>
                  </Tooltip>
                )}
                {schedule.includes("10:20") ? (
                  <Tooltip title="Slot Available">
                    <td className="box-available">10:20</td>
                  </Tooltip>
                ) : (
                  <Tooltip title="Slot Busy">
                    <td className="box-unavailable">10:20</td>
                  </Tooltip>
                )}
                {schedule.includes("10:40") ? (
                  <Tooltip title="Slot Available">
                    <td className="box-available">10:40</td>
                  </Tooltip>
                ) : (
                  <Tooltip title="Slot Busy">
                    <td className="box-unavailable">10:40</td>
                  </Tooltip>
                )}
              </tr>
              <tr>
                {schedule.includes("11:00") ? (
                  <td className="box-available">10:00</td>
                ) : (
                  <td className="box-unavailable">11:00</td>
                )}
                {schedule.includes("11:20") ? (
                  <td className="box-available">10:20</td>
                ) : (
                  <td className="box-unavailable">11:20</td>
                )}
                {schedule.includes("11:40") ? (
                  <td className="box-available">10:40</td>
                ) : (
                  <td className="box-unavailable">11:40</td>
                )}
              </tr>
              <tr>
                {schedule.includes("12:00") ? (
                  <td className="box-available">12:00</td>
                ) : (
                  <td className="box-unavailable">12:00</td>
                )}
                {schedule.includes("12:20") ? (
                  <td className="box-available">12:20</td>
                ) : (
                  <td className="box-unavailable">12:20</td>
                )}
                {schedule.includes("12:40") ? (
                  <td className="box-available">12:40</td>
                ) : (
                  <td className="box-unavailable">12:40</td>
                )}
              </tr>
              <tr>
                {schedule.includes("13:00") ? (
                  <td className="box-available">13:00</td>
                ) : (
                  <td className="box-unavailable">13:00</td>
                )}
                {schedule.includes("13:20") ? (
                  <td className="box-available">13:20</td>
                ) : (
                  <td className="box-unavailable">13:20</td>
                )}
                {schedule.includes("13:40") ? (
                  <td className="box-available">13:40</td>
                ) : (
                  <td className="box-unavailable">13:40</td>
                )}
              </tr>
              <tr>
                {schedule.includes("14:00") ? (
                  <td className="box-available">14:00</td>
                ) : (
                  <td className="box-unavailable">14:00</td>
                )}
                {schedule.includes("14:20") ? (
                  <td className="box-available">14:20</td>
                ) : (
                  <td className="box-unavailable">14:20</td>
                )}
                {schedule.includes("14:40") ? (
                  <td className="box-available">14:40</td>
                ) : (
                  <td className="box-unavailable">14:40</td>
                )}
              </tr>
              <tr>
                {schedule.includes("15:00") ? (
                  <td className="box-available">15:00</td>
                ) : (
                  <td className="box-unavailable">15:00</td>
                )}
                {schedule.includes("15:20") ? (
                  <td className="box-available">15:20</td>
                ) : (
                  <td className="box-unavailable">15:20</td>
                )}
                {schedule.includes("15:40") ? (
                  <td className="box-available">15:40</td>
                ) : (
                  <td className="box-unavailable">15:40</td>
                )}
              </tr>
              <tr>
                {schedule.includes("16:00") ? (
                  <td className="box-available">16:00</td>
                ) : (
                  <td className="box-unavailable">16:00</td>
                )}
                {schedule.includes("16:20") ? (
                  <td className="box-available">16:20</td>
                ) : (
                  <td className="box-unavailable">16:20</td>
                )}
                {schedule.includes("16:40") ? (
                  <td className="box-available">16:40</td>
                ) : (
                  <td className="box-unavailable">16:40</td>
                )}
              </tr>
              <tr>
                {schedule.includes("17:00") ? (
                  <td className="box-available">17:00</td>
                ) : (
                  <td className="box-unavailable">17:00</td>
                )}
                {schedule.includes("17:20") ? (
                  <td className="box-available">17:20</td>
                ) : (
                  <td className="box-unavailable">17:20</td>
                )}
                {schedule.includes("17:40") ? (
                  <td className="box-available">17:40</td>
                ) : (
                  <td className="box-unavailable">17:40</td>
                )}
              </tr>
              <tr>
                {schedule.includes("18:00") ? (
                  <td className="box-available">18:00</td>
                ) : (
                  <td className="box-unavailable">18:00</td>
                )}
                {schedule.includes("18:20") ? (
                  <td className="box-available">18:20</td>
                ) : (
                  <td className="box-unavailable">18:20</td>
                )}
                {schedule.includes("18:40") ? (
                  <td className="box-available">18:40</td>
                ) : (
                  <td className="box-unavailable">18:40</td>
                )}
              </tr>
            </tbody>
          </table>
          <div className="reference-container">
            <span className="reference-text">
              {t(
                "Calendar.Yellow box indicates that the slot is reserved for erst treatments, locked or busy by another treatment"
              )}
            </span>
          </div>
        </>
      )}
    </>
  );
};

export default ScheduleTable;
