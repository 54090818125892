import React from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";

export const ForgotPasswordStep0 = ({
  setEmail,
  handleValidateEmail,
  loading,
}) => {
  // translations
  const { t } = useTranslation();

  return (
    <>
      <div className="mt-5">{t("ForgotPassword.5")}</div>

      <div className="form-group mt-4">
        <label className="title-inputs">E-mail</label>
        <br />
        <br />
        <input
          type="text"
          onChange={(e) => setEmail(e.target.value)}
          className="input-control"
          placeholder="name@domain.com"
          required
        />
      </div>

      {loading ? (
        <div className="d-flex justify-content-center">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <>
          <button
            type="submit"
            className="blue-btn"
            style={{ width: "100%" }}
            onClick={handleValidateEmail}
          >
            {t("ForgotPassword.6")}
          </button>
        </>
      )}
    </>
  );
};

export default ForgotPasswordStep0;
