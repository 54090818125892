import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import CircularProgress from "@material-ui/core/CircularProgress";
import GetUserToken from "../../utils/GetUserToken";

export const NewInsurance = ({
  setIsAlert,
  showmodal,
  setShowmodal,
  fetchData,
}) => {
  // auth
  const userToken = GetUserToken();
  // translation
  const { t } = useTranslation();
  // data
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  // utils
  const [saving, setSaving] = useState(false);

  const handleSubmit = async () => {
    setSaving(true);

    const url = process.env.REACT_APP_API_URL + "/insurances/create";

    const body = {
      title: title,
      description: description,
    };

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      body: JSON.stringify(body),
    };

    await fetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          setShowmodal(false);
          setIsAlert({
            open: true,
            severity: "success",
            message: t("Insurances.Insurance added!"),
          });
          setSaving(false);
          fetchData();
          setTitle("");
          setDescription("");
        } else {
          setIsAlert({
            open: true,
            severity: "error",
            message: t("Messages.The operation could not be processed"),
          });
        }
      })
      .catch((error) =>
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.Something went wrong, please try again"),
        })
      );
  };

  return (
    <>
      <Modal
        show={showmodal}
        onHide={() => setShowmodal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ background: "#F4F5FA" }}>
          <Modal.Title>{t("Insurances.New Insurance")}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#F4F5FA" }}>
          <div className="form-group">
            <label className="title-inputs">{t("Insurances.Title")}</label>
            <input
              required
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="input-control"
            />
          </div>
          <div className="form-group">
            <label className="title-inputs">
              {t("Insurances.Description")}
            </label>
            <TextareaAutosize
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              aria-label="minimum height"
              minRows={3}
              className="input-control"
            />
          </div>
          <div className="d-flex justify-content-center">
            {saving ? (
              <>
                <CircularProgress color="secondary" />
              </>
            ) : (
              <>
                {title != "" ? (
                  <>
                    <button className="blue-btn" onClick={handleSubmit}>
                      {t("Buttons.Save")}
                    </button>
                  </>
                ) : (
                  <>
                    <button disabled className="blue-btn-disabled">
                      {t("Buttons.Save")}
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewInsurance;
