import React from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import NavbarPublic from "../../NavbarPublic";

export const LoginStep1 = ({
  error,
  setError,
  loading,
  code,
  setCode,
  handleSubmit,
  setEmail,
  setPassword,
  setStep,
}) => {
  // translations
  const { t } = useTranslation();

  return (
    <>
      <div className="container-fluid">
        <div className="row" style={{ height: "100vh" }}>
          <div className="col-xs-12 col-md-6" style={{ paddingTop: "20px" }}>
            <NavbarPublic />

            <form onSubmit={handleSubmit} className="mt-5 form-padding">
              <h1 className="main-title">{t("Login.0")}</h1>

              <div className="form-group mt-5">
                <label className="title-inputs">
                  🔒&nbsp;2FA-{t("Login.7")}:
                </label>
                <br />
                <div className="mt-3">
                  <p className="subtitle-inputs">{t("Login.8")}</p>
                  <p className="subtitle-inputs">{t("Login.9")}</p>
                </div>
                <br />
                <input
                  type="text"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  className="input-control"
                  placeholder="2FA Code"
                  required
                />
              </div>

              {loading ? (
                <div className="d-flex justify-content-center">
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <>
                  <button
                    type="submit"
                    className="blue-btn"
                    style={{ width: "100%" }}
                  >
                    {t("Login.10")}
                  </button>{" "}
                </>
              )}
            </form>
          </div>

          <div className="d-none d-sm-block  col-6  login-background"></div>
        </div>
      </div>

      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setError(false)}
          severity="error"
        >
          {t("Login.11")}
        </MuiAlert>
      </Snackbar>
    </>
  );
};
