import GetUserToken from "../utils/GetUserToken";
import { parseJSON } from "../utils/parseJSON";

const urlBase = `${process.env.REACT_APP_API_URL}/insurances`;
const readUrl = (url = "") => `${urlBase}/${url}`;

class Insurance {
  async getOne({ id }) {
    const auth = `${GetUserToken()}`;

    let res = await fetch(readUrl(`show/${id}`), {
      method: "GET",
      headers: {
        Authorization: auth,
        "api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
    });

    res = await parseJSON(res);
    return res;
  }

  async getAll() {
    console.log("getAll - insurances - service");
    const auth = `${GetUserToken()}`;

    let res = await fetch(readUrl(), {
      method: "GET",
      headers: {
        Authorization: auth,
        "api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
    });

    res = await parseJSON(res);
    return res;
  }

  async getAllByCompany({ id }) {
    const url = readUrl(`getInsurancesByCompany?company_id=${id}`);

    let res = await fetch(url, {
      method: "GET",
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
    });

    res = await parseJSON(res);
    return res;
  }

  async createOne(payload) {
    const formData = new FormData();
    const keys = Object.keys(payload);

    for (const key of keys) {
      const value = payload[key];
      formData.append(key, value);
    }

    const auth = `${GetUserToken()}`;

    let res = await fetch(readUrl("create"), {
      method: "POST",
      headers: {
        Authorization: auth,
        "api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
      body: formData,
    });

    res = await parseJSON(res);
    return res;
  }

  async update(payload) {
    const { id, ...rest } = payload;
    const formData = new FormData();
    const keys = Object.keys(rest);

    for (const key of keys) {
      const value = rest[key];
      formData.append(key, value);
    }

    const auth = `${GetUserToken()}`;

    let res = await fetch(readUrl(id), {
      method: "POST",
      headers: {
        Authorization: auth,
        "api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
      body: formData,
    });

    res = await parseJSON(res);
    return res;
  }
}

const insurance = new Insurance();
export default insurance;
