import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import { SliderPicker } from "react-color";
import { useTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import Switch from "@mui/material/Switch";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import AlertComponent from "../alerts/Alert";
import GetUserToken from "../../utils/GetUserToken";
import ImageCropMaterialUi from "./ImageCropMaterialUi";
import { getAllTreatments } from "../../redux/slices/treatment";
import { updateDoctor } from "../../redux/slices/doctor";
import { updateUser } from "../../redux/slices/user";
import { hideModal, showModal } from "../../redux/slices/modal";
import { useFileUploadProgress } from "../../providers/FileUploader";
import { EntityEnum } from "../../services/media";

const isValidJson = (jsonString) => {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (error) {
    return false;
  }
};

const Component = ({
  id,
  company,
  updateDoctorStates,
  updateUserStates,
  handleeditClose,
  fetchData,
  isOnboarding,
  handleStepComplete,
}) => {
  const dispatch = useDispatch();
  const { uploadFiles, areFiles, resetFileUploadProgress } =
    useFileUploadProgress();
  const { t } = useTranslation();
  const userToken = GetUserToken();
  const [loading, setLoading] = useState(true);
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  // data
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [clinicTreatments, setClinicTreatments] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [bio, setBio] = useState("");
  const [distinctions, setDistinctions] = useState("");
  const [signature, setSignature] = useState();
  const [avatar, setAvatar] = useState();
  //helper
  const [initialSignature, setInitialSignature] = useState(null);
  const [initialAvatar, setInitialAvatar] = useState(null);
  //
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [color, setColor] = useState("");
  const [archived, setArchived] = useState();
  const [user, setUser] = useState();
  const [futureCitations, setFutureCitations] = useState([]);
  // User Roles
  const [admin, setAdmin] = useState();
  const [therapist, setTherapist] = useState();
  const [secretary, setSecretary] = useState();
  const [account, setAccount] = useState();
  // Dialog
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setArchived(!archived);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = () => {
    setArchived(!archived);
  };

  const handleChangeAdmin = () => {
    setAdmin(!admin);
  };

  const handleChangeTherapist = () => {
    setTherapist(!therapist);
  };

  const handleChangeSecretary = () => {
    setSecretary(!secretary);
  };

  const handleChangeAccount = () => {
    setAccount(!account);
  };

  const handleTreatmentChange = (e, value) => {
    setTreatments(value);
  };

  const handleSignatureChange = (event) => {
    setSignature(event.target.value);
  };

  const handleAvatarChange = (event) => {
    setAvatar(event.target.value);
  };

  const fetchDoctor = async () => {
    const url = process.env.REACT_APP_API_URL + "/doctors/" + id;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    const response = await fetch(url, requestOptions);
    const json = await response.json();
    const user = await json.user;

    setFullname(json.name);
    setColor(json.color);
    setArchived(json.archived);
    setBio(json.bio);
    setDistinctions(json.distinctions);
    setSignature(json.signature?.url);
    setAvatar(json.avatar?.url);
    setInitialSignature(json.signature?.url);
    setInitialAvatar(json.avatar?.url);
    setUser(user);
    setEmail(user.email);
    setTherapist(user.roles.includes("doctor"));
    setSecretary(user.roles.includes("secretary"));
    setAdmin(user.roles.includes("admin"));
    setAccount(user.roles.includes("account"));

    if (isValidJson(json.treatments)) {
      setTreatments(JSON.parse(json.treatments));
    } else {
      setTreatments([]);
    }
  };

  const getTreatments = async () => {
    //dispatch(getAllTreatments({ filters: { archived: false } }))//
    const url = process.env.REACT_APP_API_URL + "/doctors/getTreatments";

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    let response = await fetch(url, requestOptions);
    response = await response.json();
    const allTreatments = response.clinicTreatments;

    const activeTreatments = allTreatments.filter((obj) => {
      return obj.archived === false;
    });

    let availableTreatments = activeTreatments.map((item) => ({
      id: item.id,
      name: item.name,
    }));

    setClinicTreatments(availableTreatments);
  };

  const getFutureCitations = async () => {
    //dispatch(getAllCitations({ filters: { doctor_id: id,  } }))
    const url =
      process.env.REACT_APP_API_URL_V2 +
      "/citations/get_doctor_future_citations/" +
      id;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    let response = await fetch(url, requestOptions);
    response = await response.json();

    setFutureCitations(response);
  };

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (!treatments || (!!treatments && treatments.length <= 0)) {
        setIsAlert({
          open: true,
          severity: "warning",
          message: t("Messages.You must select at least one treatment"),
        });

        return;
      }

      const signatureChanged = signature !== initialSignature;
      const avatarChanged = avatar !== initialAvatar;

      if (signatureChanged && avatarChanged) {
        setIsAlert({
          open: true,
          severity: "warning",
          message: t(
            "Messages.Please upload one file at a time, a signature image or an avatar image"
          ),
        });
        setTimeout(() => {
          handleeditClose();
        }, 5000);
        return;
      }

      const thereAreFilesToUpload =
        signature ||
        (avatar &&
          ((Array.isArray(signature) && areFiles(signature)) ||
            (Array.isArray(avatar) && areFiles(avatar))));

      if (thereAreFilesToUpload) {
        dispatch(showModal("modal-wait-a-minute"));
      }

      try {
        if (thereAreFilesToUpload) {
          const uploadedFiles = await uploadFiles(
            {
              ...(signature &&
                signature.length > 0 && { signature: signature }),
              ...(avatar &&
                avatar.length > 0 && {
                  avatar: avatar,
                }),
            },
            EntityEnum.DOCTOR,
            {
              idDoctor: id,
              idCompany: company?.id,
            }
          );

          const updatedValues = {
            id,
            name: fullname,
            treatments: JSON.stringify(treatments),
            color,
            archived,
            bio,
            distinctions,
            signature:
              uploadedFiles?.signature && uploadedFiles?.signature[0]
                ? uploadedFiles?.signature[0]
                : null,
            avatar:
              uploadedFiles?.avatar && uploadedFiles?.avatar[0]
                ? uploadedFiles?.avatar[0]
                : null,
          };

          dispatch(updateDoctor(updatedValues));

          const roles = [];
          admin && roles.push("admin");
          therapist && roles.push("doctor");
          secretary && roles.push("secretary");
          account && roles.push("account");

          dispatch(updateUser({ id: user?.id, roles }));
        } else {
          const updatedValues = {
            id,
            name: fullname,
            treatments: JSON.stringify(treatments),
            color,
            archived,
            bio,
            distinctions,
          };

          dispatch(updateDoctor(updatedValues));

          const roles = [];
          admin && roles.push("admin");
          therapist && roles.push("doctor");
          secretary && roles.push("secretary");
          account && roles.push("account");

          dispatch(updateUser({ id: user?.id, roles }));
        }
      } catch (error) {
        console.error("Error during file uploads:", error);
      } finally {
        dispatch(hideModal());
      }
    },
    [
      id,
      fullname,
      treatments,
      color,
      archived,
      bio,
      distinctions,
      company,
      admin,
      therapist,
      signature,
      avatar,
      secretary,
      account,
    ]
  );

  const initData = async () => {
    await fetchDoctor();
    await getFutureCitations();
    await getTreatments();
    setLoading(false);
  };

  useEffect(() => {
    if (updateDoctorStates.success && updateUserStates.success) {
      setIsAlert({
        open: true,
        severity: "success",
        message: t("Messages.The operation was successful"),
      });

      setTimeout(() => {
        if (isOnboarding) {
          handleStepComplete();
        }
        window.location.reload();
      }, 2000);
    }

    if (updateDoctorStates.error || updateUserStates.error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.The operation could not be processed"),
      });
    }
  }, [updateDoctorStates, updateUserStates]);

  useEffect(() => {
    initData();
  }, []);

  const load = (
    <>
      <div className="d-flex justify-content-center">
        <CircularProgress color="secondary" />
      </div>
    </>
  );

  return (
    <>
      <form onSubmit={handleSubmit}>
        {loading ||
        updateDoctorStates.loading ||
        updateUserStates.loading ||
        admin === "" ? (
          load
        ) : (
          <>
            <div className="form-group">
              <label htmlFor="firstname" className="title-inputs">
                {t("EditDoctor.Name")}
              </label>
              <input
                type="text"
                disabled={archived ? true : false}
                value={fullname}
                onChange={(e) => setFullname(e.target.value)}
                className="input-control"
              />
            </div>

            <div className="form-group">
              <label htmlFor="email" className="title-inputs">
                {t("EditDoctor.Email")}
              </label>
              <input
                type="text"
                disabled={true}
                value={email}
                className="input-control"
                style={{ color: "gray", fontStyle: "italic" }}
              />
            </div>

            <div className="form-group">
              <label
                htmlFor="color"
                className="title-inputs"
                style={{ marginBottom: "5px" }}
              >
                {t("EditDoctor.Color for calendar")}
              </label>
              <SliderPicker
                color={color}
                onChangeComplete={(color) => {
                  setColor(color.hex);
                }}
              />
            </div>

            <div className="form-group">
              <label htmlFor="bio" className="title-inputs">
                {t("EditDoctor.Therapist")}-Bio
              </label>
              <TextareaAutosize
                type="text"
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                aria-label="minimum height"
                minRows={2}
                className="input-control"
              />
            </div>

            <div className="form-group">
              <label htmlFor="distinctions" className="title-inputs">
                {t("EditDoctor.Distinctions")}
              </label>
              <TextareaAutosize
                type="text"
                value={distinctions}
                onChange={(e) => setDistinctions(e.target.value)}
                aria-label="minimum height"
                minRows={2}
                className="input-control"
              />
            </div>

            <label
              htmlFor="color"
              className="title-inputs"
              style={{ marginBottom: "5px" }}
            >
              {t("EditDoctor.Actions and roles")}
            </label>

            <div
              className="row"
              style={{
                backgroundColor: "white",
                borderRadius: "5px",
                marginLeft: "0px",
                marginRight: "0px",
                border: "1px solid #adb5bd",
              }}
            >
              <div className="col-3" style={{ textAlign: "center" }}>
                <Tooltip
                  title="If the therapist is archived, it'll not appear in the calendar and reminders."
                  placement="right"
                >
                  <label className="title-inputs">
                    {t("EditDoctor.Archived")}
                  </label>
                </Tooltip>

                <br />

                <Switch
                  label="checkbox"
                  checked={archived}
                  onChange={
                    futureCitations.length >= 1 && archived === false
                      ? handleClickOpen
                      : handleChange
                  }
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
              <div className="col-2" style={{ textAlign: "center" }}>
                <Tooltip
                  title="Admins has all of the permissions"
                  placement="right"
                >
                  <label className="title-inputs">
                    {t("EditDoctor.Admin")}
                  </label>
                </Tooltip>

                <br />

                <Switch
                  label="checkbox"
                  checked={admin}
                  disabled={archived}
                  onChange={handleChangeAdmin}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
              <div className="col-2" style={{ textAlign: "center" }}>
                <Tooltip
                  title="Therapist has access to appointments with files, patients, reminders and herbs"
                  placement="right"
                >
                  <label className="title-inputs">
                    {t("EditDoctor.Therapist")}
                  </label>
                </Tooltip>

                <br />

                <Switch
                  label="checkbox"
                  checked={therapist}
                  disabled={archived}
                  onChange={handleChangeTherapist}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>

              <div className="col-2" style={{ textAlign: "center" }}>
                <Tooltip
                  title="Secretary has access to appointments, patients and reminders"
                  placement="right"
                >
                  <label className="title-inputs">
                    {t("EditDoctor.Secretary")}
                  </label>
                </Tooltip>

                <br />

                <Switch
                  label="checkbox"
                  checked={secretary}
                  disabled={archived}
                  onChange={handleChangeSecretary}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>

              <div className="col-2" style={{ textAlign: "center" }}>
                <Tooltip
                  title="Accountant has access to reminders and invoices"
                  placement="right"
                >
                  <label className="title-inputs">
                    {t("EditDoctor.Accountant")}
                  </label>
                </Tooltip>

                <br />

                <Switch
                  label="checkbox"
                  checked={account}
                  disabled={archived}
                  onChange={handleChangeAccount}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col">
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert
                    severity="warning"
                    style={{ justifyContent: "center" }}
                    className="title-inputs"
                  >
                    {t("EditDoctor.Warning")}
                  </Alert>
                </Stack>
              </div>
            </div>

            <div className="form-group">
              <label
                htmlFor="color"
                className="title-inputs"
                style={{
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
              >
                {t("EditDoctor.Treatments")}
              </label>
              <Autocomplete
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                multiple
                name="treatments"
                id="treatments"
                options={clinicTreatments}
                value={treatments || []}
                disableCloseOnSelect
                onChange={(e, value) => {
                  handleTreatmentChange(e, value);
                }}
                getOptionLabel={(option) => option.name}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8, color: "var(--primary-color)" }}
                      checked={selected}
                    />
                    {option.name}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Treatments"
                    placeholder={t("Buttons.Select")}
                    fullWidth
                  />
                )}
              />
            </div>

            <div className="form-group">
              <label
                htmlFor="signature"
                className="title-inputs"
                style={{
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
              >
                {t("EditDoctor.Signature")}
              </label>
              <ImageCropMaterialUi
                name="signature"
                defaultValue={signature}
                showZoomControl
                showRotationControl
                onInput={(eventSignature) =>
                  handleSignatureChange(eventSignature)
                }
              />
            </div>

            <div className="form-group">
              <label
                htmlFor="avatar"
                className="title-inputs"
                style={{
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
              >
                {t("Avatar")}
              </label>
              <ImageCropMaterialUi
                name="avatar"
                defaultValue={avatar}
                showZoomControl
                showRotationControl
                onInput={(eventAvatar) => handleAvatarChange(eventAvatar)}
              />
            </div>

            <div className="text-center">
              <button className="blue-btn" type="submit">
                {t("Buttons.Save")}
              </button>
            </div>
          </>
        )}

        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("EditDoctorAlert.Beware!")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                className="text-center"
              >
                {futureCitations.map((item, i) => (
                  <span key={i}>
                    ･ {item.date} - {item.name}
                    <br />
                  </span>
                ))}
                <br />
                <span className="fw-bold">
                  {t("EditDoctorAlert.Are you sure you want to archive it?")}
                </span>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                {t("Buttons.Cancel")}
              </Button>
              <button
                className="blue-btn"
                id={isOnboarding ? "assign_treatment" : ""}
                type="submit"
                onClick={() => {
                  handleSubmit();
                  handleClose();
                }}
              >
                {t("Buttons.Archive")}
              </button>
            </DialogActions>
          </Dialog>
        </div>
      </form>

      {isAlert.open && (
        <AlertComponent
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
    </>
  );
};

const state = ({ doctorStore, companyStore, user }) => {
  const { data: company } = companyStore.one;

  return {
    company,
    updateDoctorStates: doctorStore.update,
    updateUserStates: user.update,
  };
};

export default connect(state)(Component);
