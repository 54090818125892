import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import services from "../../services";

// Define initial state
const initialState = {
  one: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  all: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  create: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  update: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  duplicate: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  download: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
};

// Async action to get one prescription
const getOnePrescription = createAsyncThunk("prescription/getOne", async (payload) => {
  console.log("get one - prescription - action", payload);
  const { response, error } = await services.prescription.getOne(payload);
  if (error) {
    throw new Error(error);
  }
  return response;
});

// Async action to get all prescriptions
const getAllPrescriptions = createAsyncThunk("prescription/getAll", async (payload) => {
  console.log("get all - prescription - action", payload);
  const { response, error } = await services.prescription.getAll(payload);
  if (error) {
    throw new Error(error);
  }
  return response;
});

// Async action to create one prescription
const createOnePrescription = createAsyncThunk(
  "prescription/createOne",
  async (payload) => {
    console.log("create one - prescription - action", payload);
    const { response, error } = await services.prescription.createOne(payload);
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Async action to reset a prescription creation
const resetCreateOnePrescription = createAsyncThunk("prescription/resetCreateOne", async (payload) => {
  console.log("reset creation - prescription - action", payload);
});

// Async action to update a prescription
const updatePrescription = createAsyncThunk("prescription/update", async (payload) => {
  console.log("update - prescription - action", payload);
  const { response, error } = await services.prescription.update(payload);
  if (error) {
    throw new Error(error);
  }
  return response;
});

// Async action to reset a prescription update
const resetUpdateOnePrescription = createAsyncThunk("prescription/resetUpdateOne", async (payload) => {
  console.log("reset update - prescription - action", payload);
});

// Async action to download a prescription
const downloadPrescription = createAsyncThunk("prescription/downloadPrescription", async (payload) => {
  console.log("download - prescription - action", payload);
  const { response, error } = await services.prescription.download(payload);
  if (error) {
    throw new Error(error);
  }
  return response;
});

const resetDownloadPrescription = createAsyncThunk("prescription/resetDownloadPrescription", async () => {
  console.log("reset download - prescription - action");
});

// Async action to duplicate a prescription
const duplicatePrescription = createAsyncThunk("prescription/duplicatePrescription", async (payload) => {
  console.log("duplicate - prescription - action", payload);
  const { response, error } = await services.prescription.duplicate(payload);
  if (error) {
    throw new Error(error);
  }
  return response;
});

const resetDuplicatePrescription = createAsyncThunk("prescription/resetDuplicatePrescription", async () => {
  console.log("reset duplicate - prescription - action");
});

// Create a prescription slice
const prescriptionSlice = createSlice({
  name: "prescription",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadPrescription.pending, (state, action) => {
        state.download.data = null;
        state.download.loading = true;
        state.download.success = false;
        state.download.error = false;
      })
      .addCase(downloadPrescription.fulfilled, (state, action) => {
        state.download.data = action.payload;
        state.download.loading = false;
        state.download.success = true;
        state.download.error = false;
      })
      .addCase(downloadPrescription.rejected, (state, action) => {
        state.download.data = null;
        state.download.loading = false;
        state.download.success = false;
        state.download.error = action.error.message;
      })
      .addCase(resetDownloadPrescription.fulfilled, (state, action) => {
        state.download.data = null;
        state.download.loading = false;
        state.download.success = false;
        state.download.error = false;
      })
      .addCase(getOnePrescription.pending, (state, action) => {
        state.one.data = null;
        state.one.loading = true;
        state.one.success = false;
        state.one.error = false;
      })
      .addCase(getOnePrescription.fulfilled, (state, action) => {
        state.one.data = action.payload;
        state.one.loading = false;
        state.one.success = true;
        state.one.error = false;
      })
      .addCase(getOnePrescription.rejected, (state, action) => {
        state.one.data = null;
        state.one.loading = false;
        state.one.success = false;
        state.one.error = action.error.message;
      })
      .addCase(createOnePrescription.pending, (state, action) => {
        state.create.data = null;
        state.create.loading = true;
        state.create.success = false;
        state.create.error = false;
      })
      .addCase(createOnePrescription.fulfilled, (state, action) => {
        state.create.data = action.payload;
        state.create.loading = false;
        state.create.success = true;
        state.create.error = false;
      })
      .addCase(createOnePrescription.rejected, (state, action) => {
        state.create.data = null;
        state.create.loading = false;
        state.create.success = false;
        state.create.error = action.error.message;
      })
      .addCase(resetCreateOnePrescription.fulfilled, (state, action) => {
        state.create.data = null;
        state.create.loading = false;
        state.create.success = false;
        state.create.error = false;
      })
      .addCase(getAllPrescriptions.pending, (state) => {
        state.all.data = null;
        state.all.loading = true;
        state.all.success = false;
        state.all.error = false;
      })
      .addCase(getAllPrescriptions.fulfilled, (state, action) => {
        state.all.data = action.payload;
        state.all.loading = false;
        state.all.success = true;
        state.all.error = false;
      })
      .addCase(getAllPrescriptions.rejected, (state, action) => {
        state.all.data = null;
        state.all.loading = false;
        state.all.success = false;
        state.all.error = action.error.message;
      })
      .addCase(updatePrescription.pending, (state) => {
        state.update.data = null;
        state.update.loading = true;
        state.update.success = false;
        state.update.error = false;
      })
      .addCase(updatePrescription.fulfilled, (state, action) => {
        state.update.data = action.payload;
        state.update.loading = false;
        state.update.success = true;
        state.update.error = false;
        state.one.data = action.payload;
      })
      .addCase(updatePrescription.rejected, (state, action) => {
        state.update.data = null;
        state.update.loading = false;
        state.update.success = false;
        state.update.error = action.error.message;
      })
      .addCase(resetUpdateOnePrescription.fulfilled, (state, action) => {
        state.update.data = null;
        state.update.loading = false;
        state.update.success = false;
        state.update.error = false;
      })
      .addCase(duplicatePrescription.pending, (state) => {
        state.duplicate.data = null;
        state.duplicate.loading = true;
        state.duplicate.success = false;
        state.duplicate.error = false;
      })
      .addCase(duplicatePrescription.fulfilled, (state, action) => {
        state.duplicate.data = action.payload;
        state.duplicate.loading = false;
        state.duplicate.success = true;
        state.duplicate.error = false;

        // Update state.all.data
        if (state.all.data) {
          state.all.data = [...state.all.data, action.payload];
        }
      })
      .addCase(duplicatePrescription.rejected, (state, action) => {
        state.duplicate.data = null;
        state.duplicate.loading = false;
        state.duplicate.success = false;
        state.duplicate.error = action.error.message;
      })
      .addCase(resetDuplicatePrescription.fulfilled, (state, action) => {
        state.duplicate.data = null;
        state.duplicate.loading = false;
        state.duplicate.success = false;
        state.duplicate.error = false;
      });
  },
});

// Export the async actions and the reducer
export {
  downloadPrescription,
  getOnePrescription,
  getAllPrescriptions,
  createOnePrescription,
  updatePrescription,
  resetCreateOnePrescription,
  resetUpdateOnePrescription,
  resetDownloadPrescription,
  duplicatePrescription,
  resetDuplicatePrescription
};

export default prescriptionSlice.reducer;
