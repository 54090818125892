import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import ForgotPasswordStep0 from "./forgotpassword/ForgotPasswordStep0";
import ForgotPasswordStep1 from "./forgotpassword/ForgotPasswordStep1";
import ForgotPasswordStep2 from "./forgotpassword/ForgotPasswordStep2";
import NavbarPublic from "../NavbarPublic";

export default function ForgotPassword() {
  // translations
  const { t } = useTranslation();
  // utils
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [step, setStep] = useState(0);
  // data
  const [email, setEmail] = useState();
  const [bademail, setBademail] = useState(false);
  const [code, setCode] = useState();
  const [badcode, setBadcode] = useState(false);
  const [api_key, setApi_key] = useState("qiXpoint@tcm");
  // const [validemail, setValidemail] = useState(false);
  const [password, setPassword] = useState("");
  // const [password2, setPassword2] = useState("");

  const handleValidateEmail = (e) => {
    setBademail(false);
    setLoading(true);
    e.preventDefault();
    fetch(
      process.env.REACT_APP_API_URL + "/users/checkValidEmail?email=" + email,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }
    ).then((response) => {
      if (response.ok) {
        setStep(1);
      } else {
        setBademail(true);
      }
      setLoading(false);
    });
  };

  const handleValidateCode = (e) => {
    setBadcode(false);
    setLoading(true);
    e.preventDefault();
    fetch(
      process.env.REACT_APP_API_URL +
        "/users/checkValidCode?email=" +
        email +
        "&code=" +
        code,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }
    ).then((response) => {
      if (response.ok) {
        setStep(2);
      } else {
        setBadcode(true);
      }
      setLoading(false);
    });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const forgot = { email, password, api_key };
    fetch(process.env.REACT_APP_API_URL + "/users/resetPassword", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(forgot),
    }).then((response) => {
      if (response.ok) {
        setSuccess(true);
        window.setTimeout(function () {
          window.location.href = "/login";
        }, 2000);
      } else {
        setStep(3);
        setLoading(false);
      }
    });
  };

  const renderSwitch = (step) => {
    switch (step) {
      case 0:
        return (
          <ForgotPasswordStep0
            setEmail={setEmail}
            handleValidateEmail={handleValidateEmail}
            loading={loading}
          />
        );
      case 1:
        return (
          <ForgotPasswordStep1
            setCode={setCode}
            handleValidateCode={handleValidateCode}
            loading={loading}
          />
        );
      case 2:
        return (
          <ForgotPasswordStep2
            password={password}
            setPassword={setPassword}
            handleSubmit={handleSubmit}
            loading={loading}
          />
        );
      default:
        return "Error handling request. Please reload.";
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row" style={{ height: "100vh" }}>
          <div className="col-xs-12 col-md-6" style={{ paddingTop: "20px" }}>
            <NavbarPublic />

            <div className="mt-5 form-padding">
              <h1 className="main-title">{t("ForgotPassword.0")}</h1>

              {renderSwitch(step)}

              <div className="text-center mt-3">
                <a href="/" className="blue-anchors">
                  {t("ForgotPassword.1")}
                </a>
                <br></br>
              </div>
            </div>
          </div>

          <div className="d-none d-sm-block  col-6  login-background"></div>
        </div>
      </div>

      <Snackbar
        open={bademail}
        autoHideDuration={6000}
        onClose={() => setBademail(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setBademail(false)}
          severity="error"
        >
          {t("ForgotPassword.2")}
        </MuiAlert>
      </Snackbar>

      <Snackbar
        open={badcode}
        autoHideDuration={6000}
        onClose={() => setBadcode(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setBadcode(false)}
          severity="error"
        >
          {t("ForgotPassword.3")}
        </MuiAlert>
      </Snackbar>

      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSuccess(false)}
          severity="success"
        >
          {t("ForgotPassword.4")}
        </MuiAlert>
      </Snackbar>
    </>
  );
}
