export const getCustomTheme = (
  primaryColor = "#666666",
  secondaryColor = "#8c8c8c"
) => {
  document.documentElement.style.setProperty("--primary-color", primaryColor);
  // document.documentElement.style.setProperty(
  //   "--mui-palette-primary-main",
  //   primaryColor
  // );
  document.documentElement.style.setProperty(
    "--secondary-color",
    secondaryColor
  );
  // document.documentElement.style.setProperty(
  //   "--mui-palette-secondary-main",
  //   secondaryColor
  // );
};
