import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import HomeTablePatient from "./homepageViews/HomeTablePatient";
import Footer from "./homepageViews/footer";
import { ReactComponent as Calendar } from "../assets/icons/calendar.svg";
//import HomeFilesPatients from "./homepageViews/HomeFilesPatients";
import UploadFile from "./citations/completeappointment/UploadFile";
import ButtonGroupComponent from "./reusableComponents/ButtonGroup";
import PatientPrescriptions from "./patients/PatientPrescriptions";
import ButtonScrollToTop from "./reusableComponents/ButtonScrollToTop";
import "../assets/stylesheets/homePatient.css";

const HomePatients = ({ userId, patientId, navbarRef }) => {
  // translations
  const { t, i18n } = useTranslation();
  // redux
  const state = useSelector((state) => state.user);
  // data
  const [dateState, setDateState] = useState(new Date());

  const scrollToNavbar = () => {
    if (navbarRef && navbarRef.current) {
      navbarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (state.auth.language) {
      switch (state.auth.language) {
        case "EN":
          i18n.changeLanguage("en");
          break;
        case "ES":
          i18n.changeLanguage("es");
          break;
        default:
          i18n.changeLanguage("de");
          break;
      }
    }
    setInterval(() => setDateState(new Date()), 30000);
  }, []);

  return (
    <div className="container-homePatient">
      {/* Support */}
      <ButtonGroupComponent route="homePatient" />

      <div className="frame-homePatient">
        <h4 className="main-title">
          {state.auth ? (
            <div>
              {t("Home.0")}, {state.auth?.fullname}
            </div>
          ) : (
            <></>
          )}
        </h4>

        <div style={{ marginTop: "4px", marginBottom: "4px" }}>
          <span style={{ color: "#857c8e", fontWeight: "300" }}>
            <Calendar
              className="icon"
              style={{ height: "30px", width: "30px" }}
            />
            &nbsp;
            {dateState.toLocaleString([], {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </span>
        </div>

        <br />

        <div
          className="container-fluid"
          style={{ width: "95%", marginLeft: "0px" }}
        >
          <div className="row">
            <div className="col-xs-12 col-lg-9">
              <HomeTablePatient userId={userId} dateState={dateState} />
            </div>

            <div
              className="col-xs-12 col-lg-3 px-lg-4"
              style={{
                backgroundColor: "rgb(255, 255, 255)",
                borderRadius: "10px",
              }}
            >
              {patientId && <UploadFile patient_id={patientId} />}
            </div>

            <div className="col-12">
              <PatientPrescriptions id={patientId} isHomePatient />
            </div>
          </div>
          <ButtonScrollToTop handleClick={scrollToNavbar} />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default HomePatients;
