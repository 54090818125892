import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Modal from "react-bootstrap/Modal";
import Schedule from "./Schedule";
import TimeLocker from "../citations/TimeLocker";
import ArchivedCitations from "./ArchivedCitations";
import Planner from "./Planner";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/stylesheets/homepage.css";
import "../../assets/stylesheets/calendar.css";
import { DoctorCitationV2 } from "../citations/DoctorCitationV2";
import Current from "./Current";
import ButtonGroupComponent from "../reusableComponents/ButtonGroup";

const Calendar = (infoRoute) => {
  const { t, i18n } = useTranslation();

  const state = useSelector((state) => state.user);

  const [show, setShow] = useState(false);
  const [timeLocker, setTimeLocker] = useState(false);

  const handleClose = () => {
    setShow(false);
    setSchedule([]);
  };
  const handleShow = () => setShow(true);

  const handleShowTimeLocker = () => setTimeLocker(true);
  const handleCloseTimeLocker = () => setTimeLocker(false);
  const [assistant, setAssistant] = useState(true);
  const [schedule, setSchedule] = useState([]);
  const [isCalendarUpdated, setIsCalendarUpdated] = useState(false);

  const handleLoad = (e) => {
    console.log(e);
  };

  useEffect(() => {
    if (state.auth.language) {
      switch (state.auth.language) {
        case "EN":
          i18n.changeLanguage("en");
          break;
        case "ES":
          i18n.changeLanguage("es");
          break;
        default:
          i18n.changeLanguage("de");
          break;
      }
    }
  }, []);

  return (
    <>
      {/* Reminder and Support */}
      <ButtonGroupComponent route="calendar" visible />

      <div className="frame">
        <h4 className="main-title mt-2">{t("Calendar.Calendar")}</h4>

        <Tabs
          defaultActiveKey="home"
          id="uncontrolled-tab-example"
          className="mb-3"
          onSelect={(k) => handleLoad(k)}
        >
          <Tab eventKey="home" title={t("Calendar.Current")}>
            <button
              className="blue-btn"
              id="new_appointment_calendar"
              onClick={handleShow}
            >
              {t("Calendar.New Appointment")}
            </button>

            <div className="calendar-frame-new">
              <Current
                infoRoute={infoRoute}
                isCalendarUpdated={isCalendarUpdated}
                setIsCalendarUpdated={setIsCalendarUpdated}
              />
            </div>
          </Tab>

          <Tab eventKey="other" title={t("Calendar.Archived")}>
            <div className="calendar-frame-new">
              <ArchivedCitations infoRoute={infoRoute} />
            </div>
          </Tab>

          {/* {state.doctor?.id ? (
            <Tab eventKey="planner" title="Planner">
              <div className="calendar-frame-new">
                <Planner />
              </div>
            </Tab>
          ) : (
            <></>
          )} */}

          <Tab eventKey="profile" title={t("Calendar.Settings")}>
            <button
              className="blue-btn mb-2"
              id="new_spotlock_calendar"
              onClick={handleShowTimeLocker}
            >
              {t("Calendar.New Spot Lock")}
            </button>
            <div className="calendar-frame-new">
              <Schedule />
            </div>
          </Tab>
        </Tabs>
      </div>

      <Modal
        size={assistant && schedule.length > 0 ? "lg" : <></>}
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body style={{ background: "#F4F5FA" }}>
          <DoctorCitationV2
            schedule={schedule}
            setSchedule={setSchedule}
            assistant={assistant}
            setAssistant={setAssistant}
            handleClose={handleClose}
            infoRoute={infoRoute}
            isCalendarUpdated={isCalendarUpdated}
            setIsCalendarUpdated={setIsCalendarUpdated}
            setShow={setShow}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={timeLocker}
        onHide={handleCloseTimeLocker}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ background: "#F4F5FA" }}>
          <Modal.Title>{t("Calendar.Time Locker")}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#F4F5FA" }}>
          <TimeLocker />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Calendar;
