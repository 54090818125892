import { symptoms } from "../../../../constants/pulse";

export const getValue = (newValue) => {
  let value = symptoms.filter(
    (element) =>
      element.title.replace(/\t/g, " ") === newValue ||
      element.nameEN === newValue ||
      element.nameES === newValue
  )[0].title;
  return value;
};

export const replaceSpecialCharacters = (object) => {
  const newObject = {};
  Object.keys(object).forEach((key) => {
    const valueOriginal = object[key];
    if (valueOriginal) {
      const valueModified = valueOriginal
        .replace(/ă/g, "a")
        .replace(/ē/g, "e")
        .replace(/ĭ/g, "i")
        .replace(/ō/g, "o")
        .replace(/ū/g, "u");
      Object.assign(newObject, { [key]: valueModified });
    } else {
      Object.assign(newObject, { [key]: valueOriginal });
    }
  });
  return newObject;
};

export const restoreSpecialCharacters = (parentArray) => {
  const newArray = parentArray.map((arrayChild) => {
    const newArrayChild = arrayChild.map((element) => {
      switch (element) {
        case "duanmài / kurz":
          return "duănmài / kurz";
        case "sanmài / verstreut":
          return "sănmài / verstreut";
        case "huanmài / entspannt, moderat":
          return "huănmài / entspannt, moderat";
        case "koumài / hohl":
          return "kōumài / hohl";
        case "xumài / leer":
          return "xūmài / leer";
        case "weimài / verschwindend":
          return "wēimài / verschwindend";
        case "jinmài / straff":
          return "jĭnmài / straff";
        default:
          return element;
      }
    });
    return newArrayChild;
  });
  return newArray;
};
