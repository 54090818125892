import React, { useEffect, useRef, useState } from "react";
import { Accordion, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FieldLoader from "../../../FieldLoader";
import TextField from "@mui/material/TextField";
import { Card, Center, Select } from "./styles";
import { symptoms, zungenfarbe, zungengestalt, zungenbelag } from "../../../../../../constants/tongue"
import { initialFormData, getValue } from "./helper";

const Component = ({ name, value, onInput, isLoading }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(initialFormData(name));

  // const fieldOneRef = useRef();
  // const fieldTwoRef = useRef();
  // const fieldThreeRef = useRef();
  // const fieldFourRef = useRef();
  // const fieldFiveRef = useRef();

  useEffect(() => {
    setFormData(initialFormData(name, value));
  }, [value]);

  const handleInputChange = (e) => {
    const newFormData = { ...formData };
    newFormData[e.target.name] = e.target.value;
    setFormData(newFormData);
  };

  const getTranslation = (array, value) => {
    if (value) {
      let name = array.find((element) => element.id === value.id);
      return name ? t(`Zunge.${name.nameEN}`) : value;
    } else {
      return value;
    }
  };

  return (
    <Accordion
      style={{ paddingRight: "0", paddingLeft: "0", marginBottom: "1rem" }}
    >
      <Card>
        <Card.Header
          className="reminders-subtitle"
          style={{
            border: "none",
            background: "white",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="0"
              className="text-center subtitles-patients"
              style={{ textDecoration: "none" }}
              id="accordion_tongue"
            >
              <span>&#8595; &nbsp;</span>
              {t("Titles.Zunge")}
            </Accordion.Toggle>
          </div>
        </Card.Header>

        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <Center>
              <>
                <div style={{ width: "100%", maxWidth: 300 }}>
                  <Form.Group className="mb-3" controlId={`${""}-${1}`}>
                    <Form.Label>{t("Zunge.Tongue color")}</Form.Label>
                    <FieldLoader loading={isLoading}>
                      <input
                        type="hidden"
                        name={`${name}.color_1`}
                        value={formData[`${name}.color_1`]?.id}
                      />
                      <Select
                        id={`${name}.color_1`}
                        size="small"
                        noOptionsText="Not Found"
                        options={zungenfarbe.map((option) =>
                          t(`Zunge.${option.nameEN}`)
                        )}
                        value={getTranslation(zungenfarbe, formData[`${name}.color_1`])}
                        onChange={(event, value) =>
                          handleInputChange({
                            target: {
                              name: `${name}.color_1`,
                              value: getValue(zungenfarbe, value),
                              type: "text",
                              required: false,
                            },
                          })
                        }
                        renderInput={(params) => <TextField {...params} />}
                        // ref={fieldOneRef}
                      />
                    </FieldLoader>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId={`${""}-${1}`}>
                    <Form.Label>{t("Zunge.Tongue shape")}</Form.Label>
                    <FieldLoader loading={isLoading}>
                      <input
                        type="hidden"
                        name={`${name}.shape`}
                        value={formData[`${name}.shape`]?.id}
                      />
                      <Select
                        id={`${name}.shape`}
                        size="small"
                        noOptionsText="Not Found"
                        options={zungengestalt.map((option) =>
                          t(`Zunge.${option.nameEN}`)
                        )}
                        value={getTranslation(zungengestalt, formData[`${name}.shape`])}
                        onChange={(event, value) =>
                          handleInputChange({
                            target: {
                              name: `${name}.shape`,
                              value: getValue(zungengestalt, value),
                              type: "text",
                              required: false,
                            },
                          })
                        }
                        renderInput={(params) => <TextField {...params} />}
                        // ref={fieldTwoRef}
                      />
                    </FieldLoader>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId={`${""}-${1}`}>
                    <Form.Label>{t("Zunge.Tongue coating")}</Form.Label>
                    <FieldLoader loading={isLoading}>
                      <input
                        type="hidden"
                        name={`${name}.coating`}
                        value={formData[`${name}.coating`]?.id}
                      />
                      <Select
                        id={`${name}.coating`}
                        size="small"
                        noOptionsText="Not Found"
                        options={zungenbelag.map((option) =>
                          t(`Zunge.${option.nameEN}`)
                        )}
                        value={getTranslation(zungenbelag, formData[`${name}.coating`])}
                        onChange={(event, value) =>
                          handleInputChange({
                            target: {
                              name: `${name}.coating`,
                              value: getValue(zungenbelag, value),
                              type: "text",
                              required: false,
                            },
                          })
                        }
                        renderInput={(params) => <TextField {...params} />}
                        // ref={fieldThreeRef}
                      />
                    </FieldLoader>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId={`${""}-${1}`}>
                    <Form.Label>{t("Zunge.Tongue color")}</Form.Label>
                    <FieldLoader loading={isLoading}>
                      <input
                        type="hidden"
                        name={`${name}.color_2`}
                        value={formData[`${name}.color_2`]?.id}
                      />
                      <Select
                        id={`${name}.color_2`}
                        size="small"
                        noOptionsText="Not Found"
                        options={zungenfarbe.map((option) =>
                          t(`Zunge.${option.nameEN}`)
                        )}
                        value={getTranslation(zungenfarbe, formData[`${name}.color_2`])}
                        onChange={(event, value) =>
                          handleInputChange({
                            target: {
                              name: `${name}.color_2`,
                              value: getValue(zungenfarbe, value),
                              type: "text",
                              required: false,
                            },
                          })
                        }
                        renderInput={(params) => <TextField {...params} />}
                        // ref={fieldThreeRef}
                      />
                    </FieldLoader>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId={`${""}-${1}`}>
                    <Form.Label>{t("Zunge.Lower tongue veins")}</Form.Label>
                    <FieldLoader loading={isLoading}>
                      <input
                        type="hidden"
                        name={`${name}.veins`}
                        value={formData[`${name}.veins`]?.id}
                      />
                      <Select
                        id={`${name}.veins`}
                        size="small"
                        noOptionsText="Not Found"
                        options={symptoms.map((option) =>
                          t(`Zunge.${option.nameEN}`)
                        )}
                        value={getTranslation(symptoms, formData[`${name}.veins`])}
                        onChange={(event, value) =>
                          handleInputChange({
                            target: {
                              name: `${name}.veins`,
                              value: getValue(symptoms, value),
                              type: "text",
                              required: false,
                            },
                          })
                        }
                        renderInput={(params) => <TextField {...params} />}
                        // ref={fieldThreeRef}
                      />
                    </FieldLoader>
                  </Form.Group>
                </div>

                <div className="main-zunge" style={{ flexShrink: 0 }}>
                  <div
                    className="ni-zunge"
                    // onClick={() => fieldOneRef.current.focus()}
                  >
                    <p
                      className="text-center subtitles-patients"
                      style={{ paddingTop: "20px" }}
                    >
                      Ni
                      <br />
                      Bl
                    </p>
                  </div>
                  <div
                    className="ma-zunge"
                    // onClick={() => fieldFourRef.current.focus()}
                  >
                    <p
                      className="text-center subtitles-patients"
                      style={{ paddingTop: "80px" }}
                    >
                      Ma
                      <br />
                      Mi
                    </p>
                  </div>
                  <div
                    className="le-zunge-left"
                    // onClick={() => fieldTwoRef.current.focus()}
                  >
                    <p
                      className="text-center subtitles-patients"
                      style={{
                        paddingTop: "100px",
                        paddingRight: "20px",
                      }}
                    >
                      Le
                      <br />
                      Gb
                    </p>
                  </div>
                  <div
                    className="le-zunge-right"
                    // onClick={() => fieldFiveRef.current.focus()}
                  >
                    <p
                      className="text-center subtitles-patients"
                      style={{
                        paddingTop: "103px",
                        paddingLeft: "20px",
                      }}
                    >
                      Le
                      <br />
                      Gb
                    </p>
                  </div>
                  <div
                    className="lu-zunge"
                    // onClick={() => fieldThreeRef.current.focus()}
                  >
                    <p
                      className="text-center subtitles-patients"
                      style={{ paddingTop: "20px" }}
                    >
                      Lu
                    </p>
                  </div>
                  <div
                    className="he-zunge"
                    // onClick={() => fieldFiveRef.current.focus()}
                  >
                    <p
                      className="text-center subtitles-patients"
                      style={{ paddingTop: "10px" }}
                    >
                      He
                    </p>
                  </div>
                </div>

                <div style={{ width: "100%" }}></div>
              </>
            </Center>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default Component;
