import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import PharmacyForm from "../components/Form";
import {
  createOnePharmacy,
  resetCreateOnePharmacy,
} from "../../../redux/slices/pharmacy";
import AlertComponent from "../../../components/alerts/Alert";

const keys = {
  country: "",
  country_code: "",
  email: "",
  name: "",
  number: "",
  phone_number: "",
  province: "",
  street: "",
  town: "",
  web: "",
  zip: "",
};

const Component = ({
  pharmacyCreate,
  pharmacyCreateLoading,
  pharmacyCreateError,
  pharmacyCreateSuccess,
  infoRoute,
}) => {
  // translation
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  // route
  const { history } = infoRoute;
  // utils
  const [initialValues] = useState(keys);
  const [alert, setAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    // Get all form elements
    var formElements = event.target.elements;

    // Create an object to store form values
    var formData = {};

    // Iterate over form elements and populate formData object
    for (var i = 0; i < formElements.length; i++) {
      var element = formElements[i];

      if (element.name) {
        let value = element.value;
        formData[element.name] = value;
      }
    }

    console.log("New pharmacy submit payload", formData);
    dispatch(createOnePharmacy(formData));
  };

  useEffect(() => {
    if (pharmacyCreateError) {
      setAlert({
        open: true,
        severity: "error",
        message: t("Messages.The operation could not be processed"),
      });
    }
  }, [pharmacyCreateError]);

  useEffect(() => {
    if (pharmacyCreateSuccess) {
      setAlert({
        open: true,
        severity: "success",
        message: t("Messages.The operation was successful"),
      });
      setTimeout(() => {
        history.push("/pharmacy");
      }, 1000);
    }
  }, [pharmacyCreateSuccess]);

  useEffect(() => {
    if (pharmacyCreateError || pharmacyCreateSuccess) {
      setTimeout(() => {
        dispatch(resetCreateOnePharmacy());
        setAlert({
          open: false,
          severity: "",
          message: "",
        });
      }, 3000);
    }
  }, [pharmacyCreateError, pharmacyCreateSuccess]);

  return (
    <>
      <div>
        <h4 className="main-title-treatments">
          {t("PharmacyNew.New Pharmacy")}
        </h4>
        <p className="blue-anchors">
          <Link className="blue-anchors" to="/pharmacy">
            &#60; {t("PharmacyEdit.Back to pharmacies")}
          </Link>
        </p>
      </div>
      <br />
      <Form
        noValidate
        id="new-pharmacy-form"
        onSubmit={handleSubmit}
        style={{ width: "95%" }}
      >
        <PharmacyForm
          initialValues={initialValues}
          isLoading={pharmacyCreateLoading}
        />
        <div className="d-flex justify-content-end">
          <Button
            className="btnGo"
            form="new-pharmacy-form"
            type="submit"
            target={false}
            disabled={pharmacyCreateLoading}
          >
            {t("PharmacyNew.Create Pharmacy")}
          </Button>
        </div>
      </Form>

      <AlertComponent
        isAlert={alert.open}
        setIsAlert={setAlert}
        severity={alert.severity}
        message={alert.message}
      />
    </>
  );
};

const state = ({ pharmacyStore }) => {
  const {
    data: pharmacyCreate,
    loading: pharmacyCreateLoading,
    error: pharmacyCreateError,
    success: pharmacyCreateSuccess,
  } = pharmacyStore.create;

  return {
    pharmacyCreate,
    pharmacyCreateLoading,
    pharmacyCreateError,
    pharmacyCreateSuccess,
  };
};

export default connect(state)(Component);
