import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  stepperRoot: {
    maxWidth: "100%",
  },
  stepper: {
    backgroundColor: "transparent",
    padding: "20px 0",
    marginBottom: "24px",
    [theme.breakpoints.down("sm")]: {
      overflowX: "auto",
      display: "flex",
      padding: "10px 0",
    },
  },
  step: {
    [theme.breakpoints.down("sm")]: {
      minWidth: "120px",
    },
  },
  label: {
    color: "#8c8c8c",
    fontWeight: "normal",
  },
  activeLabel: {
    color: "#8c8c8c",
    fontWeight: "bold",
  },
  stepIcon: {
    "&.MuiStepIcon-completed": {
      color: "var(--secondary-color)",
    },
  },
  verticalStepper: {
    flexDirection: "column",
  },
}));

export default useStyles;
