import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// import QRCode from "qrcode.react";
import { ReactComponent as CalendarGroup } from "../../../../assets/icons/calendarGroup.svg";
import ButtonScrollToTop from "../../../reusableComponents/ButtonScrollToTop";

export const FourthStepExistingPatient = ({ citation, handleClick }) => {
  // translations
  const { t, i18n } = useTranslation();
  let language = i18n.language;
  // redux
  const state = useSelector((state) => state.companyStore.one.data);
  const phone = state.contact_informations[1]?.value;
  const email = state.contact_informations[0]?.value;

  const dateFormat = (language, date) => {
    let arrayDate = date.split("-");
    let formattedDate =
      language === "en"
        ? `${arrayDate[1]}/${arrayDate[2]}/${arrayDate[0]}`
        : arrayDate.reverse().join("/");
    return formattedDate;
  };

  return (
    <div className="container-homePatient">
      <div className="frame-homePatient padding-right-mobile">
        <div className="scrollmenu">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="citation-box-patient">
                  <div className="row-0">
                    <h1 className="title-citation-box">
                      {t("PatientCitation.0")}
                    </h1>
                  </div>
                  <div className="row-1">
                    <div className="text-left align-self-center">
                      <CalendarGroup />
                    </div>
                    <div className="text-left align-self-center">
                      <h3 className="inside-title-citation-box">
                        {t("PatientCitation.1")}
                      </h3>
                      <h5 className="subtitle-citation-box">
                        {dateFormat(language, citation[0].date)}
                      </h5>
                    </div>
                    <div
                      className="text-left align-self-center"
                      style={{
                        borderLeft: "1px solid #857C8E",
                        paddingLeft: "25px",
                      }}
                    >
                      <h3 className="inside-title-citation-box">
                        {t("PatientCitation.2")}
                      </h3>
                      <h5 className="subtitle-citation-box">
                        {
                          String(String(citation[0].time).split("T")[1]).split(
                            ":00.000"
                          )[0]
                        }
                      </h5>
                    </div>
                    <div
                      className="text-left align-self-center"
                      style={{
                        borderLeft: "1px solid #857C8E",
                        paddingLeft: "25px",
                      }}
                    >
                      <h3 className="inside-title-citation-box">
                        {t("PatientCitation.3")}
                      </h3>
                      <h5 className="subtitle-citation-box">
                        {citation[0].doctor}
                      </h5>
                    </div>
                  </div>

                  <div className="row-2">
                    <hr className="hr-patient-citations"></hr>
                    <h1 className="title-citation-box">
                      {t("PatientCitation.4")}
                    </h1>
                    <br></br>
                    <h4 className="inside-title-citation-box">
                      {t("PatientCitation.5")} ID:{" "}
                      <span className="subtitle-citation-box">
                        #AA-{citation[0].id}
                      </span>
                    </h4>
                    <h4 className="inside-title-citation-box">
                      {t("Settings.Name")}:{" "}
                      <span className="subtitle-citation-box">
                        {citation[0].name}
                      </span>
                    </h4>
                    <h4 className="inside-title-citation-box">
                      Email:{" "}
                      <span className="subtitle-citation-box">
                        {citation[0].email}
                      </span>
                    </h4>
                    {/*<h4 className="inside-title-citation-box">Addresse: <span className="subtitle-citation-box">{citation[0].address}</span></h4>*/}
                    <h4 className="inside-title-citation-box">
                      {t("Calendar.Phone")}:{" "}
                      <span className="subtitle-citation-box">
                        {citation[0].phone}
                      </span>
                    </h4>
                    <h4 className="inside-title-citation-box">
                      {t("PatientCitation.6")}:{" "}
                      <span className="subtitle-citation-box">
                        {citation[0].treatment}
                      </span>
                    </h4>
                    <hr className="hr-patient-citations"></hr>
                    <div className="row">
                      <div className="col-8">
                        <h5 className="subtitle-citation-box-info">
                          {t("PatientCitation.7")}
                        </h5>
                        <h5 className="subtitle-citation-box-info">
                          {t("PatientCitation.8")} <br />
                          {t("PatientCitation.9")}
                        </h5>

                        <a
                          className="subtitle-citation-box-info blue-anchors"
                          style={{ textDecoration: "underline" }}
                          href={"tel:" + phone}
                        >
                          Tel.: {phone}
                        </a>
                        <br />
                        <a
                          className="subtitle-citation-box-info blue-anchors"
                          style={{ textDecoration: "underline" }}
                          href={"mailto:" + email}
                        >
                          {email}
                        </a>
                      </div>
                      <div className="col-4">
                        {/*
                          <QRCode 
                              size={100}
                              value={'https://tcm.qi-point.de/citation/'+citation[0].id} 
                          />*/}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="citation-box-patient-mobile">
                  <div>
                    <h1 className="title-citation-box">
                      {t("PatientCitation.0")}
                    </h1>
                  </div>

                  <div className="mt-3">
                    <CalendarGroup
                      style={{
                        width: "50px",
                        height: "50px",
                        marginBottom: "15px",
                      }}
                    />
                    <br />
                    <h3 className="inside-title-citation-box">
                      {t("PatientCitation.1")}
                    </h3>
                    <h5 className="subtitle-citation-box">
                      {dateFormat(language, citation[0].date)}
                    </h5>
                    <h3 className="inside-title-citation-box">
                      {t("PatientCitation.2")}
                    </h3>
                    <h5 className="subtitle-citation-box">
                      {
                        String(String(citation[0].time).split("T")[1]).split(
                          ":00.000"
                        )[0]
                      }
                    </h5>
                    <h3 className="inside-title-citation-box">
                      {t("PatientCitation.3")}
                    </h3>
                    <h5 className="subtitle-citation-box">
                      {citation[0].doctor}
                    </h5>
                  </div>

                  <div className="mt-3">
                    <h2 className="title-citation-box">
                      {t("PatientCitation.4")}
                    </h2>
                    <br />
                    <h4 className="inside-title-citation-box">
                      {t("PatientCitation.5")} ID: <br />{" "}
                      <span className="subtitle-citation-box">
                        #AA-{citation[0].id}
                      </span>
                    </h4>
                    <h4 className="inside-title-citation-box">
                      {t("Settings.Name")}: <br />{" "}
                      <span className="subtitle-citation-box">
                        {citation[0].name}
                      </span>
                    </h4>
                    <h4 className="inside-title-citation-box">
                      Email: <br />{" "}
                      <span className="subtitle-citation-box">
                        {citation[0].email}
                      </span>
                    </h4>
                    {/*<h4 className="inside-title-citation-box">Addresse: <br /> <span className="subtitle-citation-box">{citation[0].address}</span></h4>*/}
                    <h4 className="inside-title-citation-box">
                      {t("Calendar.Phone")}: <br />{" "}
                      <span className="subtitle-citation-box">
                        {citation[0].phone}
                      </span>
                    </h4>
                    <h4 className="inside-title-citation-box">
                      {t("PatientCitation.6")}: <br />{" "}
                      <span className="subtitle-citation-box">
                        {citation[0].treatment}
                      </span>
                    </h4>
                  </div>

                  <div className="mt-3">
                    <h5 className="subtitle-citation-box-info">
                      {" "}
                      {t("PatientCitation.10")}
                      <br />
                      {t("PatientCitation.11")}{" "}
                    </h5>

                    <br />
                    {/* 
                    <QRCode
                      size={100}
                      value={
                        "https://tcm.qi-point.de/citation/" + citation[0].id
                      }
                    />*/}

                    <br />
                    <br />

                    <h5 className="subtitle-citation-box-info">
                      {t("PatientCitation.12")}
                      <br />
                      {t("PatientCitation.13")}
                      <br /> {t("PatientCitation.14")}
                    </h5>

                    <a
                      className="subtitle-citation-box-info blue-anchors"
                      style={{ textDecoration: "underline" }}
                      href={"tel:" + phone}
                    >
                      Tel.: {phone}
                    </a>
                    <br />
                    <a
                      className="subtitle-citation-box-info blue-anchors"
                      style={{ textDecoration: "underline" }}
                      href={"mailto:" + email}
                    >
                      {email}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ButtonScrollToTop handleClick={handleClick} />
      </div>
    </div>
  );
};

export default FourthStepExistingPatient;
