import React from "react";
import { useTranslation } from "react-i18next";
import { Tabs, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import UserProfile from "./UserProfile";
import UserAccount from "./UserAccount";
import "../../../assets/stylesheets/profileSettings.css";

const ProfileSettings = (infoRoute) => {
  // translations
  const { t } = useTranslation();
  // redux
  const state = useSelector((state) => state.user);
  const patientId = state.auth?.patient_id;
  const userId = state.auth?.user_id;

  return (
    <div className="container-profile-settings">
      <div className="frame-profile-settings">
        <h4 className="main-title">{t("UserProfile.User Profile")}</h4>
        <Tabs
          defaultActiveKey="home"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="home" title={t("UserProfile.Profile")}>
            <UserProfile
              patientId={patientId}
              userId={userId}
              infoRoute={infoRoute}
            />
          </Tab>
          <Tab eventKey="other" title={t("UserProfile.Account information")}>
            <UserAccount
              patientId={patientId}
              userId={userId}
              infoRoute={infoRoute}
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default ProfileSettings;
