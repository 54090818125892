import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../../../../assets/stylesheets/completeappointment.css";
import file from "../../../../assets/icons/file.png";
import Alert from "../../../alerts/Alert";
import { useDispatch, connect } from "react-redux";
import {
  deletePatientDocument,
  getPatientDocuments,
  newPatientDocument,
} from "../../../../redux/slices/patient";
import {
  UploadButton,
  Separator,
  Box,
  BoxTitle,
  FilesList,
  File,
  FileName,
  DeleteFileButton,
} from "./styles";
import { useFileUploadProgress } from "../../../../providers/FileUploader";
import { EntityEnum } from "../../../../services/media";
import { hideModal, showModal } from "../../../../redux/slices/modal";

const ACCEPTED_FORMATS = [
  "xls",
  "xlsx",
  "doc",
  "docx",
  "xml",
  "pdf",
  "png",
  "jpg",
  "jpeg",
];

const MAX_SIZE_MB = 5; // Updated to 5 MB
const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024; // Convert MB to bytes
const germanCharactersRegex = /[äöüßÄÖÜ]/;

const Component = ({
  company,
  patient_id,
  isPatientProfile,
  allDocuments,
  allDocumentsStates,
  newDocumentStates,
  deleteDocumentStates,
}) => {
  const dispatch = useDispatch();
  const { uploadFiles, areFiles, resetFileUploadProgress } =
    useFileUploadProgress();
  const { t } = useTranslation();
  const [documents, setDocuments] = useState();

  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const getAllDocuments = useCallback(() => {
    dispatch(getPatientDocuments({ patient_id }));
  }, [patient_id]);

  const deleteDocument = useCallback(
    (attachment_id) => {
      dispatch(deletePatientDocument({ patient_id, attachment_id }));
    },
    [patient_id]
  );

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const fileExtension = file.name.split(".").pop().toLowerCase();
    const fileName = String(file.name).normalize();

    if (!ACCEPTED_FORMATS.includes(fileExtension)) {
      setIsAlert({
        open: true,
        severity: "warning",
        message: t("Messages.Invalid Format"),
      });
      setDocuments(null);
    } else if (fileSize > MAX_SIZE_BYTES) {
      setIsAlert({
        open: true,
        severity: "warning",
        message: t("Messages.FileTooLarge") + MAX_SIZE_MB + "MB",
      });
      setDocuments(null);
    } else if (germanCharactersRegex.test(fileName)) {
      setIsAlert({
        open: true,
        severity: "warning",
        message: t("Messages.German Characters"),
      });
      setDocuments(null);
    } else {
      setDocuments(file);
    }
  };

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      event.stopPropagation();

      const formData = new FormData(event.target);
      const document = formData.get("document");

      if (!document) {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.NoFileSelected"),
        });

        return;
      }

      const thereAreFilesToUpload = !!document;

      if (thereAreFilesToUpload) {
        dispatch(showModal("modal-wait-a-minute"));
      }

      try {
        if (thereAreFilesToUpload) {
          const uploadedFiles = await uploadFiles(
            {
              ...(!!document && { document: [document] }),
            },
            EntityEnum.PATIENT,
            {
              idPatient: patient_id,
              idCompany: company?.id,
            }
          );

          const updatedValues = {
            id: patient_id,
            document:
              uploadedFiles?.document && uploadedFiles?.document[0]
                ? uploadedFiles?.document[0]
                : null,
          };

          dispatch(newPatientDocument(updatedValues));
          //getAllDocuments();
        }
      } catch (error) {
        console.error("Error during file uploads:", error);
      } finally {
        dispatch(hideModal());
      }
    },
    [patient_id, company, getAllDocuments, dispatch, t, uploadFiles]
  );

  useEffect(() => {
    getAllDocuments();
  }, [patient_id]);

  useEffect(() => {
    if (deleteDocumentStates.success) {
      getAllDocuments();
      setIsAlert({
        open: true,
        severity: "success",
        message: t("Messages.The operation was successful"),
      });
    }

    if (deleteDocumentStates.error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.The operation could not be processed"),
      });
    }
  }, [deleteDocumentStates]);

  useEffect(() => {
    if (newDocumentStates.success) {
      setDocuments(null);
      setIsAlert({
        open: true,
        severity: "success",
        message: t("Messages.The operation was successful"),
      });
      getAllDocuments();
    }

    if (newDocumentStates.error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.The operation could not be processed"),
      });
    }
  }, [newDocumentStates]);

  return (
    <Box style={{ marginTop: "15px" }}>
      {allDocumentsStates?.loading ? (
        <div className="d-flex justify-content-center">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <>
          <form onSubmit={handleSubmit} id="upload-document-form">
            <input
              type="file"
              name="document"
              accept=".xls,.xlsx,.doc,.docx,.xml,.pdf,.png,.jpg"
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="document-input"
            />

            {!documents?.name && (
              <>
                <UploadButton htmlFor="document-input">
                  <CloudUploadIcon style={{ fontSize: 18 }} />
                  {t("Buttons.Browse")}
                </UploadButton>
              </>
            )}

            {!!documents?.name && (
              <>
                <h4 className="key-text-box">
                  {documents?.name.slice(0, 15)}..
                </h4>

                <Button
                  form="upload-document-form"
                  type="submit"
                  variant="contained"
                  color="default"
                  startIcon={<CloudUploadIcon />}
                  size="small"
                  id="upload_documents"
                >
                  {t("Buttons.Upload")}
                </Button>
              </>
            )}
          </form>

          <div style={{ height: 24 }} />

          <Separator />

          <div style={{ height: 24 }} />

          <BoxTitle>{t("CompleteAppointment.Files")}:</BoxTitle>

          <div style={{ height: 8 }} />

          <FilesList>
            {allDocuments?.length > 0 ? (
              <>
                {allDocuments?.map((item, i) => (
                  <File key={`upload-file-table-tbody-tr-${i}`}>
                    <img src={file} style={{ width: "23px", flexShrink: 0 }} />

                    <FileName
                      href={item.url}
                      target="_blank"
                      style={{
                        textDecoration: "none",
                        color: "#857c8e",
                      }}
                    >
                      {item.original_name}
                    </FileName>

                    <DeleteFileButton
                      id={item.id}
                      onClick={() => deleteDocument(item.id)}
                    >
                      x
                    </DeleteFileButton>
                  </File>
                ))}
              </>
            ) : (
              <h4 className="key-text-box">No files yet</h4>
            )}
          </FilesList>
        </>
      )}

      {isAlert.open && (
        <Alert
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
    </Box>
  );
};

const state = ({ patientStore, companyStore }) => {
  const { data, loading, success, error } = patientStore.allPatientDocuments;
  const { data: company } = companyStore.one;

  return {
    company,
    allDocuments: data?.document,
    allDocumentsStates: { loading, success, error },
    newDocumentStates: patientStore.newPatientDocument,
    deleteDocumentStates: patientStore.deletePatientDocument,
  };
};

export default connect(state)(Component);
