import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import FieldLoader from "../../../FieldLoader";
import TextField from "@mui/material/TextField";
import { getAllDiagnosis } from "../../../../../../redux/slices/diagnosis"
import { Select } from "./styles";

const Component = ({ isLoading, name, value, diagnoses, diagnosesLoading }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [formData, setFormData] = useState([]);

  const handleInputChange = (e) => {
    let newFormData = e.target.value.map(option => option.id)
    setFormData(newFormData);
  };

  const initFormData = () => {
    const initialFormData = value && value.length > 0 ? JSON.parse(value) : [];
    setFormData(initialFormData);
  };

  useEffect(() => {
    if (diagnoses) {
      initFormData();
    }
  }, [value, diagnoses]);

  useEffect(() => {
    dispatch(getAllDiagnosis());
  }, []);

  return (
    <Form.Group className="mb-3" controlId="syndroms">
      <Form.Label>{t("BlendTable/Form.Syndroms")}</Form.Label>
      <FieldLoader loading={isLoading || diagnosesLoading}>
        {formData.map((option, index) => <input
          type="hidden"
          name={`${name}.${index}`}
          value={option}
        />)}

        <Select
          id="syndroms"
          multiple={true}
          disableCloseOnSelect={true}
          variant="standard"
          size="small"
          noOptionsText="Not Found"
          value={diagnoses ? diagnoses.filter((option) => formData.includes(option.id)) : []}
          options={diagnoses || []}
          getOptionLabel={(option) => {
            return option.code + " - " + option.name
          }}
          onChange={(event, newValue) => {
            handleInputChange({
              target: {
                name,
                value: newValue,
                type: "text",
                required: false,
              },
            })
          }
          }
          renderInput={(params) => <TextField {...params} />}
        />
      </FieldLoader>
    </Form.Group>
  );
};

const state = ({ diagnosisStore }) => {
  const { data: diagnoses, loading: diagnosesLoading } = diagnosisStore.all;
 
  return {
    diagnoses,
    diagnosesLoading,
  };
};

export default connect(state)(Component);

