import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { ReactComponent as Task } from "../../assets/icons/task.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "react-bootstrap/Modal";
import { EditReminder } from "../reminders/EditReminder";
import FetchIndexData from "../../utils/FetchIndexData";
import TodayIcon from "@material-ui/icons/Today";
import LockIcon from "@material-ui/icons/Lock";
import { useTranslation } from "react-i18next";
import AlertComponent from "../alerts/Alert";

const HomeReminders = ({ updateReminders }) => {
  // Translations
  const { t } = useTranslation();

  const [reminders, setReminders] = useState([]);
  const [currentreminder, setCurrentreminder] = useState("");
  const [loading, setLoading] = useState(true);
  const [editshow, seteditShow] = useState(false);
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const fetchData = async () => {
    const json = await FetchIndexData("/reminders/getHomepageReminders");

    setReminders(json.reminders);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // new reminders (button)
  useEffect(() => {
    fetchData();
  }, [updateReminders]);

  const handleeditShow = () => seteditShow(true);

  const handleeditClose = () => {
    seteditShow(false);
    fetchData();
  };

  const editReminder = (event) => {
    event.preventDefault();
    handleeditShow();
    setCurrentreminder(event.target.id);
  };

  const reminderCards = reminders.map((item, i) => (
    <div className="home-reminders-individual-card" key={i}>
      <div className="row">
        <div className="col-1">
          <Task className="icon" />
        </div>
        <div className="col-11">
          <h4
            id={item.id}
            onClick={editReminder}
            className="home-reminders-individual-info"
          >
            {item.private ? (
              <>
                <span>
                  <LockIcon
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "15px",
                      marginBottom: "4px",
                    }}
                  />
                  &nbsp;
                </span>
              </>
            ) : (
              <></>
            )}
            {item.title}
          </h4>
          <h4 className="home-reminders-individual-time">
            {item.time.split("T")[0]} - {item.time.split("T")[1]}
          </h4>
        </div>
      </div>
    </div>
  ));

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center mt-5">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <>
          <h3 className="today-reminder-box">
            <TodayIcon />
            &nbsp;
            {t("homepageReminders.0")}
          </h3>

          <div className="today-reminder-hr">
            <hr />

            <div className="home-reminders-box">{reminderCards}</div>
          </div>
        </>
      )}

      <Modal
        show={editshow}
        onHide={handleeditClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ background: "#F4F5FA" }}>
          <Modal.Title>
            {t("homepageReminders.1")} # {currentreminder}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#F4F5FA" }}>
          <EditReminder
            id={currentreminder}
            handleeditClose={handleeditClose}
            setIsAlert={setIsAlert}
          />
        </Modal.Body>
      </Modal>

      {isAlert.open && (
        <AlertComponent
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
    </>
  );
};

export default HomeReminders;
