import React from "react";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Switch, TextField } from "@material-ui/core";

export const NameActiveEdited = ({
  state,
  name,
  setName,
  setEditor,
  switchState,
  setStateSwitch,
}) => {
  // translations
  const { t } = useTranslation();

  const handleChange = (event) => {
    setStateSwitch({
      ...switchState,
      [event.target.name]: event.target.checked,
    });
    //console.log(switchState)
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="col-6">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              alignItems: "baseline",
            }}
          >
            <p className="title-inputs">
              {t("EditQuiz.Editing")}: {state.auth?.fullname}
              {setEditor(state.auth?.user_id)}
            </p>

            <FormControlLabel
              control={
                <Switch
                  checked={switchState.active}
                  onChange={handleChange}
                  name="active"
                />
              }
              label={t("QuizList.Active")}
              style={{ display: "none" }}
            />
          </div>

          <div className="form-group">
            <label className="title-inputs">{t("EditQuiz.Title")}</label>
            <br />
            <br />
            <TextField
              required
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="input-control"
              variant="outlined"
              size="small"
              placeholder={t("EditQuiz.Write here")}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default NameActiveEdited;
