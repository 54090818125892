import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Tab, Tabs } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getAllBlends } from "../../../redux/slices/blend";
import { getAllHerbs } from "../../../redux/slices/herb";
import Loader from "./components/Loader";
import TableLoader from "./components/TableLoader";
import Label from "./components/Label";
import { ReactComponent as Pencil } from "../../../assets/icons/pencil.svg";
import { Anchor } from "./styles";

const Component = ({
  herbs,
  blends,
  blendsLoading,
  blendsError,
  blendsSuccess,
  infoRoute,
}) => {
  // translation
  const { t } = useTranslation();
  let currentLanguage = localStorage.getItem("i18nextLng");
  // redux
  const dispatch = useDispatch();
  // route
  const { history } = infoRoute;
  // utils
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filters, setFilters] = useState({});
  const [sort, setSort] = useState({
    sortBy: "created_at",
    sortDirection: "desc",
  });
  const [total, setTotal] = useState(0);

  const getElement = (row) => {
    history.push({
      pathname: `/blend/${row.id}`,
      state: { id: row.id },
    });
  };

  let timer;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFilters((oldState) => ({ ...oldState, [name]: value }));
  };

  const dispatchAction = () => {
    dispatch(getAllBlends({ currentPage, ...sort, itemsPerPage, filters }));
  };

  const columns = useMemo(
    () => [
      {
        name: t("BlendTable/Form.Pinyin name"),
        selector: (row) => row.pigin_name,
        id: "pigin_name",
        sortable: true,
      },
      {
        name: t("BlendTable/Form.Chinese name"),
        selector: (row) => row.chinese_name,
        id: "chinese_name",
        sortable: true,
      },
      {
        name: t("BlendTable/Form.Latin name"),
        selector: (row) => row.latin_name,
        id: "latin_name",
        sortable: true,
      },
      {
        name: t("BlendTable/Form.English name"),
        selector: (row) => row.english_name,
        id: "english_name",
        sortable: true,
      },
      {
        name: t("BlendTable/Form.Therapeutic actions"),
        selector: (row) => row.therapeutic_actions,
        id: "therapeutic_actions",
        sortable: true,
      },
      {
        name: t("BlendTable/Form.Administration"),
        selector: (row) => row.administration,
        id: "administration",
        sortable: true,
      },
      {
        name: t("BlendTable/Form.Clinical manifestations"),
        selector: (row) => row.clinical_manifestations,
        id: "clinical_manifestations",
        sortable: true,
      },
      {
        name: t("BlendTable/Form.Herbs"),
        selector: (row) => {
          if (herbs) {
            return (
              <>
                {row.herb?.map((item, index) => {
                  const currentHerb = herbs.find(
                    (herb) => herb.id === Number(item.herb)
                  );
                  return (
                    <Label key={currentHerb.pinyin_name + "-" + index}>
                      {currentHerb.pinyin_name} - {item.qty}g
                    </Label>
                  );
                })}
              </>
            );
          } else {
            return <Loader />;
          }
        },
        id: "herb",
        sortable: false,
      },
      {
        name: " ",
        selector: (row) => (
          <div style={{ paddingLeft: "46px" }}>
            <Pencil
              className="icon"
              onClick={() => getElement(row)}
              style={{ cursor: "pointer" }}
            />
          </div>
        ),
        id: " ",
      },
    ],
    [herbs, currentLanguage]
  );

  useEffect(() => {
    if (!!blends) {
      setTotal(blends.pagination.totalItems);
    }
  }, [blends]);

  useEffect(() => {
    dispatchAction();
    dispatch(getAllHerbs());
  }, []);

  useEffect(() => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      dispatchAction();
    }, 1500);

    return () => clearTimeout(timer);
  }, [filters, currentPage, itemsPerPage, sort]);

  const handleSelect = (tab) => {
    history.push("/" + tab);
  };

  return (
    <>
      <h4 className="main-title mt-2">{t("BlendList.Blends")}</h4>
      <br />

      <div style={{ marginRight: "50px" }}>
        <Tabs
          defaultActiveKey="blend"
          onSelect={handleSelect}
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="herbs" title={t("HerbsList.4")}></Tab>
          <Tab eventKey="blend" title={t("BlendList.Blend List")}></Tab>
        </Tabs>

        {blendsLoading && !blends?.data ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: 300 }}
          >
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <TableLoader loading={blendsLoading}>
            <div
              style={{
                maxWidth: "95%",
              }}
            >
              <div className="d-flex justify-content-end">
                <input
                  name="searchBy"
                  id="searchBy"
                  className="search-field"
                  type="text"
                  value={filters["searchBy"]}
                  onChange={handleChange}
                  placeholder={t("BlendList.Search")}
                />

                <Anchor to="/blend/new">{t("BlendList.Add blend")}</Anchor>
              </div>
              <div style={{ height: 20 }} />
              <div style={{ overflowX: "auto" }}>
                <DataTable
                  className="custom-table"
                  paginationComponentOptions={{
                    noRowsPerPage: true,
                    rowsPerPageText: t("Table.Show"),
                    rangeSeparatorText: t("Table.of"),
                    selectAllRowsItemText: t("Table.All"),
                  }}
                  noDataComponent={
                    <div className="p-3">
                      <b>{t("Table.No results")}</b>
                    </div>
                  }
                  striped
                  pagination
                  sortServer
                  paginationServer
                  data={blends?.data || []}
                  paginationTotalRows={total}
                  paginationDefaultPage={1}
                  onChangeRowsPerPage={setItemsPerPage}
                  // onRowClicked={getElement}
                  paginationPerPage={itemsPerPage}
                  onSort={(a, b) => setSort({ sortBy: a.id, sortDirection: b })}
                  onChangePage={setCurrentPage}
                  columns={columns}
                />
              </div>
            </div>
          </TableLoader>
        )}
      </div>
    </>
  );
};

const state = ({ blendStore, herbStore }) => {
  const { data: herbs } = herbStore.all;

  const {
    data: blends,
    loading: blendsLoading,
    error: blendsError,
    success: blendsSuccess,
  } = blendStore.all;

  return {
    herbs,
    blends,
    blendsLoading,
    blendsError,
    blendsSuccess,
  };
};

export default connect(state)(Component);
