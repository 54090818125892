import React, { useEffect, useState } from "react";
import { Accordion, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FieldLoader from "../../../FieldLoader";
import TextField from "@mui/material/TextField";
import { Card, Center, Select, Image } from "./styles";
import PulceImage from "../../../../../../assets/icons/pulseCircle.png";
import { symptoms } from "../../../../../../constants/pulse"
import { initialFormData, getValue } from "./helper";

const Component = ({ name, value, isLoading }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(initialFormData(value));

  useEffect(() => {
    setFormData(initialFormData(value));
  }, [value]);

  const handleInputChange = (e) => {
    const newFormData = { ...formData };
    newFormData[e.target.name] = e.target.value;
    setFormData(newFormData);
  };

    // translations
    const getTranslation = (value) => {
      if (value) {
        let name = symptoms.find((element) => element.id === value.id);
        return name ? t(`Pulse.${name.nameEN}`) : value;
      } else {
        return value;
      }
    };
    // end translations

  return (
    <Accordion
      style={{ paddingRight: "0", paddingLeft: "0", marginBottom: "1rem" }}
    >
      <Card>
        <Card.Header
          className="reminders-subtitle"
          style={{
            border: "none",
            background: "white",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="0"
              className="text-center subtitles-patients"
              style={{ textDecoration: "none" }}
              id="accordion_pulse"
            >
              <span>&#8595; &nbsp;</span>
              {t("Titles.Pulse")}
            </Accordion.Toggle>
          </div>
        </Card.Header>

        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <Center>
              <div style={{ width: "100%", maxWidth: 300 }}>
                <Form.Group className="mb-3" controlId="pulse-field-1">
                  <Form.Label>{t("Pulse.Kidney")}</Form.Label>
                  <FieldLoader loading={isLoading}>
                    <input
                      type="hidden"
                      name={`${name}.right.kidney`}
                      value={formData["right.kidney"]?.id}
                    />
                    <Select
                      id="pulse-right-kidney"
                      size="small"
                      noOptionsText="Not Found"
                      options={symptoms.map((option) =>
                        t(`Pulse.${option.nameEN}`)
                      )}
                      disabled={isLoading}
                      value={getTranslation(formData["right.kidney"])}
                      onChange={(event, value) => {
                        handleInputChange({
                          target: {
                            name: "right.kidney",
                            value: getValue(value),
                            type: "text",
                            required: false,
                          },
                        })
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </FieldLoader>
                </Form.Group>

                <Form.Group className="mb-3" controlId="pulse-field-2">
                  <Form.Label>{t("Pulse.Spleen")}</Form.Label>
                  <FieldLoader loading={isLoading}>
                    <input
                      type="hidden"
                      name={`${name}.right.spleen`}
                      value={formData["right.spleen"]?.id}
                    />
                    <Select
                      id="pulse-right-spleen"
                      size="small"
                      noOptionsText="Not Found"
                      options={symptoms.map((option) =>
                        t(`Pulse.${option.nameEN}`)
                      )}
                      disabled={isLoading}
                      value={getTranslation(formData["right.spleen"])}
                      onChange={(event, value) =>
                        handleInputChange({
                          target: {
                            name: "right.spleen",
                            value: getValue(value),
                            type: "text",
                            required: false,
                          },
                        })
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </FieldLoader>
                </Form.Group>

                <Form.Group className="mb-3" controlId="pulse-field-3">
                  <Form.Label>{t("Pulse.Lung")}</Form.Label>
                  <FieldLoader loading={isLoading}>
                    <input
                      type="hidden"
                      name={`${name}.right.lung`}
                      value={formData["right.lung"]?.id}
                    />
                    <Select
                      id="pulse-right-lung"
                      size="small"
                      noOptionsText="Not Found"
                      options={symptoms.map((option) =>
                        t(`Pulse.${option.nameEN}`)
                      )}
                      disabled={isLoading}
                      value={getTranslation(formData["right.lung"])}
                      onChange={(event, value) =>
                        handleInputChange({
                          target: {
                            name: "right.lung",
                            value: getValue(value),
                            type: "text",
                            required: false,
                          },
                        })
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </FieldLoader>
                </Form.Group>
              </div>

              <Image src={PulceImage} alt="" />

              <div style={{ width: "100%", maxWidth: 300 }}>
                <Form.Group className="mb-3" controlId="pulse-field-4">
                  <Form.Label>{t("Pulse.Kidney")}</Form.Label>
                  <FieldLoader loading={isLoading}>
                    <input
                      type="hidden"
                      name={`${name}.left.kidney`}
                      value={formData["left.kidney"]?.id}
                    />
                    <Select
                      id="pulse-left-kidney"
                      size="small"
                      noOptionsText="Not Found"
                      options={symptoms.map((option) =>
                        t(`Pulse.${option.nameEN}`)
                      )}
                      disabled={isLoading}
                      value={getTranslation(formData["left.kidney"])}
                      onChange={(event, value) =>
                        handleInputChange({
                          target: {
                            name: "left.kidney",
                            value: getValue(value),
                            type: "text",
                            required: false,
                          },
                        })
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </FieldLoader>
                </Form.Group>

                <Form.Group className="mb-3" controlId="pulse-field-5">
                  <Form.Label>{t("Pulse.Liver")}</Form.Label>
                  <FieldLoader loading={isLoading}>
                    <input
                      type="hidden"
                      name={`${name}.left.liver`}
                      value={formData["left.liver"]?.id}
                    />
                    <Select
                      id="pulse-left-liver"
                      size="small"
                      noOptionsText="Not Found"
                      options={symptoms.map((option) =>
                        t(`Pulse.${option.nameEN}`)
                      )}
                      disabled={isLoading}
                      value={getTranslation(formData["left.liver"])}
                      onChange={(event, value) =>
                        handleInputChange({
                          target: {
                            name: "left.liver",
                            value: getValue(value),
                            type: "text",
                            required: false,
                          },
                        })
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </FieldLoader>
                </Form.Group>

                <Form.Group className="mb-3" controlId="pulse-field-6">
                  <Form.Label>{t("Pulse.Heart")}</Form.Label>
                  <FieldLoader loading={isLoading}>
                    <input
                      type="hidden"
                      name={`${name}.left.heart`}
                      value={formData["left.heart"]?.id}
                    />
                    <Select
                      id="pulse-left-heart"
                      size="small"
                      noOptionsText="Not Found"
                      options={symptoms.map((option) =>
                        t(`Pulse.${option.nameEN}`)
                      )}
                      disabled={isLoading}
                      value={getTranslation(formData["left.heart"])}
                      onChange={(event, value) =>
                        handleInputChange({
                          target: {
                            name: "left.heart",
                            value: getValue(value),
                            type: "text",
                            required: false,
                          },
                        })
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </FieldLoader>
                </Form.Group>
              </div>
            </Center>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default Component;
