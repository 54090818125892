import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { styles } from "./styles";

const Component = ({
  name,
  defaultValue,
  onInput,
  required,
}) => {
  // translations
  const { t } = useTranslation();
  const [changeFile, setChangeFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [fileName, setFileName] = useState('');
  const [fileType, setFileType] = useState('');
  const [fileSize, setFileSize] = useState('');

  const handleFileChange = async (event) => {
    const uploadedFile = event.target.files[0];

    if (uploadedFile) {
      const aceptedMimes = ["application/pdf"];
      const maxFileSize = 3 * 1024 * 1024; // 3MB

      if (
        aceptedMimes.includes(uploadedFile.type) &&
        uploadedFile.size <= maxFileSize
      ) {
        setSelectedFile(uploadedFile);
        setFileName(uploadedFile.name);
        setFileType(uploadedFile.type);
        setFileSize(`${uploadedFile.size.toFixed(2)} KB`);

        const parts = uploadedFile.type.split("/");
        const ext = parts[1];
        const fileName = name + "." + ext;
        console.log('fileName', fileName)

        !!onInput && onInput({ target: { value: [uploadedFile], name, required } });
      } else {
        alert(t("Messages.FileTooLarge"));
      }
    }
  };

  useEffect(() => {
    const fetchPdfInfo = async () => {
      try {
        const response = await fetch(defaultValue);
        const contentDisposition = response.headers.get('Content-Disposition');

        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+?)"/);
          if (fileNameMatch) {
            setFileName(fileNameMatch[1]);
          }
        }

        setFileType(response.headers.get('Content-Type'));

        const contentLength = response.headers.get('Content-Length');
        if (contentLength) {
          const sizeInBytes = parseInt(contentLength, 10);
          const sizeInKb = sizeInBytes / 1024;
          setFileSize(`${sizeInKb.toFixed(2)} KB`);
        }
      } catch (error) {
        console.error('Error fetching PDF info:', error);
      }
    };

    fetchPdfInfo();
  }, [defaultValue]);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          flexDirection: "column",
        }}
      >
        {defaultValue && !changeFile ? (
          <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
            {/* FIXME: REACT_APP_DOWNLOAD_URL */}
            <a href={defaultValue.replace(
                                      "http://127.0.0.1:3001/",
                                      process.env.REACT_APP_DOWNLOAD_URL
                                    )} target="_blank" rel="noreferrer" className="blue-anchors">{t("FileField.View current file")}</a>
            <div style={{ height: 10 }} />
            <Button size="sm" type="button" onClick={() => setChangeFile(true)}>
              {t("FileField.Change file")}
            </Button>
          </div>
        ) : (
          <>
            <input name={name} id={`${name}-file`} type="file" accept=".pdf" onChange={handleFileChange} />

            <div style={{ height: 10 }} />

            {selectedFile && (
              <section>
                {t("FileField.File details")}:
                <ul>
                  <li><b>{t("FileField.File name")}:</b> {fileName}</li>
                  <li><b>{t("FileField.File type")}:</b> {fileType}</li>
                  <li><b>{t("FileField.File size")}:</b> {fileSize} bytes</li>
                </ul>
              </section>
            )}

            <Button size="sm" type="button" onClick={() => setChangeFile(false)}>
              {t("FileField.Cancel")}
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default Component;
