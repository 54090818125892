import React, { useEffect, useState } from "react";
import { useBeforeunload } from "react-beforeunload";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Card from "react-bootstrap/Card";
import {
  Backdrop,
  CircularProgress,
  TextareaAutosize,
} from "@material-ui/core";
import { useMediaQuery } from "@mui/material";
import GetUserToken from "../../../utils/GetUserToken";
import AlertComponent from "../../alerts/Alert";
import "../../../assets/stylesheets/homePatient.css";

export const AnswerQuiz = (infoRoute) => {
  // translations
  const { t } = useTranslation();
  // route
  const history = infoRoute.history;
  // redux
  const state = useSelector((state) => state.user);
  // utils
  const [loading, setLoading] = useState(true);
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const isMobile = useMediaQuery("(max-width:600px)");
  // data
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  //
  const [title, setTitle] = useState("Fragebogen erhalten!");
  let fullName = state.auth?.fullname;
  let today = new Date().toISOString().slice(0, 16);
  const [task, setTask] = useState(fullName);
  const [time, setTime] = useState("");
  const [note, setNotes] = useState("");
  const [assignto, setDoctor] = useState("");
  const [priv, setPriv] = useState(false);
  const done = false;
  //

  const handleInputChange = (event, i) => {
    let actualAnswers = answers;
    actualAnswers[i] = event.target.value;
    setAnswers(actualAnswers);
  };

  const initData = async () => {
    const url =
      process.env.REACT_APP_API_URL + "/quiz/show/" + infoRoute.match.params.id;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: GetUserToken(),
      },
      redirect: "follow",
    };

    const res = await fetch(url, requestOptions);
    const json = await res.json();

    let questionsArray = JSON.parse(json.questions);
    let answersArray = [];
    if (questionsArray !== null) {
      setQuestions(questionsArray);
      questionsArray.map((item, i) => answersArray.push(""));
      setAnswers(answersArray);
      setLoading(false);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  useBeforeunload((event) => {
    if (!loading) {
      event.preventDefault();
    }
  });

  const onSubmit = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      const body = {
        answer_patient: JSON.stringify(answers),
      };

      const url =
        process.env.REACT_APP_API_URL +
        "/quiz/update_patient/" +
        infoRoute.match.params.id;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: GetUserToken(),
        },
        body: JSON.stringify(body),
      };

      const res = await fetch(url, requestOptions);
      if (res.ok) {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("AnswerQuiz.Your answers have been submitted!"),
        });
        setTimeout(() => {
          history.push("/");
        }, 1000);
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("AnswerQuiz.Something went wrong... please try again!"),
        });
      }

      // reminder
      const reminder = {
        title,
        task,
        time: today,
        note,
        assignto,
        done,
        private: priv,
      };

      var url2 = process.env.REACT_APP_API_URL + "/reminders/create";

      var requestOptions2 = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: GetUserToken(),
        },
        body: JSON.stringify(reminder),
      };

      await fetch(url2, requestOptions2);
      //
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="container-quiz">
        <div className="frame-quiz">
          <h4 className="main-title">{t("Messages.Quiz")}</h4>

          <form
            onSubmit={onSubmit}
            className="row"
            style={{
              background: "#FFFFFF",
              borderRadius: "10px",
              paddingLeft: "25px",
              paddingRight: "25px",
              paddingTop: "20px",
              paddingBottom: "10px",
              width: !isMobile && "95%",
            }}
          >
            {loading ? (
              <></>
            ) : (
              <>
                {questions.map((item, i) => (
                  <>
                    <div
                      className="col-sm-12 col-lg-6"
                      style={{ background: "#f4f5fa", paddingTop: "10px" }}
                    >
                      <Card key={i} style={{ width: "100%" }}>
                        <Card.Header
                          className="reminders-subtitle"
                          style={{ background: "#f4f5fa" }}
                        >
                          {" "}
                          {i + 1}. {item.question}{" "}
                        </Card.Header>
                        <Card.Body style={{ background: "#f4f5fa" }}>
                          <Card.Text>
                            <TextareaAutosize
                              type="text"
                              name="answer"
                              value={item.answer}
                              onChange={(e) => handleInputChange(e, i)}
                              className="input-control"
                              variant="outlined"
                              size="small"
                              aria-label="minimum height"
                              minRows={3}
                              placeholder={t("Messages.Reply")}
                            />
                          </Card.Text>
                        </Card.Body>
                      </Card>
                      <br />
                    </div>
                  </>
                ))}

                <div className="text-center mt-3">
                  <button className="blue-btn" type="submit">
                    {t("Login.10")}
                  </button>
                </div>
              </>
            )}
          </form>
        </div>
      </div>

      {isAlert.open && (
        <AlertComponent
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}

      <Backdrop open={loading} style={{ zIndex: "999" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default AnswerQuiz;
