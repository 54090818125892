import {
  CalendarToday as CalendarTodayIcon,
  Assignment as AssignmentIcon,
  AccessTime as AccessTimeIcon,
  List as ListIcon,
  Notifications as NotificationsIcon,
  Receipt as ReceiptIcon,
  LocalOffer as LocalOfferIcon,
} from "@material-ui/icons";
import patientBooking from "../../../assets/images/patient-booking.webp";
import createAppointment from "../../../assets/images/create-appointment.webp";
import viewAppointment from "../../../assets/images/view-appointment.webp";
import patientAppointmentFile from "../../../assets/images/patient-appointment-file.webp";
import createTimeBlock from "../../../assets/images/create-time-block.webp";
import createReminder from "../../../assets/images/create-reminder.webp";
import createPatient from "../../../assets/images/create-patient.webp";
import createInvoice from "../../../assets/images/create-invoice.webp";
import viewHerb from "../../../assets/images/view-herb.webp";
import viewBlend from "../../../assets/images/view-blend.webp";
import createTreatment from "../../../assets/images/create-treatment.webp";
import createProduct from "../../../assets/images/create-product.webp";
import createAdditionalProduct from "../../../assets/images/create-additional-product.webp";
import createQuiz from "../../../assets/images/create-quiz.webp";
import createTherapist from "../../../assets/images/create-therapist.webp";
import createInsurance from "../../../assets/images/create-insurance.webp";
import createDiagnosis from "../../../assets/images/create-diagnosis.webp";
import createPharmacy from "../../../assets/images/create-pharmacy.webp";
import editCompany from "../../../assets/images/edit-company.webp";

const content = [
  {
    id: "HelpButton_patient_booking",
    route: "homePatient",
    title: "Create an appointment",
    link: "/appointments/wie-lege-ich-als-patient-einen-termin-an/",
    icon: <CalendarTodayIcon />,
    image: patientBooking,
  },
  {
    id: "HelpButton_patient_booking",
    route: "patientInvoices",
    title: "Create an appointment",
    link: "/appointments/wie-lege-ich-als-patient-einen-termin-an/",
    icon: <CalendarTodayIcon />,
    image: patientBooking,
  },
  {
    id: "HelpButton_create_appointment",
    route: "home",
    title: "Create an appointment",
    link: "/appointments/wie-erstellt-man-einen-termin-als-therapeut/",
    icon: <CalendarTodayIcon />,
    image: createAppointment,
  },
  {
    id: "HelpButton_view_appointment",
    route: "homeSecretary",
    title: "View appointments",
    link: "/appointments/wie-kann-man-als-therapeut-termininformationen-einsehen/",
    icon: <CalendarTodayIcon />,
    image: viewAppointment,
  },
  {
    id: "HelpButton_create_appointment",
    route: "calendar",
    title: "Create an appointment",
    link: "/appointments/wie-erstellt-man-einen-termin-als-therapeut/",
    icon: <CalendarTodayIcon />,
    image: createAppointment,
  },
  {
    id: "HelpButton_patient_appointment_file",
    route: "calendar",
    title: "Patient appointment file",
    link: "/appointments/wie-kann-man-als-therapeut-auf-eine-termindatei-zugreifen/",
    icon: <AssignmentIcon />,
    image: patientAppointmentFile,
  },
  {
    id: "HelpButton_create_time_block",
    route: "calendar",
    title: "Create time block",
    link: "/time-blocks/wie-erstellt-man-einen-zeitblock-im-kalender/",
    icon: <AccessTimeIcon />,
    image: createTimeBlock,
  },
  {
    id: "HelpButton_create_reminder",
    route: "reminders",
    title: "Create a reminder",
    link: "/reminders/wie-kann-ich-eine-erinnerung-erstellen/",
    icon: <NotificationsIcon />,
    image: createReminder,
  },
  {
    id: "HelpButton_create_patient",
    route: "patients",
    title: "Create a patient",
    link: "/users/wie-legt-man-einen-patienten-an/",
    icon: <AssignmentIcon />,
    image: createPatient,
  },
  {
    id: "HelpButton_create_invoice",
    route: "invoices",
    title: "Create an invoice",
    link: "/invoices/wie-erstellt-man-eine-rechnung/",
    icon: <ReceiptIcon />,
    image: createInvoice,
  },
  {
    id: "HelpButton_view_herb",
    route: "herb",
    title: "View a herb",
    link: "/herbs/wie-visualisiert-man-die-informationen-einer-pflanze/",
    icon: <ListIcon />,
    image: viewHerb,
  },
  {
    id: "HelpButton_view_blend",
    route: "herb",
    title: "View a blend",
    link: "/blends/wie-koennen-die-informationen-eines-gemischs-visualisiert-werden/",
    icon: <ListIcon />,
    image: viewBlend,
  },
  {
    id: "HelpButton_create_treatment",
    route: "config",
    title: "Create a treatment",
    link: "/treatments/wie-erstellt-man-eine-behandlung/",
    icon: <LocalOfferIcon />,
    image: createTreatment,
  },
  {
    id: "HelpButton_create_product",
    route: "config",
    title: "Create a product",
    link: "/products/wie-erstellt-man-ein-produkt/",
    icon: <LocalOfferIcon />,
    image: createProduct,
  },
  {
    id: "HelpButton_create_additional_product",
    route: "config",
    title: "Create an additional product",
    link: "/additional-products/wie-kann-man-zusaetzliche-produkte-erstellen/",
    icon: <LocalOfferIcon />,
    image: createAdditionalProduct,
  },
  {
    id: "HelpButton_create_quiz",
    route: "config",
    title: "Create a quiz",
    link: "/questionnaires/wie-erstellt-man-einen-fragebogen/",
    icon: <ListIcon />,
    image: createQuiz,
  },
  {
    id: "HelpButton_create_therapist",
    route: "config",
    title: "Create a therapist",
    link: "/users/wie-erstellt-man-einen-therapeuten/",
    icon: <AssignmentIcon />,
    image: createTherapist,
  },
  {
    id: "HelpButton_create_insurance",
    route: "config",
    title: "Create an insurance",
    link: "/insurances/wie-kann-man-eine-versicherung-abschliessen/",
    icon: <LocalOfferIcon />,
    image: createInsurance,
  },
  {
    id: "HelpButton_create_patient",
    route: "config",
    title: "Create a patient",
    link: "/users/wie-legt-man-einen-patienten-an/",
    icon: <AssignmentIcon />,
    image: createPatient,
  },
  {
    id: "HelpButton_create_diagnosis",
    route: "config",
    title: "Create a diagnosis",
    link: "/diagnostics/wie-erstellt-man-eine-diagnose/",
    icon: <ListIcon />,
    image: createDiagnosis,
  },
  {
    id: "HelpButton_create_pharmacy",
    route: "config",
    title: "Create a pharmacy",
    link: "/pharmacies/wie-gruendet-man-eine-apotheke/",
    icon: <ListIcon />,
    image: createPharmacy,
  },
  {
    id: "HelpButton_edit_company",
    route: "config",
    title: "Edit company",
    link: "/onboarding/wie-bearbeitet-man-ein-unternehmen/",
    icon: <ListIcon />,
    image: editCompany,
  },
];

export const getContent = (route) => {
  const matchedContent = content.filter((item) => item.route === route);
  return matchedContent;
};
