import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
    },
  },
  Itemscontainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "start",
    },
  },
  timeItem: {
    backgroundColor: "#FFFFFF",
    borderRadius: "5px",
    padding: "10px 16px",
    margin: "3px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "var(--primary-color)",
      color: "#FFFFFF",
    },
    "&.selected": {
      backgroundColor: "var(--primary-color)",
      color: "#FFFFFF",
    },
  },
  loading: {
    color: "#8c8c8c",
  },
}));

export default useStyles;
