import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as CalendarGroup } from "../../assets/icons/calendarGroup.svg";
import "../../assets/stylesheets/viewcitation.css";
import "../../assets/stylesheets/newcitation.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from "@material-ui/core/Tooltip";
import Buttons from "./viewcitation/Buttons";
import HelpIcon from "@material-ui/icons/HelpOutline";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withTranslation } from "react-i18next";
import { Alert, Stack } from "@mui/material";
import GetUserToken from "../../utils/GetUserToken";
import AlertComponent from "../alerts/Alert";

class ViewCitation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      unsaved: false,
      citation: { date: "" },
      showed: null,
      invoiced: false,
      patientID: "",
      patientInfo: [],
      created_by: [],
      showederror: false,
      is_from_patient: "",
      action: [],
      editor: [],
      clinicDoctors: [],
      doctor: "",
      userToken: GetUserToken(),
      isAlert: {
        open: false,
        severity: "",
        message: "",
      },
    };

    this.addHtmlEntities = this.addHtmlEntities.bind(this);
    this.deleteCitation = this.deleteCitation.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onTimeChange = this.onTimeChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleShowV2 = this.handleShowV2.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleDoctorChange = this.handleDoctorChange.bind(this);
    this.dateFormat = this.dateFormat.bind(this);
  }

  async componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    const url = process.env.REACT_APP_API_URL + `/show/${atob(id)}`;

    var requestOptions = {
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: this.state.userToken,
      },
    };

    await fetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then((response) => {
        console.log(response);
        this.setState({
          ...this.state,
          citation: response,
          patientID: response["patient_id"],
          showed: response.showed,
          invoiced: response.invoiced,
          action: response.action,
          editor: response.editor,
          is_from_patient: response.is_from_patient,
          // loading: false
        });
      });
    //doctors
    var requestOptionsD = {
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: this.state.userToken,
      },
    };

    const url4 = process.env.REACT_APP_API_URL + "/citations/getDoctors";

    await fetch(url4, requestOptionsD)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then((response) => {
        console.log(response);
        let activeDoctors = response.clinicDoctors.filter((item) => {
          return item.archived === false;
        });
        this.setState({
          ...this.state,
          clinicDoctors: response.clinicDoctors,
          loading: false,
        });
      });

    if (this.state.patientID) {
      var requestOptions = {
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: this.state.userToken,
        },
      };

      const url2 =
        process.env.REACT_APP_API_URL +
        `/patients/getPatientInfo?id=` +
        this.state.patientID;

      await fetch(url2, requestOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Network response was not ok.");
        })
        .then((response) =>
          this.setState({
            ...this.state,
            patientInfo: response.patient,
          })
        );
    }

    console.log("created by: ", this.state.citation.created_by);
    console.log("patient showed: ", this.state.showed);

    var requestOptions = {
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: this.state.userToken,
      },
    };

    const url3 =
      process.env.REACT_APP_API_URL +
      `/users/get_user_info?id=` +
      this.state.citation.created_by;

    const response = await fetch(url3, requestOptions);
    const json = await response.json();

    this.setState({
      ...this.state,
      created_by: json,
    });
    console.log(json);

    this.setState({ ...this.state, loading: false });
  }

  addHtmlEntities(str) {
    return String(str).replace(/&lt;/g, "<").replace(/&gt;/g, ">");
  }

  onDateChange(e) {
    var newTime =
      e.target.value + "T" + this.state.citation.time.split("T", 2)[1];
    let actualCitation = this.state.citation;
    actualCitation.time = newTime;
    actualCitation.date = e.target.value;
    this.setState({
      ...this.state,
      unsaved: true,
      citation: actualCitation,
    });
  }

  onTimeChange(e) {
    let actualCitation = this.state.citation;
    var timeString = this.state.citation.time.split("T", 2);
    actualCitation.time =
      timeString[0] + "T" + e.target.value + ":00.000+00:00";
    console.log(this.state.citation.time);
    console.log(actualCitation);
    this.setState({
      ...this.state,
      unsaved: true,
      citation: actualCitation,
    });
  }

  onSubmit(event) {
    this.setState({ ...this.state, loading: true });

    event.preventDefault();
    console.log(this.state.citation);

    const {
      match: {
        params: { id },
      },
    } = this.props;

    const url = process.env.REACT_APP_API_URL + `/citations/update/${atob(id)}`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: this.state.userToken,
      },
      body: JSON.stringify(this.state.citation),
    }).then((response) => {
      console.log(response.json());
      this.setState({
        ...this.state,
        loading: false,
        unsaved: false,
      });
    });
  }

  handleShow(event) {
    console.log("handleShow:", event.target.checked);
    let actualCitation = this.state.citation;
    var checked = event.target.checked;
    actualCitation.showed = checked;

    this.setState({
      ...this.state,
      loading: true,
      citation: actualCitation,
      showed: event.target.checked,
    });

    const {
      match: {
        params: { id },
      },
    } = this.props;

    const url = process.env.REACT_APP_API_URL + `/citations/showed/${atob(id)}`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: this.state.userToken,
      },
      body: JSON.stringify({ showed: event.target.checked }),
    }).then((response) => {
      if (response.ok) {
        console.log(response);
        this.setState({ ...this.state, loading: false });
      } else {
        console.log(response);
        this.setState({ ...this.state, loading: false });
      }
    });
  }

  handleShowV2(status) {
    console.log("handleShow:", status);
    let actualCitation = this.state.citation;
    var checked = status;
    actualCitation.showed = checked;

    this.setState({
      ...this.state,
      loading: true,
      citation: actualCitation,
      // showed: status
    });

    const {
      match: {
        params: { id },
      },
    } = this.props;

    const url = process.env.REACT_APP_API_URL + `/citations/showed/${atob(id)}`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: this.state.userToken,
      },
      body: JSON.stringify({ showed: status }),
    }).then((response) => {
      if (response.ok) {
        console.log(response);
        this.setState({
          ...this.state,
          loading: false,
          showed: status,
        });
      } else {
        console.log(response);
        this.setState({
          ...this.state,
          loading: false,
          showederror: true,
        });
      }
    });
  }

  handleRefresh() {
    const { t } = this.props;

    this.setState({
      ...this.state,
      loading: true,
    });

    const url =
      process.env.REACT_APP_API_URL_V2 +
      `/citations/refresh_appointment_status/${this.state.citation.id}`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: this.state.userToken,
      },
    }).then((response) => {
      if (response.ok) {
        this.setState({
          ...this.state,
          loading: false,
          isAlert: {
            open: true,
            severity: "success",
            message: t("Messages.The operation was successful"),
          },
        });
        setTimeout(() => {
          this.setState({
            ...this.state,
            isAlert: {
              open: false,
            },
          });
        }, 5000);
      } else {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    });
  }

  deleteCitation(event) {
    this.setState({ ...this.state, loading: true });
    console.log(event.target.value);
    const {
      match: {
        params: { id },
      },
    } = this.props;

    const url =
      process.env.REACT_APP_API_URL +
      `/citations/archive/${atob(id)}?archived=${event.target.value}`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: this.state.userToken,
      },
    })
      .then((response) => {
        if (response.ok) {
          window.location.reload();
        }
        throw new Error("Network response was not ok.");
      })
      .catch((error) => console.log(error.message));
  }

  handleDoctorChange(event) {
    let actualCitation = this.state.citation;

    actualCitation.doctor = event.target.value;
    console.log(this.state.citation.doctor);
    console.log(actualCitation);
    this.setState({
      ...this.state,
      unsaved: true,
      citation: actualCitation,
    });
  }

  dateFormat = (language, date) => {
    let arrayDate = date.split("-");
    let formattedDate =
      language === "en"
        ? `${arrayDate[1]}/${arrayDate[2]}/${arrayDate[0]}`
        : arrayDate.reverse().join("/");
    return formattedDate;
  };

  render() {
    const { citation } = this.state;

    // translations
    const { t, i18n } = this.props;
    let language = i18n.language;
    // route
    const { location } = this.props;
    const isInvoice = location.state ? location.state.idInvoice : false;
    const isPatientProfile = location.state
      ? location.state.patientProfile
      : false;

    return (
      <>
        <div className="frame">
          <div className="scrollmenu">
            <div className="container" style={{ paddingLeft: "0" }}>
              <div className="row">
                <div className="col">
                  <div
                    className={`citation-box ${
                      this.state.showed &&
                      !this.state.invoiced &&
                      "citation-box-warning"
                    }`}
                  >
                    <div className="row-0">
                      <h1 className="title-citation-box">
                        {t("ViewCitation.Your Appointment")}&nbsp;
                        {citation.archived ? (
                          <>
                            <Tooltip
                              title="This appointment is archived. That means that not will be showed in the calendar and invoces, but you can still see the info."
                              placement="right"
                            >
                              <span className="appointment-status-archived">
                                &nbsp;{t("ViewCitation.Archived")}&nbsp;
                              </span>
                            </Tooltip>
                          </>
                        ) : (
                          <>
                            {this.state.unsaved ? (
                              <>
                                <span className="appointment-status-unsaved">
                                  &nbsp;{t("ViewCitation.Unsaved")}&nbsp;
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="appointment-status-saved">
                                  &nbsp;{t("ViewCitation.Saved")}&nbsp;
                                </span>
                              </>
                            )}
                            &nbsp;&nbsp;
                          </>
                        )}
                      </h1>
                      <p className="blue-anchors">
                        {" "}
                        <Link
                          className="blue-anchors"
                          to={
                            isInvoice
                              ? `/create-invoice/${isInvoice}`
                              : isPatientProfile
                              ? `/patients-profile/${isPatientProfile}`
                              : "/calendar"
                          }
                        >
                          {" "}
                          &#60; {t("Buttons.Back")}
                        </Link>
                      </p>
                    </div>

                    {this.state.loading ? (
                      <>
                        <div className="d-flex justify-content-center">
                          <CircularProgress color="secondary" />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="row">
                          <div className="col-2">
                            <CalendarGroup />
                          </div>
                          <div className="col-3">
                            <h3 className="inside-title-citation-box">
                              {t("ViewCitation.Date")}
                            </h3>
                            <FormControl
                              component="fieldset"
                              style={{ maxWidth: 140 }}
                              className="subtitle-citation-box"
                            >
                              <TextField
                                required
                                id="time"
                                name="time"
                                type="date"
                                className="subtitle-citation-box"
                                value={citation.time.split("T", 2)[0]}
                                onChange={this.onDateChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </FormControl>
                          </div>

                          <div className="col-2">
                            <h3 className="inside-title-citation-box">
                              {t("ViewCitation.Time")}
                            </h3>
                            <FormControl
                              component="fieldset"
                              style={{ maxWidth: 110 }}
                              className="subtitle-citation-box"
                            >
                              <TextField
                                required
                                id="time"
                                name="time"
                                type="time"
                                className="subtitle-citation-box"
                                value={
                                  citation.time
                                    .split("T", 2)[1]
                                    .split(":", 2)[0] +
                                  ":" +
                                  citation.time
                                    .split("T", 2)[1]
                                    .split(":", 2)[1]
                                }
                                onChange={this.onTimeChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </FormControl>
                          </div>
                          {/*DOCTORS*/}
                          <div className="col-3">
                            <h3 className="inside-title-citation-box">
                              {t("ViewCitation.Therapist")}:
                            </h3>
                            <FormControl
                              style={{ minWidth: 300, borderRadius: "5px" }}
                            >
                              <Select
                                value={citation.doctor}
                                labelId="doctor"
                                id="doctor"
                                type="text"
                                name="doctor"
                                onChange={this.handleDoctorChange}
                                style={{ width: 200 }}
                              >
                                {this.state.clinicDoctors.map((item) => {
                                  return (
                                    <MenuItem
                                      style={{
                                        color:
                                          item.archived === false
                                            ? "none"
                                            : "#c0c0c0",
                                        fontStyle:
                                          item.archived === false
                                            ? "none"
                                            : "italic",
                                      }}
                                      value={item["id"]}
                                    >
                                      {item["name"]}{" "}
                                      {item.archived === false
                                        ? ""
                                        : t("CompleteAppointment.Archived")}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>

                        <div className="row-2">
                          <hr></hr>
                          <h1 className="title-citation-box">
                            {t("ViewCitation.Appointment info")}
                          </h1>
                          <br></br>
                          <h4 className="inside-title-citation-box">
                            {t("ViewCitation.Name")}:&nbsp;
                            <span className="subtitle-citation-box">
                              <Link
                                target="_blank"
                                className="blue-anchors"
                                to={{
                                  pathname:
                                    "/patients-profile/" +
                                    `${this.state.patientID}`,
                                }}
                              >
                                {" "}
                                {citation.name}
                              </Link>
                            </span>
                          </h4>
                          <h4 className="inside-title-citation-box">
                            {t("ViewCitation.Treatment")}:&nbsp;
                            <span className="subtitle-citation-box">
                              {citation.treatmentName}
                            </span>
                          </h4>
                          <h4 className="inside-title-citation-box">
                            {t("ViewCitation.Phone")}:&nbsp;
                            <span className="subtitle-citation-box">
                              {citation.phone != null ? (
                                citation.phone
                              ) : (
                                <>{t("ViewCitation.No phone available")}</>
                              )}
                            </span>
                          </h4>

                          {this.state.patientInfo.length ? (
                            <h4 className="inside-title-citation-box">
                              {t("ViewCitation.Birth")}:{" "}
                              <span className="subtitle-citation-box">
                                {this.state.patientInfo[0].birthday !=
                                undefined ? (
                                  this.dateFormat(
                                    language,
                                    this.state.patientInfo[0].birthday
                                  )
                                ) : (
                                  <>{t("ViewCitation.Cannot get data.")}</>
                                )}
                              </span>
                            </h4>
                          ) : (
                            <></>
                          )}

                          <h4 className="inside-title-citation-box overflow-auto">
                            {t("ViewCitation.Remark")}:&nbsp;
                            <span className="subtitle-citation-box">
                              {citation.warnings != "" ? (
                                citation.warnings
                              ) : (
                                <>{t("ViewCitation.No recent info")}</>
                              )}
                            </span>
                          </h4>

                          <h4 className="inside-title-citation-box">
                            {t("ViewCitation.Created by")}:{" "}
                            <span className="subtitle-citation-box">
                              {this.state.created_by.fullname != "" ? (
                                this.state.created_by.fullname
                              ) : (
                                <>{t("ViewCitation.Unavailable recent info")}</>
                              )}
                            </span>
                          </h4>

                          <h4 className="inside-title-citation-box">
                            {t("ViewCitation.External booking")}:{" "}
                            <span className="subtitle-citation-box">
                              {this.state.is_from_patient === true
                                ? t("ViewCitation.Yes")
                                : t("ViewCitation.No")}
                            </span>
                          </h4>

                          <hr></hr>
                        </div>
                        <div className="row-3">
                          <h1 className="title-citation-box">
                            {t("ViewCitation.Appointment Status")}
                          </h1>
                          <div className="d-flex align-items-center mt-1">
                            <span className="subtitle-citation-box">
                              {this.state.action == "no_data" ||
                              this.state.editor == "no_data" ? (
                                <>
                                  {t(
                                    "ViewCitation.Sorry, No history available for this appointment."
                                  )}
                                </>
                              ) : (
                                <>
                                  {t("ViewCitation.Last edit was made at")}{" "}
                                  {this.state.action["created_at"] &&
                                    this.dateFormat(
                                      language,
                                      this.state.action["created_at"].split(
                                        "T"
                                      )[0]
                                    )}{" "}
                                  {t("ViewCitation.by")}{" "}
                                  {this.state.editor["fullname"]}
                                </>
                              )}
                            </span>
                          </div>

                          <div className="d-flex flex-column mt-2">
                            <div className="d-flex">
                              {this.state.showed == null ? (
                                <>
                                  <span className="subtitle-citation-box">
                                    {t(
                                      "ViewCitation.Did this patient showed up?"
                                    )}
                                    &nbsp;
                                    <span
                                      onClick={() => this.handleShowV2(true)}
                                      className="assist-link"
                                    >
                                      {t("ViewCitation.Yes")}
                                    </span>
                                    &nbsp;&nbsp;
                                    <span>/</span>
                                    &nbsp;&nbsp;
                                    <span
                                      onClick={() => this.handleShowV2(false)}
                                      className="assist-link"
                                    >
                                      {t("ViewCitation.No")}
                                    </span>
                                  </span>
                                </>
                              ) : (
                                <>
                                  {this.state.showed ? (
                                    <>
                                      <span className="subtitle-citation-box">
                                        <CheckIcon
                                          style={{
                                            color: "var(--primary-color)",
                                          }}
                                        />{" "}
                                        {t("ViewCitation.Patient showed up.")}
                                        &nbsp;
                                        <span
                                          onClick={() =>
                                            this.handleShowV2(false)
                                          }
                                          className="change-assist-link"
                                          id="change_button_viewCitation"
                                        >
                                          {t("ViewCitation.Change")}
                                        </span>
                                        {!this.state.invoiced && (
                                          <>
                                            &nbsp;&nbsp;
                                            <span>/</span>
                                            &nbsp;&nbsp;
                                            <span
                                              onClick={() =>
                                                this.handleRefresh()
                                              }
                                              className="change-assist-link box-refresh"
                                              id="refresh_button_viewCitation"
                                            >
                                              {t("ViewCitation.Refresh")}
                                            </span>
                                          </>
                                        )}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span className="subtitle-citation-box">
                                        <CloseIcon
                                          style={{
                                            color: "var(--primary-color)",
                                          }}
                                        />
                                        {t(
                                          "ViewCitation.Patient didnt showed up."
                                        )}
                                        &nbsp;
                                        <span
                                          onClick={() =>
                                            this.handleShowV2(true)
                                          }
                                          className="change-assist-link"
                                        >
                                          {t("ViewCitation.Change")}
                                        </span>
                                      </span>
                                    </>
                                  )}
                                </>
                              )}
                              <Tooltip
                                title={t("ViewCitation.Change")}
                                placement="right"
                              >
                                <span>
                                  &nbsp;
                                  <HelpIcon
                                    style={{ color: "var(--primary-color)" }}
                                  />
                                  &nbsp;
                                </span>
                              </Tooltip>
                            </div>
                            {this.state.showed && !this.state.invoiced && (
                              <Stack sx={{ mt: 1, mb: 2 }}>
                                <Alert
                                  severity="warning"
                                  style={{ alignItems: "center" }}
                                  className="title-inputs"
                                >
                                  {t(
                                    "ViewCitation.Clicking on refresh will update to current prices"
                                  )}
                                </Alert>
                              </Stack>
                            )}
                          </div>
                        </div>

                        <div className="row-4 text-center mb-2">
                          <Buttons
                            citation={citation}
                            deleteCitation={this.deleteCitation}
                            onSubmit={this.onSubmit}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Snackbar
          open={this.state.showederror}
          autoHideDuration={6000}
          onClose={() => this.setState({ ...this.state, showederror: false })}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() => this.setState({ ...this.state, showederror: false })}
            severity="error"
          >
            {t(
              "ViewCitation.Error saving prices. Please, check if patient has a valid insurance."
            )}
          </MuiAlert>
        </Snackbar>

        {this.state.isAlert.open && (
          <AlertComponent
            isAlert={this.state.isAlert}
            severity={this.state.isAlert.severity}
            message={this.state.isAlert.message}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(ViewCitation);
