import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Form } from "react-bootstrap";
import FieldLoader from "../FieldLoader";
import {
  createOneContactInformation,
  updateContactInformation,
} from "../../../../redux/slices/contact-information";
import BackdropLoading from "../../../../components/reusableComponents/BackdropLoading";
import Alert from "../../../../components/alerts/Alert";

const keys = {
  value: "",
  reference: "",
};

const contactType = 2;

const Component = ({
  company,
  companyLoading,
  contactInformation,
  contactInformationLoading,
  contactInformationError,
  contactInformationSuccess,
  contactInformationUpdate,
  contactInformationUpdateLoading,
  contactInformationUpdateError,
  contactInformationUpdateSuccess,
}) => {
  // translations
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  // data
  const [initialValues, setInitialValues] = useState(keys);
  const [values, setValues] = useState();
  const [companyPhone, setCompanyPhone] = useState();
  // utils
  const [errors, setErrors] = useState({});
  const [isAlert, setIsAlert] = useState({
    open: false,
  });

  const handleChange = (event) => {
    if (!event.target) {
      console.error("Event was not found.");
    }

    const { name, value, type, required } = event.target;

    setValues((prevValues) => {
      const newValues = { ...prevValues };

      if (value === "") {
        delete newValues[name];
      } else {
        newValues[name] = value;
      }

      return newValues;
    });

    // Validate the required field
    if (type === "text" && value.trim() === "" && required) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "This field is required",
      }));
    } else {
      // Clear the error if the field is not empty
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    console.log("Values", values);
    if (!companyPhone) {
      dispatch(
        createOneContactInformation({
          company_id: company?.id,
          contact_type_id: contactType,
          enabled: true,
          ...values,
        })
      );
    } else {
      dispatch(
        updateContactInformation({
          id: companyPhone?.id,
          company_id: company?.id,
          contact_type_id: contactType,
          enabled: true,
          ...values,
        })
      );
    }

    setIsAlert({
      open: true,
    });
  };

  useEffect(() => {
    let initialValues = keys;

    if (!!companyPhone) {
      for (const key of Object.keys(keys)) {
        initialValues[key] = companyPhone[key];
      }
    }

    setInitialValues(initialValues);
    setValues(initialValues);
  }, [companyPhone]);

  useEffect(() => {
    const phone = company?.contact_informations?.find(
      (item) => item.contact_type_id === contactType
    );
    setCompanyPhone(phone);
  }, [company]);

  return (
    <>
      <h4 className="main-title-treatments">
        {t("FormCompanyPhone.Phone Number")}
      </h4>
      <Form noValidate id="edit-company-phone" onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="control-phone-value">
          <Form.Label>{t("FormCompanyPhone.Number")}</Form.Label>
          <FieldLoader loading={companyLoading}>
            <Form.Control
              required
              type="text"
              name="value"
              defaultValue={initialValues["value"]}
              onInput={handleChange}
              disabled={contactInformationUpdateLoading}
            />
          </FieldLoader>

          {!!errors["value"] && (
            <Form.Control.Feedback type="invalid">
              {t("Messages.Required field")}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        {/* <Form.Group className="mb-3" controlId="control-phone-reference">
          <Form.Label>Reference</Form.Label>
          <FieldLoader loader={companyLoading}>
            <Form.Control
              required
              type="text"
              name="reference"
              defaultValue={initialValues["reference"]}
              onInput={handleChange}
              disabled={contactInformationUpdateLoading}
            />
          </FieldLoader>

          {!!errors["reference"] && (
            <Form.Control.Feedback type="invalid">
              Required field
            </Form.Control.Feedback>
          )}
        </Form.Group> */}
      </Form>

      <FieldLoader loader={contactInformationUpdateLoading}>
        <Button
          className="btnGo"
          form="edit-company-phone"
          type="submit"
          target={false}
          disabled={contactInformationUpdateLoading}
        >
          {t("FormCompanyPhone.Update phone")}
        </Button>
      </FieldLoader>

      <BackdropLoading isOpen={contactInformationUpdateLoading} />

      {isAlert.open && contactInformationUpdateSuccess && (
        <Alert
          isAlert={isAlert.open && contactInformationUpdateSuccess}
          setIsAlert={setIsAlert}
          severity="success"
          message={t("Messages.The operation was successful")}
        />
      )}
      {isAlert.open && contactInformationUpdateError && (
        <Alert
          isAlert={isAlert.open && contactInformationUpdateError}
          setIsAlert={setIsAlert}
          severity="error"
          message={t("Messages.Something went wrong, please try again")}
        />
      )}
    </>
  );
};

const state = ({ companyStore, contactInformationStore }) => {
  const { data: company, loading: companyLoading } = companyStore.one;
  const {
    data: contactInformation,
    loading: contactInformationLoading,
    error: contactInformationError,
    success: contactInformationSuccess,
  } = contactInformationStore.one;
  const {
    data: contactInformationUpdate,
    loading: contactInformationUpdateLoading,
    error: contactInformationUpdateError,
    success: contactInformationUpdateSuccess,
  } = contactInformationStore.update;

  return {
    company,
    companyLoading,
    contactInformation,
    contactInformationLoading,
    contactInformationError,
    contactInformationSuccess,
    contactInformationUpdate,
    contactInformationUpdateLoading,
    contactInformationUpdateError,
    contactInformationUpdateSuccess,
  };
};

export default connect(state)(Component);
