import styled from 'styled-components'

export const Wrapper = styled('div')`
  position: relative;
  // width: calc(100% - 50px);
  width: 100%;
  height: 100%;

  margin-right: 50px;
  background-color: white;
  padding: 18px 12px 18px 12px;
  border-radius: 8px;
  box-shadow: 0 0 0 10px #FFFFFF;

  
  /*
   *  Tabla general: 
   */

  .custom-table {
    .rdt_Table {
      font-size: 16px;
      font-weight: 400;
      color: #564c60;

      .rdt_TableHead {
        .rdt_TableHeadRow {
          min-height: 40px;
          border: none;

          .rdt_TableCol {
            font-size: 16px;
            font-weight: 400;
            color: #564c60;
          }
        }
      }
      .rdt_TableBody {
        .rdt_TableRow {

          &:not(:last-of-type) {
            border: none;
          }

          &:nth-child(odd) {
            background-color: #F4F5FA;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 9999px inset;
          }

          .rdt_TableCell{
            font-size: 16px;
            font-weight: 400;
            color: #564c60;
          }
        }
      }
    }
  }

  .rdt_Pagination {
    border: none;
  }

`

export const Loader = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.4);
    backdrop-filter: blur(1px);
  }
`