import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/stylesheets/homepage.css";
//....
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import GetUserToken from "../../utils/GetUserToken";

function HomeCards({ id }) {
  const userToken = GetUserToken();

  const state = useSelector((state) => state.user);
  const { t } = useTranslation();

  const [appointments, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const url =
        process.env.REACT_APP_API_URL +
        "/homepage/getDoctorUpcomingAppointments?id=" +
        id;

      var requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        redirect: "follow",
      };

      const res = await fetch(url, requestOptions);
      const json = await res.json();
      // console.log(json.currentDoctorAppointments)
      setData(json.currentDoctorAppointments);
    };
    fetchData();
  }, [setData]);

  const wait = (
    <div className="vw-50 vh-50 d-flex align-items-center justify-content-center">
      <h4 className="subtitles-patients">{t("No appointments today.0")}</h4>
    </div>
  );

  const all = appointments?.filter((item) => !item[6])?.map(
    (item, i) =>
      i < 4 && (
        <div key={i} className="row">
          <div className="col">
            <div className="card home-cards" id={"card" + i}>
              <div className="card-body">
                <Link
                  to={`/citation/${btoa(item[1])}`}
                  style={{ textDecoration: "none" }}
                >
                  <p className="homepage-treatments-cards-text-title-purple">
                    {item[2]}
                  </p>
                </Link>
                <h4 className="homepage-treatments-cards-text-content-purple">
                  · {item[0].slice(11, 16)}
                </h4>
                <h4 className="homepage-treatments-cards-text-content-purple">
                  · {item[4]}
                </h4>
              </div>
            </div>
          </div>
        </div>
      )
  );

  return (
    <>
      {appointments?.length > 0 ? (
        <div className="mt-5 home-titles-container">
          <h3 className="subtitles-patients">{t("homepageCards.Now")}</h3>
          <h3 className="subtitles-patients">
            {t("homepageCards.Next appointments")}
          </h3>
        </div>
      ) : (
        <></>
      )}
      <div className="mt-2" style={{ display: "flex" }}>
        {appointments?.length > 0 ? all : wait}
      </div>
    </>
  );
}

export default HomeCards;
