import { Switch, Route, Redirect } from "react-router-dom";
import NewBlend from "./New";
import EditBlend from "./Edit";
import ListBlend from "./List";
import Error from "../../components/Error";

const Component = ({ admin, therapist, props }) => {
  const path = "/blend";

  return (
    <div className="frame pb-3">
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={() =>
            admin || therapist ? <ListBlend infoRoute={props} /> : <Error />
          }
        />
        <Route
          path={`${path}/new`}
          render={() =>
            admin || therapist ? <NewBlend infoRoute={props} /> : <Error />
          }
        />
        <Route
          path={`${path}/:id`}
          render={() =>
            admin || therapist ? <EditBlend infoRoute={props} /> : <Error />
          }
        />
        <Route
          exact
          path={`${path}/*`}
          render={() => <Redirect to={"/404"} />}
        />
      </Switch>
    </div>
  );
};

export default Component;
