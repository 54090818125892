import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Checkbox, CircularProgress, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import "../../assets/stylesheets/newtratamiento.css";
import GetLocalAuthToken from "../../utils/GetLocalAuthToken";
import GetUserToken from "../../utils/GetUserToken";
import Alert from "../alerts/Alert";

class NewAdittional extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userToken: GetUserToken(),
      loading: true,
      name: "",
      duration: "",
      price: "",
      rate: "",
      p_two: "",
      p_three: "",
      p_four: "",
      p_five: "",
      p_six: "",
      p_seven: "",
      p_eight: "",
      clinicProduct: [],
      selectedProducts: [],
      products: "",
      isAlert: {
        open: false,
        severity: "",
        message: "",
      },
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.stripHtmlEntities = this.stripHtmlEntities.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.onProductChange = this.onProductChange.bind(this);
  }

  getProducts() {
    const url =
      process.env.REACT_APP_API_URL_V2 + "/treatments/get_base_products";

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: this.state.userToken,
      },
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => {
        response.json().then((response) => {
          const filteredProducts = response.filter((item) => {
            return item.archived === false;
          });
          this.setState({
            ...this.state,
            clinicProduct: filteredProducts,
            loading: false,
          });
        });
      })
      .catch((error) => console.log(error.message));
  }

  componentDidMount() {
    this.getProducts();
  }

  stripHtmlEntities(str) {
    return String(str).replace(/</g, "&lt;").replace(/>/g, "&gt;");
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onProductChange(event, newValues) {
    this.setState({
      ...this.state,
      products: JSON.stringify(newValues),
    });
  }

  onSubmit(event) {
    event.preventDefault();
    const { t } = this.props;
    if (this.state.products.length !== 0) {
      this.setState({ ...this.state, loading: true });

      const url = process.env.REACT_APP_API_URL_V2 + "/bundles/create";

      const { name, rate, products } = this.state;
      const body = {
        title: name,
        rate,
        is_adittional_product: true,
        adittional_products: products,
      };

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: this.state.userToken,
        },
        redirect: "follow",
        body: JSON.stringify(body),
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          if (response.id) {
            this.setState({
              ...this.state,
              isAlert: {
                open: true,
                severity: "success",
                message: t("Messages.The operation was successful"),
              },
              loading: false,
            });
            setTimeout(() => {
              this.props.history.push(`/edit-adittional/${response.id}`);
            }, 1000);
          } else {
            this.setState({
              ...this.state,
              isAlert: {
                open: true,
                severity: "error",
                message: t("Messages.The operation could not be processed"),
              },
              loading: false,
            });
          }
        })
        .catch((error) =>
          this.setState({
            ...this.state,
            isAlert: {
              open: true,
              severity: "error",
              message: t("Messages.Something went wrong, please try again"),
            },
            loading: false,
          })
        );
    } else {
      this.setState({
        isAlert: {
          open: true,
          severity: "warning",
          message: t("Messages.You must select at least one product"),
        },
      });
    }
  }

  render() {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const { t } = this.props;

    return (
      <>
        <div
          className="container-fluid"
          style={{ paddingLeft: "1px", paddingRight: "1px" }}
        >
          <div style={{ paddingLeft: "180px" }}>
            <form onSubmit={this.onSubmit} className="row">
              <div className="container-fluid">
                <h4 className="main-title-treatments">
                  {t("AdditionalList.New Product Bundle")}
                </h4>
                <p className="blue-anchors">
                  {" "}
                  <Link className="blue-anchors" to="/adittionals">
                    {" "}
                    &#60; {t("AdditionalList.Back to bundles")}
                  </Link>
                </p>
              </div>

              {this.state.loading ? (
                <div className="d-flex justify-content-center">
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <>
                  <div className="col-sm-12 col-lg-5 mt-3">
                    <div className="form-group">
                      <label htmlFor="name" className="title-treatments">
                        {t("AdditionalList.Name")}
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="form-control"
                        required
                        onChange={this.onChange}
                        placeholder={t("AdditionalList.Write here")}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="name" className="title-treatments">
                        {t("AdditionalList.Fee")} Nr.
                      </label>
                      <input
                        type="text"
                        name="rate"
                        id="rate"
                        className="form-control"
                        required
                        onChange={this.onChange}
                        placeholder={t("AdditionalList.Write here")}
                      />
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="product"
                        className="title-treatments"
                        style={{ marginBottom: "10px" }}
                      >
                        {t("AdditionalList.Select Product")}
                      </label>
                      <Autocomplete
                        multiple
                        name="products"
                        id="products"
                        options={this.state.clinicProduct}
                        disableCloseOnSelect
                        onChange={(event, newValues) => {
                          this.onProductChange(event, newValues);
                        }}
                        getOptionLabel={(option) => option.title}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{
                                marginRight: 8,
                                color: "var(--primary-color)",
                              }}
                              checked={selected}
                            />
                            {option.title}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={t("AdditionalList.Products")}
                            placeholder={t("Buttons.Select")}
                            fullWidth
                          />
                        )}
                      />
                    </div>

                    <div className="row" style={{ marginTop: "10px" }}>
                      <div className="col text-right">
                        <Link to="/adittionals">
                          <button
                            className="btnCancel"
                            style={{ width: "100%" }}
                          >
                            {t("Buttons.Cancel")}
                          </button>
                        </Link>
                      </div>
                      <div className="col text-right">
                        <button
                          type="submit"
                          className="btnGo"
                          style={{ width: "100%" }}
                        >
                          {t("Buttons.Save")}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
        {this.state.isAlert.open && (
          <Alert
            isAlert={this.state.isAlert}
            severity={this.state.isAlert.severity}
            message={this.state.isAlert.message}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(NewAdittional);
