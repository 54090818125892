import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../../../assets/stylesheets/invoicesStyles.css";
import GetUserToken from "../../../utils/GetUserToken";

export const PatientInfo = ({ patient }) => {
  // auth
  const userToken = GetUserToken();
  // translations
  const { t } = useTranslation();
  // data
  const [insurancePatient, setInsurencePatient] = useState("");

  const fetchDataInsurances = async () => {
    const url = process.env.REACT_APP_API_URL + "/insurances/index";
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };
    const res = await fetch(url, requestOptions);
    const json = await res.json();
    if (json["insurances"] && patient.insurance) {
      setInsurencePatient(
        json["insurances"].filter(
          (insurance) => insurance.id === Number(patient.insurance)
        )[0]
      );
    }
  };

  useEffect(() => {
    fetchDataInsurances();
  }, []);

  return (
    <div>
      <h4 className="invoice-patient-info">
        {t("InvoicePatientInfo.0")}:{" "}
        <Link
          className="blue-anchors"
          to={{
            pathname: `/patients-profile/${patient.id}`,
            state: {
              idInvoice: patient.id,
            },
          }}
        >
          {patient.firstname}
        </Link>
      </h4>
      <h4 className="invoice-patient-info">
        {t("InvoicePatientInfo.1")}:{" "}
        <Link
          className="blue-anchors"
          to={{
            pathname: `/patients-profile/${patient.id}`,
            state: {
              idInvoice: patient.id,
            },
          }}
        >
          {patient.lastname}
        </Link>
      </h4>
      <h4 className="invoice-patient-info">Email: {patient.email}</h4>
      <h4 className="invoice-patient-info">
        {t("InvoicePatientInfo.2")}:{" "}
        {insurancePatient && insurancePatient.title}
      </h4>
    </div>
  );
};

export default PatientInfo;
