import React from "react";
import { useTranslation } from "react-i18next";

export const SubmitButtons = ({
  onSubmit,
  handleClose,
  isOnboarding = false,
  isPatient = false,
}) => {
  // translation
  const { t } = useTranslation();

  return (
    <>
      {!isOnboarding ? (
        <div className="row">
          <div className="col-6">
            <button
              type="submit"
              className="cancel-citation-button"
              onClick={handleClose}
            >
              {t("Buttons.Cancel")}
            </button>
          </div>
          <div className="col-6">
            <button
              type="submit"
              onClick={onSubmit}
              className="save-citation-button"
            >
              {t("Buttons.Save")}
            </button>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          <button
            type="submit"
            onClick={onSubmit}
            className={!isPatient ? "btnGo-disabled" : "save-citation-button"}
            disabled={!isPatient}
            id={isOnboarding ? "create_appointment" : ""}
          >
            {t("Buttons.Save")}
          </button>
        </div>
      )}
    </>
  );
};
