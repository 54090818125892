import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import services from "../../services";

// Define initial state
const initialState = {
  one: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  all: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  create: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  update: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
};

// Async action to get one pharmacy
const getOnePharmacy = createAsyncThunk("pharmacy/getOne", async (payload) => {
  console.log("get one - pharmacy - action", payload);
  const { response, error } = await services.pharmacy.getOne(payload);
  if (error) {
    throw new Error(error);
  }
  return response;
});

// Async action to get all companies
const getAllPharmacies = createAsyncThunk("pharmacy/getAll", async (payload) => {
  console.log("get all - pharmacy - action", payload);
  const { response, error } = await services.pharmacy.getAll(payload);
  if (error) {
    throw new Error(error);
  }
  return response;
});

// Async action to create one pharmacy
const createOnePharmacy = createAsyncThunk(
  "pharmacy/createOne",
  async (payload) => {
    console.log("create one - pharmacy - action", payload);
    const { response, error } = await services.pharmacy.createOne(payload);
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Async action to reset a pharmacy creation
const resetCreateOnePharmacy = createAsyncThunk("pharmacy/resetCreateOne", async (payload) => {
  console.log("reset creation - pharmacy - action", payload);
});

// Async action to update a pharmacy
const updatePharmacy = createAsyncThunk("pharmacy/update", async (payload) => {
  console.log("update - pharmacy - action", payload);
  const { response, error } = await services.pharmacy.update(payload);
  if (error) {
    throw new Error(error);
  }
  return response;
});

// Async action to reset a pharmacy update
const resetUpdateOnePharmacy = createAsyncThunk("pharmacy/resetUpdateOne", async (payload) => {
  console.log("reset update - pharmacy - action", payload);
});

// Create a pharmacy slice
const pharmacySlice = createSlice({
  name: "pharmacy",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOnePharmacy.pending, (state, action) => {
        state.one.data = null;
        state.one.loading = true;
        state.one.success = false;
        state.one.error = false;
      })
      .addCase(getOnePharmacy.fulfilled, (state, action) => {
        state.one.data = action.payload;
        state.one.loading = false;
        state.one.success = true;
        state.one.error = false;
      })
      .addCase(getOnePharmacy.rejected, (state, action) => {
        state.one.data = null;
        state.one.loading = false;
        state.one.success = false;
        state.one.error = action.error.message;
      })

      .addCase(createOnePharmacy.pending, (state, action) => {
        state.create.data = null;
        state.create.loading = true;
        state.create.success = false;
        state.create.error = false;
      })
      .addCase(createOnePharmacy.fulfilled, (state, action) => {
        state.create.data = action.payload;
        state.create.loading = false;
        state.create.success = true;
        state.create.error = false;
      })
      .addCase(createOnePharmacy.rejected, (state, action) => {
        state.create.data = null;
        state.create.loading = false;
        state.create.success = false;
        state.create.error = action.error.message;
      })
      .addCase(resetCreateOnePharmacy.fulfilled, (state, action) => {
        state.create.data = null;
        state.create.loading = false;
        state.create.success = false;
        state.create.error = false;
      })

      .addCase(getAllPharmacies.pending, (state) => {
        //state.all.data = null;
        state.all.loading = true;
        state.all.success = false;
        state.all.error = false;
      })
      .addCase(getAllPharmacies.fulfilled, (state, action) => {
        state.all.data = action.payload;
        state.all.loading = false;
        state.all.success = true;
        state.all.error = false;
      })
      .addCase(getAllPharmacies.rejected, (state, action) => {
        state.all.data = null;
        state.all.loading = false;
        state.all.success = false;
        state.all.error = action.error.message;
      })

      .addCase(updatePharmacy.pending, (state) => {
        state.update.data = null;
        state.update.loading = true;
        state.update.success = false;
        state.update.error = false;
      })
      .addCase(updatePharmacy.fulfilled, (state, action) => {
        state.update.data = action.payload;
        state.update.loading = false;
        state.update.success = true;
        state.update.error = false;
        state.one.data = action.payload;
      })
      .addCase(updatePharmacy.rejected, (state, action) => {
        state.update.data = null;
        state.update.loading = false;
        state.update.success = false;
        state.update.error = action.error.message;
      })
      .addCase(resetUpdateOnePharmacy.fulfilled, (state, action) => {
        state.update.data = null;
        state.update.loading = false;
        state.update.success = false;
        state.update.error = false;
      });
  },
});

// Export the async actions and the reducer
export {
  getOnePharmacy,
  getAllPharmacies,
  createOnePharmacy,
  updatePharmacy,
  resetCreateOnePharmacy,
  resetUpdateOnePharmacy
};

export default pharmacySlice.reducer;