import styled from 'styled-components'

export const Label = styled('div')`
  height: 20px;
  width: max-content;
  padding: 0px 10px;
  background: gray;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 2px;
  margin-bottom: 2px;
`;