import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../assets/stylesheets/newdoctorcitation.css";
import { DateTimePicker } from "./newcitation/DateTimePicker";
import { PatientPicker } from "./newcitation/PatientPicker";
import { PatientSummary } from "./newcitation/PatientSummary";
import { SubmitButtons } from "./newcitation/SubmitButtons";
import { TherapistPicker } from "./newcitation/TherapistPicker";
import { TreatmentPicker } from "./newcitation/TreatmentPicker";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector } from "react-redux";
import Chip from "@material-ui/core/Chip";
import GetUserToken from "../../utils/GetUserToken";

export const DoctorCitationV2 = ({
  assistant,
  setAssistant,
  schedule,
  setSchedule,
  handleClose,
  infoRoute,
  setIsCalendarUpdated,
  setShow,
}) => {
  // translation
  const { t } = useTranslation();
  // auth
  const userToken = GetUserToken();

  const [loading, setLoading] = useState(true);
  const [loadingschedule, setLoadingschedule] = useState(false);
  const [error, setError] = useState(false);
  const [required, setRequired] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [patients, setPatients] = useState([]);
  //....
  const state = useSelector((state) => state.user);
  //....
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  //....
  const [statustime, setStatustime] = useState();
  const [checking, setChecking] = useState(false);
  //....
  const [doctor, setDoctor] = useState("");
  const [treatment, setTreatment] = useState("");
  const [name, setName] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [user_id, setUser] = useState("");
  const [patient_id, setPatient] = useState("");
  const [created_by, setCreated_by] = useState();
  const [insurance, setInsurance] = useState("");
  const [remark, setRemark] = useState("");
  //....
  const [warning, setWarning] = useState("");
  // const [schedule, setSchedule] = useState([]);

  useEffect(() => {
    fetchDoctors();
    fetchTreatments();
    fetchPatients();
    if (state) {
      setCreated_by(state["auth"].user_id);
    }
  }, []);

  const fetchDoctors = async () => {
    var requestOptions = {
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL + "/citations/getDoctors",
      requestOptions
    );
    const json = await res.json();

    let activeDoctors = json.clinicDoctors.filter((item) => {
      return item.archived === false;
    });

    setDoctors(activeDoctors);
  };

  const fetchTreatments = async () => {
    var requestOptions = {
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL + "/citations/getTreatments",
      requestOptions
    );
    const json = await res.json();
    const allTreatments = json.clinicTreatments;
    const activeTreatments = allTreatments.filter((obj) => {
      return obj.archived === false;
    });
    setTreatments(activeTreatments);
  };

  const fetchPatients = async () => {
    var requestOptions = {
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL + "/citations/getPatients",
      requestOptions
    );
    const json = await res.json();

    const filtered = json.clinicPatients.filter(
      (item) => item.user_id !== null
    );
    setPatients(filtered);

    setLoading(false);
  };

  const checkSchedule = (value) => {
    setLoadingschedule(true);
    if (time != "" && doctor != "") {
      const schedule_date = time.split("T")[0];

      var url = "";

      if (value) {
        url =
          process.env.REACT_APP_API_URL + "/citations/checkErstDoctorSchedule";
      } else {
        url = process.env.REACT_APP_API_URL + "/citations/checkDoctorSchedule";
      }

      console.log("DATA:");
      console.log("Date: ", schedule_date);
      console.log("Doctor: ", doctor);
      console.log("Erst: ", value);
      console.log(url);

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        body: JSON.stringify({
          date: schedule_date,
          doctor: doctor,
        }),
      }).then((response) => {
        console.log(response);
        response.json().then((data) => {
          // console.log(data['data']);
          var current_data = [];
          data["data"].map((item, i) =>
            current_data.push(item.split("T")[1].split(":00.000")[0])
          );
          console.log(current_data);
          setSchedule(current_data);
          setLoadingschedule(false);
        });
      });
    } else {
      console.log("time missing");
    }
  };

  const checkStatus = async (param, status_data) => {
    setChecking(true);

    console.log("Checking...");

    var raw = null;

    if (param === "time" && doctor != "") {
      console.log("Given Time, using doctor");
      raw = {
        time: status_data,
        doctor: doctor,
      };
      console.log(raw);
    } else if (param === "doctor" && statustime != null) {
      console.log("given doctor, using time");
      raw = {
        time: statustime,
        doctor: status_data,
      };
      console.log(raw);
    } else {
      console.log("one of more data is missing");
    }

    if (raw != null) {
      const res = await fetch(
        process.env.REACT_APP_API_URL + "/citations/check_doctor_time_status",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "api-key": process.env.REACT_APP_API_KEY,
            Authorization: userToken,
          },
          body: JSON.stringify(raw),
        }
      );
      const json = await res.json();

      console.log("response: ", json);

      if (json["citations"].length > 0) {
        console.log("Appointment starting at: ", json["citations"][0][0]);
        var appointmentStart = json["citations"][0][0]
          .split("T")[1]
          .split(":00.000Z")[0];
        setWarning(["appointment", appointmentStart]);
        // setWarning('> Warning: Overlapsing with ' + appointmentStart + ' hs appointment')
      } else if (json["locks"].length > 0) {
        console.log("Lock starting at: ", json["locks"][0][0]);
        var lockStart = json["locks"][0]["start"]
          .split("T")[1]
          .split(":00.000Z")[0];
        var lockEnd = json["locks"][0]["end"]
          .split("T")[1]
          .split(":00.000Z")[0];
        setWarning(["lock", lockStart, lockEnd]);
      } else {
        setWarning("ok");
        console.log("Everything ok");
      }
    } else {
      setWarning("");
      console.log("Data missing...");
    }

    setChecking(false);

    // const json = await res.json();

    // if (json.length > 0) {
    //     console.log('Appointment starting at: ', json[0]);
    // } else {
    //     console.log('Everything ok')
    // }
  };

  const onSubmit = async () => {
    if (
      name != "" &&
      firstname != "" &&
      lastname != "" &&
      time != "" &&
      doctor != "" &&
      treatment != ""
    ) {
      setLoading(true);
      const body = {
        name,
        firstname,
        lastname,
        date,
        time,
        doctor,
        treatment,
        email,
        phone,
        address,
        user_id,
        patient_id,
        created_by,
        warnings: remark,
      };

      console.log(body);

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        body: JSON.stringify(body),
      };

      await fetch(
        process.env.REACT_APP_API_URL + "/citations/create_from_doctor",
        requestOptions
      )
        .then((response) => {
          if (response.ok) {
            //window.location.reload();
            setShow(false);
            setIsCalendarUpdated(true);
          } else {
            setLoading(false);
            setError(true);
          }
        })
        .catch((error) => console.log("error", error));
    } else {
      setRequired(true);
    }
  };

  return (
    <>
      <div>
        <h1 className="title-modal">{t("Calendar.New Appointment")}</h1>
        <hr></hr>
        {loading ? (
          <>
            <div className="d-flex justify-content-center">
              <CircularProgress color="secondary" />
            </div>
          </>
        ) : (
          <>
            <div className="row">
              <div className="col">
                <div className="mt-1">
                  <DateTimePicker
                    schedule={schedule}
                    setDate={setDate}
                    setTime={setTime}
                    setTreatment={setTreatment}
                    checkSchedule={checkSchedule}
                    checkStatus={checkStatus}
                    setStatustime={setStatustime}
                  />
                </div>
                <div className="mt-3">
                  <TherapistPicker
                    schedule={schedule}
                    doctors={doctors}
                    setDoctor={setDoctor}
                    checkSchedule={checkSchedule}
                    checkStatus={checkStatus}
                  />
                </div>
                <div className="mt-3">
                  {checking ? (
                    <>{t("Buttons.Checking")}...</>
                  ) : (
                    <b>
                      {warning == "" ? (
                        <></>
                      ) : (
                        <>
                          {warning == "ok" ? (
                            <>
                              <Chip
                                size="small"
                                label="Free slot. Ready to book"
                                color="primary"
                              />
                            </>
                          ) : (
                            <>
                              {warning[0] == "appointment" ? (
                                <>
                                  <Chip
                                    size="small"
                                    label={`${t(
                                      "Calendar.Overlapping with appointment at"
                                    )} ${warning[1]}`}
                                    color="primary"
                                  />
                                </>
                              ) : (
                                <>
                                  {warning[0] == "lock" ? (
                                    <>
                                      <Chip
                                        size="small"
                                        label={`${t(
                                          "Calendar.Overlapping with time lock"
                                        )} ${t("Calendar.From")} ${
                                          warning[1]
                                        } ${t("Calendar.To")} ${warning[2]}`}
                                        color="primary"
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </b>
                  )}
                </div>
                <div className="mt-3">
                  <TreatmentPicker
                    treatments={treatments}
                    setTreatment={setTreatment}
                    checkSchedule={checkSchedule}
                  />
                </div>
                <div className="mt-3">
                  <PatientPicker
                    patients={patients}
                    setName={setName}
                    setFirstname={setFirstname}
                    setLastname={setLastname}
                    email={email}
                    setEmail={setEmail}
                    setPhone={setPhone}
                    setAddress={setAddress}
                    setUser={setUser}
                    setPatient={setPatient}
                    setInsurance={setInsurance}
                    infoRoute={infoRoute}
                  />
                </div>
                <div className="mt-3">
                  <PatientSummary
                    email={email}
                    phone={phone}
                    insurance={insurance}
                    remark={remark}
                    setRemark={setRemark}
                  />
                </div>
                <div className="mt-3">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={assistant}
                        onChange={() => setAssistant(!assistant)}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label={`${t("Calendar.Use Schedule Assistant")} (beta)`}
                  />
                </div>
                <div className="mt-4">
                  <SubmitButtons
                    onSubmit={onSubmit}
                    handleClose={handleClose}
                  />
                </div>
              </div>
              {assistant && schedule.length > 0 ? (
                <>
                  <div className="col-4">
                    <div
                      className="mt-1"
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: "15px",
                        paddingTop: "7px",
                        paddingBottom: "9px",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                      }}
                    >
                      <h1 className="schedule-title">
                        {t("Calendar.Schedule Assistant")}
                      </h1>
                      {loadingschedule ? (
                        <>
                          <div className="d-flex justify-content-center mt-5 mb-5">
                            <CircularProgress color="secondary" />
                          </div>
                        </>
                      ) : (
                        <>
                          {schedule.length > 0 ? (
                            <>
                              <table
                                className="table"
                                style={{ borderRadius: "10px" }}
                              >
                                <tbody>
                                  <tr>
                                    {schedule.includes("08:00") ? (
                                      <Tooltip title="Slot Available">
                                        <td className="box-available">08:00</td>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title="Slot Busy">
                                        <td className="box-unavailable">
                                          08:00
                                        </td>
                                      </Tooltip>
                                    )}
                                    {schedule.includes("08:20") ? (
                                      <Tooltip title="Slot Available">
                                        <td className="box-available">08:20</td>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title="Slot Busy">
                                        <td className="box-unavailable">
                                          08:20
                                        </td>
                                      </Tooltip>
                                    )}
                                    {schedule.includes("08:40") ? (
                                      <Tooltip title="Slot Available">
                                        <td className="box-available">08:40</td>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title="Slot Busy">
                                        <td className="box-unavailable">
                                          08:40
                                        </td>
                                      </Tooltip>
                                    )}
                                  </tr>
                                  <tr>
                                    {schedule.includes("09:00") ? (
                                      <Tooltip title="Slot Available">
                                        <td className="box-available">09:00</td>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title="Slot Busy">
                                        <td className="box-unavailable">
                                          09:00
                                        </td>
                                      </Tooltip>
                                    )}
                                    {schedule.includes("09:20") ? (
                                      <Tooltip title="Slot Available">
                                        <td className="box-available">09:20</td>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title="Slot Busy">
                                        <td className="box-unavailable">
                                          09:20
                                        </td>
                                      </Tooltip>
                                    )}
                                    {schedule.includes("09:40") ? (
                                      <Tooltip title="Slot Available">
                                        <td className="box-available">09:40</td>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title="Slot Busy">
                                        <td className="box-unavailable">
                                          09:40
                                        </td>
                                      </Tooltip>
                                    )}
                                  </tr>
                                  <tr>
                                    {schedule.includes("10:00") ? (
                                      <Tooltip title="Slot Available">
                                        <td className="box-available">10:00</td>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title="Slot Busy">
                                        <td className="box-unavailable">
                                          10:00
                                        </td>
                                      </Tooltip>
                                    )}
                                    {schedule.includes("10:20") ? (
                                      <Tooltip title="Slot Available">
                                        <td className="box-available">10:20</td>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title="Slot Busy">
                                        <td className="box-unavailable">
                                          10:20
                                        </td>
                                      </Tooltip>
                                    )}
                                    {schedule.includes("10:40") ? (
                                      <Tooltip title="Slot Available">
                                        <td className="box-available">10:40</td>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title="Slot Busy">
                                        <td className="box-unavailable">
                                          10:40
                                        </td>
                                      </Tooltip>
                                    )}
                                  </tr>
                                  <tr>
                                    {schedule.includes("11:00") ? (
                                      <td className="box-available">10:00</td>
                                    ) : (
                                      <td className="box-unavailable">11:00</td>
                                    )}
                                    {schedule.includes("11:20") ? (
                                      <td className="box-available">10:20</td>
                                    ) : (
                                      <td className="box-unavailable">11:20</td>
                                    )}
                                    {schedule.includes("11:40") ? (
                                      <td className="box-available">10:40</td>
                                    ) : (
                                      <td className="box-unavailable">11:40</td>
                                    )}
                                  </tr>
                                  <tr>
                                    {schedule.includes("12:00") ? (
                                      <td className="box-available">12:00</td>
                                    ) : (
                                      <td className="box-unavailable">12:00</td>
                                    )}
                                    {schedule.includes("12:20") ? (
                                      <td className="box-available">12:20</td>
                                    ) : (
                                      <td className="box-unavailable">12:20</td>
                                    )}
                                    {schedule.includes("12:40") ? (
                                      <td className="box-available">12:40</td>
                                    ) : (
                                      <td className="box-unavailable">12:40</td>
                                    )}
                                  </tr>
                                  <tr>
                                    {schedule.includes("13:00") ? (
                                      <td className="box-available">13:00</td>
                                    ) : (
                                      <td className="box-unavailable">13:00</td>
                                    )}
                                    {schedule.includes("13:20") ? (
                                      <td className="box-available">13:20</td>
                                    ) : (
                                      <td className="box-unavailable">13:20</td>
                                    )}
                                    {schedule.includes("13:40") ? (
                                      <td className="box-available">13:40</td>
                                    ) : (
                                      <td className="box-unavailable">13:40</td>
                                    )}
                                  </tr>
                                  <tr>
                                    {schedule.includes("14:00") ? (
                                      <td className="box-available">14:00</td>
                                    ) : (
                                      <td className="box-unavailable">14:00</td>
                                    )}
                                    {schedule.includes("14:20") ? (
                                      <td className="box-available">14:20</td>
                                    ) : (
                                      <td className="box-unavailable">14:20</td>
                                    )}
                                    {schedule.includes("14:40") ? (
                                      <td className="box-available">14:40</td>
                                    ) : (
                                      <td className="box-unavailable">14:40</td>
                                    )}
                                  </tr>
                                  <tr>
                                    {schedule.includes("15:00") ? (
                                      <td className="box-available">15:00</td>
                                    ) : (
                                      <td className="box-unavailable">15:00</td>
                                    )}
                                    {schedule.includes("15:20") ? (
                                      <td className="box-available">15:20</td>
                                    ) : (
                                      <td className="box-unavailable">15:20</td>
                                    )}
                                    {schedule.includes("15:40") ? (
                                      <td className="box-available">15:40</td>
                                    ) : (
                                      <td className="box-unavailable">15:40</td>
                                    )}
                                  </tr>
                                  <tr>
                                    {schedule.includes("16:00") ? (
                                      <td className="box-available">16:00</td>
                                    ) : (
                                      <td className="box-unavailable">16:00</td>
                                    )}
                                    {schedule.includes("16:20") ? (
                                      <td className="box-available">16:20</td>
                                    ) : (
                                      <td className="box-unavailable">16:20</td>
                                    )}
                                    {schedule.includes("16:40") ? (
                                      <td className="box-available">16:40</td>
                                    ) : (
                                      <td className="box-unavailable">16:40</td>
                                    )}
                                  </tr>
                                  <tr>
                                    {schedule.includes("17:00") ? (
                                      <td className="box-available">17:00</td>
                                    ) : (
                                      <td className="box-unavailable">17:00</td>
                                    )}
                                    {schedule.includes("17:20") ? (
                                      <td className="box-available">17:20</td>
                                    ) : (
                                      <td className="box-unavailable">17:20</td>
                                    )}
                                    {schedule.includes("17:40") ? (
                                      <td className="box-available">17:40</td>
                                    ) : (
                                      <td className="box-unavailable">17:40</td>
                                    )}
                                  </tr>
                                  <tr>
                                    {schedule.includes("18:00") ? (
                                      <td className="box-available">18:00</td>
                                    ) : (
                                      <td className="box-unavailable">18:00</td>
                                    )}
                                    {schedule.includes("18:20") ? (
                                      <td className="box-available">18:20</td>
                                    ) : (
                                      <td className="box-unavailable">18:20</td>
                                    )}
                                    {schedule.includes("18:40") ? (
                                      <td className="box-available">18:40</td>
                                    ) : (
                                      <td className="box-unavailable">18:40</td>
                                    )}
                                  </tr>
                                </tbody>
                              </table>
                              <div className="reference-container">
                                <span className="reference-text">
                                  {t(
                                    "Calendar.Yellow box indicates that the slot is reserved for erst treatments, locked or busy by another treatment"
                                  )}
                                </span>
                              </div>

                              {/* {schedule.map((item, i) => <>
                                    {i}, {(item.split('T')[1]).split(':00.000')[0]}
                                    <br />
                                    </>)} */}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        )}
      </div>

      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(!error)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setError(!error)}
          severity="error"
        >
          {t(
            "Calendar.Error creating appointment. Please backup your data and retry"
          )}
        </MuiAlert>
      </Snackbar>

      <Snackbar
        open={required}
        autoHideDuration={6000}
        onClose={() => setRequired(!required)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setRequired(!required)}
          severity="error"
        >
          {t(
            "Calendar.All the fields are mandatory. Please complete it and retry"
          )}
        </MuiAlert>
      </Snackbar>
    </>
  );
};
