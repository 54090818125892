import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useBeforeunload } from "react-beforeunload";
import { Accordion, Card, Button } from "react-bootstrap";
import {
  CircularProgress,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import edit_clock from "../../../../assets/icons/edit_clock.png";
import GetUserToken from "../../../../utils/GetUserToken";
import Alert from "../../../alerts/Alert";
import "../../../../assets/stylesheets/pulse.css";
import { symptoms } from "../../../../constants/pulse";
import {
  replaceSpecialCharacters,
  restoreSpecialCharacters,
  getValue,
} from "./helper";

const Pulse = ({ id, patientID, handleCallbackPulse }) => {
  // translations
  const { t } = useTranslation();
  let currentLanguage = localStorage.getItem("i18nextLng");
  // auth
  const userToken = GetUserToken();
  // utils
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const [loading, setLoading] = useState(true);
  const [valid, setValid] = useState(false);
  const [save, setSave] = useState(false);
  const [last, setLast] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [hovering, setHovering] = useState(false);
  // ref
  /*
    let textInput = useRef(null);
    let textInput2 = useRef(null);
    let textInput3 = useRef(null);
    let textInput4 = useRef(null);
    let textInput5 = useRef(null);
    let textInput6 = useRef(null);
    */
  // data
  const [pulsea, setpulsea] = useState("");
  const [pulseb, setpulseb] = useState("");
  const [pulsec, setpulsec] = useState("");
  const [pulsed, setpulsed] = useState("");
  const [pulsee, setpulsee] = useState("");
  const [pulsef, setpulsef] = useState("");

  const pulse = { pulsea, pulseb, pulsec, pulsed, pulsee, pulsef };

  // translations
  const getTranslation = (value) => {
    if (value) {
      let value_in_array = symptoms.filter(({ title }) => value === title);
      if (value_in_array.length !== 0) {
        let name = symptoms.filter((element) => element.title === value)[0]
          .nameEN;
        return t(`Pulse.${name}`);
      } else {
        return value;
      }
    } else {
      return value;
    }
  };
  // end translations

  useBeforeunload((event) => {
    if (save !== false) {
      event.preventDefault();
    }
  });

  const fetchData = async () => {
    handleCallbackPulse([false, "No changes"]);
    setLoading(true);

    var requestOptions = {
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL +
      `/citations/getPulse?patient_id=` +
      patientID,
      requestOptions
    );
    const json = await res.json();

    let data = restoreSpecialCharacters(json.pulse);

    let filteredData = data.filter(
      (element) =>
        element[2] ||
        element[3] ||
        element[4] ||
        element[5] ||
        element[6] ||
        element[7] != null
    );
    setFiltered(filteredData);

    let pulseData = data.find(
      (element) =>
        element[2] ||
        element[3] ||
        element[4] ||
        element[5] ||
        element[6] ||
        element[7] !== null
    );
    setLast(pulseData);

    if (pulseData) {
      handleCallbackPulse([true, pulseData[1]]);
      setValid(true);
      setpulsea(pulseData[2]);
      setpulseb(pulseData[3]);
      setpulsec(pulseData[4]);
      setpulsed(pulseData[5]);
      setpulsee(pulseData[6]);
      setpulsef(pulseData[7]);
      setLoading(false);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDateChange = (e) => {
    setpulsea(e.target.value[2]);
    setpulseb(e.target.value[3]);
    setpulsec(e.target.value[4]);
    setpulsed(e.target.value[5]);
    setpulsee(e.target.value[6]);
    setpulsef(e.target.value[7]);
    setLast(e.target.value);
    setSave(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const body = replaceSpecialCharacters(pulse);

    fetch(process.env.REACT_APP_API_URL + `/citations/update/` + id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });

        setSave(false);
        fetchData();
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.Something went wrong, please try again"),
        });
      });
  };

  return (
    <>
      <Accordion style={{ paddingRight: "0", paddingLeft: "0" }}>
        <Card
          className="reminders-cards"
          style={{ width: "93%", borderRadius: "10px" }}
        >
          <Card.Header
            className="reminders-subtitle"
            style={{
              border: "none",
              background: "white",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="0"
                className="text-center subtitles-patients"
                style={{ textDecoration: "none" }}
                id="accordion_pulse"
              >
                <span>&#8595; &nbsp;</span>
                {t("Titles.Pulse")}
              </Accordion.Toggle>
              <span className="subtitles-patients border-start">
                <img
                  src={edit_clock}
                  alt="clock"
                  onMouseEnter={() => setHovering(true)}
                  onMouseLeave={() => setHovering(false)}
                />
                {loading ? (
                  <></>
                ) : (
                  <>
                    {filtered.length !== 0 ? (
                      <>
                        <Select
                          id="treatment"
                          name="treatment"
                          value={last}
                          onChange={handleDateChange}
                        >
                          {filtered.map((item, i) => (
                            <MenuItem key={`pulse-menu-item-${i}`} value={item}>
                              <b>{item[0].split("T")[0]}&nbsp; /&nbsp;</b>
                              {item[1].replace("T", " at: ").slice(0, -8)}
                            </MenuItem>
                          ))}
                        </Select>
                        {hovering && (
                          <span className="key-text-box">
                            <b> {t("CompleteAppointment.Reference1")}</b>
                            {t("CompleteAppointment.Reference2")}
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        <span className="key-text-box">
                          &nbsp;{t("Messages.No previous records")}
                        </span>
                      </>
                    )}
                  </>
                )}
              </span>
            </div>
            <div>
              {save === false ? (
                <></>
              ) : (
                <button
                  className="button-citation-box-2-save"
                  onClick={handleSubmit}
                  style={{ padding: "6px" }}
                >
                  {t("Buttons.Save")}
                </button>
              )}
            </div>
          </Card.Header>

          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <form onSubmit={handleSubmit}>
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <CircularProgress color="secondary" />
                  </div>
                ) : (
                  <>
                    <div
                      className="row"
                      style={{
                        background: "#f4f5fa",
                        borderRadius: "10px",
                        paddingLeft: "25px",
                        paddingRight: "25px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <div className="col-3">
                        <p className="subtitles-patients">
                          {t("Pulse.Kidney")}
                        </p>
                        <Autocomplete
                          noOptionsText={"Not Found"}
                          options={symptoms.map((option) =>
                            t(`Pulse.${option.nameEN}`)
                          )}
                          value={getTranslation(pulsea)}
                          onChange={(event, value) => {
                            var fieldValue = value;
                            if (!fieldValue) {
                              fieldValue = null;
                            } else {
                              setpulsea(getValue(value));
                            }
                            setSave(true);
                          }}
                          style={{ width: "65%" }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                        <p className="subtitles-patients mt-2">
                          {t("Pulse.Spleen")}
                        </p>
                        <Autocomplete
                          noOptionsText={"Not Found"}
                          options={symptoms.map((option) =>
                            t(`Pulse.${option.nameEN}`)
                          )}
                          value={getTranslation(pulseb)}
                          onChange={(event, value) => {
                            var fieldValue = value;
                            if (!fieldValue) {
                              fieldValue = null;
                            } else {
                              setpulseb(getValue(value));
                            }
                            setSave(true);
                          }}
                          style={{ width: "65%" }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                        <p className="subtitles-patients mt-2">
                          {t("Pulse.Lung")}
                        </p>
                        <Autocomplete
                          noOptionsText={"Not Found"}
                          options={symptoms.map((option) =>
                            t(`Pulse.${option.nameEN}`)
                          )}
                          value={getTranslation(pulsec)}
                          onChange={(event, value) => {
                            var fieldValue = value;
                            if (!fieldValue) {
                              fieldValue = null;
                            } else {
                              setpulsec(getValue(value));
                            }
                            setSave(true);
                          }}
                          style={{ width: "65%" }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </div>

                      <div
                        className={`col-5 ${currentLanguage === "es" || currentLanguage === "en"
                          ? "pulse-background-translation"
                          : "pulse-background"
                          }`}
                      ></div>

                      <div className="col-3">
                        <p className="subtitles-patients mt-2">
                          {t("Pulse.Kidney")}
                        </p>
                        <Autocomplete
                          noOptionsText={"Not Found"}
                          options={symptoms.map((option) =>
                            t(`Pulse.${option.nameEN}`)
                          )}
                          value={getTranslation(pulsed)}
                          onChange={(event, value) => {
                            var fieldValue = value;
                            if (!fieldValue) {
                              fieldValue = null;
                            } else {
                              setpulsed(getValue(value));
                            }
                            setSave(true);
                          }}
                          style={{ width: "65%" }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                        <p className="subtitles-patients mt-2">
                          {t("Pulse.Liver")}
                        </p>
                        <Autocomplete
                          noOptionsText={"Not Found"}
                          options={symptoms.map((option) =>
                            t(`Pulse.${option.nameEN}`)
                          )}
                          value={getTranslation(pulsee)}
                          onChange={(event, value) => {
                            var fieldValue = value;
                            if (!fieldValue) {
                              fieldValue = null;
                            } else {
                              setpulsee(getValue(value));
                            }
                            setSave(true);
                          }}
                          style={{ width: "65%" }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                        <p className="subtitles-patients mt-2">
                          {t("Pulse.Heart")}
                        </p>
                        <Autocomplete
                          noOptionsText={"Not Found"}
                          options={symptoms.map((option) =>
                            t(`Pulse.${option.nameEN}`)
                          )}
                          value={getTranslation(pulsef)}
                          onChange={(event, value) => {
                            var fieldValue = value;
                            if (!fieldValue) {
                              fieldValue = null;
                            } else {
                              setpulsef(getValue(value));
                            }
                            setSave(true);
                          }}
                          style={{ width: "65%" }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </div>

                      <div className="col text-end"></div>
                    </div>
                  </>
                )}
              </form>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      {isAlert.open && (
        <Alert
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
    </>
  );
};

export default Pulse;
