import React from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";

export const ForgotPasswordStep1 = ({
  setCode,
  handleValidateCode,
  loading,
}) => {
  // translations
  const { t } = useTranslation();

  return (
    <>
      <div className="mt-5">{t("ForgotPassword.7")}</div>
      <div className="form-group mt-4">
        <label className="title-inputs">{t("ForgotPassword.8")}</label>
        <br />
        <br />
        <input
          type="text"
          onChange={(e) => setCode(e.target.value)}
          className="input-control"
          placeholder="Code"
          required
        />
      </div>
      <br />

      {loading ? (
        <div className="d-flex justify-content-center">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <>
          <button
            type="submit"
            className="blue-btn"
            style={{ width: "100%" }}
            onClick={handleValidateCode}
          >
            {t("ForgotPassword.6")}
          </button>
        </>
      )}
    </>
  );
};

export default ForgotPasswordStep1;
