// DEV: if the title, nameEN or nameES value is modified, you must also update that value in the translation files.

export const symptoms = [
  {
    id: "",
    title: "Keine ausgewählt",
    nameEN: "None selected",
    nameES: "Ninguno seleccionado",
  },
  {
    id: 0,
    title: "xìmài / dünn, fadenförmig",
    nameEN: "xìmài / thin, thread-like",
    nameES: "xìmài / delgado, como un hilo",
  },
  {
    id: 1,
    title: "fúmài / oberflächlich",
    nameEN: "fúmài / superficial",
    nameES: "fúmài / superficial",
  },
  {
    id: 2,
    title: "chénmài / tief",
    nameEN: "chénmài / deep",
    nameES: "chénmài / profundo",
  },
  {
    id: 3,
    title: "fúmài / verborgen",
    nameEN: "fúmài / hidden",
    nameES: "fúmài / oculto",
  },
  {
    id: 4,
    title: "chángmài / lang",
    nameEN: "chángmài / long",
    nameES: "chángmài / largo",
  },
  {
    id: 5,
    title: "duănmài / kurz",
    nameEN: "duănmài / short",
    nameES: "duănmài / corto",
  },
  {
    id: 6,
    title: "shímài / voll",
    nameEN: "shímài / full",
    nameES: "shímài / lleno",
  },
  {
    id: 7,
    title: "láomài / eingepfercht",
    nameEN: "láomài / crammed",
    nameES: "láomài / abarrotado",
  },
  {
    id: 8,
    title: "xūmài / leer",
    nameEN: "xūmài / empty",
    nameES: "xūmài / vacío",
  },
  {
    id: 9,
    title: "wēimài / verschwindend",
    nameEN: "wēimài / disappearing",
    nameES: "wēimài / desapareciendo",
  },
  {
    id: 10,
    title: "rúmài / durchnässt",
    nameEN: "rúmài / soaked",
    nameES: "rúmài / empapado",
  },
  {
    id: 11,
    title: "ruòmài / schwach",
    nameEN: "ruòmài / weak",
    nameES: "ruòmài / débil",
  },
  {
    id: 12,
    title: "xiánmài / drahtig, saitenförmig",
    nameEN: "xiánmài / wiry, stringy",
    nameES: "xiánmài / enjuto, fibroso",
  },
  {
    id: 13,
    title: "jĭnmài / straff",
    nameEN: "jĭnmài / taut",
    nameES: "jĭnmài / tenso",
  },
  {
    id: 14,
    title: "gémài / Trommelpuls",
    nameEN: "gémài / drum pulse",
    nameES: "gémài / pulso de tambor",
  },
  {
    id: 15,
    title: "kōumài / hohl",
    nameEN: "kōumài / hollow",
    nameES: "kōumài / hueco",
  },
  {
    id: 16,
    title: "sănmài / verstreut",
    nameEN: "sănmài / scattered",
    nameES: "sănmài / disperso",
  },
  {
    id: 17,
    title: "jímài / rasend",
    nameEN: "jímài / furious",
    nameES: "jímài / furioso",
  },
  {
    id: 18,
    title: "shuòmài / schnell",
    nameEN: "shuòmài / fast",
    nameES: "shuòmài / rápido",
  },
  {
    id: 19,
    title: "huănmài / entspannt, moderat",
    nameEN: "huănmài / relaxed, moderate",
    nameES: "huănmài / relajado, moderado",
  },
  {
    id: 20,
    title: "chímài / langsam",
    nameEN: "chímài / slow",
    nameES: "chímài / lento",
  },
  {
    id: 21,
    title: "dáimài / intermittierend",
    nameEN: "dáimài / intermittent",
    nameES: "dáimài / intermitente",
  },
  {
    id: 22,
    title: "cùmài / hüpfend",
    nameEN: "cùmài / bouncing",
    nameES: "cùmài / rebote",
  },
  {
    id: 23,
    title: "jiémài / knotig",
    nameEN: "jiémài / knotty",
    nameES: "jiémài / nudoso",
  },
  {
    id: 24,
    title: "huámài / schlüpfrig",
    nameEN: "huámài / slippery",
    nameES: "huámài / resbaladizo",
  },
  {
    id: 25,
    title: "sèmài / abgehackt, rauh",
    nameEN: "sèmài / choppy, rough",
    nameES: "sèmài / picado, áspero",
  },
  {
    id: 26,
    title: "hóngmài /	aufwallend, überflutend",
    nameEN: "hóngmài / surging, overflowing",
    nameES: "hóngmài / agitado, desbordante",
  },
  {
    id: 27,
    title: "dòngmài / bewegt",
    nameEN: "dòngmài / moving",
    nameES: "dòngmài / en movimiento",
  },
];

// const symptoms = [{ "id": 0, "title": "Leere" }, { "id": 0, "title": "Dünn, fadenförmig" }, { "id": 1, "title": "Oberflächlich" }, { "id": 2, "title": "Tief" }, { "id": 3, "title": "Verborgen" }, { "id": 4, "title": "Lang" }, { "id": 5, "title": "Kurz" }, { "id": 6, "title": "Voll" }, { "id": 7, "title": "Eingepfercht" }, { "id": 8, "title": "Leer" }, { "id": 9, "title": "Verschwindend" }, { "id": 10, "title": "Durchnässt" }, { "id": 11, "title": "Schwach" }, { "id": 12, "title": "Drahtig, saitenförmig" }, { "id": 13, "title": "Straff" }, { "id": 14, "title": "Trommelpuls" }, { "id": 15, "title": "Hohl" }, { "id": 16, "title": "Verstreut" }, { "id": 17, "title": "Rasend" }, { "id": 18, "title": "Schnell" }, { "id": 19, "title": "Entspannt, moderat" }, { "id": 20, "title": "Langsam" }, { "id": 21, "title": "Intermittierend" }, { "id": 22, "title": "Hüpfend" }, { "id": 23, "title": "Knotig" }, { "id": 24, "title": "Schlüpfrig" }, { "id": 25, "title": "Abgehackt, rauh" }, { "id": 26, "title": "Aufwallend, überflutend" }, { "id": 27, "title": "Bewegt" }];
