import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import NewLock from "./planner/NewLock";
import AddProductButton from "../../assets/icons/add_product_button.png";
import delete_img from "../../assets/icons/delete_invoice.png";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import GetUserToken from "../../utils/GetUserToken";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 999,
    color: "#fff",
  },
}));

export const Planner = () => {
  // translation
  const { t } = useTranslation();
  // auth
  const userToken = GetUserToken();

  const [loading, setLoading] = useState(false);
  const state = useSelector((state) => state.user);
  const doctorID = state.doctor?.id;
  const [data, setData] = useState([]);
  const [newlock, setNewlock] = useState(false);
  const [day, setDay] = useState("");
  const [dayname, setDayname] = useState("");
  const [hourerror, setHourerror] = useState(false);
  const classes = useStyles();

  console.log("current doctor: ", doctorID);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL +
      "/weeks/getWeeksByDocId?doc_id=" +
      doctorID,
      requestOptions
    );
    const json = await res.json();
    setData(json["weeks"]);
    setLoading(false);
    console.log(json);
  };

  const days = [
    { id: "1", day: "Monday" },
    { id: "2", day: "Tuesday" },
    { id: "3", day: "Wednesday" },
    { id: "4", day: "Thursday" },
    { id: "5", day: "Friday" },
  ];

  const handleDelete = async (lock) => {
    setLoading(true);

    var requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
    };

    await fetch(
      process.env.REACT_APP_API_URL + "/weeks/destroy/" + lock,
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          console.log("deleted!");
          fetchData();
        } else {
          console.log("error");
          setLoading(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      <h2 className="subtitles-patients mt-2 mb-4">
        {t("Calendar.Week Planner")}
      </h2>

      <div className="row">
        {days.map((item, i) => (
          <>
            <div className="col">
              <div className="row">
                <div className="col-5">
                  <b>{item.day}</b>
                </div>
                <div className="col-6">
                  <img
                    src={AddProductButton}
                    onClick={() => {
                      setDay(item.id);
                      setDayname(item.day);
                      setNewlock(true);
                    }}
                    style={{ width: "24px", alignContent: "right" }}
                  />
                </div>
              </div>

              <br />
              {data.map((subitem, i) => (
                <>
                  {subitem.day === item.id ? (
                    <>
                      <div className="row">
                        <div className="col-8">
                          {t("Calendar.From")}:{" "}
                          {subitem.start.split("T")[1].split(":00.000Z")[0]}
                          <br />
                          {t("Calendar.To")}:{" "}
                          {subitem.end.split("T")[1].split(":00.000Z")[0]}
                        </div>
                        <div className="col-4">
                          <img
                            src={delete_img}
                            style={{ marginTop: "15%" }}
                            onClick={() => handleDelete(subitem.id)}
                          />
                        </div>
                      </div>
                      <hr />
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ))}
              <div></div>
            </div>
          </>
        ))}
      </div>

      <Modal
        size="sm"
        show={newlock}
        onHide={() => setNewlock(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body style={{ background: "#F4F5FA" }}>
          <NewLock
            setHourerror={setHourerror}
            fetchData={fetchData}
            setLoading={setLoading}
            day={day}
            dayname={dayname}
            doctor={doctorID}
            setNewlock={setNewlock}
          />
        </Modal.Body>
      </Modal>

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        open={hourerror}
        autoHideDuration={6000}
        onClose={() => setHourerror(!hourerror)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setHourerror(!hourerror)}
          severity="error"
        >
          {t("Calendar.The start time must be lower than end time")}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default Planner;
