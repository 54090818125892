import React, { useEffect, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useTranslation } from "react-i18next";
import { es, de, enUS } from "date-fns/locale";

export const DatePicker = ({ date, setDate }) => {
  const { t, i18n } = useTranslation();
  let language = i18n.language.slice(0, 2);
  const [localizer, setLocalizer] = useState();

  useEffect(() => {
    switch (language) {
      case "es":
        setLocalizer(es);
        break;
      case "de":
        setLocalizer(de);
        break;
      default:
        setLocalizer(enUS);
    }
  }, [language]);

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localizer}>
        <KeyboardDatePicker
          //disabled={patient == null ? true : false}
          //disabled={true}
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          id="date-picker-inline"
          label={t("InvoiceDates.0")}
          value={date}
          onChange={setDate}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </MuiPickersUtilsProvider>
    </>
  );
};
