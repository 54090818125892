import React from "react";
import { Box, Button, useMediaQuery } from "@mui/material";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const ButtonScrollToTop = ({ isNeueTermin, handleClick }) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  if (!isMobile) {
    return null;
  }

  const scrollToTop = () => {
    const isSafari = /^((?!chrome|android|crios|edg|fxios).)*safari/i.test(
      navigator.userAgent
    );
    if (isSafari) {
      document.documentElement.scrollTop = 0;
    } else {
      handleClick();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        marginTop: isNeueTermin ? "18px" : "0px",
      }}
    >
      <Button
        onClick={scrollToTop}
        sx={{
          backgroundColor: "var(--primary-color)",
          color: "white",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          minWidth: "unset",
          minHeight: "unset",
          "&:hover": {
            backgroundColor: "var(--secondary-color)",
          },
        }}
      >
        <KeyboardArrowUpIcon />
      </Button>
    </Box>
  );
};

export default ButtonScrollToTop;
