import styled from 'styled-components'

export const UploadButton = styled('label')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: white;
  background-color: #38baff;
  cursor: pointer;
  height: 30px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  letter-spacing: 0.02857em;
`

export const Box = styled('div')`
  height: 100%;
`

export const BoxTitle = styled('h5')`
  margin: 0px;
  font-size: 16px;
  font-weight: 600;
  color: #564c60;
`

export const Separator = styled('div')`
  height: 1px;
  background-color: #dee2e6;
`

export const FilesList = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  overflow: auto;
`

export const File = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 8px;
  padding: 6px;
  border-radius: 4px;

  &:nth-child(odd){
    background-color: #f5f5f5;
  }
`

export const FileName = styled('a')`
  text-decoration: none;
  color: rgb(133, 124, 142);
  font-size: 13px;
  font-weight: 400;
  width: calc(100% - 46px);
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
`

export const DeleteFileButton = styled('button')`
  margin: 0px;
  padding: 0px;
  border: 0px;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: var(--primary-color);
  font-weight: 600;
  cursor: pointer;
  background-color: transparent;
  border-radius: 4px;
  transition: background-color .2s ease-in-out;

  &:hover {
    background-color: #e9d3ff;
  }
`