import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

const DeleteHerbDialog = ({
  dialog,
  setDialog,
  herb,
  setAnchorEl,
  handleDelete,
  isTranslation,
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setDialog(false);
    if (setAnchorEl) {
      setAnchorEl(null);
    }
  };

  return (
    <>
      <Dialog
        open={dialog}
        onClose={() => setDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {isTranslation
            ? t("Herbs.Translation delete")
            : t("Herbs.Herb delete")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="text-center"
          >
            {isTranslation
              ? t("Are you sure you want to delete this translation?")
              : t("Herbs.Are you sure you want to delete this herb?")}
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            {t("Herbs.No")}
          </Button>
          <Button onClick={() => handleDelete(herb.id)} color="secondary">
            {t("Herbs.Yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteHerbDialog;
