import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { EditText } from "react-edit-text";
import { Tab, Tabs } from "react-bootstrap";
import {
  Backdrop,
  CircularProgress,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { TbPlant2 } from "react-icons/tb";
import DeleteHerbDialog from "./DeleteHerbDialog";
import "../../assets/stylesheets/herbs.css";
import "react-edit-text/dist/index.css";
import fetchApi from "../../utils/fetchApi";
import AlertComponent from "../alerts/Alert";
import {
  formatHerbTranslations,
  handleViewHerbTranslations,
  updatedHerbTranslation,
} from "../../utils/helper";
import HerbFields from "./viewEditHerb/HerbFields";

// styles
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const ViewEditHerb = (infoRoute) => {
  // styles
  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"));
  // translations
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState("");
  // route
  const {
    match: {
      params: { action, id },
    },
  } = infoRoute;
  const history = infoRoute.history;
  // redux
  const state = useSelector((state) => state.user);
  // utils
  const [loading, setLoading] = useState(true);
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  // data
  const [languages, setLanguages] = useState([]);
  const [herb, setHerb] = useState({});
  const [herbTranslation, setHerbsTranslation] = useState([]);
  // delete
  const [dialog, setDialog] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentTranslation, setCurrentTranslation] = useState([]);

  // check if the action is 'view' or 'edit'
  let isEditingMode = false;

  if (action !== "view") {
    isEditingMode = true;
  }

  // menu
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event, item) => {
    setAnchorEl(event.currentTarget);
  };

  const fetchData = async () => {
    try {
      const url = `
        ${process.env.REACT_APP_HERBS_API}/herbs/${id}`;
      const { data } = await fetchApi(url, "GET");

      let herbs_translations_response = formatHerbTranslations(
        data.herbs_translations
      );
      setHerb(data);
      setHerbsTranslation(herbs_translations_response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchLanguages = async () => {
    try {
      setLoading(true);
      const url = `
        ${process.env.REACT_APP_HERBS_API}/constants`;
      const { data } = await fetchApi(url, "GET");
      setLanguages(data.languages);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (state.auth.language) {
      switch (state.auth.language) {
        case "EN":
          i18n.changeLanguage("en");
          setCurrentLanguage("EN");
          break;
        case "ES":
          i18n.changeLanguage("es");
          setCurrentLanguage("ES");
          break;
        default:
          i18n.changeLanguage("de");
          setCurrentLanguage("DE");
          break;
      }
    }
    fetchData();
    fetchLanguages();
  }, []);

  const handleUpdateHerbTranslations = (
    idLanguage,
    name,
    value,
    sub_value,
    index
  ) => {
    let herbs_translations_updated = updatedHerbTranslation(
      idLanguage,
      name,
      value,
      sub_value,
      index,
      herbTranslation,
      id
    );
    setHerbsTranslation(herbs_translations_updated);
  };

  const handleDeleteHerb = async (id) => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_HERBS_API}/herbs/${id}`;
      const response = await fetchApi(url, "DELETE");
      if (response.ok) {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
        setTimeout(() => {
          history.push("/herbs");
        }, 1000);
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setLoading(false);
      setDialog(false);
    }
  };

  const handlePreDeleteHerbTranslation = (id) => {
    const delete_translation = herbTranslation.filter(
      (element) => element.languages_id === id
    );
    setCurrentTranslation(delete_translation);
    setIsOpen(true);
  };

  const handleDeleteHerbTranslation = async (id) => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_HERBS_API}/herbs_translations/${id}`;
      const response = await fetchApi(url, "DELETE");
      if (response.ok) {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
        fetchData();
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setLoading(false);
      setIsOpen(false);
    }
  };

  const handleSubmitHerb = async () => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_HERBS_API}/herbs/${id}`;
      const response = await fetchApi(url, "PUT", JSON.stringify(herb));
      if (response.ok) {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitHerbTranslation = async () => {
    let invalidTranslations = herbTranslation.filter(
      (obj) => !obj.name || obj.name === null
    );
    if (herbTranslation.length === 0 || invalidTranslations.length !== 0) {
      setIsAlert({
        open: true,
        severity: "warning",
        message: t("Messages.The name field is required"),
      });
      return;
    }

    try {
      setLoading(true);
      let body = formatHerbTranslations(herbTranslation, true);
      const url = `${process.env.REACT_APP_HERBS_API}/herbs_translations/batch`;
      const response = await fetchApi(url, "POST", JSON.stringify(body));
      if (response.ok) {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
        fetchData();
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setLoading(false);
    }
  };

  // console.log("herb", herb);
  // console.log("herbTranslation", herbTranslation);

  return (
    <>
      <div className="frame" style={{ paddingBottom: "18px" }}>
        <div className={isTablet ? "mb-4" : "mb-0"}>
          <Link
            to="/herbs"
            className="blue-anchors"
            style={{ textDecoration: "none" }}
          >
            <span>&lt;&nbsp;{t("ViewHerb.0")}</span>
          </Link>
        </div>
        <div
          className={`d-flex justify-content-${isTablet ? "between" : "end"}`}
          style={{ width: "95%" }}
        >
          {/* IMAGE */}
          {isTablet &&
            (herb.image ? (
              <img
                src={herb.image}
                alt="herb"
                style={{
                  height: "180px",
                  width: "200px",
                  marginRight: "25px",
                }}
              />
            ) : (
              <TbPlant2
                size={200}
                style={{
                  marginRight: "25px",
                  strokeWidth: "0.7",
                  color: "var(--primary-color)",
                }}
              />
            ))}

          {/* DOTS */}
          {herb.owner_id === state.auth.user_id && (
            <svg
              onClick={(event) => openMenu(event, id)}
              id={id}
              style={{ cursor: "pointer" }}
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="#0d6efd"
              className="bi bi-three-dots-vertical"
              viewBox="0 0 16 16"
            >
              <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
            </svg>
          )}
        </div>

        {/* Image URL */}
        {isEditingMode && isTablet && (
          <div
            className="subtitles-patients mb-3 mt-3"
            style={{
              width: "95%",
            }}
          >
            <p className="subtitles-patients mb-0 p-1">
              {t("NewIndividualHerbs.21")}
            </p>
            <EditText
              name="image"
              defaultValue={herb.image ? herb.image : t("Messages.no data")}
              onSave={({ value }) =>
                setHerb({
                  ...herb,
                  image: value,
                })
              }
            />
          </div>
        )}

        <div
          style={{
            width: !isTablet && "94%",
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* IMAGE */}
          {!isTablet &&
            (herb.image ? (
              <img
                src={herb.image}
                alt="herb"
                style={{
                  height: "180px",
                  width: "200px",
                  marginRight: "25px",
                }}
              />
            ) : (
              <TbPlant2
                size={200}
                style={{
                  marginRight: "25px",
                  strokeWidth: "0.7",
                  color: "var(--primary-color)",
                }}
              />
            ))}

          <div
            className="row patient-id-box"
            style={{
              width: "95%",
              marginLeft: "0px",
              marginTop: isTablet ? "0px" : "50px",
              paddingLeft: "20px",
              marginBottom: "21px",
            }}
          >
            <div className="d-flex justify-content-between mt-2">
              <p className="subtitles-patients" style={{ fontSize: "18px" }}>
                {t("ViewHerb.1")}
              </p>
              <div className="col-4 button-column">
                <div className="key-text-box-edit-button d-flex justify-content-end"></div>
              </div>
            </div>
            {/* Names boxes */}
            <div className="col-4 text-left">
              <p className="subtitles-patients">{t("ViewHerb.17")}</p>
              <div className="col">
                {!isEditingMode && (
                  <div className="_i5--j key-text-box px-0">
                    {herb.pinyin_name
                      ? herb.pinyin_name
                      : t("Messages.no data")}
                  </div>
                )}
                {isEditingMode && (
                  <EditText
                    className="key-text-box px-0"
                    name="pinyin_name"
                    defaultValue={herb.pinyin_name}
                    onSave={({ value }) =>
                      setHerb({
                        ...herb,
                        pinyin_name: value,
                      })
                    }
                  />
                )}
              </div>
            </div>
            <div className="col-4 text-left border-start">
              <p className="subtitles-patients">{t("ViewHerb.3")}</p>
              <div className="col">
                {!isEditingMode && (
                  <div className="_i5--j key-text-box px-0">
                    {herb.latin_name ? herb.latin_name : t("Messages.no data")}
                  </div>
                )}
                {isEditingMode && (
                  <EditText
                    className="key-text-box px-0"
                    name="latin_name"
                    defaultValue={herb.latin_name}
                    onSave={({ value }) =>
                      setHerb({
                        ...herb,
                        latin_name: value,
                      })
                    }
                  />
                )}
              </div>
            </div>
            <div className="col-4 border-start">
              <div className="row">
                <div className="row">
                  <div className="col-8" style={{ paddingLeft: "38px" }}>
                    <p className="subtitles-patients">{t("ViewHerb.16")}</p>
                  </div>
                </div>
                <div className="col" style={{ paddingLeft: "38px" }}>
                  {!isEditingMode && (
                    <div className="_i5--j key-text-box">
                      {herb.chinese_name
                        ? herb.chinese_name
                        : t("Messages.no data")}
                    </div>
                  )}
                  {isEditingMode && (
                    <EditText
                      className="key-text-box"
                      name="chinese_name"
                      defaultValue={herb.chinese_name}
                      onSave={({ value }) =>
                        setHerb({
                          ...herb,
                          chinese_name: value,
                        })
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            {/* end Names boxes */}
          </div>
        </div>

        {/* Image URL */}
        {isEditingMode && !isTablet && (
          <div
            className="subtitles-patients mb-3"
            style={{
              width: "95%",
            }}
          >
            <p className="subtitles-patients mb-0 p-1">
              {t("NewIndividualHerbs.21")}
            </p>
            <EditText
              name="image"
              defaultValue={herb.image ? herb.image : t("Messages.no data")}
              onSave={({ value }) =>
                setHerb({
                  ...herb,
                  image: value,
                })
              }
            />
          </div>
        )}

        {/* Button Update Herb */}
        {isEditingMode && (
          <div
            className="d-flex justify-content-end mb-3"
            style={{ width: "95%" }}
          >
            <button
              className="blue-btn"
              onClick={() => handleSubmitHerb()}
              style={{ width: "185px" }}
            >
              {t("Buttons.Save")}
            </button>
          </div>
        )}

        {/* Tabs */}
        {currentLanguage && languages && (
          <Tabs
            defaultActiveKey={currentLanguage}
            id="new-herb"
            className="mb-3"
          >
            {languages.map(({ id, name, code }) => (
              <Tab key={id} eventKey={code} title={name}>
                <HerbFields
                  isEditingMode={isEditingMode}
                  idLanguage={id}
                  herbTranslation={herbTranslation}
                  currentTranslation={currentTranslation}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  handleViewHerbTranslations={handleViewHerbTranslations}
                  handleUpdateHerbTranslations={handleUpdateHerbTranslations}
                  handleSubmitHerbTranslation={handleSubmitHerbTranslation}
                  handlePreDeleteHerbTranslation={
                    handlePreDeleteHerbTranslation
                  }
                  handleDeleteHerbTranslation={handleDeleteHerbTranslation}
                />
              </Tab>
            ))}
          </Tabs>
        )}
      </div>

      {isAlert.open && (
        <AlertComponent
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        style={{ paddingLeft: "0px" }}
      >
        {!isEditingMode && herb.owner_id === state.auth.user_id && (
          <Link
            target="_blank"
            to={{
              pathname: `/herb/edit/${id}`,
            }}
            style={{ color: "#000", textDecoration: "none" }}
          >
            <MenuItem className="invoice-menu-item">
              &nbsp;
              <span className="invoice-menu-text">
                <>{t("HerbsList.10")}</>
              </span>
            </MenuItem>
          </Link>
        )}
        {herb.owner_id === state.auth.user_id && (
          <MenuItem
            onClick={() => setDialog(true)}
            className="invoice-menu-item"
          >
            &nbsp;
            <span className="invoice-menu-text">
              <>{t("HerbsList.11")}</>
            </span>
          </MenuItem>
        )}
      </Menu>

      <DeleteHerbDialog
        herb={herb}
        dialog={dialog}
        setDialog={setDialog}
        setAnchorEl={setAnchorEl}
        handleDelete={handleDeleteHerb}
      />
    </>
  );
};

export default ViewEditHerb;
