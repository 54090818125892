import bankAccount from "./bank-account";
import company from "./company";
import contactInformation from "./contact-information";
import herb from "./herb";
import blend from "./blend";
import prescription from "./prescription";
import pharmacy from "./pharmacy";
import diagnosis from "./diagnosis";
import doctor from "./doctor";
import patient from "./patient";
import citation from "./citation";
import treatment from "./treatment";
import insurance from "./insurance";
import media from "./media";
import user from "./user";

const services = {
  bankAccount,
  company,
  contactInformation,
  herb,
  blend,
  prescription,
  pharmacy,
  diagnosis,
  doctor,
  patient,
  citation,
  treatment,
  insurance,
  media,
  user,
};

export default services;
