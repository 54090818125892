import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import "../../../assets/stylesheets/schedules.css"

export const TimeLockerDialog = ({ creating, timeLockStart, timeLockEnd, doctors, handleDoctorChange, submitTimeLock, title, setTitle }) => {
    const { t } = useTranslation();
    const [validate, setValidate] = useState(false);

    return (
        <>
            <h3 id="simple-modal-title">{t('Calendar.Create single lock')}:</h3>
            <p id="simple-modal-description">
                {t('Calendar.From')}: {timeLockStart} <br />
                {t('Calendar.To')}: {timeLockEnd} <br />

            </p>
            <div className="form-group">
                <label className="title-inputs">{t('Calendar.Title')}</label>
                <input
                    required
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="input-control"
                />
            </div>
            <label htmlFor="doctor" className="title-inputs">{t('Calendar.Therapist')}</label>
            <br />
            <Autocomplete
                noOptionsText={'Therapist not found'}
                options={doctors}
                onChange={(e, value) => {
                    handleDoctorChange(e, value)
                    setValidate(true)
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} />}
            />

            <br />
            
            <br />
            <br />
            {creating ? <></> : <>
                {validate ? <>
                    <div className="d-flex justify-content-center">
                        <button className="new-lock-btn" type="button" onClick={submitTimeLock}>
                            {t('Buttons.Save')}
                        </button>
                    </div>
                </> : <>
                    <div className="d-flex justify-content-center">
                        <button disabled className="new-lock-btn-disabled" type="button">
                            {t('Buttons.Save')}
                        </button>
                    </div>
                </>}

            </>}
        </>
    )
}

export default TimeLockerDialog;
