import React from "react";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ButtonScrollToTop from "../../../reusableComponents/ButtonScrollToTop";

const useStyles = makeStyles({
  textField: {
    '& input[type="date"]': {
      "&::-webkit-datetime-edit": {
        color: "transparent",
      },
      "&:focus::-webkit-datetime-edit, &:valid::-webkit-datetime-edit": {
        color: "#857C8E",
      },
    },
  },
});

const SecondStepNewPatient = ({
  patientData,
  insurances,
  onChange,
  prevStep,
  nextStep,
  scrollToNavbar,
}) => {
  // translations
  const { t } = useTranslation();
  // styles
  const classes = useStyles();
  // data
  const {
    firstname,
    lastname,
    email,
    password,
    passwordConfirmation,
    insurance,
    phone,
    birth,
    city,
    zip,
    street,
  } = patientData;

  return (
    <div className="container-NeueTermin">
      <div className="container-fluid">
        <div className="frame-NeueTermin">
          <div className="root">
            <h2 className="titles">{t("NeueTermin.9")}</h2>
            <h2 className="new-appointment-back-link">
              <a
                className="new-appointment-back-link"
                style={{ textDecoration: "none" }}
                href="/"
              >
                &#60; {t("NeueTermin.10")}
              </a>
            </h2>
            <h3 className="subTitles">{t("NeueTermin.17")}</h3>
            <br />
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <h3 className="subTitles">{t("NeueTermin.18")}</h3>
                  <div className="row">
                    <div className="col-sm-4 col-lg-4 col-xxl-3">
                      <FormControl className="inputTitle formControl-NewCitation">
                        <TextField
                          required
                          type="email"
                          value={email}
                          error={!email.includes("@")}
                          id="email"
                          label={t("EditPatient.Email")}
                          name="email"
                          size="small"
                          onChange={onChange}
                        />
                      </FormControl>

                      <FormControl className="inputTitle formControl-NewCitation">
                        <TextField
                          required
                          value={password}
                          error={password !== passwordConfirmation}
                          id="password"
                          label={t("UserProfile.Password")}
                          type="password"
                          name="password"
                          size="small"
                          onChange={onChange}
                        />
                      </FormControl>

                      <FormControl className="inputTitle formControl-NewCitation">
                        <TextField
                          required
                          value={passwordConfirmation}
                          error={password !== passwordConfirmation}
                          id="passwordConfirmation"
                          label={t("UserProfile.Password Confirmation")}
                          type="password"
                          name="passwordConfirmation"
                          size="small"
                          onChange={onChange}
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <h3 className="subTitles">{t("NeueTermin.19")}</h3>
                  <div className="row">
                    <div className="col-sm-12 col-lg-4 col-xxl-3">
                      <FormControl className="inputTitle formControl-NewCitation">
                        <TextField
                          required
                          value={firstname}
                          id="firstname"
                          label={t("EditPatient.First Name")}
                          type="text"
                          name="firstname"
                          size="small"
                          onChange={onChange}
                        />
                      </FormControl>

                      <FormControl className="inputTitle formControl-NewCitation">
                        <TextField
                          required
                          value={lastname}
                          id="lastname"
                          label={t("EditPatient.Last Name")}
                          type="text"
                          name="lastname"
                          size="small"
                          onChange={onChange}
                        />
                      </FormControl>

                      <FormControl className="inputTitle formControl-NewCitation">
                        <InputLabel id="insurance-label">
                          {t("EditPatient.Insurance")}
                        </InputLabel>
                        <Select
                          value={insurance}
                          labelId="insurance-label"
                          id="insurance"
                          type="text"
                          name="insurance"
                          onChange={onChange}
                        >
                          {insurances.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl className="inputTitle formControl-NewCitation">
                        <TextField
                          required
                          value={phone}
                          id="phone"
                          label={t("EditPatient.Phone")}
                          type="text"
                          name="phone"
                          size="small"
                          onChange={onChange}
                        />
                      </FormControl>
                    </div>
                    <div className="col-sm-12 col-lg-4 col-xxl-3">
                      <FormControl className="inputTitle formControl-NewCitation">
                        <TextField
                          required
                          value={city}
                          id="city"
                          label={t("EditPatient.City")}
                          type="text"
                          name="city"
                          size="small"
                          onChange={onChange}
                        />
                      </FormControl>

                      <FormControl className="inputTitle formControl-NewCitation">
                        <TextField
                          required
                          value={zip}
                          id="zip"
                          label={t("EditPatient.Zip")}
                          type="text"
                          name="zip"
                          size="small"
                          onChange={onChange}
                        />
                      </FormControl>

                      <FormControl className="inputTitle formControl-NewCitation">
                        <TextField
                          required
                          value={street}
                          id="street"
                          label={t("EditPatient.Street")}
                          type="text"
                          name="street"
                          size="small"
                          onChange={onChange}
                        />
                      </FormControl>

                      <FormControl className="inputTitle formControl-NewCitation">
                        <TextField
                          required
                          value={birth}
                          id="birth"
                          label={t("EditPatient.Birth")}
                          type="date"
                          name="birth"
                          size="small"
                          onChange={onChange}
                          className={classes.textField}
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3 mb-3 invert-column">
              <div className="col-sm-12 col-md-6 col-lg-4 col-xxl-3 mt-2 mb-2">
                <button
                  className="btnBack"
                  style={{
                    backgroundColor: "var(--secondary-color)",
                  }}
                  onClick={prevStep}
                >
                  {t("NeueTermin.15")}
                </button>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4 col-xxl-3 mt-2 mb-2">
                {firstname && lastname && email && phone ? (
                  <button
                    className="btnGo-NewCitation"
                    style={{
                      backgroundColor: "var(--primary-color)",
                    }}
                    onClick={nextStep}
                  >
                    {t("NeueTermin.16")}
                  </button>
                ) : (
                  <button className="btnBack" disabled onClick={nextStep}>
                    {t("NeueTermin.16")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <ButtonScrollToTop isNeueTermin={true} handleClick={scrollToNavbar} />
      </div>
    </div>
  );
};

export default SecondStepNewPatient;
