import styled from 'styled-components'
import { Link as UiLink } from 'react-router-dom'

export const Sheet = styled('div')`
  padding: 50px;
  background-color: white;
`

export const Box = styled('div')`
  border: 1px solid #dee2e6;
  padding: 10px 15px;
  border-radius: .375rem;
`

export const Paragraph = styled('p')`
  margin: 0px;
  ${({ textRight }) => textRight ? "text-align: right" : ""}
`

export const Section = styled('section')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  & > * {
    width: 100%;
  }
`

export const Logo = styled('div')`
  max-width: 200px;

  img {
    width: 100%;
    height: auto;
    max-width: 100px;
    max-height: 100px;
    display: block;
    padding: 0;
  }
`

export const TableTitle = styled('h3')`
  line-height: 24px;
  font-size: 16px;
  font-weight: normal;
  margin: 0px;
`

export const Table = styled('table')`
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
  width: 100%;
  font-size: 14px;
`

export const Thead = styled('thead')`
  border: 0;
  padding: 0;
`

export const Tbody = styled('tbody')`
  border: 0;
  padding: 0;
`

export const Tr = styled('tr')`
  border: 0px;
`

export const Th = styled('th')`
  border: 1px solid gray;
  padding: 0px 3px;
  font-size: 16px;
  overflow: visible;

  // &:first-child {
  //   border: 0px;
  //   border-bottom: 1px solid gray;
  //   text-align: center;
  //   padding: 0px;
  // }
`

export const Td = styled('td')`
  border: 1px solid gray;
  padding: 0px 3px;
  overflow: visible;

  &:first-child {
    border: 0px;
    border-bottom: 1px solid gray;
    border-left: 1px solid gray;
    text-align: center;
    padding: 0px;
  }
`

export const Input = styled('input')`
  border: 0px;
  padding: 0px;
`

export const Select = styled('select')`
  //border: 0px;
  padding: 0px;
  width: 100%;
  height: 30px;
  padding-block: 1px;
  padding-inline: 2px;
`

export const Link = styled(UiLink)`
  display: block;
  width: 100%;
  line-height: 38px;
  text-align: center;
  border: 1px solid #0d6efd;
  border-radius: 6px;
  text-decoration: none;
`