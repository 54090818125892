import GetUserToken from "../utils/GetUserToken";
import { parseJSON } from "../utils/parseJSON";

const urlBase = `${process.env.REACT_APP_API_URL_V2}/pharmacies`;
const readUrl = (url = "") => `${urlBase}/${url}`;

class Pharmacy {
  async getOne({ id }) {
    console.log("getOne - pharmacies - service", id);
    const auth = `${GetUserToken()}`;

    let res = await fetch(readUrl(id), {
      method: "GET",
      headers: {
        Authorization: auth,
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
      },
    });

    res = await parseJSON(res);
    return res;
  }

  async getAll(payload) {
    console.log("getAll - pharmacies - service");
    const auth = `${GetUserToken()}`;
    const { currentPage, sortBy, sortDirection, itemsPerPage, filters } =
      payload;
    const queryParams = new URLSearchParams(filters).toString();

    let res = await fetch(
      `${readUrl()}?${queryParams}&currentPage=${currentPage}&sortBy=${sortBy}&sortDirection=${sortDirection}&itemsPerPage=${itemsPerPage}`,
      {
        method: "GET",
        headers: {
          Authorization: auth,
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );

    res = await parseJSON(res);
    return res;
  }

  async createOne(payload) {
    console.log("createOne - pharmacies - service", payload);
    const auth = `${GetUserToken()}`;

    let res = await fetch(readUrl(), {
      method: "POST",
      headers: {
        Authorization: auth,
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(payload),
    });

    res = await parseJSON(res);
    return res;
  }

  async update(payload) {
    console.log("update - pharmacies - service", payload);
    const { id, ...rest } = payload;

    const auth = `${GetUserToken()}`;

    let res = await fetch(readUrl(id), {
      method: "PATCH",
      headers: {
        Authorization: auth,
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(rest),
    });

    res = await parseJSON(res);
    return res;
  }
}

const pharmacy = new Pharmacy();
export default pharmacy;
