import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    minHeight: 82,
    width: 310,
    backgroundColor: "#FFFFFF",
    borderRadius: "5px",
    padding: "6px 18px 18px 18px",
    [theme.breakpoints.up("sm")]: {
      width: 450,
    },
  },
  inputLabel: {
    color: "#8c8c8c",
    backgroundColor: "transparent",
    paddingBottom: "4px",
  },
  loading: {
    color: "#8c8c8c",
  },
  list: {
    width: "100%",
    maxHeight: "185px",
    overflowY: "auto",
    padding: 0,
    margin: 0,
    listStyleType: "none",
    scrollbarColor: "var(--secondary-color) #FFFFFF",
    scrollbarWidth: "thin",
    // "&::-webkit-scrollbar": {
    //   width: "8px",
    // },
    // "&::-webkit-scrollbar-track": {
    //   background: "#FFFFFF",
    // },
    // "&::-webkit-scrollbar-thumb": {
    //   backgroundColor: "var(--secondary-color)",
    //   borderRadius: "10px",
    // },
  },
  listItem: {
    backgroundColor: "var(--secondary-color)",
    color: "#FFFFFF",
    padding: "10px 18px",
    cursor: "pointer",
    margin: "2px 0",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "var(--primary-color)",
      color: "#FFFFFF",
    },
    "&.selected": {
      backgroundColor: "var(--primary-color)",
      color: "#FFFFFF",
    },
    whiteSpace: "normal",
    display: "block",
  },
  treatmentInfo: {
    display: "flex",
    flexDirection: "column",
  },
  treatmentName: {
    fontWeight: "bold",
  },
  treatmentDescription: {
    textDecoration: "underline",
  },
}));

export default useStyles;
