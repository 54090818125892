import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from "@material-ui/core/Switch";
import GetUserToken from "../../utils/GetUserToken";

export const EditTaxRate = ({
  insurance,
  updateInsurances,
  setShowupdatemodal,
  setIsAlert,
}) => {
  // auth
  const userToken = GetUserToken();
  // translation
  const { t } = useTranslation();
  // data
  const [vat_code, setVatCode] = useState("");
  const [description, setDescription] = useState("");
  const [tax_rate, setTaxRate] = useState("");
  // utils
  const [saving, setSaving] = useState(false);

  const fetchData = async () => {
    const url =
      process.env.REACT_APP_API_URL_V2 + "/tax_rates/show/" + insurance;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    const res = await fetch(url, requestOptions);
    const json = await res.json();
    setVatCode(json.vat_code);
    setDescription(json.description);
    setTaxRate(json.tax_rate);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async () => {
    setSaving(true);

    const url = process.env.REACT_APP_API_URL_V2 + "/tax_rates/update/";

    const body = {
      vat_code: vat_code,
      description: description,
      tax_rate: tax_rate,
    };

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      body: JSON.stringify(body),
    };

    await fetch(url + insurance, requestOptions)
      .then((response) => {
        if (response.ok) {
          setIsAlert({
            open: true,
            severity: "success",
            message: t("Messages.The operation was successful"),
          });
          setShowupdatemodal(false);
          updateInsurances();
        } else {
          setIsAlert({
            open: true,
            severity: "error",
            message: t("Messages.The operation could not be processed"),
          });
        }
      })
      .catch((error) =>
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.Something went wrong, please try again"),
        })
      );
  };

  return (
    <>
      <div className="form-group">
        <label className="title-inputs">{t("Settings.Code")}</label>
        <input
          required
          type="text"
          value={vat_code}
          onChange={(e) => setVatCode(e.target.value)}
          className="input-control"
        />
      </div>
      <div className="form-group">
        <label className="title-inputs">{t("Settings.Rate")} (%)</label>
        <input
          required
          type="number"
          step="0.01"
          min="0"
          value={tax_rate}
          onChange={(e) => {
            const value = e.target.value;

            if (!isNaN(value) && value >= 0) {
              setTaxRate(value);
            }
          }}
          className="input-control"
        />
      </div>
      <div className="form-group">
        <label className="title-inputs">{t("Insurances.Description")}</label>
        <TextareaAutosize
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          aria-label="minimum height"
          minRows={3}
          className="input-control"
        />
      </div>

      <div className="d-flex justify-content-center">
        {saving ? (
          <>
            <CircularProgress color="secondary" />
          </>
        ) : (
          <>
            {vat_code && tax_rate != "" ? (
              <>
                <button className="blue-btn" onClick={handleSubmit}>
                  {t("Buttons.Save")}
                </button>
              </>
            ) : (
              <>
                <button disabled className="blue-btn-disabled">
                  {t("Buttons.Save")}
                </button>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default EditTaxRate;
