import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";
import { TextareaAutosize } from "@mui/material";

const HomeRecommendationsPatients = ({ recommendation }) => {
  const { t } = useTranslation();

  return (
    <>
      <Card style={{ width: "auto", borderRadius: "10px" }}>
        <Card.Body>
          <div className="row">
            <div className="col-lg-6 col-sm-12 mb-2 mt-2">
              <Card className="mx-auto">
                <Card.Header
                  className="reminders-subtitle"
                  style={{ background: "var(--secondary-color)" }}
                >
                  {t("Recommendation.Feeding")}
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <TextareaAutosize
                      value={recommendation.fieldOne}
                      aria-label="minimum height"
                      minRows={6}
                      style={{ width: 260 }}
                    />
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>

            <div className="col-lg-6 col-sm-12 mb-2 mt-2">
              <Card className="mx-auto">
                <Card.Header
                  className="reminders-subtitle"
                  style={{ background: "var(--secondary-color)" }}
                >
                  {t("Recommendation.Miscellaneous")}
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <TextareaAutosize
                      value={recommendation.fieldTwo}
                      aria-label="minimum height"
                      minRows={6}
                      style={{ width: 260 }}
                    />
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-6 col-sm-12 mb-2 mt-2">
              <Card className="mx-auto">
                <Card.Header
                  className="reminders-subtitle"
                  style={{ background: "var(--secondary-color)" }}
                >
                  {t("Recommendation.Exercise")}
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <TextareaAutosize
                      value={recommendation.fieldThree}
                      aria-label="minimum height"
                      minRows={6}
                      style={{ width: 260 }}
                    />
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>

            <div className="col-lg-6 col-sm-12 mb-2 mt-2">
              <Card className="mx-auto">
                <Card.Header
                  className="reminders-subtitle"
                  style={{ background: "var(--secondary-color)" }}
                >
                  {t("Recommendation.Nem")}
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <TextareaAutosize
                      value={recommendation.fieldFour}
                      aria-label="minimum height"
                      minRows={6}
                      style={{ width: 260 }}
                    />
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>

          <div className="row ">
            <div className="col-lg-6 col-sm-12 mb-2 mt-2">
              <Card className="mx-auto">
                <Card.Header
                  className="reminders-subtitle"
                  style={{ background: "var(--secondary-color)" }}
                >
                  {t("Recommendation.Findings")}
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <TextareaAutosize
                      value={recommendation.fieldFive}
                      aria-label="minimum height"
                      minRows={6}
                      style={{ width: 260 }}
                    />
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default HomeRecommendationsPatients;
