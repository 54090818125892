import GetUserToken from "../utils/GetUserToken";
import { parseJSON } from "../utils/parseJSON";

const urlBase = `${process.env.REACT_APP_API_URL}/patients`;
const readUrl = (url = "") => `${urlBase}/${url}`;

class Patient {
  async getOne({ id }) {
    const auth = `${GetUserToken()}`;

    let res = await fetch(readUrl(id), {
      method: "GET",
      headers: {
        Authorization: auth,
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
      },
    });

    res = await parseJSON(res);
    return res;
  }

  async getOneByUid({ userID }) {
    const auth = `${GetUserToken()}`;
    const url = readUrl(`getPatientByUid?user_id=${userID}`);

    let res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: auth,
        "api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
    });

    res = await parseJSON(res);
    return res;
  }

  async getAll() {
    const auth = `${GetUserToken()}`;

    let res = await fetch(readUrl(), {
      method: "GET",
      headers: {
        Authorization: auth,
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
      },
    });

    res = await parseJSON(res);
    return res;
  }

  async createOne(payload) {
    const formData = new FormData();
    const keys = Object.keys(payload);

    for (const key of keys) {
      const value = payload[key];

      if (key === "logo") {
        if (typeof value !== "string") {
          const file = value[0];
          formData.append("logo", file, file.name);
        }
      } else {
        formData.append(key, value);
      }
    }

    const auth = `${GetUserToken()}`;

    let res = await fetch(readUrl("new"), {
      method: "POST",
      headers: {
        Authorization: auth,
        "Content-Type": "application/json",
      },
      body: formData,
    });

    res = await parseJSON(res);
    return res;
  }

  async update(payload) {
    const { id, ...rest } = payload;
    const formData = new FormData();
    const keys = Object.keys(rest);

    for (const key of keys) {
      const value = rest[key];

      if (key === "logo") {
        if (typeof value !== "string") {
          const file = value[0];
          formData.append("logo", file, file.name);
        }
      } else {
        formData.append(key, value);
      }
    }

    const auth = `${GetUserToken()}`;

    let res = await fetch(readUrl(id), {
      method: "POST",
      headers: {
        Authorization: auth,
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
      },
      body: formData,
    });

    res = await parseJSON(res);
    return res;
  }

  async getPatientCitations({ id }) {
    console.log("getPatientCitations - citations - service", id);
    const auth = `${GetUserToken()}`;
    const url = `${
      process.env.REACT_APP_API_URL_V2
    }/citations/get_patient_citations?id=${parseInt(id)}`;

    let res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: auth,
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
      },
    });

    res = await parseJSON(res);
    return res;
  }

  async newDocument(body) {
    console.log("newDocument - patients add document - service");
    const auth = `${GetUserToken()}`;
    const url = `${process.env.REACT_APP_API_URL}/patients/new_attachment`;

    let res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: auth,
        "api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    res = await parseJSON(res);
    return res;
  }

  async deleteDocument(body) {
    console.log("deleteDocument - patients delete document - service");
    const auth = `${GetUserToken()}`;
    const url = `${process.env.REACT_APP_API_URL}/patients/destroy_attachment`;

    let res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: auth,
        "api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    res = await parseJSON(res);
    return res;
  }

  async getDocument() {}

  async getAllDocuments({ patient_id }) {
    console.log("getAllDocuments - patients documents - service");
    const auth = `${GetUserToken()}`;
    const url = `${
      process.env.REACT_APP_API_URL
    }/patients/get_attachments/${parseInt(patient_id)}`;

    let res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: auth,
        "api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
    });

    res = await parseJSON(res);
    return res;
  }
}

const patient = new Patient();
export default patient;
