import { Box } from "@mui/material";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

const SuccessView = ({ text }) => {
  return (
    <Box
      sx={{
        height: "364px",
        width: "100%",
        maxWidth: "500px",
        mr: "auto",
        ml: "auto",
        mt: "20px",
        backgroundColor: "#ffffff",
        border: "1px solid #f1f4fa",
        borderRadius: "16px",
        p: "26px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h4 className="main-title mt-2">{text}</h4>
      <CheckCircleOutlineIcon
        style={{
          marginTop: "25px",
          width: "45px",
          height: "45px",
          color: "#4caf50",
        }}
      />
    </Box>
  );
};

export default SuccessView;
