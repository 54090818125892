import { styles } from "./styles"
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Wrapper,
  Loader
} from './styles'

const Component = ({ children, loading }) => {
  return (
    <Wrapper>
      {children}
      {loading && (
        <Loader>
          <CircularProgress size={50} />
        </Loader>
      )}
    </Wrapper>
  )
}

export default Component