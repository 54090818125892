import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import services from '../../services';

// Define initial state
const initialState = {
  one: {
    data: null,
    loading: false,
    error: false,
    success: false
  },
  create: {
    data: null,
    loading: false,
    error: false,
    success: false
  },
  all: {
    data: null,
    loading: false,
    error: false,
    success: false
  },
  update: {
    data: null,
    loading: false,
    error: false,
    success: false
  },
};

// Async action to get one contact information
const getOneContactInformation = createAsyncThunk(
  'contactInformation/getOne',
  async (payload) => {
    console.log('get one - contact information - action', payload)
    const { response, error } = await services.contactInformation.getOne(payload);
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Async action to get all contact information
const getAllContactInformation = createAsyncThunk(
  'contactInformation/getAll',
  async (payload) => {
    console.log('get all - contact information - action', payload)
    const { response, error } = await services.contactInformation.getAll(payload);
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Async action to crate one contact information
const createOneContactInformation = createAsyncThunk(
  'contactInformation/createOne',
  async (payload) => {
    console.log('createone - contact information - action', payload)
    const { response, error } = await services.contactInformation.createOne(payload);
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Async action to update contact information
const updateContactInformation = createAsyncThunk(
  'contactInformation/update',
  async (payload) => {
    console.log('update - contact information - action', payload)
    const { response, error } = await services.contactInformation.update(payload);
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Create a contact information slice
const contactInformationSlice = createSlice({
  name: 'contactInformation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOneContactInformation.pending, (state) => {
        state.one.data = null;
        state.one.loading = true;
        state.one.success = false;
        state.one.error = false;
      })
      .addCase(getOneContactInformation.fulfilled, (state, action) => {
        state.one.data = action.payload;
        state.one.loading = false;
        state.one.success = true;
        state.one.error = false;
      })
      .addCase(getOneContactInformation.rejected, (state, action) => {
        state.one.data = null;
        state.one.loading = false;
        state.one.success = false;
        state.one.error = action.error.message;
      })
      .addCase(getAllContactInformation.pending, (state) => {
        state.all.data = null;
        state.all.loading = true;
        state.all.success = false;
        state.all.error = false;
      })
      .addCase(getAllContactInformation.fulfilled, (state, action) => {
        state.all.data = action.payload;
        state.all.loading = false;
        state.all.success = true;
        state.all.error = false;
      })
      .addCase(getAllContactInformation.rejected, (state, action) => {
        state.all.data = null;
        state.all.loading = false;
        state.all.success = false;
        state.all.error = action.error.message;
      })
      .addCase(createOneContactInformation.pending, (state) => {
        state.create.data = null;
        state.create.loading = true;
        state.create.success = false;
        state.create.error = false;
      })
      .addCase(createOneContactInformation.fulfilled, (state, action) => {
        state.create.data = action.payload;
        state.create.loading = false;
        state.create.success = true;
        state.create.error = false;
      })
      .addCase(createOneContactInformation.rejected, (state, action) => {
        state.create.data = null;
        state.create.loading = false;
        state.create.success = false;
        state.create.error = action.error.message;
      })
      .addCase(updateContactInformation.pending, (state) => {
        state.update.data = null;
        state.update.loading = true;
        state.update.success = false;
        state.update.error = false;
      })
      .addCase(updateContactInformation.fulfilled, (state, action) => {
        state.update.data = action.payload;
        state.update.loading = false;
        state.update.success = true;
        state.update.error = false;
      })
      .addCase(updateContactInformation.rejected, (state, action) => {
        state.update.data = null;
        state.update.loading = false;
        state.update.success = false;
        state.update.error = action.error.message;
      });
  },
});

// Export the async actions and the reducer
export { getOneContactInformation, getAllContactInformation, createOneContactInformation, updateContactInformation };
export default contactInformationSlice.reducer;
