import React from 'react'
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import '../../../assets/stylesheets/newdoctorcitation.css'

export const TherapistPicker = ({ schedule, doctors, setDoctor, checkSchedule, checkStatus }) => {
    const { t } = useTranslation();

    return (
        <>
            <h1 className='subtitle-modal'>{t('Calendar.Therapist')}</h1>
            <FormControl style={{ minWidth: '100%', textAlign: 'left' }}>
                <Autocomplete
                    //disabled={schedule.length > 0}
                    onChange={(event, value) =>{
                        var fieldValue = value;
                        if (!fieldValue) {
                            fieldValue = null;
                        } else {
                            checkStatus("doctor", value.id);
                            setDoctor(value.id);
                        }

                        var field = value
                        if (!field) {
                            field = null;
                        } else{
                            checkSchedule(value.first);
                        }
                    }}
                    id="combo-box-demo"
                    options={doctors}
                    getOptionLabel={(option) => option.name}
                    style={{ width: '100%' }}
                    renderInput={(params) => <TextField  {...params} />}
                />
            </FormControl>

        </>
    )
}
