import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useBeforeunload } from "react-beforeunload";
import { Accordion, Card, Button } from "react-bootstrap";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Chip,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import edit_clock from "../../../../assets/icons/edit_clock.png";
import GetUserToken from "../../../../utils/GetUserToken";
import Alert from "../../../alerts/Alert";
import { values } from "./constants";
import { getArrayType, getType, getValue, getValueArray } from "./helper";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const Akupunktur = ({ id, patientID, handleCallbackAkupuntur }) => {
  // translations
  const { t } = useTranslation();
  // auth
  const userToken = GetUserToken();
  // utils
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const [last, setLast] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [loading, setLoading] = useState(true);
  const [valid, setValid] = useState(false);
  const [save, setSave] = useState(false);
  const [hovering, setHovering] = useState(false);

  /* First select*/
  const [selected, setSelected] = useState("");
  const [type, setType] = useState(null);

  /* Multiple select*/
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  // translations
  const getTranslationArray = (array) => {
    if (array.length !== 0) {
      let newArray = array.map((value) => {
        let array_type = getArrayType(value);
        let name = array_type.filter(
          (element) =>
            element.title === value ||
            element.nameEN === value ||
            element.nameES === value
        )[0].nameEN;
        let translation = t(`Akupunktur.${name}`);
        return translation;
      });
      return newArray;
    }
    return array;
  };
  // end translations

  useBeforeunload((event) => {
    if (save !== false) {
      event.preventDefault();
    }
  });

  const fetchData = async () => {
    handleCallbackAkupuntur([false, "No changes"]);
    setLoading(true);

    var requestOptions = {
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL +
      `/citations/getPoints?patient_id=` +
      patientID,
      requestOptions
    );
    const json = await res.json();

    let data = json.points;
    console.log("aca", data);
    let filteredData = data.filter((element) => element[2] != null);
    setFiltered(filteredData);

    let pulseData = data.find((element) => element[2] !== null);
    setLast(pulseData);

    if (pulseData) {
      handleCallbackAkupuntur([true, pulseData[1]]);
      setValid(true);
      setPersonName(JSON.parse(pulseData[2]));
      setLoading(false);
    }

    /* if (json.points !== null){
      setPersonName(JSON.parse(json.points));
      
    }; */
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    /** Set Type */
    let type = getType(selected);
    setType(type);
  }, [selected]);

  const changeSelectOptionHandler = (array, value) => {
    setSelected(getValue(array, value));
  };

  const handleChange = (value) => {
    setSave(true);
    setPersonName(
      getValueArray(
        // On autofill we get a the stringified value.
        typeof value === "string" ? value.split(",") : value
      )
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const body = { points: JSON.stringify(personName) };

    fetch(process.env.REACT_APP_API_URL + `/citations/update/` + id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
        setSave(false);
        fetchData();
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.Something went wrong, please try again"),
        });
      });
  };

  const handleDateChange = (e) => {
    setPersonName(JSON.parse(e.target.value[2]));
    setLast(e.target.value);
    setSave(true);
  };

  const cleanArray = () => {
    setPersonName([]);
  };

  return (
    <>
      <Accordion style={{ paddingRight: "0", paddingLeft: "0" }}>
        <Card
          className="reminders-cards"
          style={{ width: "93%", borderRadius: "10px" }}
        >
          <Card.Header
            className="reminders-subtitle"
            style={{
              border: "none",
              background: "white",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="0"
                className="text-center subtitles-patients"
                style={{ textDecoration: "none" }}
                id="accordion_akupunktur"
              >
                <span>&#8595; &nbsp;</span>
                {t("Titles.Akupunktur")}
              </Accordion.Toggle>
              <span className="subtitles-patients border-start">
                <img
                  src={edit_clock}
                  alt="clock"
                  onMouseEnter={() => setHovering(true)}
                  onMouseLeave={() => setHovering(false)}
                />
                {loading ? (
                  <></>
                ) : (
                  <>
                    {filtered.length !== 0 ? (
                      <>
                        <Select
                          id="treatment"
                          name="treatment"
                          value={last}
                          onChange={handleDateChange}
                          variant="standard"
                        >
                          {filtered.map((item, i) => (
                            <MenuItem key={`akupunktur-menu-item-${i}`} value={item}>
                              <b>{item[0].split("T")[0]}&nbsp; /&nbsp;</b>
                              {item[1].replace("T", " at: ").slice(0, -8)}
                              &nbsp;&nbsp;
                            </MenuItem>
                          ))}
                        </Select>
                        {hovering && (
                          <span className="key-text-box">
                            <b> {t("CompleteAppointment.Reference1")}</b>
                            {t("CompleteAppointment.Reference2")}&nbsp;
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        <span className="key-text-box">
                          &nbsp;{t("Messages.No previous records")}
                        </span>
                      </>
                    )}
                  </>
                )}
              </span>
              {personName.length ? (
                <>
                  <span
                    className="blue-anchors border-start"
                    type="button"
                    onClick={cleanArray}
                  >
                    &nbsp;&nbsp; {t("Messages.New values")}
                  </span>
                </>
              ) : (
                <></>
              )}
            </div>
            <div>
              {save === false ? (
                <></>
              ) : (
                <button
                  className="button-citation-box-2-save"
                  onClick={handleSubmit}
                  style={{ padding: "6px" }}
                >
                  {t("Buttons.Save")}
                </button>
              )}
            </div>
          </Card.Header>

          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <form
                onSubmit={handleSubmit}
                className="row"
                style={{
                  background: "#f4f5fa",
                  borderRadius: "10px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <CircularProgress color="secondary" />
                  </div>
                ) : (
                  <>
                    <div className="col-3 border-end">
                      <div className="form-group">
                        <label htmlFor="gender" className="subtitles-patients">
                          {t("Akupunktur.List of all acupuncture points")}
                        </label>
                        <br />
                        <br />
                        <Select
                          required
                          type="text"
                          onChange={(e) =>
                            changeSelectOptionHandler(values, e.target.value)
                          }
                          style={{ width: "135px" }}
                          defaultValue=""
                          label="Aller"
                        >
                          {values.map((item, index) => {
                            return (
                              <MenuItem key={`akupunktur-select-menu-item-${index}`} value={item.title}>
                                {t(`Akupunktur.${item.nameEN}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </div>
                    </div>

                    <div className="col-5 border-end">
                      <div className="form-group">
                        <FormControl className="form-control-width-citation">
                          <label
                            htmlFor="gender"
                            className="subtitles-patients"
                          >
                            {" "}
                            {t("Akupunktur.Options")}:
                          </label>
                          <br />
                          <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            value={getTranslationArray(personName)}
                            onChange={(e) => handleChange(e.target.value)}
                            label="Aller"
                            input={
                              <OutlinedInput
                                id="select-multiple-chip"
                                label="Chip"
                              />
                            }
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value) => (
                                  <Chip key={`akupunktur-chip-${value}`} label={value} />
                                ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {type !== null ? (
                              type.map((name) => (
                                <MenuItem
                                  key={name.title}
                                  value={name.title}
                                  style={getStyles(
                                    name.title,
                                    personName,
                                    theme
                                  )}
                                >
                                  {t(`Akupunktur.${name.nameEN}`)}
                                </MenuItem>
                              ))
                            ) : (
                              <></>
                            )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div className="col-4">
                      <h3 className="subtitles-patients mb-3">
                        {t("Akupunktur.Selected acupuncture points")}
                      </h3>
                      {getTranslationArray(personName).map((item) => {
                        return (
                          <p key={item} className="key-text-box">
                            {item}
                          </p>
                        );
                      })}
                    </div>
                  </>
                )}
              </form>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      {isAlert.open && (
        <Alert
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
    </>
  );
};

export default Akupunktur;
