import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Cropper from "react-easy-crop";
import getCroppedImg from "./helpers/cropImage";
import { Button } from "react-bootstrap";
import UploadImage from "../../../assets/icons/upload_placeholder.png";

const ImageCropMaterialUi = ({
  name,
  defaultValue,
  onInput,
  showZoomControl,
  showRotationControl,
  isAvatar,
}) => {
  const placeholderImage = UploadImage;
  const { t } = useTranslation();

  const [previewImage, setPreviewImage] = useState(
    defaultValue || placeholderImage
  );
  const [croppedImage, setCroppedImage] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [showCrop, setShowCrop] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  async function blobUrlToFile(ext) {
    const response = await fetch(croppedImage);
    const blobData = await response.blob();
    const filename = `${name}.${ext}`; // Set the desired filename
    const file = new File([blobData], filename, { type: `image/${ext}` });
    return file;
  }

  const handleChangeImage = async () => {
    const parts = selectedFile.type.split("/");
    const ext = parts[1];
    const file = await blobUrlToFile(ext);
    !!onInput && onInput({ target: { value: [file], name } });
  };

  const setDefaultImageAsSelectedFile = async () => {
    if (typeof defaultValue === "string") {
      const parts = defaultValue.split(".");
      const ext = parts[parts.length - 1];
      const file = await blobUrlToFile(ext);
      setSelectedFile(file);
      setPreviewImage(defaultValue); // Set preview image to default value
    }
  };

  const handleImageUpload = (e) => {
    const uploadedFile = e.target.files[0];

    if (uploadedFile) {
      if (
        (uploadedFile.type === "image/png" ||
          uploadedFile.type === "image/jpeg") &&
        uploadedFile.size <= 3 * 1024 * 1024 // 3MB
      ) {
        setSelectedFile(uploadedFile);
        setPreviewImage(URL.createObjectURL(uploadedFile)); // Update preview image
      } else {
        alert("Please upload a PNG or JPEG image with a maximum size of 3MB.");
      }
    }
  };

  const saveCroppedImage = useCallback(async () => {
    try {
      if (previewImage) {
        const croppedImage = await getCroppedImg(
          previewImage,
          croppedAreaPixels,
          rotation
        );
        setCroppedImage(croppedImage);
        setShowCrop(false);
      }
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation, previewImage]);

  useEffect(() => {
    if (!!croppedImage) {
      handleChangeImage();
    }
  }, [croppedImage]);

  useEffect(() => {
    if (!!defaultValue) {
      setDefaultImageAsSelectedFile();
    } else {
      setPreviewImage(placeholderImage);
    }
  }, [defaultValue]);

  return (
    <>
      <input
        hidden
        name={name}
        id={`upload-file-${name}`}
        type="file"
        accept="image/jpeg, image/png"
        onChange={handleImageUpload}
      />

      {!showCrop && (
        <div style={{ textAlign: "center" }}>
          <img
            src={croppedImage ? croppedImage : previewImage || placeholderImage}
            alt={name}
            onError={(e) => (e.target.src = placeholderImage)}
            style={{ width: 200, height: 200, borderRadius: "100%" }}
          />
          <div style={{ height: 15 }} />
          <Button size="sm" type="button" onClick={() => setShowCrop(true)}>
            {t("Buttons.Modify")} {t(name)}
          </Button>
        </div>
      )}

      {showCrop && (
        <div style={{ textAlign: "center" }}>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: 200,
              background: "#333",
            }}
          >
            <Cropper
              image={previewImage}
              crop={crop}
              zoom={zoom}
              rotation={rotation}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={showZoomControl && setZoom} // Handle zoom change
              onRotationChange={showRotationControl && setRotation}
            />
          </div>
          <br />
          {showZoomControl && ( // Render zoom control if enabled
            <div>
              <label>{t("ImageCrop.Zoom")}</label>
              <input
                type="range"
                min={1}
                max={3}
                step={0.1}
                value={zoom}
                onChange={(e) => setZoom(parseFloat(e.target.value))}
              />
            </div>
          )}
          <br />
          <div>
            <Button
              size="sm"
              variant="primary"
              type="button"
              onClick={() =>
                document.querySelector(`#upload-file-${name}`).click()
              }
            >
              {t("ImageCrop.Load other image")}
            </Button>
          </div>
          <br />
          <div className="d-flex justify-content-around">
            <Button
              size="sm"
              variant="secondary"
              type="button"
              onClick={() => setShowCrop(false)}
            >
              {t("Buttons.Cancel")}
            </Button>
            <Button
              size="sm"
              variant="primary"
              type="button"
              onClick={saveCroppedImage}
              disabled={!selectedFile}
            >
              {t("Buttons.Upload")}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageCropMaterialUi;
