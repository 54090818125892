import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  MenuItem,
  Select,
  Switch,
  TextareaAutosize,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import FetchIndexData from "../../utils/FetchIndexData";
import GetUserToken from "../../utils/GetUserToken";
import PhoneCallButton from "../PhoneCallButton.jsx";
import EmailButton from "../EmailButton.jsx";
import ProfileLink from "../ProfileLink.jsx";
import AlertComponent from "../alerts/Alert";

export const EditReminder = ({ id, handleeditClose }) => {
  // translations
  const { t } = useTranslation();
  // utils
  const [loading, setLoading] = useState(true);
  // data
  //const [data, setData] = useState([]);
  const [reminderid, setReminderid] = useState("");
  const [title, setTitle] = useState("");
  const [task, setTask] = useState("");
  const [time, setTime] = useState("");
  const [note, setNotes] = useState("");
  const [assignto, setDoctor] = useState("");
  const [doctors, setDoctors] = useState([]);
  const [done, setDone] = useState();
  const [priv, setPriv] = useState();
  const [patientData, setPatientData] = useState([
    {
      email: "",
      phone: "",
      patientID: "",
    },
  ]);

  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const fetchData = async () => {
    try {
      const json = await FetchIndexData("/reminders/show/" + id);
      const patientData = {
        email: json.patient_email,
        phone: json.patient_phone,
        patientID: json.patient_id,
      };

      //setData([]);
      setReminderid(json.id.toString());
      setTitle(json.title);
      setTask(json.task);
      setTime(json.time);
      setDoctor(json.assignto);
      setNotes(json.note);
      setDone(json.done);
      setPriv(json.private);
      setPatientData(patientData);
      setLoading(false);
    } catch (error) {
      // Handle error here
      console.error("Error fetching data:", error);
    }
  };

  const getDoctors = async () => {
    try {
      const requestOptions = {
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: GetUserToken(),
        },
      };

      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/citations/getDoctors`,
        requestOptions
      );

      if (!res.ok) {
        throw new Error("Failed to fetch doctors");
      }

      const json = await res.json();
      const filteredDoctors = json.clinicDoctors.filter(
        (item) => !item.archived
      );
      setDoctors(filteredDoctors);
    } catch (error) {
      // Handle error here
      console.error("Error fetching doctors:", error);
    }
  };

  const deleteReminder = async () => {
    try {
      setLoading(true);
      var url =
        process.env.REACT_APP_API_URL + "/reminders/destroy/" + reminderid;
      var requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: GetUserToken(),
        },
      };
      const res = await fetch(url, requestOptions);
      if (res.ok) {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
      handleeditClose();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDoctorChange = (e, value) => {
    var fieldValue = value;
    if (!fieldValue) {
      fieldValue = null;
    } else setDoctor(value.id);
  };

  // Post method
  const handleSubmit = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      const reminder = {
        title,
        task,
        time,
        note,
        assignto,
        done,
        private: priv,
      };
      var url = process.env.REACT_APP_API_URL + "/reminders/update/" + id;
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: GetUserToken(),
        },
        body: JSON.stringify(reminder),
      };

      const res = await fetch(url, requestOptions);
      if (res.ok) {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
      handleeditClose();
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    getDoctors();
  }, []);

  console.log(patientData);
  return (
    <>
      <div className="row">
        <div className="col">
          {loading ? (
            <div className="d-flex justify-content-center">
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <>
              <div className="form-group">
                <label className="title-inputs">{t("NewReminder.0")}</label>
                <input
                  required
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="input-control"
                />
              </div>

              <div className="form-group">
                <label className="title-inputs">{t("NewReminder.1")}</label>
                <input
                  required
                  type="text"
                  value={task}
                  onChange={(e) => setTask(e.target.value)}
                  className="input-control"
                />
              </div>

              <div className="form-group">
                <label className="title-inputs">{t("NewReminder.2")}</label>
                <input
                  required
                  type="datetime-local"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  className="input-control"
                />
              </div>

              <div className="form-group mt-1 row">
                <div className="col-10">
                  <label className="title-inputs">{t("NewReminder.3")}</label>
                  <br />
                  <Select
                    value={assignto}
                    onChange={(e) => setDoctor(e.target.value)}
                    style={{ width: "100%" }}
                  >
                    {doctors.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item["id"]}>
                          {item["name"]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
                <div className="col-2">
                  <label className="title-inputs">{t("NewReminder.4")}</label>
                  <br />
                  <Switch
                    checked={priv ? true : false}
                    onChange={() => setPriv(!priv)}
                    name="checkedA"
                    color="secondary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </div>
              </div>
              {patientData &&
                (patientData.phone ||
                  patientData.email ||
                  patientData.patientID) && (
                  <div
                    className="row"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "5px",
                      marginLeft: "0px",
                      marginRight: "0px",
                      border: "1px solid #adb5bd",
                    }}
                  >
                    {/* Phone section */}
                    {patientData.phone && (
                      <div className="col-4" style={{ textAlign: "center" }}>
                        <div>
                          <label className="title-inputs">
                            {t("Messages.Call Patient")}
                          </label>
                        </div>
                        <div>
                          <PhoneCallButton phoneNumber={patientData.phone} />
                          <br />
                          <label className="title-inputs">
                            {patientData.phone}
                          </label>
                        </div>
                      </div>
                    )}

                    {/* Email section */}
                    {patientData.email && (
                      <div className="col-4" style={{ textAlign: "center" }}>
                        <div>
                          <label className="title-inputs">
                            {t("Messages.Email Patient")}
                          </label>
                        </div>
                        <div>
                          <EmailButton emailAddress={patientData.email} />
                          <br />
                          <label className="title-inputs">
                            {patientData.email}
                          </label>
                        </div>
                      </div>
                    )}

                    {/* Patient ID section */}
                    {patientData.patientID && (
                      <div className="col-4" style={{ textAlign: "center" }}>
                        <div>
                          <label className="title-inputs">
                            {t("Messages.Patient Profile")}
                          </label>
                        </div>
                        <div>
                          <ProfileLink id={patientData.patientID} />
                          <br />
                          <label className="title-inputs">
                            #{patientData.patientID}
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                )}

              <div className="form-group mt-4">
                <label className="title-inputs">{t("NewReminder.5")}</label>
                <TextareaAutosize
                  type="text"
                  value={note}
                  onChange={(e) => setNotes(e.target.value)}
                  aria-label="minimum height"
                  minRows={3}
                  className="input-control"
                />
              </div>

              <div className="mt-2 text-center">
                <div className="row">
                  <div className="col-6">
                    <button
                      className="red-btn"
                      onClick={deleteReminder}
                      style={{ width: "100%" }}
                    >
                      <DeleteIcon />
                      &nbsp;
                      {t("Buttons.Delete")}
                    </button>
                  </div>

                  <div className="col-6">
                    <button
                      className="blue-btn"
                      onClick={handleSubmit}
                      style={{ width: "100%" }}
                    >
                      <SaveIcon />
                      &nbsp;
                      {t("NewReminder.6")}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {isAlert.open && (
        <AlertComponent
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
    </>
  );
};
