import {
  Lungenmeridian,
  Dickdarm,
  Magen,
  Milz,
  Herz,
  Dünndarm,
  Blase,
  Niere,
  Herzbeutel,
  Erwärmer,
  Gallenblase,
  Leber,
  DuMai,
  RenMai,
  Extrapunkte,
} from "./constants";

export const getType = (selected) => {
  let type = null;
  if (selected === "Lungenmeridian (Lu)") {
    type = Lungenmeridian;
  } else if (selected === "Dickdarm (Di)") {
    type = Dickdarm;
  } else if (selected === "Magen (Ma)") {
    type = Magen;
  } else if (selected === "Milz (Mi)") {
    type = Milz;
  } else if (selected === "Herz (He)") {
    type = Herz;
  } else if (selected === "Dünndarm") {
    type = Dünndarm;
  } else if (selected === "Blase (Bl)") {
    type = Blase;
  } else if (selected === "Niere (Ni)") {
    type = Niere;
  } else if (selected === "Herzbeutel (Hb)") {
    type = Herzbeutel;
  } else if (selected === "3facher Erwärmer (3E)") {
    type = Erwärmer;
  } else if (selected === "Gallenblase (Gb)") {
    type = Gallenblase;
  } else if (selected === "Leber (Le)") {
    type = Leber;
  } else if (selected === "Du Mai (Du)") {
    type = DuMai;
  } else if (selected === "Ren Mai (Ren)") {
    type = RenMai;
  } else if (selected === "Extrapunkte") {
    type = Extrapunkte;
  }
  return type;
};

export const getArrayType = (name) => {
  if (name.startsWith("Lu")) {
    return Lungenmeridian;
  } else if (name.startsWith("Di")) {
    return Dickdarm;
  } else if (name.startsWith("Ma")) {
    return Magen;
  } else if (name.startsWith("Mi")) {
    return Milz;
  } else if (name.startsWith("He")) {
    return Herz;
  } else if (name.startsWith("Dü")) {
    return Dünndarm;
  } else if (name.startsWith("Bl")) {
    return Blase;
  } else if (name.startsWith("Ni")) {
    return Niere;
  } else if (name.startsWith("Hb")) {
    return Herzbeutel;
  } else if (name.startsWith("3E")) {
    return Erwärmer;
  } else if (name.startsWith("Gb")) {
    return Gallenblase;
  } else if (name.startsWith("Le")) {
    return Leber;
  } else if (name.startsWith("Du")) {
    return DuMai;
  } else if (name.startsWith("Ren")) {
    return RenMai;
  } else {
    return Extrapunkte;
  }
};

export const getValue = (array, value) => {
  let newValue = array.filter(
    (element) =>
      element.title.replace(/\t/g, " ") === value ||
      element.nameEN === value ||
      element.nameES === value
  )[0].title;
  return newValue;
};

export const getValueArray = (array) => {
  let newArray = array.map((value) => {
    let array_type = getArrayType(value);
    let newValue = array_type.filter(
      (element) =>
        element.title.replace(/\t/g, " ") === value ||
        element.nameEN === value ||
        element.nameES === value
    )[0].title;
    return newValue;
  });
  return newArray;
};
