import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from "react-redux";
import { storeTWO } from './redux/store';
import FileUploadProgressProvider from './providers/FileUploader'
import './i18n';
import { Suspense } from "react"


ReactDOM.render(
    <Suspense fallback={(<div>Load...</div>)}>
        <Provider store={storeTWO}>
            <FileUploadProgressProvider>
                <App />
            </FileUploadProgressProvider>
        </Provider>
    </Suspense>,
    document.getElementById('root'));

