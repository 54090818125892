import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@material-ui/core";
import { Alert, Stack } from "@mui/material";
import GetUserToken from "../../utils/GetUserToken";
import InsurancePriceField from "../products/InsurancePriceField";

const EditProductOnboarding = ({
  productID,
  setIsAlert,
  handleStepComplete,
}) => {
  // translations
  const { t } = useTranslation();
  // data
  const [insurances, setInsurances] = useState([]);
  const [prices, setPrices] = useState([]);
  const [title, setName] = useState("");
  const [rate, setFee] = useState("");
  // utils
  const [saved, setSaved] = useState(true);
  const [loading, setLoading] = useState(true);
  // auth
  const userToken = GetUserToken();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);

      const url =
        process.env.REACT_APP_API_URL_V2 + "/products/show/" + productID;

      var requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        redirect: "follow",
      };

      const res = await fetch(url, requestOptions);
      const json = await res.json();
      const productInfo = json.product;
      setName(productInfo.title);
      setFee(productInfo.rate);
      setPrices(json.prices);
      await fetchInsurances();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchInsurances = async () => {
    setInsurances([]);
    try {
      const url = process.env.REACT_APP_API_URL + "/insurances/index/";

      var requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        redirect: "follow",
      };

      const res = await fetch(url, requestOptions);
      const json = await res.json();
      setInsurances(json.insurances);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const product = { title, rate };
    const url = `${process.env.REACT_APP_API_URL}/products/update/${productID}`;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
      body: JSON.stringify(product),
    };

    try {
      const response = await fetch(url, requestOptions);

      if (response.ok) {
        await handleStepComplete();
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setLoading(false);
    }
  };

  const getInsurancePrice = (insurance) => {
    let filtered = prices.filter((p) => p.insurance_id == insurance);
    if (filtered.length > 0) {
      let lastElementIndex = filtered.length - 1;
      let finalPrice = filtered[lastElementIndex]["price"];
      return finalPrice;
    } else {
      //
      return 0;
    }
  };

  const handlePriceChange = async (insurance, price) => {
    try {
      const url =
        process.env.REACT_APP_API_URL + "/products/change_price/" + productID;

      const raw = {
        product: productID,
        insurance: insurance,
        price: price,
      };

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        redirect: "follow",
        body: JSON.stringify(raw),
      };

      const response = await fetch(url, requestOptions);
      if (response.ok) {
        setSaved(true);
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
        fetchData();
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    }
  };

  return (
    <div style={{ padding: "40px" }}>
      <h4 className="main-title-treatments">{t("EditProduct.6")}</h4>
      {loading ? (
        <div className="d-flex justify-content-center">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <div className="row d-flex justify-content-center">
          <div className="col-sm-12 col-lg-5">
            <div className="row">
              <div className="form-group">
                <label htmlFor="name" className="title-treatments">
                  {t("EditProduct.0")}
                </label>
                <input
                  required
                  disabled={true}
                  type="text"
                  value={title}
                  onChange={(e) => setName(e.target.value)}
                  className="input-control"
                  style={{ color: "gray", fontStyle: "italic" }}
                />
                <Stack sx={{ mt: 1 }}>
                  <Alert
                    severity="warning"
                    style={{ alignItems: "center" }}
                    className="title-inputs"
                  >
                    {t(
                      "Messages.At the moment, you cannot change the name, if you wish to do so you must create a new entry"
                    )}
                  </Alert>
                </Stack>
              </div>

              <div className="form-group">
                <label htmlFor="duration" className="title-treatments">
                  {t("EditProduct.8")}
                </label>
                <input
                  required
                  type="text"
                  value={rate}
                  onChange={(e) => setFee(e.target.value)}
                  className="input-control"
                />
              </div>

              <div className="d-flex justify-content-center mt-3">
                <div>
                  <button
                    className="blue-btn"
                    id="edit_product"
                    onClick={handleSubmit}
                    style={{ width: "auto" }}
                  >
                    {t("EditProduct.3")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-lg-5">
            {insurances
              .sort((a, b) => a["id"] - b["id"])
              .map((item, i) => (
                <InsurancePriceField
                  key={i}
                  item={item}
                  getInsurancePrice={getInsurancePrice}
                  handlePriceChange={handlePriceChange}
                  setSaved={setSaved}
                  prices={prices}
                  productID={productID}
                  isEditTreatment={false}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default EditProductOnboarding;
