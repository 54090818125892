import { combineReducers } from "redux";

import company from "./company";
import bankAccount from "./bank-account";
import contactInformation from "./contact-information";
import user from "./user";
import herb from "./herb";
import prescription from "./prescription";
import pharmacy from "./pharmacy";
import blend from "./blend";
import diagnosis from "./diagnosis";
import doctor from "./doctor";
import patient from "./patient";
import citation from "./citation";
import treatment from "./treatment";
import insurance from "./insurance";

const rootReducer = combineReducers({
  bankAccountStore: bankAccount,
  blendStore: blend,
  citationStore: citation,
  companyStore: company,
  contactInformationStore: contactInformation,
  diagnosisStore: diagnosis,
  doctorStore: doctor,
  herbStore: herb,
  insuranceStore: insurance,
  patientStore: patient,
  pharmacyStore: pharmacy,
  prescriptionStore: prescription,
  treatmentStore: treatment,
  user,
});

export default rootReducer;
