import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import CircularProgress from "@material-ui/core/CircularProgress";
import GetUserToken from "../../utils/GetUserToken";

export const NewDiagnosis = ({
  setError,
  setSuccess,
  showmodal,
  setShowmodal,
  fetchData,
}) => {
  // translation
  const { t } = useTranslation();
  // auth
  const userToken = GetUserToken();

  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [saving, setSaving] = useState(false);

  const handleSubmit = async () => {
    setSaving(true);

    const body = {
      code: code,
      name: name,
    };

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      body: JSON.stringify(body),
    };

    await fetch(
      process.env.REACT_APP_API_URL + "/diagnosis/create",
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          setShowmodal(false);
          setSuccess(true);
          setSaving(false);
          fetchData();
          setName("");
          setCode("");
        } else {
          setError(true);
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      <Modal
        show={showmodal}
        onHide={() => setShowmodal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ background: "#F4F5FA" }}>
          <Modal.Title>{t("Diagnosis.New Diagnosis")}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#F4F5FA" }}>
          <div className="form-group">
            <label className="title-inputs">{t("Diagnosis.Code")}</label>
            <input
              required
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              className="input-control"
            />
          </div>
          <div className="form-group">
            <label className="title-inputs">{t("Diagnosis.Name")}</label>
            <TextareaAutosize
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              aria-label="minimum height"
              minRows={3}
              className="input-control"
            />
          </div>
          <div className="d-flex justify-content-center">
            {saving ? (
              <>
                <CircularProgress color="secondary" />
              </>
            ) : (
              <>
                {code != "" ? (
                  <>
                    <button className="blue-btn" onClick={handleSubmit}>
                      {t("Buttons.Save")}
                    </button>
                  </>
                ) : (
                  <>
                    <button disabled className="blue-btn-disabled">
                      {t("Buttons.Save")}
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewDiagnosis;
