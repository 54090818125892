import React, { useMemo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import Avatar from "@material-ui/core/Avatar";
import Table from "./PatientsTable";
import { ReactComponent as Pencil } from "../../assets/icons/pencil.svg";
import ButtonGroupComponent from "../reusableComponents/ButtonGroup";
import GetUserToken from "../../utils/GetUserToken";
import { TrackableLink } from "../../utils/helper";

function Patients() {
  // auth
  const userToken = GetUserToken();
  // translations
  const { t } = useTranslation();
  let currentLanguage = localStorage.getItem("i18nextLng");
  // data
  const [data, setData] = useState([]);
  // utils
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL + "/citations/getPatients",
      requestOptions
    );
    const json = await res.json();
    setData(json.clinicPatients);
    //console.table(json.clinicPatients)
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const not = (
    <div className="d-flex justify-content-center">
      <CircularProgress color="secondary" />
    </div>
  );

  const columns = useMemo(
    () => [
      {
        // first group
        Header: " ",
        // First group columns
        columns: [
          {
            Header: "ID",
            accessor: "id",
          },
          {
            Header: " ",
            Cell: (
              <>
                <Avatar className="patientList-avatar" />
              </>
            ),
          },
          {
            Header: <>{t("EditPatient.First Name")}</>,
            accessor: "firstname",
            Cell: ({ row }) => (
              <Link
                target="_blank"
                className="blue-anchors"
                to={{
                  pathname: "/patients-profile/" + `${row.original.id}`,
                  state: { data: row },
                }}
                id="view_patient_firstname"
                onClick={() => TrackableLink("view_patient_firstname")}
              >
                {" "}
                {row.original.firstname}
              </Link>
            ),
          },
          {
            Header: <>{t("EditPatient.Last Name")}</>,
            accessor: "lastname",
            Cell: ({ row }) => (
              <Link
                target="_blank"
                className="blue-anchors"
                to={{
                  pathname: "/patients-profile/" + `${row.original.id}`,
                  state: { data: row },
                }}
                id="view_patient_lastname"
                onClick={() => TrackableLink("view_patient_lastname")}
              >
                {" "}
                {row.original.lastname}
              </Link>
            ),
          },
          {
            Header: <>{t("EditPatient.Address")}</>,
            accessor: "address",
            Cell: ({ row }) => (
              <>
                {row.original.address
                  ? t(`Countries.${row.original.address}`)
                  : ""}
              </>
            ),
          },
          {
            Header: <>{t("EditPatient.Phone")}</>,
            accessor: "phone",
          },
          {
            Header: "Email",
            accessor: "email",
          },
          {
            Header: "  ",
            Cell: ({ row }) => (
              <Link
                target="_blank"
                to={{
                  pathname: "/patients-profile/" + `${row.original.id}`,
                  state: { data: row },
                }}
                id="edit_patient"
                onClick={() => TrackableLink("edit_patient")}
              >
                <Pencil className="icon" />
              </Link>
            ),
          },
        ],
      },
    ],
    [currentLanguage]
  );

  return (
    <>
      {/* Support */}
      <ButtonGroupComponent route="patients" />

      <div className="frame">
        <h4 className="main-title mt-2">{t("PatientList.Patient List")}</h4>
        <br />
        {loading ? (
          not
        ) : (
          <>
            <Table columns={columns} data={data} />
          </>
        )}
      </div>
    </>
  );
}

export default Patients;
