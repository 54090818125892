import React from "react";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

export const Questions = ({ inputList, setInputList, counter, setCounter }) => {
  // translations
  const { t } = useTranslation();

  const handleCounter = () => {
    setCounter(counter + 1);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    handleCounter();
    setInputList([...inputList, { id: counter, question: "" }]);
  };

  //console.log(inputList)

  return (
    <>
      {inputList.map((item, i) => {
        return (
          <>
            <div className="row">
              <div className="col form-group">
                <input
                  name="id"
                  value={i}
                  onChange={(e) => handleInputChange(e, i)}
                  style={{ display: "none" }}
                />

                <label className="title-inputs">
                  {t("EditQuiz.Questions")} *{i + 1}
                </label>
                <br />
                <br />

                <TextareaAutosize
                  type="text"
                  name="question"
                  value={item.question}
                  onChange={(e) => handleInputChange(e, i)}
                  className="input-control"
                  variant="outlined"
                  size="small"
                  style={{ width: "50%" }}
                  aria-label="minimum height"
                  minRows={2}
                  placeholder={t("EditQuiz.Write here")}
                />

                <>
                  {inputList.length !== 1 && (
                    <button
                      className="btn-cancel mt-1"
                      type="button"
                      onClick={() => handleRemoveClick(i)}
                    >
                      {t("EditQuiz.Remove input")}
                    </button>
                  )}
                </>
              </div>
            </div>

            <div className="row text-center" style={{ width: "92%" }}>
              <div className="col">
                {inputList.length - 1 === i && (
                  <div
                    className="blue-anchors"
                    type="button"
                    onClick={handleAddClick}
                  >
                    + {t("EditQuiz.Add question")}
                  </div>
                )}
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

export default Questions;
