import React from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export const PatientsBackdrop = ({ patientsload, citationsload }) => {
  const classes = useStyles();

  return (
    <>
      <Backdrop className={classes.backdrop} open={patientsload}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Backdrop className={classes.backdrop} open={citationsload}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default PatientsBackdrop;
