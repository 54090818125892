import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Backdrop, CircularProgress } from "@material-ui/core";
import "react-edit-text/dist/index.css";
import "../../assets/stylesheets/herbs.css";
import AlertComponent from "../alerts/Alert";
import NewHerbStep1 from "./newHerb/NewHerbStep1";
import NewHerbStep2 from "./newHerb/NewHerbStep2";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const NewHerb = (infoRoute) => {
  // styles
  const classes = useStyles();
  // utils
  const [idHerb, setIdHerb] = useState("");
  const [loading, setLoading] = useState(false);
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  return (
    <>
      <div className="frame" style={{ paddingBottom: "18px" }}>
        <NewHerbStep1
          idHerb={idHerb}
          setIdHerb={setIdHerb}
          setLoading={setLoading}
          setIsAlert={setIsAlert}
        />
        <NewHerbStep2
          idHerb={idHerb}
          setLoading={setLoading}
          setIsAlert={setIsAlert}
          infoRoute={infoRoute}
        />
      </div>

      {isAlert.open && (
        <AlertComponent
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default NewHerb;
