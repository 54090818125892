import styled from 'styled-components'
import Dropdown from 'react-bootstrap/Dropdown';

export const Icon = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: rotate .2s ease-in-out;

  svg {
    width: 15px;
    height: 15px;
  }
`

export const DropdownComponent = styled(Dropdown)`
 &.show{
    ${Icon} {
      transform: rotate(90deg);
    }
  }
`

export const DropdownToggle = styled(Dropdown.Toggle)`
  padding: 0;
  height: 27px;
  width: 100%;
  border-radius: 0;
  border: 0;
  background: white;
  cursor: pointer;

  &:after {
    display: none;
  }
`

export const DropdownMenu = styled(Dropdown.Menu)`
  padding: 0px;
  margin-top: 1px!important;
  margin-left: 1px!important;
  box-shadow: 1px 2px 2px 0px rgba(0,0,0,.2);
`

export const DropdownItem = styled(Dropdown.Item)`
  font-size: 14px;
  padding: 5px 10px;
  cursor: pointer;
`

