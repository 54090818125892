import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

const InsurancePriceField = ({
  item,
  getInsurancePrice,
  handlePriceChange,
  setSaved,
  prices,
  productID,
  isEditTreatment,
}) => {
  // translations
  const { t } = useTranslation();
  // data
  const ID = parseInt(productID);
  const [price, setPrice] = useState(getInsurancePrice(item.id));

  const handleChange = (e) => {
    setPrice(e.target.value);
    setSaved(false);
  };

  const modified = [...prices];
  const arrayFiltered = [];

  modified.forEach((obj) => {
    const item = arrayFiltered.find(
      (thisItem) => thisItem.insurance_id === obj.insurance_id
    );
    if (item) {
      if (item.id < obj.id) {
        item.id = obj.id;
      }

      return;
    }

    arrayFiltered.push(obj);
  });

  return (
    <>
      <label className="title-inputs">
        {item.title}
        (&euro;)
      </label>
      {isEditTreatment &&
        arrayFiltered.map((x, i) => (
          <>
            {x.insurance_id === item.id && x.product_id !== ID ? (
              <>
                <Tooltip title={t("Messages.Original price")}>
                  <IconButton aria-label="info">
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <></>
            )}
            {x.insurance_id === item.id && x.product_id === ID ? (
              <>
                <Tooltip title={t("Messages.Modified price")}>
                  <IconButton aria-label="info">
                    <InfoIcon style={{ color: "red" }} />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <></>
            )}
          </>
        ))}
      <input
        required
        type="number"
        min="0"
        value={Number(Number(price).toFixed(2))}
        onChange={handleChange}
        onBlur={(e) => handlePriceChange(item.id, e.target.value)}
        className="input-control"
      />
    </>
  );
};

export default InsurancePriceField;
