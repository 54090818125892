import React from "react";
import IconButton from "@material-ui/core/IconButton";
import PhoneIcon from "@material-ui/icons/Phone";

const PhoneCallButton = ({ phoneNumber }) => {
  const handleCallClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <IconButton
      onClick={handleCallClick}
      aria-label="Call"
      style={{ color: "var(--primary-color)" }}
    >
      <PhoneIcon />
    </IconButton>
  );
};

export default PhoneCallButton;
