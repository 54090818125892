import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import useStyles from "./styles";
import DescriptionText from "../DescriptionText";

const TreatmentSelect = ({
  treatment,
  handleTreatmentChange,
  isLoadingTreatments,
  clinicTreatments,
}) => {
  // translations
  const { t } = useTranslation();
  // styles
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.inputLabel}>
        {t("NeueTermin.12")}
      </Typography>
      <ul className={classes.list}>
        {isLoadingTreatments ? (
          <Typography className={classes.loading}>
            {t("FirstStep.loading")}...
          </Typography>
        ) : clinicTreatments.length !== 0 ? (
          clinicTreatments.map((item) => (
            <li
              key={item.id}
              className={`${classes.listItem} ${
                treatment === item.id ? "selected" : ""
              }`}
              onClick={() =>
                handleTreatmentChange({ target: { value: item.id } })
              }
            >
              <div className={classes.treatmentInfo}>
                <Typography className={classes.treatmentName}>
                  {item.name}
                </Typography>
                <Typography variant="body2">
                  {t("FirstStep.Duration")}: {item.duration}
                </Typography>
                <Typography variant="body2">
                  <DescriptionText
                    text={item.treatment_description}
                    classes={classes}
                  />
                </Typography>
              </div>
            </li>
          ))
        ) : (
          <Typography className={classes.loading}>
            {t("FirstStep.No treatments are available.")}
          </Typography>
        )}
      </ul>
    </div>
  );
};

export default TreatmentSelect;
