import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import HelpPopup from "../HelpPopup";
import { getContent } from "./helper";
import fetchApi from "../../../utils/fetchApi";

const HelpButton = ({ route }) => {
  // redux
  const state = useSelector((state) => state.user);
  const userId = state.auth?.user_id;
  // utils
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFirstShow, setIsFirstShow] = useState(false);
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [routesFirstShow, setRoutesFirstShow] = useState([]);
  // data
  const [filteredContent, setFilteredContent] = useState([]);

  const fetchUserData = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL_V2}/users/show/${userId}`;
      const { data } = await fetchApi(url, "GET");

      if (data.first_show) {
        const firstShow = JSON.parse(data.first_show);
        const isFirstShow = firstShow.some((item) => item === route);
        setIsFirstShow(isFirstShow);
        setRoutesFirstShow(firstShow);
      }

      if (data.onboarding) {
        const onboarding = JSON.parse(data.onboarding);
        const isFirstLogin = onboarding.find(
          (item) => "is_first_login" in item
        )?.is_first_login;
        setIsFirstLogin(isFirstLogin);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserData();
    }
  }, [userId]);

  useEffect(() => {
    if (isFirstShow && !isFirstLogin) {
      handleClickOpen({ currentTarget: null });
    }
  }, [isFirstShow, isFirstLogin]);

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
    const matchedContent = getContent(route);
    setFilteredContent(matchedContent);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
    setCurrentIndex(0);
    if (isFirstShow) {
      removeRouteFromFirstShow(route);
    }
  };

  const removeRouteFromFirstShow = async (routeToRemove) => {
    const updatedFirstShow = routesFirstShow.filter(
      (item) => item !== routeToRemove
    );

    const body = {
      first_show: JSON.stringify(updatedFirstShow),
    };
    const url = `${process.env.REACT_APP_API_URL_V2}/users/update/${userId}`;

    try {
      const { ok } = await fetchApi(url, "PUT", JSON.stringify(body));
      if (ok) {
        setIsFirstShow(false);
        setRoutesFirstShow(updatedFirstShow);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Button className="help-button" onClick={handleClickOpen}>
        <InfoOutlinedIcon fontSize="large" />
      </Button>
      <HelpPopup
        anchorEl={anchorEl}
        open={open}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        handleClose={handleClose}
        content={filteredContent}
      />
    </>
  );
};

export default HelpButton;
