import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Pencil from "../../assets/icons/blue_pencil.png";
import { useBeforeunload } from "react-beforeunload";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Accordion, Card, Button } from "react-bootstrap";
import edit_clock from "../../assets/icons/edit_clock.png";
import GetUserToken from "../../utils/GetUserToken";
import { Counter } from "../citations/completeappointment/Counter";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const Quizz = ({ id }) => {
  const userToken = GetUserToken();

  const { t } = useTranslation();

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(20);
  const [save, setSave] = useState(false);
  const [hasQuiz, sethasQuiz] = useState(null);
  const [questionsArray, setQuestionsArray] = useState([]);
  const [answersArray, setAnswersArray] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [updateDate, setUpdateDate] = useState("");

  const fetchData = async () => {
    setLoading(true);

    const url = process.env.REACT_APP_API_URL + `/show/` + id;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    const res = await fetch(url, requestOptions);

    const json = await res.json();
    let quizID = json.hasquiz;
    if (quizID !== null) {
      sethasQuiz(quizID);
    }

    setLoading(false);
  };

  const fetchDataQuiz = async () => {
    setLoading(true);

    const url = process.env.REACT_APP_API_URL + `/quiz/show/` + hasQuiz;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    const res = await fetch(url, requestOptions);

    const json = await res.json();

    let questions = json.questions;
    if (questions) {
      setQuestionsArray(JSON.parse(questions));
    }

    let answers = json.answer_patient;
    if (answers) {
      setAnswersArray(JSON.parse(answers));
    }

    let array = json.answer_doctor;
    if (array !== "") {
      setAnswers(JSON.parse(array));
    }

    setUpdateDate(json.updated_at);

    setLoading(false);
  };

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    const list = [...answers];
    list[index] = value;
    setAnswers(list);
  };

  useBeforeunload((event) => {
    if (save !== false) {
      event.preventDefault();
    }
  });

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const body = {
      answer_doctor: JSON.stringify(answers),
    };

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      body: JSON.stringify(body),
    };

    await fetch(
      process.env.REACT_APP_API_URL + "/quiz/update_doctor/" + hasQuiz,
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          setSave(false);
          setLoading(false);
          setSuccess(true);
          fetchData();
          fetchDataQuiz();
        } else {
          alert(t("Messages.Something went wrong, please try again"));
          setLoading(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    fetchData();
    fetchDataQuiz();
  }, [hasQuiz]);

  //console.log(questionsArray)

  return (
    <>
      {questionsArray.length ? (
        <Accordion style={{ paddingRight: "0", paddingLeft: "0" }}>
          <Card
            className="reminders-cards"
            style={{ width: "93%", borderRadius: "10px" }}
          >
            <Card.Header
              className="reminders-subtitle"
              style={{
                border: "none",
                background: "white",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="0"
                  className="text-center subtitles-patients"
                  style={{ textDecoration: "none" }}
                >
                  <span>&#8595; &nbsp;</span>
                  {t("Titles.Quiz")} &nbsp;
                  <span className="subtitles-patients border-start">
                    <img src={edit_clock} />
                    {loading ? (
                      <></>
                    ) : (
                      <>
                        <span className="key-text-box">
                          {updateDate != "" ? (
                            <>{updateDate.replace("T", " at: ").slice(0, -8)}</>
                          ) : (
                            "Open to see more"
                          )}
                        </span>
                      </>
                    )}
                  </span>
                </Accordion.Toggle>
              </div>
              <div>
                {save == false ? (
                  <></>
                ) : (
                  <button
                    className="button-citation-box-2-save"
                    onClick={handleSubmit}
                    style={{ padding: "6px" }}
                  >
                    {t("Buttons.Save")}
                  </button>
                )}
              </div>
            </Card.Header>

            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <div className="row">
                  <div className="col-10"></div>
                  <div className="col-2 text-end">
                    {/*                     {save == false ? (
                      <>
                        <button style={{ border: "none", background: "none" }}>
                          <span className="key-text-box-edit appointment-status-saved">
                            &nbsp;&nbsp;Saved&nbsp;&nbsp;
                          </span>
                        </button>
                      </>
                    ) : (
                      <>
                        <div className="unsaved-div">
                          <button
                            onClick={handleSubmit}
                            title="please save your changes"
                            style={{
                              border: "none",
                              background: "none",
                              padding: "0",
                            }}
                          >
                            <span className="key-text-box-edit appointment-status-unsaved">
                              &nbsp;&nbsp;Click to save changes&nbsp;&nbsp;
                            </span>
                          </button>
                        </div>
                      </>
                    )} */}
                  </div>
                </div>
                <form
                  className="row"
                  style={{
                    background: "#f4f5fa",
                    borderRadius: "10px",
                    padding: "10px 240px",
                  }}
                >
                  {loading ? (
                    <div className="d-flex justify-content-center">
                      <CircularProgress color="secondary" />
                    </div>
                  ) : (
                    <>
                      {questionsArray.map((item, i) => {
                        return (
                          <>
                            <div className="col-lg-6 col-sm-12">
                              <Card key={i}>
                                <Card.Header
                                  className="reminders-subtitle"
                                  style={{ background: "#f4f5fa" }}
                                >
                                  {" "}
                                  {i + 1}. {item.question}{" "}
                                </Card.Header>
                                <Card.Body style={{ background: "#f5f4f7" }}>
                                  <Card.Text>
                                    <p
                                      className="key-text-box"
                                      style={{ color: "#564c60" }}
                                    >
                                      {answersArray.length ? (
                                        answersArray[i]
                                      ) : (
                                        <>
                                          {t(
                                            "Messages.The patient did not respond yet"
                                          )}
                                        </>
                                      )}
                                    </p>

                                    <hr />

                                    <TextareaAutosize
                                      type="text"
                                      name="answer"
                                      value={answers[i]}
                                      onChange={(e) => {
                                        handleInputChange(e, i);
                                        setSave(true);
                                      }}
                                      className="input-control"
                                      variant="outlined"
                                      size="small"
                                      aria-label="minimum height"
                                      minRows={3}
                                      placeholder={t(
                                        "Messages.Therapist Answer"
                                      )}
                                      style={{ height: "100px" }}
                                    />
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                              <br />
                            </div>
                          </>
                        );
                      })}
                    </>
                  )}
                </form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      ) : (
        <></>
      )}

      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(!success)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSuccess(!success)}
          severity="success"
        >
          {t("Messages.The operation was successful")}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default Quizz;
