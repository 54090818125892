import { Calendar, momentLocalizer } from 'react-big-calendar'
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import moment from 'moment'


const localizer = momentLocalizer(moment)

export default function CalendarPatients(deleteToken, userId) {
    const state = useSelector(state => state.user);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const res = await fetch(
                process.env.REACT_APP_API_URL + '/patients/getPatientCitationsById/' + userId,
            );
            const json = await res.json();
            setData(json.citations);
            setLoading(false);
        };
        fetchData();
    }, [setData]);


    return (<>

        <div className="frame">
            <Calendar
                popup
                localizer={localizer}
                defaultDate={new Date()}
                defaultView="week"
                events={data}
                style={{ height: "100vh", width: "100%", background: 'white' }}
                //onSelectEvent={handleOpen}
                //eventPropGetter={eventStyleGetter}
                min={new Date(0, 0, 0, 7, 0, 0)}
                max={new Date(0, 0, 0, 19, 0, 0)}
            />
        </div>

    </>)

}