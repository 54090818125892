import GetUserToken from "../utils/GetUserToken";

const StorageActionsEnum = {
  READ: 'READ',
  WRITE: 'WRITE'
}

export const EntityEnum = {
  DOCTOR: 'doctor',
  PATIENT: 'patient',
  COMPANY: 'company'
};

// xhrInstances is an array with XMLHttpRequest instances for tracking ongoing requests
let xhrInstances = [];

/**
 * Function to get a signed URL for file upload or download.
 * @param {Object} params - The parameters for getting the signed URL.
 * @param {string} params.fileName - The name of the file.
 * @param {string} [params.fileType] - The type of the file (optional).
 * @param {StorageActionsEnum} params.action - The action to be performed (e.g., WRITE or READ).
 * @returns {Promise<Object>} - The response or error from the request.
 */
const getSignedUrl = async ({ fileType, fileName, filePath, action }) => {
  const auth = `${GetUserToken()}`;
  const url = `${process.env.REACT_APP_API_URL_V2}/google_cloud_storage/get_signed_url?filePath=${filePath}&fileName=${fileName}&fileType=${fileType}&gcsAction=${action}`;

  try {
    const fetchResponse = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: auth,
        "api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json"
      },
    });

    // Verificar si la respuesta es exitosa (status code 2xx)
    if (fetchResponse.ok) {
      // Si la respuesta es exitosa, analizar el cuerpo de la respuesta como JSON
      const response = await fetchResponse.json();
      return { response, error: null };
    } else {
      // Si la respuesta no es exitosa, lanzar un error con el mensaje de estado
      const error = await fetchResponse.text();
      throw new Error(`Fetch error: ${fetchResponse.status} - ${error}`);
    }
  } catch (error) {
    // Capturar errores de análisis JSON o errores de red
    console.error("Error fetching data:", error);
    return { response: null, error };
  }
};

/**
 * Function to upload a file with progress tracking.
 * @param {Object} params - The parameters for uploading the file.
 * @param {string} params.url - The URL to upload the file to.
 * @param {File} params.file - The file to be uploaded.
 * @param {Function} params.onUploadProgress - Callback function to handle upload progress.
 * @param {Function} params.onAbort - Callback function to handle upload abort.
 * @returns {Promise<Object>} - The response or error from the upload.
 */
const storePut = async ({ url, file, onUploadProgress, onAbort }) => {
  const uploadFileWithProgress = async ({ url, file, onUploadProgress, onAbort }) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100;
          onUploadProgress(progress);
        }
      });

      xhr.addEventListener('load', () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          // Successful upload
          resolve(xhr.response);
        } else {
          // Upload error
          reject(new Error(`Failed to upload file. Status: ${xhr.status}`));
        }
      });

      xhr.addEventListener('error', () => {
        reject(new Error('Network error during file upload.'));
      });

      xhr.addEventListener('abort', () => {
        reject(onAbort());
      });

      console.log(file.type)

      xhr.open('PUT', url, true);
      xhr.setRequestHeader('Content-Type', file.type);
      xhr.send(file);

      xhrInstances.push(xhr);
    });
  };

  return uploadFileWithProgress({ url, file, onUploadProgress, onAbort })
    .then((response) => {
      return { response };
    })
    .catch((error) => {
      return { error };
    });
};

/**
 * Function to abort all ongoing file uploads.
 */
export const abortUpload = () => {
  if (xhrInstances.length > 0) {
    for (let index = 0; index < xhrInstances.length; index++) {
      const xhrInstance = xhrInstances[index];
      xhrInstance.abort();
      console.log('XHR request aborted.');
    }
  } else {
    console.warn('No active XHR request to abort.');
  }
};

/**
 * Function to generate the path for files based on the entity type.
 * @param {Object} params - The parameters for generating the path.
 * @param {string} params.entity - Enum between doctor | patient | company.
 * @param {string} params.idCompany - The company id.
 * @param {string} [params.idDoctor] - The doctor id (required if entity is doctor).
 * @param {string} [params.idPatient] - The patient id (required if entity is patient).
 * @param {string} params.fileName - The file name.
 * @param {string} params.fileExt - The file extension.
 * @returns {string} - The path for the selected entity.
 */

export const getFilePath = ({ entity, fieldName, idCompany, idDoctor, idPatient }) => {
  if (!idCompany) {
    console.error("idCompany is required");
    return;
  }

  switch (entity) {
    case 'doctor':
      if (!idDoctor) {
        console.error("idDoctor is required for doctor entity");
        return;
      }
      return `company/${idCompany}/doctor/${idDoctor}/${fieldName}/`;
    case 'patient':
      if (!idPatient) {
        console.error("idPatient is required for patient entity");
        return;
      }
      return `company/${idCompany}/patient/${idPatient}/${fieldName}/`;
    case 'company':
      return `company/${idCompany}/${fieldName}/`;
    default:
      console.error("Invalid entity type");
      return
  }
};


class Storage {
  /**
   * Function to save a file with progress tracking.
   * @param {Object} params - The parameters for saving the file.
   * @param {File} params.file - The file to be saved.
   * @param {string} params.fileName - The name to save the file as.
   * @param {Function} params.onUploadProgress - Callback function for upload progress.
   * @param {Function} [params.onAbort] - Callback function for upload abort (optional).
   * @returns {Promise<Object>} - The response or error from saving the file.
   */
  async saveFile({ file, filePath, fileName, fileType, onUploadProgress, onAbort }) {
    if (!file || !filePath || !fileName || !fileType) {
      console.error("One or more properties to save the file are empty", { file, filePath, fileName, fileType })
      return
    }

    const { response, error } = await getSignedUrl({
      filePath,
      fileName,
      fileType,
      action: StorageActionsEnum.WRITE,
    });

    if (!!response) {
      const { url } = response;
      return storePut({ url, file, onUploadProgress, onAbort });
    } else {
      return { error };
    }
  }

  /**
   * Function to get a file URL for download.
   * @param {Object} params - The parameters for getting the file URL.
   * @param {string} params.fileName - The name of the file to be retrieved.
   * @returns {Promise<Object>} - The URL to the file or an error.
   */
  async getFile({ filePath, fileName }) {
    const { response, error } = await getSignedUrl({
      filePath,
      fileName,
      action: StorageActionsEnum.READ,
    });

    if (!!response) {
      const { url } = response;
      return url;
    } else {
      return { error };
    }
  }
}

const storage = new Storage();
export default storage;
