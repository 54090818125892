import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import services from "../../services";

// Define initial state
const initialState = {
  one: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  all: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  create: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  update: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
};

// Async action to get one company
const getOneCompany = createAsyncThunk("company/getOne", async (payload) => {
  console.log("get one - company - action", payload);
  const { response, error } = await services.company.getOne(payload);
  if (error) {
    throw new Error(error);
  }
  return response;
});

const getOneCompanyBySlug = createAsyncThunk(
  "company/getBySlug",
  async (payload) => {
    console.log("get one company by slug - company - action", payload);
    const { response, error } = await services.company.getOneBySlug(payload);
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Async action to get all companies
const getAllCompanies = createAsyncThunk("company/getAll", async (payload) => {
  console.log("get all - company - action", payload);
  const { response, error } = await services.company.getAll(payload);
  if (error) {
    throw new Error(error);
  }
  return response;
});

// Async action to create one company
const createOneCompany = createAsyncThunk(
  "company/createOne",
  async ({ payload }) => {
    console.log("create one - company - action", payload);
    const { response, error } = await services.company.createOne(payload);
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Async action to update a company
const updateCompany = createAsyncThunk("company/update", async (payload) => {
  console.log("update - company - action", payload);
  const { response, error } = await services.company.update(payload);
  if (error) {
    throw new Error(error);
  }
  return response;
});

// Create a company slice
const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOneCompanyBySlug.pending, (state, action) => {
        state.one.data = null;
        state.one.loading = true;
        state.one.success = false;
        state.one.error = false;
      })
      .addCase(getOneCompanyBySlug.fulfilled, (state, action) => {
        state.one.data = action.payload;
        state.one.loading = false;
        state.one.success = true;
        state.one.error = false;
      })
      .addCase(getOneCompanyBySlug.rejected, (state, action) => {
        state.one.data = null;
        state.one.loading = false;
        state.one.success = false;
        state.one.error = action.error.message;
      })
      .addCase(getOneCompany.pending, (state, action) => {
        state.one.data = null;
        state.one.loading = true;
        state.one.success = false;
        state.one.error = false;
      })
      .addCase(getOneCompany.fulfilled, (state, action) => {
        state.one.data = action.payload;
        state.one.loading = false;
        state.one.success = true;
        state.one.error = false;
      })
      .addCase(getOneCompany.rejected, (state, action) => {
        state.one.data = null;
        state.one.loading = false;
        state.one.success = false;
        state.one.error = action.error.message;
      })
      .addCase(getAllCompanies.pending, (state) => {
        state.all.data = null;
        state.all.loading = true;
        state.all.success = false;
        state.all.error = false;
      })
      .addCase(getAllCompanies.fulfilled, (state, action) => {
        state.all.data = action.payload;
        state.all.loading = false;
        state.all.success = true;
        state.all.error = false;
      })
      .addCase(getAllCompanies.rejected, (state, action) => {
        state.all.data = null;
        state.all.loading = false;
        state.all.success = false;
        state.all.error = action.error.message;
      })
      .addCase(updateCompany.pending, (state) => {
        state.update.data = null;
        state.update.loading = true;
        state.update.success = false;
        state.update.error = false;
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.one.data = action.payload;
        state.update.data = action.payload;
        state.update.loading = false;
        state.update.success = true;
        state.update.error = false;
      })
      .addCase(updateCompany.rejected, (state, action) => {
        state.update.data = null;
        state.update.loading = false;
        state.update.success = false;
        state.update.error = action.error.message;
      });
  },
});

// Export the async actions and the reducer
export {
  getOneCompany,
  getOneCompanyBySlug,
  getAllCompanies,
  createOneCompany,
  updateCompany,
};

export default companySlice.reducer;