import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ReactComponent as Pencil } from "../../assets/icons/pencil.svg";
import quizz from "../../assets/icons/quizzIMG.png";
import EditQuiz from "./EditQuiz";
import GetUserToken from "../../utils/GetUserToken";
import AlertComponent from "../alerts/Alert";
import Table from "./QuizTable";

export const QuizList = () => {
  // auth
  const userToken = GetUserToken();
  // translations
  const { t } = useTranslation();
  let currentLanguage = localStorage.getItem("i18nextLng");
  // utils
  const [loading, setLoading] = useState(true);
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  // data
  const [data, setData] = useState([]);
  const [quizID, setQuizID] = useState("");

  const fetchData = async () => {
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    const url = process.env.REACT_APP_API_URL + "/quiztemplates/index";

    const res = await fetch(url, requestOptions);
    const json = await res.json();
    console.log(json);
    setData(json.quiztemplate);
    setLoading(false);
  };

  const [editshow, seteditShow] = useState(false);
  const handleeditClose = () => {
    seteditShow(false);
    fetchData();
  };
  const handleeditShow = () => seteditShow(true);

  const editQuizz = (id) => {
    handleeditShow();
    setQuizID(id);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = useMemo(
    () => [
      {
        // first group
        Header: " ",
        // First group columns
        columns: [
          {
            Header: <>{t("QuizList.Name")}</>,
            accessor: "name",
          },
          {
            Header: <>{t("QuizList.Date")}</>,
            accessor: "updated_at",
            Cell: ({ row }) => <>{row.original.updated_at.slice(0, 10)}</>,
          },
          {
            Header: <>{t("QuizList.Treatments")}</>,
            accessor: "treatments",
          },
          {
            Header: " ",
            accessor: " ",
            Cell: ({ row }) => (
              <div style={{ cursor: "pointer", marginRight: "10px" }}>
                <Pencil
                  className="icon"
                  onClick={() => editQuizz(row.original.id)}
                />
              </div>
            ),
          },
        ],
      },
    ],
    [currentLanguage]
  );

  const quizTable = (
    <>
      {data.length ? (
        <Table columns={columns} data={data} />
      ) : (
        <div className="d-flex justify-content-center">
          <div style={{ display: "block", textAlign: "center" }}>
            <img src={quizz} alt="quizz" />
            <br />
            <h4 className="home-reminders-title">
              {t("QuizList.There are no quizzes yet")}
            </h4>
            <br />
            <h5 className="key-text-box">
              {t("QuizList.If you want to create one,")}
              <br />
              {t("QuizList.just click NEW QUIZ button and go ahead.")}
            </h5>
            <br />
            <Link to={`/create-quiz/`} className="blue-btn">
              {t("QuizList.New Quiz")}
            </Link>
          </div>
        </div>
      )}
    </>
  );

  const load = (
    <>
      <div className="d-flex justify-content-center">
        <CircularProgress color="secondary" />
      </div>
    </>
  );

  return (
    <>
      <div className="frame">
        <h4 className="main-title mt-2">{t("Settings.Quizes")}</h4>
        <br />
        <div style={{ marginRight: "50px" }}>
          <div className="container-fluid treatments-table">
            {loading ? load : quizTable}
          </div>
        </div>
      </div>

      <Modal
        show={editshow}
        onHide={handleeditClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ background: "#F4F5FA" }}>
          <Modal.Title>
            {t("QuizList.Edit Quiz")} # {quizID}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#F4F5FA" }}>
          <EditQuiz
            id={quizID}
            handleeditClose={handleeditClose}
            setIsAlert={setIsAlert}
          />
        </Modal.Body>
      </Modal>

      {isAlert.open && (
        <AlertComponent
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
    </>
  );
};
export default QuizList;
