import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Checkbox,
  CircularProgress,
  Stack,
  TextField,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Autocomplete } from "@material-ui/lab";
import GetUserToken from "../../utils/GetUserToken";
import InsuranceField from "./InsuranceField";
import AlertComponent from "../alerts/Alert";
import ConfirmDeletionModal from "./ConfirmDeletionModal";

const EditAdittional = (infoRoute) => {
  const { t } = useTranslation();
  // route
  const history = infoRoute.history;
  // data
  const [insurances, setInsurances] = useState([]);
  const [prices, setPrices] = useState([]);
  const [title, setName] = useState("");
  const [rate, setRate] = useState("");
  const [clinicproduct, setClinicproduct] = useState([]);
  const [products, setProducts] = useState("");
  // utils
  const [archived, setArchived] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const [isPriceUpdating, setIsPriceUpdating] = useState({
    insurance: undefined,
    isUpdating: false,
  });
  const [resetDialog, setResetDialog] = useState(false);
  // auth
  const userToken = GetUserToken();
  // icons
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      console.log("Fetching adittional...");
      setLoading(true);

      const url =
        process.env.REACT_APP_API_URL_V2 +
        "/bundles/show_of_bundle/" +
        infoRoute.match.params.id;

      var requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        redirect: "follow",
      };

      const res = await fetch(url, requestOptions);
      const json = await res.json();
      const { prices, product } = json;

      setName(product.title);
      setRate(product.rate);
      setArchived(product.archived);
      setProducts(JSON.parse(product.adittional_products));
      setPrices(prices);

      await getProducts();
      await getInsurances();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = async (insurance) => {
    setIsPriceUpdating({
      insurance: insurance,
      isUpdating: true,
    });
    try {
      const url =
        process.env.REACT_APP_API_URL_V2 +
        "/bundles/show_of_bundle/" +
        infoRoute.match.params.id;

      var requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        redirect: "follow",
      };

      const res = await fetch(url, requestOptions);
      const json = await res.json();
      const { prices, product } = json;

      setPrices(prices);
      setIsPriceUpdating({
        insurance: undefined,
        isUpdating: false,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const getProducts = async () => {
    try {
      const url =
        process.env.REACT_APP_API_URL_V2 + "/treatments/get_base_products";

      var requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        redirect: "follow",
      };

      const res = await fetch(url, requestOptions);
      const json = await res.json();
      const filteredProducts = json.filter((item) => {
        return item.archived === false;
      });
      setClinicproduct(filteredProducts);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getInsurances = async () => {
    try {
      const url = process.env.REACT_APP_API_URL + "/insurances/index/";

      var requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        redirect: "follow",
      };

      const res = await fetch(url, requestOptions);
      const json = await res.json();
      setInsurances(json.insurances);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePriceChange = async (insurance, price) => {
    setIsPriceUpdating({
      insurance: insurance,
      isUpdating: true,
    });
    try {
      const url =
        process.env.REACT_APP_API_URL +
        "/products/change_price/" +
        infoRoute.match.params.id;

      const raw = {
        product: infoRoute.match.params.id,
        insurance: insurance,
        price: price,
      };

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        redirect: "follow",
        body: JSON.stringify(raw),
      };

      const response = await fetch(url, requestOptions);

      if (response.ok) {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
        // fetchData();
        refreshData(insurance);
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    }
  };

  const handleArchive = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const archive = { archived: !archived };

      const url =
        process.env.REACT_APP_API_URL +
        "/products/update/" +
        infoRoute.match.params.id;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        redirect: "follow",
        body: JSON.stringify(archive),
      };

      const response = await fetch(url, requestOptions);
      if (response.ok) {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
        setTimeout(() => {
          history.push("/adittionals");
        }, 1000);
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleResetPrices = async () => {
    console.log("Reseting prices...");
    setResetDialog(false);
    setLoading(true);
    try {
      const url =
        process.env.REACT_APP_API_URL_V2 +
        "/bundles/reset_bundle_prices/" +
        infoRoute.match.params.id;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        redirect: "follow",
      };

      const response = await fetch(url, requestOptions);

      if (response.ok) {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
        fetchData();
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.Something went wrong, please try again"),
        });
      }
    } catch (e) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (products.length !== 0) {
      setLoading(true);
      try {
        const bundle = {
          title,
          rate,
          adittional_products: JSON.stringify(products),
        };

        const url =
          process.env.REACT_APP_API_URL_V2 +
          "/bundles/update/" +
          infoRoute.match.params.id;

        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "api-key": process.env.REACT_APP_API_KEY,
            Authorization: userToken,
          },
          redirect: "follow",
          body: JSON.stringify(bundle),
        };

        const response = await fetch(url, requestOptions);
        if (response.ok) {
          setIsAlert({
            open: true,
            severity: "success",
            message: t("Messages.The operation was successful"),
          });
          setTimeout(() => {
            history.push("/adittionals");
          }, 1000);
        } else {
          setIsAlert({
            open: true,
            severity: "error",
            message: t("Messages.The operation could not be processed"),
          });
        }
      } catch (error) {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.Something went wrong, please try again"),
        });
      } finally {
        setLoading(false);
      }
    } else {
      setIsAlert({
        open: true,
        severity: "warning",
        message: t("Messages.You must select at least one product"),
      });
    }
  };

  return (
    <>
      <div className="frame" style={{ width: "calc(100% - 80px)" }}>
        <div className="row">
          <div
            className="d-flex flex-column justify-content-between"
            style={{ paddingBottom: "6%" }}
          >
            <div>
              <h4 className="main-title-treatments">
                {t("AdditionalList.Edit Bundle")}
              </h4>
              <p className="blue-anchors">
                <Link className="blue-anchors" to="/adittionals">
                  &#60; {t("Buttons.Back")}
                </Link>
              </p>
            </div>
            {loading ? (
              <div className="d-flex justify-content-center">
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <div className="row">
                <div className="col-sm-12 col-lg-5">
                  <div className="row">
                    <div>
                      <h3 className="main-subtitle-treatments">
                        {t("AdditionalList.Bundle info")}
                      </h3>
                    </div>
                    <div className="form-group">
                      <label htmlFor="name" className="title-treatments">
                        {t("AdditionalList.Name")}
                      </label>
                      <input
                        required
                        disabled={true}
                        type="text"
                        value={title}
                        onChange={(e) => setName(e.target.value)}
                        className="input-control"
                        style={{ color: "gray", fontStyle: "italic" }}
                      />
                      <Stack sx={{ mt: 1 }}>
                        <Alert
                          severity="warning"
                          style={{ alignItems: "center" }}
                          className="title-inputs"
                        >
                          {t(
                            "Messages.At the moment, you cannot change the name, if you wish to do so you must create a new entry"
                          )}
                        </Alert>
                      </Stack>
                    </div>

                    <div className="form-group">
                      <label htmlFor="duration" className="title-treatments">
                        {t("AdditionalList.Fee")} Nr.
                      </label>
                      <input
                        required
                        type="text"
                        value={rate}
                        onChange={(e) => setRate(e.target.value)}
                        className="input-control"
                      />
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="product"
                        className="title-treatments"
                        style={{ marginBottom: "15px" }}
                      >
                        {t("AdditionalList.Select Product")}
                      </label>
                      <Autocomplete
                        multiple
                        name="products"
                        id="products"
                        options={clinicproduct}
                        value={products}
                        disableCloseOnSelect
                        onChange={(event, newValues) => {
                          setProducts(newValues);
                        }}
                        getOptionLabel={(option) => option.title}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{
                                marginRight: 8,
                                color: "var(--primary-color)",
                              }}
                              checked={selected}
                            />
                            {option.title}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={t("AdditionalList.Products")}
                            placeholder={t("Buttons.Select")}
                            fullWidth
                          />
                        )}
                      />
                    </div>

                    <div className="d-flex justify-content-center mt-3">
                      {archived ? (
                        <button
                          className="blue-btn"
                          onClick={handleArchive}
                          style={{ width: "auto" }}
                        >
                          {t("Buttons.Unarchive")}
                        </button>
                      ) : (
                        <button
                          className="blue-btn"
                          onClick={handleArchive}
                          style={{ width: "auto" }}
                        >
                          {t("Buttons.Archive")}
                        </button>
                      )}

                      <div>
                        <button
                          className="blue-btn"
                          onClick={handleSubmit}
                          style={{ width: "auto" }}
                        >
                          {t("Buttons.Save")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-6">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                    }}
                  >
                    <span className="main-subtitle-treatments">
                      {t("AdditionalList.Prices")}
                    </span>
                    <span
                      className="blue-anchors"
                      style={{ cursor: "pointer" }}
                      onClick={() => setResetDialog(true)}
                    >
                      &#8635; &nbsp; {t("AdditionalList.Reset Prices")}
                    </span>
                  </div>
                  {insurances
                    .sort((a, b) => a["id"] - b["id"])
                    .map((item, i) => (
                      <InsuranceField
                        key={i}
                        item={item}
                        prices={prices}
                        handlePriceChange={handlePriceChange}
                        isPriceUpdating={isPriceUpdating}
                      />
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {isAlert.open && (
        <AlertComponent
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
      <ConfirmDeletionModal
        resetDialog={resetDialog}
        setResetDialog={setResetDialog}
        handleResetPrices={handleResetPrices}
      />
    </>
  );
};

export default EditAdittional;
