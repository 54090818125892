// DEV: if the title, nameEN or nameES value is modified, you must also update that value in the translation files.

/** Type array */
export const values = [
  {
    id: 0,
    title: "Lungenmeridian (Lu)",
    nameEN: "Lung meridian (Lu)",
    nameES: "Meridiano pulmonar (Lu)",
  },
  {
    id: 1,
    title: "Dickdarm (Di)",
    nameEN: "Large intestine (Di)",
    nameES: "Intestino grueso (Di)",
  },
  {
    id: 2,
    title: "Magen (Ma)",
    nameEN: "Stomach (Ma)",
    nameES: "Estómago (Ma)",
  },
  {
    id: 3,
    title: "Milz (Mi)",
    nameEN: "Spleen (Mi)",
    nameES: "Bazo (Mi)",
  },
  {
    id: 4,
    title: "Herz (He)",
    nameEN: "Heart (He)",
    nameES: "Corazón (He)",
  },
  {
    id: 5,
    title: "Dünndarm",
    nameEN: "Small intestine",
    nameES: "Intestino delgado",
  },
  {
    id: 6,
    title: "Blase (Bl)",
    nameEN: "Bladder (Bl)",
    nameES: "Vejiga (Bl)",
  },
  {
    id: 7,
    title: "Niere (Ni)",
    nameEN: "Kidney (Ni)",
    nameES: "Riñón (Ni)",
  },
  {
    id: 8,
    title: "Herzbeutel (Hb)",
    nameEN: "Pericardium (Hb)",
    nameES: "Pericardio (Hb)",
  },
  {
    id: 9,
    title: "3facher Erwärmer (3E)",
    nameEN: "triple warmer (3E)",
    nameES: "triple calentador (3E)",
  },
  {
    id: 10,
    title: "Gallenblase (Gb)",
    nameEN: "Gall bladder (Gb)",
    nameES: "Vesícula biliar (Gb)",
  },
  {
    id: 11,
    title: "Leber (Le)",
    nameEN: "Liver (Le)",
    nameES: "Hígado (Le)",
  },
  {
    id: 12,
    title: "Du Mai (Du)",
    nameEN: "Du Mai (Du)",
    nameES: "Du Mai (Du)",
  },
  {
    id: 13,
    title: "Ren Mai (Ren)",
    nameEN: "Ren Mai (Ren)",
    nameES: "Ren Mai (Ren)",
  },
  {
    id: 14,
    title: "Extrapunkte",
    nameEN: "Extra points",
    nameES: "Puntos extra",
  },
];

/** Treatments arrays */
export const Lungenmeridian = [
  {
    id: 0,
    title: "Lu 01 / Zhong Fu / Versammlungshalle der Mitte",
    nameEN: "Lu 01 / Zhong Fu / Assembly Hall of the Center",
    nameES: "Lu 01 / Zhong Fu / Salón de Actos del Centro",
  },
  {
    id: 1,
    title: "Lu 02 / Yun Men / Tor der Wolken",
    nameEN: "Lu 02 / Yun Men / Gate of the Clouds",
    nameES: "Lu 02 / Yun Men / Puerta de las Nubes",
  },
  {
    id: 2,
    title: "Lu 03 / Tian Fu / Versammlungshalle des Himmels",
    nameEN: "Lu 03 / Tian Fu / Assembly Hall of Heaven",
    nameES: "Lu 03 / Tian Fu / Sala de Asamblea del Cielo",
  },
  {
    id: 3,
    title: "Lu 04 / Xia Bai/ Eingezwängte Weiße",
    nameEN: "Lu 04 / Xia Bai / Cramped Whites",
    nameES: "Lu 04 / Xia Bai / Blancos Apiñados",
  },
  {
    id: 4,
    title: "Lu 05 / Chi Ze / Moorsee am Fußpunkt",
    nameEN: "Lu 05 / Chi Ze / Bog lake at the foot",
    nameES: "Lu 05 / Chi Ze / Lago pantanoso al pie",
  },
  {
    id: 5,
    title: "Lu 06 / Kong Zui / Tiefe des Lochs",
    nameEN: "Lu 06 / Kong Zui / Depth of the Hole",
    nameES: "Lu 06 / Kong Zui / Profundidad del Agujero",
  },
  {
    id: 6,
    title: "Lu 07 / Lie Que / Reihe von Lücken",
    nameEN: "Lu 07 / Lie Que / Row of gaps",
    nameES: "Lu 07 / Lie Que / Fila de huecos",
  },
  {
    id: 7,
    title: "Lu 08 / Jing Qu / Entwässerungsgraben des Durchganges",
    nameEN: "Lu 08 / Jing Qu / Drainage ditch of the passage",
    nameES: "Lu 08 / Jing Qu / Zanja de drenaje del paso",
  },
  {
    id: 8,
    title: "Lu 09 / Tai Yuan / grosser Wasserschlund",
    nameEN: "Lu 09 / Tai Yuan / large water gorge",
    nameES: "Lu 09 / Tai Yuan / gran desfiladero de agua",
  },
  {
    id: 9,
    title: "Lu 10 / Yu Ji /Fischbauchgrenze",
    nameEN: "Lu 10 / Yu Ji / fish belly boundary",
    nameES: "Lu 10 / Yu Ji / Límite del vientre del pez",
  },
  {
    id: 10,
    title: "Lu 11 / Shao Shang / Junges Shang",
    nameEN: "Lu 11 / Shao Shang / Young Shang",
    nameES: "Lu 11 / Shao Shang / Young Shang",
  },
];

export const Dickdarm = [
  {
    id: 0,
    title: "Di 01 / Shang Yang / äusserstes Yang",
    nameEN: "Di 01 / Shang Yang / utmost Yang",
    nameES: "Di 01 / Shang Yang / máximo Yang",
  },
  {
    id: 1,
    title: "Di 02 / Er Jian / zweites Interstitium",
    nameEN: "Di 02 / Er Jian / second interstitium",
    nameES: "Di 02 / Er Jian / segundo intersticio",
  },
  {
    id: 2,
    title: "Di 03 / San Jian / drittes Interstitium",
    nameEN: "Di 03 / San Jian / third interstitium",
    nameES: "Di 03 / San Jian / tercer intersticio",
  },
  {
    id: 3,
    title: "Di 04 / He Gu / vereinte Täler",
    nameEN: "Di 04 / He Gu / united valleys",
    nameES: "Di 04 / He Gu / valles unidos",
  },
  {
    id: 4,
    title: "Di 05 / Yang Xi / Yang-Bach / -Schlucht",
    nameEN: "Di 05 / Yang Xi / Yang stream / gorge",
    nameES: "Di 05 / Yang Xi / arroyo Yang / desfiladero",
  },
  {
    id: 5,
    title: "Di 06 / Pian Li / schräger Durchgang / schräge Verbindung",
    nameEN: "Di 06 / Pian Li / oblique passage / oblique connection",
    nameES: "Di 06 / Pian Li / paso oblicuo / conexión oblicua",
  },
  {
    id: 6,
    title: "Di 07 / Wen Liu / warmer Strom / wärmende Strömung",
    nameEN: "Di 07 / Wen Liu / warm stream / warming current",
    nameES: "Di 07 / Wen Liu / arroyo cálido / corriente cálida",
  },
  {
    id: 7,
    title: "Di 08 / Xia Lian / unterer Engpaß der Hand",
    nameEN: "Di 08 / Xia Lian / lower bottleneck of the hand",
    nameES: "Di 08 / Xia Lian / cuello inferior de la mano",
  },
  {
    id: 8,
    title: "Di 09 / Shàng Lian / oberer Engpaß der Hand",
    nameEN: "Di 09 / Shàng Lian / upper bottleneck of the hand",
    nameES: "Di 09 / Shàng Lian / cuello superior de la mano",
  },
  {
    id: 9,
    title: "Di 10 / Shousanli / dritter Weiler der Hand / 3 Meilen des Arms",
    nameEN: "Di 10 / Shousanli / third hamlet of the hand / 3 miles of the arm",
    nameES: "Di 10 / Shousanli / tercera aldea de la mano / 3 millas del brazo",
  },
  {
    id: 10,
    title: "Di 11 / Qu Chi / gewundener / gekrümmter Teich",
    nameEN: "Di 11 / Qu Chi / winding / curved pond",
    nameES: "Di 11 / Qu Chi / estanque sinuoso / curvo",
  },
  {
    id: 11,
    title:
      "Di 12 / Zhou Liao oder Zhou Jiao/ Kellerloch des Ellbogens / -grube",
    nameEN: "Di 12 / Zhou Liao or Zhou Jiao / cellar hole of the elbow / pit",
    nameES: "Di 12 / Zhou Liao o Zhou Jiao / bodega del codo / pozo",
  },
  {
    id: 12,
    title: "Di 13 / Shou Wuli / fünfter Weiler der Hand",
    nameEN: "Di 13 / Shou Wuli / fifth hamlet of the hand",
    nameES: "Di 13 / Shou Wuli / quinta aldea de la mano",
  },
  {
    id: 13,
    title: "Di 14 / Bi Nao / Arm und Schulterblatt",
    nameEN: "Di 14 / Bi Nao / arm and shoulder blade",
    nameES: "Di 14 / Bi Nao / brazo y omóplato",
  },
  {
    id: 14,
    title: "Di 15 / Jian Yu / Spalt unter der Schulterhöhe",
    nameEN: "Di 15 / Jian Yu / gap below shoulder level",
    nameES: "Di 15 / Jian Yu / hueco por debajo del nivel del hombro",
  },
  {
    id: 15,
    title: "Di 16 / Jù Gu / Großer Knochen",
    nameEN: "Di 16 / Jù Gu / large bone",
    nameES: "Di 16 / Jù Gu / hueso grande",
  },
  {
    id: 16,
    title: "Di 17 / Tian Ding / Dreifuß des Himmels",
    nameEN: "Di 17 / Tian Ding / Tripod of the sky",
    nameES: "Di 17 / Tian Ding / Trípode del cielo",
  },
  {
    id: 17,
    title: "Di 18 / Fu Tu / Wasser-Punkt / Unterstützer der Verwölbung",
    nameEN: "Di 18 / Fu Tu / water point / supporter of the arch",
    nameES: "Di 18 / Fu Tu / punta de agua / soporte del arco",
  },
  {
    id: 18,
    title: "Di 19 / Hé Jiao / Kellerloch des Getreides",
    nameEN: "Di 19 / Hé Jiao / Cellar Hole of the Grain",
    nameES: "Di 19 / Hé Jiao / Agujero de la bodega del grano",
  },
  {
    id: 19,
    title: "Di 20 / Ying Xiang / Empfangen der Wohlgerüche",
    nameEN: "Di 20 / Ying Xiang / Receiving the fragrances",
    nameES: "Di 20 / Ying Xiang / Recepción de las fragancias",
  },
];

export const Magen = [
  {
    id: 0,
    title: "Ma 01 / Cheng Qi / Punkt, der die Tränen aufnimmt",
    nameEN: "Ma 01 / Cheng Qi / point that absorbs tears",
    nameES: "Ma 01 / Cheng Qi / punto que absorbe las lágrimas",
  },
  {
    id: 1,
    title: "Ma 02 / Si Bai / Rand des Wangenbeins",
    nameEN: "Ma 02 / Si Bai / edge of the cheekbone",
    nameES: "Ma 02 / Si Bai / borde del pómulo",
  },
  {
    id: 2,
    title: "Ma 03 / Ju Liao, Ju Jiao / Weites Kellerloch",
    nameEN: "Ma 03 / Ju Liao, Ju Jiao / Wide cellar hole",
    nameES: "Ma 03 / Ju Liao, Ju Jiao / Agujero ancho del sótano",
  },
  {
    id: 3,
    title: "Ma 04 / Di Cang / Zwischenspeicher der Erde",
    nameEN: "Ma 04 / Di Cang / Cache of the earth",
    nameES: "Ma 04 / Di Cang / Alijo de la tierra",
  },
  {
    id: 4,
    title: "Ma 05 / Da Ying / Punkt, der Großes empfängt",
    nameEN: "Ma 05 / Da Ying / Point that receives great things",
    nameES: "Ma 05 / Da Ying / Punto que recibe grandes cosas",
  },
  {
    id: 5,
    title: "Ma 06 / Jia Che / Maxilla",
    nameEN: "Ma 06 / Jia Che / Maxilla",
    nameES: "Ma 06 / Jia Che / Maxila",
  },
  {
    id: 6,
    title: "Ma 07 / Xia Guan / Unteres Paßtor",
    nameEN: "Ma 07 / Xia Guan / Lower Pass Gate",
    nameES: "Ma 07 / Xia Guan / Puerta del Paso Inferior",
  },
  {
    id: 7,
    title: "Ma 08 / Tou Wei / Punkt der Halteleitbahn",
    nameEN: "Ma 08 / Tou Wei / Point of the holding guideway",
    nameES: "Ma 08 / Tou Wei / Punto de la guía de sujeción",
  },
  {
    id: 8,
    title: "Ma 09 / Ren Ying / Punkt, durch den man die Menschen aufnimmt",
    nameEN: "Ma 09 / Ren Ying / Point through which people are received",
    nameES: "Ma 09 / Ren Ying / Punto por el que se recibe a la gente",
  },
  {
    id: 9,
    title: "Ma 10 / Shui Tu / Tor des Wassers",
    nameEN: "Ma 10 / Shui Tu / gate of the water",
    nameES: "Ma 10 / Shui Tu / Puerta del agua",
  },
  {
    id: 10,
    title: "Ma 11 / Qi She / Haus des Qi",
    nameEN: "Ma 11 / Qi She / House of Qi",
    nameES: "Ma 11 / Qi She / Casa del Qi",
  },
  {
    id: 11,
    title: "Ma 12 / Que Pen / Grube über dem Schlüsselbein",
    nameEN: "Ma 12 / Que Pen / Pit above the collarbone",
    nameES: "Ma 12 / Que Pen / Fosa por encima de la clavícula",
  },
  {
    id: 12,
    title: "Ma 13 / Qi Hu / Tür des Qi",
    nameEN: "Ma 13 / Qi Hu / Door of Qi",
    nameES: "Ma 13 / Qi Hu / Puerta del Qi",
  },
  {
    id: 13,
    title: "Ma 14 / Ku Fang / Schatzkammer",
    nameEN: "Ma 14 / Ku Fang / Treasury",
    nameES: "Ma 14 / Ku Fang / Tesoro",
  },

  {
    id: 14,
    title: "Ma 15 / Wu Yi / Schutzdach des Hauses",
    nameEN: "Ma 15 / Wu Yi / roof of the house",
    nameES: "Ma 15 / Wu Yi / Techo de la casa",
  },
  {
    id: 15,
    title: "Ma 16 / Ying Chuang / Fenster der Brust",
    nameEN: "Ma 16 / Ying Chuang / window of the chest",
    nameES: "Ma 16 / Ying Chuang / ventana del pecho",
  },
  {
    id: 16,
    title: "Ma 17 / Ru Zhong / Mitte der Brust",
    nameEN: "Ma 17 / Ru Zhong / center of the chest",
    nameES: "Ma 17 / Ru Zhong / centro del pecho",
  },
  {
    id: 17,
    title: "Ma 18 / Ru Gen / Wurzel der Brust",
    nameEN: "Ma 18 / Ru Gen / root of the chest",
    nameES: "Ma 18 / Ru Gen / raíz del pecho",
  },
  {
    id: 18,
    title: "Ma 19 / Bu Rong / er duldet's nicht",
    nameEN: "Ma 19 / Bu Rong / he does not tolerate it",
    nameES: "Ma 19 / Bu Rong / no lo tolera",
  },
  {
    id: 19,
    title: "Ma 20 / Cheng Man / Pt, der die Vollheit aufnimmt",
    nameEN: "Ma 20 / Cheng Man / Pt, who absorbs completeness",
    nameES: "Ma 20 / Cheng Man / Pt, que absorbe la plenitud",
  },
  {
    id: 20,
    title: "Ma 21 / Liang Men / Tor der Scheidewand",
    nameEN: "Ma 21 / Liang Men / Gate of the Partition",
    nameES: "Ma 21 / Liang Men / puerta de la barrera del paso",
  },
  {
    id: 21,
    title: "Ma 22 / Guan Men / Tor der Paßsperre",
    nameEN: "Ma 22 / Guan Men / gate of the pass barrier",
    nameES: "Ma 22 / Guan Men / puerta de la barrera del paso",
  },
  {
    id: 22,
    title: "Ma 23 / Tai Yi / das mächtige Eine",
    nameEN: "Ma 23 / Tai Yi / the mighty one",
    nameES: "Ma 23 / Tai Yi / el poderoso",
  },
  {
    id: 23,
    title: "Ma 24 / Hua Rou Men / Pforte des Schlüpfrigen",
    nameEN: "Ma 24 / Hua Rou Men / Gate of the Slippery One",
    nameES: "Ma 24 / Hua Rou Men / Puerta del resbaladizo",
  },
  {
    id: 24,
    title: "Ma 25 / Tian Shu / Angel des Himmels",
    nameEN: "Ma 25 / Tian Shu / Angel of Heaven",
    nameES: "Ma 25 / Tian Shu / Ángel del Cielo",
  },
  {
    id: 25,
    title: "Ma 26 / Wai Ling / äußerer Grabhügel",
    nameEN: "Ma 26 / Wai Ling / outer burial mound",
    nameES: "Ma 26 / Wai Ling / túmulo exterior",
  },
  {
    id: 26,
    title: "Ma 27 / Da Ju / das grosse Winkelmaß",
    nameEN: "Ma 27 / Da Ju / the great angle measure",
    nameES: "Ma 27 / Da Ju / la medida del gran ángulo",
  },
  {
    id: 27,
    title: "Ma 28 / Hui Dao / Weg des Wassers",
    nameEN: "Ma 28 / Hui Dao / path of the water",
    nameES: "Ma 28 / Hui Dao / camino del agua",
  },
  {
    id: 28,
    title: "Ma 29 / Gui Lai / Komm zurück",
    nameEN: "Ma 29 / Gui Lai / Come back",
    nameES: "Ma 29 / Gui Lai / Vuelve",
  },
  {
    id: 29,
    title: "Ma 30 / Qi Chong / breite Straße des Qi",
    nameEN: "Ma 30 / Qi Chong / broad road of qi",
    nameES: "Ma 30 / Qi Chong / camino ancho del qi",
  },
  {
    id: 30,
    title: "Ma 31 / Bi Guan / Paßtor des Femurs",
    nameEN: "Ma 31 / Bi Guan / Gate of the Femur",
    nameES: "Ma 31 / Bi Guan / Puerta del Fémur",
  },
  {
    id: 31,
    title: "Ma 32 / FU Tu / der sich an den Boden drückende Hase",
    nameEN: "Ma 32 / FU Tu / the hare pressing against the ground",
    nameES: "Ma 32 / FU Tu / la liebre presionando contra el suelo",
  },
  {
    id: 32,
    title: "Ma 33 / Yin Shi / Marktplatz des Yin",
    nameEN: "Ma 33 / Yin Shi / Marketplace of the Yin",
    nameES: "Ma 33 / Yin Shi / Mercado del Yin",
  },
  {
    id: 33,
    title: "Ma 34 / Liang Qiu / Hügel mit der Scheidewand",
    nameEN: "Ma 34 / Liang Qiu / hill with the septum",
    nameES: "Ma 34 / Liang Qiu / colina con el tabique",
  },
  {
    id: 34,
    title: "Ma 35 / Du Bi / Kalbsnase",
    nameEN: "Ma 35 / Du Bi / calf's nose",
    nameES: "Ma 35 / Du Bi / nariz de ternera",
  },
  {
    id: 35,
    title: "Ma 36 / Zu San Li / dritter Weiler am Fuß",
    nameEN: "Ma 36 / Zu San Li / third hamlet at the foot",
    nameES: "Ma 36 / Zu San Li / tercera aldea al pie",
  },
  {
    id: 36,
    title: "Ma 37 / Ju Xu Shang Lian, Shang Ju Xu / ob. Enge des weiten Feldes",
    nameEN:
      "Ma 37 / Ju Xu Shang Lian, Shang Ju Xu / ob. Narrow part of the wide field",
    nameES:
      "Ma 37 / Ju Xu Shang Lian, Shang Ju Xu / ob. Parte estrecha del campo ancho",
  },
  {
    id: 37,
    title: "Ma 38 / Tiao Kou / Öffnung der Erschlaffung",
    nameEN: "Ma 38 / Tiao Kou / opening of the slackness",
    nameES: "Ma 38 / Tiao Kou / apertura de la holgura",
  },
  {
    id: 38,
    title: "Ma 39 / Ju Xu Xia Lian, Xia Ju Xu / untere Enge des weiten Feldes",
    nameEN:
      "Ma 39 / Ju Xu Xia Lian, Xia Ju Xu / lower narrowness of the wide field",
    nameES:
      "Ma 39 / Ju Xu Xia Lian, Xia Ju Xu / estrechez inferior del campo ancho",
  },
  {
    id: 39,
    title: "Ma 40 / Feng Long / Üppige Fülle",
    nameEN: "Ma 40 / Feng Long / Lush fullness",
    nameES: "Ma 40 / Feng Long / exuberante plenitud",
  },
  {
    id: 40,
    title: "Ma 41 / Jie Xi / befreiter Wasserlauf",
    nameEN: "Ma 41 / Jie Xi / liberated watercourse",
    nameES: "Ma 41 / Jie Xi / curso de agua liberado",
  },
  {
    id: 41,
    title: "Ma 42 / Chong Yang / Yang der großen Straße",
    nameEN: "Ma 42 / Chong Yang / Yang of the great road",
    nameES: "Ma 42 / Chong Yang / Yang del gran camino",
  },
  {
    id: 42,
    title: "Ma 43 / Xian Gu / das eingebrochene Tal",
    nameEN: "Ma 43 / Xian Gu / the collapsed valley",
    nameES: "Ma 43 / Xian Gu / el valle derrumbado",
  },
  {
    id: 43,
    title: "Ma 44 / Nei Ting / innere Vorhalle",
    nameEN: "Ma 44 / Nei Ting / inner vestibule",
    nameES: "Ma 44 / Nei Ting / vestíbulo interior",
  },
  {
    id: 44,
    title: "Ma 45 / Li Dui / unterdrückte Heiterkeit",
    nameEN: "Ma 45 / Li Dui / suppressed serenity",
    nameES: "Ma 45 / Li Dui / serenidad suprimida",
  },
];

export const Milz = [
  {
    id: 0,
    title: "Mi 01 / Yin Bai / verborgene Weiße",
    nameEN: "Mi 01 / Yin Bai / hidden white",
    nameES: "Mi 01 / Yin Bai / blanco oculto",
  },
  {
    id: 1,
    title: "Mi 02 / Dadu / grosse Stadt / grosse Hauptstadt",
    nameEN: "Mi 02 / Dadu / big city / big capital",
    nameES: "Mi 02 / Dadu / gran ciudad / gran capital",
  },
  {
    id: 2,
    title: "Mi 03 / Tai Bai / das größte Weiße / grosses Weiss",
    nameEN: "Mi 03 / Tai Bai / the largest white / great white",
    nameES: "Mi 03 / Tai Bai / el blanco más grande / gran blanco",
  },
  {
    id: 3,
    title: "Mi 04 / Gong Sun / Enkel des Herzogs / kleine Verbindungsgefässe",
    nameEN:
      "Mi 04 / Gong Sun / grandson of the duke / small connecting vessels",
    nameES: "Mi 04 / Gong Sun / nieto del duque / pequeños vasos comunicantes",
  },
  {
    id: 4,
    title: "Mi 05 / Shang Qiu / kleiner Fersenberg / Goldhügel / Metallhügel",
    nameEN: "Mi 05 / Shang Qiu / small heel hill / gold hill / metal hill",
    nameES:
      "Mi 05 / Shang Qiu / pequeña colina del talón / colina de oro / colina de metal",
  },
  {
    id: 5,
    title: "Mi 06 / San Yin Jiao / Verbindung der drei Yin",
    nameEN: "Mi 06 / San Yin Jiao / connection of the three Yin",
    nameES: "Mi 06 / San Yin Jiao / conexión de los tres Yin",
  },
  {
    id: 6,
    title: "Mi 07 / Lou Gu / Tal des Sickerflusses",
    nameEN: "Mi 07 / Lou Gu / valley of the seepage river",
    nameES: "Mi 07 / Lou Gu / valle del río de la filtración",
  },
  {
    id: 7,
    title: "Mi 08 / Di Ji / Mechanismus der Erde / Erd-Drehpunkt",
    nameEN: "Mi 08 / Di Ji / mechanism of the earth / earth pivot point",
    nameES:
      "Mi 08 / Di Ji / mecanismo de la tierra / punto de giro de la tierra",
  },
  {
    id: 8,
    title: "Mi 09 / Yin Ling Quan / die Quelle am -Grabhügel / -Hügel-Quelle",
    nameEN:
      "Mi 09 / Yin Ling Quan / the spring at the burial mound / hill spring",
    nameES:
      "Mi 09 / Yin Ling Quan / el manantial del túmulo / manantial de la colina",
  },
  {
    id: 9,
    title: "Mi 10 / Xue Hai / Meer des Blutes",
    nameEN: "Mi 10 / Xue Hai / Sea of Blood",
    nameES: "Mi 10 / Xue Hai / Mar de Sangre",
  },
  {
    id: 10,
    title: "Mi 11 / Ji Men / Tor des Schützen",
    nameEN: "Mi 11 / Ji Men / Gate of the Sagittarius",
    nameES: "Mi 11 / Ji Men / Puerta del Sagitario",
  },
  {
    id: 11,
    title: "Mi 12 / Chong Mén / Pforte der großen Straße / brandendes Tor",
    nameEN: "Mi 12 / Chong Mén / gate of the great road / blazing gate",
    nameES: "Mi 12 / Chong Mén / puerta del gran camino / puerta ardiente",
  },
  {
    id: 12,
    title: "Mi 13 / Fu She / ein Haus wie eine Versammlungshalle",
    nameEN: "Mi 13 / Fu She / a house like an assembly hall",
    nameES: "Mi 13 / Fu She / una casa como un salón de actos",
  },
  {
    id: 13,
    title: "Mi 14 / Fu Jie / Knoten im Abdomen",
    nameEN: "Mi 14 / Fu Jie / lump in the abdomen",
    nameES: "Mi 14 / Fu Jie / bulto en el abdomen",
  },
  {
    id: 14,
    title:
      "Mi 15 / Da Heng / Foramen der groß Querfalte, Grosser horizontaler Pinselstrich",
    nameEN:
      "Mi 15 / Da Heng / foramen of the large transverse fold, large horizontal brushstroke",
    nameES:
      "Mi 15 / Da Heng / foramen del gran pliegue transversal, gran pincelada horizontal",
  },
  {
    id: 15,
    title: "Mi 16 / Fu Ai / jammervolles Gefühl im Bauch",
    nameEN: "Mi 16 / Fu Ai / miserable feeling in the abdomen",
    nameES: "Mi 16 / Fu Ai / sensación miserable en el abdomen",
  },
  {
    id: 16,
    title:
      "Mi 17 / Ming Quan  oder Shi Dou/ Nahrungsvertiefung, Paßsperre des Lebensloses",
    nameEN:
      "Mi 17 / Ming Quan or Shi Dou / depression of nourishment, blockage of the lifeless",
    nameES:
      "Mi 17 / Ming Quan o Shi Dou / depresión de la alimentación, bloqueo de los sin vida",
  },
  {
    id: 17,
    title: "Mi 18 / Tian Xi / himmlischer Wasserlauf",
    nameEN: "Mi 18 / Tian Xi / heavenly watercourse",
    nameES: "Mi 18 / Tian Xi / corriente de agua celestial",
  },
  {
    id: 18,
    title: "Mi 19 / Xiong Xiang / auf dem Land der Brust",
    nameEN: "Mi 19 / Xiong Xiang / on the land of the chest",
    nameES: "Mi 19 / Xiong Xiang / en la tierra del pecho",
  },
  {
    id: 19,
    title: "Mi 20 / Zhou Ying oder Zhou Rong / das eingefriedete Lager",
    nameEN: "Mi 20 / Zhou Ying or Zhou Rong / the enclosed camp",
    nameES: "Mi 20 / Zhou Ying o Zhou Rong / el campo cerrado",
  },
  {
    id: 20,
    title: "Mi 21 / Da Bao / die große Umhüllung / allgemeine Kontrolle",
    nameEN: "Mi 21 / Da Bao / the great envelope / general control",
    nameES: "Mi 21 / Da Bao / la gran envoltura / el control general",
  },
];

export const Herz = [
  {
    id: 0,
    title: "He 01 / Ji Quan / Quelle am äußersten Ende",
    nameEN: "He 01 / Ji Quan / spring at the far end",
    nameES: "He 01 / Ji Quan / manantial en el extremo lejano",
  },
  {
    id: 1,
    title: "He 02 / Qing Ling Quan / blaugrüne Quelle",
    nameEN: "He 02 / Qing Ling Quan / blue-green spring",
    nameES: "He 02 / Qing Ling Quan / manantial verde azulado",
  },
  {
    id: 2,
    title: "He 03 / Shao Hai / das kleine Meer",
    nameEN: "He 03 / Shao Hai / the small sea",
    nameES: "He 03 / Shao Hai / el pequeño mar",
  },
  {
    id: 3,
    title: "He 04 / Tong Li / der verbindende Weiler",
    nameEN: "He 04 / Tong Li / the connecting hamlet",
    nameES: "He 04 / Tong Li / la aldea de conexión",
  },
  {
    id: 4,
    title: "He 05 / Ling Dao / Weg der Struktivkraft",
    nameEN: "He 05 / Ling Dao / path of structural power",
    nameES: "He 05 / Ling Dao / camino del poder estructural",
  },
  {
    id: 5,
    title: "He 06 / Yin Xi / Spaltpunkt des kleinen Yin",
    nameEN: "He 06 / Yin Xi / splitting point of the small Yin",
    nameES: "He 06 / Yin Xi / punto de división del pequeño Yin",
  },
  {
    id: 6,
    title:
      "He 07 / Shen Men, Dui Chong / die Strasse zur Heiterkeit (Pforte des Shen)",
    nameEN: "He 07 / Shen Men, Dui Chong / the road to serenity (gate of Shen)",
    nameES:
      "He 07 / Shen Men, Dui Chong / el camino de la serenidad (puerta de Shen)",
  },
  {
    id: 7,
    title: "He 08 / Shao Fu / kleine Versammlungshalle",
    nameEN: "He 08 / Shao Fu / small assembly hall",
    nameES: "He 08 / Shao Fu / pequeño salón de actos",
  },
  {
    id: 8,
    title: "He 09 / Shao Chong / die kleinere Strasse",
    nameEN: "He 09 / Shao Chong / the smaller road",
    nameES: "He 09 / Shao Chong / el camino más pequeño",
  },
];

export const Dünndarm = [
  {
    id: 0,
    title: "Dü 01 / Shao Ze / der kleine Moorsee",
    nameEN: "Dü 01 / Shao Ze / the small moor lake",
    nameES: "Dü 01 / Shao Ze / el pequeño lago del páramo",
  },
  {
    id: 1,
    title: "Dü 02 / Qian Gu / das vordere Tal",
    nameEN: "Dü 02 / Qian Gu / the front valley",
    nameES: "Dü 02 / Qian Gu / el valle delantero",
  },
  {
    id: 2,
    title: "Dü 03 / Hou Xi / der hintere Wasserlauf",
    nameEN: "Dü 03 / Hou Xi / the rear watercourse",
    nameES: "Dü 03 / Hou Xi / el curso de agua posterior",
  },
  {
    id: 3,
    title: "Dü 04 / Wan Gu / Handgelenksknochen",
    nameEN: "Dü 04 / Wan Gu / wrist bone",
    nameES: "Dü 04 / Wan Gu / hueso de la muñeca",
  },
  {
    id: 4,
    title: "Dü 05 / Yang Gu / Tal des Yang",
    nameEN: "Dü 05 / Yang Gu / Valley of the Yang",
    nameES: "Dü 05 / Yang Gu / Valle del Yang",
  },
  {
    id: 5,
    title: "Dü 06 / Yang Lao / glückliches Alter",
    nameEN: "Dü 06 / Yang Lao / happy old age",
    nameES: "Dü 06 / Yang Lao / vejez feliz",
  },
  {
    id: 6,
    title: "Dü 07 / Zhi Zheng / Punkt, der die Orthopathie stützt",
    nameEN: "Dü 07 / Zhi Zheng / point that supports orthopathy",
    nameES: "Dü 07 / Zhi Zheng / punto que sostiene la ortopatía",
  },
  {
    id: 7,
    title: "Dü 08 / Xiao Hai / kleines Meer",
    nameEN: "Dü 08 / Xiao Hai / small sea",
    nameES: "Dü 08 / Xiao Hai / pequeño mar",
  },
  {
    id: 8,
    title: "Dü 09 / Jian Zhen / Geradheit der Schulter",
    nameEN: "Dü 09 / Jian Zhen / straightness of the shoulder",
    nameES: "Dü 09 / Jian Zhen / rectitud del hombro",
  },
  {
    id: 9,
    title: "Dü 10 / Nao Shu / Einflußpunkt des Armmuskel",
    nameEN: "Dü 10 / Nao Shu / point of influence of the arm muscle",
    nameES: "Dü 10 / Nao Shu / punto de influencia del músculo del brazo",
  },
  {
    id: 10,
    title: "Dü 11 / Tian Zong / Geschlecht des Himmels",
    nameEN: "Dü 11 / Tian Zong / sex of the sky",
    nameES: "Dü 11 / Tian Zong / sexo del cielo",
  },
  {
    id: 11,
    title: "Dü 12 / Bing Feng / Punkt, über den Wind zu bezwingen ist",
    nameEN: "Dü 12 / Bing Feng / Point over which wind can be conquered",
    nameES:
      "Dü 12 / Bing Feng / punto sobre el que se puede conquistar el viento",
  },
  {
    id: 12,
    title: "Dü 13 / Qu Yuan / gekrümmte Mauer",
    nameEN: "Dü 13 / Qu Yuan / curved wall",
    nameES: "Dü 13 / Qu Yuan / pared curva",
  },
  {
    id: 13,
    title: "Dü 14 / Jian Wai Shu / äusserer Einflunpunkt der Schulter",
    nameEN: "Dü 14 / Jian Wai Shu / outer confluence point of the shoulder",
    nameES: "Dü 14 / Jian Wai Shu / punto de confluencia exterior del hombro",
  },
  {
    id: 14,
    title: "Dü 15 / Jian Zhong Shu / mittlerer Einflusspunkt der Schulter",
    nameEN:
      "Dü 15 / Jian Zhong Shu / middle point of influence of the shoulder",
    nameES: "Dü 15 / Jian Zhong Shu / punto medio de influencia del hombro",
  },
  {
    id: 15,
    title: "Dü 16 / Tian Chuang / Fenster des Himmels",
    nameEN: "Dü 16 / Tian Chuang / window of the sky",
    nameES: "Dü 16 / Tian Chuang / ventana del cielo",
  },
  {
    id: 16,
    title: "Dü 17 / Tian Rong / Antlitz des Himmels",
    nameEN: "Dü 17 / Tian Rong / Face of the sky",
    nameES: "Dü 17 / Tian Rong / Rostro del cielo",
  },
  {
    id: 17,
    title: "Dü 18 / Quanjio, Quanliao / Kellerloch der Wange",
    nameEN: "Dü 18 / Quanjio, Quanliao / Cellar hole of the cheek",
    nameES: "Dü 18 / Quanjio, Quanliao / Agujero del sótano de la mejilla",
  },
  {
    id: 18,
    title: "Dü 19 / Ting Gong / Palast des Gehörs",
    nameEN: "Dü 19 / Ting Gong / Palace of Hearing",
    nameES: "Dü 19 / Ting Gong / Palacio del oído",
  },
];

export const Blase = [
  {
    id: 0,
    title: "Bl 01 / Jing Ming / Helle des Auges",
    nameEN: "Bl 01 / Jing Ming / Brightness of the eye",
    nameES: "Bl 01 / Jing Ming / Brillo del ojo",
  },
  {
    id: 1,
    title: "Bl 02 / Cuan Zhu / zusammengelegter Bambus",
    nameEN: "Bl 02 / Cuan Zhu / folded bamboo",
    nameES: "Bl 02 / Cuan Zhu / bambú doblado",
  },
  {
    id: 2,
    title: "Bl 03 / Mei Chong / Straße der Augenbraue",
    nameEN: "Bl 03 / Mei Chong / road of the eyebrow",
    nameES: "Bl 03 / Mei Chong / calle de la ceja",
  },
  {
    id: 3,
    title: "Bl 04 / Bi Chong / große Strasse der Nase",
    nameEN: "Bl 04 / Bi Chong / large street of the nose",
    nameES: "Bl 04 / Bi Chong / gran calle de la nariz",
  },
  {
    id: 4,
    title: "Bl 05 / Wu Chu / an der fünften Stelle",
    nameEN: "Bl 05 / Wu Chu / in the fifth place",
    nameES: "Bl 05 / Wu Chu / en el quinto lugar",
  },
  {
    id: 5,
    title: "Bl 06 / Cheng Guang / Pt, welcher das Licht aufnehmen lässt",
    nameEN: "Bl 06 / Cheng Guang / Pt, which lets the light in",
    nameES: "Bl 06 / Cheng Guang / Pt, que deja entrar la luz",
  },
  {
    id: 6,
    title: "Bl 07 / Tong Tian / Himmelskommunikation",
    nameEN: "Bl 07 / Tong Tian / communication with the sky",
    nameES: "Bl 07 / Tong Tian / comunicación con el cielo",
  },
  {
    id: 7,
    title: "Bl 08 / Luo Que / Verbindung mit Zurückweichendem",
    nameEN: "Bl 08 / Luo Que / connection with the receding",
    nameES: "Bl 08 / Luo Que / conexión con el retroceso",
  },
  {
    id: 8,
    title: "Bl 09 / Yu Zhen / Nackenstütze aus Jade",
    nameEN: "Bl 09 / Yu Zhen / neck support made of jade",
    nameES: "Bl 09 / Yu Zhen / soporte de cuello hecho de jade",
  },
  {
    id: 9,
    title: "Bl 10 / Tian Zhu / Säule des Himmels",
    nameEN: "Bl 10 / Tian Zhu / Pillar of heaven",
    nameES: "Bl 10 / Tian Zhu / pilar del cielo",
  },
  {
    id: 10,
    title: "Bl 11 / Da Zhu / grosses Weberschiffchen",
    nameEN: "Bl 11 / Da Zhu / large weaver's shuttle",
    nameES: "Bl 11 / Da Zhu / gran lanzadera de tejedor",
  },
  {
    id: 11,
    title: "Bl 12 / Feng Men / Pforte der Winde",
    nameEN: "Bl 12 / Feng Men / Gate of the winds",
    nameES: "Bl 12 / Feng Men / Puerta de los vientos",
  },
  {
    id: 12,
    title: "Bl 13 / Fei Shu / Einflusspunkt des Lungen",
    nameEN: "Bl 13 / Fei Shu / point of influence of the lungs",
    nameES: "Bl 13 / Fei Shu / punto de influencia de los pulmones",
  },
  {
    id: 13,
    title: "Bl 14 / Jue Yin Shu / Einflusspunkt für das weichende Yin",
    nameEN: "Bl 14 / Jue Yin Shu / point of influence for the softening Yin",
    nameES: "Bl 14 / Jue Yin Shu / punto de influencia del Yin suavizante",
  },
  {
    id: 14,
    title: "Bl 15 / Xin Shu / Einflusspunkt des Herz",
    nameEN: "Bl 15 / Xin Shu / point of influence of the heart",
    nameES: "Bl 15 / Xin Shu / punto de influencia del corazón",
  },
  {
    id: 15,
    title: "Bl 16 / Du Shu / Einflusspunkt der Steuerungsleitbahn",
    nameEN: "Bl 16 / Du Shu / Influence point of the control pathway",
    nameES: "Bl 16 / Du Shu / punto de influencia de la vía de control",
  },
  {
    id: 16,
    title: "Bl 17 / Ge Shu / Einflusspunkt des Zwerchfells",
    nameEN: "Bl 17 / Ge Shu / point of influence of the diaphragm",
    nameES: "Bl 17 / Ge Shu / punto de influencia del diafragma",
  },
  {
    id: 17,
    title: "Bl 18 / Gan Shu / Einflusspunkt des Leber",
    nameEN: "Bl 18 / Gan Shu / Influence point of the liver",
    nameES: "Bl 18 / Gan Shu / Punto de influencia del hígado",
  },
  {
    id: 18,
    title: "Bl 19 / Dan Shu / Einflusspunkt des GB",
    nameEN: "Bl 19 / Dan Shu / Influence point of the GB",
    nameES: "Bl 19 / Dan Shu / Punto de influencia del GB",
  },
  {
    id: 19,
    title: "Bl 20 / Pi Shu / Einflusspunkt der Mitte",
    nameEN: "Bl 20 / Pi Shu / point of influence of the center",
    nameES: "Bl 20 / Pi Shu / punto de influencia del centro",
  },
  {
    id: 20,
    title: "Bl 21 / Wei Shu / Einflusspunkt des Magenfunktionskreises",
    nameEN: "Bl 21 / Wei Shu / Influence point of the stomach function circle",
    nameES:
      "Bl 21 / Wei Shu / Punto de influencia del círculo de la función estomacal",
  },
  {
    id: 21,
    title: "Bl 22 / San Jiao Shu / Einflusspunkt d Dreier Erwärmer",
    nameEN: "Bl 22 / San Jiao Shu / Influence point of the Triple Warmer",
    nameES: "Bl 22 / San Jiao Shu / Punto de influencia del triple calentador",
  },
  {
    id: 22,
    title: "Bl 23 / Shen Shu / Einflusspunkt des Nierefunktionskreises",
    nameEN:
      "Bl 23 / Shen Shu / Influence point of the kidney functional circuit",
    nameES:
      "Bl 23 / Shen Shu / Punto de influencia del circuito funcional del riñón",
  },
  {
    id: 23,
    title: "Bl 24 / Qi Hai Shu / Einflusspunkt des Meer des Qi",
    nameEN: "Bl 24 / Qi Hai Shu / Influence point of the Sea of Qi",
    nameES: "Bl 24 / Qi Hai Shu / Punto de influencia del Mar de Qi",
  },
  {
    id: 24,
    title: "Bl 25 / Da Chang Shu / Einflusspunkt des Dickdarmfunktionskreises",
    nameEN:
      "Bl 25 / Da Chang Shu / Influence point of the Large Intestine functional circuit",
    nameES:
      "Bl 25 / Da Chang Shu / Punto de influencia del circuito funcional del Intestino Grueso",
  },
  {
    id: 25,
    title: "Bl 26 / Guan Yuan Shu / Einflusspunkt des ersten Passtores",
    nameEN: "Bl 26 / Guan Yuan Shu / Influence point of the first pass gate",
    nameES:
      "Bl 26 / Guan Yuan Shu / Punto de influencia de la puerta del primer paso",
  },
  {
    id: 26,
    title:
      "Bl 27 / Xiao Chang Shu / Einflusspunkt des Dünndarmfunktionskreises",
    nameEN:
      "Bl 27 / Xiao Chang Shu / Influence point of the small intestine functional circuit",
    nameES:
      "Bl 27 / Xiao Chang Shu / Punto de influencia del circuito funcional del intestino delgado",
  },
  {
    id: 27,
    title: "Bl 28 / Pang Guang Shu / Einflusspunkt des Blasenfunktionskreises",
    nameEN:
      "Bl 28 / Pang Guang Shu / Influence point of the bladder functional circuit",
    nameES:
      "Bl 28 / Pang Guang Shu / Punto de influencia del circuito funcional de la vejiga",
  },
  {
    id: 28,
    title: "Bl 29 / Zhong Lu Shu / Einflusspunkt des mittleren Rücken",
    nameEN: "Bl 29 / Zhong Lu Shu / Influence point of the middle back",
    nameES: "Bl 29 / Zhong Lu Shu / Punto de influencia de la espalda media",
  },
  {
    id: 29,
    title: "Bl 30 / Bai Huan Shu / Einflusspunkt des weissen Ringes",
    nameEN: "Bl 30 / Bai Huan Shu / Influence point of the white ring",
    nameES: "Bl 30 / Bai Huan Shu / Punto de influencia del anillo blanco",
  },
  {
    id: 30,
    title: "Bl 31 / Shang Liao, Shang Jiao / oberes Kellerloch",
    nameEN: "Bl 31 / Shang Liao, Shang Jiao / upper cellar hole",
    nameES: "Bl 31 / Shang Liao, Shang Jiao / orificio superior del sótano",
  },
  {
    id: 31,
    title: "Bl 32 / Ci Liao, Ci Jiao / das nachfolgende Kellerloch",
    nameEN: "Bl 32 / Ci Liao, Ci Jiao / the following cellar hole",
    nameES: "Bl 32 / Ci Liao, Ci Jiao / el siguiente agujero del sótano",
  },
  {
    id: 32,
    title: "Bl 33 / Zhong Liao, Zhong Jiao / mittleres Kellerloch",
    nameEN: "Bl 33 / Zhong Liao, Zhong Jiao / middle cellar hole",
    nameES: "Bl 33 / Zhong Liao, Zhong Jiao / agujero del sótano medio",
  },
  {
    id: 33,
    title: "Bl 34 / Xia Liao, Xia Jiao / unteres Kellerloch",
    nameEN: "Bl 34 / Xia Liao, Xia Jiao / lower cellar hole",
    nameES: "Bl 34 / Xia Liao, Xia Jiao / agujero del sótano inferior",
  },
  {
    id: 34,
    title: "Bl 35 / Hui  Yang/ die sich treffenden Yang",
    nameEN: "Bl 35 / Hui Yang / the meeting Yang",
    nameES: "Bl 35 / Hui Yang / la reunión Yang",
  },
  {
    id: 35,
    title: "Bl 36 / Cheng Fu / Spalte des Fleisches",
    nameEN: "Bl 36 / Cheng Fu / cleft of the flesh",
    nameES: "Bl 36 / Cheng Fu / hendidura de la carne",
  },
  {
    id: 36,
    title: "Bl 37 / Yin Men / Pforte des Femur",
    nameEN: "Bl 37 / Yin Men / gate of the femur",
    nameES: "Bl 37 / Yin Men / puerta del fémur",
  },
  {
    id: 37,
    title: "Bl 38 / Fu Xi oder Rou Xi/ oberflächlicher Spalt",
    nameEN: "Bl 38 / Fu Xi or Rou Xi / superficial fissure",
    nameES: "Bl 38 / Fu Xi o Rou Xi / fisura superficial",
  },
  {
    id: 38,
    title: "Bl 39 / Wei Yang  / des Staugewässers",
    nameEN: "Bl 39 / Wei Yang / of the stagnant water",
    nameES: "Bl 39 / Wei Yang / del agua estancada",
  },
  {
    id: 39,
    title: "Bl 40 / Wei Zhong / Mitte des Staugewässers",
    nameEN: "Bl 40 / Wei Zhong / center of the backwater",
    nameES: "Bl 40 / Wei Zhong / centro del remanso",
  },
  {
    id: 40,
    title: "Bl 41 / Fu Fen / angefügtes Teil",
    nameEN: "Bl 41 / Fu Fen / attached part",
    nameES: "Bl 41 / Fu Fen / parte adjunta",
  },
  {
    id: 41,
    title: "Bl 42 / Po Hu / Tür der Seele",
    nameEN: "Bl 42 / Po Hu / door of the soul",
    nameES: "Bl 42 / Po Hu / puerta del alma",
  },
  {
    id: 42,
    title: "Bl 43 / Gao Huang Shu / das Innere",
    nameEN: "Bl 43 / Gao Huang Shu / the inside",
    nameES: "Bl 43 / Gao Huang Shu / el interior",
  },
  {
    id: 43,
    title: "Bl 44 / Shen Tang / Vorhof d konstellierenden Kraft",
    nameEN: "Bl 44 / Shen Tang / atrium of the constellating power",
    nameES: "Bl 44 / Shen Tang / atrio del poder constelador",
  },
  {
    id: 44,
    title: "Bl 45 / Yi Xi / Pkt. d. Aufstöhnens",
    nameEN: "Bl 45 / Yi Xi / Pkt. d. Groaning",
    nameES: "Bl 45 / Yi Xi / Pkt. d. Gemido",
  },
  {
    id: 45,
    title: "Bl 46 / Ge Guan / Paßtor des Zwerchfell",
    nameEN: "Bl 46 / Ge Guan / Gate of the Diaphragm",
    nameES: "Bl 46 / Ge Guan / Puerta del Diafragma",
  },
  {
    id: 46,
    title: "Bl 47 / Hun Men / Pforte des Animus",
    nameEN: "Bl 47 / Hun Men / Gate of the Animus",
    nameES: "Bl 47 / Hun Men / Puerta del Animus",
  },
  {
    id: 47,
    title: "Bl 48 / Yang Gang / Generalpunkt des Yang",
    nameEN: "Bl 48 / Yang Gang / General point of the Yang",
    nameES: "Bl 48 / Yang Gang / Punto general del Yang",
  },
  {
    id: 48,
    title: "Bl 49 / Yi She / Haus der Phantasie",
    nameEN: "Bl 49 / Yi She / House of the imagination",
    nameES: "Bl 49 / Yi She / Casa de la imaginación",
  },
  {
    id: 49,
    title: "Bl 50 / Wei Cang / der Getreidespeicher",
    nameEN: "Bl 50 / Wei Cang / the granary",
    nameES: "Bl 50 / Wei Cang / el granero",
  },
  {
    id: 50,
    title: "Bl 51 / Huang Men / das Tor des Inneren",
    nameEN: "Bl 51 / Huang Men / the gate of the interior",
    nameES: "Bl 51 / Huang Men / la puerta del interior",
  },
  {
    id: 51,
    title: "Bl 52 / Zhi Shi / Zimmer d Potenz",
    nameEN: "Bl 52 / Zhi Shi / Room of Potency",
    nameES: "Bl 52 / Zhi Shi / Sala de la potencia",
  },
  {
    id: 52,
    title: "Bl 53 / Bao Huang / die eingewickelten Eingeweide",
    nameEN: "Bl 53 / Bao Huang / the wrapped entrails",
    nameES: "Bl 53 / Bao Huang / las entrañas envueltas",
  },
  {
    id: 53,
    title: "Bl 54 / Zhi Bian / der nachfolgende Rand",
    nameEN: "Bl 54 / Zhi Bian / the following edge",
    nameES: "Bl 54 / Zhi Bian / el borde siguiente",
  },
  {
    id: 54,
    title: "Bl 55 / He Yang / die verbundenen Yang",
    nameEN: "Bl 55 / He Yang / the connected Yang",
    nameES: "Bl 55 / He Yang / el Yang conectado",
  },
  {
    id: 55,
    title: "Bl 56 / Cheng Jin / Stützung des Bewegungsapparates",
    nameEN: "Bl 56 / Cheng Jin / supporting the musculoskeletal system",
    nameES: "Bl 56 / Cheng Jin / sostener el sistema musculoesquelético",
  },
  {
    id: 56,
    title: "Bl 57 / Cheng Shan, Rou Zhu / Säule des Fleisches",
    nameEN: "Bl 57 / Cheng Shan, Rou Zhu / pillar of the flesh",
    nameES: "Bl 57 / Cheng Shan, Rou Zhu / pilar de la carne",
  },
  {
    id: 57,
    title: "Bl 58 / Fei Yang, Jue Yang/ der zurückweichende Yang",
    nameEN: "Bl 58 / Fei Yang, Jue Yang / the retreating Yang",
    nameES: "Bl 58 / Fei Yang, Jue Yang / el Yang en retirada",
  },
  {
    id: 58,
    title: "Bl 59 / Fu Yang / Yang der Ferse",
    nameEN: "Bl 59 / Fu Yang / Yang of the heel",
    nameES: "Bl 59 / Fu Yang / Yang del talón",
  },
  {
    id: 59,
    title: "Bl 60 / Kun Lun / Olympus",
    nameEN: "Bl 60 / Kun Lun / Olympus",
    nameES: "Bl 60 / Kun Lun / el Olimpo",
  },
  {
    id: 60,
    title: "Bl 61 / Pu Can / der Diener grüßt",
    nameEN: "Bl 61 / Pu Can / the servant greets",
    nameES: "Bl 61 / Pu Can / el sirviente saluda",
  },
  {
    id: 61,
    title: "Bl 62 / Shen Mai, Shen Mo / Basis emporziehender  Leitbahn",
    nameEN: "Bl 62 / Shen Mai, Shen Mo / base of the ascending line",
    nameES: "Bl 62 / Shen Mai, Shen Mo / base de la línea ascendente",
  },
  {
    id: 62,
    title: "Bl 63 / Jin Men / das eherne Tor",
    nameEN: "Bl 63 / Jin Men / the brazen gate",
    nameES: "Bl 63 / Jin Men / la puerta de bronce",
  },
  {
    id: 63,
    title: "Bl 64 / Jing Gu / Pyramidenknochen",
    nameEN: "Bl 64 / Jing Gu / pyramid bone",
    nameES: "Bl 64 / Jing Gu / hueso de la pirámide",
  },
  {
    id: 64,
    title: "Bl 65 / Shu Gu / der geschnürte Knochen",
    nameEN: "Bl 65 / Shu Gu / the laced bone",
    nameES: "Bl 65 / Shu Gu / el hueso de encaje",
  },
  {
    id: 65,
    title: "Bl 66 / Zu Tong Gu / d verbindende Tal der Basen-Leitbahn",
    nameEN: "Bl 66 / Zu Tong Gu / the connecting valley of the base line",
    nameES: "Bl 66 / Zu Tong Gu / el valle conector de la línea base",
  },
  {
    id: 66,
    title: "Bl 67 / Zhi Yin / d äußerste Yin",
    nameEN: "Bl 67 / Zhi Yin / the outermost Yin",
    nameES: "Bl 67 / Zhi Yin / el Yin más exterior",
  },
];

export const Niere = [
  {
    id: 0,
    title: "Ni 01 / Yong Quan / emporsprudelnde Quelle",
    nameEN: "Ni 01 / Yong Quan / gushing spring",
    nameES: "Ni 01 / Yong Quan / manantial efusivo",
  },
  {
    id: 1,
    title: "Ni 02 / Long Quan, Ran Gu / Drachenquelle",
    nameEN: "Ni 02 / Long Quan, Ran Gu / Dragon Spring",
    nameES: "Ni 02 / Long Quan, Ran Gu / Manantial del Dragón",
  },
  {
    id: 2,
    title: "Ni 03 / Tai Xi / Mächtiger Wasserlauf",
    nameEN: "Ni 03 / Tai Xi / Mighty watercourse",
    nameES: "Ni 03 / Tai Xi / Poderoso curso de agua",
  },
  {
    id: 3,
    title: "Ni 04 / Da Zhong / große Glocke",
    nameEN: "Ni 04 / Da Zhong / big bell",
    nameES: "Ni 04 / Da Zhong / gran campana",
  },
  {
    id: 4,
    title: "Ni 05 / Shui Quan / Wasserquelle",
    nameEN: "Ni 05 / Shui Quan / Water spring",
    nameES: "Ni 05 / Shui Quan / Manantial de agua",
  },
  {
    id: 5,
    title: "Ni 06 / Zhao Hai / das Meer der Erhellung",
    nameEN: "Ni 06 / Zhao Hai / the sea of illumination",
    nameES: "Ni 06 / Zhao Hai / el mar de la iluminación",
  },
  {
    id: 6,
    title: "Ni 07 / Fu Liu / der zurückfließende Strom",
    nameEN: "Ni 07 / Fu Liu / the flowing back stream",
    nameES: "Ni 07 / Fu Liu / el arroyo que fluye hacia atrás",
  },
  {
    id: 7,
    title: "Ni 08 / Jiao Xin / die sich treffenden Boten",
    nameEN: "Ni 08 / Jiao Xin / the meeting messengers",
    nameES: "Ni 08 / Jiao Xin / los mensajeros del encuentro",
  },
  {
    id: 8,
    title: "Ni 09 / Zhu Bin / das abgeteufte Ufer",
    nameEN: "Ni 09 / Zhu Bin / the receding shore",
    nameES: "Ni 09 / Zhu Bin / la orilla que retrocede",
  },
  {
    id: 9,
    title: "Ni 10 / Yin Gu / Tal des Yin",
    nameEN: "Ni 10 / Yin Gu / Valley of Yin",
    nameES: "Ni 10 / Yin Gu / Valle de Yin",
  },
  {
    id: 10,
    title: "Ni 11 / Heng Gu / Querknochen",
    nameEN: "Ni 11 / Heng Gu / transverse bone",
    nameES: "Ni 11 / Heng Gu / hueso transversal",
  },
  {
    id: 11,
    title: "Ni 12 / Da He, Ying Guan / Passtor des Yin",
    nameEN: "Ni 12 / Da He, Ying Guan / Passing Gate of Yin",
    nameES: "Ni 12 / Da He, Ying Guan / Puerta de paso de Yin",
  },
  {
    id: 12,
    title: "Ni 13 / Qi Xue / Punkt des Qi",
    nameEN: "Ni 13 / Qi Xue / point of Qi",
    nameES: "Ni 13 / Qi Xue / punto de Qi",
  },
  {
    id: 13,
    title: "Ni 14 / Si Man / das vierte Volle",
    nameEN: "Ni 14 / Si Man / the fourth full",
    nameES: "Ni 14 / Si Man / el cuarto lleno",
  },
  {
    id: 14,
    title: "Ni 15 / Zhong Zhu / mittlerer Zufluß",
    nameEN: "Ni 15 / Zhong Zhu / middle tributary",
    nameES: "Ni 15 / Zhong Zhu / afluente medio",
  },
  {
    id: 15,
    title: "Ni 16 / Huang Shu / Einflusspunkt der Eingeweide",
    nameEN: "Ni 16 / Huang Shu / point of influence of the viscera",
    nameES: "Ni 16 / Huang Shu / punto de influencia de las vísceras",
  },
  {
    id: 16,
    title: "Ni 17 / Shang Qu / hohe Krummung",
    nameEN: "Ni 17 / Shang Qu / high curvature",
    nameES: "Ni 17 / Shang Qu / curvatura alta",
  },
  {
    id: 17,
    title: "Ni 18 / Shi Guan / steinernes Paßtor",
    nameEN: "Ni 18 / Shi Guan / stone pass gate",
    nameES: "Ni 18 / Shi Guan / puerta de paso de piedra",
  },
  {
    id: 18,
    title: "Ni 19 / Yin Du / Yin-Sammlung / -Hauptstadt / -des Inneren",
    nameEN: "Ni 19 / Yin Du / yin collection / capital / of the interior",
    nameES: "Ni 19 / Yin Du / colección yin / capital / del interior",
  },
  {
    id: 19,
    title: "Ni 20 / Fu Tong Gu / das verbindende Tal zur Nieren",
    nameEN: "Ni 20 / Fu Tong Gu / the connecting valley to the kidneys",
    nameES: "Ni 20 / Fu Tong Gu / el valle de conexión con los riñones",
  },
  {
    id: 20,
    title: "Ni 21 / You Mén / das abgelegene Tor",
    nameEN: "Ni 21 / You Men / the remote gate",
    nameES: "Ni 21 / You Men / la puerta remota",
  },
  {
    id: 21,
    title: "Ni 22 / Bu Lang / Wandelgalerie",
    nameEN: "Ni 22 / Bu Lang / the changing gallery",
    nameES: "Ni 22 / Bu Lang / la galería cambiante",
  },
  {
    id: 22,
    title: "Ni 23 / Shen Feng / Altar der Gottheiten",
    nameEN: "Ni 23 / Shen Feng / Altar of the Deities",
    nameES: "Ni 23 / Shen Feng / Altar de las Deidades",
  },
  {
    id: 23,
    title: "Ni 24 / Ling Xu, Ling Qiang / Mauer der Struktivkraft",
    nameEN: "Ni 24 / Ling Xu, Ling Qiang / Wall of Structural Power",
    nameES: "Ni 24 / Ling Xu, Ling Qiang / Muro del Poder Estructural",
  },
  {
    id: 24,
    title: "Ni 25 / Shen Cang / Speicher der konstellierenden Kraft",
    nameEN: "Ni 25 / Shen Cang / Storehouse of Constellating Power",
    nameES: "Ni 25 / Shen Cang / Almacén del Poder Constelador",
  },
  {
    id: 25,
    title: "Ni 26 / Yu Zhong / der die Mitte zur Blüte bringt",
    nameEN: "Ni 26 / Yu Zhong / who brings the center to bloom",
    nameES: "Ni 26 / Yu Zhong / que hace florecer el centro",
  },
  {
    id: 26,
    title: "Ni 27 / Shu Fu / Versammlungshalle der Einflußnahme",
    nameEN: "Ni 27 / Shu Fu / Assembly Hall of Influence",
    nameES: "Ni 27 / Shu Fu / Sala de reunión de la influencia",
  },
];

export const Herzbeutel = [
  {
    id: 0,
    title: "Hb 1 / Tian Chi / Teich des Himmels",
    nameEN: "Hb 1 / Tian Chi / Pond of Heaven",
    nameES: "Hb 1 / Tian Chi / Estanque del Cielo",
  },
  {
    id: 1,
    title: "Hb 2 / Tian Quan / Quelle des Himmels",
    nameEN: "Hb 2 / Tian Quan / Spring of Heaven",
    nameES: "Hb 2 / Tian Quan / Manantial del Cielo",
  },
  {
    id: 2,
    title: "Hb 3 / Qu Ze / der gekrümmte Moorsee",
    nameEN: "Hb 3 / Qu Ze / the curved moor lake",
    nameES: "Hb 3 / Qu Ze / el lago curvo del páramo",
  },
  {
    id: 3,
    title: "Hb 4 / Xi Men / Spaltpforte",
    nameEN: "Hb 4 / Xi Men / rift gate",
    nameES: "Hb 4 / Xi Men / puerta de la grieta",
  },
  {
    id: 4,
    title: "Hb 5 / Jian Shi / Zwischenträger",
    nameEN: "Hb 5 / Jian Shi / intermediate carrier",
    nameES: "Hb 5 / Jian Shi / portador intermedio",
  },
  {
    id: 5,
    title: "Hb 6 / Nei Guan / inneresPaßtor",
    nameEN: "Hb 6 / Nei Guan / inner pass gate",
    nameES: "Hb 6 / Nei Guan / puerta de paso interior",
  },
  {
    id: 6,
    title: "Hb 7 / Da Ling / großer Grabhügel",
    nameEN: "Hb 7 / Da Ling / large burial mound",
    nameES: "Hb 7 / Da Ling / gran túmulo funerario",
  },
  {
    id: 7,
    title: "Hb 8 / Lao Gong, ZhangZhong / Mitte des Handteller",
    nameEN: "Hb 8 / Lao Gong, ZhangZhong / center of the palm",
    nameES: "Hb 8 / Lao Gong, ZhangZhong / centro de la palma",
  },
  {
    id: 8,
    title: "Hb 9 / Zhong Chong / mittlere große Straße",
    nameEN: "Hb 9 / Zhong Chong / middle large street",
    nameES: "Hb 9 / Zhong Chong / gran calle central",
  },
];

export const Erwärmer = [
  {
    id: 0,
    title: "3E 01 / Guan Chong / große Straße am Paßtor / Tor-Ansturm",
    nameEN: "3E 01 / Guan Chong / big road at the pass gate / gate assault",
    nameES:
      "3E 01 / Guan Chong / gran camino en la puerta del paso / asalto a la puerta",
  },
  {
    id: 1,
    title: "3E 02 / Yè Mén / Pforte des Saftes",
    nameEN: "3E 02 / Yè Mén / Gate of Juice",
    nameES: "3E 02 / Yè Mén / Puerta de Jugo",
  },
  {
    id: 2,
    title: "3E 03 / Zhong Zhu / mittlere Insel",
    nameEN: "3E 03 / Zhong Zhu / middle island",
    nameES: "3E 03 / Zhong Zhu / isla del medio",
  },
  {
    id: 3,
    title: "3E 04 / Yang Chi / Teich des Yang",
    nameEN: "3E 04 / Yang Chi / Pond of Yang",
    nameES: "3E 04 / Yang Chi / Estanque de Yang",
  },
  {
    id: 4,
    title: "3E 05 / Wai Guan / äußeres Passtor",
    nameEN: "3E 05 / Wai Guan / outer pass gate",
    nameES: "3E 05 / Wai Guan / puerta del paso exterior",
  },
  {
    id: 5,
    title:
      "3E 06 / Zhi Gou oder FeiHu/ der fliegende Tiger / Wassergraben - Abzweigung",
    nameEN: "3E 06 / Zhi Gou or FeiHu / the flying tiger / moat - junction",
    nameES: "3E 06 / Zhi Gou o FeiHu / el tigre volador / foso - cruce",
  },
  {
    id: 6,
    title:
      "3E 07 / Hui Zong / die versammelten Geschlechter / Zulaufende Kanäle",
    nameEN: "3E 07 / Hui Zong / the assembled sexes / Inflowing channels",
    nameES: "3E 07 / Hui Zong / los sexos reunidos / canales fluviales",
  },
  {
    id: 7,
    title: "3E 08 / San Yang Luo oder Tong Men/ Verbindungspforte",
    nameEN: "3E 08 / San Yang Luo or Tong Men / connecting gate",
    nameES: "3E 08 / San Yang Luo o Tong Men / puerta de conexión",
  },
  {
    id: 8,
    title: "3E 09 / Si Du / vierter Abzugsgraben",
    nameEN: "3E 09 / Si Du / fourth drainage ditch",
    nameES: "3E 09 / Si Du / cuarta zanja de drenaje",
  },
  {
    id: 9,
    title: "3E 10 / Tian Jing / Himmelsbrunnen / himmlische Quelle",
    nameEN: "3E 10 / Tian Jing / Heavenly Well / Heavenly Spring",
    nameES: "3E 10 / Tian Jing / Pozo Celestial / Manantial Celestial",
  },
  {
    id: 10,
    title: "3E 11 / Qing Leng Yuan (Qing Leng Quan) / klare - kühle Quelle",
    nameEN: "3E 11 / Qing Leng Yuan (Qing Leng Quan) / clear - cool spring",
    nameES:
      "3E 11 / Qing Leng Yuan (Qing Leng Quan) / manantial claro y fresco",
  },
  {
    id: 11,
    title: "3E 12 / Xiao Luo / Punkt der zerstreut",
    nameEN: "3E 12 / Xiao Luo / point of dispersion",
    nameES: "3E 12 / Xiao Luo / punto de dispersión",
  },
  {
    id: 12,
    title: "3E 13 / Nao Hui / Versammlungspunkt am Oberarm / auf der Schulter",
    nameEN:
      "3E 13 / Nao Hui / Gathering point on the upper arm / on the shoulder",
    nameES:
      "3E 13 / Nao Hui / punto de reunión en la parte superior del brazo / en el hombro",
  },
  {
    id: 13,
    title:
      "3E 14 / Jian Liao (Jian Jiao)/ Kellerloch der Schulter, Schulterspalte",
    nameEN:
      "3E 14 / Jian Liao (Jian Jiao)/ Cellar hole of the shoulder, shoulder cleft",
    nameES:
      "3E 14 / Jian Liao (Jian Jiao)/ Agujero del hombro, hendidura del hombro",
  },
  {
    id: 14,
    title:
      "3E 15 / Tian Liao (Tian Jiao)/ Kellerloch des Himmels, Himmelsspalte",
    nameEN: "3E 15 / Tian Liao (Tian Jiao)/ Cellar hole of the sky, sky cleft",
    nameES:
      "3E 15 / Tian Liao (Tian Jiao)/ Agujero de la bodega del cielo, hendidura del cielo",
  },
  {
    id: 15,
    title: "3E 16 / Tian You / Öffnung des Himmels",
    nameEN: "3E 16 / Tian You / Opening of the sky",
    nameES: "3E 16 / Tian You / Apertura del cielo",
  },
  {
    id: 16,
    title: "3E 17 / Yi Feng / Schutzschirm gegen Wind, Windschild",
    nameEN: "3E 17 / Yi Feng / Shield against wind, wind shield",
    nameES:
      "3E 17 / Yi Feng / Escudo contra el viento, escudo contra el viento",
  },
  {
    id: 17,
    title: "3E 18 / Chi Mai (Ji Mo, Ji Mai, Chi Mai)/ Punkt der Spasmen",
    nameEN: "3E 18 / Chi Mai (Ji Mo, Ji Mai, Chi Mai)/ Point of spasms",
    nameES: "3E 18 / Chi Mai (Ji Mo, Ji Mai, Chi Mai)/ Punto de espasmos",
  },
  {
    id: 18,
    title: "3E 19 / Lu Xi / Punkt hinter dem Ohr",
    nameEN: "3E 19 / Lu Xi / point behind the ear",
    nameES: "3E 19 / Lu Xi / Punto detrás de la oreja",
  },
  {
    id: 19,
    title: "3E 20 / Jiao Sun / oberes Schläfenbein",
    nameEN: "3E 20 / Jiao Sun / upper temporal bone",
    nameES: "3E 20 / Jiao Sun / hueso temporal superior",
  },
  {
    id: 20,
    title: "3E 21 / Ermen / Pforte des Ohres / Tor des Ohres",
    nameEN: "3E 21 / Ermen / gate of the ear / gate of the ear",
    nameES: "3E 21 / Ermen / puerta de la oreja / puerta de la oreja",
  },
  {
    id: 21,
    title: "3E 22 / He Jiao (He Liao)/ Kellerloch der Harmonie",
    nameEN: "3E 22 / He Jiao (He Liao)/ cellar hole of harmony",
    nameES: "3E 22 / He Jiao (He Liao)/ agujero del sótano de la armonía",
  },
  {
    id: 22,
    title: "3E 23 / Si Zhu Kong / Geigen+Flöten / Seidenbambusloch",
    nameEN: "3E 23 / Si Zhu Kong / Violins+Flutes / Silk Bamboo Hole",
    nameES:
      "3E 23 / Si Zhu Kong / violines+flautas / agujero del bambú de seda",
  },
];

export const Gallenblase = [
  {
    id: 0,
    title: "Gb 1 / Tong Ziliao / Kellerloch der Pupille / Pupillenspalte",
    nameEN: "Gb 1 / Tong Ziliao / Cellar hole of the pupil / Pupil fissure",
    nameES: "Gb 1 / Tong Ziliao / Agujero de la pupila / Fisura de la pupila",
  },
  {
    id: 1,
    title:
      "Gb 2 / Ting Hui / Versammlungspunkt fürs Gehör / Treffpunkt des Hörens",
    nameEN:
      "Gb 2 / Ting Hui / meeting point for hearing / meeting point of hearing",
    nameES:
      "Gb 2 / Ting Hui / punto de encuentro de la audición / punto de encuentro de la audición",
  },
  {
    id: 2,
    title: "Gb 3 / Shang Guan / oberes Passtor",
    nameEN: "Gb 3 / Shang Guan / upper pass gate",
    nameES: "Gb 3 / Shang Guan / puerta de paso superior",
  },
  {
    id: 3,
    title: "Gb 4 / Han Yan / im Winkel des Keilbeinflügels",
    nameEN: "Gb 4 / Han Yan / at the angle of the sphenoid wing",
    nameES: "Gb 4 / Han Yan / en el ángulo del ala del esfenoides",
  },
  {
    id: 4,
    title: "Gb 5 / Xuan Lu / Punkt der Medulla",
    nameEN: "Gb 5 / Xuan Lu / point of the medulla",
    nameES: "Gb 5 / Xuan Lu / punto de la médula",
  },
  {
    id: 5,
    title: "Gb 6 / Xuan Li / der aufgehängte Bissen",
    nameEN: "Gb 6 / Xuan Li / the suspended bite",
    nameES: "Gb 6 / Xuan Li / la mordida suspendida",
  },
  {
    id: 6,
    title: "Gb 7 / Qu Bin / Bogen des Schläfenhaars",
    nameEN: "Gb 7 / Qu Bin / arch of the temporal hair",
    nameES: "Gb 7 / Qu Bin / arco del vello temporal",
  },
  {
    id: 7,
    title: "Gb 8 / Shuai Gu / der obere Punkt des Ohres / führendes Tal",
    nameEN: "Gb 8 / Shuai Gu / the upper point of the ear / leading valley",
    nameES: "Gb 8 / Shuai Gu / el punto superior de la oreja / valle principal",
  },
  {
    id: 8,
    title:
      "Gb 9 / Tian Chong / große Strasse des Himmels / Durchdringung des Himmels",
    nameEN:
      "Gb 9 / Tian Chong / great road of the sky / penetration of the sky",
    nameES: "Gb 9 / Tian Chong / gran camino del cielo / penetración del cielo",
  },
  {
    id: 9,
    title: "Gb 10 / Fu Bai / oberflächliche Weisse",
    nameEN: "Gb 10 / Fu Bai / superficial whiteness",
    nameES: "Gb 10 / Fu Bai / blancura superficial",
  },
  {
    id: 10,
    title: "Gb 11 / Tou QiaoYin / das ins Yin eindringen lässt",
    nameEN: "Gb 11 / Tou QiaoYin / penetrating the yin",
    nameES: "Gb 11 / Tou QiaoYin / penetración del yin",
  },
  {
    id: 11,
    title: "Gb 12 / Wan Gu / Processus mastoideus / ganzer Knochen",
    nameEN: "Gb 12 / Wan Gu / processus mastoideus / whole bone",
    nameES: "Gb 12 / Wan Gu / processus mastoideus / hueso entero",
  },
  {
    id: 12,
    title: "Gb 13 / Ben Shen / konstellierende Kraft der (Geistes-) Wurzel",
    nameEN: "Gb 13 / Ben Shen / constellating power of the (spirit) root",
    nameES: "Gb 13 / Ben Shen / poder constelador de la raíz (espiritual)",
  },
  {
    id: 13,
    title: "Gb 14 / Yang Bai / die Weine des Yang / Yang Weisse",
    nameEN: "Gb 14 / Yang Bai / the wines of Yang / Yang white",
    nameES: "Gb 14 / Yang Bai / los vinos de Yang / Yang blanco",
  },
  {
    id: 14,
    title: "Gb 15 / (Tou) Lin Qi / am Rand der Tränen / fliessende Tränen",
    nameEN: "Gb 15 / (Tou) Lin Qi / at the edge of tears / flowing tears",
    nameES:
      "Gb 15 / (Tou) Lin Qi / al borde de las lágrimas / lágrimas que fluyen",
  },
  {
    id: 15,
    title: "Gb 16 / Mu Chuang / Fenster des Auges",
    nameEN: "Gb 16 / Mu Chuang / window of the eye",
    nameES: "Gb 16 / Mu Chuang / ventana del ojo",
  },
  {
    id: 16,
    title: "Gb 17 / Zheng Ying / des Heeres Hauptlager",
    nameEN: "Gb 17 / Zheng Ying / of the army's main camp",
    nameES: "Gb 17 / Zheng Ying / del campamento principal del ejército",
  },
  {
    id: 17,
    title:
      "Gb 18 / Cheng Ling / Empfänger des Geistes, der die Struktivkraft aufnimmt",
    nameEN:
      "Gb 18 / Cheng Ling / Receiver of the spirit that absorbs structural force",
    nameES:
      "Gb 18 / Cheng Ling / Receptor del espíritu que absorbe la fuerza estructural",
  },
  {
    id: 18,
    title: "Gb 19 / Nao Kong / Spalte zum Hirn",
    nameEN: "Gb 19 / Nao Kong / fissure to the brain",
    nameES: "Gb 19 / Nao Kong / fisura del cerebro",
  },
  {
    id: 19,
    title: "Gb 20 / Feng Chi / Teich des Windes / Windteich",
    nameEN: "Gb 20 / Feng Chi / Pond of Wind / Wind Pond",
    nameES: "Gb 20 / Feng Chi / Estanque del Viento / Estanque del Viento",
  },
  {
    id: 20,
    title: "Gb 21 / Jian Jing / Schulterbrunnen",
    nameEN: "Gb 21 / Jian Jing / Shoulder well",
    nameES: "Gb 21 / Jian Jing / pozo del hombro",
  },
  {
    id: 21,
    title: "Gb 22 / Yuan Ye / Achselhöhlenpforte",
    nameEN: "Gb 22 / Yuan Ye / armpit orifice",
    nameES: "Gb 22 / Yuan Ye / orificio de la axila",
  },
  {
    id: 22,
    title: "Gb 23 / Zhe Jin / Vorhof der Achselhöhle",
    nameEN: "Gb 23 / Zhe Jin / Atrium of the armpit",
    nameES: "Gb 23 / Zhe Jin / Atrio de la axila",
  },
  {
    id: 23,
    title: "Gb 24 / Ri Yue / Sonne und Mond",
    nameEN: "Gb 24 / Ri Yue / Sun and moon",
    nameES: "Gb 24 / Ri Yue / Sol y luna",
  },
  {
    id: 24,
    title: "Gb 25 / Jing Men / Pforte der Pyramide / Hauptstadt-Tor",
    nameEN: "Gb 25 / Jing Men / Gate of the Pyramid / Capital Gate",
    nameES: "Gb 25 / Jing Men / Puerta de la Pirámide / Puerta Capital",
  },
  {
    id: 25,
    title: "Gb 26 / Dai Mai / Punkt der Gürtelbahn",
    nameEN: "Gb 26 / Dai Mai / Point of the Belt Railway",
    nameES: "Gb 26 / Dai Mai / Punto del Cinturón Ferroviario",
  },
  {
    id: 26,
    title: "Gb 27 / Wu Shu / Fünfter Angelpunkt",
    nameEN: "Gb 27 / Wu Shu / Fifth pivot point",
    nameES: "Gb 27 / Wu Shu / Quinto punto de giro",
  },
  {
    id: 27,
    title: "Gb 28 / Wei Dao / der zurückgehaltene Weg",
    nameEN: "Gb 28 / Wei Dao / the way held back",
    nameES: "Gb 28 / Wei Dao / el camino retenido",
  },
  {
    id: 28,
    title: "Gb 29 / Ju Liao / Wohnkellerloch",
    nameEN: "Gb 29 / Ju Liao / the residential cellar hole",
    nameES: "Gb 29 / Ju Liao / el agujero de la bodega residencial",
  },
  {
    id: 29,
    title: "Gb 30 / Huan Tiao / Angelpunkt des Femurs / Springender Kreis",
    nameEN: "Gb 30 / Huan Tiao / Pivot of the femur / Jumping circle",
    nameES: "Gb 30 / Huan Tiao / Pivote del fémur / Círculo de salto",
  },
  {
    id: 30,
    title: "Gb 31 / Feng Shi / Marktplatz der Winde",
    nameEN: "Gb 31 / Feng Shi / Marketplace of the Winds",
    nameES: "Gb 31 / Feng Shi / Mercado de los Vientos",
  },
  {
    id: 31,
    title: "Gb 32 / Zhong Du / mittlerer Abzugsgraben",
    nameEN: "Gb 32 / Zhong Du / middle drainage ditch",
    nameES: "Gb 32 / Zhong Du / Zanja de drenaje central",
  },
  {
    id: 32,
    title: "Gb 33 / Xi Yang Guan / Yangtor am Knie",
    nameEN: "Gb 33 / Xi Yang Guan / Yangtor at the knee",
    nameES: "Gb 33 / Xi Yang Guan / Yangtor en la rodilla",
  },
  {
    id: 33,
    title:
      "Gb 34 / Yang Ling Quan / Quelle am sonnenbeschienenen Yanghügel / Grabhügel",
    nameEN:
      "Gb 34 / Yang Ling Quan / spring at the sunlit Yang Hill / burial mound",
    nameES:
      "Gb 34 / Yang Ling Quan / manantial en la colina Yang iluminada por el sol / túmulo funerario",
  },
  {
    id: 34,
    title: "Gb 35 / Yang Jiao / Yang-Kreuzung / Vereinigung im Yang",
    nameEN: "Gb 35 / Yang Jiao / Yang junction / Union in Yang",
    nameES: "Gb 35 / Yang Jiao / cruce de Yang / Unión en Yang",
  },
  {
    id: 35,
    title: "Gb 36 / Wai Qiu / äußerer Hügel",
    nameEN: "Gb 36 / Wai Qiu / outer hill",
    nameES: "Gb 36 / Wai Qiu / colina exterior",
  },
  {
    id: 36,
    title: "Gb 37 / Guang Ming / Glanz und Licht",
    nameEN: "Gb 37 / Guang Ming / splendor and light",
    nameES: "Gb 37 / Guang Ming / esplendor y luz",
  },
  {
    id: 37,
    title: "Gb 38 / Yang Fu / Stützen des Yang / Yang",
    nameEN: "Gb 38 / Yang Fu / Supporting the Yang / Yang",
    nameES: "Gb 38 / Yang Fu / Apoyo al Yang / Yang",
  },
  {
    id: 38,
    title: "Gb 39 / Xuan Zhong / herabhängende Glocke",
    nameEN: "Gb 39 / Xuan Zhong / hanging bell",
    nameES: "Gb 39 / Xuan Zhong / campana colgante",
  },
  {
    id: 39,
    title: "Gb 40 / Qiu Xu / Feld am Hügel / Hügelruinen",
    nameEN: "Gb 40 / Qiu Xu / Field on the hill / Hill ruins",
    nameES: "Gb 40 / Qiu Xu / Campo en la colina / Ruinas de la colina",
  },
  {
    id: 40,
    title: "Gb 41 / Zu Lin Qi / am Rand der Tränen des Fußes",
    nameEN: "Gb 41 / To Lin Qi / at the edge of the tears of the foot",
    nameES: "Gb 41 / A Lin Qi / al borde de las lágrimas del pie",
  },
  {
    id: 41,
    title: "Gb 42 / Diwu Hui / der fünfte Versammlungspunkt der Erde",
    nameEN: "Gb 42 / Diwu Hui / the fifth gathering point of the earth",
    nameES: "Gb 42 / Diwu Hui / el quinto punto de reunión de la tierra",
  },
  {
    id: 42,
    title: "Gb 43 / Xia Xi / der eingezwängte Wasserlauf",
    nameEN: "Gb 43 / Xia Xi / the squeezed watercourse",
    nameES: "Gb 43 / Xia Xi / el curso de agua exprimido",
  },
  {
    id: 43,
    title: "Gb 44 / Zu Qiao Yin / Punkt, der ins Yin eindringen läßt",
    nameEN: "Gb 44 / To Qiao Yin / point that allows penetration into Yin",
    nameES: "Gb 44 / To Qiao Yin / punto que permite la penetración en el Yin",
  },
];

export const Leber = [
  {
    id: 0,
    title: "Le 01 / Da Dun / große Schale / Grosses Dickes",
    nameEN: "Le 01 / Da Dun / big bowl / big fat",
    nameES: "Le 01 / Da Dun / cuenco grande / grasa grande",
  },
  {
    id: 1,
    title:
      "Le 02 / Xing Jian / Zwischenraum des Gehens / vorübergehendes Dazwischentreten",
    nameEN:
      "Le 02 / Xing Jian / intermediate space of walking / temporary stepping in between",
    nameES:
      "Le 02 / Xing Jian / espacio intermedio de caminar / paso temporal en medio",
  },
  {
    id: 2,
    title: "Le 03 / Tai Chong / die mächtige grosse Strasse, Grosses Branden",
    nameEN: "Le 03 / Tai Chong / the mighty great road, great burning",
    nameES: "Le 03 / Tai Chong / el poderoso gran camino, gran ardor",
  },
  {
    id: 3,
    title: "Le 04 / Zhong Feng / Mittlerer Altar, Mittleres Siegel",
    nameEN: "Le 04 / Zhong Feng / Middle Altar, Middle Seal",
    nameES: "Le 04 / Zhong Feng / Altar del medio, sello del medio",
  },
  {
    id: 4,
    title: "Le 05 / Li Gou / Kanal des Holzwurms / Kürbisgraben",
    nameEN: "Le 05 / Li Gou / Channel of the Woodworm / Pumpkin Ditch",
    nameES: "Le 05 / Li Gou / Canal de la Carcoma / Zanja de la Calabaza",
  },
  {
    id: 5,
    title: "Le 06 / Zhong Du / Mittlere Stadt",
    nameEN: "Le 06 / Zhong Du / Middle City",
    nameES: "Le 06 / Zhong Du / Ciudad del Medio",
  },
  {
    id: 6,
    title: "Le 07 / Xi Guan / Passtor des Knies",
    nameEN: "Le 07 / Xi Guan / Gate of the Knee",
    nameES: "Le 07 / Xi Guan / Puerta de la Rodilla",
  },
  {
    id: 7,
    title: "Le 08 / Qu Quan / Quelle an der Krümmung",
    nameEN: "Le 08 / Qu Quan / Spring at the Bend",
    nameES: "Le 08 / Qu Quan / Primavera en el Recodo",
  },
  {
    id: 8,
    title: "Le 09 / Yin Bao / Punkt des Uterus",
    nameEN: "Le 09 / Yin Bao / Point of the Uterus",
    nameES: "Le 09 / Yin Bao / Punta del Útero",
  },
  {
    id: 9,
    title: "Le 10 / Wu Li / Der fünfte Weiler am Fuss",
    nameEN: "Le 10 / Wu Li / The fifth hamlet at the foot",
    nameES: "Le 10 / Wu Li / La quinta aldea al pie",
  },
  {
    id: 10,
    title: "Le 11 / Yin Han / Yin-Rand / -Winkel / -Engpass",
    nameEN: "Le 11 / Yin Han / Yin edge / angle / bottleneck",
    nameES: "Le 11 / Yin Han / Borde Yin / Ángulo / Cuello de botella",
  },
  {
    id: 11,
    title: "Le 12 / Ji Mai / der erregte Puls",
    nameEN: "Le 12 / Ji Mai / the excited pulse",
    nameES: "Le 12 / Ji Mai / el pulso excitado",
  },
  {
    id: 12,
    title: "Le 13 / Zhang Men / dekorierte Pforte",
    nameEN: "Le 13 / Zhang Men / decorated gate",
    nameES: "Le 13 / Zhang Men / puerta decorada",
  },
  {
    id: 13,
    title: "Le 14 / Qi Men, Gan Mu / Zusammenkunftspunkt des LeberKreises",
    nameEN: "Le 14 / Qi Men, Gan Mu / meeting point of the liver circle",
    nameES: "Le 14 / Qi Men, Gan Mu / punto de encuentro del círculo hepático",
  },
];

export const DuMai = [
  {
    id: 0,
    title: "Du 01 / Chang Qiang / Wachstum und Stärke",
    nameEN: "Du 01 / Chang Qiang / growth and strength",
    nameES: "Du 01 / Chang Qiang / crecimiento y fuerza",
  },
  {
    id: 1,
    title: "Du 02 / Yao Shu / Einflußpunkt der Lenden",
    nameEN: "Du 02 / Yao Shu / point of influence of the loins",
    nameES: "Du 02 / Yao Shu / punto de influencia de los lomos",
  },
  {
    id: 2,
    title: "Du 03 / Yao Yang Guan / das Yang",
    nameEN: "Du 03 / Yao Yang Guan / the Yang",
    nameES: "Du 03 / Yao Yang Guan / el Yang",
  },
  {
    id: 3,
    title: "Du 04 / Ming Men / Pforte des Lebensloses",
    nameEN: "Du 04 / Ming Men / gate of the lifeless",
    nameES: "Du 04 / Ming Men / puerta de los sin vida",
  },
  {
    id: 4,
    title: "Du 05 / Xuan Shu / schwebender Angelpunkt",
    nameEN: "Du 05 / Xuan Shu / floating pivot",
    nameES: "Du 05 / Xuan Shu / pivote flotante",
  },
  {
    id: 5,
    title: "Du 06 / Ji Zhong / Mitte des Rückgrat",
    nameEN: "Du 06 / Ji Zhong / center of the spine",
    nameES: "Du 06 / Ji Zhong / centro de la columna vertebral",
  },
  {
    id: 6,
    title: "Du 07 / Zhong Shu / mittlerer Angelpunkt",
    nameEN: "Du 07 / Zhong Shu / middle pivot",
    nameES: "Du 07 / Zhong Shu / pivote central",
  },
  {
    id: 7,
    title: "Du 08 / Jin Suo / die geschrumpften Bewegungselemente",
    nameEN: "Du 08 / Jin Suo / the shrunken movement elements",
    nameES: "Du 08 / Jin Suo / los elementos de movimiento encogidos",
  },
  {
    id: 8,
    title: "Du 09 / Zhi Yang / äußerstes Yang",
    nameEN: "Du 09 / Zhi Yang / extreme Yang",
    nameES: "Du 09 / Zhi Yang / Yang extremo",
  },
  {
    id: 9,
    title: "Du 10 / Ling Tai / Terrasse der Struktivkraft",
    nameEN: "Du 10 / Ling Tai / terrace of structural force",
    nameES: "Du 10 / Ling Tai / terraza de la fuerza estructural",
  },
  {
    id: 10,
    title: "Du 11 / Shen Dao / Weg der konstellierenden Kraft",
    nameEN: "Du 11 / Shen Dao / path of constellating power",
    nameES: "Du 11 / Shen Dao / camino del poder constelador",
  },
  {
    id: 11,
    title: "Du 12 / Shen Zhu / Säule der Person",
    nameEN: "Du 12 / Shen Zhu / Pillar of the Person",
    nameES: "Du 12 / Shen Zhu / pilar de la persona",
  },
  {
    id: 12,
    title: "Du 13 / Tao Dao / Weg der Töpfer",
    nameEN: "Du 13 / Tao Dao / Way of the Potter",
    nameES: "Du 13 / Tao Dao / camino del alfarero",
  },
  {
    id: 13,
    title: "Du 14 / Da Zhui / Punkt aller Strapazen",
    nameEN: "Du 14 / Da Zhui / Point of all hardships",
    nameES: "Du 14 / Da Zhui / Punto de todas las dificultades",
  },
  {
    id: 14,
    title: "Du 15 / Ya Men / Pforte der Stummheit",
    nameEN: "Du 15 / Ya Men / Gate of Silence",
    nameES: "Du 15 / Ya Men / Puerta del Silencio",
  },
  {
    id: 15,
    title: "Du 16 / Feng Fu / Versammlungshalle des Windes",
    nameEN: "Du 16 / Feng Fu / Assembly hall of the wind",
    nameES: "Du 16 / Feng Fu / Sala de reunión del viento",
  },
  {
    id: 16,
    title: "Du 17 / Nao Hu / Tür des Hirn",
    nameEN: "Du 17 / Nao Hu / Door of the Brain",
    nameES: "Du 17 / Nao Hu / Puerta del Cerebro",
  },
  {
    id: 17,
    title: "Du 18 / Qiang Jian / Zwischenraum der Kraft",
    nameEN: "Du 18 / Qiang Jian / Intermediate Space of Power",
    nameES: "Du 18 / Qiang Jian / Espacio intermedio del poder",
  },
  {
    id: 18,
    title: "Du 19 / Hou Ding / hinteres Schädeldach",
    nameEN: "Du 19 / Hou Ding / rear roof of the skull",
    nameES: "Du 19 / Hou Ding / Techo posterior del cráneo",
  },
  {
    id: 19,
    title: "Du 20 / Bai Hui / Zusammenkunft aller Leitbahnen",
    nameEN: "Du 20 / Bai Hui / meeting of all the channels",
    nameES: "Du 20 / Bai Hui / reunión de todos los canales",
  },
  {
    id: 20,
    title: "Du 21 / Qian Ding / vorderes Schädeldach",
    nameEN: "Du 21 / Qian Ding / front cranial roof",
    nameES: "Du 21 / Qian Ding / techo anterior del cráneo",
  },
  {
    id: 21,
    title: "Du 22 / Xin Hui / grosse Fontanelle",
    nameEN: "Du 22 / Xin Hui / large fontanel",
    nameES: "Du 22 / Xin Hui / fontanela grande",
  },
  {
    id: 22,
    title: "Du 23 / Shang Xing / oberer Stern",
    nameEN: "Du 23 / Shang Xing / upper star",
    nameES: "Du 23 / Shang Xing / estrella superior",
  },
  {
    id: 23,
    title: "Du 24 / Shen Ting / Vorhalle der konstellierenden Kraft",
    nameEN: "Du 24 / Shen Ting / vestibule of the constellating power",
    nameES: "Du 24 / Shen Ting / vestíbulo del poder constelador",
  },
  {
    id: 24,
    title: "Du 25 / Su Jiao, SuLiao, Mian Wang / der König des Gesichts",
    nameEN: "Du 25 / Su Jiao, SuLiao, Mian Wang / the king of the face",
    nameES: "Du 25 / Su Jiao, SuLiao, Mian Wang / el rey de la cara",
  },
  {
    id: 25,
    title: "Du 26 / Ren Zhong, Shui Gou / Wassergraben",
    nameEN: "Du 26 / Ren Zhong, Shui Gou / moat",
    nameES: "Du 26 / Ren Zhong, Shui Gou / foso",
  },
  {
    id: 26,
    title: "Du 27 / Dui Duan / Vorgebirge der Heiterkeit",
    nameEN: "Du 27 / Dui Duan / foothills of serenity",
    nameES: "Du 27 / Dui Duan / estribaciones de la serenidad",
  },
  {
    id: 27,
    title: "Du 28 / Yin Jiao / Verbindung auf dem Zahnfleisch",
    nameEN: "Du 28 / Yin Jiao / connection on the gums",
    nameES: "Du 28 / Yin Jiao / conexión en las encías",
  },
];

export const RenMai = [
  {
    id: 0,
    title: "Ren 01 / Hui Yin / Zusammenkunft des Yin",
    nameEN: "Ren 01 / Hui Yin / Gathering of the Yin",
    nameES: "Ren 01 / Hui Yin / Reunión del Yin",
  },
  {
    id: 1,
    title: "Ren 02 / Qu Gu / der gekrümmte Knochen",
    nameEN: "Ren 02 / Qu Gu / the curved bone",
    nameES: "Ren 02 / Qu Gu / el hueso curvo",
  },
  {
    id: 2,
    title:
      "Ren 03 / Zhong Gji, Pang Guang Mu / Sammlungspunkt für Nierenblasen",
    nameEN:
      "Ren 03 / Zhong Gji, Pang Guang Mu / gathering point for kidney bladders",
    nameES:
      "Ren 03 / Zhong Gji, Pang Guang Mu / punto de reunión de las vejigas renales",
  },
  {
    id: 3,
    title: "Ren 04 / Guan Yuan / das erste der Paßtore",
    nameEN: "Ren 04 / Guan Yuan / the first of the pass gates",
    nameES: "Ren 04 / Guan Yuan / la primera de las puertas de paso",
  },
  {
    id: 4,
    title: "Ren 05 / Shi Men / das steinerne Tor",
    nameEN: "Ren 05 / Shi Men / the stone gate",
    nameES: "Ren 05 / Shi Men / la puerta de piedra",
  },
  {
    id: 5,
    title: "Ren 06 / Qi Hai / Meer des Qi",
    nameEN: "Ren 06 / Qi Hai / Sea of Qi",
    nameES: "Ren 06 / Qi Hai / Mar de Qi",
  },
  {
    id: 6,
    title: "Ren 07 / Yin Jiao / Verknüpfung der Yin-LB",
    nameEN: "Ren 07 / Yin Jiao / linking the Yin-LB",
    nameES: "Ren 07 / Yin Jiao / la unión del Yin-LB",
  },
  {
    id: 7,
    title: "Ren 08 / Shen Que, Qi Zhong / Mitte des Nabels",
    nameEN: "Ren 08 / Shen Que, Qi Zhong / center of the navel",
    nameES: "Ren 08 / Shen Que, Qi Zhong / centro del ombligo",
  },
  {
    id: 8,
    title: "Ren 09 / Shui Fen / die geteilten Wasser",
    nameEN: "Ren 09 / Shui Fen / the divided waters",
    nameES: "Ren 09 / Shui Fen / las aguas divididas",
  },
  {
    id: 9,
    title: "Ren 10 / Xia Wan / Punkt des Magenausganges",
    nameEN: "Ren 10 / Xia Wan / point of the stomach outlet",
    nameES: "Ren 10 / Xia Wan / punto de la salida del estómago",
  },
  {
    id: 10,
    title: "Ren 11 / Jian Li / der errichtete Weiler",
    nameEN: "Ren 11 / Jian Li / the built hamlet",
    nameES: "Ren 11 / Jian Li / la aldea construida",
  },
  {
    id: 11,
    title:
      "Ren 12 / Zhong Wan, Wie Mu / Sammlungspunkt des Magenfunktionskreises",
    nameEN:
      "Ren 12 / Zhong Wan, Wie Mu / Gathering point of the stomach function circle",
    nameES:
      "Ren 12 / Zhong Wan, Wie Mu / punto de reunión del círculo de la función estomacal",
  },
  {
    id: 12,
    title: "Ren 13 / Shang Wan / Ort des Mageneingangs",
    nameEN: "Ren 13 / Shang Wan / place of the stomach entrance",
    nameES: "Ren 13 / Shang Wan / lugar de la entrada del estómago",
  },
  {
    id: 13,
    title: "Ren 14 / Ju Que, Xin Mu / Sammlungspunkt Herz",
    nameEN: "Ren 14 / Ju Que, Xin Mu / Gathering point of the Heart",
    nameES: "Ren 14 / Ju Que, Xin Mu / Punto de reunión del corazón",
  },
  {
    id: 14,
    title: "Ren 15 / Jiu Wei / Taubenschwanz",
    nameEN: "Ren 15 / Jiu Wei / Dove's Tail",
    nameES: "Ren 15 / Jiu Wei / Cola de paloma",
  },
  {
    id: 15,
    title: "Ren 16 / Zhong Ting / mittlere Vorhalle",
    nameEN: "Ren 16 / Zhong Ting / middle vestibule",
    nameES: "Ren 16 / Zhong Ting / vestíbulo medio",
  },
  {
    id: 16,
    title: "Ren 17 / Shan Zhong, Tan Zhong / Vorhof der Brust",
    nameEN: "Ren 17 / Shan Zhong, Tan Zhong / atrium of the chest",
    nameES: "Ren 17 / Shan Zhong, Tan Zhong / aurícula del pecho",
  },
  {
    id: 17,
    title: "Ren 18 / Yu Tang / Vorhof der Jade",
    nameEN: "Ren 18 / Yu Tang / atrium of the jade",
    nameES: "Ren 18 / Yu Tang / atrio del jade",
  },
  {
    id: 18,
    title: "Ren 19 / Zi Gong / Purpurner Palast",
    nameEN: "Ren 19 / Zi Gong / Purple Palace",
    nameES: "Ren 19 / Zi Gong / Palacio Púrpura",
  },
  {
    id: 19,
    title: "Ren 20 / Hua Gai / geschmückter Baldachin",
    nameEN: "Ren 20 / Hua Gai / adorned canopy",
    nameES: "Ren 20 / Hua Gai / dosel adornado",
  },
  {
    id: 20,
    title: "Ren 21 / Xuan Ji / Visierrohr",
    nameEN: "Ren 21 / Xuan Ji / Visor pipe",
    nameES: "Ren 21 / Xuan Ji / Tubo de la visera",
  },
  {
    id: 21,
    title: "Ren 22 / Tian Tu / Bresche des Himmels",
    nameEN: "Ren 22 / Tian Tu / Breach of Heaven",
    nameES: "Ren 22 / Tian Tu / Brecha del Cielo",
  },
  {
    id: 22,
    title: "Ren 23 / Lian Quan / Quelle in den Engen",
    nameEN: "Ren 23 / Lian Quan / Spring in the Narrows",
    nameES: "Ren 23 / Lian Quan / Primavera en los Estrechos",
  },
  {
    id: 23,
    title: "Ren 24 / Chéng Jiang / Pkt der Flüssigkeiten aufnimmt",
    nameEN: "Ren 24 / Chéng Jiang / Point that absorbs liquids",
    nameES: "Ren 24 / Chéng Jiang / Punto que absorbe líquidos",
  },
];

export const Extrapunkte = [
  {
    id: 0,
    title: "1 / Yin Tang / Siegelhalle",
    nameEN: "1 / Yin Tang / Seal Hall",
    nameES: "1 / Yin Tang / Sala del Sello",
  },
  {
    id: 1,
    title: "2 / Tai Yang / Sonne (das große Yang)",
    nameEN: "2 / Tai Yang / Sun (the great Yang)",
    nameES: "2 / Tai Yang / Sol (el gran Yang)",
  },
  {
    id: 2,
    title: "3 / Bi Tong / Duchgängige Nase",
    nameEN: "3 / Bi Tong / Nose that passes through",
    nameES: "3 / Bi Tong / Nariz que atraviesa",
  },
  {
    id: 3,
    title: "4 / Yin Ming / Klarheit des Auges",
    nameEN: "4 / Yin Ming / Clarity of the eye",
    nameES: "4 / Yin Ming / Claridad del ojo",
  },
  {
    id: 4,
    title: "5 / An Mian / RuhigerSchlaf",
    nameEN: "5 / An Mian / Calm sleep",
    nameES: "5 / An Mian / Sueño tranquilo",
  },
  {
    id: 5,
    title: "6 / Shen Cong / Vier Punkte der Geistesschärfe",
    nameEN: "6 / Shen Cong / Four points of mental acuity",
    nameES: "6 / Shen Cong / Cuatro puntos de agudeza mental",
  },
  {
    id: 6,
    title: "7 / Cheng Jiang / neben d. Pkt, der Flüssigkeiten aufnimmt",
    nameEN: "7 / Cheng Jiang / next to the point that absorbs liquids",
    nameES: "7 / Cheng Jiang / Junto al punto que absorbe los líquidos",
  },
  {
    id: 7,
    title: "8 / Qian Zheng / das Gerade rückziehen",
    nameEN: "8 / Qian Zheng / retracting the straight line",
    nameES: "8 / Qian Zheng / retracción de la línea recta",
  },
  {
    id: 8,
    title: "9 / Zi Gong / Punkt des Uterus",
    nameEN: "9 / Zi Gong / point of the uterus",
    nameES: "9 / Zi Gong / punto del útero",
  },
  {
    id: 9,
    title: "10 / Ding Chuan / Asthmaerleichterung",
    nameEN: "10 / Ding Chuan / asthma relief",
    nameES: "10 / Ding Chuan / alivio del asma",
  },
  {
    id: 10,
    title: "11 / Shi Qi, Zhui Xia / 17. Wirbelkörper",
    nameEN: "11 / Shi Qi, Zhui Xia / 17th vertebral body",
    nameES: "11 / Shi Qi, Zhui Xia / 17º cuerpo vertebral",
  },
  {
    id: 11,
    title: "12 / Hua Tuo Jai Ji / zu beiden Seiten der Wirbelsäule",
    nameEN: "12 / Hua Tuo Jai Ji / on both sides of the spine",
    nameES: "12 / Hua Tuo Jai Ji / a ambos lados de la columna vertebral",
  },
  {
    id: 12,
    title: "13 / Si Feng / die vier Fingergelenksspalten",
    nameEN: "13 / Si Feng / the four finger joint fissures",
    nameES: "13 / Si Feng / las cuatro fisuras articulares de los dedos",
  },
  {
    id: 13,
    title: "14 / Ba Xie / die acht Schrägläufigkeiten",
    nameEN: "14 / Ba Xie / the eight obliques",
    nameES: "14 / Ba Xie / los ocho oblicuos",
  },
  {
    id: 14,
    title: "15 / Shi Xuan / die zehn Drainagen",
    nameEN: "15 / Shi Xuan / the ten drains",
    nameES: "15 / Shi Xuan / los diez drenajes",
  },
  {
    id: 15,
    title: "16 / Jian Nei Ling / Schulterpunkt",
    nameEN: "16 / Jian Nei Ling / shoulder point",
    nameES: "16 / Jian Nei Ling / punto del hombro",
  },
  {
    id: 16,
    title: "17 / Luo Zhen / Nackenstarre",
    nameEN: "17 / Luo Zhen / stiff neck",
    nameES: "17 / Luo Zhen / tortícolis",
  },
  {
    id: 17,
    title: "18 / Yao Tong / Lumbagopunkt",
    nameEN: "18 / Yao Tong / lumbago point",
    nameES: "18 / Yao Tong / punto del lumbago",
  },
  {
    id: 18,
    title: "19 / Ba Feng / die acht Winde",
    nameEN: "19 / Ba Feng / the eight winds",
    nameES: "19 / Ba Feng / los ocho vientos",
  },
  {
    id: 19,
    title: "20 / Huan Zhong / Mitte von Stein + Femur",
    nameEN: "20 / Huan Zhong / center of stone + femur",
    nameES: "20 / Huan Zhong / centro de la piedra + fémur",
  },
  {
    id: 20,
    title: "21 / Bai Chong Wo / Insektennest",
    nameEN: "21 / Bai Chong Wo / insect nest",
    nameES: "21 / Bai Chong Wo / nido de insectos",
  },
  {
    id: 21,
    title: "22 / LanWei Xue / Appendixpunkt",
    nameEN: "22 / LanWei Xue / appendix point",
    nameES: "22 / LanWei Xue / punto apéndice",
  },
  {
    id: 22,
    title: "23 / Xi Yan / Knieaugen",
    nameEN: "23 / Xi Yan / Knee eyes",
    nameES: "23 / Xi Yan / ojos de la rodilla",
  },
];
