import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Checkbox, Chip, CircularProgress, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InfoIcon from "@material-ui/icons/Info";
import { Alert, Stack } from "@mui/material";
import GetUserToken from "../../utils/GetUserToken";
import { TrackableLink } from "../../utils/helper";
import AlertComponent from "../alerts/Alert";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiInputBase-root": {
      minHeight: "200px !important",
      display: "flex !important",
      flexDirection: "column !important",
      alignItems: "flex-start !important",
    },
  },
  chipBaseProduct: {
    margin: "3px",
    width: "100%",
  },
  chipTreatmentProduct: {
    backgroundColor: "#cfe8f5 !important",
    margin: "3px",
    width: "100%",
    "& .MuiChip-label": {
      color: "#087cb6 !important",
    },
    "& .MuiChip-deleteIcon": {
      color: "#087cb6 !important",
    },
  },
  iconBaseProduct: {
    color: "var(--primary-color)",
    marginRight: "3px",
  },
  iconTreatmentProduct: {
    color: "#087cb6",
    marginRight: "3px",
  },
}));

const EditTreatment = (infoRoute) => {
  // translations
  const { t } = useTranslation();
  // styles
  const classes = useStyles();
  // route
  const history = infoRoute.history;
  // data
  const [name, setName] = useState("");
  const [duration, setDuration] = useState("");
  const [treatmentDescription, setTreatmentDescription] = useState("");
  const [first, setFirst] = useState(false);
  const [initialProducts, setInitialProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [clinicproduct, setClinicproduct] = useState([]);
  // utils
  const [loading, setLoading] = useState(true);
  const [bookable, setBookable] = useState(false);
  const [archived, setArchived] = useState(false);
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  // auth
  const userToken = GetUserToken();

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const url =
        process.env.REACT_APP_API_URL_V2 +
        "/treatments/show/" +
        infoRoute.match.params.id;

      var requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        redirect: "follow",
      };

      const res = await fetch(url, requestOptions);
      const response = await res.json();

      setName(response.name);
      setDuration(response.duration);
      setTreatmentDescription(response.treatment_description);
      setFirst(response.first);
      setBookable(response.bookable);
      setArchived(response.archived);
      setInitialProducts(response.products);
      setProducts(response.products);

      getProducts(response.products);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getProducts = (treatmentProducts) => {
    const url =
      process.env.REACT_APP_API_URL_V2 + "/treatments/get_base_products";

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => {
        response.json().then((response) => {
          const filteredProducts = response.filter((item) => {
            return item.archived === false;
          });
          setClinicproduct(filteredProducts);
        });
      })
      .catch((error) => console.log(error.message));
  };

  // Post method
  const handleSubmit = (e) => {
    e.preventDefault();

    if (products.length === 0) {
      setIsAlert({
        open: true,
        severity: "warning",
        message: t("Messages.You must select at least one product"),
      });
      return;
    }

    setLoading(true);
    const treatment = {
      name,
      duration,
      treatment_description: treatmentDescription,
      first,
      bookable,
      products: products,
    };

    const url =
      process.env.REACT_APP_API_URL_V2 +
      "/treatments/update/" +
      infoRoute.match.params.id;

    var requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
      body: JSON.stringify(treatment),
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          setIsAlert({
            open: true,
            severity: "success",
            message: t("Messages.The operation was successful"),
          });
          setTimeout(() => {
            history.push("/treatments");
          }, 1000);
        } else {
          setIsAlert({
            open: true,
            severity: "error",
            message: t("Messages.The operation could not be processed"),
          });
        }
      })
      .catch((error) =>
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.Something went wrong, please try again"),
        })
      );
  };

  const handleArchive = (e) => {
    setLoading(true);
    e.preventDefault();
    const treatment = { archived: !archived };
    const url =
      process.env.REACT_APP_API_URL_V2 +
      "/treatments/archive/" +
      infoRoute.match.params.id;
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
      body: JSON.stringify(treatment),
    };
    fetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          setIsAlert({
            open: true,
            severity: "success",
            message: t("Messages.The operation was successful"),
          });
          setTimeout(() => {
            history.push("/treatments");
          }, 1000);
        } else {
          setIsAlert({
            open: true,
            severity: "error",
            message: t("Messages.The operation could not be processed"),
          });
        }
      })
      .catch((error) =>
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.Something went wrong, please try again"),
        })
      );
  };

  const handleEditProduct = (id) => {
    TrackableLink("edit_product_treatment");

    if (JSON.stringify(initialProducts) === JSON.stringify(products)) {
      history.push(`/edit-product/${id}`, {
        idTreatment: infoRoute.match.params.id,
      });
    } else {
      setIsAlert({
        open: true,
        severity: "warning",
        message: t(
          "EditTreatment.Remember to save the changes before continuing editing"
        ),
      });
    }
  };

  return (
    <>
      <div className="frame" style={{ width: "calc(100% - 80px)" }}>
        <div className="row" style={{ height: "calc(100vh - 144px)" }}>
          <div
            className="d-flex flex-column justify-content-between"
            style={{ paddingBottom: "8%" }}
          >
            <div>
              <h4 className="main-title-treatments">
                {t("TreatmentList.Edit Treatment")}
              </h4>
              <p className="blue-anchors">
                <Link className="blue-anchors" to="/treatments">
                  &#60; {t("NewTreatment.Back to treatments")}
                </Link>
              </p>
            </div>
            {loading ? (
              <div className="d-flex justify-content-center">
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <div className="form-group">
                      <label htmlFor="name" className="title-treatments">
                        {t("EditTreatment.Name")}
                      </label>
                      <input
                        required
                        disabled={true}
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="input-control"
                        style={{ color: "gray", fontStyle: "italic" }}
                      />
                      <Stack sx={{ mt: 1 }}>
                        <Alert
                          severity="warning"
                          style={{ alignItems: "center" }}
                          className="title-inputs"
                        >
                          {t(
                            "Messages.At the moment, you cannot change the name, if you wish to do so you must create a new entry"
                          )}
                        </Alert>
                      </Stack>
                    </div>

                    <div className="form-group" style={{ zIndex: 99 }}>
                      <label className="title-inputs">
                        {t("EditTreatment.Options")}
                      </label>
                      <Form.Check
                        checked={first}
                        onChange={() => setFirst(!first)}
                        type="checkbox"
                        label={t("EditTreatment.First contact?")}
                      />
                      <Form.Check
                        checked={bookable}
                        onChange={() => setBookable(!bookable)}
                        type="checkbox"
                        label={t("EditTreatment.Bookable by patient?")}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="duration" className="title-treatments">
                        {t("EditTreatment.Duration (minutes)")}
                      </label>
                      <input
                        required
                        type="number"
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                        className="input-control"
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="treatment_description"
                      className="title-treatments"
                    >
                      {t("NewTreatment.Treatment Description")}
                    </label>
                    <textarea
                      value={treatmentDescription}
                      onChange={(e) => setTreatmentDescription(e.target.value)}
                      className="input-control"
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label
                      htmlFor="product"
                      className="title-treatments"
                      style={{ marginBottom: "6px" }}
                    >
                      {t("EditTreatment.Select Product")}
                    </label>
                    <Autocomplete
                      multiple
                      name="products"
                      id="products"
                      className={classes.root}
                      options={clinicproduct.sort((a, b) =>
                        a.rate.toString().localeCompare(b.rate.toString())
                      )}
                      value={products.sort((a, b) =>
                        a.rate.toString().localeCompare(b.rate.toString())
                      )}
                      disableCloseOnSelect
                      disablePortal
                      onChange={(event, newValues) => {
                        setProducts(newValues);
                      }}
                      getOptionLabel={(option) =>
                        option.rate + " - " + option.title
                      }
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{
                              marginRight: 8,
                              color: "var(--primary-color)",
                            }}
                            checked={selected}
                          />
                          {option.rate} - {option.title}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Products"
                          fullWidth
                        />
                      )}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            key={index}
                            id="edit_product_treatment"
                            label={`${option.rate} - ${option.title}`}
                            {...getTagProps({ index })}
                            clickable
                            onClick={() => handleEditProduct(option.id)}
                            className={
                              option.title.includes(name)
                                ? classes.chipTreatmentProduct
                                : classes.chipBaseProduct
                            }
                          />
                        ))
                      }
                      ListboxProps={{
                        style: { maxHeight: "300px" },
                      }}
                    />
                  </div>
                  <div>
                    <div className="d-flex align-items-center">
                      <InfoIcon
                        fontSize="small"
                        className={classes.iconBaseProduct}
                      />
                      <span>{t("EditTreatment.Base product")}</span>
                    </div>
                    <div className="d-flex align-items-center">
                      <InfoIcon
                        fontSize="small"
                        className={classes.iconTreatmentProduct}
                      />
                      <span>{t("EditTreatment.Modified product")}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="d-flex justify-content-end">
              {archived ? (
                <button
                  className="blue-btn"
                  onClick={handleArchive}
                  style={{ width: "185px" }}
                >
                  {t("EditTreatment.Unarchive")}
                </button>
              ) : (
                <button
                  className="blue-btn"
                  onClick={handleArchive}
                  style={{ width: "185px" }}
                >
                  {t("EditTreatment.Archive")}
                </button>
              )}

              <div>
                <button
                  className="blue-btn"
                  onClick={handleSubmit}
                  style={{ width: "185px" }}
                >
                  {t("EditTreatment.SAVE")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isAlert.open && (
        <AlertComponent
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
    </>
  );
};

export default EditTreatment;
