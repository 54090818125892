import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ReactComponent as Pencil } from "../../assets/icons/pencil.svg";
import GetUserToken from "../../utils/GetUserToken";
import { TrackableLink } from "../../utils/helper";
import Table from "./TreatmentsTable";

const TreatmentsList = () => {
  // translations
  const { t } = useTranslation();
  let currentLanguage = localStorage.getItem("i18nextLng");
  // utils
  const [loading, setLoading] = useState(true);
  // data
  const [activeTreatments, setActiveTreatments] = useState([]);
  const [archivedTreatments, setArchivedTreatments] = useState([]);

  const columns = useMemo(
    () => [
      {
        // first group
        Header: " ",
        // First group columns
        columns: [
          {
            Header: "ID",
            accessor: "id",
          },
          {
            Header: <>{t("TreatmentList.Title")}</>,
            accessor: "name",
          },
          {
            Header: <>{t("TreatmentList.Duration (min)")}</>,
            accessor: "duration",
          },
          {
            Header: <>{t("TreatmentList.Duration (min)")}</>,
            accessor: "treatment_description",
            Cell: ({ row }) => {
              const maxLength = 50;
              if (
                row.original.treatment_description &&
                row.original.treatment_description.length > maxLength
              ) {
                return (
                  <span title={row.original.treatment_description}>
                    {row.original.treatment_description.substring(0, maxLength)}
                    ...
                  </span>
                );
              } else {
                return <span>{row.original.treatment_description}</span>;
              }
            },
          },
          {
            Header: <>{t("TreatmentList.First")}</>,
            accessor: "first",
            sortType: (a, b, id) => {
              if (a.original[id] > b.original[id]) return -1;
              if (b.original[id] > a.original[id]) return 1;
            },
            Cell: ({ row }) => (
              <>
                {row.original.first ? (
                  <>{t("TreatmentList.Yes")}</>
                ) : (
                  <>{t("TreatmentList.No")}</>
                )}
              </>
            ),
          },
          {
            Header: <>{t("TreatmentList.Bookable")}</>,
            accessor: "bookable",
            sortType: (a, b, id) => {
              if (a.original[id] > b.original[id]) return -1;
              if (b.original[id] > a.original[id]) return 1;
            },
            Cell: ({ row }) => (
              <>
                {row.original.bookable ? (
                  <>{t("TreatmentList.Yes")}</>
                ) : (
                  <>{t("TreatmentList.No")}</>
                )}
              </>
            ),
          },
          {
            Header: <>{t("TreatmentList.Active")}</>,
            accessor: "archived",
            Cell: ({ row }) => (
              <>
                {row.original.archived ? (
                  <>{t("TreatmentList.No")}</>
                ) : (
                  <>{t("TreatmentList.Yes")}</>
                )}
              </>
            ),
          },
          {
            Header: " ",
            accessor: " ",
            Cell: ({ row }) => (
              <Link
                to={`/edit-treatment/${row.original.id}`}
                id="edit_treatment"
                onClick={() => TrackableLink("edit_treatment")}
              >
                <Pencil className="icon" />
              </Link>
            ),
          },
        ],
      },
    ],
    [currentLanguage]
  );

  const fetchData = async () => {
    try {
      const url = process.env.REACT_APP_API_URL_V2 + "/treatments/index";
      const userToken = GetUserToken();

      var requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        redirect: "follow",
      };

      const res = await fetch(url, requestOptions);
      const json = await res.json();
      console.log(json);
      const active_treatments = json.filter((treatment) => !treatment.archived);
      const archived_treatments = json.filter(
        (treatment) => treatment.archived
      );
      setActiveTreatments(active_treatments);
      setArchivedTreatments(archived_treatments);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="frame">
      <h4 className="main-title mt-2">{t("TreatmentList.Treatments")}</h4>
      <br />

      <div style={{ marginRight: "50px" }}>
        <Tabs defaultActiveKey="active_treatments" className="mb-3">
          <Tab
            eventKey="active_treatments"
            title={t("TreatmentList.Active treatments")}
          >
            <div className="container-fluid treatments-table">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <Table columns={columns} data={activeTreatments} />
              )}
            </div>
          </Tab>
          <Tab
            eventKey="archived_treatments"
            title={t("TreatmentList.Archived treatments")}
          >
            <div className="container-fluid treatments-table">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <Table columns={columns} data={archivedTreatments} isArchived />
              )}
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default TreatmentsList;
