import {
  DropdownComponent,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Icon,
} from "./styles";

const Component = ({ disabled, resetChanges, removeBlend }) => {
  return (
    <DropdownComponent>
      <DropdownToggle variant="success" id="dropdown-basic">
        <Icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Isolation_Mode"
            data-name="Isolation Mode"
            viewBox="0 0 24 24"
            width="512"
            height="512"
          >
            <path d="M8.127,24l9.507-9.52a3.507,3.507,0,0,0,0-4.948L8.116,0,6,2.121l9.518,9.531a.5.5,0,0,1,0,.707L6.01,21.879Z" />
          </svg>
        </Icon>
      </DropdownToggle>

      <DropdownMenu>
        <DropdownItem disabled onClick={resetChanges}>
          Reset changes
        </DropdownItem>
        <DropdownItem disabled onClick={removeBlend}>
          Remove all
        </DropdownItem>
      </DropdownMenu>
    </DropdownComponent>
  );
};

export default Component;
