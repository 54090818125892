import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const Popup = ({ isOpen, body, fullScreen }) => {
  return (
    <Dialog open={isOpen} fullScreen={fullScreen} sx={{ zIndex: 1049 }}>
      {body && (
        <>
          {body.title && (
            <DialogTitle
              sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                px: 8,
                pt: 4,
                fontSize: "22px",
              }}
            >
              {body.title}
            </DialogTitle>
          )}
          {body.content && (
            <DialogContent
              sx={
                fullScreen
                  ? { p: 0 }
                  : {
                      display: "flex",
                      justifyContent: "center",
                      px: 15,
                      pb: 0,
                    }
              }
            >
              {body.content}
            </DialogContent>
          )}
          {(body.leftButton || body.rightButton) && (
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "space-around",
                px: 6,
                py: 4,
              }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={body.handleClickleftButton}
              >
                {body.leftButton}
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={body.handleClickrightButton}
              >
                {body.rightButton}
              </Button>
            </DialogActions>
          )}
        </>
      )}
    </Dialog>
  );
};

export default Popup;
