import React from "react";
import Language from "./reusableComponents/Language";
// simport logo from "../assets/icons/Title.png";
import { connect } from "react-redux";

const Component = ({ company, isPadding, navbarRef }) => {
  return (
    <div
      className="d-flex justify-content-between align-items-center"
      style={{ padding: isPadding && "20px" }}
      ref={navbarRef}
    >
      <a href="/">
        <img
          src={
            company?.logo?.url || "https://www.tcmassist.org/wp-content/uploads/2022/08/tcm-assist-logo.png"
          }
          alt="logo"
          className="logo-padding"
        />
      </a>
      <Language />
    </div>
  );
};

const state = ({ companyStore }) => {
  const {
    data: company,
    loading: companyLoading,
    error: companyError,
    success: companySuccess,
  } = companyStore.one;

  return {
    company,
    companyLoading,
    companyError,
    companySuccess,
  };
};

export default connect(state)(Component);
