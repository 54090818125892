import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useBeforeunload } from "react-beforeunload";
import { Accordion, Card, Button } from "react-bootstrap";
import {
  CircularProgress,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import edit_clock from "../../../../assets/icons/edit_clock.png";
import GetUserToken from "../../../../utils/GetUserToken";
import Alert from "../../../alerts/Alert";
import "../../../../assets/stylesheets/zunge.css";
import { symptoms, zungenfarbe, zungengestalt, zungenbelag } from "../../../../constants/tongue";
import { getValue } from "./helper";

const Zunge = ({ id, patientID, handleCallbackZunge }) => {
  // translations
  const { t } = useTranslation();
  // auth
  const userToken = GetUserToken();
  // utils
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const [loading, setLoading] = useState(true);
  const [valid, setValid] = useState(false);
  const [save, setSave] = useState(false);
  const [last, setLast] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [hovering, setHovering] = useState(false);
  // ref
  let textInput = useRef(null);
  let textInput2 = useRef(null);
  let textInput3 = useRef(null);
  let textInput4 = useRef(null);
  let textInput5 = useRef(null);
  let textInput6 = useRef(null);
  // data
  const [tonguea, setTonguea] = useState("");
  const [tongueb, setTongueb] = useState("");
  const [tonguec, setTonguec] = useState("");
  const [tongued, setTongued] = useState("");
  const [tonguee, setTonguee] = useState("");
  const [tonguef, setTonguef] = useState("");

  // translations
  const getTranslation = (array, value) => {
    if (value) {
      let value_in_array = array.filter(({ title }) => value === title);
      if (value_in_array.length !== 0) {
        let name = array.filter((element) => element.title === value)[0].nameEN;
        return t(`Zunge.${name}`);
      } else {
        return value;
      }
    } else {
      return value;
    }
  };
  // end translations

  useBeforeunload((event) => {
    if (save !== false) {
      event.preventDefault();
    }
  });

  const fetchData = async () => {
    handleCallbackZunge([false, "No changes"]);
    setLoading(true);
    // console.log("patient: ", patientID);

    var requestOptions = {
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL +
      `/citations/getTongue?patient_id=` +
      patientID,
      requestOptions
    );

    const json = await res.json();

    let data = json.tongue;

    let filteredData = data.filter(
      (element) =>
        element[2] ||
        element[3] ||
        element[4] ||
        element[5] ||
        element[6] ||
        element[7] != null
    );
    setFiltered(filteredData);

    let zungeData = data.find(
      (element) =>
        element[2] ||
        element[3] ||
        element[4] ||
        element[5] ||
        element[6] ||
        element[7] !== null
    );
    setLast(zungeData);

    if (zungeData) {
      handleCallbackZunge([true, zungeData[1]]);
      setValid(true);
      setTonguea(zungeData[2]);
      setTongueb(zungeData[3]);
      setTonguec(zungeData[4]);
      setTongued(zungeData[5]);
      setTonguee(zungeData[6]);
      setTonguef(zungeData[7]);
      setLoading(false);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDateChange = (e) => {
    setTonguea(e.target.value[2]);
    setTongueb(e.target.value[3]);
    setTonguec(e.target.value[4]);
    setTongued(e.target.value[5]);
    setTonguee(e.target.value[6]);
    setTonguef(e.target.value[7]);
    setLast(e.target.value);
    setSave(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const zunge = { tonguea, tongueb, tonguec, tongued, tonguee, tonguef };
    fetch(process.env.REACT_APP_API_URL + `/citations/update/` + id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      body: JSON.stringify(zunge),
    })
      .then((response) => response.json())
      .then((data) => {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
        setSave(false);
        fetchData();
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.Something went wrong, please try again"),
        });
      });
  };

  return (
    <>
      <Accordion style={{ paddingRight: "0", paddingLeft: "0" }}>
        <Card
          className="reminders-cards"
          style={{ width: "93%", borderRadius: "10px" }}
        >
          <Card.Header
            className="reminders-subtitle"
            style={{
              border: "none",
              background: "white",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="0"
                className="text-center subtitles-patients"
                style={{ textDecoration: "none" }}
                id="accordion_zunge"
              >
                <span>&#8595; &nbsp;</span> {t("Titles.Zunge")}
              </Accordion.Toggle>

              <span className="subtitles-patients border-start">
                <img
                  src={edit_clock}
                  alt="clock"
                  onMouseEnter={() => setHovering(true)}
                  onMouseLeave={() => setHovering(false)}
                />
                {loading ? (
                  <></>
                ) : (
                  <>
                    {filtered.length !== 0 ? (
                      <>
                        <Select
                          id="treatment"
                          name="treatment"
                          value={last}
                          onChange={handleDateChange}
                        >
                          {filtered.map((item, i) => (
                            <MenuItem key={`zunge-menu-item-${i}`} value={item}>
                              <b>{item[0].split("T")[0]}&nbsp; /&nbsp;</b>
                              {item[1].replace("T", " at: ").slice(0, -8)}
                            </MenuItem>
                          ))}
                        </Select>
                        {hovering && (
                          <span className="key-text-box">
                            <b> {t("CompleteAppointment.Reference1")}</b>
                            {t("CompleteAppointment.Reference2")}
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        <span className="key-text-box">
                          &nbsp;{t("Messages.No previous records")}
                        </span>
                      </>
                    )}
                  </>
                )}
              </span>
            </div>
            <div>
              {save === false ? (
                <></>
              ) : (
                <button
                  className="button-citation-box-2-save"
                  onClick={handleSubmit}
                  style={{ padding: "6px" }}
                >
                  {t("Buttons.Save")}
                </button>
              )}
            </div>
          </Card.Header>

          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <form onSubmit={handleSubmit}>
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <CircularProgress color="secondary" />
                  </div>
                ) : (
                  <>
                    <div
                      className="row"
                      style={{
                        background: "#f4f5fa",
                        borderRadius: "10px",
                        paddingLeft: "25px",
                        paddingRight: "25px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <div className="col-5">
                        <p className="subtitles-patients">
                          {t("Zunge.Tongue color")}
                        </p>
                        <Autocomplete
                          noOptionsText={"Not Found"}
                          options={zungenfarbe.map((option) =>
                            t(`Zunge.${option.nameEN}`)
                          )}
                          value={getTranslation(zungenfarbe, tonguea)}
                          onChange={(event, value) => {
                            var fieldValue = value;
                            if (!fieldValue) {
                              fieldValue = null;
                            } else {
                              setTonguea(getValue(zungenfarbe, value));
                            }
                            setSave(true);
                          }}
                          style={{ width: "65%" }}
                          renderInput={(params) => (
                            <TextField {...params} inputRef={textInput} />
                          )}
                        />
                        {/*<TextField value={tonguea} onChange={(e) => {setTonguea(e.target.value) ; setSave(true)}}  type="text" variant="outlined" inputRef={textInput}  /> */}
                        <p className="subtitles-patients mt-2">
                          {t("Zunge.Tongue shape")}
                        </p>
                        <Autocomplete
                          noOptionsText={"Not Found"}
                          options={zungengestalt.map((option) =>
                            t(`Zunge.${option.nameEN}`)
                          )}
                          value={getTranslation(zungengestalt, tongueb)}
                          onChange={(event, value) => {
                            var fieldValue = value;
                            if (!fieldValue) {
                              fieldValue = null;
                            } else {
                              setTongueb(getValue(zungengestalt, value));
                            }
                            setSave(true);
                          }}
                          style={{ width: "65%" }}
                          renderInput={(params) => (
                            <TextField {...params} inputRef={textInput2} />
                          )}
                        />
                        {/*<TextField value={tongueb} onChange={(e) => {setTongueb(e.target.value) ; setSave(true)}} type="text" variant="outlined" inputRef={textInput2}  />*/}
                        <p className="subtitles-patients mt-2">
                          {t("Zunge.Tongue coating")}
                        </p>
                        <Autocomplete
                          noOptionsText={"Not Found"}
                          options={zungenbelag.map((option) =>
                            t(`Zunge.${option.nameEN}`)
                          )}
                          value={getTranslation(zungenbelag, tonguec)}
                          onChange={(event, value) => {
                            var fieldValue = value;
                            if (!fieldValue) {
                              fieldValue = null;
                            } else {
                              setTonguec(getValue(zungenbelag, value));
                            }
                            setSave(true);
                          }}
                          style={{ width: "65%" }}
                          renderInput={(params) => (
                            <TextField {...params} inputRef={textInput3} />
                          )}
                        />
                        {/*<TextField value={tonguec} onChange={(e) => {setTonguec(e.target.value) ; setSave(true)}} type="text" variant="outlined" inputRef={textInput3} />*/}

                        <p className="subtitles-patients mt-2">
                          {t("Zunge.Tongue color")}
                        </p>
                        <Autocomplete
                          noOptionsText={"Not Found"}
                          options={zungenfarbe.map((option) =>
                            t(`Zunge.${option.nameEN}`)
                          )}
                          value={getTranslation(zungenfarbe, tongued)}
                          onChange={(event, value) => {
                            var fieldValue = value;
                            if (!fieldValue) {
                              fieldValue = null;
                            } else {
                              setTongued(getValue(zungenfarbe, value));
                            }
                            setSave(true);
                          }}
                          style={{ width: "65%" }}
                          renderInput={(params) => (
                            <TextField {...params} inputRef={textInput3} />
                          )}
                        />
                        {/*<TextField value={tonguec} onChange={(e) => {setTonguec(e.target.value) ; setSave(true)}} type="text" variant="outlined" inputRef={textInput3} />*/}

                        <p className="subtitles-patients mt-2">
                          {t("Zunge.Lower tongue veins")}
                        </p>
                        <Autocomplete
                          noOptionsText={"Not Found"}
                          options={symptoms.map((option) =>
                            t(`Zunge.${option.nameEN}`)
                          )}
                          value={getTranslation(symptoms, tonguee)}
                          onChange={(event, value) => {
                            var fieldValue = value;
                            if (!fieldValue) {
                              fieldValue = null;
                            } else {
                              setTonguee(getValue(symptoms, value));
                            }
                            setSave(true);
                          }}
                          style={{ width: "65%" }}
                          renderInput={(params) => (
                            <TextField {...params} inputRef={textInput3} />
                          )}
                        />
                        {/*<TextField value={tonguec} onChange={(e) => {setTonguec(e.target.value) ; setSave(true)}} type="text" variant="outlined" inputRef={textInput3} />*/}
                      </div>

                      <div className="col-5" style={{ marginRight: "45px" }}>
                        <div className="main-zunge">
                          <div
                            className="ni-zunge"
                            onClick={() => {
                              setTimeout(() => {
                                textInput.current.focus();
                              }, 100);
                            }}
                          >
                            <p
                              className="text-center subtitles-patients"
                              style={{ paddingTop: "20px" }}
                            >
                              Ni
                              <br />
                              Bl
                            </p>
                          </div>
                          <div
                            className="ma-zunge"
                            onClick={() => {
                              setTimeout(() => {
                                textInput4.current.focus();
                              }, 100);
                            }}
                          >
                            <p
                              className="text-center subtitles-patients"
                              style={{ paddingTop: "80px" }}
                            >
                              Ma
                              <br />
                              Mi
                            </p>
                          </div>
                          <div
                            className="le-zunge-left"
                            onClick={() => {
                              setTimeout(() => {
                                textInput2.current.focus();
                              }, 100);
                            }}
                          >
                            <p
                              className="text-center subtitles-patients"
                              style={{
                                paddingTop: "100px",
                                paddingRight: "20px",
                              }}
                            >
                              Le
                              <br />
                              Gb
                            </p>
                          </div>
                          <div
                            className="le-zunge-right"
                            onClick={() => {
                              setTimeout(() => {
                                textInput5.current.focus();
                              }, 100);
                            }}
                          >
                            <p
                              className="text-center subtitles-patients"
                              style={{
                                paddingTop: "103px",
                                paddingLeft: "20px",
                              }}
                            >
                              Le
                              <br />
                              Gb
                            </p>
                          </div>
                          <div
                            className="lu-zunge"
                            onClick={() => {
                              setTimeout(() => {
                                textInput3.current.focus();
                              }, 100);
                            }}
                          >
                            <p
                              className="text-center subtitles-patients"
                              style={{ paddingTop: "20px" }}
                            >
                              Lu
                            </p>
                          </div>
                          <div
                            className="he-zunge"
                            onClick={() => {
                              setTimeout(() => {
                                textInput6.current.focus();
                              }, 100);
                            }}
                          >
                            <p
                              className="text-center subtitles-patients"
                              style={{ paddingTop: "10px" }}
                            >
                              He
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col text-end"></div>
                    </div>
                  </>
                )}
              </form>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      {isAlert.open && (
        <Alert
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
    </>
  );
};

export default Zunge;
