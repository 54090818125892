import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataTable from "react-data-table-component";
import { getAllPharmacies } from "../../../redux/slices/pharmacy";
import TableLoader from "./components/TableLoader";
import { ReactComponent as Pencil } from "../../../assets/icons/pencil.svg";
import { Anchor } from "./styles";

const Component = ({
  pharmacies,
  pharmaciesLoading,
  pharmaciesError,
  pharmaciesSuccess,
  infoRoute,
}) => {
  // translation
  const { t } = useTranslation();
  let currentLanguage = localStorage.getItem("i18nextLng");
  // redux
  const dispatch = useDispatch();
  // route
  const { history } = infoRoute;
  // utils
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filters, setFilters] = useState({});
  const [sort, setSort] = useState({
    sortBy: "created_at",
    sortDirection: "desc",
  });
  const [total, setTotal] = useState(0);

  const getElement = (row) => {
    history.push({
      pathname: `/pharmacy/${row.id}`,
      state: { id: row.id },
    });
  };

  let timer;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFilters((oldState) => ({ ...oldState, [name]: value }));
  };

  const dispatchAction = () => {
    dispatch(getAllPharmacies({ currentPage, ...sort, itemsPerPage, filters }));
  };

  const columns = useMemo(
    () => [
      {
        name: t("PharmacyTable/Form.Name"),
        selector: (row) => row.name,
        id: "name",
        sortable: true,
      },
      {
        name: t("PharmacyTable/Form.Street"),
        selector: (row) => row.street,
        id: "street",
        sortable: true,
      },
      {
        name: t("PharmacyTable/Form.Number"),
        selector: (row) => row.number,
        id: "number",
        sortable: true,
      },
      {
        name: t("PharmacyTable/Form.Zip code"),
        selector: (row) => row.zip,
        id: "zip",
        sortable: true,
      },
      {
        name: t("PharmacyTable/Form.Town"),
        selector: (row) => row.town,
        id: "town",
        sortable: true,
      },
      {
        name: t("PharmacyTable/Form.Province"),
        selector: (row) => row.province,
        id: "province",
        sortable: true,
      },
      {
        name: t("PharmacyTable/Form.Country"),
        selector: (row) => row.country,
        id: "country",
        sortable: true,
      },
      {
        name: t("PharmacyTable/Form.Country code"),
        selector: (row) => row.country_code,
        id: "country_code",
        sortable: true,
      },
      {
        name: t("PharmacyTable/Form.Phone number"),
        selector: (row) => row.phone_number,
        id: "phone_number",
        sortable: true,
      },
      {
        name: t("PharmacyTable/Form.Email"),
        selector: (row) => row.email,
        id: "email",
        sortable: true,
      },
      {
        name: t("PharmacyTable/Form.Web"),
        selector: (row) => row.web,
        id: "web",
        sortable: true,
      },
      {
        name: " ",
        selector: (row) => (
          <div style={{ paddingLeft: "46px" }}>
            <Pencil
              className="icon"
              onClick={() => getElement(row)}
              style={{ cursor: "pointer" }}
            />
          </div>
        ),
        id: " ",
      },
    ],
    [pharmacies, currentLanguage]
  );

  useEffect(() => {
    if (!!pharmacies?.pagination) {
      setTotal(pharmacies?.pagination?.totalItems);
    }
  }, [pharmacies]);

  useEffect(() => {
    dispatchAction();
  }, []);

  useEffect(() => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      dispatchAction();
    }, 1500);

    return () => clearTimeout(timer);
  }, [filters, currentPage, itemsPerPage, sort]);

  return (
    <>
      <h4 className="main-title mt-2">{t("PharmacyList.Pharmacies")}</h4>
      <br />
      {pharmaciesLoading && !pharmacies?.data ? (
        <div className="d-flex justify-content-center">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <TableLoader loading={pharmaciesLoading}>
          <div
            style={{
              maxWidth: "95%",
            }}
          >
            <div className="d-flex justify-content-end">
              <input
                name="searchBy"
                id="searchBy"
                className="search-field"
                type="text"
                value={filters["searchBy"]}
                onChange={handleChange}
                placeholder={t("PharmacyList.Search")}
              />

              <Anchor to="/pharmacy/new">
                {t("PharmacyList.Add pharmacy")}
              </Anchor>
            </div>
            <div style={{ height: 20 }} />
            <div style={{ overflowX: "auto" }}>
              <DataTable
                className="custom-table"
                paginationComponentOptions={{
                  noRowsPerPage: true,
                  rowsPerPageText: t("Table.Show"),
                  rangeSeparatorText: t("Table.of"),
                  selectAllRowsItemText: t("Table.All"),
                }}
                noDataComponent={
                  <div className="p-3">
                    <b>{t("Table.No results")}</b>
                  </div>
                }
                striped
                pagination
                sortServer
                paginationServer
                data={pharmacies?.data || []}
                paginationTotalRows={total}
                paginationDefaultPage={1}
                onChangeRowsPerPage={setItemsPerPage}
                // onRowClicked={getElement}
                paginationPerPage={itemsPerPage}
                onSort={(a, b) => setSort({ sortBy: a.id, sortDirection: b })}
                onChangePage={setCurrentPage}
                columns={columns}
              />
            </div>
          </div>
        </TableLoader>
      )}
    </>
  );
};

const state = ({ pharmacyStore }) => {
  const {
    data: pharmacies,
    loading: pharmaciesLoading,
    error: pharmaciesError,
    success: pharmaciesSuccess,
  } = pharmacyStore.all;

  return {
    pharmacies,
    pharmaciesLoading,
    pharmaciesError,
    pharmaciesSuccess,
  };
};

export default connect(state)(Component);
