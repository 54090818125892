import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/es";
import "moment/locale/de";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import GetUserToken from "../../utils/GetUserToken";

const Current = ({ infoRoute, isCalendarUpdated, setIsCalendarUpdated }) => {
  // translation
  const { t, i18n } = useTranslation();
  let language = i18n.language;
  // auth
  const userToken = GetUserToken();

  const history = infoRoute.history;

  const [doctors, setDoctors] = useState([]);
  const [filtered, setFiltered] = useState(null);
  const [citations, setCitations] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [localizer, setLocalizer] = useState(momentLocalizer(moment));

  useEffect(() => {
    moment.locale(language);
    setLocalizer(momentLocalizer(moment));
  }, [language]);

  useEffect(() => {
    initData();
    getSchedules();
    getDoctors();
    if (isCalendarUpdated === true) {
      initData();
    }
  }, [isCalendarUpdated]);

  const eventStyleGetter = (event) => {
    var backgroundColor = event.color;
    var style = {
      backgroundColor: backgroundColor,
    };
    return {
      style: style,
    };
  };

  const eventClick = (e) => {
    if (e.eventType == "citation") {
      //window.location.href = e.link;
      //history.push(e.link);
      window.open(e.link, "_blank", "noreferrer");
    } else {
      const startDate = e.start.toString();
      const endDate = e.end.toString();
    }
  };

  function addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
  }

  const initData = async () => {
    const url = process.env.REACT_APP_API_URL + "/citations/getCitationsV2";
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
    };
    const res = await fetch(url, requestOptions);
    const json = await res.json();

    json.forEach((item, i) => {
      // item.start = addMinutes(new Date(item.start), 0);
      item.start = new Date(
        new Date(item.start).toLocaleString("en-US", { timeZone: "UTC" })
      );
      // item.end = addMinutes(new Date(item.end), 0);
      item.end = new Date(
        new Date(item.end).toLocaleString("en-US", { timeZone: "UTC" })
      );
      item.eventType = "citation";
      item.link = "/citation/" + btoa(item.link);
      item.title = item.title + " - " + item.treatment;
    });
    // console.log('current citations: ', json);
    setCitations(json);
    setIsCalendarUpdated(false);
    setLoading(false);
  };

  const getSchedules = async () => {
    const url = process.env.REACT_APP_API_URL + "/schedules/getScheduleV2";
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
    };
    const res = await fetch(url, requestOptions);
    const json = await res.json();
    json.map((item, i) => {
      // item.start = new Date(item.start);
      // item.end = new Date(item.end);
      item.start = addMinutes(new Date(item.start), 0);
      item.end = addMinutes(new Date(item.end), 0);
      item.eventType = "blocked";
      item.color = item.color + "ba";
    });

    // console.log(json);
    setSchedules(json);
  };

  const getDoctors = async () => {
    const url = process.env.REACT_APP_API_URL + "/citations/getDoctors";
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
    };
    const res = await fetch(url, requestOptions);
    const json = await res.json();
    const activeDoctors = json.clinicDoctors.filter((obj) => {
      return obj.archived === false;
    });
    setDoctors(activeDoctors);
  };

  const handleDoctorFilterChange = (event) => {
    setFiltered(event.target.value);
    arrangeCalendarEvents();
  };

  const arrangeCalendarEvents = () => {
    let eventsToShow = [];
    let gettedCitations = [];
    let gettedLocks = [];

    if (filtered == null) {
      eventsToShow = citations;
    } else {
      gettedCitations = citations.filter((item) => item.doctor_id == filtered);
      gettedLocks = schedules.filter((item) => item.doctorID == filtered);
      eventsToShow = [...gettedCitations, ...gettedLocks];
    }
    return eventsToShow;
  };

  return (
    <div>
      {loading ? (
        <div className="d-flex justify-content-center">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <>
          <div>
            <FormControl className="filter">
              <InputLabel>{t("Calendar.Filter by Therapist")}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={handleDoctorFilterChange}
                defaultValue=""
              >
                <MenuItem value={null}>{t("Calendar.Select")}</MenuItem>
                {doctors.map((doctor) => (
                  <MenuItem key={doctor.id} value={doctor.id}>
                    {doctor.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <br />
          <div className="basic-calendar">
            <Calendar
              step={15}
              popup
              localizer={localizer}
              defaultDate={new Date()}
              defaultView="day"
              events={arrangeCalendarEvents()}
              // events={citations}
              style={{ height: "100vh", width: "100%", background: "white" }}
              onSelectEvent={eventClick}
              eventPropGetter={eventStyleGetter}
              min={new Date(0, 0, 0, 6, 0, 0)}
              max={new Date(0, 0, 0, 21, 0, 0)}
              messages={{
                today: t("Calendar.Today"),
                previous: t("Calendar.Back"),
                next: t("Calendar.Next"),
                month: t("Calendar.Month"),
                week: t("Calendar.Week"),
                day: t("Calendar.Day"),
                agenda: t("Calendar.Agenda"),
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Current;
