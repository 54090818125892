import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import services from '../../services';

// Define initial state
const initialState = {
  one: {
    data: null,
    loading: false,
    error: false,
    success: false
  },
  all: {
    data: null,
    loading: false,
    error: false,
    success: false
  },
};

// Async action to get one herb
const getOneHerb = createAsyncThunk(
  'herb/getOne',
  async (payload) => {
    console.log('get one - herb - action', payload)
    const { response, error } = await services.herb.getOne(payload);
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Async action to get all herbs
const getAllHerbs = createAsyncThunk(
  'herb/getAll',
  async (payload) => {
    console.log('get all - herb - action', payload)
    const { response, error } = await services.herb.getAll(payload);
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Create a herb slice
const herbSlice = createSlice({
  name: 'herb',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOneHerb.pending, (state) => {
        state.one.data = null;
        state.one.loading = true;
        state.one.success = false;
        state.one.error = false;
      })
      .addCase(getOneHerb.fulfilled, (state, action) => {
        state.one.data = action.payload;
        state.one.loading = false;
        state.one.success = true;
        state.one.error = false;
      })
      .addCase(getOneHerb.rejected, (state, action) => {
        state.one.data = null;
        state.one.loading = false;
        state.one.success = false;
        state.one.error = action.error.message;
      })
      .addCase(getAllHerbs.pending, (state) => {
        state.all.data = null;
        state.all.loading = true;
        state.all.success = false;
        state.all.error = false;
      })
      .addCase(getAllHerbs.fulfilled, (state, action) => {
        state.all.data = action.payload;
        state.all.loading = false;
        state.all.success = true;
        state.all.error = false;
      })
      .addCase(getAllHerbs.rejected, (state, action) => {
        state.all.data = null;
        state.all.loading = false;
        state.all.success = false;
        state.all.error = action.error.message;
      });
  },
});

// Export the async actions and the reducer
export { getOneHerb, getAllHerbs };
export default herbSlice.reducer;