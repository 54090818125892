import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import services from "../../services";

// Define initial state
const initialState = {
  one: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  all: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  allbycompany: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  create: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  update: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
};

// Async action to get one insurance
const getOneInsurance = createAsyncThunk(
  "insurance/getOne",
  async (payload) => {
    console.log("get one - insurance - action", payload);
    const { response, error } = await services.insurance.getOne(payload);
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Async action to get all insurances
const getAllInsurances = createAsyncThunk("insurance/getAll", async () => {
  console.log("get all - insurance - action");
  const { response, error } = await services.insurance.getAll();
  if (error) {
    throw new Error(error);
  }
  return response;
});

// Async action to get all insurances by company
const getInsurancesByCompany = createAsyncThunk(
  "insurance/getAllByCompany",
  async (payload) => {
    console.log("get all by company - insurance - action");
    const { response, error } = await services.insurance.getAllByCompany(
      payload
    );
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Async action to create one insurance
const createOneInsurance = createAsyncThunk(
  "insurance/createOne",
  async ({ payload }) => {
    console.log("create one - insurance - action", payload);
    const { response, error } = await services.insurance.createOne(payload);
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Async action to update a insurance
const updateInsurance = createAsyncThunk(
  "insurance/update",
  async (payload) => {
    console.log("update - insurance - action", payload);
    const { response, error } = await services.insurance.update(payload);
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Create a insurance slice
const insuranceSlice = createSlice({
  name: "insurance",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOneInsurance.pending, (state, action) => {
        state.one.data = null;
        state.one.loading = true;
        state.one.success = false;
        state.one.error = false;
      })
      .addCase(getOneInsurance.fulfilled, (state, action) => {
        state.one.data = action.payload;
        state.one.loading = false;
        state.one.success = true;
        state.one.error = false;
      })
      .addCase(getOneInsurance.rejected, (state, action) => {
        state.one.data = null;
        state.one.loading = false;
        state.one.success = false;
        state.one.error = action.error.message;
      })
      .addCase(getAllInsurances.pending, (state) => {
        state.all.data = null;
        state.all.loading = true;
        state.all.success = false;
        state.all.error = false;
      })
      .addCase(getAllInsurances.fulfilled, (state, action) => {
        state.all.data = action.payload;
        state.all.loading = false;
        state.all.success = true;
        state.all.error = false;
      })
      .addCase(getAllInsurances.rejected, (state, action) => {
        state.all.data = null;
        state.all.loading = false;
        state.all.success = false;
        state.all.error = action.error.message;
      })
      .addCase(getInsurancesByCompany.pending, (state) => {
        state.allbycompany.data = null;
        state.allbycompany.loading = true;
        state.allbycompany.success = false;
        state.allbycompany.error = false;
      })
      .addCase(getInsurancesByCompany.fulfilled, (state, action) => {
        state.allbycompany.data = action.payload;
        state.allbycompany.loading = false;
        state.allbycompany.success = true;
        state.allbycompany.error = false;
      })
      .addCase(getInsurancesByCompany.rejected, (state, action) => {
        state.allbycompany.data = null;
        state.allbycompany.loading = false;
        state.allbycompany.success = false;
        state.allbycompany.error = action.error.message;
      })
      .addCase(updateInsurance.pending, (state) => {
        state.update.data = null;
        state.update.loading = true;
        state.update.success = false;
        state.update.error = false;
      })
      .addCase(updateInsurance.fulfilled, (state, action) => {
        state.update.data = action.payload;
        state.update.loading = false;
        state.update.success = true;
        state.update.error = false;
        state.one.data = action.payload;
      })
      .addCase(updateInsurance.rejected, (state, action) => {
        state.update.data = null;
        state.update.loading = false;
        state.update.success = false;
        state.update.error = action.error.message;
      });
  },
});

// Export the async actions and the reducer
export {
  getOneInsurance,
  getAllInsurances,
  getInsurancesByCompany,
  createOneInsurance,
  updateInsurance,
};

export default insuranceSlice.reducer;
