import GetUserToken from "../utils/GetUserToken";
import { parseJSON } from "../utils/parseJSON";

const urlBase = `${process.env.REACT_APP_API_URL}/diagnosis`;
const readUrl = (url = "") => `${urlBase}/${url}`;

class Diagnosis {
  async getOne({ id }) {
    const auth = `${GetUserToken()}`;

    let res = await fetch(readUrl(id), {
      method: "GET",
      headers: {
        Authorization: auth,
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
      },
    });

    res = await parseJSON(res);
    return res;
  }

  async getAll(payload) {
    console.log("getAll - diagnosis - service");
    const auth = `${GetUserToken()}`;

    let res = await fetch(readUrl("index"), {
      method: "GET",
      headers: {
        Authorization: auth,
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
      },
    });

    res = await parseJSON(res);
    console.log(res);
    return res;
  }

  async createOne(payload) {
    const formData = new FormData();
    const keys = Object.keys(payload);

    for (const key of keys) {
      const value = payload[key];

      if (key === "logo") {
        if (typeof value !== "string") {
          const file = value[0];
          formData.append("logo", file, file.name);
        }
      } else {
        formData.append(key, value);
      }
    }

    const auth = `${GetUserToken()}`;

    let res = await fetch(readUrl("new"), {
      method: "POST",
      headers: {
        Authorization: auth,
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
      },
      body: formData,
    });

    res = await parseJSON(res);
    return res;
  }

  async update(payload) {
    const { id, ...rest } = payload;
    const formData = new FormData();
    const keys = Object.keys(rest);

    for (const key of keys) {
      const value = rest[key];

      if (key === "logo") {
        if (typeof value !== "string") {
          const file = value[0];
          formData.append("logo", file, file.name);
        }
      } else {
        formData.append(key, value);
      }
    }

    const auth = `${GetUserToken()}`;

    let res = await fetch(readUrl(id), {
      method: "POST",
      headers: {
        Authorization: auth,
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
      },
      body: formData,
    });

    res = await parseJSON(res);
    return res;
  }
}

const diagnosis = new Diagnosis();
export default diagnosis;
