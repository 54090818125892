import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ReactComponent as Pencil } from "../../../assets/icons/pencil.svg";
import "../../../assets/stylesheets/invoicesStyles.css";
import { FormControl, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

export const DiagnosisInfo = ({
  diagnosis,
  setDiagnosis,
  westlicheDiagnosis,
  setWestlicheDiagnosis,
  diagnosisFromCrud,
}) => {
  const { t } = useTranslation();

  const [pickedDiagnosis, setPickedDiagnosis] = useState(diagnosis);

  var diagnosis2 = diagnosis;

  if (diagnosis2 != null) {
    setWestlicheDiagnosis(diagnosis);
  } else {
    setWestlicheDiagnosis([]);
  }

  const addWestlicheDiagnosis = (value) => {
    let currentDiagnosis = diagnosis;
    currentDiagnosis = value;
    setDiagnosis(currentDiagnosis);
    setWestlicheDiagnosis(value);
    setPickedDiagnosis(value);
  };

  return (
    <>
      <Accordion className="invoice-diagnosis-accordion-master">
        <AccordionSummary
          className="invoice-patient-accordion-summary"
          expandIcon={<ExpandMoreIcon style={{ color: "#38baff" }} />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
          style={{ zIndex: "99" }}
        >
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={<></>}
            label={
              <span className="invoice-patient-title">
                <Pencil className="icon" />
                &nbsp;{t("InvoiceDiagnosisInfo.Diagnosis")}
              </span>
            }
          />
        </AccordionSummary>
        <AccordionDetails>
          <>
            <div className="row">
              <div className="col">
                <div className="mt-4">
                  <div>
                    <h4 className="invoice-patient-info mt-1">
                      {t("InvoiceDiagnosisInfo.Westliche Diagnosis")}:
                    </h4>
                    <br />
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <Autocomplete
                        getOptionSelected={(option, selectedValue) =>
                          option.code === selectedValue.code
                        }
                        multiple
                        id="tags-standard"
                        options={diagnosisFromCrud}
                        getOptionLabel={(option) =>
                          option.code + " - " + option.name
                        }
                        onChange={(e, value) => {
                          addWestlicheDiagnosis(value);
                        }}
                        value={westlicheDiagnosis}
                        renderInput={(params) => (
                          <div
                          //style={{ maxHeight: "150px", overflowY: "scroll" }}
                          >
                            <TextField
                              {...params}
                              variant="outlined"
                              label={t("InvoiceDiagnosisInfo.Nothing to show")}
                            />
                          </div>
                        )}
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
