import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useBeforeunload } from "react-beforeunload";
import { Accordion, Button, Card, Col, Nav, Row, Tab } from "react-bootstrap";
import { MenuItem, Select, TextareaAutosize } from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import edit_clock from "../../../../assets/icons/edit_clock.png";
import GetUserToken from "../../../../utils/GetUserToken";
import Alert from "../../../alerts/Alert";
import { InfoTabs } from "./constants";

const Info = ({ id, patientID, handleCallbackInfo }) => {
  // translations
  const { t } = useTranslation();
  // auth
  const userToken = GetUserToken();
  // utils
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const [loading, setLoading] = useState(true);
  const [valid, setValid] = useState(false);
  const [save, setSave] = useState(false);
  // data
  const [last, setLast] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [content, setContent] = useState([]);
  const [hovering, setHovering] = useState(false);

  useBeforeunload((event) => {
    if (save !== false) {
      event.preventDefault();
    }
  });

  const handleInputChange = (e, i, subi) => {
    let contentArray = [...content];
    contentArray[i].content[subi].content = e.target.value;
    setSave(true);
    setContent(contentArray);
  };

  const fetchData = async () => {
    handleCallbackInfo([false, "No changes"]);
    setLoading(true);

    var requestOptions = {
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL +
      `/citations/getInfo?patient_id=` +
      patientID,
      requestOptions
    );

    const json = await res.json();

    let data = json.info;

    let filteredData = data.filter((element) => element[2] != null);
    setFiltered(filteredData);

    let pulseData = data.find((element) => element[2] !== null);
    setLast(pulseData);

    if (pulseData) {
      handleCallbackInfo([true, pulseData[1]]);
      setValid(true);
      setContent(JSON.parse(pulseData[2]));
      setLoading(false);
    }

    setLoading(false);
  };

  useEffect(() => {
    console.log("fill content: ", InfoTabs);
    setContent(InfoTabs);

    fetchData();
  }, []);

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    const body = { info: JSON.stringify(content) };

    fetch(process.env.REACT_APP_API_URL + `/citations/update/` + id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
        setSave(false);
        setLoading(false);
        fetchData();
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.Something went wrong, please try again"),
        });
        setLoading(false);
      });
  };

  const handleDateChange = (e) => {
    setContent(JSON.parse(e.target.value[2]));
    setLast(e.target.value);
    setSave(true);
  };

  return (
    <>
      <Accordion style={{ paddingRight: "0", paddingLeft: "0" }}>
        <Card
          className="reminders-cards"
          style={{ width: "93%", borderRadius: "10px" }}
        >
          <Card.Header
            className="reminders-subtitle"
            style={{
              border: "none",
              background: "white",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="0"
                className="text-center subtitles-patients"
                style={{ textDecoration: "none" }}
                id="accordion_info"
              >
                <span>&#8595; &nbsp;</span>
                {t("Titles.Info")}
              </Accordion.Toggle>
              <span className="subtitles-patients border-start">
                <img
                  src={edit_clock}
                  alt="clock"
                  onMouseEnter={() => setHovering(true)}
                  onMouseLeave={() => setHovering(false)}
                />
                {loading ? (
                  <></>
                ) : (
                  <>
                    {filtered.length !== 0 ? (
                      <>
                        <Select
                          id="treatment"
                          name="treatment"
                          value={last}
                          onChange={handleDateChange}
                          variant="standard"
                        >
                          {filtered.map((item, i) => (
                            <MenuItem key={`info-menu-item-${i}`} value={item} >
                              <b>{item[0].split("T")[0]}&nbsp; /&nbsp;</b>
                              {item[1].replace("T", " at: ").slice(0, -8)}
                              &nbsp;&nbsp;
                            </MenuItem>
                          ))}
                        </Select>
                        {hovering && (
                          <span className="key-text-box">
                            <b> {t("CompleteAppointment.Reference1")}</b>
                            {t("CompleteAppointment.Reference2")}
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        <span className="key-text-box">
                          &nbsp;{t("Messages.No previous records")}
                        </span>
                      </>
                    )}
                  </>
                )}
              </span>
            </div>
            <div>
              {save === false ? (
                <></>
              ) : (
                <button
                  className="button-citation-box-2-save"
                  onClick={handleSubmit}
                  style={{ padding: "6px" }}
                >
                  {t("Buttons.Save")}
                </button>
              )}
            </div>
          </Card.Header>

          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <form
                onSubmit={handleSubmit}
                className="row"
                style={{
                  background: "#f4f5fa",
                  borderRadius: "10px",
                  paddingLeft: "25px",
                  paddingRight: "25px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <CircularProgress color="secondary" />
                  </div>
                ) : (
                  <>
                    <div className="col-11">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="first"
                      >
                        <Row>
                          <Col sm={2} className="border-end">
                            <Nav variant="pills" className="flex-column">
                              {InfoTabs.map((item, i) => (
                                <Nav.Item key={`info-nav-item-${i}`} style={{ height: "43px" }}>
                                  <Nav.Link
                                    eventKey={item.id}
                                    className="reminders-subtitle info-nav-link"
                                  >
                                    {t(`Info.${item.name}`)}
                                  </Nav.Link>
                                  <br />
                                </Nav.Item>
                              ))}
                            </Nav>
                          </Col>
                          <Col sm={10} className="border-end text-center">
                            <Tab.Content>
                              {InfoTabs.map((item, i) => (
                                <Tab.Pane key={`info-pane-item-${i}`} eventKey={item.id}>
                                  {item.content.map((subitem, subi) => (
                                    <>
                                      <div
                                        key={`info-pane-item-div-${i}`}
                                        style={{
                                          display: "inline-block",
                                          flexFlow: "row wrap",
                                        }}
                                        className="mx-4"
                                      >
                                        <Card>
                                          <Card.Header
                                            className="reminders-subtitle text-start"
                                            style={{
                                              background:
                                                "var(--secondary-color)",
                                            }}
                                          >
                                            {t(`Info.${subitem.name}`)}
                                          </Card.Header>
                                          <Card.Body>
                                            <Card.Text>
                                              <TextareaAutosize
                                                name="content"
                                                value={
                                                  content[i].content[subi]
                                                    .content
                                                }
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    e,
                                                    i,
                                                    subi
                                                  )
                                                }
                                                aria-label="minimum height"
                                                minRows={6}
                                                style={{ width: 260 }}
                                              />
                                            </Card.Text>
                                          </Card.Body>
                                        </Card>
                                        <br />
                                      </div>
                                    </>
                                  ))}
                                </Tab.Pane>
                              ))}
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </div>

                    <div className="col-1 text-end px-0">
                      {/*                       {save == false ? (
                        <>
                          <button
                            style={{ border: "none", background: "none" }}
                          >
                            <span className="key-text-box-edit appointment-status-saved">
                              &nbsp;&nbsp;Saved&nbsp;&nbsp;
                            </span>
                          </button>
                        </>
                      ) : (
                        <>
                          <div className="unsaved-div">
                            <button
                              title="please save your changes"
                              style={{ border: "none", background: "none" }}
                            >
                              <span className="key-text-box-edit appointment-status-unsaved">
                                &nbsp;&nbsp;Click to save changes&nbsp;&nbsp;
                              </span>
                            </button>
                          </div>
                        </>
                      )} */}
                    </div>
                  </>
                )}
              </form>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      {isAlert.open && (
        <Alert
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
    </>
  );
};

export default Info;
