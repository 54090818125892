import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "bootstrap/dist/css/bootstrap.min.css";
import { makeStyles } from "@material-ui/core/styles";
import GetUserToken from "../../utils/GetUserToken";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Chart } from "react-google-charts";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import HelpIcon from "@material-ui/icons/HelpOutline";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "280px",
    textAlign: "center",
  },
  pos: {
    marginBottom: 12,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 120,
  },
  rootAccordion: {
    width: "100%",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const userToken = GetUserToken();

  // translations
  const { t, i18n } = useTranslation();
  // redux
  const state = useSelector((state) => state.user);
  const stateOne = useSelector((state) => state.companyStore.one.data);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState([]);
  const [chartData, setChartdata] = useState([]);
  const [totalByDate, setTotalByDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [citationsByDate, setCitationByDate] = useState("");
  const [doctors, setDoctors] = useState([]);
  const [doctorID, setDoctorID] = useState(null);
  const [startDateDoctor, setStartDateDoctor] = useState("");
  const [endDateDoctor, setEndDateDoctor] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [totalCitations, setTotalCitations] = useState("");
  const [totalAmount, setTotalAmount] = useState("");

  const [totalCanceled, setTotalCanceled] = useState("");
  const [totalShowed, setTotalShowed] = useState("");
  const [totalNotShowed, setTotalNotShowed] = useState("");
  const [totalPendingShowed, setTotalPendingShowed] = useState("");
  const [totalInvoiced, setTotalInvoiced] = useState("");
  const [totalPendingInvoiced, setTotalPendingInvoiced] = useState("");

  const spinner = (
    <div className="d-flex justify-content-center mt-2">
      <CircularProgress color="secondary" />
    </div>
  );

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip disableFocusListener {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 400,
      fontSize: "15px",
    },
  });

  const options = {
    title: "Invoices Status",
    sliceVisibilityThreshold: 0,
    titleTextStyle: {
      color: "#564c60",
      fontName: "Helvetica",
      fontSize: 15,
      bold: true,
    },
  };

  const getDoctors = async () => {
    setLoading(true);

    const url = process.env.REACT_APP_API_URL + "/citations/getDoctors";
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    try {
      const res = await fetch(url, requestOptions);
      const json = await res.json();
      setDoctors(json.clinicDoctors);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchData = async () => {
    setLoading(true);

    const url = process.env.REACT_APP_API_URL_V2 + "/dashboard";
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    try {
      const res = await fetch(url, requestOptions);
      const json = await res.json();
      setData(json);
      setChartdata([
        ["Task", "Hours per Day"],
        ["Paid", json.total_paid_invoices],
        ["Partially Paid", json.total_partialpaid_invoices],
        ["Unpaid", json.total_unpaid_invoices],
        ["Overdue", json.total_overdue_invoices],
        ["Canceled", json.total_cancel_invoices],
      ]);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchInvoicesBydate = async (e) => {
    setFetching(true);

    e.preventDefault();
    const url = `${process.env.REACT_APP_API_URL_V2}/dashboard/total_invoices_and_citations_by_date?start_date=${startDate}&end_date=${endDate}`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    try {
      const res = await fetch(url, requestOptions);
      const json = await res.json();
      console.log(json);
      setTotalByDate(json.total_invoices_in_date_range);
      setCitationByDate(json.total_citations_by_date);
      setFetching(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setFetching(false);
    }
  };

  const fetchTotalForDoctors = async (e) => {
    setFetching(true);

    e.preventDefault();

    const url = `${process.env.REACT_APP_API_URL_V2}/dashboard/total_for_doctors_and_citations?start_date=${startDateDoctor}&end_date=${endDateDoctor}&doctor_id=${doctorID}`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    try {
      const res = await fetch(url, requestOptions);
      const json = await res.json();
      console.log(json);
      setTotalCitations(json.total_citations);
      setTotalAmount(json.total_amount);
      setTotalCanceled(json.total_canceled_citations);
      setTotalShowed(json.total_showed_citations);
      setTotalNotShowed(json.total_not_showed);
      setTotalPendingShowed(json.total_pending_showed);
      setTotalInvoiced(json.total_invoiced_citations);
      setTotalPendingInvoiced(json.total_pending_invoiced_citations);
      setFetching(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setFetching(false);
    }
  };

  const fetchBirthDays = async (e) => {
    //setFetching(true);

    //e.preventDefault();
    const date = "2024-02-18";

    const url = `${process.env.REACT_APP_API_URL_V2}/dashboard/patients_birthdays?date=${date}`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    try {
      const res = await fetch(url, requestOptions);
      const json = await res.json();
      console.log(json);
      //setTotalCitations(json.total_citations);
      //setTotalAmount(json.total_amount);

      setFetching(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      //setFetching(false);
    }
  };

  const handleDoctorFilterChange = (event) => {
    setDoctorID(event.target.value);
  };

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value === "quarters") {
      // Calculate start and end dates for current quarter
      const today = new Date();
      let quarterStartDate;
      let quarterEndDate;
      if (today.getMonth() >= 0 && today.getMonth() <= 2) {
        // Quarter 1: January 1st to March 31st
        quarterStartDate = new Date(today.getFullYear(), 0, 1);
        quarterEndDate = new Date(today.getFullYear(), 2, 31);
      } else if (today.getMonth() >= 3 && today.getMonth() <= 5) {
        // Quarter 2: April 1st to June 30th
        quarterStartDate = new Date(today.getFullYear(), 3, 1);
        quarterEndDate = new Date(today.getFullYear(), 5, 30);
      } else if (today.getMonth() >= 6 && today.getMonth() <= 8) {
        // Quarter 3: July 1st to September 30th
        quarterStartDate = new Date(today.getFullYear(), 6, 1);
        quarterEndDate = new Date(today.getFullYear(), 8, 30);
      } else {
        // Quarter 4: October 1st to December 31st
        quarterStartDate = new Date(today.getFullYear(), 9, 1);
        quarterEndDate = new Date(today.getFullYear(), 11, 31);
      }
      setStartDateDoctor(quarterStartDate.toISOString().slice(0, 10));
      setEndDateDoctor(quarterEndDate.toISOString().slice(0, 10));
    } else if (event.target.value === "fiscal-year") {
      // Calculate start and end dates for current fiscal year (January 1st to December 31st)
      const today = new Date();
      const fiscalYearStartDate = new Date(today.getFullYear(), 0, 1);
      const fiscalYearEndDate = new Date(today.getFullYear(), 11, 31);
      setStartDateDoctor(fiscalYearStartDate.toISOString().slice(0, 10));
      setEndDateDoctor(fiscalYearEndDate.toISOString().slice(0, 10));
    } else if (event.target.value === "last-quarter") {
      // Calculate start and end dates for the last completed quarter (previous quarter ending on March 31st)
      const today = new Date();
      let lastQuarterStartDate;
      let lastQuarterEndDate;
      if (today.getMonth() >= 0 && today.getMonth() <= 2) {
        // Last quarter is Q4 of the previous year
        lastQuarterStartDate = new Date(today.getFullYear() - 1, 9, 1);
        lastQuarterEndDate = new Date(today.getFullYear() - 1, 11, 31);
      } else if (today.getMonth() >= 3 && today.getMonth() <= 5) {
        // Last quarter is Q1
        lastQuarterStartDate = new Date(today.getFullYear(), 0, 1);
        lastQuarterEndDate = new Date(today.getFullYear(), 2, 31);
      } else if (today.getMonth() >= 6 && today.getMonth() <= 8) {
        // Last quarter is Q2
        lastQuarterStartDate = new Date(today.getFullYear(), 3, 1);
        lastQuarterEndDate = new Date(today.getFullYear(), 5, 30);
      } else {
        // Last quarter is Q3
        lastQuarterStartDate = new Date(today.getFullYear(), 6, 1);
        lastQuarterEndDate = new Date(today.getFullYear(), 8, 30);
      }
      setStartDateDoctor(lastQuarterStartDate.toISOString().slice(0, 10));
      setEndDateDoctor(lastQuarterEndDate.toISOString().slice(0, 10));
    } else if (event.target.value === "last-fiscal-year") {
      // Calculate start and end dates for the last fiscal year (January 1st to December 31st of previous year)
      const lastFiscalYearStartDate = new Date(
        new Date().getFullYear() - 1,
        0,
        1
      );
      const lastFiscalYearEndDate = new Date(
        new Date().getFullYear() - 1,
        11,
        31
      );
      setStartDateDoctor(lastFiscalYearStartDate.toISOString().slice(0, 10));
      setEndDateDoctor(lastFiscalYearEndDate.toISOString().slice(0, 10));
    }
  };

  useEffect(() => {
    fetchData();
    getDoctors();
    //fetchBirthDays();
  }, []);

  return (
    <>
      <div className="frame">
        <div style={{ height: "100vh" }}>
          <h4 className="main-title mt-2">Dashboard</h4>

          {loading ? (
            spinner
          ) : (
            <>
              {/* ROW */}

              <div className="mt-4" style={{ display: "flex", gap: "10px" }}>
                <Card className={classes.root} variant="outlined">
                  <CardContent>
                    <Typography
                      className="home-reminders-title"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      {data.citations}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                      {t("Dashboard.Appointments booked")}
                    </Typography>
                    <hr />
                    <Typography
                      className="home-reminders-title"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      {data.total_canceled_citations}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                      {t("Dashboard.Appointments cancel or archived")}
                    </Typography>
                    <hr />
                    <Typography
                      className="home-reminders-title"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      {data.citations_by_patients}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                      {t("Dashboard.Externally booked")}
                    </Typography>
                    <hr />
                    <Typography
                      className="home-reminders-title"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      {data.upcoming_citations}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                      {t("Dashboard.Upcoming appointments")}
                    </Typography>
                  </CardContent>
                </Card>

                <Card
                  variant="outlined"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "280px",
                    textAlign: "center",
                  }}
                >
                  <CardContent>
                    <Typography
                      className="home-reminders-title"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      {data.patients}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                      {t("Dashboard.Patients")}
                    </Typography>
                    <hr />
                    <Typography
                      className="home-reminders-title"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      {data.total_patients_with_citations_in_both_months}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                      {t("Dashboard.Active Patients")}
                      <CustomWidthTooltip
                        title={
                          <span style={{ whiteSpace: "pre-line" }}>
                            {t("Dashboard.HelpIcontext1")}
                            <br />
                            {t("Dashboard.HelpIcontext2")}
                          </span>
                        }
                      >
                        &nbsp;
                        <HelpIcon style={{ color: "var(--primary-color)" }} />
                        &nbsp;
                      </CustomWidthTooltip>
                    </Typography>
                  </CardContent>
                </Card>

                <Card
                  className={classes.root}
                  variant="outlined"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    //width: "280px",
                    textAlign: "center",
                  }}
                >
                  <CardContent>
                    <Typography
                      className="home-reminders-title"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      {data.invoices}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                      {t("Dashboard.Invoices generated")}
                    </Typography>
                    <hr />
                    <Typography
                      className="home-reminders-title"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      {stateOne ? stateOne.currency : ""} {data.total_invoices}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                      {t("Dashboard.Gross Total")}
                    </Typography>
                    <hr />
                    <Typography
                      className="home-reminders-title"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      {stateOne ? stateOne.currency : ""}{" "}
                      {data.total_paid_invoices_gross}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                      {t("Dashboard.Gross Total")} {t("Dashboard.Paid")}
                    </Typography>
                  </CardContent>
                </Card>

                <Card
                  className={classes.root}
                  variant="outlined"
                  style={{ width: "480px" }}
                >
                  {chartData.length > 0 ? (
                    <Chart
                      chartType="PieChart"
                      data={chartData}
                      options={options}
                      width={"100%"}
                      height={"300px"}
                    />
                  ) : (
                    <></>
                  )}
                </Card>
              </div>

              {/* Row */}

              {/* ROW */}

              <div className="mt-3" style={{ display: "flex", gap: "10px" }}>
                <div
                  className={classes.rootAccordion}
                  style={{ backgroundColor: "white" }}
                >
                  <AccordionSummary>
                    <Typography
                      className="home-reminders-title"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Information by therapist {fetching ? "...loading" : <></>}
                    </Typography>
                  </AccordionSummary>

                  <form
                    className={classes.container}
                    onSubmit={fetchTotalForDoctors}
                    style={{
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    <FormControl style={{ width: "35%" }}>
                      <InputLabel>
                        {t("Calendar.Filter by Therapist")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={handleDoctorFilterChange}
                        defaultValue=""
                      >
                        <MenuItem value={null}>{t("Calendar.Select")}</MenuItem>
                        {doctors.map((doctor) => (
                          <MenuItem
                            key={doctor.id}
                            value={doctor.id}
                            style={{
                              color:
                                doctor.archived === false ? "none" : "#c0c0c0",
                              fontStyle:
                                doctor.archived === false ? "none" : "italic",
                            }}
                          >
                            {doctor.name}{" "}
                            {doctor.archived === false
                              ? ""
                              : t("CompleteAppointment.Archived")}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl style={{ width: "20%" }}>
                      <InputLabel id="date-range-select-label">
                        Date Range
                      </InputLabel>
                      <Select
                        labelId="date-range-select-label"
                        id="date-range-select"
                        value={selectedOption}
                        onChange={handleChange}
                      >
                        <MenuItem value="quarters">Quarter</MenuItem>
                        <MenuItem value="last-quarter">Last Quarter</MenuItem>

                        <MenuItem value="fiscal-year">Fiscal Year</MenuItem>
                        <MenuItem value="last-fiscal-year">
                          Last Fiscal Year
                        </MenuItem>

                        <MenuItem value="custom-date">Custom Date</MenuItem>
                      </Select>
                    </FormControl>

                    <TextField
                      disabled={selectedOption !== "custom-date"}
                      required
                      id="date"
                      label="From"
                      type="date"
                      value={startDateDoctor}
                      onChange={(e) => setStartDateDoctor(e.target.value)}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    <TextField
                      disabled={selectedOption !== "custom-date"}
                      required
                      id="date"
                      label="To"
                      type="date"
                      value={endDateDoctor}
                      onChange={(e) => setEndDateDoctor(e.target.value)}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    <button type="submit" className="blue-btn">
                      {t("Dashboard.Submit")}
                    </button>
                  </form>
                </div>
              </div>
              {/* RESULTS */}
              {totalCitations && (
                <>
                  {/* new */}

                  <div
                    className="mt-3"
                    style={{ display: "flex", gap: "10px" }}
                  >
                    <Card className={classes.root} variant="outlined">
                      <CardContent>
                        <Typography
                          className="home-reminders-title"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          {totalCitations}
                        </Typography>
                        <Typography
                          className={classes.pos}
                          color="textSecondary"
                        >
                          {t("Dashboard.Appointments booked")}
                        </Typography>
                        <hr />
                        <Typography
                          className="home-reminders-title"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          {totalCanceled}
                        </Typography>
                        <Typography
                          className={classes.pos}
                          color="textSecondary"
                        >
                          {t("Dashboard.Appointments cancel or archived")}
                        </Typography>
                        <hr />
                        <Typography
                          className="home-reminders-title"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          {totalShowed}
                        </Typography>
                        <Typography
                          className={classes.pos}
                          color="textSecondary"
                        >
                          {t("Dashboard.Appointments showed up")}
                        </Typography>
                        <hr />
                        <Typography
                          className="home-reminders-title"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          {totalPendingShowed}
                        </Typography>
                        <Typography
                          className={classes.pos}
                          color="textSecondary"
                        >
                          {t("Dashboard.Appointments pending showed up")}
                        </Typography>
                        <hr />
                        <Typography
                          className="home-reminders-title"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          {totalNotShowed}
                        </Typography>
                        <Typography
                          className={classes.pos}
                          color="textSecondary"
                        >
                          {t("Dashboard.Appointments not showed up")}
                        </Typography>
                      </CardContent>
                    </Card>
                    {/* new */}

                    <Card
                      className={classes.root}
                      variant="outlined"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        // width: "280px",
                        textAlign: "center",
                      }}
                    >
                      <CardContent>
                        <Typography
                          className="home-reminders-title"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          {totalInvoiced}
                        </Typography>
                        <Typography
                          className={classes.pos}
                          color="textSecondary"
                        >
                          {t("Dashboard.Appointments invoiced")}
                        </Typography>
                        <hr />
                        <Typography
                          className="home-reminders-title"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          {stateOne ? stateOne.currency : ""} {totalAmount}
                        </Typography>
                        <Typography
                          className={classes.pos}
                          color="textSecondary"
                        >
                          {t("Dashboard.Gross Total")}
                        </Typography>
                        <hr />
                        <Typography
                          className="home-reminders-title"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          {totalPendingInvoiced}
                        </Typography>
                        <Typography
                          className={classes.pos}
                          color="textSecondary"
                        >
                          {t("Dashboard.Appointments not invoiced yet")}
                        </Typography>
                      </CardContent>
                    </Card>

                    {/* new */}
                  </div>
                </>
              )}
            </>
          )}
          <div className="mt-5">&nbsp;</div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
