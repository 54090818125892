import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    "& .MuiPickersToolbar-toolbar": {
      backgroundColor: "var(--secondary-color) !important",
    },
    "& .MuiPickersDay-daySelected": {
      backgroundColor: "var(--primary-color) !important",
    },
    "& .MuiPickersStaticWrapper-staticWrapperRoot": {
      borderRadius: "5px",
    },
  },
  formControl: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: "500",
    fontSize: "13pt",
    color: "#8c8c8c",
    minWidth: 200,
  },
}));

export default useStyles;
