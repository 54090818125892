import GetUserToken from "../utils/GetUserToken";
import { parseJSON } from "../utils/parseJSON";

const urlBase = `${process.env.REACT_APP_API_URL}/doctors`;
const readUrl = (url = "") => `${urlBase}/${url}`;

class Doctor {
  async getOne({ id }) {
    const auth = `${GetUserToken()}`;

    let res = await fetch(readUrl(id), {
      method: "GET",
      headers: {
        Authorization: auth,
        "api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
    });

    res = await parseJSON(res);
    return res;
  }

  async getByUserId({ user_id }) {
    const auth = `${GetUserToken()}`;

    let res = await fetch(readUrl(`getDoctorByUserId/${user_id}`), {
      method: "GET",
      headers: {
        Authorization: auth,
        "api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
    });

    res = await parseJSON(res);
    return res;
  }

  async getAll() {
    console.log("getAll - doctors - service");
    const auth = `${GetUserToken()}`;

    let res = await fetch(readUrl(), {
      method: "GET",
      headers: {
        Authorization: auth,
        "api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
    });

    res = await parseJSON(res);
    return res;
  }

  async getAllByCompany({ id }) {
    const url = readUrl(`getDoctors?company_id=${id}`);

    let res = await fetch(url, {
      method: "GET",
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
    });

    res = await parseJSON(res);
    return res;
  }

  async getAllByTreatment({ treatment }) {
    const url = readUrl(`getDoctorsByTreatment?treatment=${treatment}`);

    let res = await fetch(url, {
      method: "GET",
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
    });

    res = await parseJSON(res);
    return res;
  }

  async createOne(payload) {
    const auth = `${GetUserToken()}`;

    let res = await fetch(readUrl("new"), {
      method: "POST",
      headers: {
        Authorization: auth,
        "api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
      body: payload,
    });

    res = await parseJSON(res);
    return res;
  }

  async update(payload) {
    const { id, ...rest } = payload;
    const auth = `${GetUserToken()}`;

    let res = await fetch(readUrl(id), {
      method: "PATCH",
      headers: {
        Authorization: auth,
        "api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(rest),
    });

    res = await parseJSON(res);
    return res;
  }
}

const doctor = new Doctor();
export default doctor;
