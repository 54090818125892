/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object} The parsed JSON, status from the response
 */
export async function parseJSON(response) {
  let json = {};

  try {
    json = await response.json();
  } catch (err) {
    "".toLowerCase();
  }

  json = {
    response: json,
    httpCode: response.status,
  }

  if (!response.ok) {
    const error = {
      error: { isSoftError: false },
      response,
      httpCode: response.status,
    };

    throw error;
  }

  return json;
}
