import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { CircularProgress, TextareaAutosize } from "@material-ui/core";
import GetUserToken from "../../utils/GetUserToken";

const NewDiagnosisOnboarding = ({
  isAlert,
  setIsAlert,
  handleStepComplete,
}) => {
  // translation
  const { t } = useTranslation();
  // auth
  const userToken = GetUserToken();
  // utils
  const [loading, setLoading] = useState(false);
  // data
  const [code, setCode] = useState("");
  const [name, setName] = useState("");

  const handleSubmit = async () => {
    setLoading(true);

    const body = {
      code: code,
      name: name,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      body: JSON.stringify(body),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/diagnosis/create`,
        requestOptions
      );

      if (response.ok) {
        await handleStepComplete();
        setName("");
        setCode("");
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
    } catch (error) {
      console.error("Error:", error);
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        maxHeight: "100%",
        overflowY: "auto",
        width: "100%",
        maxWidth: "500px",
        mr: "auto",
        ml: "auto",
        mt: "20px",
        backgroundColor: "#ffffff",
        border: "1px solid #f1f4fa",
        borderRadius: "16px",
        p: "26px",
      }}
    >
      <h4 className="main-title text-center mt-2">
        {t("Diagnosis.New Diagnosis")}
      </h4>
      <div className="form-group">
        <label className="title-inputs">{t("Diagnosis.Code")}</label>
        <input
          required
          type="text"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          className="input-control"
          disabled={loading}
        />
      </div>

      <div className="form-group">
        <label className="title-inputs">{t("Diagnosis.Name")}</label>
        <TextareaAutosize
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          aria-label="minimum height"
          minRows={3}
          className="input-control"
          disabled={loading}
        />
      </div>

      <div className="d-flex justify-content-center">
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <button
            className={
              !code || !name || isAlert.open ? "blue-btn-disabled" : "blue-btn"
            }
            id="create_diagnosis"
            disabled={!code || !name || isAlert.open}
            onClick={handleSubmit}
          >
            {t("NewProduct.3")}
          </button>
        )}
      </div>
    </Box>
  );
};

export default NewDiagnosisOnboarding;
