import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { CircularProgress, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import NewDiagnosis from "./NewDiagnosis";
import EditDiagnosis from "./EditDiagnosis";
import { ReactComponent as Pencil } from "../../assets/icons/pencil.svg";
import GetUserToken from "../../utils/GetUserToken";
import Table from "./DiagnosisTable";
import "../../assets/stylesheets/treatmentsStyles.css";

const DiagnosisList = () => {
  // translation
  const { t } = useTranslation();
  let currentLanguage = localStorage.getItem("i18nextLng");
  // auth
  const userToken = GetUserToken();
  // data
  const [data, setData] = useState();
  const [idDiagnosis, setIdDiagnosis] = useState("");
  // utils
  const [loading, setLoading] = useState(true);
  const [showmodal, setShowmodal] = useState(false);
  const [showupdatemodal, setShowupdatemodal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const columns = useMemo(
    () => [
      {
        // first group
        Header: " ",
        // First group columns
        columns: [
          {
            Header: <>{t("Diagnosis.Code")}</>,
            accessor: "code",
          },
          {
            Header: <>{t("Diagnosis.Name")}</>,
            accessor: "name",
          },
          {
            Header: " ",
            accessor: " ",
            Cell: ({ row }) => (
              <div style={{ cursor: "pointer", marginRight: "10px" }}>
                <Pencil
                  className="icon"
                  onClick={() => handleEdit(row.original.id)}
                />
              </div>
            ),
          },
        ],
      },
    ],
    [currentLanguage]
  );

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    var requestOptions = {
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
    };
    const res = await fetch(
      process.env.REACT_APP_API_URL + "/diagnosis/index",
      requestOptions
    );
    const json = await res.json();
    const sortedDiagnosis = json.diagnosis.sort((a, b) =>
      a.code > b.code ? 1 : b.code > a.code ? -1 : 0
    );
    setData(sortedDiagnosis);
    setLoading(false);
  };

  const handleEdit = (id) => {
    setIdDiagnosis(id);
    setShowupdatemodal(true);
  };

  return (
    <>
      <div className="frame">
        <h4 className="main-title mt-2">{t("Diagnosis.Diagnosis")}</h4>
        <br />

        <div style={{ marginRight: "50px" }}>
          <div className="container-fluid treatments-table">
            {loading ? (
              <div className="d-flex justify-content-center">
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <Table
                columns={columns}
                data={data}
                setShowmodal={setShowmodal}
              />
            )}
          </div>
        </div>
      </div>

      <NewDiagnosis
        showmodal={showmodal}
        setShowmodal={setShowmodal}
        setSuccess={setSuccess}
        setError={setError}
        fetchData={fetchData}
      />

      <Modal
        show={showupdatemodal}
        onHide={() => setShowupdatemodal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ background: "#F4F5FA" }}>
          <Modal.Title>{t("Diagnosis.Edit Diagnosis")}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#F4F5FA" }}>
          <EditDiagnosis
            updateDiagnosis={fetchData}
            idDiagnosis={idDiagnosis}
            setSuccessUpdating={setSuccess}
            setErrorUpdating={setError}
            setShowupdatemodal={setShowupdatemodal}
          />
        </Modal.Body>
      </Modal>

      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(!success)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSuccess(!success)}
          severity="success"
        >
          {t("Diagnosis.Diagnosis added!")}
        </MuiAlert>
      </Snackbar>

      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setSuccess(!error)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSuccess(!error)}
          severity="error"
        >
          {t("Diagnosis.Something went wrong... please try again!")}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default DiagnosisList;
