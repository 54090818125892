import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';

import logo from '../../assets/icons/Title.png';



export default function Signup() {
  
  const [fullname, setFullname] = useState();  
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [passwordConfirmation, setPasswordConfirmation] = useState();
  const [birth, setBirth] = useState();
  const [phone, setPhone] = useState();
  const [address, setAddress] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    const newUser = { fullname, email, password, passwordConfirmation, birth, phone, address };
    fetch( process.env.REACT_APP_API_URL + '/users/ ????',{
        
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newUser)

    }).then ( () => {
        window.location.href = "/";
    })

  }  



  return(
    <div className="container-fluid">
      <div className="row" style={{ height: '100vh' }}>
        <div className="col-6" style={{ paddingTop: "20px" }}>
         <img src={logo} style={{ paddingLeft: "80px" }} />
        
            <form onSubmit={handleSubmit} style={{ paddingLeft: "100px" }} className="mt-4">
                <h1 className="main-title">Register</h1>

                <div className="form-group mt-2">
                    <label className="title-inputs">Full Name</label>
                    <br/>
                    <input 
                        type="text"
                        value={fullname}
                        onChange={(e) => setFullname(e.target.value)}  
                        className="input-control"
                        placeholder="John Doe" 
                    />

                
                </div>

                <div className="form-group mt-2">
                    <label className="title-inputs">Email</label>
                    <br/>
                    <input 
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}    
                        className="input-control"
                        placeholder="name@domain.com" 
                    />

                
                </div>

                <div className="form-group mt-2">
                    <label className="title-inputs">Password</label>
                    <br/>
                    <input 
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}  
                        className="input-control"
                        placeholder="At least 6 characters" 
                    />

                </div>

                <div className="form-group mt-2">
                    <label className="title-inputs">Password Confirmation</label>
                    <br/>
                    <input 
                        type="password"
                        value={passwordConfirmation}
                        onChange={(e) => setPasswordConfirmation(e.target.value)}  
                        className="input-control"
                        placeholder="At least 6 characters" 
                    />

                </div>

                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                    <div className="form-group mt-2" style={{ paddingRight: '5px' }}>
                        <label className="title-inputs">Birthday</label>
                        <input
                            type="date"
                            value={birth}
                            onChange={(e) => setBirth(e.target.value)}  
                            className="form-control"
                            required
                        />
                    </div>

                    <div className="form-group mt-2" style={{ paddingLeft: '5px' }}>
                        <label className="title-inputs">Phone</label>
                        <input
                            type="text"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}  
                            className="form-control"
                            placeholder="08003332244"
                            required
                        />
                    </div>

                </div>

                <div className="form-group mt-2">
                    <label className="title-inputs">Address</label>
                    <br/>
                    <input 
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        className="input-control"
                        placeholder="Düsseldorf Deutschland"
                        required 
                    />

                </div>

                <div className="form-group mt-2">
                <Checkbox
                    defaultChecked
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    style={{ color: '#38baff' }}
                />
                <span>I agree to all <a className="blue-anchors">Terms</a> and <a className="blue-anchors">Privacy Policy</a></span>

                </div>

                <button type="submit" className="blue-btn" style={{ width: '100%' }}>Sign up</button>
            </form>

          <div className="text-center mt-2">
            <span>Already have account? </span><a href="/login" className="blue-anchors">Log in</a>
          </div>

        </div>

        <div className="col-6 signup-background">

        </div>
   
      </div>
    </div>
  )
}
