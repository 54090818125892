import React from "react";
import { useTranslation } from "react-i18next";
import { FormControl, Typography } from "@material-ui/core";
import useStyles from "./styles";

const TimeSelect = ({
  loading,
  dayCurrent,
  dateSelector,
  currentTime,
  timeSelected,
  handleTimeChange,
}) => {
  // translations
  const { t } = useTranslation();
  // styles
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <h3 className="subTitles">{t("NeueTermin.14")}</h3>
      {dateSelector &&
        (loading ? (
          <Typography className={classes.loading}>
            {t("FirstStep.loading")}...
          </Typography>
        ) : !dayCurrent.length ? (
          <Typography className={classes.loading}>
            {t("FirstStep.No schedules are available.")}
          </Typography>
        ) : (
          <FormControl component="fieldset">
            <div className={classes.Itemscontainer}>
              {dayCurrent
                .filter((option) => option > currentTime)
                .map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`${classes.timeItem} ${
                        timeSelected === item ? "selected" : ""
                      }`}
                      onClick={() => handleTimeChange(item)}
                    >
                      {item.split("T")[1].split(":00.000")[0]}
                    </div>
                  );
                })}
            </div>
          </FormControl>
        ))}
    </div>
  );
};

export default TimeSelect;
