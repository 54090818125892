import { symptoms } from "../../../../../../constants/pulse"

const getSymptom = (key) => {
    return symptoms.find(symptom => symptom.id === key)
}
  
export const initialFormData = (value) =>  {
    return {
      "right.kidney": getSymptom(value?.right?.kidney  ?? ""),
      "right.spleen": getSymptom(value?.right?.spleen  ?? ""),
      "right.lung": getSymptom(value?.right?.lung  ?? ""),
      "left.kidney": getSymptom(value?.left?.kidney  ?? ""),
      "left.liver": getSymptom(value?.left?.liver  ?? ""),
      "left.heart": getSymptom(value?.left?.heart  ?? ""),
    }
};

export const getValue = (newValue) => {
  let value = symptoms.find(
    ({ title, nameEN, nameES }) =>
      title.replace(/\t/g, " ") === newValue ||
      nameEN === newValue ||
      nameES === newValue
  );
  return value;
};
