import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { SliderPicker } from "react-color";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import AlertComponent from "../../alerts/Alert";
import GetUserToken from "../../../utils/GetUserToken";
import ImageCropMaterialUi from "../ImageCropMaterialUi";

const NewDoctor = ({ infoRoute }) => {
  // translation
  const { t } = useTranslation();
  // auth
  const userToken = GetUserToken();
  // route
  const history = infoRoute.history;
  // redux
  const state = useSelector((state) => state.companyStore.one.data);
  //utils
  const [loading, setLoading] = useState(true);
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  //data
  const [fullname, setFullname] = useState("");
  const [userId, setUserId] = useState("");
  const [color, setColor] = useState("#ff66ff");
  const [signature, setSignature] = useState();
  const [clinicTreatments, setClinicTreatments] = useState([]);
  const [clinicUsers, setClinicUsers] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [bio, setBio] = useState("");
  const [distinctions, setDistinctions] = useState("");
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const getTreatments = async () => {
    const url = process.env.REACT_APP_API_URL + "/doctors/getTreatments";

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    let response = await fetch(url, requestOptions);
    response = await response.json();

    const filteredTreatments = response.clinicTreatments.filter((item) => {
      return item.archived === false;
    });
    let availableTreatments = filteredTreatments.map((item) => ({
      id: item.id,
      name: item.name,
    }));
    setClinicTreatments(availableTreatments);
  };

  const fetchData = async () => {
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL + "/doctors/getUsers",
      requestOptions
    );

    const json = await res.json();
    setClinicUsers(json.clinicUsers);
    const last = json.clinicUsers.at(-1);
    setUserId(last.id);
    setFullname(last.fullname);
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    // Create FormData object
    const formData = new FormData();
    formData.append("name", fullname);
    formData.append("user_id", userId);
    formData.append("color", color);
    formData.append("bio", bio);
    formData.append("distinctions", distinctions);
    !!treatments && formData.append("treatments", JSON.stringify(treatments));
    formData.append("archived", "true"); // Assuming archived is always true initially
    !!signature && formData.append("signature", signature);

    const url = process.env.REACT_APP_API_URL + "/doctors/create";

    var requestOptions = {
      method: "POST",
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      body: formData,
    };

    try {
      const res = await fetch(url, requestOptions);

      if (res.ok) {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
        setTimeout(() => {
          history.push("/doctors");
        }, 1000);
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.An unexpected error occurred"),
      });
    } finally {
      setLoading(false);
    }
  };

  function handleUserChange(e, value) {
    setUserId(value.id);
    setFullname(value.fullname);
  }

  function handleTreatmentChange(e, value) {
    setTreatments(value);
  }

  const handleSignatureChange = (event) => {
    setSignature(event.target.value[0]);
  };

  useEffect(() => {
    fetchData();
    getTreatments();
  }, []);

  return (
    <>
      <form className="frame pb-3" onSubmit={handleSubmit}>
        <h4 className="main-title-treatments">{t("NewDoctor.5")}</h4>

        {loading ? (
          <div className="d-flex justify-content-center">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="col-8 mt-3">
              <h4 className="main-subtitle-treatments">
                1) {t("NewDoctor.0")}:{" "}
              </h4>
              <br />
              <Autocomplete
                required
                disabled={true}
                id="user"
                name="user"
                options={clinicUsers}
                onChange={(e, value) => handleUserChange(e, value)}
                getOptionLabel={(option) => option.email}
                inputValue={clinicUsers["id"]}
                defaultValue={clinicUsers.at(-1)}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
            <br />
            <div className="col-8 mt-3">
              <h4 className="main-subtitle-treatments">
                2) {t("NewDoctor.1")}:{" "}
              </h4>
              <input
                required
                type="text"
                className="form-control"
                name="fullname"
                label="name"
                value={fullname}
                onChange={(e) => setFullname(e.target.value)}
              />
            </div>
            <br />
            <div className="col-8 mt-3">
              <h4 className="main-subtitle-treatments">
                3) {t("NewDoctor.2")}:{" "}
              </h4>
              <br />
              <SliderPicker
                required
                color={color}
                onChangeComplete={(color) => {
                  setColor(color.hex);
                }}
              />
            </div>
            <br />
            <div className="col-8 mt-3">
              <h4 className="main-subtitle-treatments">
                4){t("EditDoctor.Therapist")}-Bio
              </h4>
              <br />
              <TextareaAutosize
                type="text"
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                aria-label="minimum height"
                minRows={2}
                className="input-control"
              />
            </div>
            <div className="col-8 mt-3">
              <h4 className="main-subtitle-treatments">
                5) {t("EditDoctor.Distinctions")}
              </h4>
              <br />
              <TextareaAutosize
                type="text"
                value={distinctions}
                onChange={(e) => setDistinctions(e.target.value)}
                aria-label="minimum height"
                minRows={2}
                className="input-control"
              />
            </div>

            <div className="col-8 mt-3">
              <h4 className="main-subtitle-treatments">
                6) {t("NewDoctor.3")}:{" "}
              </h4>
              <br />
              <Autocomplete
                required
                multiple
                name="treatments"
                id="treatments"
                options={clinicTreatments}
                disableCloseOnSelect
                onChange={(e, value) => {
                  handleTreatmentChange(e, value);
                }}
                getOptionLabel={(option) => option.name}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8, color: "var(--primary-color)" }}
                      checked={selected}
                    />
                    {option.name}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Treatments"
                    placeholder="Select"
                    fullWidth
                  />
                )}
              />
            </div>
            <br />
            {/* 
            <div className="col-8 mt-3">
              <h4 className="main-subtitle-treatments">
                5) {t("NewDoctor.4")}:{" "}
              </h4>
              <br />
                          <div className="form-group">
                <label
                  htmlFor="color"
                  className="title-inputs"
                  style={{
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                >
                  {t("EditDoctor.Signature")}
                </label>
                <ImageCropMaterialUi
                  name="signature"
                  defaultValue={signature}
                  showZoomControl
                  showRotationControl
                  onInput={(event) => handleSignatureChange(event)}
                />
              </div> 
            </div>
            */}
            <br />
            <div className="col-8 mt-3">
              <Stack sx={{ width: "100%" }} spacing={2}>
                <Alert
                  severity="warning"
                  style={{ justifyContent: "center" }}
                  className="title-inputs"
                >
                  {t("EditDoctor.CreateWarning")}
                  <br />
                  {`https://${state.slug}.tcmassist.org/forgot-password`}
                </Alert>
              </Stack>
              <div className="d-flex justify-content-center">
                <button type="submit" className="btnGo mt-2">
                  SAVE
                </button>
              </div>
            </div>
          </>
        )}
      </form>
      {isAlert.open && (
        <AlertComponent
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
    </>
  );
};

export default NewDoctor;
