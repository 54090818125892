// DEV: if name value is modified, you must also update that value in the translation files.

const FeuerInfo = [
  { id: 0, title: "Schlaf", name: "Sleep", content: "" },
  { id: 1, title: "Schweiss", name: "Sweat", content: "" },
  { id: 2, title: "Sprache", name: "Language", content: "" },
  { id: 3, title: "Beziehungen", name: "Relationships", content: "" },
  { id: 4, title: "Palpitationen", name: "Palpitations", content: "" },
  {
    id: 5,
    title: "Temperaturempfindung",
    name: "Temperature sensation",
    content: "",
  },
];

const ErdeInfo = [
  { id: 0, title: "Appetit", name: "Appetite", content: "" },
  { id: 1, title: "Durst", name: "Thirst", content: "" },
  { id: 2, title: "Prolaps/Ödeme", name: "Prolapse/edema", content: "" },
  { id: 3, title: "Lippen", name: "Lips", content: "" },
  { id: 4, title: "Ernährung", name: "Nutrition", content: "" },
  { id: 5, title: "Magen", name: "Stomach", content: "" },
  { id: 6, title: "Kinder", name: "Children", content: "" },
  { id: 7, title: "Problemerfassung", name: "Problem detection", content: "" },
];

const MetalInfo = [
  { id: 0, title: "Haut/Körperhaar", name: "Skin/body hair", content: "" },
  { id: 1, title: "Verdauung", name: "Digestion", content: "" },
  {
    id: 2,
    title: "Erkältungen/Infekte",
    name: "Colds/Infections",
    content: "",
  },
  { id: 3, title: "Atemwege", name: "Respiratory tract", content: "" },
  { id: 4, title: "Ausdauer", name: "Endurance", content: "" },
  { id: 5, title: "Raucher", name: "Smoker", content: "" },
  { id: 6, title: "Trennungen/Trauer", name: "Separations/grief", content: "" },
];

const WasserInfo = [
  { id: 0, title: "Urin", name: "Urine", content: "" },
  { id: 1, title: "Kopfhaar", name: "Scalp hair", content: "" },
  { id: 2, title: "Knochen/Zähne", name: "Bones/Teeth", content: "" },
  { id: 3, title: "Menstruation", name: "Menstruation", content: "" },
  { id: 4, title: "Vital-Energie", name: "Vital energy", content: "" },
  { id: 5, title: "Ohren", name: "Ears", content: "" },
  { id: 6, title: "Sexualität", name: "Sexuality", content: "" },
  { id: 7, title: "Rauschmittel", name: "Intoxicants", content: "" },
  { id: 8, title: "Ängste", name: "Fears", content: "" },
];

const HolzInfo = [
  { id: 0, title: "Muskel", name: "Muscle", content: "" },
  { id: 1, title: "Tremor/Tics", name: "Tremor/tics", content: "" },
  { id: 2, title: "Augen", name: "Eyes", content: "" },
  { id: 3, title: "Träume", name: "Dreams", content: "" },
  { id: 4, title: "Bewegung/Sport", name: "Movement/Sports", content: "" },
  { id: 5, title: "Beruf", name: "Occupation", content: "" },
  { id: 6, title: "Ärger", name: "Anger", content: "" },
];

export const InfoTabs = [
  { id: 0, title: "Feuer", name: "Fire", content: FeuerInfo },
  { id: 1, title: "Erde", name: "Earth", content: ErdeInfo },
  { id: 2, title: "Metall", name: "Metal", content: MetalInfo },
  { id: 3, title: "Wasser", name: "Water", content: WasserInfo },
  { id: 4, title: "Holz", name: "Wood", content: HolzInfo },
];
