import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from "@material-ui/core/Switch";
import GetUserToken from "../../utils/GetUserToken";

export const EditInsurance = ({
  insurance,
  updateInsurances,
  setShowupdatemodal,
  setIsAlert,
}) => {
  // auth
  const userToken = GetUserToken();
  // translation
  const { t } = useTranslation();
  // data
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isAvailable, setIsAvailable] = useState(false);
  // utils
  const [saving, setSaving] = useState(false);

  const fetchData = async () => {
    const url = process.env.REACT_APP_API_URL + "/insurances/show/" + insurance;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    const res = await fetch(url, requestOptions);
    const json = await res.json();
    setTitle(json.title);
    setDescription(json.description);
    setIsAvailable(json.is_available);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async () => {
    setSaving(true);

    const url = process.env.REACT_APP_API_URL + "/insurances/update/";

    const body = {
      title: title,
      description: description,
      is_available: isAvailable,
    };

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      body: JSON.stringify(body),
    };

    await fetch(url + insurance, requestOptions)
      .then((response) => {
        if (response.ok) {
          setIsAlert({
            open: true,
            severity: "success",
            message: t("Messages.The operation was successful"),
          });
          setShowupdatemodal(false);
          updateInsurances();
        } else {
          setIsAlert({
            open: true,
            severity: "error",
            message: t("Messages.The operation could not be processed"),
          });
        }
      })
      .catch((error) =>
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.Something went wrong, please try again"),
        })
      );
  };

  const handleSwitchChange = (event) => {
    setIsAvailable(event.target.checked);
  };

  return (
    <>
      <div className="form-group">
        <label className="title-inputs">{t("Insurances.Title")}</label>
        <input
          required
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="input-control"
        />
      </div>

      <div className="form-group">
        <label className="title-inputs">
          {t("Insurances.Available for patientes")}
        </label>
        <br />
        <Switch
          checked={isAvailable}
          onChange={handleSwitchChange}
          name="availability"
          color="primary"
        />
        <span>{isAvailable ? t("Insurances.Yes") : t("Insurances.No")}</span>
      </div>
      <div className="form-group">
        <label className="title-inputs">{t("Insurances.Description")}</label>
        <TextareaAutosize
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          aria-label="minimum height"
          minRows={3}
          className="input-control"
        />
      </div>
      <div className="d-flex justify-content-center">
        {saving ? (
          <>
            <CircularProgress color="secondary" />
          </>
        ) : (
          <>
            {title != "" ? (
              <>
                <button className="blue-btn" onClick={handleSubmit}>
                  {t("Buttons.Save")}
                </button>
              </>
            ) : (
              <>
                <button disabled className="blue-btn-disabled">
                  {t("Buttons.Save")}
                </button>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default EditInsurance;
