import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { getAllBlends } from "../../../../redux/slices/blend";
import { getAllDoctors } from "../../../../redux/slices/doctor";
import { getAllPatients } from "../../../../redux/slices/patient";
import { getAllPharmacies } from "../../../../redux/slices/pharmacy";
import { getAllHerbs } from "../../../../redux/slices/herb";
import { getOnePrescription } from "../../../../redux/slices/prescription";
import datesHandler from "../../../../helpers/dates";
import FieldLoader from "../FieldLoader";
import HerbSelectField from "./components/HerbSelectField";
import RowDropdownMenu from "./components/RowDropdownMenu";
import TableDropdownMenu from "./components/TableDropdownMenu";
import TableEmptyState from "./components/TableEmptyState";
import {
  Link,
  Sheet,
  Box,
  Paragraph,
  Select,
  Section,
  Logo,
  TableTitle,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  TdButton,
} from "./styles";

const Component = ({
  isLoading,
  isPreview,
  initialValues,
  company,
  companyLoading,
  companyError,
  companySuccess,
  patients,
  patientsLoading,
  patientsError,
  patientsSuccess,
  doctors,
  doctorsLoading,
  doctorsError,
  doctorsSuccess,
  pharmacies,
  pharmaciesLoading,
  pharmaciesError,
  pharmaciesSuccess,
  blends,
  blendsLoading,
  blendsError,
  blendsSuccess,
  herbs,
  herbsLoading,
  herbsSuccess,
  herbsError,
  prescription,
  validated,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [selectedPharmacy, setSelectedPharmacy] = useState();
  const [selectedPatient, setSelectedPatient] = useState();
  const [selectedDoctor, setSelectedDoctor] = useState();
  const [addedBlends, setAddedBlends] = useState([]);
  const [patientCitations, setPatientCitations] = useState([]);

  const getBlendName = useCallback(
    (id, language) => {
      const selectedBlend = blends?.data.find((blend) => blend?.id === id);
      return selectedBlend ? selectedBlend[language] : "";
    },
    [blends]
  );

  const handleChange = (event) => {
    if (!event.target) {
      console.error("Event was not found.");
    }

    const { name, value, type, required } = event.target;

    setValues((prevValues) => {
      const newValues = { ...prevValues };

      if (value === "") {
        delete newValues[name];
      } else {
        newValues[name] = value;
      }

      return newValues;
    });

    // Validate the required field
    if (
      ((type === "text" || type === "textarea") &&
        value.trim() === "" &&
        required) ||
      (type === "file" && !value && required)
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "This field is required",
      }));
    } else {
      // Clear the error if the field is not empty
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  const addBlend = (idBlend) => {
    if (idBlend) {
      const newAddedBlend = blends.data.find(
        (blend) => blend.id === Number(idBlend)
      );

      setAddedBlends((oldState) => [
        ...oldState,
        { id: newAddedBlend.id, herbs: newAddedBlend.herb },
      ]);
    } else {
      setAddedBlends((oldState) => [
        ...oldState,
        { id: null, herbs: [{ qty: null, herb: null }] },
      ]);
    }
  };

  const addHerb = (selectedBlend, selectedBlendIndex) => {
    const updatedAddedBlends = addedBlends.map((addedBlend, index) => {
      if (index !== selectedBlendIndex) {
        return addedBlend;
      } else {
        return {
          ...addedBlend,
          herbs: [...addedBlend.herbs, { herb: null, qty: null }],
        };
      }
    });

    setAddedBlends(updatedAddedBlends);
  };

  const changeHerb = (field, event, selectedBlendIndex, selectedHerbIndex) => {
    const updatedAddedBlends = addedBlends.map((blend, blendIndex) => {
      if (blendIndex !== selectedBlendIndex) {
        return blend;
      } else {
        const updatedBlendHerbs = blend.herbs.map((herb, herbIndex) => {
          if (herbIndex !== selectedHerbIndex) {
            return herb;
          } else {
            return { ...herb, [field]: Number(event.target.value) };
          }
        });

        return { ...blend, herbs: updatedBlendHerbs };
      }
    });

    setAddedBlends(updatedAddedBlends);
  };

  const removeHerb = (selectedBlendIndex, selectedHerbIndex) => {
    const updatedAddedBlends = addedBlends.map((blend, blendIndex) => {
      if (blendIndex !== selectedBlendIndex) {
        return blend;
      } else {
        const updatedBlendHerbs = blend.herbs.filter(
          (herb, herbIndex) => herbIndex !== selectedHerbIndex
        );
        return { ...blend, herbs: updatedBlendHerbs };
      }
    });

    setAddedBlends(updatedAddedBlends);
  };

  const resetChanges = () => {};

  const removeAll = () => {};

  function getCurrentDate() {
    const currentDate = new Date();

    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = currentDate.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  }

  const getTotal = (key, elements) => {
    if (!!elements) {
      return elements?.herbs?.reduce((suma, elemento) => {
        const number =
          typeof elemento[key] === "number" || typeof elemento[key] === "string"
            ? Number(elemento[key])
            : 0;
        return suma + number;
      }, 0);
    }

    return 0;
  };

  useEffect(() => {
    dispatch(
      getAllBlends({
        currentPage: 1,
        sortBy: "created_at",
        sortDirection: "desc",
        itemsPerPage: 5000,
      })
    );
    dispatch(getAllHerbs());
    dispatch(getAllDoctors());
    dispatch(getAllPatients());
    dispatch(
      getAllPharmacies({
        currentPage: 1,
        sortBy: "created_at",
        sortDirection: "desc",
        itemsPerPage: 5000,
      })
    );
    if (initialValues.id) {
      dispatch(getOnePrescription(initialValues.id));
    }
  }, []);

  useEffect(() => {
    if (values.pharmacy_id && !!pharmacies) {
      setSelectedPharmacy(
        prescription
          ? prescription?.pharmacy
          : pharmacies?.data?.find(
              (pharmacy) => pharmacy.id === Number(values.pharmacy_id)
            )
      );
    }

    if (values.doctor_id && !!doctors) {
      setSelectedDoctor(
        doctors.find((doctor) => doctor.id === Number(values.doctor_id))
      );
    }

    if (values.patient_id && !!patients) {
      setSelectedPatient(
        patients.find((patient) => patient.id === Number(values.patient_id))
      );
    }

    if (values.blends) {
      setAddedBlends(values.blends);
    }

    if (values.patient_id && !!patients) {
      const patient = patients.find(
        (item) => Number(item.id) === Number(values.patient_id)
      );

      if (patient && patient.citations) {
        const patientCitations = patient.citations.filter(
          (item) => !item.invoiced && !item.archived
        );

        const sortedCitations = patientCitations.sort((a, b) => {
          // Comparar por la propiedad 'time' de forma descendente
          const timeA = new Date(a.time).getTime();
          const timeB = new Date(b.time).getTime();
          return timeB - timeA;
        });

        // Obtener las citas 'invoiced' y 'archived'
        const invoicedAndArchivedCitations = patient.citations.filter(
          (item) => item.invoiced || item.archived
        );

        // Concatenar las citas ordenadas con las 'invoiced' y 'archived' al final
        const finalPatientCitations = sortedCitations.concat(
          invoicedAndArchivedCitations
        );

        setPatientCitations(finalPatientCitations);
      }
    }
  }, [values, pharmacies, doctors, patients, prescription]);

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (initialValues.blend_id && blends && addedBlends.length === 0) {
      addBlend(Number(initialValues.blend_id));
    }
  }, [initialValues, blends]);

  return (
    <>
      <Section style={{ gap: 16 }}>
        <Form.Group className="mb-3" controlId="state">
          <Form.Label>
            <b>{t("PrescriptionForm.Status")}</b>
          </Form.Label>

          <FieldLoader loading={isLoading}>
            <Form.Control
              as="select"
              className="form-select"
              name="state"
              defaultValue={initialValues["state"]}
              onInput={handleChange}
              disabled={isLoading || isPreview}
              required
              isInvalid={validated}
            >
              <option value="issued">
                {t("PrescriptionFormSelectors.State issued")}
              </option>
              <option value="draft">
                {t("PrescriptionFormSelectors.State draft")}
              </option>
              <option value="cancelled">
                {t("PrescriptionFormSelectors.State cancelled")}
              </option>
            </Form.Control>
          </FieldLoader>

          <Form.Control.Feedback type="invalid">
            {t("Messages.Required field")}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="doctor_id">
          <Form.Label>
            <b> {t("PrescriptionForm.Therapist")}</b>
          </Form.Label>

          <FieldLoader loading={isLoading || doctorsLoading}>
            <Form.Control
              as="select"
              className="form-select"
              name="doctor_id"
              value={values.doctor_id}
              onChange={handleChange}
              disabled={isLoading || doctorsLoading || isPreview}
              required
              isInvalid={validated}
            >
              <option className="d-none" value="">
                {t("PrescriptionForm.Select a therapist")}
              </option>
              {!!doctors && doctors.length > 0 ? (
                doctors.map((option, i) => (
                  <option key={i} value={option.id}>
                    {option.name}
                  </option>
                ))
              ) : (
                <></>
              )}
            </Form.Control>
          </FieldLoader>

          <Form.Control.Feedback type="invalid">
            {t("Messages.Required field")}
          </Form.Control.Feedback>
        </Form.Group>
      </Section>

      <Sheet className="mb-3">
        {/* Company */}
        <Section>
          <Logo>
            <img
              src={company ? company.logo?.url : ""}
              alt="logo"
              class="makeStyles-tcmLogo-3"
            ></img>
          </Logo>

          <div>
            <Paragraph textRight>
              {company ? company.city : ""} - {getCurrentDate()}
            </Paragraph>
            <Paragraph textRight>
              <b>Praxis Qi Point</b>
            </Paragraph>
            <Paragraph textRight>
              <b>{company ? company.business_name : ""}</b>
            </Paragraph>
            <Paragraph textRight>
              <b>
                {company
                  ? company.street +
                    " " +
                    company.street_number +
                    " " +
                    company.zip +
                    " " +
                    company.city
                  : ""}
              </b>
            </Paragraph>
          </div>
        </Section>

        <div style={{ height: 40 }} />

        <Section style={{ gap: 16 }}>
          <div>
            <Form.Group className="mb-3" controlId="pharmacy_id">
              <Form.Label>
                <b>{t("PrescriptionForm.Correspondent")}</b>
              </Form.Label>

              <FieldLoader loading={isLoading || pharmaciesLoading}>
                <Form.Control
                  required
                  as="select"
                  onChange={handleChange}
                  disabled={isLoading || pharmaciesLoading || isPreview}
                  value={values.pharmacy_id}
                  defaultValue={initialValues["pharmacy_id"]}
                  name="pharmacy_id"
                  className="form-select"
                  style={{ cursor: "pointer" }}
                >
                  <option className="d-none" value="">
                    {t("PrescriptionForm.Select a pharmacy")}
                  </option>

                  {!!pharmacies &&
                    pharmacies.data &&
                    pharmacies?.data?.length > 0 &&
                    pharmacies?.data?.map((option, i) => (
                      <option key={i} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                </Form.Control>
              </FieldLoader>

              {!!errors["pharmacy_id"] && (
                <Form.Control.Feedback type="invalid">
                  {t("Messages.Required field")}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <div>
              <Paragraph>
                {selectedPharmacy &&
                  selectedPharmacy.street +
                    " " +
                    selectedPharmacy.number +
                    " " +
                    selectedPharmacy.town}
              </Paragraph>
              <Paragraph>
                {selectedPharmacy &&
                  selectedPharmacy.zip +
                    " " +
                    selectedPharmacy.province +
                    " " +
                    selectedPharmacy.country}
              </Paragraph>
            </div>
          </div>

          <div>
            <div style={{ height: 32 }} />
            <Box>
              <Paragraph>
                <b>
                  {t(
                    "PrescriptionForm.If you have any questions, contact the pharmacy"
                  )}
                </b>
              </Paragraph>
              <Paragraph>
                {t("PrescriptionForm.Phone")}:{" "}
                {selectedPharmacy &&
                  selectedPharmacy.country_code +
                    " " +
                    selectedPharmacy.phone_number}
              </Paragraph>
              <Paragraph>
                {t("PrescriptionForm.Email")}:{" "}
                {selectedPharmacy && selectedPharmacy.email}
              </Paragraph>
            </Box>
          </div>
        </Section>

        <div style={{ height: 40 }} />

        {/* Status and Shipping method */}
        <Section style={{ gap: 16 }}>
          <Form.Group className="mb-3" controlId="prescription_type">
            <Form.Label>
              <b>{t("PrescriptionForm.Type")}</b>
            </Form.Label>

            <FieldLoader loading={isLoading}>
              <Form.Control
                required
                as="select"
                onChange={handleChange}
                disabled={isLoading || isPreview}
                value={values.prescription_type}
                defaultValue={initialValues["prescription_type"]}
                name="prescription_type"
                className="form-select"
                style={{ cursor: "pointer" }}
              >
                <option value="new">
                  {t("PrescriptionFormSelectors.New")}
                </option>
                <option value="edited">
                  {t("PrescriptionFormSelectors.Edited")}
                </option>
                <option value="duplicated" disabled>
                  {t("PrescriptionFormSelectors.Duplicated")}
                </option>
              </Form.Control>
            </FieldLoader>

            {!!errors["prescription_type"] && (
              <Form.Control.Feedback type="invalid">
                {t("Messages.Required field")}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          {values.prescription_id && (
            <>
              <div style={{ height: 86, width: 1, backgroundColor: "gray" }} />

              <div>
                <Paragraph style={{ marginBottom: ".5rem" }}>
                  <b>{t("PrescriptionForm.Prescription No.")}</b>
                </Paragraph>
                <Paragraph style={{ lineHeight: "38px" }}>
                  <span>{values.prescription_id}</span>
                </Paragraph>
              </div>
            </>
          )}

          <div style={{ height: 86, width: 1, backgroundColor: "gray" }} />

          <Form.Group className="mb-3" controlId="delivery_method">
            <Form.Label>
              <b>{t("PrescriptionForm.Shipping method")}</b>
            </Form.Label>

            <FieldLoader loading={isLoading}>
              <Form.Control
                required
                as="select"
                onChange={handleChange}
                disabled={isLoading || isPreview}
                value={values.delivery_method}
                defaultValue={initialValues["delivery_method"]}
                name="delivery_method"
                className="form-select"
                style={{ cursor: "pointer" }}
              >
                <option value="post">
                  {t("PrescriptionFormSelectors.Post")}
                </option>
                <option value="personally">
                  {t("PrescriptionFormSelectors.Personally")}
                </option>
              </Form.Control>
            </FieldLoader>

            {!!errors["delivery_method"] && (
              <Form.Control.Feedback type="invalid">
                {t("Messages.Required field")}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Section>

        <div style={{ height: 40 }} />

        <Section style={{ gap: 16 }}>
          <div>
            {!!addedBlends && addedBlends.length <= 0 ? (
              <TableEmptyState />
            ) : (
              <>
                {!!addedBlends &&
                  addedBlends.map((addedBlend, blendIndex) => (
                    <>
                      {addedBlend.id ? (
                        <>
                          <input
                            type="hidden"
                            name={`blends.${blendIndex}.id`}
                            value={addedBlend?.id}
                          />
                          <TableTitle>
                            {t("PrescriptionForm.Blend selected")}:{" "}
                            <b>{getBlendName(addedBlend.id, "pigin_name")}</b>{" "}
                          </TableTitle>
                        </>
                      ) : (
                        <TableTitle>
                          {t("PrescriptionForm.Other herbs")}
                        </TableTitle>
                      )}

                      <div style={{ height: 5 }} />

                      <Table key={`selected-blend-${blendIndex}`}>
                        <Thead>
                          <Tr>
                            <Th style={{ width: 28 }}>
                              <TableDropdownMenu
                                disabled={isPreview}
                                resetChanges={() => resetChanges(blendIndex)}
                                removeAll={() => removeAll(blendIndex)}
                              />
                            </Th>
                            <Th style={{ width: 40 }}>
                              {t("PrescriptionFormTable.Pos")}
                            </Th>
                            <Th style={{ width: "65%" }}>
                              {t("PrescriptionFormTable.Name")}
                            </Th>
                            <Th style={{ width: "35%" }}>
                              {t("PrescriptionFormTable.Pinyin name")}
                            </Th>
                            <Th style={{ width: 70 }}>
                              {t("PrescriptionFormTable.Pao")}
                            </Th>
                            <Th style={{ width: 70 }}>
                              {t("PrescriptionFormTable.Gram")}
                            </Th>
                            <Th style={{ width: 70 }}>
                              {t("PrescriptionFormTable.Orig")}
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {addedBlend?.herbs?.map((herb, herbIndex) => (
                            <Tr key={`blend-herb-${blendIndex}-${herbIndex}`}>
                              <Td>
                                <RowDropdownMenu
                                  disabled={isPreview}
                                  removeHerb={() =>
                                    removeHerb(blendIndex, herbIndex)
                                  }
                                />
                              </Td>
                              <Td>{herbIndex + 1}</Td>
                              <Td>
                                <HerbSelectField
                                  name={`blends.${blendIndex}.herbs.${herbIndex}.herb`}
                                  value={herb.herb}
                                  language="english"
                                  disabled={isPreview}
                                  onChange={(event) =>
                                    changeHerb(
                                      "herb",
                                      event,
                                      blendIndex,
                                      herbIndex
                                    )
                                  }
                                />
                              </Td>
                              <Td>
                                <HerbSelectField
                                  name=""
                                  value={herb.herb}
                                  language="pinyin"
                                  disabled={isPreview}
                                  onChange={(event) =>
                                    changeHerb(
                                      "herb",
                                      event,
                                      blendIndex,
                                      herbIndex
                                    )
                                  }
                                />
                              </Td>
                              <Td>
                                <Input
                                  type="number"
                                  min={0}
                                  style={{ maxWidth: "100%" }}
                                  value={herb.pao}
                                  name={`blends.${blendIndex}.herbs.${herbIndex}.pao`}
                                  disabled={isPreview}
                                  onChange={(event) =>
                                    changeHerb(
                                      "pao",
                                      event,
                                      blendIndex,
                                      herbIndex
                                    )
                                  }
                                />
                              </Td>
                              <Td>
                                <Input
                                  type="number"
                                  min={0}
                                  style={{ maxWidth: "100%" }}
                                  value={herb.qty}
                                  name={`blends.${blendIndex}.herbs.${herbIndex}.qty`}
                                  disabled={isPreview}
                                  onChange={(event) =>
                                    changeHerb(
                                      "qty",
                                      event,
                                      blendIndex,
                                      herbIndex
                                    )
                                  }
                                />
                              </Td>
                              <Td>
                                <Input
                                  type="number"
                                  min={0}
                                  style={{ maxWidth: "100%" }}
                                  value={herb.orig}
                                  name={`blends.${blendIndex}.herbs.${herbIndex}.orig`}
                                  disabled={isPreview}
                                  onChange={(event) =>
                                    changeHerb(
                                      "orig",
                                      event,
                                      blendIndex,
                                      herbIndex
                                    )
                                  }
                                />
                              </Td>
                            </Tr>
                          ))}
                          <Tr>
                            <Td
                              style={{ border: 0, padding: "4px 0px" }}
                              colSpan={5}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Button
                                  variant="outline-primary"
                                  size="sm"
                                  style={{ padding: "2px 5px", fontSize: 14 }}
                                  disabled={isPreview}
                                  onClick={() =>
                                    addHerb(addedBlend, blendIndex)
                                  }
                                >
                                  {t("PrescriptionFormTable.Add herb")}
                                </Button>
                                <span style={{ padding: "0px 5px" }}>
                                  {t("PrescriptionFormTable.Total grams")}
                                </span>
                              </div>
                            </Td>
                            <Td>{getTotal("qty", addedBlend)}</Td>
                            <Td>{getTotal("orig", addedBlend)}</Td>
                          </Tr>
                        </Tbody>
                      </Table>

                      <div style={{ height: 40 }} />
                    </>
                  ))}
              </>
            )}

            <Section
              style={{
                gap: "16px",
                backgroundColor: "#f0f0f0",
                padding: "10px 20px 14px",
                alignItems: "flex-end",
              }}
            >
              <div>
                <FieldLoader loading={blendsLoading}>
                  {!!blends && blends.length <= 0 ? (
                    <Link to="/blend/new" disabled={herbsLoading}>
                      {t("PrescriptionForm.Create your first Blend")}
                    </Link>
                  ) : (
                    <Form.Control
                      as="select"
                      className="form-select"
                      onChange={(event) => {
                        addBlend(event.target.value);
                        event.target.value = "";
                      }}
                      disabled={blendsLoading || isPreview}
                      style={{ cursor: "pointer" }}
                    >
                      <option className="d-none" value="">
                        {t("PrescriptionForm.Add a blend")}
                      </option>
                      {!!blends && blends.data.length > 0 ? (
                        blends.data.map((option, i) => (
                          <option key={i} value={option.id}>
                            {option.pigin_name}
                          </option>
                        ))
                      ) : (
                        <></>
                      )}
                    </Form.Control>
                  )}
                </FieldLoader>
              </div>

              <div style={{ width: 1, height: 40, backgroundColor: "gray" }} />

              <FieldLoader loading={herbsLoading}>
                <Button
                  variant="primary"
                  disabled={herbsLoading || isPreview}
                  onClick={(event) => addBlend(event.target.value)}
                  style={{ width: "100%" }}
                  block
                >
                  {t("PrescriptionForm.Add herbs")}
                </Button>
              </FieldLoader>
            </Section>
          </div>

          <div style={{ width: 350 }}>
            <Form.Group className="mb-3" controlId="patient_id">
              <Form.Label>
                <b>{t("PrescriptionForm.Patient")}</b>
              </Form.Label>

              <FieldLoader loading={isLoading || patientsLoading}>
                <Form.Control
                  required
                  as="select"
                  onChange={handleChange}
                  disabled={isLoading || patientsLoading || isPreview}
                  value={values.patient_id}
                  defaultValue={initialValues["patient_id"]}
                  name="patient_id"
                  className="form-select"
                  style={{ cursor: "pointer" }}
                >
                  <option className="d-none" value="">
                    {t("PrescriptionForm.Select a patient")}
                  </option>
                  {!!patients && patients.length > 0 ? (
                    patients.map((option, i) => (
                      <option key={i} value={option.id}>
                        {option.lastname}, {option.firstname}
                      </option>
                    ))
                  ) : (
                    <></>
                  )}
                </Form.Control>
              </FieldLoader>

              {!!errors["patient_id"] && (
                <Form.Control.Feedback type="invalid">
                  {t("Messages.Required field")}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Paragraph>
              <b>{t("PrescriptionForm.Address")}</b>
            </Paragraph>

            <Form.Control
              as="textarea"
              placeholder=""
              style={{ height: "100px" }}
              disabled={isPreview}
              value={
                selectedPatient
                  ? selectedPatient.street +
                    " " +
                    selectedPatient.number +
                    " " +
                    selectedPatient.zip +
                    " " +
                    selectedPatient.city
                  : ""
              }
            />

            <div style={{ height: 10 }} />

            <Paragraph>
              <b>{t("PrescriptionForm.Phone")}</b>:{" "}
              {selectedPatient && selectedPatient.phone}
            </Paragraph>

            <div style={{ height: 10 }} />

            <Paragraph>
              <b>{t("PrescriptionForm.Email")}</b>:{" "}
              {selectedPatient && selectedPatient.email}
            </Paragraph>

            <div style={{ height: 30 }} />

            <Form.Group className="mb-3" controlId="citation_id">
              <Form.Label>
                <b>{t("PrescriptionForm.Citation")}</b>
              </Form.Label>

              <FieldLoader loading={isLoading || patientsLoading}>
                {(!patientCitations || patientCitations.length <= 0) &&
                !isLoading &&
                !patientsLoading ? (
                  <Link to="/calendar">
                    {t("PrescriptionForm.Create a new citation")}
                  </Link>
                ) : (
                  <Form.Control
                    required
                    as="select"
                    onChange={handleChange}
                    disabled={isLoading || patientsLoading || isPreview}
                    value={values.citation_id}
                    defaultValue={initialValues["citation_id"]}
                    name="citation_id"
                    className="form-select"
                    style={{ cursor: "pointer" }}
                  >
                    <option className="d-none" value="">
                      {t("PrescriptionForm.Select a citation")}
                    </option>
                    {!!patientCitations && patientCitations.length > 0 ? (
                      patientCitations.map((option, i) => (
                        <option
                          key={i}
                          value={option.id}
                          disabled={option.invoiced || option.archived}
                        >
                          {datesHandler.formatDateAndTimeKeepTimezone(
                            option.time,
                            true
                          )}
                        </option>
                      ))
                    ) : (
                      <></>
                    )}
                  </Form.Control>
                )}
              </FieldLoader>

              {!!errors["patient_id"] && (
                <Form.Control.Feedback type="invalid">
                  {t("Messages.Required field")}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <div style={{ height: 30 }} />

            <Form.Group className="mb-3" controlId="patient_comments">
              <Form.Label>
                <b>{t("PrescriptionForm.Patient comments")}</b>
              </Form.Label>

              <Form.Control
                as="textarea"
                placeholder=""
                style={{ height: "100px" }}
                disabled={isPreview}
                name="patient_comments"
                onChange={handleChange}
                value={values.patient_comments}
              />

              {!!errors["patient_comments"] && (
                <Form.Control.Feedback type="invalid">
                  {t("Messages.Required field")}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <div style={{ height: 30 }} />

            <Form.Group className="mb-3" controlId="pharmacy_comments">
              <Form.Label>
                <b>{t("PrescriptionForm.Pharmacy comments")}</b>
              </Form.Label>

              <Form.Control
                as="textarea"
                placeholder=""
                style={{ height: "100px" }}
                disabled={isPreview}
                name="pharmacy_comments"
                onChange={handleChange}
                value={values.pharmacy_comments}
              />

              {!!errors["pharmacy_comments"] && (
                <Form.Control.Feedback type="invalid">
                  {t("Messages.Required field")}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </div>
        </Section>

        <div style={{ height: 20 }} />

        <Section>
          <Form.Group className="mb-3" controlId="presentation">
            <Form.Label>
              <b>{t("PrescriptionForm.Method of preparation")}:</b>
            </Form.Label>

            <FieldLoader loading={isLoading}>
              <Form.Control
                required
                as="select"
                onChange={handleChange}
                disabled={isLoading || isPreview}
                value={values.presentation}
                defaultValue={initialValues["presentation"]}
                name="presentation"
                className="form-select"
                style={{ cursor: "pointer" }}
              >
                <option value="powder">
                  {t("PrescriptionFormSelectors.Powder")}
                </option>
                <option value="granules">
                  {t("PrescriptionFormSelectors.Granules")}
                </option>
                <option value="crude drugs">
                  {t("PrescriptionFormSelectors.Crude drugs")}
                </option>
              </Form.Control>
            </FieldLoader>

            {!!errors["presentation"] && (
              <Form.Control.Feedback type="invalid">
                {t("Messages.Required field")}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <div>
            <div style={{ height: 24 }} />

            <Paragraph textRight>
              {t("PrescriptionForm.Quantity is intended for")}{" "}
              <input
                type="number"
                min={1}
                name="days"
                value={values.days}
                onChange={handleChange}
                disabled={isPreview}
                style={{ width: 50, textAlign: "center" }}
              />{" "}
              {t("PrescriptionForm.days")}
            </Paragraph>
          </div>
        </Section>

        <div style={{ height: 20 }} />

        <Section>
          <div>
            <Paragraph>
              <b>{t("PrescriptionForm.Therapist signature")}</b>
            </Paragraph>
            {/*             {selectedDoctor && (
              <img
                src={selectedDoctor?.signature}
                width="100px"
                height="auto"
              />
            )} */}
          </div>

          <div>
            <Paragraph>
              <b>{t("PrescriptionForm.Basic recipe")}</b>
            </Paragraph>
          </div>

          <div>
            <Paragraph>
              <b>{t("PrescriptionForm.Factor")}</b>
            </Paragraph>
          </div>
        </Section>
      </Sheet>
    </>
  );
};

const state = ({
  companyStore,
  patientStore,
  doctorStore,
  pharmacyStore,
  blendStore,
  herbStore,
  prescriptionStore,
}) => {
  const {
    data: herbs,
    loading: herbsLoading,
    success: herbsSuccess,
    error: herbsError,
  } = herbStore.all;

  const {
    data: company,
    loading: companyLoading,
    error: companyError,
    success: companySuccess,
  } = companyStore.one;

  const {
    data: patients,
    loading: patientsLoading,
    error: patientsError,
    success: patientsSuccess,
  } = patientStore.all;

  const {
    data: doctors,
    loading: doctorsLoading,
    error: doctorsError,
    success: doctorsSuccess,
  } = doctorStore.all;

  const {
    data: pharmacies,
    loading: pharmaciesLoading,
    error: pharmaciesError,
    success: pharmaciesSuccess,
  } = pharmacyStore.all;

  const {
    data: blends,
    loading: blendsLoading,
    error: blendsError,
    success: blendsSuccess,
  } = blendStore.all;

  const {
    data: prescription,
    loading: prescriptionLoading,
    error: prescriptionError,
    success: prescriptionSuccess,
  } = prescriptionStore.one;

  return {
    company,
    companyLoading,
    companyError,
    companySuccess,
    patients,
    patientsLoading,
    patientsError,
    patientsSuccess,
    doctors,
    doctorsLoading,
    doctorsError,
    doctorsSuccess,
    pharmacies,
    pharmaciesLoading,
    pharmaciesError,
    pharmaciesSuccess,
    blends,
    blendsLoading,
    blendsError,
    blendsSuccess,
    herbs,
    herbsLoading,
    herbsSuccess,
    herbsError,
    prescription,
  };
};

export default connect(state)(Component);
