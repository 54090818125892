import { useState } from "react";
import { useTranslation } from "react-i18next";

const DescriptionText = ({
  text,
  characterLimit = 24,
  classes,
  isDoctorSelect = false,
}) => {
  // translations
  const { t } = useTranslation();
  // utils
  const [showFullText, setShowFullText] = useState(false);

  const shortenedText = `${text?.slice(0, characterLimit)}...`;
  const fullText = text;

  const toggleShowFullText = (event) => {
    event.stopPropagation();
    setShowFullText(!showFullText);
  };

  return (
    <>
      {isDoctorSelect ? (
        <div>
          {text ? (
            <>
              {showFullText ? fullText : shortenedText}{" "}
              {text.length > characterLimit && (
                <span
                  className={classes.doctorDescription}
                  onClick={toggleShowFullText}
                >
                  {showFullText
                    ? t("FirstStep.show less")
                    : t("FirstStep.show more")}
                </span>
              )}
            </>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div>
          {t("FirstStep.Description")}:{" "}
          {text ? (
            <>
              {showFullText ? fullText : shortenedText}{" "}
              {text.length > characterLimit && (
                <span
                  className={classes.treatmentDescription}
                  onClick={toggleShowFullText}
                >
                  {showFullText
                    ? t("FirstStep.show less")
                    : t("FirstStep.show more")}
                </span>
              )}
            </>
          ) : (
            t("FirstStep.no data")
          )}
        </div>
      )}
    </>
  );
};

export default DescriptionText;
