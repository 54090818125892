function convertToNumber(str) {
  // Check if the string represents a valid number
  if (!isNaN(parseFloat(str)) && isFinite(str)) {
    // If it is a valid number, convert it to a number
    return parseFloat(str);
  } else {
    // If not, return the original string
    return str;
  }
}

export default convertToNumber