import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
} from "@material-ui/core";
import FetchIndexData from "../../utils/FetchIndexData";
import GetUserToken from "../../utils/GetUserToken";

export const EditQuiz = ({ id, handleeditClose, setIsAlert }) => {
  // auth
  const userToken = GetUserToken();
  // translations
  const { t } = useTranslation();
  // data
  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const [inputList, setInputList] = useState([{}]);
  const [counter, setCounter] = useState(1);
  const [clinicTreatments, setClinicTreatments] = useState([]);
  const [treatments, setTreatments] = useState("");
  // utils
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setData([]);

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    const url = process.env.REACT_APP_API_URL + "/quiztemplates/show/" + id;

    const res = await fetch(url, requestOptions);
    const json = await res.json();

    setName(json.name);
    setTreatments(json.treatments);
    let array = json.questions;
    setInputList(JSON.parse(array));
    setLoading(false);
  };

  const getTreatments = async () => {
    var response = await FetchIndexData("/doctors/getTreatments");
    let availableTreatments = response["clinicTreatments"].map((item) => ({
      id: item.id,
      name: item.name,
    }));
    setClinicTreatments(availableTreatments);
  };

  useEffect(() => {
    fetchData();
    getTreatments();
  }, [setData]);

  const handleCounter = () => {
    setCounter(counter + 1);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    handleCounter();
    setInputList([...inputList, { id: counter, question: "" }]);
  };

  const deleteQuiz = async () => {
    setLoading(true);
    try {
      var requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        redirect: "follow",
      };

      const url =
        process.env.REACT_APP_API_URL + "/quiztemplates/destroy/" + id;

      const response = await fetch(url, requestOptions);
      if (response.ok) {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
      handleeditClose();
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setLoading(false);
    }
  };

  // Post method
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const body = {
        name: name,
        treatments: treatments,
        questions: JSON.stringify(inputList),
      };

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        body: JSON.stringify(body),
      };

      const url = process.env.REACT_APP_API_URL + "/quiztemplates/update/" + id;

      const response = await fetch(url, requestOptions);
      if (response.ok) {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
      handleeditClose();
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="form-group">
            <label className="title-inputs">{t("EditQuiz.Title")}</label>
            <br />
            <br />
            <TextField
              required
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="input-control"
              variant="outlined"
              size="small"
              placeholder={t("EditQuiz.Write here")}
            />
          </div>

          <div className="form-group">
            <label className="title-inputs">
              {t("EditQuiz.Select Treatments")}
            </label>
            <br />
            <br />
            <Select
              type="text"
              onChange={(e) => setTreatments(e.target.value)}
              style={{ width: "100%" }}
              value={treatments}
              variant="outlined"
            >
              {clinicTreatments.map((item, index) => {
                return (
                  <MenuItem key={index} value={item["id"]}>
                    {item["name"]}
                  </MenuItem>
                );
              })}
            </Select>
          </div>

          <label className="title-inputs">{t("EditQuiz.Questions")}</label>
          <br />
          <br />

          {inputList.map((item, i) => {
            return (
              <>
                <div className="row">
                  <div className="col form-group">
                    <input
                      name="id"
                      value={i}
                      onChange={(e) => handleInputChange(e, i)}
                      style={{ display: "none" }}
                    />

                    <TextareaAutosize
                      type="text"
                      name="question"
                      value={item.question}
                      onChange={(e) => handleInputChange(e, i)}
                      className="input-control"
                      variant="outlined"
                      size="small"
                      aria-label="minimum height"
                      minRows={2}
                      placeholder={t("EditQuiz.Write here")}
                    />

                    <>
                      {inputList.length !== 1 && (
                        <button
                          className="btn-cancel mt-1"
                          type="button"
                          onClick={() => handleRemoveClick(i)}
                        >
                          {t("EditQuiz.Remove input")}
                        </button>
                      )}
                    </>
                  </div>
                </div>

                <div className="row text-center" style={{ width: "92%" }}>
                  <div className="col">
                    {inputList.length - 1 === i && (
                      <div
                        className="blue-anchors"
                        type="button"
                        onClick={handleAddClick}
                      >
                        + {t("EditQuiz.Add question")}
                      </div>
                    )}
                  </div>
                </div>
              </>
            );
          })}

          {loading ? (
            <div className="d-flex justify-content-center">
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <>
              <div className="mt-5 text-center">
                <div className="row">
                  <div className="col-6">
                    <button className="blue-btn" onClick={deleteQuiz}>
                      {t("EditQuiz.Destroy")}
                    </button>
                  </div>

                  <div className="col-6">
                    <button className="blue-btn" onClick={handleSubmit}>
                      {t("EditQuiz.Save")}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default EditQuiz;
