import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";
import "react-edit-text/dist/index.css";
import "../../../assets/stylesheets/herbs.css";
import fetchApi from "../../../utils/fetchApi";
import {
  updatedHerbTranslation,
  formatHerbTranslations,
} from "../../../utils/helper";
import NewHerbFields from "./NewHerbFields";

const NewHerbStep2 = ({ idHerb, setLoading, setIsAlert, infoRoute }) => {
  // translations
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState("");
  // route
  const history = infoRoute.history;
  // redux
  const state = useSelector((state) => state.user);
  // data
  const [languages, setLanguages] = useState([]);
  const [newHerbTranslation, setNewHerbTranslation] = useState([]);

  const fetchLanguages = async () => {
    try {
      setLoading(true);
      const url = `
        ${process.env.REACT_APP_HERBS_API}/constants`;
      const { data } = await fetchApi(url, "GET");
      setLanguages(data.languages);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (state.auth.language) {
      switch (state.auth.language) {
        case "EN":
          i18n.changeLanguage("en");
          setCurrentLanguage("EN");
          break;
        case "ES":
          i18n.changeLanguage("es");
          setCurrentLanguage("ES");
          break;
        default:
          i18n.changeLanguage("de");
          setCurrentLanguage("DE");
          break;
      }
    }
    fetchLanguages();
  }, []);

  const handleUpdateHerbTranslations = (
    idLanguage,
    name,
    value,
    sub_value,
    index
  ) => {
    let herbs_translations_updated = updatedHerbTranslation(
      idLanguage,
      name,
      value,
      sub_value,
      index,
      newHerbTranslation,
      idHerb
    );
    setNewHerbTranslation(herbs_translations_updated);
  };

  const handleCreateHerbTranslation = async () => {
    let invalidTranslations = newHerbTranslation.filter(
      (obj) => !obj.name || obj.name === null
    );
    if (newHerbTranslation.length === 0) {
      setIsAlert({
        open: true,
        severity: "warning",
        message: t("Messages.You must complete the required fields"),
      });
      return;
    }
    if (invalidTranslations.length !== 0) {
      setIsAlert({
        open: true,
        severity: "warning",
        message: t("Messages.The name field is required"),
      });
      return;
    }

    try {
      setLoading(true);
      let body = formatHerbTranslations(newHerbTranslation, true);
      const url = `${process.env.REACT_APP_HERBS_API}/herbs_translations/batch`;
      const response = await fetchApi(url, "POST", JSON.stringify(body));
      if (response.ok) {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
        setTimeout(() => {
          history.push(`/herb/edit/${idHerb}`);
        }, 1000);
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setLoading(false);
    }
  };

  // console.log("newHerbTranslation", newHerbTranslation);

  return (
    <>
      {currentLanguage && languages && (
        <Tabs defaultActiveKey={currentLanguage} id="new-herb" className="mb-3">
          {languages.map(({ id, name, code }) => (
            <Tab key={id} eventKey={code} title={name}>
              <NewHerbFields
                idLanguage={id}
                idHerb={idHerb}
                handleUpdateHerbTranslations={handleUpdateHerbTranslations}
                handleCreateHerbTranslation={handleCreateHerbTranslation}
              />
            </Tab>
          ))}
        </Tabs>
      )}
    </>
  );
};

export default NewHerbStep2;
