import { styles } from "./styles"
import CircularProgress from "@material-ui/core/CircularProgress";

const Component = ({ children, loading }) => {
  return (
    <div style={styles.wrapper}>
      {children}
      {loading && (
        <div style={styles.loader}>
          <CircularProgress size={20} />
        </div>
      )}
    </div>
  )
}

export default Component