import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import services from "../../services";

// Define initial state
const initialState = {
  one: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  oneByUid: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  all: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  create: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  update: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  citations: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  allPatientDocuments: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  newPatientDocument: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  deletePatientDocument: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
};

// Async action to get one patient
const getOnePatient = createAsyncThunk("patient/getOne", async (payload) => {
  console.log("get one - patient - action", payload);
  const { response, error } = await services.patient.getOne(payload);
  if (error) {
    throw new Error(error);
  }
  return response;
});

// Async action to get one patient by UID
const getOnePatientByUid = createAsyncThunk(
  "patient/getOneByUid",
  async (payload) => {
    console.log("get patient by UID - patient - action", payload);
    const { response, error } = await services.patient.getOneByUid(payload);
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Async action to get all patients
const getAllPatients = createAsyncThunk("patient/getAll", async (payload) => {
  console.log("get all - patient - action", payload);
  const { response, error } = await services.patient.getAll(payload);
  if (error) {
    throw new Error(error);
  }
  return response;
});

// Async action to create one patient
const createOnePatient = createAsyncThunk(
  "patient/createOne",
  async ({ payload }) => {
    console.log("create one - patient - action", payload);
    const { response, error } = await services.patient.createOne(payload);
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Async action to update a patient
const updatePatient = createAsyncThunk("patient/update", async (payload) => {
  console.log("update - patient - action", payload);
  const { response, error } = await services.patient.update(payload);
  if (error) {
    throw new Error(error);
  }
  return response;
});

// Async action to get all patient's citations
const getPatientCitations = createAsyncThunk(
  "patient/getPatientCitations",
  async (payload) => {
    console.log("get citations - patient - action", payload);
    const { response, error } = await services.patient.getPatientCitations(
      payload
    );
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Async action to get all patient's citations
const getPatientDocuments = createAsyncThunk(
  "patient/getPatientDocuments",
  async (payload) => {
    console.log("get documents - patient - action", payload);
    const { response, error } = await services.patient.getAllDocuments(payload);
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Async action to get all patient's citations
const newPatientDocument = createAsyncThunk(
  "patient/newPatientDocument",
  async (payload) => {
    console.log("new document - patient - action", payload);
    const { response, error } = await services.patient.newDocument(payload);
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Async action to get all patient's citations
const deletePatientDocument = createAsyncThunk(
  "patient/deletePatientDocument",
  async (payload) => {
    console.log("delete document - patient - action", payload);
    const { response, error } = await services.patient.deleteDocument(payload);
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Create a patient slice
const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOnePatient.pending, (state, action) => {
        state.one.data = null;
        state.one.loading = true;
        state.one.success = false;
        state.one.error = false;
      })
      .addCase(getOnePatient.fulfilled, (state, action) => {
        state.one.data = action.payload;
        state.one.loading = false;
        state.one.success = true;
        state.one.error = false;
      })
      .addCase(getOnePatient.rejected, (state, action) => {
        state.one.data = null;
        state.one.loading = false;
        state.one.success = false;
        state.one.error = action.error.message;
      })
      .addCase(getOnePatientByUid.pending, (state) => {
        state.oneByUid.data = null;
        state.oneByUid.loading = true;
        state.oneByUid.success = false;
        state.oneByUid.error = false;
      })
      .addCase(getOnePatientByUid.fulfilled, (state, action) => {
        state.oneByUid.data = action.payload;
        state.oneByUid.loading = false;
        state.oneByUid.success = true;
        state.oneByUid.error = false;
      })
      .addCase(getOnePatientByUid.rejected, (state, action) => {
        state.oneByUid.data = null;
        state.oneByUid.loading = false;
        state.oneByUid.success = false;
        state.oneByUid.error = action.error.message;
      })
      .addCase(getAllPatients.pending, (state) => {
        state.all.data = null;
        state.all.loading = true;
        state.all.success = false;
        state.all.error = false;
      })
      .addCase(getAllPatients.fulfilled, (state, action) => {
        state.all.data = action.payload;
        state.all.loading = false;
        state.all.success = true;
        state.all.error = false;
      })
      .addCase(getAllPatients.rejected, (state, action) => {
        state.all.data = null;
        state.all.loading = false;
        state.all.success = false;
        state.all.error = action.error.message;
      })
      .addCase(updatePatient.pending, (state) => {
        state.update.data = null;
        state.update.loading = true;
        state.update.success = false;
        state.update.error = false;
      })
      .addCase(updatePatient.fulfilled, (state, action) => {
        state.update.data = action.payload;
        state.update.loading = false;
        state.update.success = true;
        state.update.error = false;
        state.one.data = action.payload;
      })
      .addCase(updatePatient.rejected, (state, action) => {
        state.update.data = null;
        state.update.loading = false;
        state.update.success = false;
        state.update.error = action.error.message;
      })
      .addCase(getPatientCitations.pending, (state) => {
        state.citations.data = null;
        state.citations.loading = true;
        state.citations.success = false;
        state.citations.error = false;
      })
      .addCase(getPatientCitations.fulfilled, (state, action) => {
        state.citations.data = action.payload;
        state.citations.loading = false;
        state.citations.success = true;
        state.citations.error = false;
        state.one.data = action.payload;
      })
      .addCase(getPatientCitations.rejected, (state, action) => {
        state.citations.data = null;
        state.citations.loading = false;
        state.citations.success = false;
        state.citations.error = action.error.message;
      })
      .addCase(getPatientDocuments.pending, (state) => {
        state.allPatientDocuments.data = null;
        state.allPatientDocuments.loading = true;
        state.allPatientDocuments.success = false;
        state.allPatientDocuments.error = false;
      })
      .addCase(getPatientDocuments.fulfilled, (state, action) => {
        state.allPatientDocuments.data = action.payload;
        state.allPatientDocuments.loading = false;
        state.allPatientDocuments.success = true;
        state.allPatientDocuments.error = false;
      })
      .addCase(getPatientDocuments.rejected, (state, action) => {
        state.allPatientDocuments.data = null;
        state.allPatientDocuments.loading = false;
        state.allPatientDocuments.success = false;
        state.allPatientDocuments.error = action.error.message;
      })
      .addCase(newPatientDocument.pending, (state) => {
        state.newPatientDocument.data = null;
        state.newPatientDocument.loading = true;
        state.newPatientDocument.success = false;
        state.newPatientDocument.error = false;
      })
      .addCase(newPatientDocument.fulfilled, (state, action) => {
        state.newPatientDocument.data = action.payload;
        state.newPatientDocument.loading = false;
        state.newPatientDocument.success = true;
        state.newPatientDocument.error = false;
      })
      .addCase(newPatientDocument.rejected, (state, action) => {
        state.newPatientDocument.data = null;
        state.newPatientDocument.loading = false;
        state.newPatientDocument.success = false;
        state.newPatientDocument.error = action.error.message;
      })
      .addCase(deletePatientDocument.pending, (state) => {
        state.deletePatientDocument.data = null;
        state.deletePatientDocument.loading = true;
        state.deletePatientDocument.success = false;
        state.deletePatientDocument.error = false;
      })
      .addCase(deletePatientDocument.fulfilled, (state, action) => {
        state.deletePatientDocument.data = action.payload;
        state.deletePatientDocument.loading = false;
        state.deletePatientDocument.success = true;
        state.deletePatientDocument.error = false;
      })
      .addCase(deletePatientDocument.rejected, (state, action) => {
        state.deletePatientDocument.data = null;
        state.deletePatientDocument.loading = false;
        state.deletePatientDocument.success = false;
        state.deletePatientDocument.error = action.error.message;
      });
  },
});

// Export the async actions and the reducer
export {
  getOnePatient,
  getOnePatientByUid,
  getAllPatients,
  createOnePatient,
  updatePatient,
  getPatientCitations,
  getPatientDocuments,
  newPatientDocument,
  deletePatientDocument,
};

export default patientSlice.reducer;
