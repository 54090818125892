import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import PrescriptionsTable from "./PrescriptionsTable";
import "../../assets/stylesheets/patients.css";
import { useDispatch, connect } from "react-redux";
import { getAllPrescriptions } from "../../redux/slices/prescription";

const Component = ({
  id,
  isHomePatient,
  prescriptions,
  prescriptionsLoading,
  prescriptionsError,
  prescriptionsSuccess,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getAllPrescriptions({
        currentPage: 1,
        itemsPerPage: 5000,
        sortBy: "created_at",
        sortDirection: "desc",
        filters: { patient_id: id },
      })
    );
  }, []);

  return (
    <div className="mt-3 pb-5">
      <h2
        className={
          !isHomePatient ? "subtitles-patients" : "home-reminders-title"
        }
      >
        {t("PatientPrescriptions.Prescriptions")}
      </h2>

      {prescriptionsLoading || !prescriptions ? (
        <div className="d-flex justify-content-center">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <>
          {prescriptions.length > 0 ? (
            <PrescriptionsTable
              id={id}
              prescriptions={prescriptions}
              isHomePatient={isHomePatient}
            />
          ) : (
            t("PatientPrescriptions.No prescriptions were found")
          )}
        </>
      )}
    </div>
  );
};

const state = ({ prescriptionStore }) => {
  const {
    data: prescriptions,
    loading: prescriptionsLoading,
    error: prescriptionsError,
    success: prescriptionsSuccess,
  } = prescriptionStore.all;

  return {
    prescriptions,
    prescriptionsLoading,
    prescriptionsError,
    prescriptionsSuccess,
  };
};

export default connect(state)(Component);
