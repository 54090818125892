import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../../assets/stylesheets/invoicesStyles.css";
import CitationsAccordion from "./invoicingmachine/CitationsAccordion";
import { DatePicker } from "./invoicingmachine/DatePicker";
import { DueDatePicker } from "./invoicingmachine/DueDatePicker";
//import { DiagnosisInfo } from "./invoicingmachine/DiagnosisInfo";
import { DiagnosisInfo } from "./invoicingmachine/DiagnosisInfoV2";
import PatientInfo from "./invoicingmachine/PatientInfo";
import PatientPicker from "./invoicingmachine/PatientPicker";
import PatientsBackdrop from "./invoicingmachine/PatientsBackdrop";
import ProductsPicker from "./invoicingmachine/ProductsPicker";
import SummaryButtons from "./invoicingmachine/SummaryButtons";
import GetUserToken from "../../utils/GetUserToken";

export const InvoicingMachine = (infoRoute) => {
  // translations
  const { t } = useTranslation();
  // route
  const params = infoRoute.match.params;
  // date
  let today = new Date();
  let dueDatetoday = today.setDate(today.getDate() + 14);
  // data
  const [picked, setPicked] = useState(null);
  const [patientsload, setPatientsload] = useState(true);
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState(null);
  const [date, setDate] = useState(new Date());
  const [duedate, setDuedate] = useState(dueDatetoday);
  const [citationsload, setCitationsload] = useState(false);
  const [citations, setCitations] = useState(null);
  const [diagnosisFromCrud, setDiagnosisFromCrud] = useState([]);
  const [adittionalproducts, setAdittionalproducts] = useState([]);
  const [adittionalProductsFromCitations, setAdittionalProductsFromCitations] =
    useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [taxes, setTaxes] = useState([]);
  const [total, setTotal] = useState(0);
  const [ready, setReady] = useState(false);
  const [diagnosis, setDiagnosis] = useState(null);
  const [westlicheDiagnosis, setWestlicheDiagnosis] = useState([]);
  const [creating, setCreating] = useState(false);
  const [success, setSuccess] = useState(false);
  const [preview, setPreview] = useState(false);
  const [download, setDownload] = useState("");
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    fetchPatients();
    fetchDiagnosis();
  }, [setPatients]);

  const fetchPatients = async () => {
    const url = process.env.REACT_APP_API_URL + "/citations/getPatients";

    const userToken = GetUserToken();

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    const res = await fetch(url, requestOptions);

    const json = await res.json();
    setPatients(json["clinicPatients"]);
    setPatientsload(false);

    if (params.id == "new") {
      // console.log("New Invoice from scratch");
    } else {
      const patient = json["clinicPatients"].filter(function (element) {
        return element.id == parseInt(params.id);
      });
      // console.log(patient[0]);
      setPicked(patient[0]);
      getPatientCitations(patient[0]);
    }
  };

  const fetchDiagnosis = async () => {
    const url = process.env.REACT_APP_API_URL + "/diagnosis/index";

    const userToken = GetUserToken();

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    const res = await fetch(url, requestOptions);

    const json = await res.json();

    const filteredResponse = json.diagnosis.map(({ code, name }) => ({
      code,
      name,
    }));

    const sortedDiagnosis = filteredResponse.sort((a, b) =>
      a.code > b.code ? 1 : b.code > a.code ? -1 : 0
    );

    setDiagnosisFromCrud(sortedDiagnosis);
  };

  const getPatientCitations = async (value) => {
    const url =
      process.env.REACT_APP_API_URL_V2 +
      "/invoices/get_patient_citations?id=" +
      value.id;

    const userToken = GetUserToken();

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    setCitationsload(true);

    if (value != null) {
      setPatient(value);

      const res = await fetch(url, requestOptions);

      const json = await res.json();

      // console.log("CURRENT CITATIONS: ", json["events"]);

      //FIXME: Filtering invoiced and archived citations
      let actualCitations = json.events.filter((item) => {
        return item.archived === false && item.invoiced === false;
      });

      setCitations(actualCitations);

      // console.log("FILTERED CITATIONS: ", actualCitations);

      let actualDiagnosis = [];
      let initialPrice = 0;
      let adittionalsFromCitation = [];

      actualCitations.map((citation, index_0) => {
        citation.products.map((product, index_1) => {
          initialPrice = initialPrice + parseFloat(product.price);
        });

        // console.log(JSON.parse(citation.adittionalProducts));

        let parsedAdittionals = citation.adittionalProducts;

        if (parsedAdittionals != null) {
          parsedAdittionals.map((item, index_0) => {
            item.title = item.title;
            item.toinvoice = true;
          });

          adittionalsFromCitation =
            adittionalsFromCitation.concat(parsedAdittionals);
        }

        if (!citation.diagnosis) {
          citation.diagnosis = " ";
        }

        actualDiagnosis.push([
          citation.treatment,
          citation.start,
          citation.diagnosis,
          JSON.parse(citation.altdiagnosis),
        ]);

        const clean = actualDiagnosis
          .map((subArray) => subArray[3])
          .filter((value) => value !== null && value.length > 0);

        const flattenedArray = [].concat(...clean);

        const uniqueData = Array.from(
          new Set(flattenedArray.map(JSON.stringify))
        ).map(JSON.parse);

        setDiagnosis(uniqueData);
      });

      handlePriceCalculation(actualCitations, adittionalsFromCitation);

      // console.log("adittional from citation", adittionalsFromCitation);
      setAdittionalProductsFromCitations(adittionalsFromCitation);
      setAdittionalproducts(adittionalsFromCitation);

      // console.log("FIRST PRICE CALCULATION: ", initialPrice);

      // console.log("diagnosis", actualDiagnosis);
      // console.log("citations: ", actualCitations);

      //setDiagnosis(actualDiagnosis);

      if (actualCitations.length >= 1) {
        setPreview(true);
        setReady(true);
      }
      // setTotal(initialPrice);
      // console.log(initialPrice);
      setCitationsload(false);
    } else {
      setCitations(null);
      setPatient(null);
      setSubTotal(0);
      setTotal(0);
      setCitationsload(false);
    }
  };

  const calculateFinalPrice = (price, tax) => price + price * (tax / 100);

  const handlePriceCalculation = (citations_, adittionals_) => {
    let initialPrice = 0;
    let initialPriceWithTax = 0;
    let taxSummary = [];

    // products and taxes
    if (citations_ != null && citations_.length > 0) {
      citations_.map((citation, index_0) => {
        if (citation.toinvoice) {
          citation.products.map((product, index_1) => {
            if (product.toinvoice) {
              const price = parseFloat(product.price);
              const taxRate = product.tax_rate ? product.tax_rate : 0;
              const finalPrice = calculateFinalPrice(price, taxRate);
              const taxValue = finalPrice - price;

              initialPrice += price;
              initialPriceWithTax += parseFloat(finalPrice);

              if (taxRate > 0) {
                let taxEntry = taxSummary.find(
                  (entry) => entry.tax === taxRate
                );
                if (taxEntry) {
                  taxEntry.total += taxValue;
                } else {
                  taxSummary.push({
                    tax: taxRate,
                    total: taxValue,
                  });
                }
              }
            }
          });
        }
      });
    }

    // adittionals products
    if (adittionals_ != null && adittionals_.length > 0) {
      let actualProducts = [...adittionals_];
      actualProducts.map((product, index) => {
        if (product.toinvoice) {
          initialPrice = initialPrice + parseFloat(product.price);
          initialPriceWithTax = initialPriceWithTax + parseFloat(product.price);
        }
      });
    }

    setSubTotal(initialPrice);
    setTaxes(taxSummary);
    setTotal(initialPriceWithTax);
  };

  const handleRefresh = () => {
    handlePriceCalculation(citations, adittionalproducts);
  };

  //FIXME...
  const onProductChange = (index_0, index_1, state) => {
    // console.log(index_0, index_1);
    let citationsCopy = [...citations];
    citationsCopy[index_0].products[index_1].toinvoice = state;
    setCitations(citationsCopy);
    // console.log(citationsCopy);
    // onTotalChange();
    handleRefresh();
  };

  const onPartialChange = (index_0, index_1, price) => {
    // console.log(index_0, index_1, price);
    let citationsCopy = citations;
    citationsCopy[index_0].products[index_1].price = price;
    setCitations(citationsCopy);
    // console.log(citationsCopy);
    onTotalChange();
  };

  const onAdittionalProductChange = (value, status) => {
    // console.log(value, status);
    let selectedProductsCopy = [...adittionalproducts];
    selectedProductsCopy.push(value);
    setAdittionalproducts(selectedProductsCopy);
    // onTotalChange();
    handleRefresh();
  };

  const handleFuncionDePrueba = (index_0, event) => {
    // console.log(index_0);
    // console.log(event.target.checked);
    let currentCitations = [...citations];
    currentCitations[index_0].toinvoice = !currentCitations[index_0].toinvoice;
    setCitations(currentCitations);
    // console.log(currentCitations[index_0]);
    handleRefresh();
  };

  //....FIXME tiene errores al calcular el total
  const onTotalChange = () => {
    // console.log("calculando total...");
    let actualCitations = citations;
    let initialPrice = 0;
    actualCitations.map((citation, index_0) => {
      citation.products.map((product, index_1) => {
        if (product.toinvoice) {
          initialPrice = initialPrice + parseFloat(product.price);
        }
      });
    });

    let actualProducts = adittionalproducts;
    actualProducts.map((product, index) => {
      initialPrice = initialPrice + parseFloat(product.price);
    });
    setTotal(initialPrice);
  };

  // FIXME TEST
  const onPreview = () => {
    let toInvoiceCitations = citations.filter((item) => item.toinvoice);

    const body = {
      name: patient,
      date: date,
      due_date: new Date(duedate),
      diagnosis: diagnosis,
      citations: toInvoiceCitations,
      products: adittionalproducts,
      total: total,
      altdiagnosis: JSON.stringify(westlicheDiagnosis),
    };

    // console.log("pre body: ", body);
  };

  const onSubmit = async () => {
    setCreating(true);

    let toInvoiceCitations = citations.filter((item) => item.toinvoice);

    const body = {
      name: patient,
      date: date,
      due_date: new Date(duedate),
      diagnosis: diagnosis,
      citations: toInvoiceCitations,
      products: adittionalproducts.filter((item) => item.toinvoice),
      total: total,
      altdiagnosis: JSON.stringify(westlicheDiagnosis),
    };

    const userToken = GetUserToken();

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      body: JSON.stringify(body),
    };

    let response = await fetch(
      process.env.REACT_APP_API_URL_V2 + "/invoices/create",
      requestOptions
    );

    if (response.ok) {
      let invoiceDetails = await response.json();
      setSuccess(true);
      setDownload(invoiceDetails.id);
      // console.log(invoiceDetails);
      setCreating(false);
    } else {
      // console.log("error in invoicing");
      setCreating(false);
    }
  };

  return (
    <>
      <div className="frame">
        <h4 className="main-title mt-2">
          {t("InvoiceMachine.0")}&nbsp;&nbsp;
          <span className="beta-sign">&nbsp;&nbsp;BETA&nbsp;&nbsp;</span>
        </h4>
        <p className="blue-anchors">
          {" "}
          <Link className="blue-anchors" to="/invoices">
            {" "}
            &#60; {t("InvoiceMachine.1")}
          </Link>
        </p>
        <br />
        <h4 className="subtitles-patients">{t("InvoiceMachine.2")}</h4>
        <div className="row">
          <div className="col-8">
            <div className="invoice-patient-card">
              <div className="row mb-3">
                <div className="col-4">
                  <DatePicker patient={patient} date={date} setDate={setDate} />
                </div>
                <div className="col-4">
                  <h4 className="invoice-machine-title">
                    {t("InvoiceMachine.3")}
                  </h4>
                </div>
                <div className="col-4">
                  <DueDatePicker
                    patient={patient}
                    duedate={duedate}
                    setDuedate={setDuedate}
                  />
                </div>
              </div>
              <hr />
              <div className="row mt-4">
                <h4 className="invoice-patient-title">
                  {t("InvoiceMachine.4")}:
                </h4>
                <div className="col-sm-12 col-lg-6">
                  {patientsload ? (
                    <></>
                  ) : (
                    <>
                      <PatientPicker
                        picked={picked}
                        patients={patients}
                        setPatient={setPatient}
                        getPatientCitations={getPatientCitations}
                      />
                    </>
                  )}
                </div>
                <div className="col-sm-12 col-lg-6">
                  {patient != null ? (
                    <>
                      <PatientInfo patient={patient} />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                {diagnosis != null ? (
                  <>
                    <hr className="mt-2" />
                    <DiagnosisInfo
                      diagnosis={diagnosis}
                      setDiagnosis={setDiagnosis}
                      westlicheDiagnosis={westlicheDiagnosis}
                      setWestlicheDiagnosis={setWestlicheDiagnosis}
                      diagnosisFromCrud={diagnosisFromCrud}
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="col-4">
            <SummaryButtons
              subTotal={subTotal}
              taxes={taxes}
              total={total}
              citations={citations}
              adittionalproducts={adittionalproducts}
              onSubmit={onSubmit}
              preview={preview}
              ready={ready}
              creating={creating}
              success={success}
              download={download}
              downloading={downloading}
              setDownloading={setDownloading}
              patient={patient}
              diagnosis={diagnosis}
              westlicheDiagnosis={westlicheDiagnosis}
              date={date}
              duedate={duedate}
              onPreview={onPreview}
              calculateFinalPrice={calculateFinalPrice}
            />
          </div>
        </div>
        <div className="mt-4">
          {citations != null ? (
            citations.map((item, index_0) => (
              <CitationsAccordion
                index_0={index_0}
                citation={item}
                citations={citations}
                setCitations={setCitations}
                handleRefresh={handleRefresh}
                onProductChange={onProductChange}
                onPartialChange={onPartialChange}
                handleFuncionDePrueba={handleFuncionDePrueba}
                calculateFinalPrice={calculateFinalPrice}
              />
            ))
          ) : (
            <></>
          )}
        </div>
        <div className="mt-4">
          {patient != null ? (
            <>
              <ProductsPicker
                adittionalProductsFromCitations={
                  adittionalProductsFromCitations
                }
                setAdittionalProductsFromCitations={
                  setAdittionalProductsFromCitations
                }
                adittionalproducts={adittionalproducts}
                setAdittionalproducts={setAdittionalproducts}
                handleRefreshV2={handleRefresh}
                onAdittionalProductChange={onAdittionalProductChange}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <PatientsBackdrop
        patientsload={patientsload}
        citationsload={citationsload}
      />
    </>
  );
};

export default InvoicingMachine;
