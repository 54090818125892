import React from "react";
import { useTranslation } from "react-i18next";
import { EditText, EditTextarea } from "react-edit-text";
import { Card } from "react-bootstrap";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const NewHerbFields = ({
  idLanguage,
  idHerb,
  handleUpdateHerbTranslations,
  handleCreateHerbTranslation,
}) => {
  // translations
  const { t } = useTranslation();
  // utils
  const id_herb = idHerb ? true : false;

  return (
    <>
      {/* Name */}
      <div
        className="row patient-id-box"
        style={{
          width: "95%",
          marginLeft: "0px",
          paddingLeft: "20px",
          marginBottom: "21px",
        }}
      >
        <p className="subtitles-patients">{t("NewIndividualHerbs.0")}*</p>
        <div className="col">
          <EditText
            className="key-text-box"
            name="name"
            readonly={!id_herb}
            placeholder={t("Herbs.Write here")}
            onSave={({ name, value }) =>
              handleUpdateHerbTranslations(idLanguage, name, value)
            }
          />
        </div>
      </div>

      {/* Description boxes */}
      <Accordion className="herbs-accordion" style={{ width: "95%" }}>
        <AccordionSummary
          expandIcon={
            <ArrowForwardIosIcon
              style={{ color: "#38baff", fontSize: "15px" }}
            />
          }
          aria-label="Expand"
          style={{
            zIndex: "99",
            flexDirection: "row-reverse",
          }}
        >
          <span
            className="subtitles-patients"
            style={{ fontSize: "18px", paddingLeft: "14px" }}
          >
            {t("Herbs.Description")}
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <div
              className="row patient-id-box"
              style={{
                width: "95%",
                marginLeft: "12px",
                paddingLeft: "20px",
              }}
            >
              <div className="col-3 text-left">
                <p className="subtitles-patients">{t("Herbs.Best quality")}</p>
                <div className="col">
                  <EditTextarea
                    className="key-text-box px-0"
                    name="best_quality"
                    readonly={!id_herb}
                    placeholder={t("Herbs.Write here")}
                    onSave={({ name, value }) =>
                      handleUpdateHerbTranslations(idLanguage, name, value)
                    }
                  />
                </div>
              </div>

              <div
                className="col-8 border-start"
                style={{ paddingLeft: "38px", marginLeft: "6px" }}
              >
                <div className="row">
                  <div className="row">
                    <div className="col-8">
                      <p className="subtitles-patients">{t("ViewHerb.5")}</p>
                    </div>
                  </div>
                  <div className="col">
                    <EditText
                      className="key-text-box"
                      name="category"
                      readonly={!id_herb}
                      placeholder={t("Herbs.Write here")}
                      onSave={({ name, value }) =>
                        handleUpdateHerbTranslations(idLanguage, name, value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="row mt-3 patient-id-box"
              style={{
                width: "95%",
                marginLeft: "12px",
                paddingLeft: "20px",
              }}
            >
              <div className="col-3 text-left">
                <p className="subtitles-patients">{t("Herbs.Taste")}</p>
                <div className="col">
                  <EditTextarea
                    className="key-text-box px-0"
                    name="taste"
                    readonly={!id_herb}
                    placeholder={t("Herbs.Write here")}
                    onSave={({ name, value }) =>
                      handleUpdateHerbTranslations(idLanguage, name, value)
                    }
                  />
                </div>
              </div>

              <div
                className="col-3 text-left border-start"
                style={{ paddingLeft: "38px", marginLeft: "6px" }}
              >
                <p className="subtitles-patients">{t("Herbs.Temperature")}</p>
                <div className="col">
                  <EditTextarea
                    className="key-text-box px-0"
                    name="temperature"
                    readonly={!id_herb}
                    placeholder={t("Herbs.Write here")}
                    onSave={({ name, value }) =>
                      handleUpdateHerbTranslations(idLanguage, name, value)
                    }
                  />
                </div>
              </div>

              <div className="col-3 text-left border-start">
                <p className="subtitles-patients">
                  {t("Herbs.Entering Meridians")}
                </p>
                <div className="col">
                  <EditTextarea
                    className="key-text-box"
                    name="entering_meridians"
                    readonly={!id_herb}
                    placeholder={t("Herbs.Write here")}
                    onSave={({ name, value }) =>
                      handleUpdateHerbTranslations(idLanguage, name, value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      {/* end Description boxes */}

      {/* Dosage and Mixtures boxes */}
      <Accordion className="herbs-accordion" style={{ width: "95%" }}>
        <AccordionSummary
          expandIcon={
            <ArrowForwardIosIcon
              style={{ color: "#38baff", fontSize: "15px" }}
            />
          }
          aria-label="Expand"
          style={{
            zIndex: "99",
            flexDirection: "row-reverse",
          }}
        >
          <span
            className="subtitles-patients"
            style={{ fontSize: "18px", paddingLeft: "14px" }}
          >
            {t("Herbs.Dosage and Mixtures")}
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <div
              className="row patient-id-box"
              style={{
                width: "95%",
                marginLeft: "12px",
                paddingLeft: "20px",
              }}
            >
              <div className="col-3 text-left">
                <p className="subtitles-patients">{t("Herbs.Dosage")}</p>
                <div className="col">
                  <EditText
                    className="key-text-box px-0"
                    name="dosage"
                    readonly={!id_herb}
                    placeholder={t("Herbs.Write here")}
                    onSave={({ name, value }) =>
                      handleUpdateHerbTranslations(
                        idLanguage,
                        name,
                        value,
                        "normal"
                      )
                    }
                  />
                </div>
              </div>

              <div
                className="col-3 text-left border-start"
                style={{ paddingLeft: "38px", marginLeft: "6px" }}
              >
                <p className="subtitles-patients">{t("Herbs.Tincture")}</p>
                <div className="col">
                  <EditTextarea
                    className="key-text-box"
                    name="dosage"
                    readonly={!id_herb}
                    placeholder={t("Herbs.Write here")}
                    onSave={({ name, value }) =>
                      handleUpdateHerbTranslations(
                        idLanguage,
                        name,
                        value,
                        "tincture"
                      )
                    }
                  />
                </div>
              </div>

              <div
                className="col-3 text-left border-start"
                style={{ paddingLeft: "38px", marginLeft: "6px" }}
              >
                <p className="subtitles-patients">{t("Herbs.Powder/pills")}</p>
                <div className="col">
                  <EditTextarea
                    className="key-text-box"
                    name="dosage"
                    readonly={!id_herb}
                    placeholder={t("Herbs.Write here")}
                    onSave={({ name, value }) =>
                      handleUpdateHerbTranslations(
                        idLanguage,
                        name,
                        value,
                        "powdersorpills"
                      )
                    }
                  />
                </div>
              </div>
            </div>

            <div
              className="row mt-3 patient-id-box"
              style={{
                width: "95%",
                marginLeft: "12px",
                paddingLeft: "20px",
              }}
            >
              <div className="col-3 text-left">
                <p className="subtitles-patients">
                  {t("Herbs.Administrations")}
                </p>
                <div className="col">
                  <EditTextarea
                    className="key-text-box px-0"
                    name="dosage"
                    readonly={!id_herb}
                    placeholder={t("Herbs.Write here")}
                    onSave={({ name, value }) =>
                      handleUpdateHerbTranslations(
                        idLanguage,
                        name,
                        value,
                        "administration"
                      )
                    }
                  />
                </div>
              </div>

              <div
                className="col-3 text-left border-start"
                style={{ paddingLeft: "38px", marginLeft: "6px" }}
              >
                <p className="subtitles-patients">{t("Herbs.Processing")}</p>
                <div className="col">
                  <EditTextarea
                    className="key-text-box"
                    name="dosage"
                    readonly={!id_herb}
                    placeholder={t("Herbs.Write here")}
                    onSave={({ name, value }) =>
                      handleUpdateHerbTranslations(
                        idLanguage,
                        name,
                        value,
                        "processing"
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      {/* end Dosage and Mixtures boxes */}

      {/* Actions and Indications boxes */}
      <Accordion className="herbs-accordion" style={{ width: "95%" }}>
        <AccordionSummary
          expandIcon={
            <ArrowForwardIosIcon
              style={{ color: "#38baff", fontSize: "15px" }}
            />
          }
          aria-label="Expand"
          style={{
            zIndex: "99",
            flexDirection: "row-reverse",
          }}
        >
          <span
            className="subtitles-patients"
            style={{ fontSize: "18px", paddingLeft: "14px" }}
          >
            {t("NewIndividualHerbs.6")}
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <div
            className="herbs-accordion-body"
            style={{
              background: "#f4f5fa",
              padding: "27px",
              borderRadius: "9px",
            }}
          >
            <table
              className="table herb-accordion-table-actions-and-indications"
              style={{ width: "80%" }}
            >
              <thead>
                <tr>
                  <th
                    className="herb-accordion-table-actions-and-indications-thead herb-ai-titles"
                    style={{ borderLeftWidth: "0px" }}
                  >
                    {t("NewIndividualHerbs.7")}
                  </th>
                  <th
                    className="herb-accordion-table-actions-and-indications-thead herb-ai-titles"
                    style={{ borderLeftWidth: "0px" }}
                  >
                    {t("NewIndividualHerbs.8")}
                  </th>
                </tr>
              </thead>
              <tbody style={{ borderTop: "0px" }}>
                <tr>
                  <th
                    className="herb-accordion-table-actions-and-indications-tbody"
                    style={{
                      fontWeight: "300",
                      borderLeftWidth: "0px",
                      borderBottomWidth: "0px",
                    }}
                  >
                    <div>
                      <EditTextarea
                        name="actions_and_indications"
                        readonly={!id_herb}
                        placeholder={t("Herbs.Write here")}
                        onSave={({ name, value }) =>
                          handleUpdateHerbTranslations(
                            idLanguage,
                            name,
                            value,
                            "Actions",
                            0
                          )
                        }
                      />
                    </div>
                  </th>
                  <th
                    className="herb-accordion-table-actions-and-indications-tbody"
                    style={{
                      fontWeight: "300",
                      borderBottomWidth: "0px",
                    }}
                  >
                    <div>
                      <EditTextarea
                        name="actions_and_indications"
                        readonly={!id_herb}
                        placeholder={t("Herbs.Write here")}
                        onSave={({ name, value }) =>
                          handleUpdateHerbTranslations(
                            idLanguage,
                            name,
                            value,
                            "Indications/Syndromes",
                            0
                          )
                        }
                      />
                    </div>
                  </th>
                </tr>
                <tr>
                  <th
                    className="herb-accordion-table-actions-and-indications-tbody"
                    style={{
                      fontWeight: "300",
                      borderLeftWidth: "0px",
                      borderBottomWidth: "0px",
                      borderBottomLeftRadius: "9px",
                    }}
                  >
                    <div>
                      <EditTextarea
                        name="actions_and_indications"
                        readonly={!id_herb}
                        placeholder={t("Herbs.Write here")}
                        onSave={({ name, value }) =>
                          handleUpdateHerbTranslations(
                            idLanguage,
                            name,
                            value,
                            "Actions",
                            1
                          )
                        }
                      />
                    </div>
                  </th>
                  <th
                    className="herb-accordion-table-actions-and-indications-tbody"
                    style={{
                      fontWeight: "300",
                      borderBottomWidth: "0px",
                      borderBottomRightRadius: "9px",
                    }}
                  >
                    <div>
                      <EditTextarea
                        name="actions_and_indications"
                        readonly={!id_herb}
                        placeholder={t("Herbs.Write here")}
                        onSave={({ name, value }) =>
                          handleUpdateHerbTranslations(
                            idLanguage,
                            name,
                            value,
                            "Indications/Syndromes",
                            1
                          )
                        }
                      />
                    </div>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </AccordionDetails>
      </Accordion>
      {/* end Actions and Indications boxes */}

      {/* Contraindications, incompabilities And Herb/Drug Interactions boxes*/}
      <Accordion className="herbs-accordion" style={{ width: "95%" }}>
        <AccordionSummary
          expandIcon={
            <ArrowForwardIosIcon
              style={{ color: "#38baff", fontSize: "15px" }}
            />
          }
          aria-label="Expand"
          style={{
            zIndex: "99",
            flexDirection: "row-reverse",
          }}
        >
          <span
            className="subtitles-patients"
            style={{ fontSize: "18px", paddingLeft: "14px" }}
          >
            {t("NewIndividualHerbs.9")}
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <div
            className="herbs-accordion-body d-flex"
            style={{
              background: "#f4f5fa",
              padding: "27px",
              borderRadius: "9px",
            }}
          >
            <Card
              className="reminders-cards"
              style={{
                width: "35%",
                borderRadius: "10px",
                marginRight: "10px",
                paddingBottom: "12px",
              }}
            >
              <Card.Header
                className="reminders-subtitle"
                style={{
                  border: "none",
                  background: "#E1DFE5",
                  borderRadius: "10px",
                  borderBottomRightRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  paddingBottom: "12px",
                }}
              >
                {t("NewIndividualHerbs.10")}
              </Card.Header>

              <Card.Body style={{ fontWeight: "300" }}>
                <EditTextarea
                  name="contraindications"
                  readonly={!id_herb}
                  placeholder={t("Herbs.Write here")}
                  onSave={({ name, value }) =>
                    handleUpdateHerbTranslations(idLanguage, name, value)
                  }
                />
              </Card.Body>
            </Card>
            <Card
              className="reminders-cards"
              style={{
                width: "35%",
                borderRadius: "10px",
                marginRight: "10px",
              }}
            >
              <Card.Header
                className="reminders-subtitle"
                style={{
                  border: "none",
                  background: "#E1DFE5",
                  borderRadius: "10px",
                  borderBottomRightRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  paddingBottom: "12px",
                }}
              >
                {t("NewIndividualHerbs.11")}
              </Card.Header>

              <Card.Body style={{ fontWeight: "300" }}>
                <EditTextarea
                  name="incompatibilities"
                  readonly={!id_herb}
                  placeholder={t("Herbs.Write here")}
                  onSave={({ name, value }) =>
                    handleUpdateHerbTranslations(idLanguage, name, value)
                  }
                />
              </Card.Body>
            </Card>
            <Card
              className="reminders-cards"
              style={{ width: "35%", borderRadius: "10px" }}
            >
              <Card.Header
                className="reminders-subtitle"
                style={{
                  border: "none",
                  background: "#E1DFE5",
                  borderRadius: "10px",
                  borderBottomRightRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  paddingBottom: "12px",
                }}
              >
                {t("NewIndividualHerbs.12")}
              </Card.Header>

              <Card.Body style={{ fontWeight: "300" }}>
                <EditTextarea
                  name="herb_drug_interactions"
                  readonly={!id_herb}
                  placeholder={t("Herbs.Write here")}
                  onSave={({ name, value }) =>
                    handleUpdateHerbTranslations(idLanguage, name, value)
                  }
                />
              </Card.Body>
            </Card>
          </div>
        </AccordionDetails>
      </Accordion>
      {/* end Contraindications, incompabilities And Herb/Drug Interactions boxes */}

      {/* Frecuent Pairings boxes */}
      <Accordion className="herbs-accordion" style={{ width: "95%" }}>
        <AccordionSummary
          expandIcon={
            <ArrowForwardIosIcon
              style={{ color: "#38baff", fontSize: "15px" }}
            />
          }
          aria-label="Expand"
          style={{
            zIndex: "99",
            flexDirection: "row-reverse",
          }}
        >
          <span
            className="subtitles-patients"
            style={{ fontSize: "18px", paddingLeft: "14px" }}
          >
            {t("NewIndividualHerbs.13")}
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <div
            className="herbs-accordion-body"
            style={{
              background: "#f4f5fa",
              padding: "27px",
              borderRadius: "9px",
            }}
          >
            <table className="table herb-table-major-combinations">
              <thead>
                <tr>
                  <th className="herb-accordion-table-major-combinations herb-mc-titles">
                    {t("ViewHerb.18")}
                  </th>
                  <th className="herb-accordion-table-major-combinations herb-mc-titles">
                    {t("ViewHerb.19")}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="herb-accordion-table-major-combinations key-text-box">
                    <EditTextarea
                      name="major_combinations"
                      readonly={!id_herb}
                      placeholder={t("Herbs.Write here")}
                      onSave={({ name, value }) =>
                        handleUpdateHerbTranslations(
                          idLanguage,
                          name,
                          value,
                          "ingredients",
                          0
                        )
                      }
                      style={{ fontWeight: "bold", minHeight: "88px" }}
                    />
                  </td>
                  <td
                    className="herb-accordion-table-major-combinations"
                    style={{
                      fontWeight: "300",
                      borderBottomWidth: "0px",
                    }}
                  >
                    <EditTextarea
                      name="major_combinations"
                      readonly={!id_herb}
                      placeholder={t("Herbs.Write here")}
                      onSave={({ name, value }) =>
                        handleUpdateHerbTranslations(
                          idLanguage,
                          name,
                          value,
                          "action",
                          0
                        )
                      }
                      style={{ minHeight: "88px" }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="herb-accordion-table-major-combinations key-text-box">
                    <EditTextarea
                      name="major_combinations"
                      readonly={!id_herb}
                      placeholder={t("Herbs.Write here")}
                      onSave={({ name, value }) =>
                        handleUpdateHerbTranslations(
                          idLanguage,
                          name,
                          value,
                          "ingredients",
                          1
                        )
                      }
                      style={{ fontWeight: "bold", minHeight: "88px" }}
                    />
                  </td>
                  <td
                    className="herb-accordion-table-major-combinations"
                    style={{
                      fontWeight: "300",
                      borderBottomWidth: "0px",
                    }}
                  >
                    <EditTextarea
                      name="major_combinations"
                      readonly={!id_herb}
                      placeholder={t("Herbs.Write here")}
                      onSave={({ name, value }) =>
                        handleUpdateHerbTranslations(
                          idLanguage,
                          name,
                          value,
                          "action",
                          1
                        )
                      }
                      style={{ minHeight: "88px" }}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    className="herb-accordion-table-major-combinations key-text-box"
                    style={{
                      borderBottomLeftRadius: "6px",
                    }}
                  >
                    <EditTextarea
                      name="major_combinations"
                      readonly={!id_herb}
                      placeholder={t("Herbs.Write here")}
                      onSave={({ name, value }) =>
                        handleUpdateHerbTranslations(
                          idLanguage,
                          name,
                          value,
                          "ingredients",
                          2
                        )
                      }
                      style={{ fontWeight: "bold", minHeight: "88px" }}
                    />
                  </td>
                  <td
                    className="herb-accordion-table-major-combinations"
                    style={{
                      fontWeight: "300",
                      borderBottomRightRadius: "6px",
                    }}
                  >
                    <EditTextarea
                      name="major_combinations"
                      readonly={!id_herb}
                      placeholder={t("Herbs.Write here")}
                      onSave={({ name, value }) =>
                        handleUpdateHerbTranslations(
                          idLanguage,
                          name,
                          value,
                          "action",
                          2
                        )
                      }
                      style={{ minHeight: "88px" }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </AccordionDetails>
      </Accordion>
      {/* end Frecuent Pairings boxes */}

      {/* Notes boxes */}
      <Accordion className="herbs-accordion" style={{ width: "95%" }}>
        <AccordionSummary
          expandIcon={
            <ArrowForwardIosIcon
              style={{ color: "#38baff", fontSize: "15px" }}
            />
          }
          aria-label="Expand"
          style={{
            zIndex: "99",
            flexDirection: "row-reverse",
          }}
        >
          <span
            className="subtitles-patients"
            style={{ fontSize: "18px", paddingLeft: "14px" }}
          >
            {t("NewIndividualHerbs.14")}
          </span>
        </AccordionSummary>
        <AccordionDetails style={{ marginBottom: "25px" }}>
          <div className="herb-accordion-body">
            <EditTextarea
              name="notes"
              readonly={!id_herb}
              placeholder={t("Herbs.Write here")}
              onSave={({ name, value }) =>
                handleUpdateHerbTranslations(idLanguage, name, value)
              }
            />
          </div>
        </AccordionDetails>
      </Accordion>
      {/* end Notes boxes */}

      {/* Formulas boxes */}
      <Accordion className="herbs-accordion" style={{ width: "95%" }}>
        <AccordionSummary
          expandIcon={
            <ArrowForwardIosIcon
              style={{ color: "#38baff", fontSize: "15px" }}
            />
          }
          aria-label="Expand"
          style={{
            zIndex: "99",
            flexDirection: "row-reverse",
          }}
        >
          <span
            className="subtitles-patients"
            style={{ fontSize: "18px", paddingLeft: "14px" }}
          >
            {t("NewIndividualHerbs.17")}
          </span>
        </AccordionSummary>
        <AccordionDetails style={{ marginBottom: "25px" }}>
          <Typography className="herb-accordion-body">
            {t("NewIndividualHerbs.18")}...
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* end Formulas boxes */}

      <div className="d-flex justify-content-end mb-3" style={{ width: "95%" }}>
        <button
          className={!id_herb ? "blue-btn-disabled" : "blue-btn"}
          disabled={!id_herb}
          style={{ width: "185px" }}
          onClick={() => handleCreateHerbTranslation()}
        >
          {t("Buttons.Save")}
        </button>
      </div>
    </>
  );
};

export default NewHerbFields;
