import React from "react";
import logo from "../../../assets/icons/nollLogo.png";
import background from "../../../assets/icons/draft_background.png";
// import tcmlogo from "../../../assets/icons/tcm-assist-logo.png";
import moment from "moment";
import { useSelector } from "react-redux";

export const Preview = ({
  subTotal,
  taxes,
  total,
  adittionalproducts,
  citations,
  patient,
  diagnosis,
  date,
  duedate,
  westlicheDiagnosis,
  calculateFinalPrice,
}) => {
  // console.log("CITAS", citations);
  // console.log("WESTLICHE", westlicheDiagnosis);
  // console.log(patient)
  // console.log("PRODUCTOS", adittionalproducts);
  // console.log(diagnosis);
  const state = useSelector((state) => state.companyStore.one.data);

  let dueDate = new Date(duedate);

  let iterator = 0;

  const formatDate = (date, invoiceNumber) => {
    let formattedDate = moment(date).format(
      invoiceNumber ? "YYYY.MM" : "DD.MM.YYYY"
    );
    return invoiceNumber ? `${formattedDate}-XX` : formattedDate;
  };

  const citationsTable = (
    <div>
      <table
        className="table table-borderless table-striped"
        style={{ marginBottom: "0" }}
      >
        <thead>
          <tr>
            <th className="value-text-box fw-bold" style={{ width: "6%" }}>
              Pos
            </th>
            <th className="value-text-box fw-bold" style={{ width: "15%" }}>
              Datum
            </th>
            <th
              className="value-text-box fw-bold"
              // style={{ width: "10%" }}
            >
              Ziffer
            </th>
            <th className="value-text-box fw-bold" style={{ width: "40%" }}>
              erbrachte Leistung
            </th>
            <th className="value-text-box fw-bold text-end">
              Summe
              <br />
              Netto
            </th>
            <th className="value-text-box fw-bold text-end">
              MwSt
              <br />
              (%)
            </th>
            <th className="value-text-box fw-bold text-end">
              Summe
              <br />
              Brutto
            </th>
          </tr>
        </thead>
        <tbody>
          {citations.map((item, i) =>
            item.toinvoice ? (
              item.products.map((product, i) => (
                <>
                  {product.toinvoice != false && product.price > 0 ? (
                    <tr key={i}>
                      <th className="key-text-box">
                        {(iterator = iterator + 1)}
                      </th>
                      <th className="key-text-box">{formatDate(item.start)}</th>
                      <th className="key-text-box">{product.rate}</th>
                      <th className="key-text-box">
                        {product.title.replace(
                          ` ${item.treatment} Version`,
                          ""
                        )}
                      </th>
                      <th className="key-text-box text-end">
                        {state.currency && state.currency}
                        {parseFloat(product.price).toFixed(2).replace(".", ",")}
                      </th>
                      <th className="key-text-box text-end">
                        {(product.tax_rate ? product.tax_rate : 0).toFixed(1)}%
                      </th>
                      <th className="key-text-box text-end">
                        {state.currency && state.currency}
                        {calculateFinalPrice(
                          product.price,
                          product.tax_rate ? product.tax_rate : 0
                        )
                          .toFixed(2)
                          .replace(".", ",")}
                      </th>
                    </tr>
                  ) : (
                    <></>
                  )}
                </>
              ))
            ) : (
              <></>
            )
          )}

          {adittionalproducts.length != 0 ? (
            adittionalproducts.map((item, i) =>
              item.toinvoice && item.price > 0 ? (
                <>
                  {/*    <caption>Products<span className="value-text-box"></span></caption>*/}

                  <tr key={i}>
                    <th className="key-text-box">
                      {(iterator = iterator + 1)}
                    </th>
                    <th className="key-text-box">
                      {formatDate(item.appointment_date)}
                    </th>
                    <th className="key-text-box">{item.rate}</th>
                    <th className="key-text-box">{item.title}</th>
                    <th className="key-text-box text-end">
                      {state.currency && state.currency}
                      {parseFloat(item.price).toFixed(2).replace(".", ",")}
                    </th>
                    <th className="key-text-box text-end">{(0).toFixed(1)}%</th>
                    <th className="key-text-box text-end">
                      {state.currency && state.currency}
                      {parseFloat(item.price).toFixed(2).replace(".", ",")}
                    </th>
                  </tr>
                </>
              ) : (
                <></>
              )
            )
          ) : (
            <></>
          )}
        </tbody>
      </table>
    </div>
  );
  const productsTable = (
    <>
      {adittionalproducts.length != 0 ? (
        adittionalproducts.map((item, i) =>
          item.toinvoice && item.price > 0 ? (
            <>
              <div key={i}>
                {/*    <caption>Products<span className="value-text-box"></span></caption>*/}
                <table className="table table-borderless table-striped">
                  <thead>
                    <tr>
                      <th
                        className="value-text-box"
                        style={{ width: "8%", padding: "0" }}
                      ></th>
                      <th
                        className="value-text-box"
                        style={{ width: "15%", padding: "0" }}
                      ></th>
                      <th
                        className="value-text-box"
                        style={{ width: "10%", padding: "0" }}
                      ></th>
                      <th
                        className="value-text-box"
                        style={{ width: "50%", padding: "0" }}
                      ></th>
                      <th className="value-text-box text-end"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr key={i}>
                      <th className="key-text-box">
                        {(iterator = iterator + 1)}
                      </th>
                      <th className="key-text-box">
                        {new Date(item.appointment_date).toLocaleString([], {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                          timeZone: "UTC",
                        })}
                      </th>
                      <th className="key-text-box">{item.rate}</th>
                      <th className="key-text-box">{item.title}</th>
                      <th className="key-text-box text-end">
                        {state.currency && state.currency}
                        {parseFloat(item.price).toFixed(2).replace(".", ",")}
                      </th>
                    </tr>
                  </tbody>
                  {/*<tfoot>
                    <tr>
                    <th colSpan="3"><div class="line"></div></th>
                    <th className="titles">Subtotal</th>
                    <th className="subtitles"></th>
                    </tr>
                    </tfoot> 
                  */}
                </table>
              </div>
            </>
          ) : (
            <></>
          )
        )
      ) : (
        <></>
      )}
    </>
  );

  const diagnosisInput = diagnosis.map((item) => (
    <>
      {item[2] !== " " ? <p style={{ fontSize: "14px" }}>{item[2]}</p> : <></>}
    </>
  ));

  const diagnosisWestliche =
    diagnosis.length > 0 ? (
      diagnosis.map((item, index) => (
        <p key={index} style={{ fontSize: "14px" }}>
          {item.code}-{item.name}
        </p>
      ))
    ) : (
      <></>
    );

  return (
    <>
      <div
        className="invoices-frame"
        // style={{backgroundImage: `url(${background})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: '600px 600px', zIndex: '999999'}}
      >
        <img
          src={background}
          alt="background"
          style={{
            width: "600px",
            height: "600px",
            position: "absolute",
            top: "24%",
            left: "12%",
            opacity: "0.5",
          }}
        />

        <div className="d-flex justify-content-between">
          <div>
            <img
              src={
                state?.logo
                  ? state?.logo.url
                  : "https://www.tcmassist.org/wp-content/uploads/2022/08/tcm-assist-logo.png"
              }
              alt="logo"
              style={{ height: "60px", width: "60px" }}
            />
          </div>
          <div style={{ textAlign: "right" }}>
            <p className="value-text-box fw-bold" style={{ fontSize: "20px" }}>
              Rechnung
            </p>

            <span className="key-text-box">
              {state.business_name && state.business_name}
              <br />
              {state.slogan && state.slogan}
              <br />
              {state.street && state.street}{" "}
              {state.street_number && state.street_number}{" "}
              {state.zip && state.zip} {state.city && state.city},{" "}
              {state.country && state.country}
              <br />
              {state.contact_informations[0]?.value &&
                state.contact_informations[0]?.value}
              <br />
              {state.website && state.website}
              <br />
              {state.tax_identification && state.tax_identification}
            </span>
          </div>
        </div>

        <hr style={{ borderTop: "dashed 1px gray", background: "none" }} />

        <div className="d-flex" style={{ width: "95%" }}>
          <div style={{ width: "60%" }}>
            {/* <p className="value-text-box fw-bold">Rechnung an:</p> */}
            <p className="value-text-box fw-bold">
              {state.business_name && state.business_name}
              <br />
              {state.street && state.street}{" "}
              {state.street_number && state.street_number}{" "}
              {state.zip && state.zip} {state.city && state.city},{" "}
              {state.country && state.country}
            </p>

            <span className="key-text-box">
              {patient.lastname}, {patient.firstname}
            </span>
            <br />
            <span className="key-text-box">
              {patient.street}
              <br />
              {patient.zip} {patient.city}
              <br />
              {patient.state}
              <br />
              {patient.address}
            </span>
          </div>

          <div>
            <p className="value-text-box fw-bold">
              Liquidation:{" "}
              <span className="key-text-box">{formatDate(date, true)}</span>
            </p>

            <span className="key-text-box">Datum: {formatDate(date)}</span>
            <br />
            <span className="key-text-box">
              Fällig zum: {formatDate(dueDate)}
            </span>
          </div>
        </div>

        <hr />

        <div className="d-flex flex-column" style={{ width: "95%" }}>
          <span className="key-text-box" style={{ fontSize: "14px" }}>
            {patient.gender === "Female"
              ? "Sehr geehrte Frau"
              : "Sehr geehrter Herr"}{" "}
            {patient.lastname},
            <br />
            Für meine diagnostischen und therapeutischen Bemühungen erlaube ich
            mir Folgendes zu liquidieren:
            <br />
            (Behandelt wurde {patient.firstname} {patient.lastname}- geb.{" "}
            {formatDate(patient.birth)} Auf Wunsch erfolgt die Liquidation nach
            GebüH bzw. Hufelandverzeichnis)
          </span>

          <p className="value-text-box">&nbsp;</p>
          <div className="d-flex justify-content-between">
            {/*
            <div style={{ width: "50%" }}>
              <p className="value-text-box fw-bold">Diagnose:</p>
              <span className="key-text-box">{diagnosisInput}</span>
            </div> 
            
            */}
            <div style={{ width: "70%" }}>
              <p className="value-text-box fw-bold">Westliche Diagnose:</p>
              <span className="key-text-box">{diagnosisWestliche}</span>
            </div>
          </div>
        </div>

        <hr />

        {citationsTable}

        {/* {productsTable} */}

        <table className="table table-borderless table-striped">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th colSpan="3">
                <div style={{ borderBottom: "2px solid #e1dfe5" }}></div>
              </th>
            </tr>
          </thead>

          <tfoot>
            <tr>
              <th></th>
              <th></th>
              <th className="subtitles text-end" colSpan="3">
                <div className="d-flex flex-column">
                  <div>
                    Netto {state.currency && state.currency}
                    {parseFloat(subTotal).toFixed(2).replace(".", ",")}
                  </div>
                  <br />
                  {taxes.length !== 0 &&
                    taxes.map(({ tax, total }) => (
                      <>
                        <div>
                          MwSt ({tax.toFixed(1)}%){" "}
                          {state.currency && state.currency}
                          {parseFloat(total).toFixed(2).replace(".", ",")}
                        </div>
                        <br />
                      </>
                    ))}
                  <div>
                    Brutto {state.currency && state.currency}
                    {parseFloat(total).toFixed(2).replace(".", ",")}
                  </div>
                </div>
              </th>
            </tr>
          </tfoot>
        </table>

        <div class="rectangle-invoices">
          <p>
            Bitte überweisen Sie den Betrag von{" "}
            {state.currency && state.currency}
            {parseFloat(total).toFixed(2).replace(".", ",")} unter Angabe der
            Rechnungsnummer {formatDate(date, true)} bis zum{" "}
            {formatDate(dueDate)} auf folgendes Konto:
          </p>

          <p>
            Deutsche Apotheker- und Ärztebank
            <br />
            &gt;&nbsp;IBAN:
            {state.bank_accounts[0]?.iban && state.bank_accounts[0]?.iban}
            <br />
            &gt;&nbsp;BIC:{" "}
            {state.bank_accounts[0]?.bic && state.bank_accounts[0]?.bic}
          </p>

          <p>
            Mit freundlichen Grüßen
            <br />
            {state.business_name && state.business_name}
          </p>
        </div>
      </div>
    </>
  );
};

export default Preview;
