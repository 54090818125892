import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import {
  CircularProgress,
  Checkbox,
  Chip,
  FormControlLabel,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { DateTimePicker } from "../citations/newcitation/DateTimePicker";
import { PatientPickerOnboarding } from "./PatientPickerOnboarding";
import { PatientSummary } from "../citations/newcitation/PatientSummary";
import { SubmitButtons } from "../citations/newcitation/SubmitButtons";
import { TherapistPicker } from "../citations/newcitation/TherapistPicker";
import { TreatmentPicker } from "../citations/newcitation/TreatmentPicker";
import ScheduleTable from "../citations/newcitation/ScheduleTable";
import GetUserToken from "../../utils/GetUserToken";
import "../../assets/stylesheets/newdoctorcitation.css";
import NewPatientOnboarding from "./NewPatientOnboarding";

const NewAppointmentOnboarding = ({
  isPatientCreated,
  setIsPatientCreated,
  setIsPatients,
  handleStepComplete,
}) => {
  // translation
  const { t } = useTranslation();
  // auth
  const userToken = GetUserToken();
  // redux
  const state = useSelector((state) => state.user);
  // utils
  const [loading, setLoading] = useState(true);
  const [loadingschedule, setLoadingschedule] = useState(false);
  const [assistant, setAssistant] = useState(true);
  const [error, setError] = useState(false);
  const [required, setRequired] = useState(false);
  const [warning, setWarning] = useState("");
  const [isOpenPatientModal, setIsOpenPatientModal] = useState(false);
  // data
  const [doctors, setDoctors] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [patients, setPatients] = useState([]);
  const [schedule, setSchedule] = useState([]);
  //....
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  //....
  const [statustime, setStatustime] = useState();
  const [checking, setChecking] = useState(false);
  //....
  const [doctor, setDoctor] = useState("");
  const [treatment, setTreatment] = useState("");
  const [name, setName] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [user_id, setUser] = useState("");
  const [patient_id, setPatient_id] = useState("");
  const [created_by, setCreated_by] = useState();
  const [insurance, setInsurance] = useState("");
  const [remark, setRemark] = useState("");

  useEffect(() => {
    fetchDoctors();
    fetchTreatments();
    fetchPatients();
    setSchedule([]);
    if (state) {
      setCreated_by(state["auth"].user_id);
    }
  }, []);

  const fetchDoctors = async () => {
    var requestOptions = {
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL + "/citations/getDoctors",
      requestOptions
    );
    const json = await res.json();

    let activeDoctors = json.clinicDoctors.filter((item) => {
      return item.archived === false;
    });

    setDoctors(activeDoctors);
  };

  const fetchTreatments = async () => {
    var requestOptions = {
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL + "/citations/getTreatments",
      requestOptions
    );
    const json = await res.json();
    const allTreatments = json.clinicTreatments;
    const activeTreatments = allTreatments.filter((obj) => {
      return obj.archived === false;
    });
    setTreatments(activeTreatments);
  };

  const fetchPatients = async () => {
    var requestOptions = {
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL + "/citations/getPatients",
      requestOptions
    );
    const json = await res.json();

    const filtered = json.clinicPatients.filter(
      (item) => item.user_id !== null
    );
    setPatients(filtered);
    setIsPatients(filtered);
    setLoading(false);
  };

  const handleClosePatientModal = () => {
    setIsOpenPatientModal(false);
  };

  const checkSchedule = (value) => {
    setLoadingschedule(true);
    if (time && doctor) {
      const schedule_date = time.split("T")[0];

      var url = "";

      if (value) {
        url =
          process.env.REACT_APP_API_URL + "/citations/checkErstDoctorSchedule";
      } else {
        url = process.env.REACT_APP_API_URL + "/citations/checkDoctorSchedule";
      }

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        body: JSON.stringify({
          date: schedule_date,
          doctor: doctor,
        }),
      }).then((response) => {
        response.json().then((data) => {
          var current_data = [];
          data["data"].map((item, i) =>
            current_data.push(item.split("T")[1].split(":00.000")[0])
          );
          setSchedule(current_data);
          setLoadingschedule(false);
        });
      });
    } else {
      console.log("time missing");
    }
  };

  const checkStatus = async (param, status_data) => {
    setChecking(true);

    var raw = null;

    if (param === "time" && doctor) {
      raw = {
        time: status_data,
        doctor: doctor,
      };
    } else if (param === "doctor" && statustime != null) {
      raw = {
        time: statustime,
        doctor: status_data,
      };
    } else {
      console.log("one of more data is missing");
    }

    if (raw != null) {
      const res = await fetch(
        process.env.REACT_APP_API_URL + "/citations/check_doctor_time_status",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "api-key": process.env.REACT_APP_API_KEY,
            Authorization: userToken,
          },
          body: JSON.stringify(raw),
        }
      );
      const json = await res.json();

      if (json["citations"].length > 0) {
        var appointmentStart = json["citations"][0][0]
          .split("T")[1]
          .split(":00.000Z")[0];
        setWarning(["appointment", appointmentStart]);
      } else if (json["locks"].length > 0) {
        var lockStart = json["locks"][0]["start"]
          .split("T")[1]
          .split(":00.000Z")[0];
        var lockEnd = json["locks"][0]["end"]
          .split("T")[1]
          .split(":00.000Z")[0];
        setWarning(["lock", lockStart, lockEnd]);
      } else {
        setWarning("ok");
      }
    } else {
      setWarning("");
    }
    setChecking(false);
  };

  const onSubmit = async () => {
    if (name && firstname && lastname && time && doctor && treatment) {
      setLoading(true);

      const body = {
        name,
        firstname,
        lastname,
        date,
        time,
        doctor,
        treatment,
        email,
        phone,
        address,
        user_id,
        patient_id,
        created_by,
        warnings: remark,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        body: JSON.stringify(body),
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/citations/create_from_doctor`,
          requestOptions
        );

        if (response.ok) {
          await handleStepComplete();
        } else {
          setError(true);
        }
      } catch (error) {
        console.error("Error:", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    } else {
      setRequired(true);
    }
  };

  return (
    <>
      <div style={{ padding: "40px" }}>
        <div className="text-center mb-4">
          <h4 className="main-title-treatments">
            {t("Calendar.New Appointment")}
          </h4>
        </div>
        {loading ? (
          <div className="d-flex justify-content-center">
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <div className="row">
            <div className="col">
              <div className="mt-1">
                <DateTimePicker
                  schedule={schedule}
                  setDate={setDate}
                  setTime={setTime}
                  setTreatment={setTreatment}
                  checkSchedule={checkSchedule}
                  checkStatus={checkStatus}
                  setStatustime={setStatustime}
                />
              </div>
              <div className="mt-3">
                <TherapistPicker
                  schedule={schedule}
                  doctors={doctors}
                  setDoctor={setDoctor}
                  checkSchedule={checkSchedule}
                  checkStatus={checkStatus}
                />
              </div>
              <div className="mt-3">
                {checking ? (
                  <>{t("Buttons.Checking")}...</>
                ) : (
                  <b>
                    {warning && (
                      <>
                        {warning === "ok" ? (
                          <Chip
                            size="small"
                            label="Free slot. Ready to book"
                            color="primary"
                          />
                        ) : (
                          <>
                            {warning[0] === "appointment" ? (
                              <Chip
                                size="small"
                                label={`${t(
                                  "Calendar.Overlapping with appointment at"
                                )} ${warning[1]}`}
                                color="primary"
                              />
                            ) : (
                              <>
                                {warning[0] === "lock" && (
                                  <Chip
                                    size="small"
                                    label={`${t(
                                      "Calendar.Overlapping with time lock"
                                    )} ${t("Calendar.From")} ${warning[1]} ${t(
                                      "Calendar.To"
                                    )} ${warning[2]}`}
                                    color="primary"
                                  />
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </b>
                )}
              </div>
              <div className="mt-3">
                <TreatmentPicker
                  treatments={treatments}
                  setTreatment={setTreatment}
                  checkSchedule={checkSchedule}
                />
              </div>
              <div className="mt-3">
                <PatientPickerOnboarding
                  patients={patients}
                  setName={setName}
                  setFirstname={setFirstname}
                  setLastname={setLastname}
                  email={email}
                  setEmail={setEmail}
                  setPhone={setPhone}
                  setAddress={setAddress}
                  setUser={setUser}
                  setPatient_id={setPatient_id}
                  setInsurance={setInsurance}
                  setIsOpenPatientModal={setIsOpenPatientModal}
                  selectedPatient={isPatientCreated}
                />
              </div>
              <div className="mt-3">
                <PatientSummary
                  email={email}
                  phone={phone}
                  insurance={insurance}
                  remark={remark}
                  setRemark={setRemark}
                  isOnboarding={true}
                />
              </div>
              <div className="mt-3">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={assistant}
                      onChange={() => setAssistant(!assistant)}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={`${t("Calendar.Use Schedule Assistant")} (beta)`}
                />
              </div>
              <div className="mt-4">
                <SubmitButtons
                  onSubmit={onSubmit}
                  isOnboarding={true}
                  isPatient={Boolean(isPatientCreated) || patients.length !== 0}
                />
              </div>
            </div>
            {assistant && schedule.length > 0 && (
              <div className="col-4">
                <div
                  className="mt-1"
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "15px",
                    paddingTop: "7px",
                    paddingBottom: "9px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                >
                  <h1 className="schedule-title">
                    {t("Calendar.Schedule Assistant")}
                  </h1>
                  {loadingschedule ? (
                    <div className="d-flex justify-content-center mt-5 mb-5">
                      <CircularProgress color="secondary" />
                    </div>
                  ) : (
                    <ScheduleTable schedule={schedule} />
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <Modal
        show={isOpenPatientModal}
        // onHide={handleClosePatientModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-lg"
      >
        <Modal.Header style={{ background: "#F4F5FA" }}>
          <Modal.Title>{t("EditPatient.New Patient")}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#F4F5FA" }}>
          <NewPatientOnboarding
            fetchPatients={fetchPatients}
            setIsPatientCreated={setIsPatientCreated}
            handleClose={handleClosePatientModal}
          />
        </Modal.Body>
      </Modal>

      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(!error)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setError(!error)}
          severity="error"
        >
          {t(
            "Calendar.Error creating appointment. Please backup your data and retry"
          )}
        </MuiAlert>
      </Snackbar>

      <Snackbar
        open={required}
        autoHideDuration={6000}
        onClose={() => setRequired(!required)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setRequired(!required)}
          severity="error"
        >
          {t(
            "Calendar.All the fields are mandatory. Please complete it and retry"
          )}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default NewAppointmentOnboarding;
