import GetUserToken from "../utils/GetUserToken";
import { parseJSON } from "../utils/parseJSON";

const urlBase = `${process.env.REACT_APP_API_URL_V2}/treatments`;
const readUrl = (url = "") => `${urlBase}/${url}`;

class Treatment {
  async getOne({ id }) {
    const auth = `${GetUserToken()}`;
    const url = readUrl(`show/${id}`);

    let res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: auth,
        "api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
    });

    res = await parseJSON(res);
    return res;
  }

  async getAll(payload) {
    console.log("getAll - treatment - service");
    const auth = `${GetUserToken()}`;
    const { filters } = payload;
    const queryParams = new URLSearchParams(filters).toString();
    const url = `${process.env.REACT_APP_API_URL}/treatments?${queryParams}`;

    let res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: auth,
        "api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
    });

    res = await parseJSON(res);
    return res;
  }

  async getFirst({ id }) {
    const url = `${process.env.REACT_APP_API_URL}/treatments/getFirstTreatments?company_id=${id}`;

    let res = await fetch(url, {
      method: "GET",
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
    });

    res = await parseJSON(res);
    return res;
  }

  async getBookable() {
    const auth = `${GetUserToken()}`;
    const url = `${process.env.REACT_APP_API_URL}/treatments/getBookableTreatments`;

    let res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: auth,
        "api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
    });

    res = await parseJSON(res);
    return res;
  }

  async createOne(payload) {
    const formData = new FormData();
    const keys = Object.keys(payload);

    for (const key of keys) {
      const value = payload[key];
      formData.append(key, value);
    }

    const auth = `${GetUserToken()}`;
    const url = readUrl("create");

    let res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: auth,
        "api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
      body: formData,
    });

    res = await parseJSON(res);
    return res;
  }

  async update(payload) {
    const { id, ...rest } = payload;
    const formData = new FormData();
    const keys = Object.keys(rest);

    for (const key of keys) {
      const value = rest[key];
      formData.append(key, value);
    }

    const auth = `${GetUserToken()}`;
    const url = readUrl(`update/${id}`);

    let res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: auth,
        "api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
      body: formData,
    });

    res = await parseJSON(res);
    return res;
  }
}

const treatment = new Treatment();
export default treatment;
