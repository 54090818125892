import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

export const DeleteDialog = ({ dialog, setDialog, handleShow, product }) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog
        open={dialog}
        onClose={() => setDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Product Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("InvoiceDeleteDialog.0")}
            <br />
            <b>{product}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleShow()} color="secondary" autoFocus>
            {t("InvoiceDeleteDialog.1")}
          </Button>
          <Button onClick={() => setDialog(false)} color="primary">
            {t("InvoiceDeleteDialog.2")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteDialog;
