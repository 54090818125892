import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "../../assets/stylesheets/floatingButtons.css";
import { ReactComponent as Reminders } from "../../assets/icons/reminders.svg";
import Modal from "react-bootstrap/Modal";
import CitationReminderHelper from "./CitationReminderHelper";
import AlertComponent from "../alerts/Alert";

export const NewReminderCitation = ({
  patientName,
  treatments,
  patientID,
  patientEmail,
}) => {
  // translations
  const { t } = useTranslation();

  //Create new reminder
  const [show, setShow] = useState(false);
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  return (
    <>
      <div
        className="floating-div"
        id="reminderCitation_button"
        onClick={handleShow}
      >
        <Reminders className="icon" />
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ background: "#F4F5FA" }}>
          <Modal.Title>{t("NewReminderHelper.0")}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#F4F5FA" }}>
          <CitationReminderHelper
            patientName={patientName}
            treatments={treatments}
            patientID={patientID}
            patientEmail={patientEmail}
            handleClose={handleClose}
            setIsAlert={setIsAlert}
          />
        </Modal.Body>
      </Modal>

      {isAlert.open && (
        <AlertComponent
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
    </>
  );
};
