import React, { useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import delete_invoice from "../../../assets/icons/delete_invoice.png";
import { useSelector } from "react-redux";

export const ProductsAccordion = ({
  handleRefreshV2,
  product,
  index,
  setAdittionalproducts,
  adittionalproducts,
}) => {
  const state = useSelector((state) => state.companyStore.one.data);

  //const [checked, setChecked] = React.useState(product.toinvoice);
  const [checked, setChecked] = React.useState(true);

  const handleRemoveClick = (e) => {
    /* console.log('index solo', index)
        let currentAdittionalProducts = [...adittionalproducts]
        console.log('product',currentAdittionalProducts[index])
        currentAdittionalProducts[index].toinvoice = !currentAdittionalProducts[index].toinvoice;
        setAdittionalproducts(currentAdittionalProducts);
        handleRefreshV2(); */

    // console.log(index);

    setChecked(e.target.checked);

    let currentAdittionalProducts = [...adittionalproducts];

    currentAdittionalProducts[index].toinvoice = e.target.checked;

    // console.log("INDEX", currentAdittionalProducts[index]);

    setAdittionalproducts(currentAdittionalProducts);

    handleRefreshV2();
  };

  const deleteProduct = (index) => {
    const list = [...adittionalproducts];
    list.splice(index, 1);
    setAdittionalproducts(list);
    handleRefreshV2();
  };

  useEffect(() => {
    handleRefreshV2();
  }, [adittionalproducts]);

  // console.log("ALL PRODUCTS", adittionalproducts);

  return (
    <>
      <Accordion className="invoice-patient-accordion-master">
        <AccordionSummary
          className="invoice-patient-accordion-summary"
          // expandIcon={<ExpandMoreIcon style={{ color: "#38baff" }} />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
          style={{ zIndex: "99" }}
        >
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={
              <Checkbox
                value={index}
                //onChange={(e) => handleRemoveClick(e,index)}
                //checked={product.toinvoice}
                checked={checked}
                onChange={(e) => handleRemoveClick(e, index)}
                style={{ color: "var(--primary-color)" }}
              />
            }
            label={
              <>
                <span className="subtitles-patients border-end">
                  {new Date(product.appointment_date).toLocaleString([], {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    timeZone: "UTC",
                  })}
                  &nbsp;
                </span>
                <span className="subtitles-patients border-end">
                  &nbsp;{product.title}&nbsp;
                </span>
                <span className="key-text-box border-end">
                  &nbsp;{product.rate}&nbsp;
                </span>
                <span className="key-text-box">
                  &nbsp;{state?.currency && state.currency}&nbsp;
                  {parseFloat(product.price).toFixed(2).replace(".", ",")}&nbsp;
                </span>
              </>
            }
          />
        </AccordionSummary>
        {/*                  <AccordionDetails>
                    <Typography color="textSecondary">
                        <span>Delete&nbsp;<img src={delete_invoice}  onClick={() => deleteProduct(index)} style={{cursor:'pointer'}} /></span>
                    </Typography>
                </AccordionDetails> */}
      </Accordion>
    </>
  );
};

export default ProductsAccordion;
