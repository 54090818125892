import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  Switch,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SaveIcon from "@material-ui/icons/Save";
import GetUserToken from "../../utils/GetUserToken";
import FetchIndexData from "../../utils/FetchIndexData";

const NewReminder = ({ handleClose, setIsAlert }) => {
  // translations
  const { t } = useTranslation();
  // utils
  const [loading, setLoading] = useState(false);
  // data
  const [doctors, setDoctors] = useState([]);

  // data to be filled by user
  const [title, setTitle] = useState("");
  const [task, setTask] = useState("");
  const [time, setTime] = useState("");
  const [note, setNotes] = useState("");
  const [assignto, setDoctor] = useState("");
  const [priv, setPriv] = useState(false);

  const done = false;

  const fetchData = async () => {
    var data = await FetchIndexData("/citations/getDoctors");
    let filteredDoctors = data.clinicDoctors.filter((item) => {
      return item.archived === false;
    });
    setDoctors(filteredDoctors);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDoctorChange = (e, value) => {
    var fieldValue = value;
    if (!fieldValue) {
      fieldValue = null;
    } else setDoctor(value.id);
  };

  // Post method
  const handleSubmit = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      const reminder = {
        title,
        task,
        time,
        note,
        assignto,
        done,
        private: priv,
      };
      var url = process.env.REACT_APP_API_URL + "/reminders/create";
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: GetUserToken(),
        },
        body: JSON.stringify(reminder),
      };

      const res = await fetch(url, requestOptions);
      if (res.ok) {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
      handleClose();
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="row">
        <div className="col">
          <div className="form-group">
            <label className="title-inputs">{t("NewReminder.0")}</label>
            <input
              required
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="input-control"
            />
          </div>

          <div className="form-group">
            <label className="title-inputs">{t("NewReminder.1")}</label>
            <input
              required
              type="text"
              value={task}
              onChange={(e) => setTask(e.target.value)}
              className="input-control"
            />
          </div>

          <div className="form-group">
            <label className="title-inputs">{t("NewReminder.2")}</label>
            <input
              required
              type="datetime-local"
              value={time}
              onChange={(e) => setTime(e.target.value)}
              className="input-control"
            />
          </div>

          <div className="form-group mt-1 row">
            <div className="col-10">
              <label className="title-inputs">{t("NewReminder.3")}</label>
              <br />
              <Autocomplete
                noOptionsText={"Therapist not found"}
                options={doctors}
                placeholder={"Therapist"}
                onChange={(e, value) => handleDoctorChange(e, value)}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
            <div className="col-2">
              <label className="title-inputs">{t("NewReminder.4")}</label>
              <br />
              <Switch
                checked={priv}
                onChange={() => setPriv(!priv)}
                name="checkedA"
                color="secondary"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </div>
          </div>

          <div className="form-group">
            <label className="title-inputs">{t("NewReminder.5")}</label>
            <TextareaAutosize
              type="text"
              value={note}
              onChange={(e) => setNotes(e.target.value)}
              aria-label="minimum height"
              minRows={3}
              className="input-control"
            />
          </div>

          {loading ? (
            <div className="d-flex justify-content-center">
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <>
              <div className="mt-2 text-center">
                <button className="blue-btn" style={{ width: "100%" }}>
                  <SaveIcon />
                  &nbsp;
                  {t("NewReminder.6")}
                </button>
              </div>
            </>
          )}
        </div>
      </form>
    </>
  );
};

export default NewReminder;
