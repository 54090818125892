import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import BlendForm from "../components/Form";
import {
  createOneBlend,
  resetCreateBlend,
  resetCreateOneBlend,
} from "../../../redux/slices/blend";
import convertToNumber from "../../../helpers/convertToNumber";
import AlertComponent from "../../../components/alerts/Alert";

const keys = {
  pigin_name: "",
  chinese_name: "",
  latin_name: "",
  english_name: "",
  therapeutic_actions: "",
  administration: "",
  clinical_manifestations: "",
  treats_conditions: "",
  syndroms: [],
  contraindications: "",
  herbs_drugs_interactions: "",
  herb: [],
  tongue: {
    color_1: "",
    shape: "",
    coating: "",
    color_2: "",
    veins: "",
  },
  pulse: {
    right: {
      kidney: "",
      spleen: "",
      lung: "",
    },
    left: {
      kidney: "",
      liver: "",
      heart: "",
    },
  },
};

const Component = ({
  blendCreate,
  blendCreateLoading,
  blendCreateError,
  blendCreateSuccess,
  infoRoute,
}) => {
  // translation
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  // route
  const { history } = infoRoute;
  // utils
  const [initialValues] = useState(keys);
  const [alert, setAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    // Get all form elements
    var formElements = event.target.elements;

    // Create an object to store form values
    var formData = {};

    // Iterate over form elements and populate formData object
    for (var i = 0; i < formElements.length; i++) {
      var element = formElements[i];

      // Check if the element name is not empty
      if (element.name) {
        let value = element.value;

        // Check if the name includes "herb"
        if (
          element.name.includes("herb") &&
          element.name !== "herbs_drugs_interactions"
        ) {
          // Extract the index and attribute from the element name
          var matches = element.name.match(/herb\.(\d+)\.([a-z]+)/);

          if (matches) {
            var index = parseInt(matches[1]);
            var attribute = matches[2];

            // Ensure the formData has enough elements for the "herb" array
            formData["herb"] = formData["herb"] || [];

            // Ensure the "herb" array has enough elements
            formData["herb"][index] = formData["herb"][index] || {};

            // Assign value to the corresponding element in the formData
            formData["herb"][index][attribute] = Number(value);
          }
        } else if (element.name.includes("syndroms")) {
          // Extract the index and attribute from the element name
          var matches = element.name.match(/syndroms\.(\d+)/);

          if (matches) {
            var index = parseInt(matches[1]);

            // Ensure the formData has enough elements for the "herb" array
            formData["syndroms"] = formData["syndroms"] || [];

            // Ensure the "herb" array has enough elements
            formData["syndroms"][index] = formData["syndroms"][index] || {};

            // Assign value to the corresponding element in the formData
            formData["syndroms"][index] = Number(value);
          }
        } else if (element.name.includes("private")) {
          formData["private"] = !value || value === "true";
        } else {
          // Split the name into nested keys using dot notation
          const keys = element.name.split(".");
          let tempObj = formData;

          // Build the nested structure
          for (let j = 0; j < keys.length - 1; j++) {
            const key = keys[j];
            tempObj[key] = tempObj[key] || {};
            tempObj = tempObj[key];
          }

          // Assign the value to the final nested key
          tempObj[keys[keys.length - 1]] = convertToNumber(value);
        }
      }
    }

    dispatch(createOneBlend(formData));
  };

  useEffect(() => {
    if (blendCreateError) {
      setAlert({
        open: true,
        severity: "error",
        message: t("Messages.The operation could not be processed"),
      });
    }
  }, [blendCreateError]);

  useEffect(() => {
    if (blendCreateSuccess) {
      setAlert({
        open: true,
        severity: "success",
        message: t("Messages.The operation was successful"),
      });
      setTimeout(() => {
        history.push("/blend");
      }, 1000);
    }
  }, [blendCreateSuccess]);

  useEffect(() => {
    if (blendCreateError || blendCreateSuccess) {
      setTimeout(() => {
        dispatch(resetCreateOneBlend());
        setAlert({
          open: false,
          severity: "",
          message: "",
        });
      }, 3000);
    }
  }, [blendCreateError, blendCreateSuccess]);

  return (
    <>
      <div>
        <h4 className="main-title-treatments">{t("BlendNew.New Blend")}</h4>
        <p className="blue-anchors">
          <Link className="blue-anchors" to="/blend">
            &#60; {t("BlendEdit.Back to blends")}
          </Link>
        </p>
      </div>
      <br />
      <Form
        noValidate
        id="new-blend-form"
        onSubmit={handleSubmit}
        style={{ width: "95%" }}
      >
        <BlendForm
          initialValues={initialValues}
          isLoading={blendCreateLoading}
        />
        <div className="d-flex justify-content-end">
          <Button
            className="btnGo"
            form="new-blend-form"
            type="submit"
            target={false}
            disabled={blendCreateLoading}
          >
            {t("BlendNew.Create Blend")}
          </Button>
        </div>
      </Form>

      <AlertComponent
        isAlert={alert.open}
        setIsAlert={setAlert}
        severity={alert.severity}
        message={alert.message}
      />
    </>
  );
};

const state = ({ blendStore }) => {
  const {
    data: blendCreate,
    loading: blendCreateLoading,
    error: blendCreateError,
    success: blendCreateSuccess,
  } = blendStore.create;

  return {
    blendCreate,
    blendCreateLoading,
    blendCreateError,
    blendCreateSuccess,
  };
};

export default connect(state)(Component);
