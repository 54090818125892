import styled from 'styled-components'
import { Card as CardBottstrap } from "react-bootstrap";
import Autocomplete from "@mui/material/Autocomplete";

export const Card = styled(CardBottstrap)`
  width: 100%;
  padding: 0px;
  border-radius: var(--bs-border-radius);
  border: var(--bs-border-width) solid var(--bs-border-color);
`

export const Select = styled(Autocomplete)`
  background-color: white;
`

export const Center = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background: rgb(244, 245, 250);
  border-radius: 10px;
  padding: 10px 25px;
  gap: 30px;
`