import React from "react";
import { useTranslation } from "react-i18next";
import { EditText, EditTextarea } from "react-edit-text";
import { Card } from "react-bootstrap";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DeleteHerbDialog from "../DeleteHerbDialog";

const HerbFields = ({
  isEditingMode,
  idLanguage,
  herbTranslation,
  currentTranslation,
  isOpen,
  setIsOpen,
  handleViewHerbTranslations,
  handleUpdateHerbTranslations,
  handleSubmitHerbTranslation,
  handlePreDeleteHerbTranslation,
  handleDeleteHerbTranslation,
}) => {
  // translations
  const { t } = useTranslation();
  // data
  const actions_and_indications = handleViewHerbTranslations(
    herbTranslation,
    idLanguage,
    "actions_and_indications",
    null,
    true
  );
  const major_combinations = handleViewHerbTranslations(
    herbTranslation,
    idLanguage,
    "major_combinations",
    null,
    true
  );
  const translation = herbTranslation.filter(
    (element) => element.languages_id === idLanguage
  );

  return (
    <>
      {/* Name */}
      <div
        className="row patient-id-box"
        style={{
          width: "95%",
          marginLeft: "0px",
          paddingLeft: "20px",
          marginBottom: "21px",
        }}
      >
        <p className="subtitles-patients">{t("NewIndividualHerbs.0")}</p>

        <div className="col">
          {!isEditingMode && (
            <div className="_i5--j key-text-box">
              {handleViewHerbTranslations(herbTranslation, idLanguage, "name")}
            </div>
          )}
          {isEditingMode && (
            <EditText
              className="key-text-box"
              name="name"
              defaultValue={handleViewHerbTranslations(
                herbTranslation,
                idLanguage,
                "name"
              )}
              onSave={({ name, value }) =>
                handleUpdateHerbTranslations(idLanguage, name, value)
              }
            />
          )}
        </div>
      </div>

      {/* Description boxes */}
      <Accordion className="herbs-accordion" style={{ width: "95%" }}>
        <AccordionSummary
          expandIcon={
            <ArrowForwardIosIcon
              style={{ color: "#38baff", fontSize: "15px" }}
            />
          }
          aria-label="Expand"
          style={{
            zIndex: "99",
            flexDirection: "row-reverse",
          }}
        >
          <span
            className="subtitles-patients"
            style={{ fontSize: "18px", paddingLeft: "14px" }}
          >
            {t("Herbs.Description")}
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <div
              className="row patient-id-box"
              style={{
                width: "95%",
                marginLeft: "12px",
                paddingLeft: "20px",
              }}
            >
              <div className="col-3 text-left">
                <p className="subtitles-patients">{t("Herbs.Best quality")}</p>
                <div className="col">
                  {!isEditingMode && (
                    <div className="_i5--j key-text-box px-0">
                      {handleViewHerbTranslations(
                        herbTranslation,
                        idLanguage,
                        "best_quality"
                      )}
                    </div>
                  )}
                  {isEditingMode && (
                    <>
                      <EditTextarea
                        className="key-text-box px-0"
                        name="best_quality"
                        defaultValue={handleViewHerbTranslations(
                          herbTranslation,
                          idLanguage,
                          "best_quality"
                        )}
                        onSave={({ name, value }) =>
                          handleUpdateHerbTranslations(idLanguage, name, value)
                        }
                      />
                    </>
                  )}
                </div>
              </div>

              <div
                className="col-8 border-start"
                style={{ paddingLeft: "38px", marginLeft: "6px" }}
              >
                <div className="row">
                  <div className="row">
                    <div className="col-8">
                      <p className="subtitles-patients">{t("ViewHerb.5")}</p>
                    </div>
                  </div>
                  <div className="col">
                    {!isEditingMode && (
                      <div className="_i5--j key-text-box px-0">
                        {handleViewHerbTranslations(
                          herbTranslation,
                          idLanguage,
                          "category"
                        )}
                      </div>
                    )}
                    {isEditingMode && (
                      <EditText
                        className="key-text-box"
                        name="category"
                        defaultValue={handleViewHerbTranslations(
                          herbTranslation,
                          idLanguage,
                          "category"
                        )}
                        onSave={({ name, value }) =>
                          handleUpdateHerbTranslations(idLanguage, name, value)
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="row mt-3 patient-id-box"
              style={{
                width: "95%",
                marginLeft: "12px",
                paddingLeft: "20px",
              }}
            >
              <div className="col-3 text-left">
                <p className="subtitles-patients">{t("Herbs.Taste")}</p>
                <div className="col">
                  {!isEditingMode && (
                    <div className="_i5--j key-text-box px-0">
                      {handleViewHerbTranslations(
                        herbTranslation,
                        idLanguage,
                        "taste"
                      )}
                    </div>
                  )}
                  {isEditingMode && (
                    <EditTextarea
                      className="key-text-box px-0"
                      name="taste"
                      defaultValue={handleViewHerbTranslations(
                        herbTranslation,
                        idLanguage,
                        "taste"
                      )}
                      onSave={({ name, value }) =>
                        handleUpdateHerbTranslations(idLanguage, name, value)
                      }
                    />
                  )}
                </div>
              </div>

              <div
                className="col-3 text-left border-start"
                style={{ paddingLeft: "38px", marginLeft: "6px" }}
              >
                <p className="subtitles-patients">{t("Herbs.Temperature")}</p>
                <div className="col">
                  {!isEditingMode && (
                    <div className="_i5--j key-text-box px-0">
                      {handleViewHerbTranslations(
                        herbTranslation,
                        idLanguage,
                        "temperature"
                      )}
                    </div>
                  )}
                  {isEditingMode && (
                    <EditTextarea
                      className="key-text-box px-0"
                      name="temperature"
                      defaultValue={handleViewHerbTranslations(
                        herbTranslation,
                        idLanguage,
                        "temperature"
                      )}
                      onSave={({ name, value }) =>
                        handleUpdateHerbTranslations(idLanguage, name, value)
                      }
                    />
                  )}
                </div>
              </div>

              <div className="col-3 text-left border-start">
                <p className="subtitles-patients">
                  {t("Herbs.Entering Meridians")}
                </p>
                <div className="col">
                  {!isEditingMode && (
                    <div className="_i5--j key-text-box px-0">
                      {handleViewHerbTranslations(
                        herbTranslation,
                        idLanguage,
                        "entering_meridians"
                      )}
                    </div>
                  )}
                  {isEditingMode && (
                    <EditTextarea
                      className="key-text-box"
                      name="entering_meridians"
                      defaultValue={handleViewHerbTranslations(
                        herbTranslation,
                        idLanguage,
                        "entering_meridians"
                      )}
                      onSave={({ name, value }) =>
                        handleUpdateHerbTranslations(idLanguage, name, value)
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      {/* end Description boxes */}

      {/* Dosage and Mixtures boxes */}
      <Accordion className="herbs-accordion" style={{ width: "95%" }}>
        <AccordionSummary
          expandIcon={
            <ArrowForwardIosIcon
              style={{ color: "#38baff", fontSize: "15px" }}
            />
          }
          aria-label="Expand"
          style={{
            zIndex: "99",
            flexDirection: "row-reverse",
          }}
        >
          <span
            className="subtitles-patients"
            style={{ fontSize: "18px", paddingLeft: "14px" }}
          >
            {t("Herbs.Dosage and Mixtures")}
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <div
              className="row patient-id-box"
              style={{
                width: "95%",
                marginLeft: "12px",
                paddingLeft: "20px",
              }}
            >
              <div className="col-3 text-left">
                <p className="subtitles-patients">{t("Herbs.Dosage")}</p>
                <div className="col">
                  <div>
                    {!isEditingMode && (
                      <div className="_i5--j key-text-box">
                        {handleViewHerbTranslations(
                          herbTranslation,
                          idLanguage,
                          "dosage",
                          "normal"
                        )}
                      </div>
                    )}
                    {isEditingMode && (
                      <EditText
                        className="key-text-box px-0"
                        name="dosage"
                        defaultValue={handleViewHerbTranslations(
                          herbTranslation,
                          idLanguage,
                          "dosage",
                          "normal"
                        )}
                        onSave={({ name, value }) =>
                          handleUpdateHerbTranslations(
                            idLanguage,
                            name,
                            value,
                            "normal"
                          )
                        }
                      />
                    )}
                  </div>
                </div>
              </div>

              <div
                className="col-3 text-left border-start"
                style={{ paddingLeft: "38px", marginLeft: "6px" }}
              >
                <p className="subtitles-patients">{t("Herbs.Tincture")}</p>
                <div className="col">
                  {!isEditingMode && (
                    <div className="_i5--j key-text-box px-0">
                      {handleViewHerbTranslations(
                        herbTranslation,
                        idLanguage,
                        "dosage",
                        "tincture"
                      )}
                    </div>
                  )}
                  {isEditingMode && (
                    <EditTextarea
                      className="key-text-box"
                      name="dosage"
                      defaultValue={handleViewHerbTranslations(
                        herbTranslation,
                        idLanguage,
                        "dosage",
                        "tincture"
                      )}
                      onSave={({ name, value }) =>
                        handleUpdateHerbTranslations(
                          idLanguage,
                          name,
                          value,
                          "tincture"
                        )
                      }
                    />
                  )}
                </div>
              </div>

              <div
                className="col-3 text-left border-start"
                style={{ paddingLeft: "38px", marginLeft: "6px" }}
              >
                <p className="subtitles-patients">{t("Herbs.Powder/pills")}</p>
                <div className="col">
                  {!isEditingMode && (
                    <div className="_i5--j key-text-box px-0">
                      {handleViewHerbTranslations(
                        herbTranslation,
                        idLanguage,
                        "dosage",
                        "powdersorpills"
                      )}
                    </div>
                  )}
                  {isEditingMode && (
                    <>
                      <EditTextarea
                        className="key-text-box"
                        name="dosage"
                        defaultValue={handleViewHerbTranslations(
                          herbTranslation,
                          idLanguage,
                          "dosage",
                          "powdersorpills"
                        )}
                        onSave={({ name, value }) =>
                          handleUpdateHerbTranslations(
                            idLanguage,
                            name,
                            value,
                            "powdersorpills"
                          )
                        }
                      />
                    </>
                  )}
                </div>
              </div>
            </div>

            <div
              className="row mt-3 patient-id-box"
              style={{
                width: "95%",
                marginLeft: "12px",
                paddingLeft: "20px",
              }}
            >
              <div className="col-3 text-left">
                <p className="subtitles-patients">
                  {t("Herbs.Administrations")}
                </p>
                <div className="col">
                  {!isEditingMode && (
                    <div className="_i5--j key-text-box px-0">
                      {handleViewHerbTranslations(
                        herbTranslation,
                        idLanguage,
                        "dosage",
                        "administration"
                      )}
                    </div>
                  )}
                  {isEditingMode && (
                    <>
                      <EditTextarea
                        className="key-text-box px-0"
                        name="dosage"
                        defaultValue={handleViewHerbTranslations(
                          herbTranslation,
                          idLanguage,
                          "dosage",
                          "administration"
                        )}
                        onSave={({ name, value }) =>
                          handleUpdateHerbTranslations(
                            idLanguage,
                            name,
                            value,
                            "administration"
                          )
                        }
                      />
                    </>
                  )}
                </div>
              </div>

              <div
                className="col-3 text-left border-start"
                style={{ paddingLeft: "38px", marginLeft: "6px" }}
              >
                <p className="subtitles-patients">{t("Herbs.Processing")}</p>
                <div className="col">
                  {!isEditingMode && (
                    <div className="_i5--j key-text-box px-0">
                      {handleViewHerbTranslations(
                        herbTranslation,
                        idLanguage,
                        "dosage",
                        "processing"
                      )}
                    </div>
                  )}
                  {isEditingMode && (
                    <>
                      <EditTextarea
                        className="key-text-box"
                        name="dosage"
                        defaultValue={handleViewHerbTranslations(
                          herbTranslation,
                          idLanguage,
                          "dosage",
                          "processing"
                        )}
                        onSave={({ name, value }) =>
                          handleUpdateHerbTranslations(
                            idLanguage,
                            name,
                            value,
                            "processing"
                          )
                        }
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      {/* end Dosage and Mixtures boxes */}

      {/* Actions and Indications boxes */}
      <Accordion className="herbs-accordion" style={{ width: "95%" }}>
        <AccordionSummary
          expandIcon={
            <ArrowForwardIosIcon
              style={{ color: "#38baff", fontSize: "15px" }}
            />
          }
          aria-label="Expand"
          style={{
            zIndex: "99",
            flexDirection: "row-reverse",
          }}
        >
          <span
            className="subtitles-patients"
            style={{ fontSize: "18px", paddingLeft: "14px" }}
          >
            {t("ViewHerb.7")}
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <div
            className="herbs-accordion-body"
            style={{
              background: "#f4f5fa",
              padding: "27px",
              borderRadius: "9px",
            }}
          >
            <table className="table herb-accordion-table-actions-and-indications">
              <thead>
                <tr>
                  <th
                    className="herb-accordion-table-actions-and-indications-thead herb-ai-titles"
                    style={{ borderLeftWidth: "0px" }}
                  >
                    {t("ViewHerb.8")}
                  </th>
                  <th
                    className="herb-accordion-table-actions-and-indications-thead herb-ai-titles"
                    style={{ borderLeftWidth: "0px" }}
                  >
                    {t("ViewHerb.9")}
                  </th>
                </tr>
              </thead>
              <tbody style={{ borderTop: "0px" }}>
                {Array.isArray(actions_and_indications) &&
                  actions_and_indications.map((element, i) => (
                    <tr key={i}>
                      <th
                        className="herb-accordion-table-actions-and-indications-tbody"
                        style={{
                          fontWeight: "300",
                          borderLeftWidth: "0px",
                          borderBottomWidth: "0px",
                          borderBottomLeftRadius:
                            i === actions_and_indications.length - 1 && "9px",
                          height: "100%",
                        }}
                      >
                        {!isEditingMode && (
                          <div className="_i5--j key-text-box herb-card-table-actions-and-indications">
                            {element["Actions"]
                              ? element["Actions"]
                              : t("Messages.no data")}
                          </div>
                        )}
                        {isEditingMode && (
                          <EditTextarea
                            name="actions_and_indications"
                            defaultValue={
                              element["Actions"]
                                ? element["Actions"]
                                : t("Messages.no data")
                            }
                            onSave={({ name, value }) =>
                              handleUpdateHerbTranslations(
                                idLanguage,
                                name,
                                value,
                                "Actions",
                                i
                              )
                            }
                          />
                        )}
                      </th>
                      <th
                        className="herb-accordion-table-actions-and-indications-tbody"
                        style={{
                          fontWeight: "300",
                          borderBottomWidth: "0px",
                          borderBottomRightRadius:
                            i === actions_and_indications.length - 1 && "9px",
                          height: "100%",
                        }}
                      >
                        {!isEditingMode && (
                          <div className="_i5--j key-text-box herb-card-table-actions-and-indications">
                            {element["Indications/Syndromes"]
                              ? element["Indications/Syndromes"]
                              : t("Messages.no data")}
                          </div>
                        )}
                        {isEditingMode && (
                          <EditTextarea
                            name="actions_and_indications"
                            defaultValue={
                              element["Indications/Syndromes"]
                                ? element["Indications/Syndromes"]
                                : t("Messages.no data")
                            }
                            onSave={({ name, value }) =>
                              handleUpdateHerbTranslations(
                                idLanguage,
                                name,
                                value,
                                "Indications/Syndromes",
                                i
                              )
                            }
                          />
                        )}
                      </th>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </AccordionDetails>
      </Accordion>
      {/* end Actions and Indications boxes */}

      {/* Contraindications, incompabilities And Herb/Drug Interactions boxes*/}
      <Accordion className="herbs-accordion" style={{ width: "95%" }}>
        <AccordionSummary
          expandIcon={
            <ArrowForwardIosIcon
              style={{ color: "#38baff", fontSize: "15px" }}
            />
          }
          aria-label="Expand"
          style={{
            zIndex: "99",
            flexDirection: "row-reverse",
          }}
        >
          <span
            className="subtitles-patients"
            style={{ fontSize: "18px", paddingLeft: "14px" }}
          >
            {t("ViewHerb.10")}
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <div
            className="herbs-accordion-body d-flex"
            style={{
              background: "#f4f5fa",
              padding: "27px",
              borderRadius: "9px",
            }}
          >
            <Card
              className="reminders-cards"
              style={{
                width: "35%",
                borderRadius: "10px",
                marginRight: "10px",
                paddingBottom: "12px",
              }}
            >
              <Card.Header
                className="reminders-subtitle"
                style={{
                  border: "none",
                  background: "#E1DFE5",
                  borderRadius: "10px",
                  borderBottomRightRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  paddingBottom: "12px",
                }}
              >
                {t("ViewHerb.11")}
              </Card.Header>

              <Card.Body style={{ fontWeight: "300" }}>
                {!isEditingMode && (
                  <div className="_i5--j key-text-box">
                    {handleViewHerbTranslations(
                      herbTranslation,
                      idLanguage,
                      "contraindications"
                    )}
                  </div>
                )}
                {isEditingMode && (
                  <EditTextarea
                    name="contraindications"
                    defaultValue={handleViewHerbTranslations(
                      herbTranslation,
                      idLanguage,
                      "contraindications"
                    )}
                    onSave={({ name, value }) =>
                      handleUpdateHerbTranslations(idLanguage, name, value)
                    }
                  />
                )}
              </Card.Body>
            </Card>
            <Card
              className="reminders-cards"
              style={{
                width: "35%",
                borderRadius: "10px",
                marginRight: "10px",
              }}
            >
              <Card.Header
                className="reminders-subtitle"
                style={{
                  border: "none",
                  background: "#E1DFE5",
                  borderRadius: "10px",
                  borderBottomRightRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  paddingBottom: "12px",
                }}
              >
                {t("ViewHerb.12")}
              </Card.Header>

              <Card.Body style={{ fontWeight: "300" }}>
                {!isEditingMode && (
                  <div className="_i5--j key-text-box">
                    {handleViewHerbTranslations(
                      herbTranslation,
                      idLanguage,
                      "incompatibilities"
                    )}
                  </div>
                )}
                {isEditingMode && (
                  <EditTextarea
                    name="incompatibilities"
                    defaultValue={handleViewHerbTranslations(
                      herbTranslation,
                      idLanguage,
                      "incompatibilities"
                    )}
                    onSave={({ name, value }) =>
                      handleUpdateHerbTranslations(idLanguage, name, value)
                    }
                  />
                )}
              </Card.Body>
            </Card>
            <Card
              className="reminders-cards"
              style={{ width: "35%", borderRadius: "10px" }}
            >
              <Card.Header
                className="reminders-subtitle"
                style={{
                  border: "none",
                  background: "#E1DFE5",
                  borderRadius: "10px",
                  borderBottomRightRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  paddingBottom: "12px",
                }}
              >
                {t("ViewHerb.13")}
              </Card.Header>

              <Card.Body style={{ fontWeight: "300" }}>
                {!isEditingMode && (
                  <div className="_i5--j key-text-box">
                    {handleViewHerbTranslations(
                      herbTranslation,
                      idLanguage,
                      "herb_drug_interactions"
                    )}
                  </div>
                )}
                {isEditingMode && (
                  <EditTextarea
                    name="herb_drug_interactions"
                    defaultValue={handleViewHerbTranslations(
                      herbTranslation,
                      idLanguage,
                      "herb_drug_interactions"
                    )}
                    onSave={({ name, value }) =>
                      handleUpdateHerbTranslations(idLanguage, name, value)
                    }
                  />
                )}
              </Card.Body>
            </Card>
          </div>
        </AccordionDetails>
      </Accordion>
      {/* end Contraindications, incompabilities And Herb/Drug Interactions boxes */}

      {/* Frecuent Pairings boxes */}
      <Accordion className="herbs-accordion" style={{ width: "95%" }}>
        <AccordionSummary
          expandIcon={
            <ArrowForwardIosIcon
              style={{ color: "#38baff", fontSize: "15px" }}
            />
          }
          aria-label="Expand"
          style={{
            zIndex: "99",
            flexDirection: "row-reverse",
          }}
        >
          <span
            className="subtitles-patients"
            style={{ fontSize: "18px", paddingLeft: "14px" }}
          >
            {t("ViewHerb.14")}
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <div
            className="herbs-accordion-body"
            style={{
              background: "#f4f5fa",
              padding: "27px",
              borderRadius: "9px",
            }}
          >
            <table className="table herb-table-major-combinations">
              <thead>
                <tr>
                  <th className="herb-accordion-table-major-combinations herb-mc-titles">
                    {t("ViewHerb.18")}
                  </th>
                  <th className="herb-accordion-table-major-combinations herb-mc-titles">
                    {t("ViewHerb.19")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(major_combinations) &&
                  major_combinations.map((element, i) => (
                    <tr key={i}>
                      <td
                        className="herb-accordion-table-major-combinations"
                        style={{
                          borderBottomLeftRadius:
                            i === major_combinations.length - 1 && "6px",
                        }}
                      >
                        {!isEditingMode && (
                          <div
                            className="_i5--j key-text-box"
                            style={{ fontWeight: "bold" }}
                          >
                            {element.ingredients
                              ? element.ingredients
                              : t("Messages.no data")}
                          </div>
                        )}
                        {isEditingMode && (
                          <EditTextarea
                            name="major_combinations"
                            defaultValue={
                              element.ingredients
                                ? element.ingredients
                                : t("Messages.no data")
                            }
                            onSave={({ name, value }) =>
                              handleUpdateHerbTranslations(
                                idLanguage,
                                name,
                                value,
                                "ingredients",
                                i
                              )
                            }
                            style={{ fontWeight: "bold" }}
                          />
                        )}
                      </td>
                      <td
                        className="herb-accordion-table-major-combinations"
                        style={{
                          fontWeight: "300",
                          borderBottomRightRadius:
                            i === major_combinations.length - 1 && "6px",
                        }}
                      >
                        <div>
                          {!isEditingMode && (
                            <div className="_i5--j key-text-box">
                              {element.action
                                ? element.action
                                : t("Messages.no data")}
                            </div>
                          )}
                          {isEditingMode && (
                            <EditTextarea
                              name="major_combinations"
                              defaultValue={
                                element.action
                                  ? element.action
                                  : t("Messages.no data")
                              }
                              onSave={({ name, value }) =>
                                handleUpdateHerbTranslations(
                                  idLanguage,
                                  name,
                                  value,
                                  "action",
                                  i
                                )
                              }
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </AccordionDetails>
      </Accordion>
      {/* end Frecuent Pairings boxes */}

      {/* Notes boxes */}
      <Accordion className="herbs-accordion" style={{ width: "95%" }}>
        <AccordionSummary
          expandIcon={
            <ArrowForwardIosIcon
              style={{ color: "#38baff", fontSize: "15px" }}
            />
          }
          aria-label="Expand"
          style={{
            zIndex: "99",
            flexDirection: "row-reverse",
          }}
        >
          <span
            className="subtitles-patients"
            style={{ fontSize: "18px", paddingLeft: "14px" }}
          >
            {t("ViewHerb.15")}
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <div className="herb-accordion-body">
            {!isEditingMode && (
              <div className="_i5--j key-text-box">
                {handleViewHerbTranslations(
                  herbTranslation,
                  idLanguage,
                  "notes"
                )}
              </div>
            )}
            {isEditingMode && (
              <EditTextarea
                name="notes"
                defaultValue={handleViewHerbTranslations(
                  herbTranslation,
                  idLanguage,
                  "notes"
                )}
                onSave={({ name, value }) =>
                  handleUpdateHerbTranslations(idLanguage, name, value)
                }
              />
            )}
          </div>
        </AccordionDetails>
      </Accordion>
      {/* end Notes boxes */}

      {/* Formulas boxes */}
      <Accordion className="herbs-accordion" style={{ width: "95%" }}>
        <AccordionSummary
          expandIcon={
            <ArrowForwardIosIcon
              style={{ color: "#38baff", fontSize: "15px" }}
            />
          }
          aria-label="Expand"
          style={{
            zIndex: "99",
            flexDirection: "row-reverse",
          }}
        >
          <span
            className="subtitles-patients"
            style={{ fontSize: "18px", paddingLeft: "14px" }}
          >
            {t("ViewHerb.20")}
          </span>
        </AccordionSummary>
        <AccordionDetails style={{ marginBottom: "25px" }}>
          <Typography className="herb-accordion-body">
            {t("ViewHerb.21")}...
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* end Formulas boxes */}

      {isEditingMode && (
        <>
          {/* Button Update Herb Translation */}
          <div
            className="d-flex justify-content-end mb-3"
            style={{ width: "95%" }}
          >
            <button
              className={
                translation.length !== 0 && translation[0].id
                  ? "red-btn"
                  : "blue-btn-disabled"
              }
              disabled={!(translation.length !== 0 && translation[0].id)}
              onClick={() => handlePreDeleteHerbTranslation(idLanguage)}
              style={{ width: "185px", marginRight: "20px" }}
            >
              {t("Buttons.Delete")}
            </button>
            <button
              className="blue-btn"
              onClick={() => handleSubmitHerbTranslation()}
              style={{ width: "185px" }}
            >
              {t("Buttons.Save")}
            </button>
          </div>
        </>
      )}

      {isOpen && currentTranslation[0] === translation[0] && (
        <DeleteHerbDialog
          herb={currentTranslation[0]}
          dialog={isOpen}
          setDialog={setIsOpen}
          handleDelete={handleDeleteHerbTranslation}
          isTranslation
        />
      )}
    </>
  );
};

export default HerbFields;
