import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Navbar from "../Navbar";
import BackdropComponent from "../reusableComponents/BackdropLoading";
import Alert from "../alerts/Alert";
import EditCompany from "../../pages/Company/Edit";
import NewProductOnboarding from "./NewProductOnboarding";
import NewTreatment from "../treatments/NewTreatment";
import NewInsuranceOnboarding from "./NewInsuranceOnboarding";
import NewDiagnosisOnboarding from "./NewDiagnosisOnboarding";
import DoctorsList from "../doctors/DoctorsList";
import EditProductOnboarding from "./EditProductOnboarding";
import NewAppointmentOnboarding from "./NewAppointmentOnboarding";
import SuccessView from "./SuccessView";
import fetchApi from "../../utils/fetchApi";
import NextSteps from "./NextSteps";

const Onboarding = ({
  userId,
  onboarding,
  setOnboarding,
  setIsPopup,
  handleLogout,
}) => {
  // translations
  const { t, i18n } = useTranslation();
  let currentLanguage = i18n.language;
  // styles
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  // utils
  const [activeStep, setActiveStep] = useState(0);
  const [completeStep, setCompleteStep] = useState(null);
  const [isDefaultValues, setIsDefaultValues] = useState(false);
  const [isProductCreated, setIsProductCreated] = useState(null);
  const [isTreatmentCreated, setIsTreatmentCreated] = useState(false);
  const [isPatientCreated, setIsPatientCreated] = useState(null);
  const [isPatients, setIsPatients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  // data
  const steps = useMemo(
    () => [
      `${t("Stepper.step")} 1: ${t("Stepper.step0")}`,
      `${t("Stepper.step")} 2: ${t("Stepper.step1")}`,
      `${t("Stepper.step")} 3: ${t("Stepper.step2")}`,
      `${t("Stepper.step")} 4: ${t("Stepper.step3")}`,
      `${t("Stepper.step")} 5: ${t("Stepper.step4")}`,
      `${t("Stepper.step")} 6: ${t("Stepper.step5")}`,
      "",
    ],
    [currentLanguage]
  );

  useEffect(() => {
    const initializeOnboarding = async () => {
      setIsLoading(true);
      try {
        if (onboarding) {
          const is_step_complete = onboarding.find(
            (item) => "is_step_complete" in item
          )?.is_step_complete;
          const is_default_values = onboarding.find(
            (item) => "is_default_values" in item
          )?.is_default_values;
          setActiveStep(is_step_complete ? is_step_complete + 1 : 0);
          setCompleteStep(is_step_complete ? is_step_complete : null);
          setIsDefaultValues(is_default_values ? is_default_values : false);
        }
      } catch (error) {
        console.error("Error initializing onboarding:", error);
      } finally {
        setIsLoading(false);
      }
    };

    initializeOnboarding();
  }, []);

  const handleActiveStepId = () => {
    switch (activeStep) {
      case 0:
        return "skip_update_company";
      case 1:
        return "skip_create_insurance";
      case 2:
        return isProductCreated ? "skip_edit_product" : "skip_create_product";
      case 3:
        return "skip_create_diagnosis";
      case 4:
        return isTreatmentCreated
          ? "skip_assign_treatment"
          : "skip_create_treatment";
      case 5:
        return isPatientCreated
          ? "skip_create_appointment"
          : "skip_create_patient";
      default:
        return "skip_next_steps";
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStepComplete = async () => {
    try {
      const updatedOnboarding = onboarding.some(
        (item) => "is_step_complete" in item
      )
        ? onboarding.map((item) =>
            "is_step_complete" in item
              ? { ...item, is_step_complete: activeStep }
              : item
          )
        : [...onboarding, { is_step_complete: activeStep }];

      const body = {
        onboarding: JSON.stringify(updatedOnboarding),
      };
      const url = `${process.env.REACT_APP_API_URL_V2}/users/update/${userId}`;

      const { ok } = await fetchApi(url, "PUT", JSON.stringify(body));

      if (ok) {
        setCompleteStep(activeStep);
        setOnboarding(updatedOnboarding);
        if (activeStep !== 4) {
          setTimeout(() => {
            setActiveStep(activeStep + 1);
          }, 2000);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFinish = async () => {
    try {
      setIsLoading(true);

      const body = {
        onboarding: JSON.stringify([{ is_first_login: false }]),
      };
      const url = `${process.env.REACT_APP_API_URL_V2}/users/update/${userId}`;

      const { ok } = await fetchApi(url, "PUT", JSON.stringify(body));

      if (!ok) {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      } else {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
        setTimeout(() => {
          setIsPopup({
            open: false,
          });
        }, 2000);
      }
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100% ",
        backgroundColor: "#f4f5fa",
        px: 6,
        pb: 6,
      }}
    >
      <Box
        sx={{
          mb: 2,
        }}
      >
        <Navbar deleteToken={handleLogout} isOnboarding={true} />
      </Box>
      <Stepper activeStep={activeStep}>
        {steps
          .filter((step) => step !== "")
          .map((step, i) => {
            return (
              <Step key={i}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <StepLabel
                    StepIconProps={{
                      style: {
                        color:
                          i === activeStep
                            ? "var(--primary-color)"
                            : i < activeStep
                            ? "var(--secondary-color)"
                            : undefined,
                      },
                    }}
                  >
                    <h6 className="mb-0">{step}</h6>
                  </StepLabel>
                </Box>
              </Step>
            );
          })}
      </Stepper>
      <Box sx={{ display: "flex", flexDirection: "row", p: 2 }}>
        <button
          className={activeStep === 0 ? "blue-btn-disabled" : "btn-cancel"}
          disabled={activeStep === 0}
          onClick={handleBack}
        >
          {t("Buttons.Back")}
        </button>
        <Box sx={{ flex: "1 1 auto" }} />
        <button
          className={
            activeStep !== 0 && activeStep !== 6 && activeStep > completeStep
              ? "blue-btn-disabled"
              : "blue-btn"
          }
          disabled={
            activeStep !== 0 && activeStep !== 6 && activeStep > completeStep
          }
          onClick={activeStep === steps.length - 1 ? handleFinish : handleNext}
        >
          {activeStep === steps.length - 1
            ? t("Buttons.Finish")
            : t("Buttons.Next")}
        </button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "75%",
        }}
      >
        {activeStep === 0 && (
          <>
            <h4 className="text-center">{t("Stepper.step0_title")}</h4>
            <br />
            <h6 className="text-center">{t("Stepper.step0_subtitle")}</h6>
            <Box
              sx={{
                maxHeight: "100%",
                overflowY: "auto",
                width: lg ? "100%" : "50%",
                backgroundColor: "#ffffff",
                border: "1px solid #f1f4fa",
                borderRadius: "16px",
                mt: "20px",
              }}
            >
              <EditCompany
                isOnboarding={true}
                handleStepComplete={handleStepComplete}
              />
            </Box>
          </>
        )}
        {activeStep === 1 && (
          <>
            <h4 className="text-center">{t("Stepper.step1_title")}</h4>
            <br />
            <h6 className="text-center" style={{ width: lg ? "100%" : "50%" }}>
              {t("Stepper.step1_subtitle")}
            </h6>
            <h6 className="text-center">{t("Stepper.step1_subtitle0")}</h6>
            {completeStep < 1 ? (
              <NewInsuranceOnboarding
                isAlert={isAlert}
                setIsAlert={setIsAlert}
                handleStepComplete={handleStepComplete}
              />
            ) : (
              <SuccessView text={t("Insurances.Insurance added!")} />
            )}
          </>
        )}
        {activeStep === 2 && (
          <>
            <h4 className="text-center">{t("Stepper.step2_title")}</h4>
            <br />
            <h6 className="text-center">{t("Stepper.step2_subtitle")}</h6>
            {completeStep < 2 ? (
              <>
                {!isProductCreated ? (
                  <>
                    <h6 className="text-center">
                      {t("Stepper.step2_subtitle0")}
                    </h6>
                    <NewProductOnboarding
                      isAlert={isAlert}
                      setIsAlert={setIsAlert}
                      setIsProductCreated={setIsProductCreated}
                    />
                  </>
                ) : (
                  <>
                    <h6 className="text-center">
                      {t("Stepper.step2_subtitle1")}
                    </h6>
                    <Box
                      sx={{
                        maxHeight: "100%",
                        overflowY: "auto",
                        backgroundColor: "#ffffff",
                        border: "1px solid #f1f4fa",
                        borderRadius: "16px",
                        mt: "20px",
                      }}
                    >
                      <EditProductOnboarding
                        productID={isProductCreated}
                        setIsAlert={setIsAlert}
                        handleStepComplete={handleStepComplete}
                      />
                    </Box>
                  </>
                )}
              </>
            ) : (
              <SuccessView text={t("NewProduct.5")} />
            )}
          </>
        )}
        {activeStep === 3 && (
          <>
            <h4 className="text-center">{t("Stepper.step3_title")}</h4>
            <br />
            <h6 className="text-center" style={{ width: lg ? "100%" : "50%" }}>
              {t("Stepper.step3_subtitle")}
            </h6>
            {completeStep < 3 ? (
              <NewDiagnosisOnboarding
                isAlert={isAlert}
                setIsAlert={setIsAlert}
                handleStepComplete={handleStepComplete}
              />
            ) : (
              <SuccessView text={t("Diagnosis.Diagnosis added!")} />
            )}
          </>
        )}
        {activeStep === 4 && (
          <>
            <h4 className="text-center">{t("Stepper.step4_title")}</h4>
            <br />
            {completeStep < 4 ? (
              <>
                {!isTreatmentCreated ? (
                  <>
                    <h6 className="text-center">
                      {t("Stepper.step4_subtitle")}
                    </h6>
                    <h6
                      className="text-center"
                      style={{
                        width: lg ? "100%" : "50%",
                      }}
                    >
                      {t("Stepper.step4_subtitle0")}
                    </h6>
                    <Box
                      sx={{
                        maxHeight: "100%",
                        overflowY: "auto",
                        width: lg ? "100%" : "50%",
                        backgroundColor: "#ffffff",
                        border: "1px solid #f1f4fa",
                        borderRadius: "16px",
                        mt: "20px",
                      }}
                    >
                      <NewTreatment
                        isOnboarding={true}
                        setIsTreatmentCreated={setIsTreatmentCreated}
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <h6 className="text-center">
                      {t("Stepper.step4_subtitle1")}
                    </h6>
                    <h6
                      className="text-center"
                      style={{
                        width: lg ? "100%" : "50%",
                      }}
                    >
                      {t("Stepper.step4_subtitle2")}
                    </h6>
                    <Box
                      sx={{
                        maxHeight: "100%",
                        overflowY: "auto",
                        width: lg ? "100%" : "70%",
                        backgroundColor: "#ffffff",
                        border: "1px solid #f1f4fa",
                        borderRadius: "16px",
                        mt: "20px",
                        pt: "20px",
                      }}
                    >
                      <DoctorsList
                        isOnboarding={true}
                        handleStepComplete={handleStepComplete}
                      />
                    </Box>
                  </>
                )}
              </>
            ) : (
              <SuccessView text={t("NewTreatment.Treatment created!")} />
            )}
          </>
        )}
        {activeStep === 5 && (
          <>
            {completeStep < 5 ? (
              <>
                <h4 className="text-center">
                  {t(
                    `Stepper.${
                      !isPatientCreated ? "step5_title" : "step5_title0"
                    }`
                  )}
                </h4>
                <br />
                {isPatients.length !== 0 ? (
                  <>
                    {isPatientCreated && (
                      <h6 className="text-center">
                        {t("Stepper.step5_subtitle0")}
                      </h6>
                    )}
                  </>
                ) : (
                  <h6 className="text-center">{t("Stepper.step5_subtitle")}</h6>
                )}
                <Box
                  sx={{
                    maxHeight: "100%",
                    overflowY: "auto",
                    width: lg ? "100%" : "50%",
                    backgroundColor: "#ffffff",
                    border: "1px solid #f1f4fa",
                    borderRadius: "16px",
                    mt: "20px",
                  }}
                >
                  <NewAppointmentOnboarding
                    isPatientCreated={isPatientCreated}
                    setIsPatientCreated={setIsPatientCreated}
                    setIsPatients={setIsPatients}
                    handleStepComplete={handleStepComplete}
                  />
                </Box>
              </>
            ) : (
              <SuccessView text={t("Stepper.step5_title1")} />
            )}
          </>
        )}
        {activeStep === 6 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
            }}
          >
            <h4 className="text-center" style={{ width: lg ? "100%" : "50%" }}>
              {t("Stepper.step6_title")}
            </h4>
            <h6 className="text-center" style={{ width: lg ? "100%" : "50%" }}>
              {t("Stepper.step6_subtitle")}
            </h6>
            <NextSteps />
          </Box>
        )}

        {(activeStep === 0 || isDefaultValues) &&
          activeStep !== 5 &&
          activeStep !== 6 && (
            <button
              className="blue-btn"
              id={handleActiveStepId()}
              style={{ marginTop: "30px" }}
              // disabled={activeStep !== 0 && activeStep > completeStep}
              onClick={
                activeStep === steps.length - 1 ? handleFinish : handleNext
              }
            >
              {activeStep === steps.length - 1
                ? t("Buttons.Finish")
                : t("Buttons.Skip")}
            </button>
          )}
      </Box>

      <BackdropComponent isOpen={isLoading} />

      {isAlert.open && (
        <Alert
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
    </Box>
  );
};

export default Onboarding;
