import { Loader } from "./styles"
import CircularProgress from "@material-ui/core/CircularProgress";

const Component = () => {
  return (
    <Loader>
      <CircularProgress size={20} />
    </Loader>
  )
}

export default Component