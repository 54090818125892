const getParamsFromUrl = (url) => {
  const params = {};
  const urlParts = url.split('?');

  if (urlParts.length > 1) {
    const queryString = urlParts[1];
    const pairs = queryString.split('&');

    pairs.forEach(pair => {
      const [key, value] = pair.split('=');
      params[key] = value;
    });
  }

  return params;
}

const functions = {
  getParamsFromUrl
}

export default functions