import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Card from "react-bootstrap/Card";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useMediaQuery } from "@mui/material";
import GetUserToken from "../../../utils/GetUserToken";

export const PatientAnswers = (infoRoute) => {
  // translations
  const { t } = useTranslation();
  // utils
  const [loading, setLoading] = useState(true);
  const isMobile = useMediaQuery("(max-width:600px)");
  // data
  const [questions, setQuestions] = useState([]);
  const [answersArray, setAnswersArray] = useState([]);

  const initData = async () => {
    const url =
      process.env.REACT_APP_API_URL + "/quiz/show/" + infoRoute.match.params.id;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: GetUserToken(),
      },
      redirect: "follow",
    };

    const res = await fetch(url, requestOptions);

    const json = await res.json();

    let answers = json.answer_patient;
    setAnswersArray(JSON.parse(answers));

    let questionsArray = JSON.parse(json.questions);
    setQuestions(questionsArray);
    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <>
      <div className="container-quiz">
        <div className="frame-quiz">
          <h4 className="main-title">{t("PatientAnswers.Your answers")}</h4>

          <div
            className="row"
            style={{
              background: "#FFFFFF",
              borderRadius: "10px",
              paddingLeft: "25px",
              paddingRight: "25px",
              paddingTop: "20px",
              paddingBottom: "10px",
              width: !isMobile && "95%",
            }}
          >
            {loading ? (
              <>
                <div className="d-flex justify-content-center">
                  <CircularProgress color="secondary" />
                </div>
              </>
            ) : (
              <>
                {questions.map((item, i) => (
                  <>
                    <div
                      className="col-sm-12 col-lg-6"
                      style={{ background: "#f4f5fa", paddingTop: "10px" }}
                    >
                      <Card key={i} style={{ width: "100%" }}>
                        <Card.Header
                          className="reminders-subtitle"
                          style={{ background: "#f4f5fa" }}
                        >
                          {" "}
                          {i + 1}. {item.question}{" "}
                        </Card.Header>
                        <Card.Body style={{ background: "#f4f5fa" }}>
                          <Card.Text>
                            <p className="key-text-box">{answersArray[i]}</p>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                      <br />
                    </div>
                  </>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientAnswers;
