import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Error = () => {
  // translations
  const { t } = useTranslation();

  return (
    <div className="frame">
      <h2 className="title-neue">403 Forbidden</h2>
      <h3 className="subtitle-praxis">{t("Forbidden.Access denied")}</h3>
      <h3 className="subtitle-praxis">
        {t("Forbidden.You do not have permission to access this page")}
      </h3>
      <h2 className="new-appointment-back-link">
        <Link
          to="/"
          className="new-appointment-back-link"
          style={{ textDecoration: "none" }}
        >
          &#60; {t("Buttons.Back")}
        </Link>
      </h2>
    </div>
  );
};

export default Error;
