import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import services from "../../services";

// Define initial state
const initialState = {
  one: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  all: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  create: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  update: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
};

// Async action to get one diagnosis
const getOneDiagnosis = createAsyncThunk("diagnosis/getOne", async (payload) => {
  console.log("get one - diagnosis - action", payload);
  const { response, error } = await services.diagnosis.getOne(payload);
  if (error) {
    throw new Error(error);
  }
  return response;
});

// Async action to get all companies
const getAllDiagnosis = createAsyncThunk("diagnosis/getAll", async (payload) => {
  console.log("get all - diagnosis - action", payload);
  const { response, error } = await services.diagnosis.getAll(payload);
  if (error) {
    throw new Error(error);
  }
  return response.diagnosis;
});

// Async action to create one diagnosis
const createOneDiagnosis = createAsyncThunk(
  "diagnosis/createOne",
  async ({ payload }) => {
    console.log("create one - diagnosis - action", payload);
    const { response, error } = await services.diagnosis.createOne(payload);
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Async action to update a diagnosis
const updateDiagnosis = createAsyncThunk("diagnosis/update", async (payload) => {
  console.log("update - diagnosis - action", payload);
  const { response, error } = await services.diagnosis.update(payload);
  if (error) {
    throw new Error(error);
  }
  return response;
});

// Create a diagnosis slice
const diagnosisSlice = createSlice({
  name: "diagnosis",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOneDiagnosis.pending, (state, action) => {
        state.one.data = null;
        state.one.loading = true;
        state.one.success = false;
        state.one.error = false;
      })
      .addCase(getOneDiagnosis.fulfilled, (state, action) => {
        state.one.data = action.payload;
        state.one.loading = false;
        state.one.success = true;
        state.one.error = false;
      })
      .addCase(getOneDiagnosis.rejected, (state, action) => {
        state.one.data = null;
        state.one.loading = false;
        state.one.success = false;
        state.one.error = action.error.message;
      })
      .addCase(getAllDiagnosis.pending, (state) => {
        state.all.data = null;
        state.all.loading = true;
        state.all.success = false;
        state.all.error = false;
      })
      .addCase(getAllDiagnosis.fulfilled, (state, action) => {
        state.all.data = action.payload;
        state.all.loading = false;
        state.all.success = true;
        state.all.error = false;
      })
      .addCase(getAllDiagnosis.rejected, (state, action) => {
        state.all.data = null;
        state.all.loading = false;
        state.all.success = false;
        state.all.error = action.error.message;
      })
      .addCase(updateDiagnosis.pending, (state) => {
        state.update.data = null;
        state.update.loading = true;
        state.update.success = false;
        state.update.error = false;
      })
      .addCase(updateDiagnosis.fulfilled, (state, action) => {
        state.update.data = action.payload;
        state.update.loading = false;
        state.update.success = true;
        state.update.error = false;
        state.one.data = action.payload;
      })
      .addCase(updateDiagnosis.rejected, (state, action) => {
        state.update.data = null;
        state.update.loading = false;
        state.update.success = false;
        state.update.error = action.error.message;
      });
  },
});

// Export the async actions and the reducer
export {
  getOneDiagnosis,
  getAllDiagnosis,
  createOneDiagnosis,
  updateDiagnosis,
};

export default diagnosisSlice.reducer;