import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import FirstStep from "./steps/FirstStep";
import SecondStep from "./steps/SecondStepExistingPatient";
import ThirdStep from "./steps/ThirdStep";
import FourthStep from "./steps/FourthStepExistingPatient";
import AlertComponent from "../../alerts/Alert";
import { getBookableTreatments } from "../../../redux/slices/treatment";
import {
  getAllDoctors,
  getDoctorsByTreatment,
} from "../../../redux/slices/doctor";
import { getOnePatientByUid } from "../../../redux/slices/patient";
import { getTime, disableWeekends } from "../../../helpers/time";
import fetchApi from "../../../utils/fetchApi";
import "../../../assets/stylesheets/multistepCitation.css";

const ExistingPatientBooking = ({
  navbarRef,
  treatments,
  therapists,
  therapistsbytreatment,
  patient,
  isLoadingDoctors,
  isLoadingTreatments,
}) => {
  // redux
  const dispatch = useDispatch();
  // utils
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const [currentTime, setCurrentTime] = useState("");
  const [days, setDays] = useState({
    dayBefore: [],
    dayCurrent: [],
    dayAfter: [],
  });
  // data
  const [userId, setUserId] = useState("");
  const [treatment, setTreatment] = useState("");
  const [doctor, setDoctor] = useState("");
  const [dates, setDates] = useState({ date: "", dateSelector: "" });
  const [time, setTime] = useState("");
  const [clinicTreatments, setClinicTreatments] = useState([]);
  const [clinicDoctors, setClinicDoctors] = useState([]);
  const [availableDoctors, setAvailableDoctors] = useState([]);
  const [citationSummary, setCitationSummary] = useState([]);
  const [patientData, setPatientData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    address: "",
    birth: "",
    insurance: "",
    city: "",
    zip: "",
    street: "",
    state: "",
    number: "",
  });

  const dispatchAction = (userID) => {
    dispatch(getBookableTreatments());
    dispatch(getAllDoctors());
    dispatch(getOnePatientByUid({ userID }));
  };

  useEffect(() => {
    const userID = atob(
      window.location.href.split("existingpatientcitation/", 2)[1]
    );
    setUserId(userID);
    dispatchAction(userID);
    setCurrentTime(getTime());
  }, []);

  useEffect(() => {
    if (treatments) {
      let activeTreatments = treatments.filter((item) => !item.archived);
      setClinicTreatments(
        activeTreatments.sort((a, b) => a.name.localeCompare(b.name))
      );
    }
  }, [treatments]);

  useEffect(() => {
    if (therapists) {
      let activeDoctors = therapists.filter((item) => !item.archived);
      setClinicDoctors(activeDoctors);
    }
  }, [therapists]);

  useEffect(() => {
    if (therapistsbytreatment) {
      let activeDoctors = therapistsbytreatment.filter(
        (item) => !item.archived
      );
      setAvailableDoctors(activeDoctors);
    }
  }, [therapistsbytreatment]);

  useEffect(() => {
    if (patient) {
      setPatientData({
        firstname: patient.firstname,
        lastname: patient.lastname,
        email: patient.email,
        phone: patient.phone,
        address: patient.address,
        birth: patient.birth,
        insurance: patient.insurance,
        city: patient.city,
        zip: patient.zip,
        street: patient.street,
        state: patient.state,
        number: patient.number,
      });
    }
  }, [patient]);

  // MultiStep Functions
  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };
  // end MultiStep Functions

  const resetValues = () => {
    setDays((prevState) => ({
      ...prevState,
      dayCurrent: [],
    }));
    setDates((prevState) => ({
      ...prevState,
      date: "",
      dateSelector: "",
    }));
    setTime("");
  };

  const handleTreatmentChange = (event) => {
    setTreatment(event.target.value);
    setDoctor("");
    resetValues();
    dispatch(
      getDoctorsByTreatment({ treatment: event.target.value.toString() })
    );
  };

  const handleDoctorChange = (event) => {
    if (!doctor) {
      setDoctor(event.target.value);
    } else {
      setDoctor(event.target.value);
      resetValues();
    }
  };

  const handleDateChange = (event) => {
    const completeDate = event.toISOString().split("T")[0];
    setDates((prevState) => ({
      ...prevState,
      dateSelector: event,
      date: completeDate,
    }));
    setTime(""); // reset time value
    onDateSelect(completeDate, doctor);
  };

  const onDateSelect = async (value, doctor) => {
    setIsLoading(true);
    try {
      const url = `
        ${process.env.REACT_APP_API_URL}/citations/schedule_available_slots`;

      const body = JSON.stringify({
        date: value,
        doctor,
        treatment,
      });

      const { data } = await fetchApi(url, "POST", body);

      setDays((prevState) => ({
        ...prevState,
        dayCurrent: data.data,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTimeChange = (time) => {
    setTime(time);
  };

  const handlePatientDataChange = (event) => {
    setPatientData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const url = process.env.REACT_APP_API_URL + "/citations/usercreate";

      const {
        firstname,
        lastname,
        email,
        phone,
        address,
        birth,
        insurance,
        city,
        zip,
        street,
        number,
      } = patientData;

      const body = {
        firstname,
        lastname,
        date: dates.date,
        time,
        treatment,
        doctor,
        email,
        phone,
        address,
        birth,
        insurance,
        city,
        zip,
        street,
        number,
        user_id: userId,
      };

      const { data, ok } = await fetchApi(url, "POST", JSON.stringify(body));

      if (ok) {
        setCitationSummary(data.citation);
        setStep(4);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const scrollToNavbar = () => {
    if (navbarRef && navbarRef.current) {
      navbarRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      {step === 1 && (
        <FirstStep
          treatment={treatment}
          doctor={doctor}
          dateSelector={dates.dateSelector}
          dayCurrent={days.dayCurrent}
          currentTime={currentTime}
          timeSelected={time}
          disableWeekends={disableWeekends}
          handleTreatmentChange={handleTreatmentChange}
          handleDoctorChange={handleDoctorChange}
          handleDateChange={handleDateChange}
          handleTimeChange={handleTimeChange}
          clinicTreatments={clinicTreatments}
          availableDoctors={availableDoctors}
          loading={isLoading}
          loadingTreatments={isLoadingTreatments}
          loadingDoctors={isLoadingDoctors}
          nextStep={nextStep}
          scrollToNavbar={scrollToNavbar}
        />
      )}
      {step === 2 && (
        <SecondStep
          patientData={patientData}
          onChange={handlePatientDataChange}
          prevStep={prevStep}
          nextStep={nextStep}
          scrollToNavbar={scrollToNavbar}
        />
      )}
      {step === 3 && (
        <ThirdStep
          date={dates.date}
          time={time}
          doctors={clinicDoctors}
          doctor={doctor}
          onSubmit={onSubmit}
          prevStep={prevStep}
          loading={isLoading}
          // getValueCheckbox={getValueCheckbox}
          handleClick={scrollToNavbar}
        />
      )}
      {step === 4 && (
        <FourthStep citation={citationSummary} handleClick={scrollToNavbar} />
      )}

      {isAlert.open && (
        <AlertComponent
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
    </>
  );
};

const stateRedux = ({ treatmentStore, doctorStore, patientStore }) => {
  const { data: bookableTreatments, loading: isLoadingTreatments } =
    treatmentStore.bookable;
  const { data: therapists } = doctorStore.all;
  const { data: therapistsbytreatment, loading: isLoadingDoctors } =
    doctorStore.allbytreatment;
  const { data: patient } = patientStore.oneByUid;

  return {
    treatments: bookableTreatments?.treatments,
    therapists,
    therapistsbytreatment: therapistsbytreatment?.clinicDoctors,
    patient: patient?.patientInfo,
    isLoadingTreatments,
    isLoadingDoctors,
  };
};

export default connect(stateRedux)(ExistingPatientBooking);
