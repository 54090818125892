import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { RiQuestionAnswerLine } from "react-icons/ri";

const SupportButton = () => {
  return (
    <Link
      to={{
        pathname:
          "https://digitaleszeugs.atlassian.net/servicedesk/customer/portal/7",
      }}
      target="_blank"
    >
      <Button className="support-button" id="support_button">
        <RiQuestionAnswerLine size={30} />
      </Button>
    </Link>
  );
};

export default SupportButton;
