import React, { useMemo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "./AppointmentsTable";
import { ReactComponent as Pencil } from "../../assets/icons/pencil.svg";
import GetUserToken from "../../utils/GetUserToken";
import Alert from "../alerts/Alert";
import SaveAltIcon from "@material-ui/icons/SaveAlt";

function PatientAppointments({ id }) {
  // auth
  const userToken = GetUserToken();
  // translations
  const { t } = useTranslation();
  let currentLanguage = localStorage.getItem("i18nextLng");
  // utils
  const [loading, setLoading] = useState(false);
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  // data
  const [invoices, setInvoices] = useState([]);
  // table
  const columns = useMemo(
    () => [
      {
        // first group
        Header: " ",
        // First group columns
        columns: [
          {
            Header: "ID",
            accessor: "id",
          },
          {
            Header: <>{t("PatientInvoices.Last Name")}</>,
            accessor: "lastname",
            Cell: ({ row }) => <>{JSON.parse(row.original.patient).lastname}</>,
          },
          {
            Header: <>{t("PatientInvoices.First Name")}</>,
            accessor: "firstname",
            Cell: ({ row }) => (
              <>{JSON.parse(row.original.patient).firstname}</>
            ),
          },
          {
            Header: <>{t("PatientInvoices.Created at")}</>,
            accessor: "date",
          },
          {
            Header: <>{t("PatientInvoices.Due to")}</>,
            accessor: "due_date",
            Cell: ({ row }) => (
              <>
                {row.original.due_date != null ? row.original.due_date : <></>}
              </>
            ),
          },
          {
            Header: <>{t("PatientInvoices.Total")}</>,
            accessor: "total",
            Cell: ({ row }) => (
              <>
                {row.original.total != null ? (
                  parseFloat(row.original.total).toFixed(2).replace(".", ",")
                ) : (
                  <>0,00</>
                )}
              </>
            ),
          },
          {
            Header: <>{t("PatientInvoices.Last status update")}</>,
            accessor: "updated_at",
            Cell: ({ row }) => <>{row.original.updated_at.split("T")[0]}</>,
          },
          {
            Header: <>{t("PatientInvoices.Status")}</>,
            accessor: "status",
            Cell: ({ row }) => (
              <>
                {row.original.status == "warning" ? (
                  <>
                    <span className="invoices-list-warning-badge">
                      &nbsp;&nbsp;<>{t("PatientInvoices.Overdue")}</>
                      &nbsp;&nbsp;
                    </span>
                  </>
                ) : (
                  <>
                    {row.original.status == "cancel" ? (
                      <>
                        <span className="invoices-list-cancel-badge">
                          &nbsp;&nbsp;<>{t("PatientInvoices.Canceled")}</>&nbsp;
                          {row.original.paid_at.split("T")[0]}&nbsp;
                        </span>
                      </>
                    ) : (
                      <>
                        {row.original.status == "paid" ? (
                          <>
                            <span className="invoices-list-invoiced-badge">
                              &nbsp;&nbsp;<>{t("PatientInvoices.Paid at")}</>
                              &nbsp;
                              {row.original.paid_at.split("T")[0]}&nbsp;&nbsp;
                            </span>
                          </>
                        ) : (
                          <>
                            {row.original.status == "partialpaid" ? (
                              <>
                                <span className="invoices-list-partialpaid-badge">
                                  &nbsp;&nbsp;
                                  <>{t("PatientInvoices.Partial paid")}</>
                                  &nbsp;&nbsp;
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="invoices-list-unpaid-badge">
                                  &nbsp;&nbsp;<>{t("PatientInvoices.Unpaid")}</>
                                  &nbsp;&nbsp;
                                </span>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}{" "}
              </>
            ),
          },
          {
            Header: <>{t("PatientInvoices.Download")}</>,
            accessor: " ",
            Cell: ({ row }) => (
              <span
                id="patient_downloadInvoice"
                style={{ cursor: "pointer" }}
                onClick={() => downloadInvoice(row.original.id)}
              >
                <SaveAltIcon
                  className="patientInvoices-download-icon"
                  fontSize="small"
                />
              </span>
            ),
          },
        ],
      },
    ],
    [currentLanguage]
  );

  useEffect(() => {
    getPatientInvoices();
  }, []);

  const getPatientInvoices = async () => {
    try {
      setLoading(true);
      var requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        redirect: "follow",
      };

      const res = await fetch(
        `${process.env.REACT_APP_API_URL_V2}/invoices/get_invoices_for_patient/${id}`,
        requestOptions
      );

      if (res.ok) {
        const json = await res.json();
        setInvoices(json);
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const downloadInvoice = async (event) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    const url =
      process.env.REACT_APP_API_URL_V2 + "/invoices/generate/" + event;

    if (!event) {
      setIsAlert({
        open: true,
        severity: "warning",
        message: t("Messages.Error, please refresh and try again"),
      });
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Convert the response to a blob
      const blob = await response.blob();

      // Create a temporary URL for the blob
      const downloadUrl = window.URL.createObjectURL(blob);

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = downloadUrl;

      // Set the file name, adjust this depending on your backend response
      link.download = `RE_TCM_${event}.pdf`;
      // Programmatically click the link to trigger the download
      link.click();

      // Clean up the temporary URL
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Error, please refresh and try again"),
      });
    } finally {
      setLoading(false);
    }
  };

  const not = (
    <div className="d-flex justify-content-center">
      <CircularProgress color="secondary" />
    </div>
  );

  return (
    <>
      <div className="mt-3 pb-5">
        <h2 className="subtitles-patients">{t("PatientInvoices.Invoices")}</h2>
        {loading ? (
          not
        ) : (
          <>
            <Table columns={columns} data={invoices} />
          </>
        )}
      </div>
      {isAlert.open && (
        <Alert
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
    </>
  );
}

export default PatientAppointments;
