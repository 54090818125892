import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";

const useStyles = makeStyles(() => ({
  mainColor: {
    color: "var(--primary-color)",
  },
}));

const Language = () => {
  // translations
  const { i18n } = useTranslation();
  // styles
  const classes = useStyles();

  const [langAnchorEl, setLangAnchorEl] = React.useState(null);
  const isLangOpen = Boolean(langAnchorEl);
  const langId = "primary-lang-menu";

  const handleLangOpen = (event) => {
    setLangAnchorEl(event.currentTarget);
  };

  const handleLangClose = () => {
    setLangAnchorEl(null);
  };

  const handleClick = (lang) => {
    i18n.changeLanguage(lang);
    handleLangClose();
  };

  return (
    <>
      <IconButton
        className={classes.mainColor}
        size="medium"
        // edge="end"
        aria-label="account of current user"
        aria-controls={langId}
        aria-haspopup="true"
        onClick={handleLangOpen}
        color="inherit"
        id="language_button_login"
      >
        <LanguageIcon />
      </IconButton>

      <Menu
        //visible={false}
        anchorEl={langAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={langId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isLangOpen}
        onClose={handleLangClose}
      >
        <MenuItem onClick={() => handleClick("en")}>English</MenuItem>
        <MenuItem onClick={() => handleClick("es")}>Español</MenuItem>
        <MenuItem onClick={() => handleClick("de")}>Deutsch</MenuItem>
      </Menu>
    </>
  );
};

export default Language;
