import React, { useMemo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tab, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";
import Table from "./HerbsTable";
import { CircularProgress, Menu, MenuItem } from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";
import { TbPlant2 } from "react-icons/tb";
import DeleteHerbDialog from "./DeleteHerbDialog";
import fetchApi from "../../utils/fetchApi";
import ButtonGroupComponent from "../reusableComponents/ButtonGroup";
import AlertComponent from "../alerts/Alert";
import MenuIcon from "../../components/reusableComponents/MenuIcon";

const Herbs = (infoRoute) => {
  // translations
  const { t } = useTranslation();
  let currentLanguage = localStorage.getItem("i18nextLng");
  // route
  const { history } = infoRoute;
  // redux
  const state = useSelector((state) => state.user);
  // utils
  const [loading, setLoading] = useState(true);
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  // data
  const [data, setData] = useState([]);
  const [herb, setHerb] = useState("");
  // delete
  const [dialog, setDialog] = useState(false);

  // menu
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event, item) => {
    setAnchorEl(event.currentTarget);
    setHerb(item);
  };

  const fetchData = async () => {
    try {
      const url = `
        ${process.env.REACT_APP_HERBS_API}/herbs`;
      const { data } = await fetchApi(url, "GET");
      setData(data);
      console.log("data", data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePreDelete = (item) => {
    setHerb(item);
    setDialog(true);
  };

  const handleDelete = async (herb) => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_HERBS_API}/herbs/${herb}`;
      const response = await fetchApi(url, "DELETE");
      if (response.ok) {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
        fetchData();
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setDialog(false);
      setAnchorEl(null);
      setLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: " ",
        columns: [
          {
            Header: " ",
            accessor: "image",
            Cell: ({ row }) => (
              <>
                {row.original.image !== null ? (
                  <img
                    src={row.original.image}
                    alt={row.original.english_name}
                    style={{ width: "45px", height: "45px" }}
                  />
                ) : (
                  <TbPlant2
                    size={40}
                    style={{ strokeWidth: "1", color: "var(--primary-color)" }}
                  />
                )}
              </>
            ),
          },
          {
            Header: "Pinyin",
            Cell: ({ row }) => (
              <Link
                target="_blank"
                to={{
                  pathname: `/herb/view/${row.original.id}`,
                  state: { data: row },
                }}
                style={{ textDecoration: "none", color: "#564c60" }}
              >
                {row.original.pinyin_name}
              </Link>
            ),
            accessor: "pinyin_name",
          },
          {
            Header: <>{t("HerbsList.0")}</>,
            Cell: ({ row }) => (
              <Link
                target="_blank"
                to={{
                  pathname: `/herb/view/${row.original.id}`,
                  state: { data: row },
                }}
                style={{ textDecoration: "none", color: "#564c60" }}
              >
                {row.original.latin_name}
              </Link>
            ),
            accessor: "latin_name",
          },
          {
            Header: <>{t("HerbsList.1")}</>,
            Cell: ({ row }) => (
              <Link
                target="_blank"
                to={{
                  pathname: `/herb/view/${row.original.id}`,
                  state: { data: row },
                }}
                style={{ textDecoration: "none", color: "#564c60" }}
              >
                {row.original.chinese_name}
              </Link>
            ),
            accessor: "chinese_name",
          },
          {
            Header: <>{t("HerbsList.13")}</>,
            Cell: ({ row }) => (
              <Link
                target="_blank"
                to={{
                  pathname: `/herb/view/${row.original.id}`,
                  state: { data: row },
                }}
                style={{ textDecoration: "none", color: "#564c60" }}
              >
                {row.original.private === false
                  ? t("Herbs.No")
                  : t("Herbs.Yes")}
              </Link>
            ),
            accessor: "private",
          },
          {
            Header: (
              <>
                <LanguageIcon style={{ color: "var(--primary-color)" }} />
              </>
            ),
            Cell: ({ row }) => (
              <Link
                target="_blank"
                to={{
                  pathname: `/herb/view/${row.original.id}`,
                  state: { data: row },
                }}
                style={{ textDecoration: "none", color: "#564c60" }}
              >
                {row.original.herbs_translations.map((item, i) => {
                  return (
                    <div key={i}>
                      <span>{item.languages_id === 1 ? <>🇬🇧</> : <></>}</span>
                      <span>{item.languages_id === 2 ? <>🇪🇸</> : <></>}</span>
                      <span>{item.languages_id === 3 ? <>🇩🇪</> : <></>}</span>
                    </div>
                  );
                })}
              </Link>
            ),
            accessor: "herbs_translations",
          },
          {
            Header: "  ",
            Cell: ({ row }) => <MenuIcon row={row} openMenu={openMenu} />,
          },
        ],
      },
    ],
    [currentLanguage]
  );

  const handleSelect = (tab) => {
    history.push("/" + tab);
  };

  return (
    <>
      {/* Support */}
      <ButtonGroupComponent route="herb" />

      <div className="frame pb-3">
        <h4 className="main-title mt-2">{t("HerbsList.3")}</h4>
        <br />

        <div style={{ marginRight: "50px" }}>
          <Tabs
            defaultActiveKey="herbs"
            onSelect={handleSelect}
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="herbs" title={t("HerbsList.4")}></Tab>
            <Tab eventKey="blend" title={t("BlendList.Blend List")}></Tab>
          </Tabs>

          <div className="container-fluid treatments-table">
            {loading ? (
              <div className="d-flex justify-content-center">
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <Table columns={columns} data={data} />
            )}
          </div>
        </div>
      </div>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        style={{ paddingLeft: "0px" }}
      >
        <Link
          target="_blank"
          to={{
            pathname: `/herb/view/${herb.id}`,
          }}
          style={{ color: "#000", textDecoration: "none" }}
          onClick={() => setAnchorEl(null)}
          id="view_herb"
        >
          <MenuItem className="invoice-menu-item">
            &nbsp;
            <span className="invoice-menu-text">
              <>{t("HerbsList.12")}</>
            </span>
          </MenuItem>
        </Link>
        {herb.owner_id === state.auth.user_id && (
          <Link
            target="_blank"
            to={{
              pathname: `/herb/edit/${herb.id}`,
            }}
            style={{ color: "#000", textDecoration: "none" }}
            id="edit_herb"
          >
            <MenuItem className="invoice-menu-item">
              &nbsp;
              <span className="invoice-menu-text">
                <>{t("HerbsList.10")}</>
              </span>
            </MenuItem>
          </Link>
        )}
        {herb.owner_id === state.auth.user_id && (
          <MenuItem
            onClick={() => handlePreDelete(herb)}
            className="invoice-menu-item"
          >
            &nbsp;
            <span className="invoice-menu-text">
              <>{t("HerbsList.11")}</>
            </span>
          </MenuItem>
        )}
      </Menu>

      <DeleteHerbDialog
        herb={herb}
        dialog={dialog}
        setDialog={setDialog}
        setAnchorEl={setAnchorEl}
        handleDelete={handleDelete}
      />

      {isAlert.open && (
        <AlertComponent
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
    </>
  );
};

export default Herbs;
