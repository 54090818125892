import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import { Beforeunload } from "react-beforeunload";
import TextField from "@material-ui/core/TextField";
import edit_clock from "../../../assets/icons/edit_clock.png";
import { Counter } from "./Counter";

export const ClinicalFindings = ({
  clinicalFindings,
  findings,
  onChange,
  saved,
  updated_at,
  onSubmit,
}) => {
  const { t, i18n } = useTranslation();
  let language = i18n.language;

  const [timer, setTimer] = useState(20);

  const dateFormat = (language, date) => {
    let arrayDate = date.split("-");
    let formattedDate =
      language === "en"
        ? `${arrayDate[1]}/${arrayDate[2]}/${arrayDate[0]}`
        : arrayDate.reverse().join("/");
    return formattedDate;
  };

  const handleFindingsChange = (event) => {
    onChange(event);
    setTimer(20);
  };

  const useBeforeunload = (event) => {
    if (!saved) {
      event.preventDefault();
    }
  };

  return (
    <>
      <h5 className="subtitles-patients">
        {t("ClinicalFindings.Diagnostics")}&nbsp;&nbsp;
        <span className="subtitles-patients border-start">
          <img src={edit_clock} />
        </span>
        &nbsp;
        <span className="key-text-box">
          {dateFormat(language, updated_at.split("T")[0])}&nbsp;
          {t("ClinicalFindings.at")}&nbsp;
          {updated_at.split("T")[1].split(":")[0]}:
          {updated_at.split("T")[1].split(":")[1]}
        </span>
        &nbsp; &nbsp;
        {saved ? (
          <>
            <span className="appointment-status-saved">
              &nbsp;{t("Counter.Saved")}&nbsp;
            </span>
          </>
        ) : (
          <>
            <Counter
              findings={findings}
              timer={timer}
              setTimer={setTimer}
              onSubmit={onSubmit}
            />
          </>
        )}
        &nbsp;
        {/*saved ? <><span className="appointment-status-saved">&nbsp;Saved&nbsp;</span></> : <><span className="appointment-status-unsaved">&nbsp;Unsaved&nbsp;</span></>*/}
        <br />
        <br />
        {/* clinical findings box */}
        {clinicalFindings.length ? (
          <>
            <div className="clinical-findings-box mb-4">
              {clinicalFindings.map((i) => (
                <>
                  <p key={`clinical-findings-item-a-${i}`} className="value-text-box" style={{ margin: "0" }}>
                    {dateFormat(language, i[0].split("T")[0])}
                  </p>
                  <p
                    key={`clinical-findings-item-b-${i}`}
                    className="key-text-box"
                    style={{ marginTop: "0px", marginBottom: "4px" }}
                  >
                    {i[1]}
                  </p>
                </>
              ))}
            </div>
          </>
        ) : (
          <>
            {" "}
            <div className="clinical-findings-box mb-4">
              {t("ClinicalFindings.No previous clinical findings")}
            </div>
          </>
        )}
        {/* clinical findings box */}
        <span style={{ fontWeight: "bolder" }}>
          <FormControl variant="outlined" style={{ width: "96%" }}>
            <TextField
              multiline
              variant="outlined"
              // id="findings"
              id="input_clinical_findings"
              value={findings}
              type="text"
              name="findings"
              onChange={handleFindingsChange}
              placeholder={t("ClinicalFindings.Write here")}
            />
          </FormControl>
        </span>
      </h5>
    </>
  );
};

export default ClinicalFindings;
