import React from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../../assets/stylesheets/multistepCitation.css";
import { withTranslation } from "react-i18next";
import GetUserToken from "../../utils/GetUserToken";

class TimeLocker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      day: "",
      dayError: "",
      date: "2021-01-01",
      start: "",
      end: "",
      doctor: "",
      data: [],
      clinicTreatments: [],
      clinicDoctors: [],
      recursive: "",
      success: false,
      error: false,
      loading: false,
      userToken: GetUserToken(),
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.stripHtmlEntities = this.stripHtmlEntities.bind(this);
    this.getDoctors = this.getDoctors.bind(this);
    this.getUserId = this.getUserId.bind(this);
  }
  componentDidMount() {
    this.getDoctors();
    this.getUserId();
  }

  stripHtmlEntities(str) {
    return String(str).replace(/</g, "&lt;").replace(/>/g, "&gt;");
  }

  // Handle fields change
  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  getDoctors() {
    const url = process.env.REACT_APP_API_URL + "/citations/getDoctors";
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: this.state.userToken,
      },
    })
      .then((response) => {
        response.json().then((response) => {
          this.setState({
            ...this.state,
            clinicDoctors: response["clinicDoctors"],
          });
        });
      })
      .then((response) => this.props.history.push(`/citation/${response.id}`))
      .catch((error) => console.log(error.message));
  }

  getUserId() {
    const url = process.env.REACT_APP_API_URL + "/citations/getUserId";
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: this.state.userToken,
      },
    })
      .then((response) => {
        response.json().then((userId) => {
          this.state.userId = userId["userId"];
        });
      })
      .then((response) => this.props.history.push(`/citation/${response.id}`))
      .catch((error) => console.log(error.message));
  }

  onSubmit(event) {
    this.setState({ ...this.state, loading: true });

    event.preventDefault();

    const url = process.env.REACT_APP_API_URL + "/schedules/create";
    const { day, start, end, doctor, recursive } = this.state;

    const body = {
      day,
      start,
      end,
      doctor,
      recursive,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: this.state.userToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          this.setState({ ...this.state, success: true, loading: false });
          window.location.reload();
          return response.json();
        } else {
          this.setState({ ...this.state, error: true, loading: false });
        }
      })
      .catch((error) => console.log(error.message));
  }

  render() {
    //const { classes } = this.props;
    const { t } = this.props;

    return (
      <>
        <form
          onSubmit={this.onSubmit}
          className="container-fluid text-center"
          style={{
            paddingLeft: "1px",
            paddingRight: "1px",
            maxWidth: "400px",
            minHeight: "550px",
            maxHeight: "600px",
          }}
        >
          <div className="root">
            <div className="form-group">
              <FormControl
                style={{ minWidth: 200, visibility: "hidden", display: "none" }}
                className="inputTitle"
              >
                <TextField
                  required
                  id="date"
                  label="Appointment Date"
                  type="date"
                  name="date"
                  defaultValue="2021-01-01"
                  onChange={this.onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </div>
            <div className="form-group">
              <FormControl style={{ minWidth: 300, textAlign: "left" }}>
                <InputLabel id="day-label" className="inputTitle">
                  {t("Calendar.Day")}
                </InputLabel>
                <Select
                  required
                  labelId="day-label"
                  id="day"
                  type="text"
                  name="day"
                  onChange={this.onChange}
                >
                  <MenuItem value={"0"}>{t("Calendar.Monday")}</MenuItem>
                  <MenuItem value={"1"}>{t("Calendar.Tuesday")}</MenuItem>
                  <MenuItem value={"2"}>{t("Calendar.Wednesday")}</MenuItem>
                  <MenuItem value={"3"}>{t("Calendar.Thursday")}</MenuItem>
                  <MenuItem value={"4"}>{t("Calendar.Friday")}</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="form-group">
              <FormControl component="fieldset" style={{ minWidth: 300 }}>
                <TextField
                  required
                  id="start"
                  name="start"
                  label={t("Calendar.Lock From")}
                  type="time"
                  defaultValue=""
                  onChange={this.onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </div>
            <div className="form-group">
              <FormControl component="fieldset" style={{ minWidth: 300 }}>
                <TextField
                  required
                  id="end"
                  name="end"
                  label={t("Calendar.Lock To")}
                  type="time"
                  defaultValue=""
                  onChange={this.onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </div>
            <div className="form-group">
              <FormControl style={{ minWidth: 300, textAlign: "left" }}>
                <InputLabel id="doctor-label" className="inputTitle">
                  {t("Calendar.Therapist")}
                </InputLabel>
                <Select
                  required
                  labelId="doctor-label"
                  id="doctor"
                  type="text"
                  name="doctor"
                  onChange={this.onChange}
                >
                  {this.state.clinicDoctors.map((item) => {
                    return (
                      <MenuItem value={item["id"]}>{item["name"]}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="form-group">
              <FormControl style={{ minWidth: 300, textAlign: "left" }}>
                <TextField
                  required
                  id="recursive"
                  label={t("Calendar.Recursive")}
                  type="number"
                  name="recursive"
                  onChange={this.onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </div>
            <div className="row mt-3">
              <div className="col">
                {this.state.loading ? (
                  <>
                    <div className="d-flex justify-content-center">
                      <CircularProgress color="secondary" />
                    </div>
                  </>
                ) : (
                  <>
                    <button type="submit" className="btnGo">
                      {t("Buttons.Save")}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </form>

        <Snackbar
          open={this.state.success}
          autoHideDuration={6000}
          onClose={() =>
            this.setState({ ...this.state, success: !this.state.success })
          }
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() =>
              this.setState({ ...this.state, success: !this.state.success })
            }
            severity="success"
          >
            {t("Calendar.Time Spot Locked")}
          </MuiAlert>
        </Snackbar>

        <Snackbar
          open={this.state.error}
          autoHideDuration={6000}
          onClose={() =>
            this.setState({ ...this.state, error: !this.state.error })
          }
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() =>
              this.setState({ ...this.state, error: !this.state.error })
            }
            severity="error"
          >
            {t(
              "Calendar.Error creating lock. Please backup your data and retry"
            )}
          </MuiAlert>
        </Snackbar>
      </>
    );
  }
}

export default withTranslation()(TimeLocker);
