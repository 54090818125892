import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getAllPrescriptions } from "../../../redux/slices/prescription";
import { getAllBlends } from "../../../redux/slices/blend";
import { getAllDoctors } from "../../../redux/slices/doctor";
import { getAllPatients } from "../../../redux/slices/patient";
import { getAllPharmacies } from "../../../redux/slices/pharmacy";
import TableLoader from "./component/TableLoader";
import { Anchor } from "./styles";

const Component = ({
  prescriptions,
  prescriptionsLoading,
  prescriptionsError,
  prescriptionsSuccess,
  infoRoute,
}) => {
  // translation
  const { t } = useTranslation();
  let currentLanguage = localStorage.getItem("i18nextLng");
  // redux
  const dispatch = useDispatch();
  // route
  const { history } = infoRoute;
  // utils
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filters, setFilters] = useState({});
  const [sort, setSort] = useState({
    sortBy: "created_at",
    sortDirection: "desc",
  });
  const [total, setTotal] = useState(0);

  const getElement = (row) => {
    history.push({
      pathname: `/prescription/${row.id}`,
      state: { id: row.id },
    });
  };

  let timer;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFilters((oldState) => ({ ...oldState, [name]: value }));
  };

  const dispatchAction = () => {
    dispatch(
      getAllPrescriptions({ currentPage, ...sort, itemsPerPage, filters })
    );
  };

  const columns = useMemo(
    () => [
      {
        name: t("PrescriptionTable.Patient"),
        selector: (row) => row.patient_id,
        id: "patient_id",
        sortable: true,
      },
      {
        name: t("PrescriptionTable.Therapist"),
        selector: (row) => row.doctor_id,
        id: "doctor_id",
        sortable: true,
      },
      {
        name: t("PrescriptionTable.Pharmacy"),
        selector: (row) => row.pharmacy?.id,
        id: "pharmacy_id",
        sortable: true,
      },
      {
        name: t("PrescriptionTable.Blend"),
        selector: (row) => row.blend_id,
        id: "blend_id",
        sortable: true,
      },
    ],
    [prescriptions, currentLanguage]
  );

  useEffect(() => {
    if (!!prescriptions) {
      setTotal(prescriptions.length);
    }
  }, [prescriptions]);

  useEffect(() => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      dispatchAction();
    }, 1500);

    return () => clearTimeout(timer);
  }, [filters, currentPage, itemsPerPage, sort]);

  useEffect(() => {
    dispatchAction();
    dispatch(getAllBlends());
    dispatch(getAllDoctors());
    dispatch(getAllPatients());
    dispatch(getAllPharmacies());
  }, []);

  return (
    <>
      <h4 className="main-title mt-2">{t("PrescriptionList.Prescriptions")}</h4>
      <br />
      {prescriptionsLoading && !prescriptions ? (
        <div className="d-flex justify-content-center">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <TableLoader loading={prescriptionsLoading}>
          <div
            style={{
              width: "95%",
            }}
          >
            <div className="d-flex justify-content-end">
              <input
                name="searchBy"
                id="searchBy"
                className="search-field"
                type="text"
                value={filters["searchBy"]}
                onChange={handleChange}
                placeholder={t("PrescriptionList.Search")}
              />

              <Anchor to="/prescription/new">
                {t("PrescriptionList.Add prescription")}
              </Anchor>
            </div>
            <div style={{ height: 20 }} />
            <div style={{ overflowX: "auto" }}>
              <DataTable
                className="custom-table"
                paginationComponentOptions={{
                  noRowsPerPage: true,
                  rowsPerPageText: t("Table.Show"),
                  rangeSeparatorText: t("Table.of"),
                  selectAllRowsItemText: t("Table.All"),
                }}
                noDataComponent={
                  <div className="p-3">
                    <b>{t("Table.No results")}</b>
                  </div>
                }
                striped
                pagination
                sortServer
                paginationServer
                data={prescriptions || []}
                paginationTotalRows={total}
                paginationDefaultPage={1}
                onChangeRowsPerPage={setItemsPerPage}
                onRowClicked={getElement}
                paginationPerPage={itemsPerPage}
                onSort={(a, b) => setSort({ sortBy: a.id, sortDirection: b })}
                onChangePage={setCurrentPage}
                columns={columns}
              />
            </div>
          </div>
        </TableLoader>
      )}
    </>
  );
};

const state = ({
  prescriptionStore,
  patientStore,
  doctorStore,
  pharmacyStore,
  blendStore,
}) => {
  const {
    data: prescriptions,
    loading: prescriptionsLoading,
    error: prescriptionsError,
    success: prescriptionsSuccess,
  } = prescriptionStore.all;

  const {
    data: patients,
    loading: patientsLoading,
    error: patientsError,
    success: patientsSuccess,
  } = patientStore.all;

  const {
    data: doctors,
    loading: doctorsLoading,
    error: doctorsError,
    success: doctorsSuccess,
  } = doctorStore.all;

  const {
    data: pharmacies,
    loading: pharmaciesLoading,
    error: pharmaciesError,
    success: pharmaciesSuccess,
  } = pharmacyStore.all;

  const {
    data: blends,
    loading: blendsLoading,
    error: blendsError,
    success: blendsSuccess,
  } = blendStore.all;

  return {
    prescriptions,
    prescriptionsLoading,
    prescriptionsError,
    prescriptionsSuccess,
    patients,
    patientsLoading,
    patientsError,
    patientsSuccess,
    doctors,
    doctorsLoading,
    doctorsError,
    doctorsSuccess,
    pharmacies,
    pharmaciesLoading,
    pharmaciesError,
    pharmaciesSuccess,
    blends,
    blendsLoading,
    blendsError,
    blendsSuccess,
  };
};

export default connect(state)(Component);
