import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { useBeforeunload } from "react-beforeunload";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import edit_clock from "../../../assets/icons/edit_clock.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Accordion, Card, Button } from "react-bootstrap";
import GetUserToken from "../../../utils/GetUserToken";
import Alert from "../../alerts/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FormControl, TextField } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/HelpOutline";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const AltDiagnosis = ({
  id,
  patientID,
  handleCallbackAltDiagnosis,
  diagnosisList,
  invoiced,
  currentDiagnosis,
}) => {
  const { t } = useTranslation();

  const userToken = GetUserToken();

  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const [last, setLast] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [save, setSave] = useState(false);
  const [fieldOne, setFieldOne] = useState([]);
  const [hovering, setHovering] = useState(false);

  const previousFieldOne = useRef(fieldOne);

  useBeforeunload((event) => {
    if (save !== false && !invoiced) {
      event.preventDefault();
    }
  });

  const fetchData = async () => {
    //BUG BACK en algunos casos devuelve null o "null"

    handleCallbackAltDiagnosis([false, "No changes"]);
    setLoading(true);

    var requestOptions = {
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL +
        `/citations/getAltDiagnosis?patient_id=` +
        patientID,
      requestOptions
    );

    const json = await res.json();

    let data = json.altdiagnosis;
    let filteredData = data.filter((element) => element[2] !== null);
    setFiltered(filteredData);

    let pulseData = data.find((element) => element[2] !== null);
    setLast(pulseData);

    if (pulseData) {
      handleCallbackAltDiagnosis([true, pulseData[1]]);
      setValid(true);
      //let values = pulseData[2];
      setFieldOne(JSON.parse(pulseData[2]));

      setLoading(false);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (
      JSON.stringify(fieldOne) !== JSON.stringify(previousFieldOne.current) &&
      !invoiced
    ) {
      setSave(true);
      setIsAlert({
        open: true,
        severity: "warning",
        message: t("Messages.Previous value on diagnosis"),
      });
      previousFieldOne.current = fieldOne;
    } else {
      setSave(false);
    }
  }, [fieldOne]);

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    const body = { altdiagnosis: JSON.stringify(fieldOne) };
    fetch(process.env.REACT_APP_API_URL + `/citations/update/` + id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
        setSave(false);
        setLoading(false);
        fetchData();
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.Something went wrong, please try again"),
        });
        setLoading(false);
      });
  };

  const handleDateChange = (e) => {
    setFieldOne(JSON.parse(e.target.value[2]));
    setLast(e.target.value);
    setSave(true);
  };

  // Current values of citations
  const mappedValues = currentDiagnosis
    ? currentDiagnosis.map((item) => `${item.code} - ${item.name}\n`)
    : "";

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip disableFocusListener {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 400,
      fontSize: "15px",
    },
  });

  return (
    <>
      <Accordion style={{ paddingRight: "0", paddingLeft: "0" }}>
        <Card
          className="reminders-cards"
          style={{ width: "100%", borderRadius: "10px" }}
        >
          <Card.Header
            className="reminders-subtitle"
            style={{
              border: "none",
              background: "white",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "space-between",
              paddingRight: "0",
              paddingLeft: "0",
            }}
          >
            <div>
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="0"
                className="text-center subtitles-patients"
                style={{ textDecoration: "none" }}
                id="accordion_recommendations"
              >
                <span>&#8595; &nbsp;</span>
                {t("CompleteAppointment.Western diagnosis")}
              </Accordion.Toggle>
              <span className="subtitles-patients border-start">
                <img
                  src={edit_clock}
                  alt="clock"
                  onMouseEnter={() => setHovering(true)}
                  onMouseLeave={() => setHovering(false)}
                />
                {loading ? (
                  <></>
                ) : (
                  <>
                    {filtered.length != 0 ? (
                      <>
                        <Select
                          id="treatment"
                          name="treatment"
                          value={last}
                          onChange={handleDateChange}
                          variant="standard"
                          // disabled={invoiced}
                        >
                          {filtered.map((item, i) => (
                            <MenuItem
                              key={`alt-diagnosis-menu-item-${i}`}
                              value={item}
                            >
                              <b>{item[0].split("T")[0]}&nbsp; /&nbsp;</b>
                              {item[1].replace("T", " at: ").slice(0, -8)}
                              &nbsp;&nbsp;
                            </MenuItem>
                          ))}
                        </Select>
                        {hovering && (
                          <span className="key-text-box">
                            <b> {t("CompleteAppointment.Reference1")}</b>
                            {t("CompleteAppointment.Reference2")}
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        <span className="key-text-box">
                          &nbsp;{t("Messages.No previous records")}
                        </span>
                      </>
                    )}
                  </>
                )}
                <CustomWidthTooltip
                  title={
                    <span style={{ whiteSpace: "pre-line" }}>
                      {t("CompleteAppointment.Current values")}:
                      <br />
                      {mappedValues}
                    </span>
                  }
                >
                  &nbsp;
                  <HelpIcon style={{ color: "var(--primary-color)" }} />
                  &nbsp;
                </CustomWidthTooltip>
              </span>
            </div>
            <div>
              {save == false || invoiced ? (
                <></>
              ) : (
                <button
                  className="button-citation-box-2-save"
                  onClick={handleSubmit}
                  style={{ padding: "2px" }}
                >
                  {t("Buttons.Save")}
                </button>
              )}
            </div>
          </Card.Header>

          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <form
                onSubmit={handleSubmit}
                style={{
                  background: "#f4f5fa",
                  borderRadius: "10px",
                  padding: "10px 10px",
                }}
              >
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <CircularProgress color="secondary" />
                  </div>
                ) : (
                  <>
                    <div className="row">
                      <div className="col mb-2 mt-2">
                        <FormControl
                          variant="outlined"
                          style={{ width: "100%", marginTop: "15px" }}
                        >
                          <Autocomplete
                            getOptionSelected={(option, value) =>
                              option.name === value.name
                            }
                            multiple
                            id="tags-standard"
                            disabled={invoiced}
                            options={diagnosisList}
                            getOptionLabel={(option) =>
                              option.code + " - " + option.name
                            }
                            onChange={(e, value) => {
                              setSave(true);
                              setFieldOne(value);
                            }}
                            value={fieldOne}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label={t(
                                  "CompleteAppointment.Select Diagnosis"
                                )}
                                //placeholder="Favorites"
                              />
                            )}
                          />
                        </FormControl>
                      </div>
                    </div>
                  </>
                )}
              </form>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      {isAlert.open && (
        <Alert
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
    </>
  );
};

export default AltDiagnosis;
