import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, Button, Card, Modal } from "react-bootstrap";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LockIcon from "@material-ui/icons/Lock";
import { ReactComponent as Pencil } from "../../assets/icons/pencil.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import Task from "../../assets/icons/task_icon.png";
import Important from "../../assets/icons/important_icon.png";
import Todo from "../../assets/icons/todo_icon.png";
import "../../assets/stylesheets/remindersStyles.css";
import RemindersCalendar from "./RemindersCalendar";
import NewReminder from "./NewReminder";
import { EditReminder } from "./EditReminder";
import FetchIndexData from "../../utils/FetchIndexData";
import GetUserToken from "../../utils/GetUserToken";
import ButtonGroupComponent from "../reusableComponents/ButtonGroup";
import AlertComponent from "../alerts/Alert";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 300,
  },
}));

const RemindersList = () => {
  // translations
  const { t } = useTranslation();
  // styles
  const classes = useStyles();
  // data
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [doctor, setDoctor] = useState("");
  // utils
  const [loading, setLoading] = useState(true);
  const [loadingbox, setLoadingbox] = useState(true);
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  //Create new reminder
  const [show, setShow] = useState(false);
  const [upcomingreminders, setUpcomingreminders] = useState([]);

  let today = new Date().toISOString().slice(0, 10);

  const [dayview, setDayview] = useState(false);

  const monthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const handleClose = () => {
    setShow(false);
    getTodayReminders();
    getMonthReminders();
  };
  const handleShow = () => setShow(true);

  //Edit current reminder
  const [currentreminder, setCurrentreminder] = useState("");
  const [editshow, seteditShow] = useState(false);
  const handleeditClose = () => {
    seteditShow(false);
    getTodayReminders();
    getMonthReminders();
  };
  const handleeditShow = () => seteditShow(true);

  useEffect(() => {
    getTodayReminders();
    getMonthReminders();
    getDoctors();
  }, []);

  const getDoctors = async () => {
    var response = await FetchIndexData("/citations/getDoctors");
    setDoctors(response.clinicDoctors);
  };

  const getTodayReminders = async () => {
    setData([]);
    var response = await FetchIndexData("/reminders/getTodayReminders");
    console.log(response);
    setData(response.reminders);
    setFilteredData(response.reminders);
    setLoading(false);
  };

  const getMonthReminders = async () => {
    setLoadingbox(true);
    var json = await FetchIndexData("/reminders/getUpcomingReminders");

    let arrayReminders = json.reminders;
    let datesArray = [];
    let monthNumber;
    let monthName = "";
    let dayNumber;
    let hour = "";
    let compare = "";

    arrayReminders.map((item, i) => {
      monthNumber = parseInt(item.time.split("-")[1]) - 1;
      monthName = monthNames[monthNumber];
      dayNumber = item.time.split("-")[2].split("T")[0];
      hour = item.time.split("-")[2].split("T")[1];
      compare = item.time;

      datesArray.push([
        item.id,
        item.title,
        monthName,
        dayNumber,
        hour,
        compare,
        item.done,
      ]);
    });

    datesArray.sort((a, b) => {
      var dateA = new Date(a[5]).getTime();
      var dateB = new Date(b[5]).getTime();
      return dateA > dateB ? 1 : -1;
    });

    setUpcomingreminders(datesArray);
    //setLoad(false);
    setLoadingbox(false);
  };

  const handleCheck = async (event) => {
    try {
      setLoading(true);
      let reminderId = event.target.id;
      event.preventDefault();
      var url =
        process.env.REACT_APP_API_URL + "/reminders/complete/?id=" + reminderId;
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: GetUserToken(),
        },
      };
      const res = await fetch(url, requestOptions);
      if (res.ok) {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
        getTodayReminders();
        getMonthReminders();
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDoctorPick = (e) => {
    setDoctor(e.target.value);
    var myNewData;
    if (e.target.value) {
      myNewData = data.filter(
        (item, i) => item.assignto == parseInt(e.target.value)
      );
    } else {
      myNewData = data;
    }
    setFilteredData(myNewData);
  };

  const handlePin = (event) => {
    setLoading(true);
    let reminderId = event.target.id;
    event.preventDefault();
    fetch(process.env.REACT_APP_API_URL + "/reminders/pin/?id=" + reminderId, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: GetUserToken(),
      },
    }).then(() => {
      getTodayReminders();
      getMonthReminders();
    });
  };

  const editReminder = (event) => {
    event.preventDefault();
    handleeditShow();
    setCurrentreminder(event.target.id);
  };

  const sortedCompleteRemindersDate = filteredData.sort((a, b) => {
    var dateA = new Date(a.time).getTime();
    var dateB = new Date(b.time).getTime();
    return dateB > dateA ? 1 : -1;
  });

  const incomplete = filteredData
    .sort((a, b) => b.pinned - a.pinned)
    .map(
      (item, i) =>
        !item.done && (
          <div key={i}>
            {dayview ? (
              <>
                {item.time.split("T")[0] == today ? (
                  <>
                    {/* <ReminderItem item={item} i={i} /> */}
                    <div className="reminder-box">
                      <div className="row justify-content-start">
                        <div className="col-1">
                          <Checkbox
                            id={item.id.toString()}
                            checked={false}
                            onChange={handleCheck}
                            inputProps={{
                              "aria-label": "uncontrolled-checkbox",
                            }}
                            style={{ color: "#837d8d" }}
                          />
                        </div>
                        <div className="col-sm-12 col-lg-6">
                          <span className="reminder-title">
                            {item.private ? (
                              <>
                                <LockIcon
                                  style={{ color: "var(--primary-color)" }}
                                />
                                &nbsp;
                              </>
                            ) : (
                              <></>
                            )}
                            {item.title}
                          </span>
                        </div>
                        <div className="col-sm-12 col-lg-5">
                          <Pencil
                            className="icon"
                            id={item.id}
                            onClick={editReminder}
                            style={{ cursor: "pointer", marginRight: "10px" }}
                          />
                          <img src={Task} className="reminder-icons" />
                          <img
                            src={Important}
                            alt="Important"
                            id={item.id}
                            onClick={handlePin}
                            className="reminder-icons"
                            style={{ cursor: "pointer" }}
                          />
                          {item.pinned ? (
                            <>
                              <span className="important-badge">
                                {t("RemindersList.Priority")}
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="to-do-badge">
                                {t("RemindersList.To Do")}
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="row justify-content-start">
                        <div className="col-1"></div>
                        <div className="col-sm-12 col-lg-6">
                          <p className="reminder-body">
                            <b>{t("RemindersList.Date/Time")}:</b>{" "}
                            {item.time.split("T")[0]} -{" "}
                            {item.time.split("T")[1]}
                          </p>
                          <p className="reminder-body">
                            {t("RemindersList.Created by")}: {item.creator_name}
                          </p>
                          <p className="reminder-body">
                            {t("RemindersList.Note")}:{" "}
                            {item.note != "" ? (
                              item.note
                            ) : (
                              <i>{t("RemindersList.Empty")}</i>
                            )}
                          </p>
                        </div>
                        <div className="col-sm-12 col-lg-5">
                          <p className="reminder-body">
                            {t("RemindersList.Assigned to")}:{" "}
                            {item.assignto_name != "" ? (
                              item.assignto_name
                            ) : (
                              <i>{t("RemindersList.General")}</i>
                            )}
                          </p>
                          <p className="reminder-body">
                            {t("RemindersList.Task")}:{" "}
                            {item.task != "" ? (
                              item.task
                            ) : (
                              <i>{t("RemindersList.Empty")}</i>
                            )}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        color: "red",
                        marginBottom: "10px",
                        height: "5px",
                      }}
                    ></div>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <div key={i}>
                <div className="reminder-box">
                  <div className="row justify-content-start">
                    <div className="col-1">
                      <Checkbox
                        id={item.id.toString()}
                        checked={false}
                        onChange={handleCheck}
                        inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                        style={{ color: "#837d8d" }}
                      />
                    </div>
                    <div className="col-sm-12 col-lg-6">
                      <span className="reminder-title">
                        {item.private ? (
                          <>
                            <LockIcon
                              style={{ color: "var(--primary-color)" }}
                            />
                            &nbsp;
                          </>
                        ) : (
                          <></>
                        )}
                        {/* {item.pinned ? <><PriorityHighIcon style={{ color: 'var(--primary-color)' }} />&nbsp;</> : <></>} */}
                        {item.title}
                      </span>
                    </div>
                    <div className="col-sm-12 col-lg-5">
                      <Pencil
                        className="icon"
                        id={item.id}
                        onClick={editReminder}
                        style={{ cursor: "pointer", marginRight: "10px" }}
                      />
                      <img src={Task} alt="Task" className="reminder-icons" />
                      <img
                        src={Important}
                        alt="Important"
                        id={item.id}
                        onClick={handlePin}
                        className="reminder-icons"
                        style={{ cursor: "pointer" }}
                      />
                      {/* <span className="pin-button">
                            <img src={Priority} style={{ height: '19px'}}/>
                            Pin
                        </span> */}
                      {item.pinned ? (
                        <>
                          <span className="important-badge">
                            {t("RemindersList.Priority")}
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="to-do-badge">
                            {t("RemindersList.To Do")}
                          </span>
                        </>
                      )}
                      {/* <img src={Todo} className="reminder-icons" /> */}
                    </div>
                  </div>
                  <div className="row justify-content-start">
                    <div className="col-1"></div>
                    <div className="col-sm-12 col-lg-6">
                      <p className="reminder-body">
                        <b>{t("RemindersList.Date/Time")}:</b>{" "}
                        {item.time.split("T")[0]} - {item.time.split("T")[1]}
                      </p>
                      <p className="reminder-body">
                        {t("RemindersList.Created by")}: {item.creator_name}
                      </p>
                      <p className="reminder-body">
                        {t("RemindersList.Note")}:{" "}
                        {item.note != "" ? (
                          item.note
                        ) : (
                          <i>{t("RemindersList.Empty")}</i>
                        )}
                      </p>
                    </div>
                    <div className="col-sm-12 col-lg-5">
                      <p className="reminder-body">
                        {t("RemindersList.Assigned to")}:{" "}
                        {item.assignto_name != "" ? (
                          item.assignto_name
                        ) : (
                          <i>{t("RemindersList.General")}</i>
                        )}
                      </p>
                      <p className="reminder-body">
                        {t("RemindersList.Task")}:{" "}
                        {item.task != "" ? (
                          item.task
                        ) : (
                          <i>{t("RemindersList.Empty")}</i>
                        )}{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style={{ color: "red", marginBottom: "10px", height: "5px" }}
                ></div>
              </div>
            )}
          </div>
        )
    );

  const complete = sortedCompleteRemindersDate.map(
    (item, i) =>
      item.done && (
        <div key={i}>
          {dayview ? (
            <>
              {item.time.split("T")[0] == today ? (
                <>
                  <div className="reminder-box">
                    <div className="row justify-content-start">
                      <div className="col-1">
                        <Checkbox
                          id={item.id.toString()}
                          checked={item.done}
                          onChange={handleCheck}
                          inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                          style={{ color: "#837d8d" }}
                        />
                      </div>
                      <div className="col-sm-12 col-lg-6">
                        <p className="reminder-title">{item.title}</p>
                      </div>
                      <div className="col-sm-12 col-lg-5">
                        <Pencil
                          className="icon"
                          id={item.id}
                          onClick={editReminder}
                          style={{ cursor: "pointer", marginRight: "10px" }}
                        />
                        <img src={Task} alt="Task" className="reminder-icons" />
                        <img
                          src={Important}
                          alt="Important"
                          className="reminder-icons"
                        />
                        <img src={Todo} alt="Todo" className="reminder-icons" />
                      </div>
                    </div>
                    <div className="row justify-content-start">
                      <div className="col-1"></div>
                      <div className="col-sm-12 col-lg-6">
                        <p className="reminder-body">
                          <b>{t("RemindersList.Date/Time")}:</b>{" "}
                          {item.time.split("T")[0]} - {item.time.split("T")[1]}
                        </p>
                        <p className="reminder-body">
                          {t("RemindersList.Note")}:{" "}
                          {item.note != "" ? (
                            item.note
                          ) : (
                            <i>{t("RemindersList.Empty")}</i>
                          )}
                        </p>
                        <p className="reminder-body">
                          {t("RemindersList.Created by")}: {item.creator_name}
                        </p>
                      </div>
                      <div className="col-sm-12 col-lg-5">
                        <p className="reminder-body">
                          {t("RemindersList.Assigned to")}:{" "}
                          {item.assignto_name != "" ? (
                            item.assignto_name
                          ) : (
                            <i>{t("RemindersList.General")}</i>
                          )}
                        </p>
                        <p className="reminder-body">
                          {t("RemindersList.Completed")}: {item.completor_name}
                        </p>
                        <p className="reminder-body">
                          {t("RemindersList.Task")}:{" "}
                          {item.task != "" ? (
                            item.task
                          ) : (
                            <i>{t("RemindersList.Empty")}</i>
                          )}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <div className="reminder-box">
              <div className="row justify-content-start">
                <div className="col-1">
                  <Checkbox
                    id={item.id.toString()}
                    checked={item.done}
                    onChange={handleCheck}
                    inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                    style={{ color: "#837d8d" }}
                  />
                </div>
                <div className="col-sm-12 col-lg-6">
                  <p className="reminder-title">{item.title}</p>
                </div>
                <div className="col-sm-12 col-lg-5">
                  <Pencil
                    className="icon"
                    id={item.id}
                    onClick={editReminder}
                    style={{ cursor: "pointer", marginRight: "10px" }}
                  />
                  <img src={Task} alt="Task" className="reminder-icons" />
                  <img
                    src={Important}
                    alt="Important"
                    className="reminder-icons"
                  />
                  <img src={Todo} alt="Todo" className="reminder-icons" />
                </div>
              </div>
              <div className="row justify-content-start">
                <div className="col-1"></div>
                <div className="col-sm-12 col-lg-6">
                  <p className="reminder-body">
                    <b>{t("RemindersList.Date/Time")}:</b>{" "}
                    {item.time.split("T")[0]} - {item.time.split("T")[1]}
                  </p>
                  <p className="reminder-body">
                    {t("RemindersList.Note")}:{" "}
                    {item.note != "" ? (
                      item.note
                    ) : (
                      <i>{t("RemindersList.Empty")}</i>
                    )}
                  </p>
                  <p className="reminder-body">
                    {t("RemindersList.Created by")}: {item.creator_name}
                  </p>
                </div>
                <div className="col-sm-12 col-lg-5">
                  <p className="reminder-body">
                    {t("RemindersList.Assigned to")}:{" "}
                    {item.assignto_name != "" ? (
                      item.assignto_name
                    ) : (
                      <i>{t("RemindersList.General")}</i>
                    )}
                  </p>
                  <p className="reminder-body">
                    {t("RemindersList.Completed")}: {item.completor_name}
                  </p>
                  <p className="reminder-body">
                    {t("RemindersList.Task")}:{" "}
                    {item.task != "" ? (
                      item.task
                    ) : (
                      <i>{t("RemindersList.Empty")}</i>
                    )}{" "}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      )
  );

  const not = (
    <div className="d-flex justify-content-center">
      <CircularProgress color="secondary" />
    </div>
  );

  return (
    <>
      {/* Support */}
      <ButtonGroupComponent route="reminders" />

      <div className="frame">
        <h4 className="main-title mt-2">{t("RemindersList.Reminders")}</h4>
        <br />
        <div style={{ marginRight: "50px" }}>
          <div
            style={{
              paddingTop: "3px",
              paddingBottom: "15px",
              marginBottom: "10px",
              alignItems: "center",
            }}
          >
            <button
              className="new-patient-btn"
              id="add_reminder"
              onClick={handleShow}
            >
              {t("RemindersList.New Reminder")}
            </button>
          </div>

          <div className="container-fluid">
            <div className="row justify-content-start">
              <div className="col-8 mt-3">
                <p className="reminders-subtitle">
                  {t("RemindersList.Current Reminders")}:
                </p>
                {loading ? (
                  not
                ) : (
                  <>
                    <div className="reminders-toolbox-filter">
                      <div className="row">
                        <div className="col-5">
                          <FormControl style={{ marginTop: "15px" }}>
                            <span
                              className="reminder-body"
                              style={{ marginLeft: "15px" }}
                            >
                              {t("RemindersList.Show only today reminders")}
                              <Switch
                                checked={dayview}
                                onChange={() => setDayview(!dayview)}
                                name="checkedA"
                                color="secondary"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                              />
                            </span>
                          </FormControl>
                        </div>
                        <div className="col-7">
                          <FormControl
                            // variant="outlined"
                            // className={classes.formControl}
                            style={{
                              marginBottom: "26px",
                              width: "95%",
                              maxHeight: "30px",
                            }}
                          >
                            <InputLabel>
                              <span
                                className="reminder-body"
                                style={{ marginLeft: "15px" }}
                              >
                                {t("RemindersList.Filter by therapeut")}
                              </span>
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              style={{ maxHeight: "30px", height: "30px" }}
                              value={doctor}
                              onChange={handleDoctorPick}
                              label="Age"
                            >
                              <MenuItem value={null}>
                                {t("RemindersList.None")}
                              </MenuItem>
                              {doctors.map((item, i) => (
                                <MenuItem value={item.id} key={i}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                    <Accordion defaultActiveKey="0">
                      <Card
                        className="reminders-cards"
                        style={{ width: "100%" }}
                      >
                        <Card.Header
                          className="reminders-subtitle"
                          style={{ border: "none" }}
                        >
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            className="subtitles-patients"
                            style={{ textDecoration: "none" }}
                          >
                            <span>&#8595; &nbsp;</span>{" "}
                            {t("RemindersList.Uncompleted")}
                          </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse eventKey="0">
                          <Card.Body>{incomplete}</Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card
                        className="reminders-cards"
                        style={{ width: "100%" }}
                      >
                        <Card.Header style={{ border: "none" }}>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="1"
                            className="subtitles-patients"
                            style={{ textDecoration: "none" }}
                          >
                            <span>&#8595; &nbsp;</span>{" "}
                            {t("RemindersList.Completed")}
                          </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse eventKey="1">
                          <Card.Body>{complete}</Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </>
                )}
              </div>

              <div className="col-4 mt-3">
                <p className="reminders-subtitle">
                  {t("RemindersList.Upcoming Reminders")}:
                </p>
                <RemindersCalendar
                  getMonthReminders={getMonthReminders}
                  upcomingreminders={upcomingreminders}
                  loadingbox={loadingbox}
                />
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ background: "#F4F5FA" }}>
          <Modal.Title>{t("RemindersList.New Reminder")}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#F4F5FA" }}>
          <NewReminder handleClose={handleClose} setIsAlert={setIsAlert} />
        </Modal.Body>
      </Modal>

      <Modal
        show={editshow}
        onHide={handleeditClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ background: "#F4F5FA" }}>
          <Modal.Title>
            {t("ReminderCalendar.Edit Reminder")}# {currentreminder}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#F4F5FA" }}>
          <EditReminder
            id={currentreminder}
            handleeditClose={handleeditClose}
            setIsAlert={setIsAlert}
          />
        </Modal.Body>
      </Modal>

      {isAlert.open && (
        <AlertComponent
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
    </>
  );
};

export default RemindersList;
