import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../assets/stylesheets/config.css";
import ButtonGroupComponent from "../reusableComponents/ButtonGroup";
import { Link } from "react-router-dom";

const Config = () => {
  const { t } = useTranslation();
  const state = useSelector((state) => state.user);

  return (
    <>
      {/* Support */}
      <ButtonGroupComponent route="config" />

      <div className="frame">
        <h4 className="main-title mt-2">
          {state.auth ? (
            <div>
              {t("Settings.Your APP settings")} {state.auth?.fullname}:
            </div>
          ) : (
            <></>
          )}
        </h4>
        <div
          style={{
            paddingRight: "50px",
            paddingTop: "50px",
            paddingBottom: "20px",
          }}
        >
          <div className="list-group border-rounded">
            {state.auth.admin && (
              <Link
                to="/dashboard"
                className="list-group-item list-group-item-action list-item-gray"
                //id="view_products"
              >
                <div className="d-flex w-100 justify-content-between">
                  <h5 className="mb-1">{t("Settings.Dashboard")}</h5>
                  <small className="text-muted">
                    <></>
                  </small>
                </div>
                <p className="mb-1">
                  {t("Settings.Real-time information about")}
                </p>
              </Link>
            )}
            <Link
              to="/treatments"
              className="list-group-item list-group-item-action list-item"
              aria-current="true"
              id="view_treatment"
            >
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">{t("Settings.Edit Treatments")}</h5>
                <small>
                  <></>
                </small>
              </div>
              <p className="mb-1">
                {t("Settings.Customize and create new")}{" "}
                {t("Settings.treatments")}.
              </p>
              <small>
                {t("Settings.Params")}: {t("Settings.Title")},{" "}
                {t("Settings.duration")}, {t("Settings.products")}.
              </small>
            </Link>
            <Link
              to="/products"
              className="list-group-item list-group-item-action list-item-gray"
              id="view_products"
            >
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">{t("Settings.Edit Products")}</h5>
                <small className="text-muted">
                  <></>
                </small>
              </div>
              <p className="mb-1">
                {t("Settings.Customize and create new")}{" "}
                {t("Settings.products")}.
              </p>
              <small className="text-muted">
                {t("Settings.Params")}: {t("Settings.Title")},{" "}
                {t("Settings.price")}.
              </small>
            </Link>
            <Link
              to="/adittionals"
              className="list-group-item list-group-item-action list-item"
              id="view_bundles"
            >
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">
                  {t("Settings.Edit Adittional Products (Bundles)")}
                </h5>
                <small className="text-muted">
                  <></>
                </small>
              </div>
              <p className="mb-1">
                {t("Settings.Customize and create new")} {t("Settings.bundles")}
                .
              </p>
              <small className="text-muted">
                {t("Settings.Params")}: {t("Settings.Title")},{" "}
                {t("Settings.price")}, {t("Settings.products")}.
              </small>
            </Link>
            <Link
              to="/tax"
              className="list-group-item list-group-item-action list-item-gray"
            >
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">{t("Settings.Edit tax rates")}</h5>
                <small className="text-muted">
                  <></>
                </small>
              </div>
              <p className="mb-1">{t("Settings.Crate and edit tax rates")}.</p>
              <small className="text-muted">
                {t("Settings.Params")}: {t("Settings.Code")},{" "}
                {t("Settings.Rate")}, {t("Insurances.Description")}.
              </small>
            </Link>
            <Link
              to="/quizzes"
              className="list-group-item list-group-item-action list-item"
              id="view_quizzes"
            >
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">{t("Settings.Edit Quizes")}</h5>
                <small className="text-muted">
                  <></>
                </small>
              </div>
              <p className="mb-1">
                {t("Settings.Customize and create new")} {t("Settings.quizes")}.
              </p>
              <small className="text-muted">
                {t("Settings.Params")}: {t("Settings.Title")},{" "}
                {t("Settings.questions")}.
              </small>
            </Link>
            <Link
              to="/doctors"
              className="list-group-item list-group-item-action list-item-gray"
              id="view_therapists"
            >
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">{t("Settings.Edit Therapists")}</h5>
                <small className="text-muted">
                  <></>
                </small>
              </div>
              <p className="mb-1">
                {t("Settings.Register and edit therapists")}
              </p>
              <small className="text-muted">
                {t("Settings.Params")}: {t("Settings.Current user")},{" "}
                {t("Settings.name")}, color.
              </small>
            </Link>
            <Link
              to="/insurances"
              className="list-group-item list-group-item-action list-item"
              id="view_insurances"
            >
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">{t("Settings.Edit Insurances")}</h5>
                <small className="text-muted">
                  <></>
                </small>
              </div>
              <p className="mb-1">
                {t("Settings.Create, edit and delete insurances")}
              </p>
              <small className="text-muted">
                {t("Settings.Params")}: {t("Settings.Name")},{" "}
                {t("Settings.description")}.
              </small>
            </Link>
            <Link
              to="/patients"
              className="list-group-item list-group-item-action list-item-gray"
              id="view_patients"
            >
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">{t("Settings.Edit Patients")}</h5>
                <small className="text-muted">
                  <></>
                </small>
              </div>
              <p className="mb-1">
                {t("Settings.Create, delete and edit patients")}
              </p>
              <small className="text-muted">
                {t("Settings.Params")}: {t("Settings.Name")},{" "}
                {t("Settings.email")}, {t("Settings.address")},{" "}
                {t("Settings.phone")}, {t("Settings.relationship")}.
              </small>
            </Link>
            <Link
              to="/diagnosis"
              className="list-group-item list-group-item-action list-item"
              id="view_diagnosis"
            >
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">{t("Settings.Edit Diagnosis")}</h5>
                <small className="text-muted">
                  <></>
                </small>
              </div>
              <p className="mb-1">{t("Settings.Create and edit diagnosis")}</p>
              <small className="text-muted">
                {t("Settings.Params")}: {t("Settings.Code")},{" "}
                {t("Settings.name")}.
              </small>
            </Link>
            <Link
              to="/pharmacy"
              className="list-group-item list-group-item-action list-item-gray"
              id="view_pharmacy"
            >
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">{t("Settings.Edit pharmacy")}</h5>
                <small className="text-muted" />
              </div>
              <p className="mb-1">
                {t("Settings.Edit the pharmacy information")}
              </p>
              <small className="text-muted">
                {t("Settings.Params")}: {t("Settings.name")},{" "}
                {t("Settings.address")}, {t("Settings.email")},{" "}
                {t("Settings.phone")}.
              </small>
            </Link>
            <Link
              to="/company/"
              className="list-group-item list-group-item-action list-item list-item"
              id="view_company"
            >
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">{t("Settings.Edit Company")}</h5>
                <small className="text-muted">
                  <></>
                </small>
              </div>
              <p className="mb-1">
                {t("Settings.Edit the company information")}
              </p>
              <small className="text-muted">
                {t("Settings.Params")}: {t("Settings.name")},{" "}
                {t("Settings.address")}, {t("Settings.email")},{" "}
                {t("Settings.phone")}.
              </small>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Config;
