import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Stack } from "@mui/material";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  TextareaAutosize,
} from "@material-ui/core";
import { branches } from "../../constants/branches";
import { countries } from "../../constants/countries";
import GetUserToken from "../../utils/GetUserToken";

export const EditPatient = ({
  id,
  infoRoute,
  handleeditClose,
  handleUpdate,
  setIsAlert,
}) => {
  // auth
  const userToken = GetUserToken();
  // translations
  const { t } = useTranslation();
  // route
  const { history } = infoRoute;
  // utils
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  // data
  const [data, setData] = useState([]);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [zip, setZip] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [birth, setBirth] = useState("");
  const [notes, setNotes] = useState("");
  const [gender, setGender] = useState("");
  const [insurance, setGroup] = useState("");
  const [praxis, setBranch] = useState("");
  const [doctor, setDoctor] = useState("");
  const [relationship, setRelation] = useState("");
  const [insuranceOptions, setInsurence] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [relation, setRelationship] = useState([]);

  const genders = [
    { id: "Male", value: t("Genders.Male") },
    { id: "Female", value: t("Genders.Female") },
    { id: "Other", value: t("Genders.Other") },
  ];

  const fetchData = async () => {
    const url =
      process.env.REACT_APP_API_URL + "/patients/getPatientInfo?id=" + id;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    try {
      const res = await fetch(url, requestOptions);
      const json = await res.json();
      setData(json.patient);
      setFirstname(json.patient[0].firstname);
      setLastname(json.patient[0].lastname);
      setEmail(json.patient[0].email);
      setPhone(json.patient[0].phone);
      setAddress(json.patient[0].address);
      setZip(json.patient[0].zip);
      setStreet(json.patient[0].street);
      setCity(json.patient[0].city);
      setState(json.patient[0].state);
      setBirth(json.patient[0].birthday);
      setNotes(json.patient[0].notes);
      setGender(json.patient[0].gender);
      setGroup(json.patient[0].insurance);
      setBranch(json.patient[0].praxis);
      setDoctor(json.patient[0].doctor);
      setRelation(json.patient[0].relationship);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDataDoctors = async () => {
    const url = process.env.REACT_APP_API_URL + "/citations/getDoctors";

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    const res = await fetch(url, requestOptions);
    const json = await res.json();
    const activeDoctors = json.clinicDoctors.filter((obj) => {
      return obj.archived === false;
    });
    setDoctors(activeDoctors);
  };

  const fetchDataPatients = async () => {
    const url = process.env.REACT_APP_API_URL + "/citations/getPatients";

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    const res = await fetch(url, requestOptions);
    const json = await res.json();
    setRelationship(
      json["clinicPatients"].sort((a, b) => (a.lastname > b.lastname ? 1 : -1))
    );
  };

  const fetchDataInsurances = async () => {
    const url = process.env.REACT_APP_API_URL + "/insurances/index";

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    const res = await fetch(url, requestOptions);
    const json = await res.json();
    setInsurence(json["insurances"]);
  };

  useEffect(() => {
    fetchData();
    fetchDataDoctors();
    fetchDataPatients();
    fetchDataInsurances();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deletePatient = async () => {
    setLoading(true);
    try {
      const url = process.env.REACT_APP_API_URL + "/patients/destroy/" + id;

      var requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        redirect: "follow",
      };

      const response = await fetch(url, requestOptions);
      if (response.ok) {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
      handleeditClose();
      setTimeout(() => {
        history.push("/patients");
      }, 1000);
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setLoading(false);
    }
  };

  // Post method
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const patient = {
        firstname,
        lastname,
        email,
        phone,
        address,
        zip,
        street,
        city,
        state,
        birth,
        notes,
        gender,
        insurance,
        praxis,
        relationship,
        doctor,
      };

      const url = process.env.REACT_APP_API_URL + "/patients/update/" + id;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        redirect: "follow",
        body: JSON.stringify(patient),
      };

      const response = await fetch(url, requestOptions);
      // fix back
      /*  if (response.ok) { */
      if (response.status !== 500) {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
      handleeditClose();
      handleUpdate();
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-6">
                <p className="key-text-box">{t("EditPatient.Personal Info")}</p>

                <div className="form-group">
                  <label htmlFor="firstname" className="title-inputs">
                    {t("EditPatient.First Name")}
                  </label>
                  <input
                    required
                    type="text"
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                    className="input-control"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="gender" className="title-inputs">
                    {t("EditPatient.Gender")}
                  </label>
                  <br />
                  <Select
                    required
                    type="text"
                    onChange={(e) => setGender(e.target.value)}
                    style={{ width: "100%" }}
                    value={gender}
                  >
                    {genders.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item["id"]}>
                          {item["value"]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>

                <div className="form-group" style={{ paddingTop: "6px" }}>
                  <label htmlFor="gender" className="title-inputs">
                    {t("EditPatient.Relationship w/")}
                  </label>
                  <br />
                  <Select
                    type="text"
                    onChange={(e) => setRelation(e.target.value)}
                    style={{ width: "100%" }}
                    value={relationship}
                  >
                    {relation.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item["id"]}>
                          {item["lastname"]}, {item["firstname"]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              </div>

              <div className="col-6 mt-4">
                <p className="key-text-box"></p>

                <div className="form-group">
                  <label htmlFor="firstname" className="title-inputs">
                    {t("EditPatient.Last Name")}
                  </label>
                  <input
                    required
                    type="text"
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                    className="input-control"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="birth" className="title-inputs">
                    {t("EditPatient.Birth")}
                  </label>
                  <input
                    required
                    type="date"
                    value={birth}
                    onChange={(e) => setBirth(e.target.value)}
                    className="input-control"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="group" className="title-inputs">
                    {t("EditPatient.Insurance")}
                  </label>
                  <br />
                  <Select
                    required
                    type="text"
                    onChange={(e) => setGroup(e.target.value)}
                    style={{ width: "100%" }}
                    value={insurance}
                  >
                    {insuranceOptions.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item["id"]}>
                          {item["title"]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <Stack sx={{ mt: 1 }}>
                    <Alert
                      severity="warning"
                      style={{ alignItems: "center" }}
                      className="title-inputs"
                    >
                      {t(
                        "EditPatient.Changing the insurance will update to current prices"
                      )}
                    </Alert>
                  </Stack>
                </div>
              </div>
            </div>

            <hr />

            <div className="row mt-3">
              <div className="col-6">
                <p className="key-text-box">{t("EditPatient.Address")}</p>

                <div className="form-group">
                  <label htmlFor="address" className="title-inputs">
                    {t("EditPatient.Country")}
                  </label>
                  <br />
                  <Select
                    required
                    type="text"
                    onChange={(e) => setAddress(e.target.value)}
                    style={{ width: "100%" }}
                    value={address}
                  >
                    {countries
                      .sort((a, b) =>
                        t(`Countries.${a["name"]}`).localeCompare(
                          t(`Countries.${b["name"]}`)
                        )
                      )
                      .map((item, index) => {
                        return (
                          <MenuItem key={index} value={item["name"]}>
                            {t(`Countries.${item["name"]}`)}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </div>

                <div className="form-group">
                  <label htmlFor="address" className="title-inputs">
                    {t("EditPatient.State")}
                  </label>
                  <input
                    type="text"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    className="input-control"
                  />
                </div>
              </div>

              <div className="col-6 mt-3">
                <p className="key-text-box"></p>

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1.5fr 0.5fr",
                  }}
                >
                  <div className="form-group" style={{ paddingRight: "5px" }}>
                    <label htmlFor="address" className="title-inputs">
                      {t("EditPatient.City")}
                    </label>
                    <input
                      required
                      type="text"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      className="input-control"
                    />
                  </div>

                  <div className="form-group" style={{ paddingLeft: "5px" }}>
                    <label htmlFor="address" className="title-inputs">
                      {t("EditPatient.Zip")}
                    </label>
                    <input
                      required
                      type="text"
                      value={zip}
                      onChange={(e) => setZip(e.target.value)}
                      className="input-control"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="address" className="title-inputs">
                    {t("EditPatient.Street")}
                  </label>
                  <input
                    required
                    type="text"
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                    className="input-control"
                  />
                </div>
              </div>
            </div>

            <hr />

            <div className="row mt-5">
              <div className="col-6">
                <p className="key-text-box">{t("EditPatient.Contact")}</p>

                <div className="form-group">
                  <label htmlFor="email" className="title-inputs">
                    {t("EditPatient.Email")}
                  </label>
                  <input
                    required
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="input-control"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="phone" className="title-inputs">
                    {t("EditPatient.Phone")}
                  </label>
                  <input
                    required
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="input-control"
                  />
                </div>
              </div>

              <div className="col-6 mt-5">
                <div className="form-group">
                  <label htmlFor="gender" className="title-inputs">
                    {t("EditPatient.Branch")}
                  </label>
                  <br />
                  <Select
                    type="text"
                    onChange={(e) => setBranch(e.target.value)}
                    style={{ width: "100%" }}
                    value={praxis}
                  >
                    {branches.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item["id"]}>
                          {item["value"]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>

                <div className="form-group">
                  <label htmlFor="doctor" className="title-inputs">
                    {t("EditPatient.Therapist")}
                  </label>
                  <br />
                  <Select
                    type="text"
                    onChange={(e) => setDoctor(e.target.value)}
                    style={{ width: "100%" }}
                    value={doctor}
                  >
                    {doctors.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item["user_id"]}>
                          {item["name"]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="notes" className="title-inputs">
                    {t("EditPatient.Notes")}
                  </label>
                  <TextareaAutosize
                    type="text"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    aria-label="minimum height"
                    minRows={3}
                    className="input-control"
                  />
                </div>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col text-center">
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <CircularProgress color="secondary" />
                  </div>
                ) : (
                  <>
                    <div>
                      <button
                        type="button"
                        className="btn-gray-cancel"
                        onClick={handleClickOpen}
                      >
                        {t("Buttons.Delete")}
                      </button>
                      <button className="btn-gray-save">
                        {t("Buttons.Save")}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </form>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Patient #{id}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Möchten Sie diesen Patienten wirklich eliminieren?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={deletePatient} color="secondary">
                Löschen
              </Button>
              <Button onClick={handleClose} color="primary">
                Zurück
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};
