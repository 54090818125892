import React, { useState } from "react";
// import AddProductButton from "../../../assets/icons/add_product_button.png";
import ProductsAccordion from "./ProductsAccordion";
// import ProductsFromCitationAccordion from "./ProductsFromCitationAccordion";
import { ProductsModal } from "./ProductsModal";
import { useTranslation } from "react-i18next";

export const ProductsPicker = ({
  adittionalProductsFromCitations,
  products,
  adittionalproducts,
  setAdittionalproducts,
  handleRefreshV2,
}) => {
  // translations
  const { t } = useTranslation();
  // utils
  const [show, setShow] = useState(false);

  const onProductChange = (value, status) => {
    setShow(false);
    // onAdittionalProductChange(value, status)
    let currentProducts = [...adittionalproducts];
    currentProducts.push(value);
    setAdittionalproducts(currentProducts);
    handleRefreshV2();
  };

  return (
    <>
      <div className="my-4">
        <h4 className="subtitles-patients">
          {adittionalproducts.length > 0 && t("InvoiceProductPicker.0")}
          &nbsp;&nbsp;
          {/*           <span>
            <img
              src={AddProductButton}
              onClick={() => setShow(!show)}
              className="add-product-button-image"
            />
          </span> */}
        </h4>
      </div>
      {/* {adittionalProductsFromCitations.length > 0 ? <>

                - Adittional products from citations:
                <br />
                <br />

                {adittionalProductsFromCitations.map((item, i) =>
                    <ProductsAccordion
                        handleRefreshV2={handleRefreshV2}
                        insurance={insurance}
                        product={item}
                        index={i}
                        setAdittionalproducts={setAdittionalproducts}
                        adittionalproducts={adittionalproducts}
                    />)
                }

            </> : <></>} */}
      {adittionalproducts.length > 0 ? (
        <>
          {adittionalproducts.map((product, index) => (
            <ProductsAccordion
              handleRefreshV2={handleRefreshV2}
              product={product}
              index={index}
              setAdittionalproducts={setAdittionalproducts}
              adittionalproducts={adittionalproducts}
            />
          ))}
          &nbsp;&nbsp;
        </>
      ) : (
        <></>
      )}

      {/*      <ProductsModal
        show={show}
        setShow={setShow}
        products={products}
        onProductChange={onProductChange}
      /> */}
    </>
  );
};

export default ProductsPicker;
