import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Anchor = styled(Link)`
  height: 8%;
  font-weight: bold;
  font-size: 12px;
  color: #FFFFFF;
  text-align: center;
  background-color: #38baff;
  text-transform: uppercase;
  border-radius: 5px;
  border: 1px solid #38baff;
  padding: 1.05% 30px 1.05% 30px;
  margin-right: 15px;
  text-decoration: none;

  &:hover {
    transition: 0.3s;
    color: #38baff;
    background-color: #FFFFFF;
    text-decoration: none;
    border: 1px solid #38baff;
  }
`
