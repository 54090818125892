import React from 'react'

const GetAuthToken = async () => {

        var url = process.env.REACT_APP_AUTH0_URI + '/oauth/token';

        var raw = {
            "client_id":"3MiywXFIQLsrdDUWT2nKu2jGZTJ60Vts",
            "client_secret":"UCRK-jQyRRCk0G384weHQJPqv3ZGkrJvUsd5OHvw7cf981W6nJfvUHY6UZNIkeCf",
            "audience":"https://tcmassist-users-api",
            "grant_type":"client_credentials"
        }

        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          redirect: "follow",
          body: JSON.stringify(raw),
        };

        const res = await fetch(url, requestOptions);
        const json = await res.json();
        // console.log('res', res);
        // console.log('json', json);
        return json;
    
}

export default GetAuthToken
