import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../../assets/stylesheets/remindersStyles.css";
import Arrow from "../../assets/icons/reminders-arrow.png";
import { EditReminder } from "../reminders/EditReminder";

const RemindersCalendar = ({
  getMonthReminders,
  upcomingreminders,
  loadingbox,
}) => {
  // translations
  const { t } = useTranslation();

  const [editshow, seteditShow] = useState(false);
  const [currentreminder, setCurrentreminder] = useState("");
  // const [reminders, setReminders] = useState(upcomingreminders);
  // const [load, setLoad] = useState(loadingbox);
  // const [date, setDate] = useState([])
  // const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]

  const handleeditClose = () => {
    seteditShow(false);
    getMonthReminders();
  };

  const handleeditShow = () => {
    seteditShow(true);
  };

  const editReminder = (event) => {
    event.preventDefault();
    handleeditShow();
    setCurrentreminder(event.target.id);
  };

  const reminderCard = upcomingreminders.map((item, i) =>
    item[6] === true ? (
      <div key={i}> </div>
    ) : (
      <div key={i}>
        <div className="reminders-calendar-card">
          <div className="row justify-content-start">
            <div className="col-3">
              <div className="reminders-calendar-card-square">
                <div className="reminders-calendar-card-month">{item[2]}</div>
                <div className="reminders-calendar-card-day">{item[3]}</div>
              </div>
            </div>
            <div className="col-9">
              <div className="reminders-calendar-card-title">
                <b id={item[0]} onClick={editReminder}>
                  {item[1]}
                </b>
              </div>
              <div className="reminders-calendar-card-hour">{item[4]}</div>
            </div>
          </div>
        </div>
        <div className="reminders-calendar-separator">
          <hr />
        </div>
      </div>
    )
  );

  return (
    <>
      <div className="reminders-calendar">
        {loadingbox ? (
          <div className="d-flex justify-content-center">
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <>{reminderCard}</>
        )}
        <div className="reminders-calendar-arrow-div">
          <img
            src={Arrow}
            alt="Arrow"
            className="reminders-calendar-arrow-icon"
          />
        </div>
      </div>
      <Modal
        show={editshow}
        onHide={handleeditClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ background: "#F4F5FA" }}>
          <Modal.Title>
            {t("ReminderCalendar.Edit Reminder")} # {currentreminder}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#F4F5FA" }}>
          <EditReminder
            id={currentreminder}
            handleeditClose={handleeditClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RemindersCalendar;
