import React from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

export const PatientSummary = ({
  email,
  phone,
  insurance,
  remark,
  setRemark,
  isOnboarding = false,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="mt-3">
        <h1 className="subtitle-modal">{t("Calendar.Email")}</h1>
        <FormControl style={{ minWidth: "100%" }}>
          <TextField
            id="email"
            type="text"
            name="email"
            size="small"
            value={email}
          />
        </FormControl>
      </div>
      <div className="mt-3">
        <div className="row">
          <div className="col-6">
            <h1 className="subtitle-modal">{t("Calendar.Phone")}</h1>
            <FormControl style={{ minWidth: "100%" }}>
              <TextField
                id="phone"
                type="text"
                name="phone"
                size="small"
                value={phone}
              />
            </FormControl>
          </div>
          <div className="col-6">
            <h1 className="subtitle-modal">{t("Calendar.Insurance")}</h1>
            <FormControl style={{ minWidth: "100%" }}>
              <TextField
                disabled
                id="insurance"
                type="text"
                name="insurance"
                size="small"
                value={insurance}
              />
            </FormControl>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <h1 className="subtitle-modal">{t("Calendar.Remark")}</h1>
        <FormControl style={{ minWidth: "100%" }}>
          <TextareaAutosize
            id="remark"
            type="text"
            name="remark"
            size="small"
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
            minRows={3}
            style={{ background: !isOnboarding ? "#F4F5FA" : "#FFFFFF" }}
          />
        </FormControl>
      </div>
    </>
  );
};
