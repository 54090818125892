import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@mui/material";
import {
  AppBar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { ReactComponent as Invoices } from "../assets/icons/invoices.svg";
// import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import LanguageIcon from "@material-ui/icons/Language";
// import titleLogo from "../assets/icons/Title.png";

const useStyles = makeStyles((theme) => ({
  mainColor: {
    color: "var(--primary-color)",
  },
  nav: {
    backgroundColor: "#f4f5fa",
    boxShadow: "none",
    paddingLeft: "65px",
    "@media(max-width: 600px)": {
      paddingLeft: "0",
      paddingRight: "0",
    },
  },
  toolbar: {
    padding: "20px",
  },
  tcmLogo: {
    paddingLeft: "50px",
    height: "40px",
    "@media(max-width: 600px)": {
      paddingLeft: "0px",
    },
  },
  padding0: {
    paddingLeft: 0,
  },
  margin0: {
    marginBottom: 0,
  },
}));

export default function PrimarySearchAppBar({
  deleteToken,
  history,
  navbarRef,
  isOnboarding = false,
}) {
  // translations
  const { t, i18n } = useTranslation();
  // styles
  const classes = useStyles();
  const isMobileOrTablet = useMediaQuery("(max-width:960px)");
  // redux
  const state = useSelector((state) => state.user);
  const stateCompany = useSelector((state) => state.companyStore.one.data);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [langAnchorEl, setLangAnchorEl] = React.useState(null);

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const isLangOpen = Boolean(langAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLangOpen = (event) => {
    setLangAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleLangClose = () => {
    setLangAnchorEl(null);
    // handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  function handleClick(lang) {
    i18n.changeLanguage(lang);
    handleLangClose();
    isMobileOrTablet && handleMobileMenuClose();
  }

  const handleLogout = () => {
    if (!isOnboarding) {
      history.push("/");
    }
    deleteToken();
  };

  const menuId = "primary-search-account-menu";
  const langId = "primary-lang-menu";

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {!isOnboarding && (
        <>
          <MenuItem id="profile_button" onClick={handleMenuClose}>
            <Link className="navbar-links" to="/user-settings">
              {t("Navbar.Profile")}
            </Link>
          </MenuItem>
          <hr />
        </>
      )}
      <MenuItem onClick={handleMenuClose}>
        <p
          className={`navbar-links ${isOnboarding ? classes.margin0 : ""}`}
          onClick={() => handleLogout()}
        >
          {t("Navbar.SignOut")}
        </p>
      </MenuItem>
    </Menu>
  );

  const languagePicker = (
    <Menu
      //visible={false}
      anchorEl={langAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={langId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isLangOpen}
      onClose={handleLangClose}
    >
      <MenuItem onClick={() => handleClick("en")}>English</MenuItem>
      <MenuItem onClick={() => handleClick("es")}>Español</MenuItem>
      <MenuItem onClick={() => handleClick("de")}>Deutsch</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
        <IconButton
          className={classes.mainColor}
          size="medium"
          aria-label="show 0 new notifications"
          color="inherit"
        >
          <Badge badgeContent={0} color="error" overlap="rectangular">
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </MenuItem> */}

      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          className={classes.mainColor}
          size="medium"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
      </MenuItem>

      {!isOnboarding && (
        <MenuItem>
          <IconButton
            className={classes.mainColor}
            style={{ padding: "0px", marginLeft: "4px" }}
            size="medium"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <Link to="/invoices" onClick={handleMobileMenuClose}>
              <Invoices className="icon" />
            </Link>
          </IconButton>
        </MenuItem>
      )}

      <MenuItem onClick={handleLangOpen}>
        <IconButton
          className={classes.mainColor}
          size="medium"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <LanguageIcon />
        </IconButton>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        className={`${classes.nav} ${isOnboarding ? classes.padding0 : ""}`}
        ref={navbarRef}
      >
        <Toolbar className={classes.toolbar}>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            <Link to="/">
              <div>
                <img
                  src={
                    stateCompany?.logo?.url ||
                    "https://www.tcmassist.org/wp-content/uploads/2022/08/tcm-assist-logo.png"
                  }
                  alt="logo"
                  className={`${classes.tcmLogo} ${
                    isOnboarding ? classes.padding0 : ""
                  }`}
                />
              </div>
            </Link>
          </Typography>

          {state.auth?.user_id ? (
            <>
              <Box sx={{ flexGrow: 2 }} />

              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <IconButton
                  className={classes.mainColor}
                  size="medium"
                  // edge="end"
                  aria-label="account of current user"
                  aria-controls={langId}
                  aria-haspopup="true"
                  onClick={handleLangOpen}
                  color="inherit"
                  id="language_button"
                >
                  <LanguageIcon />
                </IconButton>

                {/* <IconButton
                  className={classes.mainColor}
                  size="medium"
                  aria-label="show 0 new notifications"
                  color="inherit"
                  id="notifications_button"
                >
                  <Badge badgeContent={0} color="error" overlap="rectangular">
                  <NotificationsIcon />
                  </Badge>
                </IconButton> */}

                <IconButton
                  className={classes.mainColor}
                  size="medium"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </Box>

              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  className={classes.mainColor}
                  size="medium"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
            </>
          ) : (
            <>{/* links not logged users here */}</>
          )}
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {languagePicker}
    </Box>
  );
}
