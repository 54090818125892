import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Cards from "./homepageViews/homepageCards";
import Footer from "./homepageViews/footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/stylesheets/homepage.css";
import HomeReminders from "./homepageViews/homepageReminders";
import ButtonGroupComponent from "./reusableComponents/ButtonGroup";
// Role Chip
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@mui/material";
import Popup from "./reusableComponents/Popup";
import Onboarding from "./onboarding/Onboarding";
import fetchApi from "../utils/fetchApi";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

const Home = ({ userId, deleteToken }) => {
  // translations
  const { t, i18n } = useTranslation();
  // redux
  const state = useSelector((state) => state.user);
  // styles
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:700px)");
  // reminders
  const [updateReminders, setUpdateReminders] = useState(false);
  // utils
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [isPopup, setIsPopup] = useState({
    open: false,
    body: "",
    fullScreen: false,
  });
  // data
  const [onboarding, setOnboarding] = useState(null);

  const fetchUserData = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL_V2}/users/show/${userId}`;
      const { data } = await fetchApi(url, "GET");

      if (data.onboarding) {
        const onboarding = JSON.parse(data.onboarding);
        const isFirstLogin = onboarding.find(
          (item) => "is_first_login" in item
        )?.is_first_login;
        setOnboarding(onboarding);
        setIsFirstLogin(isFirstLogin);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (state.auth.language) {
      switch (state.auth.language) {
        case "EN":
          i18n.changeLanguage("en");
          break;
        case "ES":
          i18n.changeLanguage("es");
          break;
        default:
          i18n.changeLanguage("de");
          break;
      }
    }
    fetchUserData();
  }, []);

  useEffect(() => {
    if (isFirstLogin) {
      if (!isMobile) {
        setIsPopup({
          open: true,
          body: {
            content: (
              <Onboarding
                userId={userId}
                onboarding={onboarding}
                setOnboarding={setOnboarding}
                setIsPopup={setIsPopup}
                handleLogout={deleteToken}
              />
            ),
          },
          fullScreen: true,
        });
      }
      if (isMobile) {
        setIsPopup({
          open: false,
        });
      }
    }
  }, [isFirstLogin, isMobile]);

  const getUserRole = () => {
    if (state.auth.admin) {
      return "Admin";
    }
    if (state.auth.doctor) {
      return "Therapist";
    }
    if (state.auth.secretary) {
      return "Secretary";
    }
  };

  return (
    <>
      {/* Reminder and Support */}
      <ButtonGroupComponent
        route="home"
        visible
        setUpdateReminders={setUpdateReminders}
      />

      <div className="frame">
        <h4 className="main-title mt-2">
          {state.auth ? (
            <div className={classes.root}>
              {t("Home.0")}, {state.auth?.fullname}
              &nbsp;
              <Chip
                icon={<FaceIcon />}
                label={getUserRole()}
                clickable
                color="primary"
              />
            </div>
          ) : (
            <></>
          )}
        </h4>

        {state.auth?.doctor && state.doctor?.id ? (
          <Cards id={userId} />
        ) : (
          <>{/* <p>{t('Home.1')}</p> */}</>
        )}
        {/* 
        {state.auth.doctor && <span>{t('Home.3')}, </span>}
        {state.auth.secretary && <span>{t('Home.4')}, </span>}
        {state.auth.admin && <span>{t('Home.5')}</span>} */}

        <h3 className="home-reminders-title">{t("Home.2")}</h3>

        <div className="home-reminders-big-box">
          <HomeReminders updateReminders={updateReminders} />
        </div>
      </div>
      <Footer />

      <Popup
        isOpen={isPopup.open}
        fullScreen={isPopup.fullScreen}
        body={isPopup.body}
      />
    </>
  );
};

export default Home;
