import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import FieldLoader from "../FieldLoader";
import { connect, useDispatch } from "react-redux";
import {
  createOneBankAccount,
  getOneBankAccount,
  updateBankAccount,
} from "../../../../redux/slices/bank-account";
import BackdropLoading from "../../../../components/reusableComponents/BackdropLoading";
import Alert from "../../../../components/alerts/Alert";

const keys = {
  iban: "",
  bic: "",
  description: "",
};

const Component = ({
  company,
  companyLoading,
  bankAccount,
  bankAccountLoading,
  bankAccountError,
  bankAccountSuccess,
  bankAccountUpdate,
  bankAccountUpdateLoading,
  bankAccountUpdateError,
  bankAccountUpdateSuccess,
}) => {
  // translations
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  // data
  const [initialValues, setInitialValues] = useState(keys);
  const [values, setValues] = useState();
  // utils
  const [errors, setErrors] = useState({});
  const [isAlert, setIsAlert] = useState({
    open: false,
  });

  const handleChange = (event) => {
    if (!event.target) {
      console.error("Event was not found.");
    }

    const { name, value, type, required } = event.target;

    setValues((prevValues) => {
      const newValues = { ...prevValues };

      if (value === "") {
        delete newValues[name];
      } else {
        newValues[name] = value;
      }

      return newValues;
    });

    // Validate the required field
    if (type === "text" && value.trim() === "" && required) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "This field is required",
      }));
    } else {
      // Clear the error if the field is not empty
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!bankAccount) {
      dispatch(
        createOneBankAccount({
          company_id: company?.id,
          enabled: true,
          ...values,
        })
      );
    } else {
      dispatch(
        updateBankAccount({
          id: bankAccount?.id,
          company_id: company?.id,
          enabled: true,
          ...values,
        })
      );
    }

    setIsAlert({
      open: true,
    });
  };

  useEffect(() => {
    let initialValues = keys;

    if (!!bankAccount) {
      for (const key of Object.keys(keys)) {
        initialValues[key] = bankAccount[key];
      }
    }

    setInitialValues(initialValues);
    setValues(initialValues);
  }, [bankAccount]);

  useEffect(() => {
    if (company) {
      dispatch(getOneBankAccount({ id: company?.id }));
    }
  }, [company]);

  return (
    <>
      <h4 className="main-title-treatments">
        {t("FormBankAccount.Company Bank account")}
      </h4>
      <Form noValidate id="edit-bank-account-form" onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>IBAN</Form.Label>
          <FieldLoader loading={bankAccountLoading}>
            <Form.Control
              type="text"
              name="iban"
              defaultValue={initialValues["iban"]}
              onInput={handleChange}
              disabled={bankAccountUpdateLoading}
            />
          </FieldLoader>
          {!!errors["iban"] && (
            <Form.Control.Feedback type="invalid">
              {t("Messages.Required field")}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>BIC</Form.Label>
          <FieldLoader loading={bankAccountLoading}>
            <Form.Control
              required
              type="text"
              name="bic"
              defaultValue={initialValues["bic"]}
              onInput={handleChange}
              disabled={bankAccountUpdateLoading}
            />
          </FieldLoader>
          {!!errors["bic"] && (
            <Form.Control.Feedback type="invalid">
              {t("Messages.Required field")}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>{t("FormBankAccount.Bank Name")}</Form.Label>
          <FieldLoader loading={bankAccountLoading}>
            <Form.Control
              required={false}
              type="text"
              name="description"
              defaultValue={initialValues["description"]}
              onInput={handleChange}
              disabled={bankAccountUpdateLoading}
            />
          </FieldLoader>
          {!!errors["description"] && (
            <Form.Control.Feedback type="invalid">
              {t("Messages.Required field")}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Form>

      <div>
        <FieldLoader loader={bankAccountUpdateLoading}>
          <Button
            className="btnGo"
            form="edit-bank-account-form"
            type="submit"
            target={false}
            disabled={bankAccountUpdateLoading}
          >
            {t("FormBankAccount.Update information")}
          </Button>
        </FieldLoader>
      </div>

      <BackdropLoading isOpen={bankAccountUpdateLoading} />

      {isAlert.open && bankAccountUpdateSuccess && (
        <Alert
          isAlert={isAlert.open && bankAccountUpdateSuccess}
          setIsAlert={setIsAlert}
          severity="success"
          message={t("Messages.The operation was successful")}
        />
      )}
      {isAlert.open && bankAccountUpdateError && (
        <Alert
          isAlert={isAlert.open && bankAccountUpdateError}
          setIsAlert={setIsAlert}
          severity="error"
          message={t("Messages.Something went wrong, please try again")}
        />
      )}
    </>
  );
};

const state = ({ companyStore, bankAccountStore }) => {
  const { data: company, loading: companyLoading } = companyStore.one;
  const {
    data: bankAccount,
    loading: bankAccountLoading,
    error: bankAccountError,
    success: bankAccountSuccess,
  } = bankAccountStore.one;
  const {
    data: bankAccountUpdate,
    loading: bankAccountUpdateLoading,
    error: bankAccountUpdateError,
    success: bankAccountUpdateSuccess,
  } = bankAccountStore.update;

  return {
    company,
    companyLoading,
    bankAccount,
    bankAccountLoading,
    bankAccountError,
    bankAccountSuccess,
    bankAccountUpdate,
    bankAccountUpdateLoading,
    bankAccountUpdateError,
    bankAccountUpdateSuccess,
  };
};

export default connect(state)(Component);
