import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import services from "../../services";

// Define initial state
const initialState = {
  one: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  all: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  allbycompany: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  allbytreatment: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  create: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  update: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
};

// Async action to get one doctor
const getOneDoctor = createAsyncThunk("doctor/getOne", async (payload) => {
  console.log("get one - doctor - action", payload);
  const { response, error } = await services.doctor.getOne(payload);
  if (error) {
    throw new Error(error);
  }
  return response;
});

// Async action to get all doctors
const getAllDoctors = createAsyncThunk("doctor/getAll", async () => {
  console.log("get all - doctor - action");
  const { response, error } = await services.doctor.getAll();
  if (error) {
    throw new Error(error);
  }
  return response;
});

// Async action to get all doctors by company
const getDoctorsByCompany = createAsyncThunk(
  "doctor/getAllByCompany",
  async (payload) => {
    console.log("get all by company - doctor - action");
    const { response, error } = await services.doctor.getAllByCompany(payload);
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Async action to get all doctors by treatment
const getDoctorsByTreatment = createAsyncThunk(
  "doctor/getAllByTreatment",
  async (payload) => {
    console.log("get all by treatment - doctor - action", payload);
    const { response, error } = await services.doctor.getAllByTreatment(
      payload
    );
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Async action to create one doctor
const createOneDoctor = createAsyncThunk(
  "doctor/createOne",
  async ({ payload }) => {
    console.log("create one - doctor - action", payload);
    const { response, error } = await services.doctor.createOne(payload);
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Async action to update a doctor
const updateDoctor = createAsyncThunk("doctor/update", async (payload) => {
  console.log("update - doctor - action", payload);
  const { response, error } = await services.doctor.update(payload);
  if (error) {
    throw new Error(error);
  }
  return response;
});

// Create a doctor slice
const doctorSlice = createSlice({
  name: "doctor",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOneDoctor.pending, (state, action) => {
        state.one.data = null;
        state.one.loading = true;
        state.one.success = false;
        state.one.error = false;
      })
      .addCase(getOneDoctor.fulfilled, (state, action) => {
        state.one.data = action.payload;
        state.one.loading = false;
        state.one.success = true;
        state.one.error = false;
      })
      .addCase(getOneDoctor.rejected, (state, action) => {
        state.one.data = null;
        state.one.loading = false;
        state.one.success = false;
        state.one.error = action.error.message;
      })
      .addCase(getAllDoctors.pending, (state) => {
        state.all.data = null;
        state.all.loading = true;
        state.all.success = false;
        state.all.error = false;
      })
      .addCase(getAllDoctors.fulfilled, (state, action) => {
        state.all.data = action.payload;
        state.all.loading = false;
        state.all.success = true;
        state.all.error = false;
      })
      .addCase(getAllDoctors.rejected, (state, action) => {
        state.all.data = null;
        state.all.loading = false;
        state.all.success = false;
        state.all.error = action.error.message;
      })
      .addCase(getDoctorsByCompany.pending, (state) => {
        state.allbycompany.data = null;
        state.allbycompany.loading = true;
        state.allbycompany.success = false;
        state.allbycompany.error = false;
      })
      .addCase(getDoctorsByCompany.fulfilled, (state, action) => {
        state.allbycompany.data = action.payload;
        state.allbycompany.loading = false;
        state.allbycompany.success = true;
        state.allbycompany.error = false;
      })
      .addCase(getDoctorsByCompany.rejected, (state, action) => {
        state.allbycompany.data = null;
        state.allbycompany.loading = false;
        state.allbycompany.success = false;
        state.allbycompany.error = action.error.message;
      })
      .addCase(getDoctorsByTreatment.pending, (state) => {
        state.allbytreatment.data = null;
        state.allbytreatment.loading = true;
        state.allbytreatment.success = false;
        state.allbytreatment.error = false;
      })
      .addCase(getDoctorsByTreatment.fulfilled, (state, action) => {
        state.allbytreatment.data = action.payload;
        state.allbytreatment.loading = false;
        state.allbytreatment.success = true;
        state.allbytreatment.error = false;
      })
      .addCase(getDoctorsByTreatment.rejected, (state, action) => {
        state.allbytreatment.data = null;
        state.allbytreatment.loading = false;
        state.allbytreatment.success = false;
        state.allbytreatment.error = action.error.message;
      })
      .addCase(updateDoctor.pending, (state) => {
        state.update.data = null;
        state.update.loading = true;
        state.update.success = false;
        state.update.error = false;
      })
      .addCase(updateDoctor.fulfilled, (state, action) => {
        state.update.data = action.payload;
        state.update.loading = false;
        state.update.success = true;
        state.update.error = false;
        state.one.data = action.payload;
      })
      .addCase(updateDoctor.rejected, (state, action) => {
        state.update.data = null;
        state.update.loading = false;
        state.update.success = false;
        state.update.error = action.error.message;
      });
  },
});

// Export the async actions and the reducer
export {
  getOneDoctor,
  getAllDoctors,
  getDoctorsByCompany,
  getDoctorsByTreatment,
  createOneDoctor,
  updateDoctor,
};

export default doctorSlice.reducer;
