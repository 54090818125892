import React, { useMemo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { ReactComponent as Pencil } from "../../assets/icons/pencil.svg";
import GetUserToken from "../../utils/GetUserToken";
import { TrackableLink } from "../../utils/helper";
import Table from "./ProductsTable";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const ProductsList = () => {
  // translations
  const { t } = useTranslation();
  let currentLanguage = localStorage.getItem("i18nextLng");
  // styles
  const classes = useStyles();
  // utils
  const [loading, setLoading] = useState(true);
  // data
  const [data, setData] = useState();

  const fetchData = async () => {
    const url =
      process.env.REACT_APP_API_URL_V2 + "/treatments/get_base_products";
    const userToken = GetUserToken();

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    const res = await fetch(url, requestOptions);
    const json = await res.json();
    json.sort((a, b) => a.rate - b.rate);
    setData(json);
    setLoading(false);
  };

  const columns = useMemo(
    () => [
      {
        // first group
        Header: " ",
        // First group columns
        columns: [
          {
            Header: "ID",
            accessor: "id",
          },
          {
            Header: <>{t("ProductList.0")}</>,
            accessor: "archived",
            Cell: ({ row }) => (
              <>
                {row.original.archived ? (
                  <>{t("ProductList.1")}</>
                ) : (
                  <>{t("ProductList.2")}</>
                )}
              </>
            ),
          },
          {
            Header: <>{t("ProductList.3")}</>,
            accessor: "rate",
          },
          {
            Header: <>{t("ProductList.4")}</>,
            accessor: "title",
          },
          {
            Header: " ",
            accessor: " ",
            Cell: ({ row }) => (
              <Link
                to={{ pathname: `/edit-product/${row.original.id}` }}
                id="edit_product"
                onClick={() => TrackableLink("edit_product")}
              >
                <Pencil className="icon" />
              </Link>
            ),
          },
        ],
      },
    ],
    [currentLanguage]
  );

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="frame">
        <h4 className="main-title mt-2">{t("ProductList.5")}</h4>
        <br />

        <div style={{ marginRight: "50px" }}>
          <div className="container-fluid treatments-table">
            {loading ? <></> : <Table columns={columns} data={data} />}
          </div>
        </div>
      </div>

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ProductsList;
