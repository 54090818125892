import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const Counter = ({
  findings,
  timer,
  setTimer,
  onSubmit,
  setSuccess,
}) => {
  const { t } = useTranslation();

  //const [timer, setTimer] = useState(15);
  const id = React.useRef(null);

  const clear = () => {
    window.clearInterval(id.current);
  };

  useEffect(() => {
    id.current = window.setInterval(() => {
      setTimer((time) => time - 1);
    }, 1000);
    return () => clear();
  }, [findings]);

  useEffect(() => {
    if (timer === 0) {
      clear();
      console.log("coundown end!");
      onSubmit();
      if (setSuccess) {
        setSuccess(true);
      }
      window.location.reload();
    }
  }, [timer]);

  return (
    <>
      <span>
        {timer == 0 ? (
          <>
            <span className="appointment-status-saving">
              &nbsp;{t("Counter.Saving")}...&nbsp;
            </span>
          </>
        ) : timer > 10 ? (
          <>
            <span className="appointment-status-unsaved">
              &nbsp;{t("Counter.Unsaved, editing")}.&nbsp;
            </span>
          </>
        ) : (
          <>
            <span className="appointment-status-unsaved">
              &nbsp;{t("Counter.Auto-saving in")} {timer} {t("Counter.sec")}
              .&nbsp;
            </span>
          </>
        )}
      </span>
    </>
  );
};
