import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import NavbarPublic from "../../NavbarPublic";
import WelcomePage from "./steps/WelcomePage";
import FirstStep from "./steps/FirstStep";
import SecondStep from "./steps/SecondStepNewPatient";
import ThirdStep from "./steps/ThirdStep";
import FourthStep from "./steps/FourthStepNewPatient";
import ButtonScrollToTop from "../../reusableComponents/ButtonScrollToTop";
import AlertComponent from "../../alerts/Alert";
import { getFirstTreatments } from "../../../redux/slices/treatment";
import {
  getDoctorsByCompany,
  getDoctorsByTreatment,
} from "../../../redux/slices/doctor";
import { getInsurancesByCompany } from "../../../redux/slices/insurance";
import { getTime, disableWeekends } from "../../../helpers/time";
import fetchApi from "../../../utils/fetchApi";
import "../../../assets/stylesheets/multistepCitation.css";

const NewPatientBooking = ({
  navbarRef,
  company,
  treatments,
  therapists,
  therapistsbytreatment,
  isLoadingTreatments,
  isLoadingDoctors,
  insurancesByCompany,
}) => {
  // redux
  const dispatch = useDispatch();
  // utils
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const [currentTime, setCurrentTime] = useState("");
  const [days, setDays] = useState({
    dayBefore: [],
    dayCurrent: [],
    dayAfter: [],
  });
  // data
  const [accepted, setAccepted] = useState({
    company_terms_and_conditions: false,
    our_terms_and_conditions: false,
  });
  const [treatment, setTreatment] = useState("");
  const [doctor, setDoctor] = useState("");
  const [dates, setDates] = useState({ date: "", dateSelector: "" });
  const [time, setTime] = useState("");
  const [clinicTreatments, setClinicTreatments] = useState([]);
  const [clinicDoctors, setClinicDoctors] = useState([]);
  const [insurances, setInsurances] = useState([]);
  const [availableDoctors, setAvailableDoctors] = useState([]);
  const [citationSummary, setCitationSummary] = useState([]);
  const [patientData, setPatientData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    passwordConfirmation: "",
    phone: "",
    address: "",
    birth: "",
    insurance: "",
    city: "",
    zip: "",
    street: "",
    state: "",
    number: "",
  });

  const dispatchAction = (id) => {
    dispatch(getFirstTreatments({ id }));
    dispatch(getDoctorsByCompany({ id }));
    dispatch(getInsurancesByCompany({ id }));
  };

  useEffect(() => {
    if (company?.id) {
      const id = company?.id;
      dispatchAction(id);
      setCurrentTime(getTime());
    }
  }, [company]);

  useEffect(() => {
    if (treatments) {
      let activeTreatments = treatments.filter((item) => !item.archived);
      setClinicTreatments(
        activeTreatments.sort((a, b) => a.name.localeCompare(b.name))
      );
    }
  }, [treatments]);

  useEffect(() => {
    if (therapists) {
      let activeDoctors = therapists.filter((item) => !item.archived);
      setClinicDoctors(activeDoctors);
    }
  }, [therapists]);

  useEffect(() => {
    if (therapistsbytreatment) {
      let activeDoctors = therapistsbytreatment.filter(
        (item) => !item.archived
      );
      setAvailableDoctors(activeDoctors);
    }
  }, [therapistsbytreatment]);

  useEffect(() => {
    if (insurancesByCompany) {
      setInsurances(insurancesByCompany);
    }
  }, [insurancesByCompany]);

  const handleAccepted = (value) => {
    if (value === "company") {
      setAccepted((prevState) => ({
        ...prevState,
        company_terms_and_conditions: !accepted.company_terms_and_conditions,
      }));
    } else if (value === "our") {
      setAccepted((prevState) => ({
        ...prevState,
        our_terms_and_conditions: !accepted.our_terms_and_conditions,
      }));
    }
  };

  // MultiStep Functions
  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };
  // end MultiStep Functions

  const resetValues = () => {
    setDays((prevState) => ({
      ...prevState,
      dayCurrent: [],
    }));
    setDates((prevState) => ({
      ...prevState,
      date: "",
      dateSelector: "",
    }));
    setTime("");
  };

  const handleTreatmentChange = (event) => {
    setTreatment(event.target.value);
    setDoctor("");
    resetValues();
    dispatch(
      getDoctorsByTreatment({ treatment: event.target.value.toString() })
    );
  };

  const handleDoctorChange = (event) => {
    if (!doctor) {
      setDoctor(event.target.value);
    } else {
      setDoctor(event.target.value);
      resetValues();
    }
  };

  const handleDateChange = (event) => {
    const completeDate = event.toISOString().split("T")[0];
    setDates((prevState) => ({
      ...prevState,
      dateSelector: event,
      date: completeDate,
    }));
    setTime(""); // reset time value
    onDateSelect(completeDate, doctor);
  };

  const onDateSelect = async (value, doctor) => {
    setIsLoading(true);
    try {
      const url = `
        ${process.env.REACT_APP_API_URL}/citations/schedule_available_slots`;

      const body = JSON.stringify({
        date: value,
        doctor,
        treatment,
      });

      const { data } = await fetchApi(url, "POST", body, false);

      setDays((prevState) => ({
        ...prevState,
        dayCurrent: data.data,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTimeChange = (time) => {
    setTime(time);
  };

  const handlePatientDataChange = (event) => {
    setPatientData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const url = process.env.REACT_APP_API_URL + "/citations/userfirstcreate";

      const {
        firstname,
        lastname,
        email,
        password,
        phone,
        address,
        birth,
        insurance,
        city,
        zip,
        street,
        number,
      } = patientData;

      const body = {
        firstname,
        lastname,
        date: dates.date,
        time,
        treatment,
        doctor,
        email,
        phone,
        address,
        birth,
        insurance,
        city,
        zip,
        street,
        number,
        password,
        company_id: company.id,
      };

      const { data, ok } = await fetchApi(
        url,
        "POST",
        JSON.stringify(body),
        false
      );

      if (ok) {
        setCitationSummary(data.citation);
        setStep(4);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const scrollToNavbar = () => {
    if (navbarRef && navbarRef.current) {
      navbarRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <NavbarPublic isPadding navbarRef={navbarRef} />

      {step === 0 && (
        <WelcomePage
          loading={isLoading}
          nextStep={nextStep}
          accepted={accepted}
          setAccepted={handleAccepted}
        />
      )}
      {step === 1 && (
        <FirstStep
          isNewPatientBooking={true}
          treatment={treatment}
          doctor={doctor}
          dateSelector={dates.dateSelector}
          dayCurrent={days.dayCurrent}
          currentTime={currentTime}
          timeSelected={time}
          disableWeekends={disableWeekends}
          handleTreatmentChange={handleTreatmentChange}
          handleDoctorChange={handleDoctorChange}
          handleDateChange={handleDateChange}
          handleTimeChange={handleTimeChange}
          clinicTreatments={clinicTreatments}
          availableDoctors={availableDoctors}
          loading={isLoading}
          loadingTreatments={isLoadingTreatments}
          loadingDoctors={isLoadingDoctors}
          nextStep={nextStep}
          scrollToNavbar={scrollToNavbar}
        />
      )}
      {step === 2 && (
        <SecondStep
          patientData={patientData}
          insurances={insurances}
          onChange={handlePatientDataChange}
          prevStep={prevStep}
          nextStep={nextStep}
          scrollToNavbar={scrollToNavbar}
        />
      )}
      {step === 3 && (
        <ThirdStep
          date={dates.date}
          time={time}
          doctors={clinicDoctors}
          doctor={doctor}
          onSubmit={onSubmit}
          prevStep={prevStep}
          loading={isLoading}
          // getValueCheckbox={getValueCheckbox}
          handleClick={scrollToNavbar}
        />
      )}
      {step === 4 && (
        <div className="container-NeueTermin">
          <FourthStep citation={citationSummary} company={company} />

          <div style={{ paddingRight: "12px" }}>
            <ButtonScrollToTop
              isNeueTermin={true}
              handleClick={scrollToNavbar}
            />
          </div>
        </div>
      )}

      {isAlert.open && (
        <AlertComponent
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
    </>
  );
};

const stateRedux = ({
  companyStore,
  treatmentStore,
  doctorStore,
  insuranceStore,
}) => {
  const { data: company } = companyStore.one;
  const { data: firstTreatments, loading: isLoadingTreatments } =
    treatmentStore.first;
  const { data: therapists } = doctorStore.allbycompany;
  const { data: therapistsbytreatment, loading: isLoadingDoctors } =
    doctorStore.allbytreatment;
  const { data: insurancesByCompany } = insuranceStore.allbycompany;

  return {
    company,
    treatments: firstTreatments?.treatments,
    therapists: therapists?.clinicDoctors,
    therapistsbytreatment: therapistsbytreatment?.clinicDoctors,
    isLoadingTreatments,
    isLoadingDoctors,
    insurancesByCompany: insurancesByCompany?.insurances,
  };
};

export default connect(stateRedux)(NewPatientBooking);
