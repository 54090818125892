import React, { useEffect, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
// import LocalOfferIcon from "@material-ui/icons/LocalOffer";
// import DoneAllIcon from "@material-ui/icons/DoneAll";
// import EditIcon from "@material-ui/icons/Edit";

const InsuranceField = ({
  item,
  prices,
  handlePriceChange,
  isPriceUpdating,
}) => {
  const [price, setPrice] = useState(0);
  const [oldPrice, setOldPrice] = useState(0);

  const [priceModified, setPriceModified] = useState(false);

  const getInsurancePrice = (insurance) => {
    let filtered = prices.filter((p) => p.insurance_id === insurance);
    if (filtered.length > 0) {
      return [filtered[0].price, filtered[0].is_modified_price];
    } else {
      return [0, false];
    }
  };

  const handleChange = (e) => {
    setPrice(e.target.value);
  };

  const handleOnFieldBlur = (e) => {
    if (oldPrice !== +e.target.value) {
      handlePriceChange(item.id, e.target.value);
    }
  };

  useEffect(() => {
    if (prices.length !== 0) {
      const priceInfo = getInsurancePrice(item.id);
      setPriceModified(priceInfo[1]);
      setOldPrice(priceInfo[0]);
      setPrice(priceInfo[0]);
    }
  }, [prices]);

  return (
    <>
      <label className="title-inputs">
        {item.title}
        &nbsp; (&euro;)
      </label>

      <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
        <Tooltip
          placement="right"
          title={
            priceModified
              ? "This price is modified by the user. It wont change automatically if the base prices are modified."
              : "This price is the sum of the subproducts corresponding to this bundle"
          }
        >
          {isPriceUpdating.insurance === item.id ? (
            <input
              required
              type="number"
              min="0"
              value={Number(Number(price).toFixed(2))}
              onChange={handleChange}
              onBlur={(e) => handleOnFieldBlur(e)}
              className="input-control"
              disabled={true}
              style={{
                backgroundColor: "#F4F5FA",
                width: "93%",
                marginRight: ".8em",
              }}
            />
          ) : (
            <input
              required
              type="number"
              min="0"
              value={Number(Number(price).toFixed(2))}
              onChange={handleChange}
              onBlur={(e) => handleOnFieldBlur(e)}
              className="input-control"
              disabled={true}
              style={{
                border: priceModified ? "1px solid #38baff" : "",
                width: "93%",
                marginRight: ".8em",
              }}
            />
          )}
        </Tooltip>
        {/* {priceModified && (
          <Tooltip
            placement="right"
            title={
              priceModified
                ? "This price is modified by the user. It wont change automatically if the base prices are modified."
                : "This price is the sum of the subproducts corresponding to this bundle"
            }
          >
            <EditIcon
              style={{
                fontSize: "1.2em",
                color: "#38baff",
              }}
            />
          </Tooltip>
        )} */}
      </div>
    </>
  );
};

export default InsuranceField;
