import React, { useMemo, useState, useEffect, useCallback } from "react";
import Alert from "react-bootstrap/Alert";
import { useTable, useFilters, useSortBy, useGlobalFilter } from "react-table";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem } from "@material-ui/core";
import MenuIcon from "../../../../../reusableComponents/MenuIcon";
import "../../../../../../assets/stylesheets/patients.css";
import { connect, useDispatch } from "react-redux";
import {
  downloadPrescription,
  resetDownloadPrescription,
  duplicatePrescription,
} from "../../../../../../redux/slices/prescription";
import { TbFileDownload } from "react-icons/tb";
import { Button } from "react-bootstrap";

const Component = ({
  id,
  prescription,
  duplicateLoading,
  duplicateError,
  duplicateSuccess,
  prescriptions,
  downloadLoading,
  downloadError,
  downloadSuccess,
}) => {
  let currentLanguage = localStorage.getItem("i18nextLng");

  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [filterInput, setFilterInput] = useState("");
  const [idPrescription, setIdPrescription] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const hideAlert = () => {
    dispatch(resetDownloadPrescription());
  };

  const showAlert = useMemo(() => {
    console.log(
      "showAlert",
      !!(downloadLoading || downloadError || downloadSuccess)
    );
    return !!(downloadLoading || downloadError || downloadSuccess);
  }, [downloadLoading, downloadError, downloadSuccess]);

  const alertVariant = useMemo(() => {
    if (downloadError) {
      return "danger";
    } else if (downloadSuccess) {
      return "success";
    } else {
      return "light";
    }
  }, [downloadLoading, downloadError, downloadSuccess]);

  const openMenu = (event, item) => {
    setAnchorEl(event.currentTarget);
    setIdPrescription(item.id);
  };

  useEffect(() => {
    console.log();
    if (duplicateSuccess && !!prescription) {
      //const duplicatedPrescriptionUrl = `/prescription/${prescription?.id}?citation_id=${id}`;
      // window.location.href = duplicatedPrescriptionUrl;
      window.location.reload();
    }
  }, [prescription, duplicateSuccess]);

  const columns = useMemo(
    () => [
      {
        Header: " ",
        columns: [
          {
            Header: <>{t("PatientPrescriptions.Status")}</>,
            accessor: "state",
            Cell: (e) =>
              e.value === "issued" ? (
                <>{t("PrescriptionFormSelectors.State issued")}</>
              ) : e.value === "draft" ? (
                <>{t("PrescriptionFormSelectors.State draft")}</>
              ) : (
                <>
                  <span style={{ color: "#857c8e", fontStyle: "italic" }}>
                    {t("PrescriptionFormSelectors.State cancelled")}
                  </span>
                </>
              ),
          },
          {
            Header: <>{t("PatientPrescriptions.Date")}</>,
            accessor: "created_at",
            sortType: "basic",
            Cell: (e) => e.value.replace("T", "").slice(0, -13),
          },
          {
            Header: <>{t("PatientPrescriptions.Prescription No.")}</>,
            accessor: "prescription_id",
          },
          {
            Header: <>{t("PatientPrescriptions.Therapist")}</>,
            accessor: "doctor.name",
          },
          {
            Header: " ",
            accessor: "id",
            Cell: ({ row }) => (
              <div style={{ paddingRight: "10px" }}>
                <MenuIcon row={row} openMenu={openMenu} />
              </div>
            ),
          },
        ],
      },
    ],
    [currentLanguage]
  );

  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    setFilter,
    setGlobalFilter, // The useFilter Hook provides a way to set the filter
    rows, // rows OR PAGE for the table based on the data passed
    state: { globalFilter },
  } = useTable(
    {
      columns,
      data: prescriptions,
      initialState: {
        sortBy: [{ id: "created_at", desc: true }],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  React.useEffect(() => {}, [globalFilter]);

  return (
    <>
      <div
        style={{
          width: "95%",
          background: "white",
          paddingTop: "10px",
          scrollPaddingBottom: "10px",
          borderRadius: "8px",
        }}
      >
        <div style={{ paddingLeft: "5px" }}>
          <input
            className="search-field"
            type="text"
            value={globalFilter || ""}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder={t("ProducTable.0")}
          />
        </div>

        <table
          {...getTableProps()}
          className="table table-borderless table-striped"
        >
          <thead>
            {headerGroups.map((headerGroup, headerGroupIndex) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                key={`table-thead-tr-${headerGroupIndex}`}
              >
                {headerGroup.headers.map((column, headerThIndex) => (
                  <th
                    key={`table-thead-th-${headerThIndex}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row, rowsIndex) => {
              prepareRow(row);

              return (
                <tr {...row.getRowProps()} key={`table-tbody-tr-${rowsIndex}`}>
                  {row.cells.map((cell, rowCellsIndex) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        id={"recent" + (rowCellsIndex + 1)}
                        className="table-appointment-cell"
                        key={`table-tbody-tr-td-${rowCellsIndex}`}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        style={{ paddingLeft: "0px" }}
      >
        <Link
          to={{
            pathname: `/prescription/${idPrescription}`,
            search: `?citation_id=${id}`,
            state: { id: idPrescription, preview: true },
          }}
          onClick={() => setAnchorEl(null)}
          style={{ color: "#000", textDecoration: "none" }}
        >
          <MenuItem className="invoice-menu-item">
            <span className="invoice-menu-text">
              {t("PatientPrescriptions.View")}
            </span>
          </MenuItem>
        </Link>
        <Link
          to={{
            pathname: `/prescription/${idPrescription}`,
            search: `?citation_id=${id}`,
            state: { id: idPrescription },
          }}
          onClick={() => setAnchorEl(null)}
          style={{ color: "#000", textDecoration: "none" }}
        >
          <MenuItem className="invoice-menu-item">
            <span className="invoice-menu-text">
              {t("PatientPrescriptions.Edit")}
            </span>
          </MenuItem>
        </Link>
        <Button
          onClick={() => dispatch(downloadPrescription({ idPrescription }))}
          style={{
            color: "#000",
            textDecoration: "none",
            background: "none",
            padding: 0,
            border: 0,
          }}
        >
          <MenuItem className="invoice-menu-item">
            <span className="invoice-menu-text">
              {t("PatientPrescriptions.Download")}
            </span>
          </MenuItem>
        </Button>
        <Button
          onClick={() => dispatch(duplicatePrescription({ idPrescription }))}
          style={{
            color: "#000",
            textDecoration: "none",
            background: "none",
            padding: 0,
            border: 0,
          }}
        >
          <MenuItem className="invoice-menu-item">
            <span className="invoice-menu-text">
              {t("PatientPrescriptions.Duplicate")}
            </span>
          </MenuItem>
        </Button>
      </Menu>
      {console.log({ downloadLoading, downloadError, downloadSuccess })}
      <Alert
        position="bottom-end"
        show={showAlert}
        variant={alertVariant}
        style={{
          width: "300px",
          position: "fixed",
          bottom: 15,
          right: 15,
          left: "auto",
        }}
      >
        <Alert.Heading>
          <TbFileDownload />
          <strong className="me-auto" style={{ fontSize: 18 }}>
            {t("Messages.Downloading Prescription")}
          </strong>
        </Alert.Heading>
        <hr />
        <p>
          {downloadLoading &&
            t(
              "Messages.The prescription is preparing to being downloading, wait a moment please."
            )}
          {downloadError &&
            t(
              "Messages.An error occurred while trying to download the prescription."
            )}
          {downloadSuccess &&
            t("Messages.The prescription was download correctly.")}
        </p>
        {(downloadSuccess || downloadError) && (
          <div className="d-flex justify-content-start">
            <Button onClick={hideAlert} variant={alertVariant} size="sm">
              Close
            </Button>
          </div>
        )}
      </Alert>
    </>
  );
};

const state = ({ prescriptionStore }) => {
  const {
    data: prescription,
    loading: duplicateLoading,
    error: duplicateError,
    success: duplicateSuccess,
  } = prescriptionStore.duplicate;

  const {
    loading: downloadLoading,
    error: downloadError,
    success: downloadSuccess,
  } = prescriptionStore.download;

  return {
    downloadLoading,
    downloadError,
    downloadSuccess,
    prescription,
    duplicateLoading,
    duplicateError,
    duplicateSuccess,
  };
};

export default connect(state)(Component);
