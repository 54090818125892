import React from 'react'
import GetUserToken from './GetUserToken';

const FetchIndexData = async (endpoint, body) => {

    var requestOptions = {
        method: 'GET',
        headers: { 
            "Content-Type": "application/json",
            "api-key": process.env.REACT_APP_API_KEY,
            "Authorization": GetUserToken()
        },
        redirect: 'follow'
    };

    var url = process.env.REACT_APP_API_URL + endpoint;

    const res = await fetch(url, requestOptions);
    const json = await res.json();

    return (json)
}

export default FetchIndexData
