import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ReactComponent as Pencil } from "../../assets/icons/pencil_big.svg";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import GetUserToken from "../../utils/GetUserToken";
import Modal from "react-bootstrap/Modal";
import Recommendation from "./HomeRecommendationsPatients";
import { TrackableLink } from "../../utils/helper";

const HomeTablePatient = ({ userId, dateState }) => {
  const userToken = GetUserToken();

  const { t } = useTranslation();

  const state = useSelector((state) => state.user);
  const companyState = useSelector((state) => state.companyStore?.one?.data);
  const companyEmail = companyState?.contact_informations[0]?.value;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [currentcitation, setcurrentcitation] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [notime, setNotime] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showModal, setShow] = useState(false);
  const [currentRecommendation, setCurrentRecommendation] = useState(null);
  const [recommendation, setRecommendation] = useState([]);

  let today = new Date();
  let yesterday = new Date();
  let tomorrow = new Date();

  yesterday.setHours(yesterday.getHours() - 24);
  tomorrow.setHours(tomorrow.getHours() + 24);

  const formatDate = (date) => {
    let formattedDate = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSZZ");
    return formattedDate;
  };

  // console.log(data)
  const fetchData = async () => {
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };
    const res = await fetch(
      process.env.REACT_APP_API_URL +
        "/patients/getPatientCitationsById?user_id=" +
        userId,
      requestOptions
    );
    const json = await res.json();
    console.log(json);
    setData(json.citations);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlenotimeClose = () => {
    setNotime(false);
  };

  const sendemail = (id, time) => {
    setcurrentcitation(id);
    setCurrentDate(time);
    setNotime(true);
  };

  const editReminder = (id, time) => {
    setcurrentcitation(id);
    setCurrentDate(time);
    handleClickOpen();
  };

  const deleteCitation = () => {
    setConfirmDelete(false);
    setLoading(true);

    const reminder = {
      title: "A patient has canceled an appointment",
      task: "Patient: " + state?.auth["fullname"],
      time: new Date().toISOString().slice(0, 16),
      note:
        "Appointed date " +
        new Date(currentDate).toLocaleString([], {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "UTC",
        }),
      assignto: "",
      done: false,
      private: false,
    };

    var url = process.env.REACT_APP_API_URL + "/reminders/create";

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      body: JSON.stringify(reminder),
    };

    const res = fetch(url, requestOptions);

    fetch(
      process.env.REACT_APP_API_URL +
        "/citations/archive/" +
        currentcitation +
        "?archived=" +
        currentcitation,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
      }
    ).then(
      () => fetchData(),
      () => setLoading(false)
    );
  };

  const handleCloseModal = () => {
    setShow(false);
  };

  const showRecommendations = (event, item) => {
    // console.log(item);
    event.preventDefault();
    const arrayRecommendations = JSON.parse(item.recommendation);
    setRecommendation(arrayRecommendations);
    setCurrentRecommendation(item);
    setShow(true);
  };

  const deleteAndCreateNewCitation = () => {
    setConfirmDelete(false);
    setLoading(true);

    const reminder = {
      title: "A patient has canceled an appointment",
      task: "Patient: " + state?.auth["fullname"],
      time: new Date().toISOString().slice(0, 16),
      note:
        "Appointed date " +
        new Date(currentDate).toLocaleString([], {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "UTC",
        }),
      assignto: "",
      done: false,
      private: false,
    };

    var url = process.env.REACT_APP_API_URL + "/reminders/create";

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      body: JSON.stringify(reminder),
    };

    const res = fetch(url, requestOptions);

    fetch(
      process.env.REACT_APP_API_URL +
        "/citations/archive/" +
        currentcitation +
        "?archived=" +
        currentcitation,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
      }
    )
      .then(() => fetchData())
      .then(() => setLoading(false))
      .then(
        () =>
          (window.location.href = `/existingpatientcitation/${btoa(
            state.auth?.user_id
          )}`)
      );
  };

  const patientTable = (
    <>
      <div
        style={{
          paddingTop: "15px",
          paddingBottom: "25px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <span className="home-reminders-title mt-0">
          {t("HomeTablePatient.0")}&nbsp;&gt;&nbsp;
        </span>
        <Link
          className="new-patient-btn"
          id="patient_newAppointment"
          to={`/existingpatientcitation/${btoa(state.auth?.user_id)}`}
          onClick={() => TrackableLink("patient_newAppointment")}
        >
          {t("HomeTablePatient.1")}
        </Link>
      </div>

      <table
        className="table table-striped tables responsive"
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <thead>
          <tr>
            <th>{t("HomeTablePatient.2")}</th>
            <th>{t("HomeTablePatient.3")}</th>
            <th>{t("HomeTablePatient.17")}</th>
            <th>{t("HomeTablePatient.4")}</th>
            <th>{t("HomeTablePatient.18")}</th>
            <th>{t("Titles.Recommendations")}</th>
            <th style={{ width: "7%" }}></th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, i) => (
            <tr key={i}>
              <td>
                {item.archived ? (
                  <span
                    style={{
                      color: "#857c8e",
                      textDecoration: "line-through",
                      fontStyle: "italic",
                    }}
                  >
                    {new Date(item.time).toLocaleDateString([], {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      timeZone: "UTC",
                    })}
                  </span>
                ) : (
                  <>
                    {new Date(item.time).toLocaleDateString([], {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      timeZone: "UTC",
                    })}
                  </>
                )}
              </td>
              <td>
                {item.archived ? (
                  <span
                    style={{
                      color: "#857c8e",
                      textDecoration: "line-through",
                      fontStyle: "italic",
                    }}
                  >
                    {item.treatment}
                  </span>
                ) : (
                  <>{item.treatment}</>
                )}
              </td>
              <td>
                {item.archived ? (
                  <span style={{ color: "#857c8e", fontStyle: "italic" }}>
                    {t("HomeTablePatient.5")}
                  </span>
                ) : formatDate(today) > item.time ? (
                  <>{t("HomeTablePatient.6")}</>
                ) : (
                  <>{t("HomeTablePatient.7")}</>
                )}
              </td>
              <td>
                {item.archived ? (
                  <span
                    style={{
                      color: "#857c8e",
                      textDecoration: "line-through",
                      fontStyle: "italic",
                    }}
                  >
                    {item.doctor}
                  </span>
                ) : (
                  <>{item.doctor}</>
                )}
              </td>
              <td>
                {item.hasquiz != null && !item.archived ? (
                  <>
                    {item.quiz_completed ? (
                      <>
                        <Link
                          className="button-homePatient"
                          to={{
                            pathname: "/patient-answers/" + `${item.hasquiz}`,
                          }}
                        >
                          {t("HomeTablePatient.8")}
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link
                          className="button-homePatient"
                          target="_blank"
                          to={{ pathname: "/answer-quiz/" + `${item.hasquiz}` }}
                        >
                          {t("HomeTablePatient.9")}
                        </Link>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </td>
              <td>
                {item.recommendation != null && !item.archived ? (
                  <>
                    <span
                      className="button-homePatient"
                      onClick={(e) => showRecommendations(e, item)}
                    >
                      {t("Messages.Show recommendations")}
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </td>
              <td style={{ textAlign: "right" }}>
                {item.archived ? (
                  <></>
                ) : (
                  <>
                    {item.time > formatDate(tomorrow) ? (
                      <>
                        <Pencil
                          className="icon"
                          title={"Termin umplanen"}
                          style={{ cursor: "pointer" }}
                          // id={item.id}
                          // name={item.time}
                          onClick={() => editReminder(item.id, item.time)}
                        />
                      </>
                    ) : (
                      <>
                        {item.time > formatDate(today) ? (
                          <>
                            <Pencil
                              className="icon"
                              title={"Termin umplanen"}
                              style={{ cursor: "pointer" }}
                              //id={item.id}
                              //name={item.time}
                              onClick={() => sendemail(item.id, item.time)}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );

  const load = (
    <>
      <div className="d-flex justify-content-center">
        <CircularProgress color="secondary" />
      </div>
    </>
  );

  return (
    <>
      {loading ? load : patientTable}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("HomeTablePatient.10")} #{currentcitation} -{" "}
          {new Date(currentDate).toLocaleString([], {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            timeZone: "UTC",
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("HomeTablePatient.11")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setConfirmDelete(true);
              setOpen(false);
            }}
            color="secondary"
          >
            {t("HomeTablePatient.12")}
          </Button>
          <Button onClick={handleClose} color="primary">
            {t("HomeTablePatient.13")}
          </Button>
          <DialogContentText
            id="alert-dialog-description"
            style={{ marginBottom: "0" }}
          >
            Step 1/2
          </DialogContentText>
        </DialogActions>
      </Dialog>

      <Dialog
        open={notime}
        onClose={() => setNotime(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("HomeTablePatient.10")} #{currentcitation} -{" "}
          {new Date(currentDate).toLocaleString([], {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            timeZone: "UTC",
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("HomeTablePatient.14")}
            <a href={"mailto:" + companyEmail}>{companyEmail}</a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNotime(false)} color="primary">
            {t("HomeTablePatient.13")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("HomeTablePatient.10")} #{currentcitation} -{" "}
          {new Date(currentDate).toLocaleString([], {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            timeZone: "UTC",
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("HomeTablePatient.15")}
            &nbsp;
            <a href={"mailto:" + companyEmail}>{companyEmail}</a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={deleteAndCreateNewCitation}
            color="secondary"
            id="patient_appointmentCancelled_newAppointment"
          >
            {t("HomeTablePatient.16")}
          </Button>
          <Button
            onClick={deleteCitation}
            color="secondary"
            id="patient_appointmentCancelled"
          >
            {t("HomeTablePatient.12")}
          </Button>
          <Button onClick={() => setConfirmDelete(false)} color="primary">
            {t("HomeTablePatient.13")}
          </Button>
          <DialogContentText
            id="alert-dialog-description"
            style={{ marginBottom: "0" }}
          >
            Step 2/2
          </DialogContentText>
        </DialogActions>
      </Dialog>

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header className="modal-Header-recommendationsPatients">
          <div className="container-iconButton-recommendationsPatients">
            <IconButton
              className="iconButton-recommendationsPatients"
              onClick={() => setShow(false)}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Modal.Title className="modal-Title-recommendationsPatients">
            {currentRecommendation != null ? (
              <>
                {" "}
                {new Date(currentRecommendation.time).toLocaleDateString([], {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  timeZone: "UTC",
                })}{" "}
                - {currentRecommendation.treatment}
              </>
            ) : (
              <></>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-Body-recommendationsPatients">
          <Recommendation recommendation={recommendation} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HomeTablePatient;
