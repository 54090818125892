import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Avatar, CircularProgress, MenuItem, Select } from "@material-ui/core";
import AlertPop from "../../alerts/Alert";
import { countries } from "../../../constants/countries";
import GetUserToken from "../../../utils/GetUserToken";
import UploadFile from "../../citations/completeappointment/UploadFile";
import { Stack, Alert, useMediaQuery } from "@mui/material";

const UserProfile = ({ patientId, userId, infoRoute }) => {
  // auth
  const userToken = GetUserToken();
  // translations
  const { t } = useTranslation();
  // route
  const history = infoRoute.history;
  // utils
  const [loading, setLoading] = useState(false);
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const isMobile = useMediaQuery("(max-width:600px)");
  // data
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [gender, setGender] = useState("");
  const [birth, setBirth] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [zip, setZip] = useState("");
  const [street, setStreet] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [insuranceOptions, setInsurence] = useState([]);
  const [insurance, setGroup] = useState("");

  const [language, setEmailLanguage] = useState("");
  // constants
  const genders = [
    { id: "Male", value: t("Genders.Male") },
    { id: "Female", value: t("Genders.Female") },
    { id: "Other", value: t("Genders.Other") },
  ];
  const languages = [
    { id: "EN", value: "English" },
    { id: "ES", value: "Español" },
    { id: "DE", value: "Deutsch" },
  ];

  const fetchDataInsurances = async () => {
    const url = process.env.REACT_APP_API_URL + "/insurances/index";

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    const res = await fetch(url, requestOptions);
    const json = await res.json();
    setInsurence(json["insurances"]);
  };

  const fetchPatientData = async () => {
    try {
      setLoading(true);
      var requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        redirect: "follow",
      };
      const res = await fetch(
        `
      ${process.env.REACT_APP_API_URL}/patients/getPatientInfo?id=${patientId}`,
        requestOptions
      );
      const json = await res.json();

      setFirstname(json.patient[0].firstname);
      setLastname(json.patient[0].lastname);
      setEmail(json.patient[0].email);
      setPhone(json.patient[0].phone);
      setAddress(json.patient[0].address);
      setZip(json.patient[0].zip);
      setStreet(json.patient[0].street);
      setCity(json.patient[0].city);
      setState(json.patient[0].state);
      setBirth(json.patient[0].birthday);
      setGender(json.patient[0].gender);
      setGroup(json.patient[0].insurance);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserData = async () => {
    try {
      setLoading(true);
      var requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        redirect: "follow",
      };
      const res = await fetch(
        `
      ${process.env.REACT_APP_API_URL_V2}/users/show/${userId}`,
        requestOptions
      );
      const json = await res.json();

      if (patientId) {
        setEmailLanguage(json.language);
      } else {
        setEmail(json.email);
        setEmailLanguage(json.language);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // Post method
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (patientId) {
      try {
        setLoading(true);

        const user = {
          firstname,
          lastname,
          email,
          phone,
          address,
          zip,
          street,
          city,
          state,
          birth,
          gender,
        };
        const url = `${process.env.REACT_APP_API_URL}/patients/update/${patientId}`;
        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "api-key": process.env.REACT_APP_API_KEY,
            Authorization: userToken,
          },
          redirect: "follow",
          body: JSON.stringify(user),
        };

        const response = await fetch(url, requestOptions);
        if (response.ok) {
          setIsAlert({
            open: true,
            severity: "success",
            message: t("Messages.The operation was successful"),
          });
          setTimeout(() => {
            history.push("/");
          }, 1000);
        } else {
          setIsAlert({
            open: true,
            severity: "error",
            message: t("Messages.The operation could not be processed"),
          });
        }
      } catch (error) {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.Something went wrong, please try again"),
        });
      } finally {
        setLoading(false);
      }
    } else {
      handleSubmitUser();
    }
  };

  const handleSubmitUser = async () => {
    try {
      setLoading(true);

      const user = {
        email,
        language,
      };
      const patient = {
        language,
      };
      var url2 = `${process.env.REACT_APP_API_URL_V2}/users/update/${userId}`;
      var requestOptions2 = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        body: JSON.stringify(patientId ? patient : user),
      };

      const response = await fetch(url2, requestOptions2);
      if (response.ok) {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
        setTimeout(() => {
          history.push("/");
        }, 1000);
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataInsurances();
    if (patientId) {
      fetchPatientData();
      fetchUserData();
    } else {
      fetchUserData();
    }
  }, []);

  return (
    <>
      <div className="container-fluid treatments-table mt-5 container-user-profile">
        {loading ? (
          <div className="d-flex justify-content-center">
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-lg-3 d-flex flex-column align-items-center">
                <Avatar className="profile-avatar" />
              </div>

              <div className="col-sm-12 col-lg-8">
                {patientId ? (
                  <>
                    <p className="key-text-box">
                      {t("UserProfile.Personal Info")}
                    </p>
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label
                            htmlFor="firstname"
                            className="reminders-subtitle"
                          >
                            {t("UserProfile.First Name")}
                          </label>
                          <input
                            type="text"
                            value={firstname}
                            onChange={(e) => setFirstname(e.target.value)}
                            className="input-control"
                          />
                        </div>

                        <div className="form-group">
                          <label
                            htmlFor="gender"
                            className="reminders-subtitle"
                          >
                            {t("UserProfile.Gender")}
                          </label>
                          <br />
                          <Select
                            type="text"
                            onChange={(e) => setGender(e.target.value)}
                            style={{ width: "100%" }}
                            value={gender}
                          >
                            {genders.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item["id"]}>
                                  {item["value"]}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>

                        <div className="form-group">
                          <label htmlFor="group" className="reminders-subtitle">
                            {t("EditPatient.Insurance")}
                          </label>
                          <br />
                          <Select
                            disabled
                            required
                            type="text"
                            onChange={(e) => setGroup(e.target.value)}
                            style={{ width: "100%" }}
                            value={insurance}
                          >
                            {insuranceOptions.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item["id"]}>
                                  {item["title"]}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <Stack sx={{ mt: 1 }}>
                            <Alert
                              severity="warning"
                              style={{ alignItems: "center" }}
                              className="title-inputs"
                            >
                              {t("UserProfile.Insurance warning")}
                            </Alert>
                          </Stack>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label
                            htmlFor="firstname"
                            className="reminders-subtitle"
                          >
                            {t("UserProfile.Last Name")}
                          </label>
                          <input
                            type="text"
                            value={lastname}
                            onChange={(e) => setLastname(e.target.value)}
                            className="input-control"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="birth" className="reminders-subtitle">
                            {t("UserProfile.Date of birth")}
                          </label>
                          <input
                            type="date"
                            value={birth}
                            onChange={(e) => setBirth(e.target.value)}
                            className="input-control"
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                    <hr />
                    <br />
                    <p className="key-text-box">{t("UserProfile.Address")}</p>
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label
                            htmlFor="address"
                            className="reminders-subtitle"
                          >
                            {t("UserProfile.Street")}
                          </label>
                          <input
                            type="text"
                            value={street}
                            onChange={(e) => setStreet(e.target.value)}
                            className="input-control"
                          />
                        </div>

                        <div
                          className="form-group"
                          style={{ paddingRight: "5px" }}
                        >
                          <label
                            htmlFor="address"
                            className="reminders-subtitle"
                          >
                            {t("UserProfile.City")}
                          </label>
                          <input
                            type="text"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            className="input-control"
                          />
                        </div>

                        <div className="form-group">
                          <label
                            htmlFor="address"
                            className="reminders-subtitle"
                          >
                            {t("UserProfile.State")}
                          </label>
                          <input
                            type="text"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                            className="input-control"
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div
                          className="form-group"
                          style={{ paddingLeft: "5px" }}
                        >
                          <label
                            htmlFor="address"
                            className="reminders-subtitle"
                          >
                            {t("UserProfile.Zip code")}
                          </label>
                          <input
                            type="text"
                            value={zip}
                            onChange={(e) => setZip(e.target.value)}
                            className="input-control"
                          />
                        </div>

                        <div className="form-group">
                          <label
                            htmlFor="address"
                            className="reminders-subtitle"
                          >
                            {t("UserProfile.Country")}
                          </label>
                          <br />
                          <Select
                            required
                            type="text"
                            onChange={(e) => setAddress(e.target.value)}
                            style={{ width: "100%" }}
                            value={address}
                          >
                            {countries
                              .sort((a, b) =>
                                t(`Countries.${a["name"]}`).localeCompare(
                                  t(`Countries.${b["name"]}`)
                                )
                              )
                              .map((item, index) => {
                                return (
                                  <MenuItem key={index} value={item["name"]}>
                                    {t(`Countries.${item["name"]}`)}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <br />
                    <hr />
                    <br />
                    <p className="key-text-box">{t("UserProfile.Contact")}</p>
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label htmlFor="email" className="reminders-subtitle">
                            {t("UserProfile.Email")}
                          </label>
                          <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="input-control"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="phone" className="reminders-subtitle">
                            {t("UserProfile.Phone")}
                          </label>
                          <input
                            type="text"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            className="input-control"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <p className="key-text-box">{t("UserProfile.Email")}</p>
                    <div className="form-group">
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="input-control"
                      />
                    </div>
                  </>
                )}
                <br />
                <hr />
                <br />
                <p className="key-text-box">
                  {t("UserProfile.Language of email notifications")}
                </p>
                <div className="row align-items-center">
                  <div className="col-6">
                    <div className="form-group">
                      <br />
                      <Select
                        type="text"
                        onChange={(e) => setEmailLanguage(e.target.value)}
                        style={{ width: "100%" }}
                        value={language}
                      >
                        {languages.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item["id"]}>
                              {item["value"]}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                  {patientId && (
                    <div className="col-6">
                      <button
                        className="btnGo"
                        onClick={() => handleSubmitUser()}
                        style={{ width: "98px", marginLeft: "20px" }}
                      >
                        {t("Buttons.Save")}
                      </button>
                    </div>
                  )}
                </div>
                {/*                 {patientId && (
                  <>
                    <br />
                    <hr />
                    <br />
                    <p className="key-text-box">
                      {t("UserProfile.Upload documents")}
                    </p>
                    <UploadFile patient_id={patientId} isPatientProfile />
                  </>
                )} */}
                <br />
                <hr />
                <br />
                <div className="d-flex justify-content-center">
                  <Link to={"/"}>
                    <button
                      className="btnCancel"
                      style={{
                        width: isMobile ? "120px" : "185px",
                        marginRight: "20px",
                      }}
                    >
                      {t("Buttons.Cancel")}
                    </button>
                  </Link>
                  <div>
                    <button
                      type="submit"
                      className="btnGo"
                      style={{ width: isMobile ? "120px" : "185px" }}
                    >
                      {t("Buttons.Save")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
      {isAlert.open && (
        <AlertPop
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
    </>
  );
};

export default UserProfile;
