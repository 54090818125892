import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import neue_logo_bk from "../assets/icons/neue_logo_bk.png";
import { useSelector } from "react-redux";

const PatientNotFound = () => {
  // translations
  const { t } = useTranslation();
  const state = useSelector((state) => state.companyStore.one.data);

  return (
    <div className="frame">
      <div
        className="d-flex flex-column justify-content-center align-items-center mt-5"
        style={{ marginRight: "82px" }}
      >
        <img src={state?.logo} alt="logo" />
        <div className="mt-5">
          <h2 className="title-neue">
            {t("PatientNotFound.Error loading patient info")}
          </h2>
          <h3 className="subtitle-praxis">
            {t("PatientNotFound.Please contact us")} &#62;&#62;{" "}
            <Link
              className="blue-anchors"
              to={{
                pathname:
                  "https://digitaleszeugs.atlassian.net/servicedesk/customer/portal/7/group/33/create/106",
              }}
              target="_blank"
              style={{ fontSize: " 16pt" }}
            >
              {t("PatientNotFound.click here")}
            </Link>
          </h3>
          <br />
          <br />
          <h3 className="subtitle-praxis">
            {t(
              "PatientNotFound.Important, data to be sent in the application form"
            )}
            :
            <br />
            <br />-
            {t("PatientNotFound.Email address you used to create your account")}
            <br />
            <br />-{t("PatientNotFound.In the field")} '
            {t("PatientNotFound.Type of error")}' {t("PatientNotFound.select")}{" "}
            '{t("PatientNotFound.Error")} #404{" "}
            {t("PatientNotFound.Patient Not Found")}'.
          </h3>
          <br />
          <br />
          <h3 className="subtitle-praxis">
            {t(
              "PatientNotFound.We will send you a reply as soon as possible, thank you very much"
            )}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default PatientNotFound;
