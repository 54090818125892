import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import NameActiveEdited from "./NameActiveEdited";
import Treatmens from "./Treatments";
import Questions from "./Questions";
import GetUserToken from "../../../utils/GetUserToken";
import AlertComponent from "../../alerts/Alert";

export const Quiz = (infoRoute) => {
  // translations
  const { t } = useTranslation();
  // auth
  const userToken = GetUserToken();
  // route
  const history = infoRoute.history;
  // redux
  const state = useSelector((state) => state.user);
  // utils
  const [loading, setLoading] = useState(false);
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  /* Name Active Edited component*/
  const [name, setName] = useState("");
  const [editor, setEditor] = useState("");
  const [switchState, setStateSwitch] = useState({
    active: true,
  });

  /* Treatments component */
  const [clinicTreatments, setClinicTreatments] = useState([]);
  const [treatments, setTreatments] = useState("");

  const getTreatments = async () => {
    let response = await fetch(
      process.env.REACT_APP_API_URL + "/doctors/getTreatments",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
      }
    );
    response = await response.json();

    const allTreatments = response.clinicTreatments;

    const activeTreatments = allTreatments.filter((obj) => {
      return obj.archived === false;
    });

    let availableTreatments = activeTreatments.map((item) => ({
      id: item.id,
      name: item.name,
    }));

    setClinicTreatments(availableTreatments);
  };

  /* Questions component */
  const [inputList, setInputList] = useState([{ id: 0, question: "" }]);
  const [counter, setCounter] = useState(1);

  useEffect(() => {
    getTreatments();
  }, []);

  const onSubmit = async () => {
    setLoading(true);
    try {
      const body = {
        name: name,
        active: switchState,
        edited_by: editor,
        treatments: treatments,
        questions: JSON.stringify(inputList),
      };

      const url = process.env.REACT_APP_API_URL + "/quiztemplates/create";

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        body: JSON.stringify(body),
      };

      const response = await fetch(url, requestOptions);
      if (response.ok) {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
      setTimeout(() => {
        history.push("/quizzes");
      }, 1000);
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setLoading(false);
    }
  };

  const load = (
    <>
      <div className="d-flex justify-content-center">
        <CircularProgress color="secondary" />
      </div>
    </>
  );
  return (
    <>
      <div className="frame">
        <div
          className="container-fluid"
          style={{ paddingLeft: "0", paddingRight: "0" }}
        >
          <NameActiveEdited
            state={state}
            name={name}
            setName={setName}
            editor={editor}
            setEditor={setEditor}
            switchState={switchState}
            setStateSwitch={setStateSwitch}
          />

          <Treatmens
            clinicTreatments={clinicTreatments}
            setClinicTreatments={setClinicTreatments}
            treatments={treatments}
            setTreatments={setTreatments}
          />

          <Questions
            inputList={inputList}
            setInputList={setInputList}
            counter={counter}
            setCounter={setCounter}
          />

          <div className="text-center mt-3 pb-4" style={{ width: "90%" }}>
            {loading ? (
              load
            ) : (
              <button className="blue-btn" type="button" onClick={onSubmit}>
                {t("Buttons.Save")}
              </button>
            )}
          </div>
        </div>
      </div>

      {isAlert.open && (
        <AlertComponent
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
    </>
  );
};

export default Quiz;
