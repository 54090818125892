import React, { useEffect } from "react";
import MuiAlert from "@mui/material/Alert";

export default function Alert({ isAlert, setIsAlert, severity, message }) {
  useEffect(() => {
    setTimeout(() => {
      if (!setIsAlert) {
        isAlert = {
          open: false,
        };
      } else {
        setIsAlert({
          open: false,
        });
      }
    }, 5000);
  }, []);

  return (
    <>
      {isAlert && (
        <MuiAlert
          variant="filled"
          severity={severity}
          className="fixed-bottom"
          sx={{
            width: "40%",
            bottom: 15,
            left: 50,
          }}
        >
          {message}
        </MuiAlert>
      )}
    </>
  );
}
