import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export const DeleteDialog = ({
  dialog,
  setDialog,
  handleDelete,
  insurance,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog
        open={dialog}
        onClose={() => setDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("Insurances.Delete Insurance")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("Insurances.Are you sure you want to delete this insurance?")}
            <br />
            <b>{insurance.vat_code}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)} color="primary">
            {t("Insurances.No")}
          </Button>
          <Button
            onClick={() => handleDelete(insurance.id)}
            color="secondary"
            autoFocus
          >
            {t("Insurances.Yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
