import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useTheme } from "@mui/material";
import TreatmentSelect from "../components/TreatmentList";
import DoctorSelect from "../components/DoctorSelect";
import DateSelect from "../components/DateSelect";
import TimeSelect from "../components/TimeSelect";
import ButtonScrollToTop from "../../../reusableComponents/ButtonScrollToTop";
import StepperComponent from "../components/StepperComponent";

const FirstStep = ({
  treatment,
  doctor,
  dateSelector,
  dayCurrent,
  currentTime,
  timeSelected,
  disableWeekends,
  handleTreatmentChange,
  handleDoctorChange,
  handleDateChange,
  handleTimeChange,
  clinicTreatments,
  availableDoctors,
  loading,
  loadingTreatments,
  loadingDoctors,
  nextStep,
  scrollToNavbar,
}) => {
  // translations
  const { t } = useTranslation();
  // styles
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const xl = useMediaQuery(theme.breakpoints.down("xl"));
  // stepper
  const [activeStep, setActiveStep] = React.useState(0);

  const handleChangeOfStep = (step) => {
    setActiveStep(step);
  };

  return (
    <div className="container-homePatient">
      <div className="container-fluid">
        <div className="frame-NeueTermin">
          <div className="root">
            <div style={{ maxWidth: lg ? "100%" : "75%" }}>
              <h4 className="main-title">{t("NeueTermin.9")}</h4>
              <h2 className="new-appointment-back-link">
                <a
                  className="new-appointment-back-link"
                  style={{ textDecoration: "none" }}
                  href="/"
                >
                  &#60; {t("NeueTermin.10")}
                </a>
              </h2>
              <StepperComponent activeStep={activeStep} />
            </div>

            {!lg && timeSelected && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  maxWidth: xl ? "100%" : "75%",
                  paddingBottom: "40px",
                }}
              >
                <button
                  className="btnGo-NewCitation"
                  onClick={nextStep}
                  style={{
                    backgroundColor: "var(--primary-color)",
                    marginRight: "0px",
                    width: "300px",
                  }}
                >
                  {t("NeueTermin.16")}
                </button>
              </div>
            )}

            <div style={{ maxWidth: lg ? "100%" : "75%" }}>
              <h3 className="subTitles mb-4">
                {t(
                  "FirstStep.Select the treatment, therapist, day and time that best suits your needs"
                )}
              </h3>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: lg ? "column" : "row",
                maxWidth: xl ? "100%" : "75%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: md ? "center" : "start",
                }}
              >
                <div className={`form-group ${sm ? "" : "me-5"}`}>
                  <TreatmentSelect
                    treatment={treatment}
                    handleTreatmentChange={(event) => {
                      handleTreatmentChange(event);
                      handleChangeOfStep(1);
                    }}
                    isLoadingTreatments={loadingTreatments}
                    clinicTreatments={clinicTreatments}
                  />
                </div>

                {treatment && (
                  <div className={`form-group ${sm ? "" : "me-5"}`}>
                    <DoctorSelect
                      doctor={doctor}
                      handleDoctorChange={(event) => {
                        handleDoctorChange(event);
                        handleChangeOfStep(2);
                      }}
                      isLoadingDoctors={loadingDoctors}
                      availableDoctors={availableDoctors}
                    />
                  </div>
                )}
              </div>

              <div className={`d-flex ${md ? "justify-content-center" : ""}`}>
                {doctor && (
                  <div className={`form-group ${sm ? "" : "me-5"}`}>
                    <DateSelect
                      dateSelector={dateSelector}
                      disableWeekends={disableWeekends}
                      handleDateChange={(date) => {
                        handleDateChange(date);
                        handleChangeOfStep(3);
                      }}
                    />
                  </div>
                )}
              </div>

              <div className={`d-flex ${md ? "justify-content-center" : ""}`}>
                {dateSelector && (
                  <div className="form-group">
                    <TimeSelect
                      dayCurrent={dayCurrent}
                      dateSelector={dateSelector}
                      currentTime={currentTime}
                      timeSelected={timeSelected}
                      loading={loading}
                      handleTimeChange={(time) => {
                        handleTimeChange(time);
                        handleChangeOfStep(4);
                      }}
                      nextStep={nextStep}
                    />
                  </div>
                )}
              </div>
            </div>

            {lg && timeSelected && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  maxWidth: xl ? "100%" : "75%",
                  paddingBottom: "20px",
                }}
              >
                <button
                  className="btnGo-NewCitation"
                  onClick={nextStep}
                  style={{
                    backgroundColor: "var(--primary-color)",
                    marginRight: "0px",
                    width: "300px",
                  }}
                >
                  {t("NeueTermin.16")}
                </button>
              </div>
            )}
          </div>
        </div>
        <ButtonScrollToTop handleClick={scrollToNavbar} />
      </div>
    </div>
  );
};

export default FirstStep;
