import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Avatar } from "@material-ui/core";
import useStyles from "./styles";
import DescriptionText from "../DescriptionText";

const DoctorSelect = ({
  doctor,
  handleDoctorChange,
  isLoadingDoctors,
  availableDoctors,
}) => {
  // translations
  const { t } = useTranslation();
  // styles
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.inputLabel}>
        {t("NeueTermin.13")}
      </Typography>
      <ul className={classes.list}>
        {isLoadingDoctors ? (
          <Typography className={classes.loading}>
            {t("FirstStep.loading")}...
          </Typography>
        ) : availableDoctors.length !== 0 ? (
          availableDoctors.map((item) => (
            <li
              key={item.id}
              className={`${classes.listItem} ${
                doctor === item.id ? "selected" : ""
              }`}
              onClick={() => handleDoctorChange({ target: { value: item.id } })}
            >
              <div className={classes.doctorInfo}>
                <Avatar
                  alt={item.name}
                  src={item.avatar ? item.avatar.url : ""}
                  className={classes.doctorAvatar}
                />
                <div
                  className={doctor === item.id ? classes.textContainer : ""}
                >
                  <Typography className={classes.doctorName}>
                    {item.name}
                  </Typography>
                  <Typography variant="body2">{item.distinctions}</Typography>
                  <Typography variant="body2">
                    <DescriptionText
                      text={item.bio}
                      characterLimit={30}
                      classes={classes}
                      isDoctorSelect={true}
                    />
                  </Typography>
                </div>
              </div>
            </li>
          ))
        ) : (
          <Typography className={classes.loading}>
            {t("FirstStep.No therapists are available.")}
          </Typography>
        )}
      </ul>
    </div>
  );
};

export default DoctorSelect;
