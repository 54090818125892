import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FormControl, TextField } from "@material-ui/core";
import ButtonScrollToTop from "../../../reusableComponents/ButtonScrollToTop";

const SecondStepExistingPatient = ({
  patientData,
  onChange,
  prevStep,
  nextStep,
  scrollToNavbar,
}) => {
  // translations
  const { t } = useTranslation();
  // data
  const {
    firstname,
    lastname,
    email,
    phone,
    birth,
    address,
    city,
    state,
    zip,
    street,
  } = patientData;

  return (
    <div className="container-homePatient">
      <div className="container-fluid">
        <div className="frame-homePatient padding-right-mobile">
          <div className="root">
            <h4 className="main-title">{t("NeueTermin.9")}</h4>
            <h2 className="new-appointment-back-link">
              <Link
                className="new-appointment-back-link"
                style={{ textDecoration: "none" }}
                to="/"
              >
                &#60; {t("NeueTermin.10")}
              </Link>
            </h2>
            <h3 className="subTitles">{t("NeueTermin.20")}</h3>
            <h4 className="subTitles">{t("NeueTermin.21")}</h4>
            <br />
            <div className="row">
              <div className="col-sm-12 col-lg-4">
                <div className="form-group">
                  <FormControl
                    style={{
                      minWidth: 280,
                      borderRadius: "5px",
                    }}
                    className="inputTitle"
                  >
                    <TextField
                      required
                      disabled
                      value={firstname}
                      id="name"
                      label={t("EditPatient.First Name")}
                      type="text"
                      name="firstname"
                      size="small"
                      onChange={onChange}
                    />
                  </FormControl>
                </div>

                <div className="form-group">
                  <FormControl
                    style={{
                      minWidth: 280,
                      borderRadius: "5px",
                    }}
                    className="inputTitle"
                  >
                    <TextField
                      required
                      disabled
                      value={lastname}
                      id="name"
                      label={t("EditPatient.Last Name")}
                      type="text"
                      name="lastname"
                      size="small"
                      onChange={onChange}
                    />
                  </FormControl>
                </div>

                <div className="form-group">
                  <FormControl
                    style={{
                      minWidth: 280,
                      borderRadius: "5px",
                    }}
                    className="inputTitle"
                  >
                    <TextField
                      required
                      disabled
                      value={email}
                      id="email"
                      label={t("EditPatient.Email")}
                      type="text"
                      name="email"
                      size="small"
                      onChange={onChange}
                    />
                  </FormControl>
                </div>
                <div className="form-group">
                  <FormControl
                    style={{
                      minWidth: 280,
                      borderRadius: "5px",
                    }}
                    className="inputTitle"
                  >
                    <TextField
                      required
                      disabled
                      value={phone}
                      id="phone"
                      label={t("EditPatient.Phone")}
                      type="text"
                      name="phone"
                      size="small"
                      onChange={onChange}
                    />
                  </FormControl>
                </div>
                <div className="form-group">
                  <FormControl
                    style={{
                      minWidth: 280,
                      borderRadius: "5px",
                    }}
                    className="inputTitle"
                  >
                    <TextField
                      required
                      disabled
                      value={birth}
                      id="birth"
                      label={t("EditPatient.Birth")}
                      type="date"
                      name="birth"
                      size="small"
                      onChange={onChange}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="col-sm-12 col-lg-4">
                <div className="form-group">
                  <FormControl
                    style={{
                      minWidth: 280,
                      borderRadius: "5px",
                    }}
                    className="inputTitle"
                  >
                    <TextField
                      required
                      disabled
                      value={address}
                      id="address"
                      label={t("EditPatient.Country")}
                      type="text"
                      name="address"
                      size="small"
                      onChange={onChange}
                    />
                  </FormControl>
                </div>
                <div className="form-group">
                  <FormControl
                    style={{
                      minWidth: 280,
                      borderRadius: "5px",
                    }}
                    className="inputTitle"
                  >
                    <TextField
                      required
                      disabled
                      value={city}
                      id="city"
                      label={t("EditPatient.City")}
                      type="text"
                      name="city"
                      size="small"
                      onChange={onChange}
                    />
                  </FormControl>
                </div>
                <div className="form-group">
                  <FormControl
                    style={{
                      minWidth: 280,
                      borderRadius: "5px",
                    }}
                    className="inputTitle"
                  >
                    <TextField
                      required
                      disabled
                      value={state}
                      id="state"
                      label={t("EditPatient.State")}
                      type="text"
                      name="state"
                      size="small"
                      onChange={onChange}
                    />
                  </FormControl>
                </div>
                <div className="form-group">
                  <FormControl
                    style={{
                      minWidth: 280,
                      borderRadius: "5px",
                    }}
                    className="inputTitle"
                  >
                    <TextField
                      required
                      disabled
                      value={zip}
                      id="zip"
                      label={t("EditPatient.Zip")}
                      type="text"
                      name="zip"
                      size="small"
                      onChange={onChange}
                    />
                  </FormControl>
                </div>
                <div className="form-group">
                  <FormControl
                    style={{
                      minWidth: 280,
                      borderRadius: "5px",
                    }}
                    className="inputTitle"
                  >
                    <TextField
                      required
                      disabled
                      value={street}
                      id="street"
                      label={t("EditPatient.Street")}
                      type="text"
                      name="street"
                      size="small"
                      onChange={onChange}
                    />
                  </FormControl>
                </div>
              </div>
            </div>

            <div className="row mt-3 mb-3 invert-column">
              <div className="col-sm-12 col-md-6 col-lg-4 mt-2 mb-2">
                <button
                  className="btnBack"
                  style={{
                    backgroundColor: "var(--secondary-color)",
                  }}
                  onClick={prevStep}
                >
                  {t("NeueTermin.15")}
                </button>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4 mt-2 mb-2">
                {firstname && lastname && email && phone ? (
                  <button
                    className="btnGo-NewCitation"
                    style={{
                      backgroundColor: "var(--primary-color)",
                    }}
                    onClick={nextStep}
                  >
                    {t("NeueTermin.16")}
                  </button>
                ) : (
                  <button className="btnBack" disabled onClick={nextStep}>
                    {t("NeueTermin.16")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <ButtonScrollToTop handleClick={scrollToNavbar} />
      </div>
    </div>
  );
};

export default SecondStepExistingPatient;
