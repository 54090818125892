import { createSlice } from "@reduxjs/toolkit";

// Define initial state
const initialState = {
  visibleModal: null, // This will hold the string identifier of the visible modal
};

// Create a modal slice
const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    showModal(state, action) {
      state.visibleModal = action.payload; // Set the identifier of the modal to show
    },
    hideModal(state) {
      state.visibleModal = null; // Hide the modal by setting it to null
    },
  },
});

// Export actions and reducer
export const { showModal, hideModal } = modalSlice.actions;
export default modalSlice.reducer;
