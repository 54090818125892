import React, {
  createContext,
  useCallback,
  useEffect,
  useContext,
  useState,
} from "react";
import { connect, useDispatch } from "react-redux";
import services from "../../services";
import { getFilePath, getPostsFilePath } from "../../services/media";
import { hideModal } from "../../redux/slices/modal";

// Define the context for file upload progress
const FileUploadProgressContext = createContext(undefined);

/**
 * Hook to use the file upload progress context
 * @returns {Object} - The file upload context.
 * @throws Will throw an error if used outside of FileUploadProgressProvider.
 */
export const useFileUploadProgress = () => {
  const context = useContext(FileUploadProgressContext);
  if (!context) {
    throw new Error(
      "useFileUploadProgress must be used within a FileUploadProgressProvider"
    );
  }
  return context;
};

const FileUploadProgressProvider = ({ children, company, user, doctor }) => {
  const dispatch = useDispatch();

  // State to hold file upload progress
  const [fileUploadProgress, setFileUploadProgress] = useState([]);
  const [uploadSpeed, setUploadSpeed] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [startBytesLoaded, setStartBytesLoaded] = useState(null);

  /**
   * Function to upload files
   * @param {Object} files - An object where keys are file types and values are arrays of files.
   * @param {string} entity - The ID of the user.
   * @param {Object} extraData - Is optional, used to create file path
   * @returns {Object} uploadedFiles - An object containing information about successfully uploaded files.
   */
  const uploadFiles = useCallback(
    async (files, entity, extraData = {}) => {
      const timestamp = new Date().getTime();
      const uploadedFiles = {};

      for (const [fieldName, fileList] of Object.entries(files)) {
        uploadedFiles[fieldName] = uploadedFiles[fieldName] || [];

        // Check if fileList is an array before filtering
        if (Array.isArray(fileList)) {
          const fileListFiltered = fileList.filter(
            (file) => file instanceof File
          );
          const nonFileList = fileList.filter(
            (file) => !(file instanceof File)
          );

          nonFileList.forEach((file) => {
            uploadedFiles[fieldName].push(file);
          });

          const uploadPromises = fileListFiltered.map((file, i) => {
            const fileExt = file.name.slice(
              ((file.name.lastIndexOf(".") - 1) >>> 0) + 2
            );
            const fileName = `${timestamp + i}`;

            return new Promise((resolve, reject) => {
              services.media
                .saveFile({
                  file,
                  filePath: getFilePath({
                    entity,
                    fieldName,
                    idCompany: company?.id,
                    idUser: user?.user_id,
                    idDoctor: doctor?.id,
                    ...extraData,
                  }),
                  fileName: `${fileName}.${fileExt}`,
                  fileType: file?.type,
                  onUploadProgress: (uploadProgress) => {
                    updateFileUploadProgress({
                      preview: file.url || file.preview,
                      fileName,
                      fileSize: file.size,
                      fileType: file.type,
                      originalName: file.name,
                      progress: uploadProgress,
                    });
                  },
                  onAbort: () => dispatch(hideModal()),
                })
                .then((response) => resolve(response))
                .catch((error) => reject(error));
            });
          });

          try {
            const uploadResponses = await Promise.all(uploadPromises);
            const allUploadsSuccessful = uploadResponses.every(
              (response) => "response" in response
            );

            if (allUploadsSuccessful) {
              const uploadedFilesForType = fileListFiltered.map((file, i) => {
                const fileExtension = file.name.slice(
                  ((file.name.lastIndexOf(".") - 1) >>> 0) + 2
                );
                const key = `${timestamp + i}`;

                return {
                  field_name: fieldName,
                  key,
                  original_name: file.name,
                  file_extension: fileExtension,
                  mime: file.type,
                  size: file.size,
                  path: getFilePath({
                    entity,
                    fieldName,
                    idCompany: company?.id,
                    idUser: user?.user_id,
                    idDoctor: doctor?.id,
                    ...extraData,
                  }),
                };
              });

              uploadedFiles[fieldName].push(...uploadedFilesForType);
            } else {
              console.error(
                `One or more file uploads for ${fieldName} failed.`
              );
            }
          } catch (error) {
            console.error(`Error during file uploads for ${fieldName}:`, error);
          }
        } else {
          console.warn(
            `File list for ${fieldName} is not an array or is empty:`,
            fileList
          );
        }
      }

      return uploadedFiles;
    },
    [company, user, doctor]
  );

  /**
   * Function to format time in HH:MM:SS
   * @param {number} seconds - The time in seconds.
   * @returns {string} - A string formatted as HH:MM:SS.
   */
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const pad = (num) => (num < 10 ? "0" + num : num);

    return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
  };

  /**
   * Function to calculate upload speed
   * @param {number} bytesLoaded - The number of bytes loaded.
   * @returns {number} - The upload speed in bytes per second.
   */
  const calculateUploadSpeed = (bytesLoaded) => {
    const currentTime = new Date().getTime();
    const elapsedTimeInSeconds = (currentTime - startTime) / 1000;
    const bytesUploaded = bytesLoaded - (startBytesLoaded || 0);
    const uploadSpeed = bytesUploaded / elapsedTimeInSeconds;

    return uploadSpeed;
  };

  /**
   * Function to handle upload start
   */
  const onUploadStart = () => {
    setStartTime(new Date().getTime());
    setStartBytesLoaded(0);
    setUploadSpeed(null);
  };

  /**
   * Function to handle upload progress
   * @param {Event} event - The progress event containing the loaded bytes.
   */
  const onUploadProgress = (event) => {
    const bytesLoaded = event.loaded;

    if (!startTime) {
      setStartTime(new Date().getTime());
      setStartBytesLoaded(bytesLoaded);
      setUploadSpeed(null);
      return;
    }

    const currentUploadSpeed = calculateUploadSpeed(bytesLoaded);
    setUploadSpeed(currentUploadSpeed);
  };

  /**
   * Function to update file upload progress
   * @param {Object} params - The parameters for updating the file upload progress.
   * @param {string} params.fileName - The name of the file.
   * @param {string} params.fileType - The type of the file.
   * @param {string} params.originalName - The original name of the file.
   * @param {number} params.progress - The upload progress.
   * @param {number} params.fileSize - The size of the file.
   * @param {string} [params.preview] - An optional preview of the file.
   */
  const updateFileUploadProgress = ({
    fileName,
    fileType,
    originalName,
    progress,
    fileSize,
    preview,
  }) => {
    setFileUploadProgress((prevProgress) => {
      const updatedProgress = [...prevProgress];
      const fileIndex = updatedProgress.findIndex(
        (file) => file.fileName === fileName
      );

      if (fileIndex !== -1) {
        const remainingBytes = fileSize - progress;
        const remainingTimeInSeconds = remainingBytes / (uploadSpeed || 1);
        const remainingTimeFormatted = formatTime(remainingTimeInSeconds);

        updatedProgress[fileIndex].progress = progress;
        updatedProgress[fileIndex].remainingTime = remainingTimeFormatted;
      } else {
        updatedProgress.push({
          preview,
          fileName,
          fileType,
          originalName,
          progress,
          remainingTime: "0",
        });
      }

      return updatedProgress;
    });
  };

  /**
   * Function to reset file upload progress
   */
  const resetFileUploadProgress = () => {
    setFileUploadProgress([]);
  };

  const areFiles = (array) => {
    let thereAreFiles = false;
    if (Array.isArray(array)) {
      for (let i = 0; i < array.length; i++) {
        const file = array[i];
        if (
          file &&
          typeof file === "object" &&
          "name" in file &&
          "size" in file &&
          "type" in file
        ) {
          thereAreFiles = true;
          break;
        }
      }
    }

    return thereAreFiles;
  };

  return (
    <FileUploadProgressContext.Provider
      value={{
        fileUploadProgress,
        updateFileUploadProgress,
        resetFileUploadProgress,
        uploadFiles,
        areFiles,
      }}
    >
      {children}
    </FileUploadProgressContext.Provider>
  );
};

const state = ({ user, companyStore }) => {
  return {
    user,
    company: companyStore.one.data,
    doctor: user.doctor,
  };
};

export default connect(state)(FileUploadProgressProvider);
