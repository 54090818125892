import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import { withStyles } from "@material-ui/core/styles";
import { Checkbox, CircularProgress, TextField } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
//import GetLocalAuthToken from "../../utils/GetLocalAuthToken";
import GetUserToken from "../../utils/GetUserToken";
import "../../assets/stylesheets/newtratamiento.css";
import Alert from "../alerts/Alert";

const styles = (theme) => ({
  root: {
    "& .MuiInputBase-root": {
      minHeight: "200px !important",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
});

class NewTreatment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      name: "",
      duration: "",
      treatment_description: "",
      first: false,
      archived: false,
      bookable: false,
      clinicProduct: [],
      selectedProducts: [],
      products: [],
      userToken: GetUserToken(),

      isAlert: {
        open: false,
        severity: "",
        message: "",
      },
    };

    this.onChange = this.onChange.bind(this);
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.stripHtmlEntities = this.stripHtmlEntities.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.onProductChange = this.onProductChange.bind(this);
  }

  componentDidMount() {
    this.getProducts();
  }

  stripHtmlEntities(str) {
    return String(str).replace(/</g, "&lt;").replace(/>/g, "&gt;");
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onChangeCheckbox(event) {
    this.setState({ [event.target.name]: event.target.checked });
  }

  onProductChange(event, newValues) {
    this.setState({
      ...this.state,
      products: newValues,
    });
  }

  getProducts() {
    const url =
      process.env.REACT_APP_API_URL_V2 + "/treatments/get_base_products";

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: this.state.userToken,
      },
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => {
        response.json().then((response) => {
          const filteredProducts = response.filter((item) => {
            return item.archived === false;
          });
          this.setState({
            ...this.state,
            clinicProduct: filteredProducts,
            loading: false,
          });
        });
      })
      .catch((error) => console.log(error.message));
  }

  onSubmit(event) {
    event.preventDefault();
    const { t } = this.props;

    if (this.state.products.length === 0) {
      this.setState({
        ...this.state,
        isAlert: {
          open: true,
          severity: "warning",
          message: t("Messages.You must select at least one product"),
        },
      });
      setTimeout(() => {
        this.setState({
          ...this.state,
          isAlert: {
            open: false,
          },
        });
      }, 5000);
      return;
    }

    this.setState({ ...this.state, loading: true });

    const url = process.env.REACT_APP_API_URL_V2 + "/treatments/create";

    const {
      name,
      duration,
      treatment_description,
      first,
      archived,
      bookable,
      products,
    } = this.state;

    const body = {
      name,
      duration,
      treatment_description,
      first,
      archived,
      bookable,
      products: JSON.stringify(products),
    };

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: this.state.userToken,
      },
      redirect: "follow",
      body: JSON.stringify(body),
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.id) {
          this.setState({
            ...this.state,
            isAlert: {
              open: true,
              severity: "success",
              message: t("Messages.The operation was successful"),
            },
            loading: false,
          });

          setTimeout(() => {
            if (!this.props.isOnboarding) {
              this.props.history.push(`/edit-treatment/${response.id}`);
            } else {
              this.props.setIsTreatmentCreated(true);
            }
          }, 1000);
        } else {
          this.setState({
            ...this.state,
            isAlert: {
              open: true,
              severity: "error",
              message: t("Messages.The operation could not be processed"),
            },
            loading: false,
          });
        }
      })
      .catch((error) =>
        this.setState({
          ...this.state,
          isAlert: {
            open: true,
            severity: "error",
            message: t("Messages.Something went wrong, please try again"),
          },
          loading: false,
        })
      );
  }

  render() {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    // translations
    const { t } = this.props;
    // styles
    const { classes, isOnboarding } = this.props;

    return (
      <>
        <div
          className="frame"
          style={{
            width: "calc(100% - 80px)",
            paddingLeft: !isOnboarding ? "150px" : "90px",
          }}
        >
          <div className="row" style={{ height: "calc(100vh - 144px)" }}>
            <form
              className="d-flex flex-column justify-content-between"
              style={{ paddingBottom: "6%" }}
              onSubmit={this.onSubmit}
            >
              <div>
                <h4
                  className={`main-title-treatments ${
                    !isOnboarding ? "" : "text-center"
                  }`}
                >
                  {t("NewTreatment.New Treatment")}
                </h4>
                {!isOnboarding && (
                  <p className="blue-anchors">
                    <Link className="blue-anchors" to="/treatments">
                      &#60; {t("NewTreatment.Back to treatments")}
                    </Link>
                  </p>
                )}
              </div>

              <div className="row">
                {this.state.loading ? (
                  <div className="d-flex justify-content-center">
                    <CircularProgress color="secondary" />
                  </div>
                ) : (
                  <>
                    <div className={!isOnboarding ? "col-6" : "col"}>
                      <div className="row">
                        <div className="form-group">
                          <label htmlFor="name" className="title-treatments">
                            {t("NewTreatment.Title")}
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className="form-control"
                            required
                            onChange={this.onChange}
                            placeholder={t("NewTreatment.Write here")}
                          />
                        </div>

                        <div className="form-group">
                          <label
                            htmlFor="duration"
                            className="title-treatments"
                          >
                            {t("NewTreatment.Duration")} (
                            {t("NewTreatment.minutes")})
                          </label>
                          <input
                            type="number"
                            name="duration"
                            id="duration"
                            className="form-control"
                            required
                            onChange={this.onChange}
                            placeholder={t("NewTreatment.Write here")}
                          />
                        </div>

                        <div className="form-group">
                          <label
                            htmlFor="treatment_description"
                            className="title-treatments"
                          >
                            {t("NewTreatment.Treatment Description")}
                          </label>
                          <textarea
                            name="treatment_description"
                            id="treatment_description"
                            className="form-control"
                            required
                            onChange={this.onChange}
                            placeholder={t("NewTreatment.Write here")}
                          />
                        </div>

                        <div className="form-group" style={{ zIndex: 99 }}>
                          <label className="title-inputs">
                            {t("NewTreatment.Options")}
                          </label>
                          <Form.Check
                            checked={this.state.first}
                            onChange={this.onChangeCheckbox}
                            type="checkbox"
                            name="first"
                            label={t("NewTreatment.First contact?")}
                          />
                          <Form.Check
                            checked={this.state.bookable}
                            onChange={this.onChangeCheckbox}
                            type="checkbox"
                            name="bookable"
                            label={t("NewTreatment.Bookable by patient?")}
                          />
                        </div>

                        <div className="form-group">
                          <label
                            htmlFor="product"
                            className="title-treatments"
                            style={{ marginBottom: "15px" }}
                          >
                            {t("NewTreatment.Select Product")}
                          </label>
                          <Autocomplete
                            multiple
                            name="products"
                            id="products"
                            className={classes.root}
                            options={this.state.clinicProduct.sort((a, b) =>
                              a.rate.toString().localeCompare(b.rate.toString())
                            )}
                            disableCloseOnSelect
                            onChange={(event, newValues) => {
                              this.onProductChange(event, newValues);
                            }}
                            getOptionLabel={(option) =>
                              option.rate + " - " + option.title
                            }
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{
                                    marginRight: 8,
                                    color: "var(--primary-color)",
                                  }}
                                  checked={selected}
                                />
                                {option.rate} - {option.title}
                              </React.Fragment>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label={t("NewTreatment.Products")}
                                fullWidth
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    {!isOnboarding && <div className="col-6"></div>}
                  </>
                )}
              </div>

              <div
                className={`d-flex justify-content-${
                  !isOnboarding ? "end" : "center"
                }`}
              >
                {!isOnboarding && (
                  <Link to={"/treatments"}>
                    <button
                      className="btnCancel"
                      style={{ width: "185px", marginRight: "20px" }}
                    >
                      {t("NewTreatment.CANCEL")}
                    </button>
                  </Link>
                )}
                <div>
                  <button
                    type="submit"
                    className={
                      this.state.isAlert.open || this.state.loading
                        ? "btnGo-disabled"
                        : "btnGo"
                    }
                    id={isOnboarding ? "create_treatment" : ""}
                    disabled={this.state.isAlert.open || this.state.loading}
                    style={{ width: "185px" }}
                  >
                    {t("NewTreatment.SAVE")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {this.state.isAlert.open && (
          <Alert
            isAlert={this.state.isAlert}
            severity={this.state.isAlert.severity}
            message={this.state.isAlert.message}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(withStyles(styles)(NewTreatment));
