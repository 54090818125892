import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { EditText } from "react-edit-text";
import "react-edit-text/dist/index.css";
import "../../../assets/stylesheets/herbs.css";
import fetchApi from "../../../utils/fetchApi";

const NewHerbStep1 = ({ idHerb, setLoading, setIsAlert, setIdHerb }) => {
  // translations
  const { t } = useTranslation();
  // data
  const [newHerb, setNewHerb] = useState({});
  // utils
  const id_herb = idHerb ? true : false;

  const handleCreateHerb = async () => {
    const { pinyin_name, chinese_name, latin_name } = newHerb;

    if (pinyin_name || chinese_name || latin_name) {
      try {
        setLoading(true);
        const url = `${process.env.REACT_APP_HERBS_API}/herbs`;
        const { data, ok } = await fetchApi(
          url,
          "POST",
          JSON.stringify(newHerb)
        );
        if (ok) {
          setIdHerb(data.id);
          setIsAlert({
            open: true,
            severity: "success",
            message: t("Messages.The operation was successful"),
          });
        } else {
          setIsAlert({
            open: true,
            severity: "error",
            message: t("Messages.The operation could not be processed"),
          });
        }
      } catch (error) {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.Something went wrong, please try again"),
        });
      } finally {
        setLoading(false);
      }
    } else {
      setIsAlert({
        open: true,
        severity: "warning",
        message: t("Messages.You must complete the required fields"),
      });
      return;
    }
  };

  // console.log("newHerb", newHerb);

  return (
    <>
      {/* Image */}
      <div
        className="subtitles-patients mb-3"
        style={{
          width: "95%",
        }}
      >
        <p className="subtitles-patients mb-0 p-1">
          {t("NewIndividualHerbs.21")}
        </p>
        <EditText
          name="image"
          placeholder={t("Herbs.Write here")}
          readonly={id_herb}
          onSave={({ value }) =>
            setNewHerb({
              ...newHerb,
              image: value,
            })
          }
        />
      </div>
      {/* Names boxes */}
      <div
        className="row patient-id-box"
        style={{
          width: "95%",
          marginLeft: "0px",
          paddingLeft: "20px",
          marginBottom: "21px",
        }}
      >
        <div className="d-flex justify-content-between mt-2">
          <p className="subtitles-patients" style={{ fontSize: "18px" }}>
            {t("NewIndividualHerbs.0")}*
          </p>
          <div className="col-4 button-column">
            <div className="key-text-box-edit-button d-flex justify-content-end"></div>
          </div>
        </div>
        <div className="col-4 text-left">
          <p className="subtitles-patients">{t("NewIndividualHerbs.20")}</p>
          <div className="col">
            <EditText
              className="key-text-box"
              name="pinyin_name"
              placeholder={t("Herbs.Write here")}
              readonly={id_herb}
              onSave={({ value }) =>
                setNewHerb({
                  ...newHerb,
                  pinyin_name: value,
                })
              }
            />
          </div>
        </div>
        <div className="col-4 border-start">
          <p className="subtitles-patients">{t("NewIndividualHerbs.2")}</p>
          <div className="col">
            <EditText
              className="key-text-box"
              name="latin_name"
              placeholder={t("Herbs.Write here")}
              readonly={id_herb}
              onSave={({ value }) =>
                setNewHerb({
                  ...newHerb,
                  latin_name: value,
                })
              }
            />
          </div>
        </div>
        <div className="col-4 border-start">
          <p className="subtitles-patients">{t("NewIndividualHerbs.19")}</p>
          <div className="col">
            <EditText
              className="key-text-box"
              name="chinese_name"
              placeholder={t("Herbs.Write here")}
              readonly={id_herb}
              onSave={({ value }) =>
                setNewHerb({
                  ...newHerb,
                  chinese_name: value,
                })
              }
            />
          </div>
        </div>
      </div>
      {/* end Names boxes */}
      <div className="d-flex justify-content-end" style={{ width: "95%" }}>
        <button
          className={id_herb ? "blue-btn-disabled" : "blue-btn"}
          disabled={id_herb}
          style={{ width: "185px" }}
          onClick={() => handleCreateHerb()}
        >
          {t("Buttons.Save")}
        </button>
      </div>
    </>
  );
};

export default NewHerbStep1;
