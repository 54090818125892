import { symptoms, zungenfarbe, zungengestalt, zungenbelag } from "../../../../../../constants/tongue"

const getSymptom = (key, array) => {
  return array.find(symptom => symptom.id === key)
}

export const initialFormData = (name, value) => {
    return {
      [`${name}.color_1`]: getSymptom(value?.color_1  ?? "", zungenfarbe),
      [`${name}.shape`]: getSymptom(value?.shape ?? "", zungengestalt),
      [`${name}.coating`]: getSymptom(value?.coating ?? "", zungenbelag),
      [`${name}.color_2`]: getSymptom(value?.color_2 ?? "", zungenfarbe),
      [`${name}.veins`]: getSymptom(value?.veins ?? "", symptoms),
    }
};

export const getValue = (array, newValue) => {
  let value = array.find(
    ({ title, nameEN, nameES }) =>
      title.replace(/\t/g, " ") === newValue ||
      nameEN === newValue ||
      nameES === newValue
  );
  return value;
};
