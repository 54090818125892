import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { branches } from "../../constants/branches";
import { countries } from "../../constants/countries";
import GetUserToken from "../../utils/GetUserToken";
import AlertComponent from "../alerts/Alert";

const NewPatientOnboarding = ({
  fetchPatients,
  setIsPatientCreated,
  handleClose,
}) => {
  // auth
  const userToken = GetUserToken();
  // translations
  const { t } = useTranslation();
  // utils
  const [loading, setLoading] = useState(false);
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  // data
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [zip, setZip] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState(" ");
  const [birth, setBirth] = useState("");
  const [notes, setNotes] = useState("");
  const [gender, setGender] = useState("");
  const [insurance, setInsurance] = useState([]);
  const [selectedInsurence, setSelectedInsurance] = useState("");
  const [praxis, setBranch] = useState("");
  const [doctor, setDoctor] = useState("");
  const [relationship, setRelation] = useState("");
  const [relation, setRelationship] = useState([]);
  const [doctors, setDoctors] = useState([]);

  const genders = [
    { id: "Male", value: t("Genders.Male") },
    { id: "Female", value: t("Genders.Female") },
    { id: "Other", value: t("Genders.Other") },
  ];

  const fetchDataDoctors = async () => {
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL + "/citations/getDoctors",
      requestOptions
    );
    const json = await res.json();
    const activeDoctors = json.clinicDoctors.filter((obj) => {
      return obj.archived === false;
    });
    setDoctors(activeDoctors);
  };

  const fetchDataPatients = async () => {
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };
    const res = await fetch(
      process.env.REACT_APP_API_URL + "/citations/getPatients",
      requestOptions
    );
    const json = await res.json();
    setRelationship(json["clinicPatients"]);
  };

  const fetchDataInsurances = async () => {
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };
    const res = await fetch(
      process.env.REACT_APP_API_URL + "/insurances/index",
      requestOptions
    );
    const json = await res.json();
    setInsurance(json["insurances"]);
  };

  useEffect(() => {
    fetchDataDoctors();
    fetchDataPatients();
    fetchDataInsurances();
  }, []);

  const handleRelationChange = (e, value) => {
    var fieldValue = value;
    if (!fieldValue) {
      fieldValue = null;
    } else setRelation(value.id);
  };

  const handleDoctorChange = (e, value) => {
    var fieldValue = value;
    if (!fieldValue) {
      fieldValue = null;
    } else setDoctor(value.user_id);
  };

  // const handleCountryChange = (e, value) => {
  //   var fieldValue = value;
  //   if (!fieldValue) {
  //     fieldValue = null;
  //   } else setAddress(value.name);
  // };

  const handleInsurance = (e, value) => {
    var fieldValue = value;
    if (!fieldValue) {
      fieldValue = null;
    } else {
      setSelectedInsurance(value.id);
    }
  };

  // Post method
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const patient = {
        firstname,
        lastname,
        email,
        phone,
        address,
        zip,
        street,
        city,
        state,
        birth,
        notes,
        gender,
        insurance: selectedInsurence,
        praxis,
        relationship,
        doctor,
      };

      const url = process.env.REACT_APP_API_URL + "/patients/create";

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
        body: JSON.stringify(patient),
      };

      const response = await fetch(url, requestOptions);
      if (response.ok) {
        const data = await response.json();
        setIsPatientCreated(data?.patient);
        fetchPatients();
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
        setTimeout(() => {
          handleClose();
        }, 2000);
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="px-3">
        <form onSubmit={handleSubmit} className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="firstname" className="title-inputs">
                {t("EditPatient.First Name")}
              </label>
              <span className="required-asterisk">*</span>
              <br />
              <br />
              <TextField
                required
                type="text"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                className="input-control"
                variant="outlined"
                size="small"
                disabled={loading}
              />
            </div>

            <div className="form-group">
              <label htmlFor="firstname" className="title-inputs">
                {t("EditPatient.Last Name")}
              </label>
              <span className="required-asterisk">*</span>
              <br />
              <br />
              <TextField
                required
                type="text"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                className="input-control"
                variant="outlined"
                size="small"
                disabled={loading}
              />
            </div>

            <div className="form-group">
              <label htmlFor="email" className="title-inputs">
                Email
              </label>
              <span className="required-asterisk">*</span>
              <br />
              <br />
              <TextField
                required
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="input-control"
                variant="outlined"
                size="small"
                disabled={loading}
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone" className="title-inputs">
                {t("EditPatient.Phone")}
              </label>
              <span className="required-asterisk">*</span>
              <br />
              <br />
              <TextField
                required
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="input-control"
                variant="outlined"
                size="small"
                disabled={loading}
              />
            </div>

            <div className="form-group">
              <label htmlFor="gender" className="title-inputs">
                {t("EditPatient.Country")}
              </label>
              <span className="required-asterisk">*</span>
              <br />
              <Select
                required
                type="text"
                onChange={(e) => setAddress(e.target.value)}
                style={{ width: "100%" }}
                value={address}
                defaultValue=""
                disabled={loading}
              >
                {countries
                  .sort((a, b) =>
                    t(`Countries.${a["name"]}`).localeCompare(
                      t(`Countries.${b["name"]}`)
                    )
                  )
                  .map((item, index) => {
                    return (
                      <MenuItem key={index} value={item["name"]}>
                        {t(`Countries.${item["name"]}`)}
                      </MenuItem>
                    );
                  })}
              </Select>
            </div>

            <div className="form-group">
              <label htmlFor="address" className="title-inputs">
                {t("EditPatient.State")}
              </label>
              <br />
              <br />
              <TextField
                type="text"
                value={state}
                onChange={(e) => setState(e.target.value)}
                className="input-control"
                variant="outlined"
                size="small"
                disabled={loading}
              />
            </div>

            <div
              style={{ display: "grid", gridTemplateColumns: "1.5fr 0.5fr" }}
            >
              <div className="form-group" style={{ paddingRight: "5px" }}>
                <label htmlFor="address" className="title-inputs">
                  {t("EditPatient.City")}
                </label>
                <span className="required-asterisk">*</span>
                <br />
                <br />
                <TextField
                  required
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  className="input-control"
                  variant="outlined"
                  size="small"
                  disabled={loading}
                />
              </div>

              <div className="form-group" style={{ paddingLeft: "5px" }}>
                <label htmlFor="address" className="title-inputs">
                  {t("EditPatient.Zip")}
                </label>
                <span className="required-asterisk">*</span>
                <br />
                <br />
                <TextField
                  required
                  type="text"
                  value={zip}
                  onChange={(e) => setZip(e.target.value)}
                  className="input-control"
                  variant="outlined"
                  size="small"
                  disabled={loading}
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="address" className="title-inputs">
                {t("EditPatient.Street")}
              </label>
              <span className="required-asterisk">*</span>
              <br />
              <br />
              <TextField
                required
                type="text"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                className="input-control"
                variant="outlined"
                size="small"
                disabled={loading}
              />
            </div>
          </div>

          <div className="col">
            <div className="form-group mt-4">
              <label htmlFor="notes" className="title-inputs">
                {t("EditPatient.Relationship w/")}
              </label>
              <br />
              <Autocomplete
                noOptionsText={"Patient not found"}
                options={relation}
                onChange={(e, value) => handleRelationChange(e, value)}
                getOptionLabel={(option) =>
                  option["lastname"] + ", " + option["firstname"]
                }
                inputValue={relation["id"]}
                renderInput={(params) => <TextField {...params} />}
                disabled={loading}
                style={{ width: "100%" }}
              />
            </div>

            <div className="form-group mt-5">
              <label htmlFor="gender" className="title-inputs">
                {t("EditPatient.Gender")}
              </label>
              <span className="required-asterisk">*</span>
              <br />
              <Select
                required
                type="text"
                onChange={(e) => setGender(e.target.value)}
                style={{ width: "100%" }}
                defaultValue=""
                disabled={loading}
              >
                {genders.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item["id"]}>
                      {item["value"]}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>

            <div className="form-group mt-5">
              <label htmlFor="group" className="title-inputs">
                {t("EditPatient.Insurance")}
              </label>
              <span className="required-asterisk">*</span>
              <br />
              <Autocomplete
                noOptionsText={"Not Found"}
                options={insurance}
                onChange={(e, value) => handleInsurance(e, value)}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => <TextField {...params} required />}
                disabled={loading}
                style={{ width: "100%" }}
              />
            </div>

            <div className="form-group mt-5">
              <label htmlFor="doctor" className="title-inputs">
                {t("EditPatient.Therapist")}
              </label>
              <br />
              <Autocomplete
                noOptionsText={"Therapist not found"}
                options={doctors}
                onChange={(e, value) => handleDoctorChange(e, value)}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} />}
                disabled={loading}
                style={{ width: "100%" }}
              />
            </div>

            <div className="form-group mt-5">
              <label htmlFor="gender" className="title-inputs">
                {t("EditPatient.Branch")}
              </label>
              <br />
              <Select
                type="text"
                onChange={(e) => setBranch(e.target.value)}
                style={{ width: "100%" }}
                defaultValue=""
                disabled={loading}
              >
                {branches.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item["id"]}>
                      {item["value"]}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>

            <div className="form-group mt-5">
              <label htmlFor="birth" className="title-inputs">
                {t("EditPatient.Birth")}
              </label>
              <span className="required-asterisk">*</span>
              <br />

              <input
                required
                type="date"
                value={birth}
                onChange={(e) => setBirth(e.target.value)}
                className="input-control"
                disabled={loading}
              />
            </div>

            <div className="form-group mt-5">
              <label htmlFor="notes" className="title-inputs">
                {t("EditPatient.Notes")}
              </label>
              <TextareaAutosize
                type="text"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                aria-label="minimum height"
                minRows={3}
                className="input-control"
                disabled={loading}
              />
            </div>

            {loading ? (
              <div className="d-flex justify-content-center">
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <div className="mt-5">
                <button className="btn-cancel" onClick={handleClose}>
                  {t("Buttons.Cancel")}
                </button>
                <button className="blue-btn" id="create_patient">
                  {t("Buttons.Save")}
                </button>
              </div>
            )}
          </div>
        </form>
      </div>

      {isAlert.open && (
        <AlertComponent
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}
    </>
  );
};

export default NewPatientOnboarding;
