import React from "react";

const MenuIcon = ({ row, openMenu }) => {
  return (
    <svg
      onClick={(event) => openMenu(event, row.original)}
      id={row.original.id}
      style={{ cursor: "pointer" }}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="#0d6efd"
      className="bi bi-three-dots-vertical"
      viewBox="0 0 16 16"
    >
      <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
    </svg>
  );
};

export default MenuIcon;
