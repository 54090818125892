import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import PrescriptionForm from "../components/Form";
import {
  getOnePrescription,
  updatePrescription,
  resetUpdateOnePrescription,
} from "../../../redux/slices/prescription";
import AlertComponent from "../../../components/alerts/Alert";

const keys = {
  id: "",
  patient_id: "",
  doctor_id: "",
  pharmacy_id: "",
  company_id: "",
  citation_id: "",
  state: "",
  delivery_method: "",
  prescription_id: "",
  prescription_type: "",
  pharmacy_comments: "",
  patient_comments: "",
  days: "",
  created_at: "",
  blends: "",
};

const Component = ({
  prescriptionUpdate,
  prescriptionUpdateLoading,
  prescriptionUpdateError,
  prescriptionUpdateSuccess,
  prescription,
  prescriptionLoading,
  prescriptionError,
  prescriptionSuccess,
  pharmacies,
  infoRoute,
}) => {
  // translation
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  // route
  const {
    history,
    location: {
      search,
      state: { id, preview: isPreview },
    },
  } = infoRoute;
  const searchParams = new URLSearchParams(search);
  const idCitation = searchParams.get("citation_id");
  const idPatient = searchParams.get("patient_id");
  const [validated, setValidated] = useState(false);
  // data
  const [initialValues, setInitialValues] = useState(keys);
  // utils
  const [alert, setAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const isValid = (formElements) => {
    let isValid = true;

    // Iterate over form elements and check validity
    for (let i = 0; i < formElements?.length; i++) {
      const element = formElements[i];

      // Check if the element is required and if its value is empty
      if (element.required && !element.value.trim()) {
        isValid = false;
        break; // Break the loop early if any required field is empty
      }
    }

    return isValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (isValid(form?.elements)) {
      // Create an object to store form values
      var formData = {};

      // Iterate over form elements and populate formData object
      for (var i = 0; i < form?.elements?.length; i++) {
        var element = form?.elements[i];

        if (element.name) {
          let value = element.value;

          if (element.name === "pharmacy_id") {
            const pharmacy = pharmacies?.data.find(
              (pharmacy) => pharmacy?.id === Number(value)
            );
            formData["pharmacy"] = pharmacy;
          } else {
            formData[element?.name] = value;
          }
        }
      }

      // Format the blend property as an array
      formData = formatArray(formData);

      // Dispatch action with formatted data
      dispatch(updatePrescription({ ...prescription, ...formData }));
    } else {
      setValidated(true);
    }
  };

  // Function to format blend as an array
  const formatArray = (formData) => {
    // Crear un array para almacenar los blends
    const cleanedFormData = {
      blends: [],
    };

    Object.keys(formData).forEach((key) => {
      // Check if the key starts with "blends"
      if (key.startsWith("blends")) {
        // Extraer el índice del blend del nombre de la clave
        const blendIndex = key.split(".")[1];

        // Si no existe un objeto para este blendIndex, inicializarlo
        if (!cleanedFormData?.blends[blendIndex]) {
          cleanedFormData.blends[blendIndex] = { id: null, herbs: [] };
        }

        // Si la clave es para el id del blend, actualizar el id
        if (key.endsWith("id")) {
          cleanedFormData.blends[blendIndex].id = formData[key]
            ? Number(formData[key])
            : null;
        }

        // Si la clave es para una hierba, agregarla al array de hierbas
        if (key.includes("herbs")) {
          // Extraer el índice de la hierba del nombre de la clave
          const herbIndex = key.split(".")[3];
          // Obtener el nombre de la propiedad (herb, pao, qty, orig)
          const property = key.split(".")[4];
          // Si no existe un objeto para esta herbIndex, inicializarlo
          if (!cleanedFormData.blends[blendIndex].herbs[herbIndex]) {
            cleanedFormData.blends[blendIndex].herbs[herbIndex] = {};
          }
          // Establecer el valor en el objeto herbs
          cleanedFormData.blends[blendIndex].herbs[herbIndex][property] =
            formData[key];
        }
      } else {
        cleanedFormData[key] = formData[key];
      }
    });

    return cleanedFormData;
  };

  useEffect(() => {
    let initialValues = {};

    if (!!prescription) {
      for (const key of Object.keys(keys)) {
        if (key === "pharmacy_id") {
          initialValues[key] = prescription?.pharmacy?.id;
        } else {
          initialValues[key] = prescription[key];
        }
      }
    }

    setInitialValues(initialValues);
  }, [prescription]);

  useEffect(() => {
    if (id && !prescriptionLoading) {
      dispatch(getOnePrescription({ id }));
    }
  }, [infoRoute]);

  useEffect(() => {
    if (prescriptionUpdateError) {
      setAlert({
        open: true,
        severity: "error",
        message: t("Messages.The operation could not be processed"),
      });
    }
  }, [prescriptionUpdateError]);

  useEffect(() => {
    if (prescriptionUpdateSuccess) {
      setAlert({
        open: true,
        severity: "success",
        message: t("Messages.The operation was successful"),
      });
      setTimeout(() => {
        history.push(
          idCitation
            ? `/completeappointment/${btoa(idCitation)}`
            : idPatient
            ? `/patients-profile/${idPatient}`
            : "/prescription"
        );
      }, 1000);
    }
  }, [prescriptionUpdateSuccess]);

  useEffect(() => {
    if (prescriptionUpdateError || prescriptionUpdateSuccess) {
      setTimeout(() => {
        dispatch(resetUpdateOnePrescription());
        setAlert({
          open: false,
          severity: "",
          message: "",
        });
      }, 3000);
    }
  }, [prescriptionUpdateError, prescriptionUpdateSuccess]);

  return (
    <>
      <h4 className="main-title mt-2">
        {!isPreview
          ? t("PrescriptionEdit.Edit Prescription")
          : t("PrescriptionEdit.View prescription")}
      </h4>
      <p className="blue-anchors">
        <Link
          className="blue-anchors"
          to={
            idCitation
              ? `/completeappointment/${btoa(idCitation)}`
              : idPatient
              ? `/patients-profile/${idPatient}`
              : "/prescription"
          }
        >
          &#60; {t("Buttons.Back")}
        </Link>
      </p>
      <br />
      <Form
        noValidate
        validated={validated}
        id="edit-prescription-form"
        onSubmit={handleSubmit}
        style={{ width: "95%" }}
      >
        <PrescriptionForm
          validated={validated}
          initialValues={initialValues}
          isLoading={prescriptionUpdateLoading || prescriptionLoading}
          isPreview={isPreview}
        />
        {!isPreview && (
          <div className="d-flex justify-content-end">
            <Button
              className="btnGo"
              form="edit-prescription-form"
              type="submit"
              target={false}
              disabled={prescriptionUpdateLoading || prescriptionLoading}
            >
              {t("PrescriptionEdit.Edit Prescription")}
            </Button>
          </div>
        )}
      </Form>

      <AlertComponent
        isAlert={alert.open}
        setIsAlert={setAlert}
        severity={alert.severity}
        message={alert.message}
      />
    </>
  );
};

const state = ({ prescriptionStore, pharmacyStore }) => {
  const {
    data: prescription,
    loading: prescriptionLoading,
    error: prescriptionError,
    success: prescriptionSuccess,
  } = prescriptionStore.one;

  const {
    data: prescriptionUpdate,
    loading: prescriptionUpdateLoading,
    error: prescriptionUpdateError,
    success: prescriptionUpdateSuccess,
  } = prescriptionStore.update;

  const {
    data: pharmacies,
    loading: pharmaciesLoading,
    error: pharmaciesError,
    success: pharmaciesSuccess,
  } = pharmacyStore.all;

  return {
    prescriptionUpdate,
    prescriptionUpdateLoading,
    prescriptionUpdateError,
    prescriptionUpdateSuccess,
    prescription,
    prescriptionLoading,
    prescriptionError,
    prescriptionSuccess,
    pharmacies,
  };
};

export default connect(state)(Component);
