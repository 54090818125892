import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  LinearProgress,
  Menu,
  MenuItem,
} from "@material-ui/core";
import Table from "./CurrentInvoicesTable";
// import download from "../../../assets/icons/download.png";
// import paid from "../../../assets/icons/download.png";
// import overdue from "../../../assets/icons/clock.png";
// import archive from "../../../assets/icons/download.png";

const CurrentInvoices = ({
  downloading,
  loading,
  setLoading,
  data,
  downloadInvoice,
  markAsPaid,
  markAsPartialPaid,
  markAsOverdue,
  markAsCancel,
  markAsUnpaid,
}) => {
  // translations
  const { t } = useTranslation();
  let currentLanguage = localStorage.getItem("i18nextLng");

  const [anchorEl, setAnchorEl] = useState(null);
  const [current, setCurrent] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [notWorking, setNotWorking] = useState();

  const columns = useMemo(
    () => [
      {
        // first group
        Header: " ",
        // First group columns
        columns: [
          {
            Header: "ID",
            accessor: "id",
          },
          {
            Header: <>{t("CurrentInvoices.0")}</>,
            accessor: "lastname",
          },
          {
            Header: <>{t("CurrentInvoices.1")}</>,
            accessor: "firstname",
          },
          {
            Header: <>{t("CurrentInvoices.2")}</>,
            accessor: "date",
          },
          {
            Header: <>{t("CurrentInvoices.3")}</>,
            accessor: "due_date",
            Cell: ({ row }) => (
              <>
                {row.original.due_date != null ? row.original.due_date : <></>}
              </>
            ),
          },
          {
            Header: <>{t("CurrentInvoices.4")}</>,
            accessor: "total",
            Cell: ({ row }) => (
              <>
                {row.original.total != null ? (
                  parseFloat(row.original.total).toFixed(2).replace(".", ",")
                ) : (
                  <>0,00</>
                )}
              </>
            ),
          },
          {
            Header: <>{t("CurrentInvoices.20")}</>,
            accessor: "updated_at",
            Cell: ({ row }) => <>{row.original.updated_at.split("T")[0]}</>,
          },
          {
            Header: <>{t("CurrentInvoices.5")}</>,
            accessor: "status",
            Cell: ({ row }) => (
              <>
                {row.original.status == "warning" ? (
                  <>
                    <span className="invoices-list-warning-badge">
                      &nbsp;&nbsp;<>{t("CurrentInvoices.6")}</>&nbsp;&nbsp;
                    </span>
                  </>
                ) : (
                  <>
                    {row.original.status == "cancel" ? (
                      <>
                        <span className="invoices-list-cancel-badge">
                          &nbsp;&nbsp;<>{t("CurrentInvoices.15")}</>&nbsp;
                          {row.original.paid_at.split("T")[0]}&nbsp;
                        </span>
                      </>
                    ) : (
                      <>
                        {row.original.status == "paid" ? (
                          <>
                            <span className="invoices-list-invoiced-badge">
                              &nbsp;&nbsp;<>{t("CurrentInvoices.7")}</>&nbsp;
                              {row.original.paid_at.split("T")[0]}&nbsp;&nbsp;
                            </span>
                          </>
                        ) : (
                          <>
                            {row.original.status == "partialpaid" ? (
                              <>
                                <span className="invoices-list-partialpaid-badge">
                                  &nbsp;&nbsp;<>{t("CurrentInvoices.19")}</>
                                  &nbsp;&nbsp;
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="invoices-list-unpaid-badge">
                                  &nbsp;&nbsp;<>{t("CurrentInvoices.8")}</>
                                  &nbsp;&nbsp;
                                </span>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}{" "}
              </>
            ),
          },
          {
            Header: " ",
            accessor: " ",
            Cell: ({ row }) => (
              <>
                {
                  <svg
                    //</td>onClick={downloadInvoice}
                    onClick={(event) => openMenu(event, row.original)}
                    id={row.original.id}
                    style={{ cursor: "pointer" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#0d6efd"
                    className="bi bi-three-dots-vertical"
                    viewBox="0 0 16 16"
                  >
                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                  </svg>
                }
              </>
            ),
          },
        ],
      },
    ],
    [currentLanguage]
  );

  const openMenu = (event, item) => {
    //console.log('current invoice: ', event)
    setNotWorking(item.status);
    setCurrent(event);
    setAnchorEl(event.currentTarget);
    setInvoice(item);
  };

  const handleDownloadInvoice = (event) => {
    downloadInvoice(event);
    setAnchorEl(null);
  };

  const handleMarkAsPaidInvoice = (event) => {
    setAnchorEl(null);
    markAsPaid(event);
  };

  const handleMarkAsPartialPaidInvoice = (event) => {
    setAnchorEl(null);
    markAsPartialPaid(event);
  };

  const handleMarkAsOverdueInvoice = (event) => {
    setAnchorEl(null);
    markAsOverdue(event);
  };

  const handleMarkAsCancelInvoice = (event) => {
    setAnchorEl(null);
    markAsCancel(event);
  };

  const handleMarkAsUnpaidInvoice = (event) => {
    setAnchorEl(null);
    markAsUnpaid(event);
  };

  const handleArchiveInvoice = (event) => {
    setAnchorEl(null);
  };

  return (
    <>
      <div style={{ marginRight: "50px" }}>
        <div className="container-fluid treatments-table">
          {downloading ? (
            <>
              <LinearProgress color="secondary" />
              <br />
              <h4 className="invoice-patient-card-info">
                <b>
                  <>{t("CurrentInvoices.9")}</>.
                </b>
              </h4>
              <h4 className="invoice-patient-card-info">
                <b>
                  <>{t("CurrentInvoices.10")}</>.
                </b>
              </h4>
            </>
          ) : (
            <>
              {loading ? (
                <div className="d-flex justify-content-center">
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <>
                  <Table columns={columns} data={data} />
                </>
              )}
            </>
          )}
        </div>
      </div>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        style={{ paddingLeft: "0px" }}
      >
        <MenuItem
          onClick={() => handleDownloadInvoice(current)}
          className="invoice-menu-item"
        >
          {/* <img src={download} style={{width: '25px'}}/> */}
          &nbsp;
          <span className="invoice-menu-text">
            <>{t("CurrentInvoices.11")}</>
          </span>
        </MenuItem>
        <MenuItem
          onClick={() => handleMarkAsPaidInvoice(current)}
          className="invoice-menu-item"
          disabled={notWorking == "cancel"}
        >
          {/* <img src={download} style={{width: '25px'}}/> */}
          &nbsp;
          <span className="invoice-menu-text">
            <>{t("CurrentInvoices.12")}</>
          </span>
        </MenuItem>
        <MenuItem
          onClick={() => handleMarkAsPartialPaidInvoice(current)}
          className="invoice-menu-item"
          disabled={notWorking == "cancel"}
        >
          &nbsp;
          <span className="invoice-menu-text">
            <>{t("CurrentInvoices.17")}</>
          </span>
        </MenuItem>
        <MenuItem
          onClick={() => handleMarkAsUnpaidInvoice(current)}
          className="invoice-menu-item"
          disabled={notWorking == "cancel"}
        >
          {/* <img src={download} style={{width: '25px'}}/> */}
          &nbsp;
          <span className="invoice-menu-text">
            <>{t("CurrentInvoices.18")}</>
          </span>
        </MenuItem>
        <MenuItem
          onClick={() => handleMarkAsOverdueInvoice(current)}
          className="invoice-menu-item"
          disabled={notWorking == "cancel"}
        >
          {/* <img src={overdue} style={{width: '17px'}}/> */}
          &nbsp;
          <span className="invoice-menu-text">
            <>{t("CurrentInvoices.13")}</>
          </span>
        </MenuItem>
        <MenuItem
          onClick={() => handleMarkAsCancelInvoice(current)}
          className="invoice-menu-item"
          disabled={notWorking == "cancel"}
        >
          {/* <img src={download} /> */}
          &nbsp;
          <span className="invoice-menu-text">
            <>{t("CurrentInvoices.16")}</>
          </span>
        </MenuItem>
      </Menu>
    </>
  );
};

export default CurrentInvoices;
