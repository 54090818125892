import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import GetUserToken from "../../../utils/GetUserToken";
import "../../../assets/stylesheets/newdoctorcitation.css";

export const PatientPicker = ({
  patients,
  setEmail,
  setName,
  setFirstname,
  setLastname,
  setPhone,
  setAddress,
  setPatient,
  setUser,
  setInsurance,
  infoRoute,
}) => {
  // translations
  const { t } = useTranslation();
  // route
  const history = infoRoute.history;
  // auth
  const userToken = GetUserToken();
  // data
  const [insurances, setInsurences] = useState([]);

  const fetchDataInsurances = async () => {
    const url = process.env.REACT_APP_API_URL + "/insurances/index";
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };
    const res = await fetch(url, requestOptions);
    const json = await res.json();
    if (json["insurances"]) {
      setInsurences(json["insurances"]);
    }
  };

  useEffect(() => {
    fetchDataInsurances();
  }, []);

  const handleNameChange = (event, value) => {
    var fieldValue = value;
    if (!fieldValue) {
      fieldValue = null;
    } else {
      setName(value.lastname + ", " + value.firstname);
      setFirstname(value.firstname);
      setLastname(value.lastname);
      setEmail(value.email);
      setPhone(value.phone);
      setAddress(value.address);
      setUser(value.user_id);
      setPatient(value.id);
      if (insurances.length !== 0 && value.insurance) {
        setInsurance(
          insurances.filter(
            (insurance) => insurance.id === Number(value.insurance)
          )[0].title
        );
      }
    }
  };

  return (
    <>
      <h1 className="subtitle-modal">{t("Calendar.Patient")}</h1>
      <div className="row">
        <div className="col-10">
          <FormControl style={{ minWidth: "100%", textAlign: "left" }}>
            <Autocomplete
              noOptionsText={
                "Need create a new patient? use add patient button"
              }
              id="name"
              name="name"
              options={patients.sort((a, b) =>
                a.lastname > b.lastname ? 1 : -1
              )}
              onChange={handleNameChange}
              getOptionLabel={(option) =>
                option["lastname"] + ", " + option["firstname"]
              }
              inputValue={patients["lastname"]}
              renderInput={(params) => <TextField {...params} />}
            />
          </FormControl>
        </div>
        <div className="col-2">
          <button
            type="submit"
            className="add-patient-button"
            onClick={() =>
              history.push("/new-patient", {
                calendar: true,
              })
            }
          >
            {t("Buttons.New")}
          </button>
        </div>
      </div>
    </>
  );
};
