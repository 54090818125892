import React from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ReactComponent as CalendarGroup } from "../../../../assets/icons/calendarGroup.svg";
import ButtonScrollToTop from "../../../reusableComponents/ButtonScrollToTop";

const ThirdStep = ({
  date,
  time,
  doctor,
  doctors,
  loading,
  prevStep,
  // getValueCheckbox,
  handleClick,
  onSubmit,
}) => {
  // translations
  const { t, i18n } = useTranslation();
  let language = i18n.language;

  const dateFormat = (language, date) => {
    let arrayDate = date.split("-");
    let formattedDate =
      language === "en"
        ? `${arrayDate[1]}/${arrayDate[2]}/${arrayDate[0]}`
        : arrayDate.reverse().join("/");
    return formattedDate;
  };

  return (
    <div className="container-homePatient">
      <div className="container-fluid">
        <div className="frame-homePatient padding-right-mobile">
          <div className="root">
            <h4 className="main-title">{t("AppointmentSummary.0")}</h4>
            <h2 className="new-appointment-back-link">
              <a
                className="new-appointment-back-link"
                style={{ textDecoration: "none" }}
                href="/"
              >
                &#60; {t("AppointmentSummary.1")}
              </a>
            </h2>
            <br />
            <div className="row">
              <div className="col-sm-12 col-lg-8">
                <div className="result-box">
                  <div className="text-center align-self-center">
                    <CalendarGroup />
                  </div>
                  <div
                    className="text-left"
                    style={{
                      borderLeft: "1px solid #857C8E",
                      paddingLeft: "25px",
                    }}
                  >
                    <h3 className="result-box-summary-data">
                      {t("AppointmentSummary.2")}
                    </h3>
                    <h5 className="subTitles">{dateFormat(language, date)}</h5>
                  </div>
                  <div
                    className="text-left"
                    style={{
                      borderLeft: "1px solid #857C8E",
                      paddingLeft: "25px",
                    }}
                  >
                    <h3 className="result-box-summary-data">
                      {t("AppointmentSummary.3")}
                    </h3>
                    <h5 className="subTitles">
                      {time.split("T")[1].split(":00.000")[0]}
                    </h5>
                  </div>
                  <div
                    className="text-left"
                    style={{
                      borderLeft: "1px solid #857C8E",
                      paddingLeft: "25px",
                    }}
                  >
                    <h3 className="result-box-summary-data">
                      {t("AppointmentSummary.4")}
                    </h3>
                    <h5 className="subTitles">
                      {
                        doctors.find(function (o) {
                          return o.id === parseInt(doctor);
                        }).name
                      }
                    </h5>
                  </div>
                </div>
                <div className="result-box-mobile">
                  <div>
                    <CalendarGroup
                      style={{
                        width: "50px",
                        height: "50px",
                        marginTop: "20px",
                      }}
                    />
                    <h3>{t("AppointmentSummary.2")}</h3>
                    <h5 className="subTitles">{dateFormat(language, date)}</h5>
                    <h3>{t("AppointmentSummary.3")}</h3>
                    <h5 className="subTitles">
                      {time.split("T")[1].split(":00.000")[0]}
                    </h5>
                    <h3>{t("AppointmentSummary.4")}</h3>
                    <h5 className="subTitles">
                      {
                        doctors.find(function (o) {
                          return o.id === parseInt(doctor);
                        }).name
                      }
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col">
                <h4 className="main-title"> {t("AppointmentSummary.4")}</h4>
              </div>
            </div>

            <div className="d-flex flex-row align-items-center mt-4">
              <Checkbox
                color="primary"
                style={{
                  padding: "0px",
                  color: "var(--primary-color)",
                  zIndex: "99999",
                }}
                value="EMAIL!"
                // onChange={getValueCheckbox}
              />
              <h5 className="subTitles mb-0" style={{ marginLeft: "12px" }}>
                {t("AppointmentSummary.5")}
              </h5>
            </div>

            <br></br>

            {loading ? (
              <div className="d-flex justify-content-center">
                <CircularProgress />
              </div>
            ) : (
              <form onSubmit={onSubmit} className="row mt-3 mb-3 invert-column">
                <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                  <button
                    className="btnBack"
                    style={{
                      backgroundColor: "var(--secondary-color)",
                    }}
                    onClick={prevStep}
                  >
                    {t("AppointmentSummary.6")}
                  </button>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4 mb-2 d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btnGo-NewCitation"
                    style={{
                      backgroundColor: "var(--primary-color)",
                    }}
                    id="patient_newAppointment_processComplete"
                  >
                    {t("AppointmentSummary.7")}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
        <ButtonScrollToTop handleClick={handleClick} />
      </div>
    </div>
  );
};

export default ThirdStep;
