import React from "react";
import { useTable, useFilters, useSortBy, useGlobalFilter } from "react-table";
import { useTranslation } from "react-i18next";
import "../../assets/stylesheets/patients.css";

export default function Table({ columns, data }) {
  const { t } = useTranslation();

  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    setGlobalFilter,
    rows, // rows OR PAGE for the table based on the data passed
    state: { globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [{ id: "start", desc: true }],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy
    // You can add as many Hooks as you want. Hooks for react-table here
  );

  React.useEffect(() => {}, [globalFilter]);

  return (
    <>
      <div
        style={{
          width: "95%",
          background: "white",
          paddingTop: "10px",
          scrollPaddingBottom: "10px",
          borderRadius: "8px",
        }}
      >
        <div style={{ paddingLeft: "5px" }}>
          <input
            className="search-field"
            type="text"
            value={globalFilter || ""}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder={t("PatientList.Search")}
          />
        </div>

        <table
          {...getTableProps()}
          className="table table-borderless table-striped"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row); // This line is necessary to prepare the rows and get the row props from react-table dynamically
              // Each row can be rendered directly as a string using the react-table render method
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, i) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        //id={"recent" + i}
                        className="table-appointment-cell"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
