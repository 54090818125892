import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Accordion, Card, Button } from "react-bootstrap";
import { Link } from "./styles";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import PrescriptionsTable from "./components/PrescriptionsTable";
import "../../../../assets/stylesheets/patients.css";
import { useDispatch } from "react-redux";
import { getAllPrescriptions } from "../../../../redux/slices/prescription";

const Component = ({
  citation,
  patientID,
  handleCallbackPrescriptions,
  prescriptions,
  prescriptionsLoading,
  prescriptionsError,
  prescriptionsSuccess,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (citation && !prescriptionsLoading && !prescriptions) {
      dispatch(
        getAllPrescriptions({
          currentPage: 1,
          itemsPerPage: 5000,
          sortBy: "created_at",
          sortDirection: "desc",
          filters: { citation_id: citation.id },
        })
      );
    }
  }, [citation, prescriptionsLoading, prescriptions]);

  return (
    <>
      <Accordion style={{ paddingRight: "0", paddingLeft: "0" }}>
        <Card
          className="reminders-cards"
          style={{ width: "93%", borderRadius: "10px" }}
        >
          <Card.Header
            className="reminders-subtitle"
            style={{
              border: "none",
              background: "white",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="0"
              className="text-center subtitles-patients"
              style={{ textDecoration: "none" }}
              id="accordion_prescriptions"
            >
              <span>&#8595; &nbsp;</span>
              {t("Titles.Prescriptions")}
            </Accordion.Toggle>
          </Card.Header>

          <Accordion.Collapse eventKey="0">
            <Card.Body>
              {prescriptionsLoading || !prescriptions ? (
                <div className="d-flex justify-content-center">
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <>
                  {prescriptions.length > 0 && (
                    <PrescriptionsTable
                      id={citation.id}
                      prescriptions={prescriptions}
                    />
                  )}

                  {!citation.invoiced && !citation.archived ? (
                    <Link
                      style={{ maxWidth: 250 }}
                      to={`/prescription/new?patient_id=${patientID}&citation_id=${citation.id}`}
                    >
                      {t("PrescriptionNew.New Prescription")}
                    </Link>
                  ) : (
                    t("PatientPrescriptions.No prescriptions were found")
                  )}
                </>
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  );
};

const state = ({ prescriptionStore }) => {
  const {
    data: prescriptions,
    loading: prescriptionsLoading,
    error: prescriptionsError,
    success: prescriptionsSuccess,
  } = prescriptionStore.all;

  return {
    prescriptions,
    prescriptionsLoading,
    prescriptionsError,
    prescriptionsSuccess,
  };
};

export default connect(state)(Component);
