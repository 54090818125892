import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Form, Row, Col } from "react-bootstrap";
import FieldLoader from "../FieldLoader";
import TongueField from "./components/TongueField";
import PulseField from "./components/PulseField";
import HerbsField from "./components/HerbsField";
import SyndromsField from "./components/SyndromsField";

const Component = ({ isLoading, initialValues }) => {
  const [values, setValues] = useState({});
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    if (!event.target) {
      console.error("Event was not found.");
    }

    const { name, value, type, required } = event.target;

    setValues((prevValues) => {
      const newValues = { ...prevValues };

      if (value === "") {
        delete newValues[name];
      } else {
        newValues[name] = value;
      }

      return newValues;
    });

    // Validate the required field
    if (
      ((type === "text" || type === "textarea") &&
        value.trim() === "" &&
        required) ||
      (type === "file" && !value && required)
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "This field is required",
      }));
    } else {
      // Clear the error if the field is not empty
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  useEffect(() => {
    if (initialValues) {
      setValues(initialValues);
    }
  }, [initialValues]);

  return (
    <>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="state">
            <Form.Label>{t("BlendTable/Form.State")}</Form.Label>
            <FieldLoader loading={isLoading}>
              <Form.Control
                as="select"
                required
                type="text"
                name="private"
                value={values["private"]}
                onInput={handleChange}
                disabled={isLoading}
              >
                <option value="true">{t("BlendTable/Form.Private")}</option>
                <option value="false">{t("BlendTable/Form.Public")}</option>
              </Form.Control>
            </FieldLoader>
            {!!errors["state"] && (
              <Form.Control.Feedback type="invalid">
                {t("Messages.Required field")}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="pigin_name">
            <Form.Label>{t("BlendTable/Form.Pinyin name")}</Form.Label>
            <FieldLoader loading={isLoading}>
              <Form.Control
                required
                type="text"
                name="pigin_name"
                value={values["pigin_name"]}
                onInput={handleChange}
                disabled={isLoading}
              />
            </FieldLoader>
            {!!errors["pigin_name"] && (
              <Form.Control.Feedback type="invalid">
                {t("Messages.Required field")}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="chinese_name">
            <Form.Label>{t("BlendTable/Form.Chinese name")}</Form.Label>
            <FieldLoader loading={isLoading}>
              <Form.Control
                required
                type="text"
                name="chinese_name"
                value={values["chinese_name"]}
                onInput={handleChange}
                disabled={isLoading}
              />
            </FieldLoader>
            {!!errors["chinese_name"] && (
              <Form.Control.Feedback type="invalid">
                {t("Messages.Required field")}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="latin_name">
            <Form.Label>{t("BlendTable/Form.Latin name")}</Form.Label>
            <FieldLoader loading={isLoading}>
              <Form.Control
                type="text"
                name="latin_name"
                value={values["latin_name"]}
                onInput={handleChange}
                disabled={isLoading}
              />
            </FieldLoader>
            {!!errors["latin_name"] && (
              <Form.Control.Feedback type="invalid">
                {t("Messages.Required field")}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>

        <Col>
          <Form.Group className="mb-3" controlId="english_name">
            <Form.Label>{t("BlendTable/Form.English name")}</Form.Label>
            <FieldLoader loading={isLoading}>
              <Form.Control
                type="text"
                name="english_name"
                value={values["english_name"]}
                onInput={handleChange}
                disabled={isLoading}
              />
            </FieldLoader>
            {!!errors["english_name"] && (
              <Form.Control.Feedback type="invalid">
                {t("Messages.Required field")}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="therapeutic_actions">
            <Form.Label>{t("BlendTable/Form.Therapeutic actions")}</Form.Label>
            <FieldLoader loading={isLoading}>
              <Form.Control
                type="text"
                name="therapeutic_actions"
                value={values["therapeutic_actions"]}
                onInput={handleChange}
                disabled={isLoading}
              />
            </FieldLoader>
            {!!errors["therapeutic_actions"] && (
              <Form.Control.Feedback type="invalid">
                {t("Messages.Required field")}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="administration">
            <Form.Label>{t("BlendTable/Form.Administration")}</Form.Label>
            <FieldLoader loading={isLoading}>
              <Form.Control
                type="text"
                name="administration"
                value={values["administration"]}
                onInput={handleChange}
                disabled={isLoading}
              />
            </FieldLoader>
            {!!errors["administration"] && (
              <Form.Control.Feedback type="invalid">
                {t("Messages.Required field")}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="clinical_manifestations">
            <Form.Label>
              {t("BlendTable/Form.Clinical manifestations")}
            </Form.Label>
            <FieldLoader loading={isLoading}>
              <Form.Control
                type="text"
                name="clinical_manifestations"
                value={values["clinical_manifestations"]}
                onInput={handleChange}
                disabled={isLoading}
              />
            </FieldLoader>
            {!!errors["clinical_manifestations"] && (
              <Form.Control.Feedback type="invalid">
                {t("Messages.Required field")}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="treats_conditions">
            <Form.Label>{t("BlendTable/Form.Treats conditions")}</Form.Label>
            <FieldLoader loading={isLoading}>
              <Form.Control
                type="text"
                name="treats_conditions"
                value={values["treats_conditions"]}
                onInput={handleChange}
                disabled={isLoading}
              />
            </FieldLoader>
            {!!errors["treats_conditions"] && (
              <Form.Control.Feedback type="invalid">
                {t("Messages.Required field")}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <SyndromsField
            name="syndroms"
            value={values["syndroms"]}
            onInput={handleChange}
            disabled={isLoading}
          />
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="contraindications">
            <Form.Label>{t("BlendTable/Form.Contraindications")}</Form.Label>
            <FieldLoader loading={isLoading}>
              <Form.Control
                type="text"
                name="contraindications"
                value={values["contraindications"]}
                onInput={handleChange}
                disabled={isLoading}
              />
            </FieldLoader>
            {!!errors["contraindications"] && (
              <Form.Control.Feedback type="invalid">
                {t("Messages.Required field")}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="herbs_drugs_interactions">
            <Form.Label>{t("BlendTable/Form.Herbs drugs")}</Form.Label>
            <FieldLoader loading={isLoading}>
              <Form.Control
                type="text"
                name="herbs_drugs_interactions"
                value={values["herbs_drugs_interactions"]}
                onInput={handleChange}
                disabled={isLoading}
              />
            </FieldLoader>
            {!!errors["herbs_drugs_interactions"] && (
              <Form.Control.Feedback type="invalid">
                {t("Messages.Required field")}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <PulseField
            name="pulse"
            value={values["pulse"]}
            onInput={handleChange}
            isLoading={isLoading}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <TongueField
            name="tongue"
            value={values["tongue"]}
            onInput={handleChange}
            isLoading={isLoading}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <HerbsField
            name="herbs"
            value={values.herb}
            onInput={handleChange}
            isLoading={isLoading}
          />
        </Col>
      </Row>
    </>
  );
};

const state = () => {
  return {};
};

export default connect(state)(Component);
