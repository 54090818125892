import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Buttons = ({ citation, deleteCitation, onSubmit }) => {
  // Translation
  const { t } = useTranslation();
  const state = useSelector((state) => state.user);

  return (
    <>
      {citation.archived ? (
        <>
          <button
            value="false"
            className="button-citation-box-2-cancel"
            onClick={deleteCitation}
          >
            {t("ViewCitation.UNARCHIVE")}
          </button>
        </>
      ) : (
        <>
          <button
            value="true"
            className="button-citation-box-2-cancel"
            onClick={deleteCitation}
          >
            {t("ViewCitation.ARCHIVE")}
          </button>
        </>
      )}
      &nbsp; &nbsp;
      {state.auth?.admin === true || state.auth?.doctor === true ? (
        <>
          <Link to={`/completeappointment/${btoa(citation.id)}`}>
            <button className="button-citation-box-2-complete" type="submit">
              {t("ViewCitation.COMPLETE")}
            </button>
          </Link>
        </>
      ) : (
        <>
          {/* Optionally handle what happens if the user does not have the required role */}
        </>
      )}
      &nbsp; &nbsp;
      <button className="button-citation-box-2-save" onClick={onSubmit}>
        {t("ViewCitation.SAVE")}
      </button>
    </>
  );
};

export default Buttons;
