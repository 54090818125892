import React, { useMemo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "./AppointmentsTable";
import { ReactComponent as Pencil } from "../../assets/icons/pencil.svg";
import GetUserToken from "../../utils/GetUserToken";

function PatientAppointments({ id }) {
  // auth
  const userToken = GetUserToken();
  // translations
  const { t } = useTranslation();
  let currentLanguage = localStorage.getItem("i18nextLng");
  // data
  const [data, setData] = useState([]);
  // utils
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      redirect: "follow",
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL_V2 +
        "/citations/get_patient_citations?id=" +
        parseInt(id),
      requestOptions
    );
    const json = await res.json();
    setData(json.events);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const not = (
    <div className="d-flex justify-content-center">
      <CircularProgress color="secondary" />
    </div>
  );

  const columns = useMemo(
    () => [
      {
        // first group
        Header: " ",
        // First group columns
        columns: [
          {
            Header: <>{t("PatientAppointments.Status")}</>,
            accessor: "archived",
            Cell: (e) =>
              e.value == true ? (
                <>
                  <span style={{ color: "#857c8e", fontStyle: "italic" }}>
                    Archived
                  </span>
                </>
              ) : (
                <>Active</>
              ),
          },
          {
            Header: <>{t("PatientAppointments.Date")}</>,
            accessor: "start",
            sortType: "basic",
            Cell: (e) => e.value.replace("T", " at: ").slice(0, -13),
          },
          {
            Header: <>{t("PatientAppointments.Treatment")}</>,
            accessor: "treatment",
          },
          {
            Header: <>{t("PatientAppointments.Therapist")}</>,
            accessor: "doctor",
          },
          {
            Header: <>{t("PatientAppointments.End")}</>,
            accessor: "end",
            Cell: (e) => e.value.replace("T", " at: ").slice(0, -13),
          },
          {
            Header: (
              <span style={{ textTransform: "capitalize" }}>
                {t("Settings.quizes")}
              </span>
            ),
            accessor: "quizCompleted",
            Cell: (e) =>
              e.value == true ? (
                <>{t("Insurances.Yes")}</>
              ) : (
                <>{t("Insurances.No")}</>
              ),
          },
          {
            Header: " ",
            accessor: "link",
            Cell: (e) => (
              <Link
                to={{
                  pathname: `/citation/${btoa(e.value)}`,
                  state: {
                    patientProfile: id,
                  },
                }}
              >
                <Pencil className="icon" />
              </Link>
            ),
          },
        ],
      },
    ],
    [currentLanguage]
  );

  return (
    <>
      <div className="mt-3 pt-5 pb-5">
        <h2 className="subtitles-patients">
          {t("PatientAppointments.Recent appointments")}
        </h2>
        {loading ? (
          not
        ) : (
          <>
            <Table columns={columns} data={data} />
          </>
        )}
      </div>
    </>
  );
}

export default PatientAppointments;
