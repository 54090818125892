import React from "react";
import IconButton from "@material-ui/core/IconButton";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { Link } from "react-router-dom";

const ProfileLink = ({ id, to }) => {
  const profileUrl = `/patients-profile/${id}`;

  return (
    <IconButton
      component={Link}
      to={profileUrl}
      target="_blank"
      aria-label="Profile"
      style={{ color: "var(--primary-color)" }}
    >
      <AccountCircleIcon />
    </IconButton>
  );
};

export default ProfileLink;
