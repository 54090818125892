import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "react-bootstrap/Modal";
import { momentLocalizer } from "react-big-calendar";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Backdrop from "@material-ui/core/Backdrop";
import "./App.css";
import TimeLockerDialog from "./timelocker/TimeLockerDialog";
import TimeLockerCalendar from "./timelocker/TimeLockerCalendar";
import TimeLockerView from "./timelocker/TimeLockerView";
import "./calendar.css";
import GetUserToken from "../../utils/GetUserToken";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #ccc",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    borderRadius: "10px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function Schedule() {
  const { t } = useTranslation();
  const classes = useStyles();
  // auth
  const userToken = GetUserToken();

  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState("");
  const [link, setLink] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [doctor, setDoctor] = useState("");
  const [position, setPosition] = useState("");
  const [doctors, setDoctors] = useState([]);
  let [filter_doctor_id, setFilter] = useState(null);
  let [filtered, setFiltered] = useState([]);
  const [filtro, setFiltro] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [daterror, setDaterror] = useState(false);
  const [creating, setCreating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [title, setTitle] = useState("");

  /* Time Lock Modal */
  let options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  const [openTimeLock, setTimeLock] = useState(false);
  const [timeLockStart, setTimeLockStart] = useState("");
  const [timeLockEnd, setTimeLockEnd] = useState("");
  const [timeLockDoctor, setTimeLockDoctor] = useState("");
  /* Time Lock Modal */

  const localizer = momentLocalizer(moment);

  const formatDate = (date) => {
    let formattedDate = new Date(date)
      .toLocaleTimeString("en-us", options)
      .toString()
      .replace(" at", "");
    return formattedDate;
  };

  useEffect(() => {
    getEvents();
  }, []);

  useEffect(() => {
    getEvents();
    fetchDoctors();
    setDeleting(false);
  }, [setDoctors]);

  const fetchDoctors = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL + "/citations/getDoctors",
      requestOptions
    );
    const json = await res.json();
    const activeDoctors = json.clinicDoctors.filter((obj) => {
      return obj.archived === false;
    });
    setDoctors(activeDoctors);
  };

  const getEvents = async () => {
    let response = await fetch(
      process.env.REACT_APP_API_URL + "/schedules/getScheduleV2",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
          Authorization: userToken,
        },
      }
    );
    if (response.ok) {
      response = await response.json();
      response.map((item, i) => {
        item.start = new Date(item.start);
        // item.start = new Date(new Date(item.start).toLocaleString('en-US', { timeZone: "UTC" }))
        item.end = new Date(item.end);
        // item.end = new Date(new Date(item.end).toLocaleString('en-US', { timeZone: "UTC" }))
        item.color = item.color + "ba";
        item.title = item.doctorName;
      });
      setEvents(response);
      setLoading(false);
    } else {
      setError(true);
    }
  };

  const handleOpen = (e) => {
    setInfo(e["title"]);
    setLink(e["link"]);
    setStart(e["start"].toString());
    setEnd(e["end"].toString());
    setDoctor(e["doctorName"]);
    setPosition(e["position"]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeLock(false);
  };

  const arrangeCalendarEvents = (myDoctorId) => {
    let eventsToShow = [];
    let gettedCitations = [];

    // console.log('picked doctor ID: ', myDoctorId)

    if (myDoctorId == null) {
      eventsToShow = events;
    } else {
      gettedCitations = events.filter((item) => item.doctorID == myDoctorId);
      eventsToShow = gettedCitations;
    }
    setEvents(eventsToShow);
  };

  const handleDoctorFilterChange = (event) => {
    // console.log('handleDoctorFilterChange: ', event.target.value);
    setFilter(event.target.value);
    setFiltro(event.target.value);
    // arrangeCalendarEvents(event.target.value);
    filterEventsToShow();
  };

  const filterEventsToShow = () => {
    let eventsToShow = [];
    if (filtro == null) {
      eventsToShow = events;
    } else {
      eventsToShow = events.filter((item) => parseInt(item.doctorID) == filtro);
    }
    return eventsToShow;
  };

  const deleteEvent = async () => {
    setDeleting(true);

    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
    };

    await fetch(
      process.env.REACT_APP_API_URL + "/schedules/destroy/" + link,
      requestOptions
    )
      .then((response) => response.text())
      .catch((error) => console.log("error", error));
    await getEvents();
    setDeleting(false);
    handleClose();
  };

  const eventStyleGetter = (event) => {
    var backgroundColor = event.color;
    var style = {
      backgroundColor: backgroundColor,
    };
    return {
      style: style,
    };
  };

  const handleSelect = ({ start, end }) => {
    // FIXME: Arreglar Zonas Horarias
    let lockStart = formatDate(start);
    let lockEnd = formatDate(end);

    let lockStartComparator = new Date(start);
    let lockEndComparator = new Date(end);

    if (
      lockStartComparator.toDateString() === lockEndComparator.toDateString()
    ) {
      setTimeLock(true);
      // setTimeLockStart(start)
      // setTimeLockEnd(end)
      setTimeLockStart(lockStart);
      setTimeLockEnd(lockEnd);
    } else {
      setDaterror(true);
    }
  };

  const handleDoctorChange = (e, value) => {
    var fieldValue = value;
    if (!fieldValue) {
      fieldValue = null;
    } else setTimeLockDoctor(value.id);
  };

  const submitTimeLock = async () => {
    // let start = new Date(timeLockStart);
    // let end = new Date(timeLockEnd);

    // if (start.toDateString() === end.toDateString()) {

    setCreating(true);
    // setDeleting(true);

    const body = {
      title,
      start: timeLockStart,
      end: timeLockEnd,
      // start: timeLockStart.toLocaleTimeString("en-us", options),
      // end: timeLockEnd.toLocaleTimeString("en-us", options),
      doctor: timeLockDoctor,
    };

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      body: JSON.stringify(body),
    };

    await fetch(
      process.env.REACT_APP_API_URL + "/schedules/createFromCalendar",
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          getEvents();
          setSuccess(true);
          setCreating(false);
          setTimeLock(false);
        } else {
          setError(true);
        }
      })
      .catch((error) => console.log("error", error));

    // } else {
    //     alert('You can only lock one day at the time')
    // }
  };

  return (
    <>
      <div>
        {loading ? (
          <>
            <div className="d-flex justify-content-center">
              <CircularProgress color="secondary" />
            </div>
          </>
        ) : (
          <div className="schedule-calendar">
            <TimeLockerCalendar
              handleDoctorFilterChange={handleDoctorFilterChange}
              doctor={doctor}
              doctors={doctors}
              handleSelect={handleSelect}
              localizer={localizer}
              filter_doctor_id={filter_doctor_id}
              events={events}
              filtered={filtered}
              handleOpen={handleOpen}
              eventStyleGetter={eventStyleGetter}
              filterEventsToShow={filterEventsToShow}
            />
          </div>
        )}
      </div>

      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(!success)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSuccess(!success)}
          severity="success"
        >
          {t("Calendar.Time Spot Locked")}
        </MuiAlert>
      </Snackbar>

      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(!error)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setError(!error)}
          severity="error"
        >
          {t("Calendar.Something went wrong... please try again")}
        </MuiAlert>
      </Snackbar>

      <Snackbar
        open={daterror}
        autoHideDuration={6000}
        onClose={() => setDaterror(!daterror)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setDaterror(!daterror)}
          severity="error"
        >
          {t("Calendar.Cannot create time lock for more than one day")}
        </MuiAlert>
      </Snackbar>

      <Backdrop className={classes.backdrop} open={deleting}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Modal
        show={open}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body style={{ background: "#F4F5FA" }}>
          <TimeLockerView
            doctor={doctor}
            start={formatDate(start)}
            end={formatDate(end)}
            deleteEvent={deleteEvent}
            deleting={deleting}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={openTimeLock}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body style={{ background: "#F4F5FA" }}>
          <TimeLockerDialog
            title={title}
            setTitle={setTitle}
            timeLockStart={timeLockStart}
            timeLockEnd={timeLockEnd}
            doctors={doctors}
            handleDoctorChange={handleDoctorChange}
            submitTimeLock={submitTimeLock}
            creating={creating}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
