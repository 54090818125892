import { useState } from "react";
import { connect } from "react-redux";
import FieldLoader from "../../../FieldLoader";
import { Select } from "./styles";

/**
 *
 * @param {language, herbs, herbsLoading, herbsSuccess, herbsError, name, value}
 * language: pinyin | chinese | latin | english
 * herbs: Herbs[]
 * herbsLoading: boolean
 * herbsSuccess: boolean
 * herbsError: string | boolen
 *
 * @returns React Component
 */

const Component = ({
  language,
  name,
  value,
  disabled,
  onChange,
  herbs,
  herbsLoading,
  herbsSuccess,
  herbsError,
  ...rest
}) => {
  const handleChange = (event) => {
    !!onChange && onChange(event);
  };

  //console.log('herb select component', { language, name, value, onChange, herbs, herbsLoading, herbsSuccess, herbsError, ...rest })

  return (
    <FieldLoader loading={herbsLoading}>
      <Select
        name={name}
        value={value}
        disabled={disabled}
        onChange={handleChange}
        {...rest}
      >
        {herbs && herbs.length > 0 ? (
          herbs.map((herb, index) => (
            <option key={`select-herb-${index}`} value={herb.id}>
              {herb[`${language}_name`]}
            </option>
          ))
        ) : (
          <></>
        )}
      </Select>
    </FieldLoader>
  );
};

const states = ({ herbStore }) => {
  const {
    data: herbs,
    loading: herbsLoading,
    success: herbsSuccess,
    error: herbsError,
  } = herbStore.all;
  return {
    herbs,
    herbsLoading,
    herbsSuccess,
    herbsError,
  };
};
export default connect(states)(Component);
