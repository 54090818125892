import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { CircularProgress, TextareaAutosize } from "@material-ui/core";
import GetUserToken from "../../utils/GetUserToken";

const NewInsuranceOnboarding = ({
  isAlert,
  setIsAlert,
  handleStepComplete,
}) => {
  // auth
  const userToken = GetUserToken();
  // translation
  const { t } = useTranslation();
  // data
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  // utils
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);

    const url = process.env.REACT_APP_API_URL + "/insurances/create";

    const body = {
      title: title,
      description: description,
    };

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
        Authorization: userToken,
      },
      body: JSON.stringify(body),
    };

    try {
      const response = await fetch(url, requestOptions);

      if (response.ok) {
        await handleStepComplete();
        setTitle("");
        setDescription("");
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.The operation could not be processed"),
        });
      }
    } catch (error) {
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        maxHeight: "100%",
        overflowY: "auto",
        width: "100%",
        maxWidth: "500px",
        mr: "auto",
        ml: "auto",
        mt: "20px",
        backgroundColor: "#ffffff",
        border: "1px solid #f1f4fa",
        borderRadius: "16px",
        p: "26px",
      }}
    >
      <h4 className="main-title text-center mt-2">
        {t("Insurances.New Insurance")}
      </h4>
      <div className="form-group">
        <label className="title-inputs">{t("Insurances.Title")}</label>
        <input
          required
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="input-control"
          disabled={loading}
        />
      </div>
      <div className="form-group">
        <label className="title-inputs">{t("Insurances.Description")}</label>
        <TextareaAutosize
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          aria-label="minimum height"
          minRows={3}
          className="input-control"
          disabled={loading}
        />
      </div>
      <div className="d-flex justify-content-center">
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <button
            className={
              !title || isAlert.open ? "blue-btn-disabled" : "blue-btn"
            }
            id="create_insurance"
            disabled={!title || isAlert.open}
            onClick={handleSubmit}
          >
            {t("Buttons.Save")}
          </button>
        )}
      </div>
    </Box>
  );
};

export default NewInsuranceOnboarding;
