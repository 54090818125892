import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import services from "../../services";

// Define initial state
const initialState = {
  one: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  create: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  update: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  delete: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  all: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  first: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
  bookable: {
    data: null,
    loading: false,
    error: false,
    success: false,
  },
};

// Async action to get one treatment
const getOneTreatment = createAsyncThunk(
  "treatment/getOne",
  async (payload) => {
    console.log("get one - treatment - action", payload);
    const { response, error } = await services.treatment.getOne(payload);
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Async action to create one treatment
const createOneTreatment = createAsyncThunk(
  "treatment/createOne",
  async (payload) => {
    console.log("create one - treatment - action", payload);
    const { response, error } = await services.treatment.createOne(payload);
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Async action to reset a treatment creation
const resetCreateOneTreatment = createAsyncThunk(
  "treatment/resetCreateOne",
  async (payload) => {
    console.log("reset creation - treatment - action", payload);
  }
);

// Async action to update a treatment
const updateOneTreatment = createAsyncThunk(
  "treatment/update",
  async (payload) => {
    console.log("update - treatment - action", payload);
    const { response, error } = await services.treatment.update(payload);
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Async action to reset a treatment update
const resetUpdateOneTreatment = createAsyncThunk(
  "treatment/resetUpdateOne",
  async (payload) => {
    console.log("reset update - treatment - action", payload);
  }
);

// Async action to update a treatment
const deleteOneTreatment = createAsyncThunk(
  "treatment/delete",
  async (payload) => {
    console.log("delete - treatment - action", payload);
    const { response, error } = await services.treatment.delete(payload);
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Async action to reset a treatment delete
const resetDeleteOneTreatment = createAsyncThunk(
  "treatment/resetDeleteOne",
  async (payload) => {
    console.log("reset delete - treatment - action", payload);
  }
);

// Async action to get all treatments
const getAllTreatments = createAsyncThunk(
  "treatment/getAll",
  async (payload) => {
    console.log("get all - treatment - action", payload);
    const { response, error } = await services.treatment.getAll(payload);
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Async action to get first treatments
const getFirstTreatments = createAsyncThunk(
  "treatment/getFirst",
  async (payload) => {
    console.log("get first - treatment - action");
    const { response, error } = await services.treatment.getFirst(payload);
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Async action to get bookable treatments
const getBookableTreatments = createAsyncThunk(
  "treatment/getBookable",
  async () => {
    console.log("get bookable - treatment - action");
    const { response, error } = await services.treatment.getBookable();
    if (error) {
      throw new Error(error);
    }
    return response;
  }
);

// Create a treatment slice
const treatmentSlice = createSlice({
  name: "treatment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOneTreatment.pending, (state, action) => {
        state.one.data = null;
        state.one.loading = true;
        state.one.success = false;
        state.one.error = false;
      })
      .addCase(getOneTreatment.fulfilled, (state, action) => {
        state.one.data = action.payload;
        state.one.loading = false;
        state.one.success = true;
        state.one.error = false;
      })
      .addCase(getOneTreatment.rejected, (state, action) => {
        state.one.data = null;
        state.one.loading = false;
        state.one.success = false;
        state.one.error = action.error.message;
      })
      .addCase(createOneTreatment.pending, (state, action) => {
        state.create.data = null;
        state.create.loading = true;
        state.create.success = false;
        state.create.error = false;
      })
      .addCase(createOneTreatment.fulfilled, (state, action) => {
        state.create.data = action.payload;
        state.create.loading = false;
        state.create.success = true;
        state.create.error = false;
      })
      .addCase(createOneTreatment.rejected, (state, action) => {
        state.create.data = null;
        state.create.loading = false;
        state.create.success = false;
        state.create.error = action.error.message;
      })
      .addCase(resetCreateOneTreatment.fulfilled, (state, action) => {
        state.create.data = null;
        state.create.loading = false;
        state.create.success = false;
        state.create.error = false;
      })
      .addCase(updateOneTreatment.pending, (state) => {
        state.update.data = null;
        state.update.loading = true;
        state.update.success = false;
        state.update.error = false;
      })
      .addCase(updateOneTreatment.fulfilled, (state, action) => {
        state.update.data = action.payload;
        state.update.loading = false;
        state.update.success = true;
        state.update.error = false;
        state.one.data = action.payload;
      })
      .addCase(updateOneTreatment.rejected, (state, action) => {
        state.update.data = null;
        state.update.loading = false;
        state.update.success = false;
        state.update.error = action.error.message;
      })
      .addCase(resetUpdateOneTreatment.fulfilled, (state, action) => {
        state.update.data = null;
        state.update.loading = false;
        state.update.success = false;
        state.update.error = false;
      })
      .addCase(deleteOneTreatment.pending, (state) => {
        state.delete.data = null;
        state.delete.loading = true;
        state.delete.success = false;
        state.delete.error = false;
      })
      .addCase(deleteOneTreatment.fulfilled, (state, action) => {
        state.delete.data = action.payload;
        state.delete.loading = false;
        state.delete.success = true;
        state.delete.error = false;
        state.one.data = action.payload;
      })
      .addCase(deleteOneTreatment.rejected, (state, action) => {
        state.delete.data = null;
        state.delete.loading = false;
        state.delete.success = false;
        state.delete.error = action.error.message;
      })
      .addCase(resetDeleteOneTreatment.fulfilled, (state, action) => {
        state.delete.data = null;
        state.delete.loading = false;
        state.delete.success = false;
        state.delete.error = false;
      })
      .addCase(getAllTreatments.pending, (state) => {
        state.all.data = null;
        state.all.loading = true;
        state.all.success = false;
        state.all.error = false;
      })
      .addCase(getAllTreatments.fulfilled, (state, action) => {
        state.all.data = action.payload;
        state.all.loading = false;
        state.all.success = true;
        state.all.error = false;
      })
      .addCase(getAllTreatments.rejected, (state, action) => {
        state.all.data = null;
        state.all.loading = false;
        state.all.success = false;
        state.all.error = action.error.message;
      })
      .addCase(getFirstTreatments.pending, (state) => {
        state.first.data = null;
        state.first.loading = true;
        state.first.success = false;
        state.first.error = false;
      })
      .addCase(getFirstTreatments.fulfilled, (state, action) => {
        state.first.data = action.payload;
        state.first.loading = false;
        state.first.success = true;
        state.first.error = false;
      })
      .addCase(getFirstTreatments.rejected, (state, action) => {
        state.first.data = null;
        state.first.loading = false;
        state.first.success = false;
        state.first.error = action.error.message;
      })
      .addCase(getBookableTreatments.pending, (state) => {
        state.bookable.data = null;
        state.bookable.loading = true;
        state.bookable.success = false;
        state.bookable.error = false;
      })
      .addCase(getBookableTreatments.fulfilled, (state, action) => {
        state.bookable.data = action.payload;
        state.bookable.loading = false;
        state.bookable.success = true;
        state.bookable.error = false;
      })
      .addCase(getBookableTreatments.rejected, (state, action) => {
        state.bookable.data = null;
        state.bookable.loading = false;
        state.bookable.success = false;
        state.bookable.error = action.error.message;
      });
  },
});

// Export the async actions and the reducer
export {
  getOneTreatment,
  createOneTreatment,
  updateOneTreatment,
  deleteOneTreatment,
  resetCreateOneTreatment,
  resetUpdateOneTreatment,
  resetDeleteOneTreatment,
  getAllTreatments,
  getFirstTreatments,
  getBookableTreatments,
};

export default treatmentSlice.reducer;
