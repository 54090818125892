import React from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

export const Treatmens = ({ clinicTreatments, setTreatments }) => {
  // translations
  const { t } = useTranslation();

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleTreatmentChange = (e, value) => {
    var fieldValue = value;
    if (!fieldValue) {
      fieldValue = null;
    } else setTreatments(value.id);
  };

  return (
    <>
      <div className="row">
        <div className="col form-group">
          <label className="title-inputs">
            {t("EditQuiz.Select Treatments")}
          </label>
          <br />
          <br />
          <Autocomplete
            name="treatments"
            id="treatments"
            options={clinicTreatments}
            disableCloseOnSelect
            onChange={(e, value) => {
              handleTreatmentChange(e, value);
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8, color: "var(--primary-color)" }}
                  checked={selected}
                />
                {option.name}
              </React.Fragment>
            )}
            style={{ width: "49%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={t("QuizList.Treatments")}
                placeholder="Select"
                fullWidth
              />
            )}
          />
        </div>
      </div>
    </>
  );
};

export default Treatmens;
