import React from 'react'
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import '../../../assets/stylesheets/newdoctorcitation.css'

export const DateTimePicker = ({ schedule, setDate, setTime, checkSchedule, setStatustime, checkStatus }) => {
    const { t } = useTranslation();

    const handleTimeChange = (event) => {
        setStatustime(event.target.value)
        var eventDate = event.target.value.split('T')[0]
        var eventTime = event.target.value + ':00.000+00:00';
        setDate(eventDate);
        setTime(eventTime);
        checkSchedule(event.target.value.first) 
        checkStatus("time", event.target.value)
    }

    return (
        <>
            <h1 className='subtitle-modal'>{t('Calendar.Date/Time')}</h1>
            <FormControl component="fieldset" style={{ minWidth: '100%' }}>
                <TextField
                    //disabled={schedule.length > 0}
                    required
                    id="time"
                    name="time"
                    type="datetime-local"
                    defaultValue={Date.now()}
                    onChange={handleTimeChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </FormControl>
        </>
    )
}
